import { isNil } from "lodash";

export function trimNumber(number, decimalPlace = 2) {
    if (isNil(number)) {
        return;
    }

    return Number(number.toFixed(decimalPlace));
}

export function calculatePercentage(value, total) {
    if (!value) {
        return 0;
    }
    return (value * 100) / total;
}
