import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

function ExpandIconBtn({ onClick, isOpen }) {

    return (
        <button type="button" data-testid="collapse-toggle" onClick={onClick} className="flex items-center">
            {isOpen ? <ExpandLess /> : <ExpandMore />}
        </button>
    );
}

export default ExpandIconBtn;