"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GeneralStatisticsData = _interopRequireDefault(require("./GeneralStatisticsData"));
var _StatisticsData = _interopRequireDefault(require("./StatisticsData"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GenerativeInsightsStatistics model module.
 * @module model/GenerativeInsightsStatistics
 * @version 0.37.0
 */
class GenerativeInsightsStatistics {
  /**
   * Constructs a new <code>GenerativeInsightsStatistics</code>.
   * @alias module:model/GenerativeInsightsStatistics
   */
  constructor() {
    GenerativeInsightsStatistics.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>GenerativeInsightsStatistics</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GenerativeInsightsStatistics} obj Optional instance to populate.
   * @return {module:model/GenerativeInsightsStatistics} The populated <code>GenerativeInsightsStatistics</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GenerativeInsightsStatistics();
      if (data.hasOwnProperty('insight_related_time')) {
        obj['insight_related_time'] = _StatisticsData.default.constructFromObject(data['insight_related_time']);
      }
      if (data.hasOwnProperty('number_of_appearances')) {
        obj['number_of_appearances'] = _StatisticsData.default.constructFromObject(data['number_of_appearances']);
      }
      if (data.hasOwnProperty('general_insights_data')) {
        obj['general_insights_data'] = _GeneralStatisticsData.default.constructFromObject(data['general_insights_data']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GenerativeInsightsStatistics</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GenerativeInsightsStatistics</code>.
   */
  static validateJSON(data) {
    // validate the optional field `insight_related_time`
    if (data['insight_related_time']) {
      // data not null
      _StatisticsData.default.validateJSON(data['insight_related_time']);
    }
    // validate the optional field `number_of_appearances`
    if (data['number_of_appearances']) {
      // data not null
      _StatisticsData.default.validateJSON(data['number_of_appearances']);
    }
    // validate the optional field `general_insights_data`
    if (data['general_insights_data']) {
      // data not null
      _GeneralStatisticsData.default.validateJSON(data['general_insights_data']);
    }
    return true;
  }
}

/**
 * @member {module:model/StatisticsData} insight_related_time
 */
GenerativeInsightsStatistics.prototype['insight_related_time'] = undefined;

/**
 * @member {module:model/StatisticsData} number_of_appearances
 */
GenerativeInsightsStatistics.prototype['number_of_appearances'] = undefined;

/**
 * @member {module:model/GeneralStatisticsData} general_insights_data
 */
GenerativeInsightsStatistics.prototype['general_insights_data'] = undefined;
var _default = exports.default = GenerativeInsightsStatistics;