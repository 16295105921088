"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Frames Storage API
 * Frames Storage API gives access to the frames of recorded appearances from cameras analyzed by the application.  It allows to read and remove frames that associated with an appearance record.
 *
 * The version of the OpenAPI document: 0.34.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GetFramesRequest model module.
 * @module model/GetFramesRequest
 * @version 0.34.0
 */
class GetFramesRequest {
  /**
   * Constructs a new <code>GetFramesRequest</code>.
   * @alias module:model/GetFramesRequest
   */
  constructor() {
    GetFramesRequest.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>GetFramesRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GetFramesRequest} obj Optional instance to populate.
   * @return {module:model/GetFramesRequest} The populated <code>GetFramesRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GetFramesRequest();
      if (data.hasOwnProperty('draw_bbox')) {
        obj['draw_bbox'] = _ApiClient.default.convertToType(data['draw_bbox'], 'Boolean');
      }
      if (data.hasOwnProperty('frame_sequence')) {
        obj['frame_sequence'] = _ApiClient.default.convertToType(data['frame_sequence'], 'Number');
      }
      if (data.hasOwnProperty('color')) {
        obj['color'] = _ApiClient.default.convertToType(data['color'], 'String');
      }
      if (data.hasOwnProperty('label')) {
        obj['label'] = _ApiClient.default.convertToType(data['label'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GetFramesRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetFramesRequest</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['color'] && !(typeof data['color'] === 'string' || data['color'] instanceof String)) {
      throw new Error("Expected the field `color` to be a primitive type in the JSON string but got " + data['color']);
    }
    // ensure the json data is a string
    if (data['label'] && !(typeof data['label'] === 'string' || data['label'] instanceof String)) {
      throw new Error("Expected the field `label` to be a primitive type in the JSON string but got " + data['label']);
    }
    return true;
  }
}

/**
 * Draw the appearance's bbox on the returned frames
 * @member {Boolean} draw_bbox
 * @default true
 */
GetFramesRequest.prototype['draw_bbox'] = true;

/**
 * The sequence number of the frame to return -X < 0 < Y while 0 is the best frame
 * @member {Number} frame_sequence
 * @default 0
 */
GetFramesRequest.prototype['frame_sequence'] = 0;

/**
 * A hex string representing the color of the BBox
 * @member {String} color
 */
GetFramesRequest.prototype['color'] = undefined;

/**
 * A label that we want to be written next to the bbox
 * @member {String} label
 */
GetFramesRequest.prototype['label'] = undefined;
var _default = exports.default = GetFramesRequest;