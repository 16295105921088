import { cancel } from "src/appConfig/Strings";
import FilledBtn from "../../Buttons/FilledBtn";
import OutlinedBtn from "../../Buttons/OutlinedBtn";

function ModalFooter({ type, formID, handleLeftBtnAction, leftBtnText = cancel, rightBtnText, handleRightBtnAction, isRightBtnDisabled, isLeftBtnDisabled, showCancelButton = true }) {
    return (
        <footer className="flex items-center justify-center p-3">
            {showCancelButton && (
                <OutlinedBtn
                    className="mr-main-elements-space"
                    onClick={handleLeftBtnAction}
                    disabled={isLeftBtnDisabled}
                    data-testid="modal-left-button"
                >
                    {leftBtnText}
                </OutlinedBtn>
            )}
            <FilledBtn
                form={formID}
                type={type}
                onClick={handleRightBtnAction}
                isDisabled={isRightBtnDisabled}
                data-testid="modal-right-button"
            >
                {rightBtnText}
            </FilledBtn>
        </footer>
    );
}

export default ModalFooter;