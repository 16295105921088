import { useCallback, useContext, useMemo } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import store from "src/appConfig/configureStore";
import ContextMenuButton from "../../Common/Buttons/ContextMenuButton";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { dispatchSetRemovedAppearanceDetailsEvent } from "@/Components/Common/Modals/dialogs/AppearanceRemoveDialog";
import { dispatchRetainAppearanceDetailsEvent } from "@/Components/Common/Modals/dialogs/AppearanceRetainDialog";
import { dispatchLabelAppearanceDetailsEvent } from "@/Components/Common/Modals/dialogs/labelAppearance/AppearanceLabelDialog";
import { loadPlaybackDialog } from "@/Components/PlaybackDialog/playback.utils";
import { saveAlteredCaseEvent } from "src/appConfig/customEvents";
import ApiContext from "@/Logic/Context/apiContext";
import { getColorFromMatchData } from "@/Logic/ApiClients/WatchlistsClient";
import { createCameraAppearancesCase, createIdentifyAppearanceImageCase, createSearchAppearanceImageCase } from "@/Logic/Hooks/useCaseInjector";
import { addFaceToPoi, createNewPoi, removeAppearance, retainAppearance, reviewAppearance, showPlayback, unretainAppearance, searchInHistory, searchInPoiDb, proximityAppearances } from "src/appConfig/Strings";
import { formatUnixTimestamp, timeFilterFormat, TimeParsingString } from "@/Logic/Parsing/timeParsing";
import { screenStepsMapping } from "@/Components/Common/ProgressStepper/ProgressStepper.model";
import { createEditPoiFromImageHandler } from "@/Logic/ApiClients/POIClient";
import { labelAppearanceDialog, removeAppearanceDialog, retainAppearanceDialog } from "@/Logic/Hooks/useDialog/useDialog.model";

function HistoryListContextMenu({ row, tableType }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { clientsManager } = useContext(ApiContext);
    const { appearance_data: appearanceData, crop_data: cropData, match_data: matchData, retain: isAppearanceRetained, camera_data: cameraData } = row;
    const hasImage = useMemo(() => Boolean(cropData.face_crop_img), [cropData.face_crop_img]);

    const isLiveAnalysisMode = store.getState().ApiActionsStore.isLiveAnalysisMode;

    const triggerTogglePlayback = useCallback(() => {
        loadPlaybackDialog(clientsManager, appearanceData.appearance_id, matchData.poi_display_name, getColorFromMatchData(matchData), cameraData.camera_description, matchData.utc_time_matched);
    }, [appearanceData.appearance_id, cameraData.camera_description, clientsManager, matchData]);

    const setOptions = useCallback(() => {
        const appearanceId = appearanceData.appearance_id;
        const poiId = matchData?.poi_id;
        const options = {
            [createNewPoi]: () => createEditPoiFromImageHandler({ selectedSteps: screenStepsMapping.newPoiContextMenu, faceImage: cropData.face_crop_img }),
            ...(poiId ? { [addFaceToPoi]: () => createEditPoiFromImageHandler({ clientsManager, poiId, selectedSteps: screenStepsMapping.existingPoiContextMenu, faceImage: cropData.face_crop_img, url: `${location.pathname}?poiId=${poiId}`, navigate: navigate }) } : {}),
            ...(appearanceData.fs_store ? { [showPlayback]: triggerTogglePlayback } : {}),
            [searchInHistory]: () => document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent,
                { detail: { caseCreator: createSearchAppearanceImageCase, dataToChange: cropData.face_crop_img } })),
            [searchInPoiDb]: () => document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent,
                { detail: { caseCreator: createIdentifyAppearanceImageCase, dataToChange: cropData.face_crop_img } })),
            [proximityAppearances]: () => {
                document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent, {
                    detail: {
                        caseCreator: createCameraAppearancesCase,
                        dataToChange: {
                            cameraId: row.camera_data.camera_id,
                            appearTimeStarted: formatUnixTimestamp(row.appearance_data.utc_time_started, isLiveAnalysisMode ? timeFilterFormat : TimeParsingString, !isLiveAnalysisMode),
                            appearTimeEnded: formatUnixTimestamp(row.appearance_data.utc_time_ended || row.utc_time_recorded, isLiveAnalysisMode ? timeFilterFormat : TimeParsingString, !isLiveAnalysisMode)
                        }
                    }
                }));
            },
            [isAppearanceRetained ? unretainAppearance : retainAppearance]: () => {
                dispatchToggleDialogEvent(retainAppearanceDialog);
                dispatchRetainAppearanceDetailsEvent(appearanceId, tableType, isAppearanceRetained);
            },
            [reviewAppearance]: () => {
                dispatchToggleDialogEvent(labelAppearanceDialog);
                dispatchLabelAppearanceDetailsEvent(row, tableType);
            },
            [removeAppearance]: () => {
                dispatchToggleDialogEvent(removeAppearanceDialog);
                dispatchSetRemovedAppearanceDetailsEvent(appearanceData.appearance_id, tableType, isAppearanceRetained);
            },
        };

        return options;
    }, [appearanceData.appearance_id, appearanceData.fs_store, clientsManager, cropData.face_crop_img, isAppearanceRetained, isLiveAnalysisMode, location.pathname, matchData?.poi_id, navigate, row, tableType, triggerTogglePlayback]);

    const disabledOptions = useMemo(() => {
        return {
            [createNewPoi]: () => !hasImage,
            [addFaceToPoi]: () => !hasImage,
            [searchInHistory]: () => !hasImage,
            [searchInPoiDb]: () => !hasImage,
        };
    }, [hasImage]);

    return (
        <ContextMenuButton options={setOptions()} disabledOptions={disabledOptions} />
    );
}

export default HistoryListContextMenu;