import { Alert, IconButton, Snackbar } from "@mui/material";

import { ReactComponent as IconClose } from '../../../../assets/Icons/Modal/icon_close.svg';

function WorkerProgressPopup({ isOpen, children, onClose, headerText, anchorOrigin = { vertical: 'bottom', horizontal: 'right' } }) {
    return (
        <Snackbar open={isOpen} anchorOrigin={anchorOrigin} >
            <Alert icon={false} severity="info" className="border border-pie-sky-blue bg-white">
                <div className="flex flex-col items-center justify-center text-dark-charcoal">
                    <IconButton id="icon-btn" className="self-end p-1" onClick={onClose}>
                        <IconClose className="stroke-black" />
                    </IconButton>
                    <span className="modal-alert-title flex items-center">
                        {headerText}
                    </span>
                    {children}
                </div>
            </Alert>
        </Snackbar >
    );
}

export default WorkerProgressPopup;