import { useCallback } from "react";

import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";
import { auditStorageLimitation, edit, historyStorageAutoClear, historyStorageLimitation } from "src/appConfig/Strings";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { auditAutoClearDialogId, dataLongevityDialogId, historyAutoClearDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";

const editDialogsMap = {
    [historyStorageLimitation]: () => dispatchToggleDialogEvent(dataLongevityDialogId),
    [auditStorageLimitation]: () => dispatchToggleDialogEvent(auditAutoClearDialogId),
    [historyStorageAutoClear]: () => dispatchToggleDialogEvent(historyAutoClearDialogId),
};

function DataRetentionConfigContextMenu({ row }) {
    const setOptions = useCallback(() => {
        const options = { [edit]: editDialogsMap[row.name] };
        return options;
    }, [row.name]);

    return (
        <ContextMenuButton options={setOptions()} />
    );
}

export default DataRetentionConfigContextMenu;