import { Box } from "@mui/material";
import Grid from '@mui/material/Grid';

import GridItem from "./GridItem";

function BaseGrid({ items, renderedItems, className, gridSpacing = 0.8, gridClassName }) {
    if (!items && !renderedItems) {
        return null;
    }

    return (
        <Box className={`grow overflow-auto ${className}`}>
            <Grid container spacing={gridSpacing} className={gridClassName}>
                {renderedItems ? renderedItems : (
                    items.map((item, index) => (
                        <GridItem key={index}>
                            {item}
                        </GridItem>
                    ))
                )}
            </Grid>
        </Box>
    );
}

export default BaseGrid;