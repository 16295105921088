"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The RepeatedIndividualConfig model module.
 * @module model/RepeatedIndividualConfig
 * @version 0.37.0
 */
class RepeatedIndividualConfig {
  /**
   * Constructs a new <code>RepeatedIndividualConfig</code>.
   * @alias module:model/RepeatedIndividualConfig
   * @param alertingNumberOfAppearances {Object} 
   */
  constructor(alertingNumberOfAppearances) {
    RepeatedIndividualConfig.initialize(this, alertingNumberOfAppearances);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, alertingNumberOfAppearances) {
    obj['alerting_number_of_appearances'] = alertingNumberOfAppearances;
  }

  /**
   * Constructs a <code>RepeatedIndividualConfig</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RepeatedIndividualConfig} obj Optional instance to populate.
   * @return {module:model/RepeatedIndividualConfig} The populated <code>RepeatedIndividualConfig</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RepeatedIndividualConfig();
      if (data.hasOwnProperty('camera_ids')) {
        obj['camera_ids'] = _ApiClient.default.convertToType(data['camera_ids'], Object);
      }
      if (data.hasOwnProperty('videos_ids')) {
        obj['videos_ids'] = _ApiClient.default.convertToType(data['videos_ids'], Object);
      }
      if (data.hasOwnProperty('excluded_watchlists')) {
        obj['excluded_watchlists'] = _ApiClient.default.convertToType(data['excluded_watchlists'], Object);
      }
      if (data.hasOwnProperty('min_detection_width')) {
        obj['min_detection_width'] = _ApiClient.default.convertToType(data['min_detection_width'], Object);
      }
      if (data.hasOwnProperty('alerting_number_of_appearances')) {
        obj['alerting_number_of_appearances'] = _ApiClient.default.convertToType(data['alerting_number_of_appearances'], Object);
      }
      if (data.hasOwnProperty('alerting_time_frame')) {
        obj['alerting_time_frame'] = _ApiClient.default.convertToType(data['alerting_time_frame'], Object);
      }
      if (data.hasOwnProperty('not_alerting_time_frame')) {
        obj['not_alerting_time_frame'] = _ApiClient.default.convertToType(data['not_alerting_time_frame'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>RepeatedIndividualConfig</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RepeatedIndividualConfig</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of RepeatedIndividualConfig.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
RepeatedIndividualConfig.RequiredProperties = ["alerting_number_of_appearances"];

/**
 * Live cameras that should trigger the insight
 * @member {Object} camera_ids
 */
RepeatedIndividualConfig.prototype['camera_ids'] = undefined;

/**
 * Forensics videos that should trigger the insight
 * @member {Object} videos_ids
 */
RepeatedIndividualConfig.prototype['videos_ids'] = undefined;

/**
 * List of Watchlists to exclude from the insight
 * @member {Object} excluded_watchlists
 */
RepeatedIndividualConfig.prototype['excluded_watchlists'] = undefined;

/**
 * @member {Object} min_detection_width
 */
RepeatedIndividualConfig.prototype['min_detection_width'] = undefined;

/**
 * @member {Object} alerting_number_of_appearances
 */
RepeatedIndividualConfig.prototype['alerting_number_of_appearances'] = undefined;

/**
 * @member {Object} alerting_time_frame
 */
RepeatedIndividualConfig.prototype['alerting_time_frame'] = undefined;

/**
 * @member {Object} not_alerting_time_frame
 */
RepeatedIndividualConfig.prototype['not_alerting_time_frame'] = undefined;
var _default = exports.default = RepeatedIndividualConfig;