import { useCallback, useMemo } from "react";

import { LinearProgress } from "@mui/material";
import { connect } from "react-redux";

import store from "src/appConfig/configureStore";
import { closeFloatingModal, runnersNames } from "@/Logic/Redux/Stores/BaseRunnersStore";
import { calculatePercentage } from "@/Logic/Parsing/numbersParsing";
import CollapseDrawer from "../Common/Drawers/CollapseDrawer";
import { aborted, analyzed, analyzingVideos, errors, processCompleted, processInProgress, started, startingCameras, summary, total } from "src/appConfig/Strings";
import WorkerProgressPopup from "../Common/Modals/WorkerProgressPopup";

function StartAllCamerasProgress({ camerasStarter, isLiveAnalysisMode }) {
    const closeProgressBar = useCallback((event) => {
        event.preventDefault();
        store.dispatch(closeFloatingModal({
            runnerName: runnersNames.camerasStarter
        }));
    }, []);

    const normalizedProgress = useMemo(() => {
        const { finishedCameras = 0, abortedCameras = 0, errorCameras = 0 } = camerasStarter.progress ?? {};
        const totalCameras = finishedCameras + abortedCameras + errorCameras;

        return calculatePercentage(totalCameras, camerasStarter.sum);
    }, [camerasStarter.progress, camerasStarter.sum]);

    const { headerText, analytics } = useMemo(() => {
        const { finishedCameras = 0, abortedCameras = 0, errorCameras = 0 } = camerasStarter.progress ?? {};
        let headerText = "";
        const processStatus = camerasStarter.finished ? processCompleted : processInProgress;
        const analytics = [{ [aborted]: abortedCameras }, { [errors]: errorCameras }];

        if (isLiveAnalysisMode) {
            headerText = `${startingCameras} ${processStatus}`;
            analytics.splice(1, 0, { [started]: finishedCameras });
        } else {
            headerText = `${analyzingVideos} ${processStatus}`;
            analytics.splice(1, 0, { [analyzed]: finishedCameras });
        }

        return { headerText, analytics };
    }, [camerasStarter.progress, camerasStarter.finished, isLiveAnalysisMode]);

    return (
        <WorkerProgressPopup isOpen={camerasStarter.showFloatingModal} onClose={closeProgressBar} headerText={headerText}>
            <CollapseDrawer label={summary}>
                <div className="mb-2 flex flex-col items-center">
                    <span className="medium-txt mt-2 flex items-center">
                        {`${total}: ${camerasStarter.sum}`}
                    </span>
                    {analytics.map((analytic) => {
                        const [[label, value]] = Object.entries(analytic);
                        if (!value) {
                            return;
                        }

                        return (
                            <span key={label} className="medium-txt mt-2 flex items-center">
                                {`${label}: ${value}`}
                            </span>
                        );
                    })}
                </div>
            </CollapseDrawer>
            <LinearProgress value={normalizedProgress} variant="determinate" className="h-3 w-full rounded bg-gray-200 text-blue-800" />
        </WorkerProgressPopup>
    );
}

const mapStateToProps = (state) => {
    const camerasStarter = state.BaseRunnersStore.camerasStarter;
    const { isLiveAnalysisMode } = state.ApiActionsStore;

    return { camerasStarter, isLiveAnalysisMode };
};

export default connect(mapStateToProps, null)(StartAllCamerasProgress);