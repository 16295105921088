import { parseTimeObj } from "@/Logic/Parsing/timeParsing";

function InsightsSearchTimeColumn({ msg_send_timestamp: msgSendTimestamp }) {
    return (
        <div>
            {`${parseTimeObj(msgSendTimestamp, false, true)} ${parseTimeObj(msgSendTimestamp, false)}`}
        </div>
    );
}

export default InsightsSearchTimeColumn;