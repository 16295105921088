import { useCallback, useEffect, useMemo } from "react";

import _ from "lodash";
import { connect } from "react-redux";

import StreamDisplay from "./StreamDisplay";
import BaseLayout from "../Common/Layout/BaseLayout";
import { deselectDisplay, disabledDisplayInvestigate } from "../../../appConfig/Strings";
import { addDisplayStream, removeDisplayStream } from "../../Logic/Redux/Stores/GlobalStore";
import LockCheck from "../Common/UtilComponents/LockCheck";

export const emptyStreamOption = {
    description: deselectDisplay,
    display_rtsp_address: "",
    cameraId: null
};

function GridDisplay({ cameras, selectedDisplayStreams, addDisplayStreamAction, removeDisplayStreamAction, camerasStreamsIdsList, maxDisplayGridEnv, isLiveAnalysisMode }) {
    const camerasStreamsList = useMemo(() => Object.values(cameras).filter(camera => camera.display_rtsp_address), [cameras]);

    const addSelectedCamera = useCallback((cameraId, selection, index) => {
        addDisplayStreamAction(cameraId, selection, index);
    }, [addDisplayStreamAction]);

    const removeSelectedCamera = useCallback((displayIndex) => {
        removeDisplayStreamAction(displayIndex);
    }, [removeDisplayStreamAction]);

    const setDisplayStream = useCallback((streamIndex) => {
        return camerasStreamsList.find(camera => camera.display_rtsp_address === selectedDisplayStreams[streamIndex]);
    }, [camerasStreamsList, selectedDisplayStreams]);

    useEffect(() => {
        selectedDisplayStreams.forEach((stream, index) => {
            const camera = camerasStreamsList.find(camera => camerasStreamsIdsList[index] === camera.camera_id);
            if (camera && camera.display_rtsp_address !== stream) {
                removeSelectedCamera(index);
                addSelectedCamera(camera.camera_id, camera.display_rtsp_address, index);
            }
        });
    }, [addSelectedCamera, camerasStreamsIdsList, camerasStreamsList, removeSelectedCamera, selectedDisplayStreams]);

    return (
        <LockCheck statusMsgCheckFunc={() => !isLiveAnalysisMode && disabledDisplayInvestigate}>
            <BaseLayout>
                <div className="grid grid-cols-2 place-items-center">
                    {_.range(0, maxDisplayGridEnv).map((displayIndex) => {
                        return <StreamDisplay
                            key={`camera-display-${displayIndex}`}
                            camerasStreamsList={[...camerasStreamsList.filter(camera => !selectedDisplayStreams.includes(camera.display_rtsp_address)), emptyStreamOption]}
                            addSelectedCamera={(cameraId, selected) => addSelectedCamera(cameraId, selected, displayIndex)}
                            removeSelectedCamera={() => removeSelectedCamera(displayIndex)}
                            selectedCamera={setDisplayStream(displayIndex)} />;
                    })}
                </div>
            </BaseLayout>
        </LockCheck>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        addDisplayStreamAction: (cameraId, streamUrl, index) => dispatch(addDisplayStream({
            cameraId,
            streamUrl,
            index
        })),
        removeDisplayStreamAction: (index) => dispatch(removeDisplayStream({ index }))
    };
};

const mapStateToProps = (state, ownProps) => {
    const isLiveAnalysisMode = state.ApiActionsStore.isLiveAnalysisMode;
    const cameraId = ownProps.searchParams.get("cameraId");
    const cameras = state.CamerasStore.cameras;
    const selectedDisplayStreams = state.GlobalStore.selectedDisplayStreams;
    const camerasStreamsIdsList = state.GlobalStore.selectedCamerasIds;
    // const maxDisplayGridEnv = state.EnvStore?.REACT_ENV_MAX_DISPLAY_GRID || 4; // should be discussed
    const maxDisplayGridEnv = 4;

    return { cameras, cameraId, selectedDisplayStreams, camerasStreamsIdsList, maxDisplayGridEnv, isLiveAnalysisMode };
};


export default connect(mapStateToProps, mapDispatchToProps)(GridDisplay);