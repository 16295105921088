import { AnalysisModeApi, ApiClient } from "smart_cameras_cameras_api";

import BaseAPIClient from "./BaseAPIClient";
import store from "src/appConfig/configureStore";
import { setAnalysisMode } from "../Redux/Stores/ApiActionsStore";
import { camerasRoute } from "../Infrastructure/networkConf";

export const liveAnalysisMode = "live";
export const investigateAnalysisModeBE = "forensics";
export const investigateAnalysisMode = "investigate";

export default class AnalysisModeClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(camerasRoute, clientInstance);
        this.analysisModeApi = new AnalysisModeApi();
    }

    initialize() {
        this.getAnalysisMode();
    }

    async getAnalysisMode() {
        return this.apiCall("Get Analysis Mode",
            (callback) => this.analysisModeApi.getAnalysisMode(callback),
            (_, data) => store.dispatch(setAnalysisMode(data.data)));
    }

    async setAnalysisMode(isCurrentLive) {
        const analysisMode = { mode: isCurrentLive ? investigateAnalysisModeBE : liveAnalysisMode };
        return await this.apiCall("Set Analysis Mode",
            (callback) => this.analysisModeApi.setAnalysisMode(analysisMode, callback),
            () => this.getAnalysisMode());
    }
}
