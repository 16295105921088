"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The BoundingBox model module.
 * @module model/BoundingBox
 * @version 0.37.1
 */
class BoundingBox {
  /**
   * Constructs a new <code>BoundingBox</code>.
   * @alias module:model/BoundingBox
   * @param x1 {Object} 
   * @param y1 {Object} 
   * @param x2 {Object} 
   * @param y2 {Object} 
   */
  constructor(x1, y1, x2, y2) {
    BoundingBox.initialize(this, x1, y1, x2, y2);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, x1, y1, x2, y2) {
    obj['x1'] = x1;
    obj['y1'] = y1;
    obj['x2'] = x2;
    obj['y2'] = y2;
  }

  /**
   * Constructs a <code>BoundingBox</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BoundingBox} obj Optional instance to populate.
   * @return {module:model/BoundingBox} The populated <code>BoundingBox</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BoundingBox();
      if (data.hasOwnProperty('x1')) {
        obj['x1'] = _ApiClient.default.convertToType(data['x1'], Object);
      }
      if (data.hasOwnProperty('y1')) {
        obj['y1'] = _ApiClient.default.convertToType(data['y1'], Object);
      }
      if (data.hasOwnProperty('x2')) {
        obj['x2'] = _ApiClient.default.convertToType(data['x2'], Object);
      }
      if (data.hasOwnProperty('y2')) {
        obj['y2'] = _ApiClient.default.convertToType(data['y2'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>BoundingBox</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>BoundingBox</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of BoundingBox.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
BoundingBox.RequiredProperties = ["x1", "y1", "x2", "y2"];

/**
 * @member {Object} x1
 */
BoundingBox.prototype['x1'] = undefined;

/**
 * @member {Object} y1
 */
BoundingBox.prototype['y1'] = undefined;

/**
 * @member {Object} x2
 */
BoundingBox.prototype['x2'] = undefined;

/**
 * @member {Object} y2
 */
BoundingBox.prototype['y2'] = undefined;
var _default = exports.default = BoundingBox;