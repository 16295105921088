import {
    AddPOIFace,
    ApiClient,
    BatchPOIsRequest,
    ChangeWLPOIsRequest,
    CreateFacesRequest,
    CreatePOIData,
    FacesListRequest,
    GetPOIsRequest,
    ImagePayload,
    POIApi,
    POIConsentData,
    POIDatabaseApi
} from "smart_cameras_poi_db_api";

import { difference, isEmpty, isObject, find } from "lodash";
import moment from "moment";

import store from "src/appConfig/configureStore";
import BaseAPIClient, { resCodes } from "./BaseAPIClient";
import { poiDbRoute } from "../Infrastructure/networkConf";
import { collectMultiResponses, parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { poiPageSize, poiSearchMaxResults } from "src/appConfig/constants";
import { removePoiFromPoisPage, setOpenPoi, setOpenPOIFailedFacesData, setPoisPage } from "../Redux/Stores/POIStore";
import { dispatchStepperEvent } from "@/Components/Common/ProgressStepper/ProgressStepper";
import { toastUtility } from "../Hooks/useToast";
import { getPoisRequest } from "../Hooks/usePrepareCreateEditPoi";
import { poiDialogId } from "../Hooks/useDialog/useDialog.model";
import { dispatchToggleDialogEvent } from "../Hooks/useDialog/useDialog";

export const poiRequestObject = {
    pois: "pois",
    faces: "faces",
    force: "force",
    failOnMultipleFaces: "failOnMultipleFaces"
};

export default class POIClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(poiDbRoute, clientInstance);
        this.poiApi = new POIApi();
        this.poiDatabaseApi = new POIDatabaseApi();
    }

    parsedCreatePoiObject(poiObject, detect = false) {
        const { display_name, display_img, poi_notes, poi_consent, poi_watchlists, faces, failOnMultipleFaces } = poiObject;
        const poiUpdateData = new CreatePOIData();
        parsePoiDisplayImage(poiUpdateData, display_img);
        parsePoiDisplayName(poiUpdateData, display_name);
        parseCreatePoiWatchlists(poiUpdateData, poi_watchlists);
        if (poi_notes !== "") {
            parsePoiNotes(poiUpdateData, poi_notes); //todo refactor after notesBox component modification (phase 2)
        }
        parsePoiConsent(poiUpdateData, poi_consent);
        const force = poiObject?.force;
        const options = { force, failOnMultipleFaces, detect };
        const { parsedFaces } = getPoiParsedFaces(poiUpdateData, faces, options);
        const facesExcludingFirst = parsedFaces.slice(1);
        const parsedPoi = { pois: [poiUpdateData] };

        return {
            [poiRequestObject.pois]: parsedPoi,
            [poiRequestObject.faces]: facesExcludingFirst,
            [poiRequestObject.force]: force,
            [poiRequestObject.failOnMultipleFaces]: failOnMultipleFaces
        };
    }

    #parsedUpdatePoiObject(poiObject, reduxStoredOpenPoi) {
        const { faces, poi_watchlists, poi_notes, poi_consent, display_name, display_img } = poiObject;
        const poiUpdateData = {};
        const { removedFaces, addedFaces } = preparePoiFaces(faces, reduxStoredOpenPoi);
        const { removedWatchlists, addedWatchlists } = parseEditPoiWatchlists(poi_watchlists, reduxStoredOpenPoi);
        parsePoiDisplayImage(poiUpdateData, display_img);
        parsePoiDisplayName(poiUpdateData, display_name);
        parsePoiNotes(poiUpdateData, poi_notes);
        parsePoiConsent(poiUpdateData, poi_consent);
        const updatedResData = {
            removedFaces,
            addedFaces,
            removedWatchlists,
            addedWatchlists,
            poiUpdateData,
            force: poiObject?.force
        };

        return updatedResData;
    }

    handleFailedFaces(facesList, addFacesResponse) {
        if (!addFacesResponse?.data) {
            return;
        }

        const successList = addFacesResponse?.data?.metadata?.success_list || [];

        const failedFaces = facesList.reduce((failed, face, index) => {
            const faceImage = isObject(face) ? face.img : face;
            const { success = false, msg = "" } = successList[index] || {};

            if (!success) {
                const faceId = face.face_id;
                failed[faceId] = { image: faceImage, status: success, message: msg };
            }

            return failed;
        }, {});

        if (!isEmpty(failedFaces)) {
            store.dispatch(setOpenPOIFailedFacesData(failedFaces));
        }
    }

    async handleSuccessCreatePoi(response, parsedFaces, force, failOnMultipleFaces) {
        const poiData = response.body.data.pois[0];
        const addFacesResponse = await this.addFacesToPoi(poiData.poi_id, parsedFaces, {
            force,
            failOnMultipleFaces
        }, {});

        this.handleFailedFaces(parsedFaces, addFacesResponse);
    }

    async handleSuccessGetPoi(getPoiResponseData) {
        const { success_list } = getPoiResponseData.metadata;
        const firstFailure = find(success_list, { success: false });
        if (firstFailure) {
            toastUtility.showError(firstFailure.msg);
            return false;
        }
        const poisObj = await this.serializeApiData(getPoiResponseData.data.pois, (poi) => poi.poi_id);

        return poisObj;
    }

    createPoiRequestOptions(filtered_poi) {
        const poiData = new GetPOIsRequest(filtered_poi);
        poiData.get_crops = true;
        poiData.get_faces = true;

        return poiData;
    }

    async getAllPois(afterId = "") {
        const reqOptions = { countPois: true, afterId, limit: poiPageSize };

        return await this.apiCall("List all POIs",
            (callback) => this.poiApi.listAllPOIs(reqOptions, callback),
            (error, data, response) => {
                const payload = {
                    ids: data.data.pois.map((poi) => poi.poi_id),
                    totalPois: data.data.total_pois
                };

                return payload;
            });
    }

    async getPois(endpointName, poisIds, onSuccess) {
        const poisData = this.createPoiRequestOptions(poisIds);

        return await this.apiCall(endpointName,
            (callback) => this.poiApi.getPOIs(poisData, callback),
            async (_, data) => {
                const poisObj = await this.handleSuccessGetPoi(data);
                if (!poisObj) {
                    return false;
                }
                await onSuccess(poisObj);

                return true;
            }
        );
    }

    async getWatchlistPois(poisIds, totalPois) {
        if (isEmpty(poisIds)) {
            store.dispatch(setPoisPage({ poisData: {}, totalPois: 0 }));
            return;
        }

        return await this.getPois("Get Watchlist POIS", poisIds, async (poisObj) => {
            store.dispatch(setPoisPage({ poisData: poisObj, totalPois }));
        });
    }

    async getSinglePoi(poiId) {
        return await this.getPois("Get POIS", [poiId], async (poisObj) => {
            const payload = { poiData: Object.values(poisObj)[0] };
            store.dispatch(setOpenPoi(payload));
        });
    }

    async addFacesToPoi(poiId, faces, options, callbackMap = null) {
        if (!faces.length) {
            return;
        }
        const preparedFaces = addFacesPrepareHandler(faces, options);
        const addFacesToPOIResponse = await this.apiCall("Add Faces To POI",
            (callback) => this.poiApi.addFacesToPOI(poiId, preparedFaces, callback),
            (error, data, response, endPointName) => ({ response: this.baseUIResponse(error, response, undefined, true, endPointName), data }),
            callbackMap || {
                [resCodes.badRequest]: (error, __, response) => ({ response: this.baseUIResponse(error, response) }),
                [resCodes.notFound]: (error, __, response) => ({ response: this.baseUIResponse(error, response) }),
                [resCodes.unauthorized]: (error, __, response) => ({ response: this.baseUIResponse(error, response) }),
                [resCodes.badRequest]: (error, _, response) => ({ response: this.baseUIResponse(error, response) }),
                [resCodes.forbidden]: (error, _, response) => ({ response: this.baseUIResponse(error, response) }),
                [resCodes.unprocessable]: (error, _, response) => ({ response: this.baseUIResponse(error, response) }),
                [resCodes.internalServerError]: (error, _, response) => ({ response: this.baseUIResponse(error, response) }),
                [resCodes.noResponse]: (error, _, response) => ({ response: this.baseUIResponse(error, response) })
            });

        return addFacesToPOIResponse;
    }

    async removeFacesFromPoi(poiId, removedFaces) {
        if (!removedFaces.length) {
            return;
        }
        const request = new FacesListRequest(removedFaces);

        return await this.apiCall("Remove Faces From POI",
            (callback) => this.poiApi.removeFacesFromPOI(poiId, request, callback),
            (error, __, response, endPointName) => parseResponseForUI(error, response, undefined, true, endPointName)
        );
    }

    async updatePoiData(poiId, poiUpdateData) {
        return await this.apiCall("Edit POI",
            (callback) => this.poiApi.updatePOI(poiId, poiUpdateData, callback),
            (error, __, response, endPointName) => parseResponseForUI(error, response, undefined, true, endPointName)
        );
    }

    async createPoi(poi) {
        const { pois: parsedPois, faces: parsedFaces, force, failOnMultipleFaces } = this.parsedCreatePoiObject(poi);
        const createPOIResponse = await this.apiCall(
            "Create POI",
            (callback) => this.poiApi.addPOIs(parsedPois, callback),
            async (error, __, response) => {
                await this.handleSuccessCreatePoi(response, parsedFaces, force, failOnMultipleFaces);
                return parseResponseForUI(error, response, response.body.data);
            }
        );

        if (createPOIResponse?.statusCode === resCodes.ok && createPOIResponse?.error) {
            toastUtility.showError(createPOIResponse?.msg);
        }

        return createPOIResponse;
    }

    async editPoi(poiObject, reduxStoredOpenPoi, poiId) {
        const { poiUpdateData, removedWatchlists, addedWatchlists, removedFaces, addedFaces, force } = this.#parsedUpdatePoiObject(poiObject, reduxStoredOpenPoi);

        const editPoiResponse = !isEmpty(poiUpdateData) && await this.updatePoiData(poiId, poiUpdateData);
        const addWatchlistsResponses = !isEmpty(addedWatchlists) ? await Promise.all(addedWatchlists.map((watchlist) => this.addPoisToWatchlist(watchlist, [poiId]))) : [];
        const removeWatchlistsResponses = !isEmpty(removedWatchlists) ? await Promise.all(removedWatchlists.map((watchlist) => this.removePoisFromWatchlist(watchlist, [poiId]))) : [];
        let removeFacesResponse;
        let addFacesResponse;

        if (!isEmpty(removedFaces)) {
            removeFacesResponse = await this.removeFacesFromPoi(poiId, removedFaces);
            removeFacesResponse?.error && toastUtility.showError(removeFacesResponse.msg);
        }
        if (!isEmpty(addedFaces)) {
            addFacesResponse = await this.addFacesToPoi(poiId, addedFaces, { force }, {});
            addFacesResponse?.response?.error && toastUtility.showError(addFacesResponse.response.msg);
        }

        const responsesList = [
            editPoiResponse,
            ...addWatchlistsResponses,
            ...removeWatchlistsResponses,
            removeFacesResponse,
            addFacesResponse?.response
        ];

        const finalResponse = collectMultiResponses(responsesList);
        this.handleFailedFaces(addedFaces, addFacesResponse);

        return finalResponse;
    }

    async addPoisToWatchlist(watchlistId, pois) {
        const request = new ChangeWLPOIsRequest(watchlistId, pois);

        return this.apiCall("Add POIs To Watchlist",
            (callback) => this.poiApi.addPOIToWatchlist(request, callback),
            (error, __, response, endPointName) => parseResponseForUI(error, response, undefined, true, endPointName)
        );
    }

    async removePoisFromWatchlist(watchlistId, pois) {
        const request = new ChangeWLPOIsRequest(watchlistId, pois);

        return this.apiCall("Remove POIs From Watchlist",
            (callback) => this.poiApi.removePOIFromWatchlist(request, callback),
            (error, __, response, endPointName) => parseResponseForUI(error, response, undefined, true, endPointName)
        );
    }

    async removePoi(removedPoiId) {
        const poisToRemove = new BatchPOIsRequest();
        poisToRemove.pois = [removedPoiId];
        await this.apiCall("Remove POIs from system",
            (callback) => this.poiApi.removePOIs(poisToRemove, callback),
            (error, __, response) => {
                store.dispatch(removePoiFromPoisPage({ updatedPoiId: removedPoiId }));
                parseResponseForUI(error, response);
            });
    }

    async searchPoiByName(poiName, watchlistId, maxMatches, shouldStore = false) {
        const request = { display_name: poiName, max_matches: (maxMatches || poiSearchMaxResults), watchlist_id: watchlistId };

        return await this.apiCall("Search POI by name",
            (callback) => this.poiApi.searchPOIs(request, callback),
            async (error, data, response) => {
                const { pois, total_pois } = data.data;
                let poisObj = {};
                if (!isEmpty(pois)) {
                    poisObj = await this.serializeApiData(data.data.pois, (poi) => poi.poi_id);
                }
                shouldStore && store.dispatch(setPoisPage({ poisData: poisObj, totalPois: total_pois }));

                return parseResponseForUI(error, response, data.data);
            }
        );
    }

    async clearPoiDb() {
        return await this.apiCall("Clear POI Db",
            (callback) => this.poiDatabaseApi.clearPOIDB(callback),
            (error, __, response) => {
                store.dispatch(setPoisPage({ poisData: {}, totalPois: 0 }));

                return parseResponseForUI(error, response);
            });
    }
}

export const parsePoiDisplayImage = (poiUpdateData, displayImage) => {
    if (displayImage === undefined) {
        return;
    } else if (displayImage === "") {
        displayImage = null;
    }
    poiUpdateData.display_img = displayImage;
};

export const parsePoiDisplayName = (poiUpdateData, displayName) => {
    if (displayName === undefined) {
        return;
    }
    poiUpdateData.display_name = displayName;
};

export const parsePoiNotes = (poiUpdateData, notesData) => {
    if (notesData === undefined) {
        return;
    }
    const notes = {
        free_notes: notesData
    };
    poiUpdateData.poi_notes = notes;
};

export const parsePoiConsent = (poiUpdateData, consentData) => {
    if (consentData === null || consentData === undefined) {
        return;
    }
    const consent = new POIConsentData();
    consent.consent = consentData;
    consent.review_time = moment().unix();
    poiUpdateData.poi_consent = consent;
};

export const parseCreatePoiWatchlists = (poiUpdateData, poiWatchlists) => {
    if (poiWatchlists === undefined) {
        return;
    }
    poiUpdateData.poi_watchlists = Array.from(poiWatchlists);
};

export const parseEditPoiWatchlists = (watchlistsData, reduxStoredOpenPoi) => {
    const removedWatchlists = new Set();
    const addedWatchlists = new Set();

    if (!watchlistsData || watchlistsData.size === 0) {
        return { removedWatchlists: Array.from(removedWatchlists), addedWatchlists: Array.from(addedWatchlists) };
    } else {
        reduxStoredOpenPoi?.poi_watchlists?.forEach((watchlist) => {
            if (!watchlistsData.has(watchlist)) {
                removedWatchlists.add(watchlist);
            }
        });
        watchlistsData.forEach((watchlist) => {
            if (!reduxStoredOpenPoi?.poi_watchlists?.has(watchlist)) {
                addedWatchlists.add(watchlist);
            }
        });

        return {
            removedWatchlists: Array.from(removedWatchlists),
            addedWatchlists: Array.from(addedWatchlists)
        };
    }
};

export const preparePoiFaces = (faces, reduxStoredOpenPoi) => {
    let removedFaces = [];
    let addedFaces = [];

    if (!faces) {
        return { removedFaces, addedFaces };
    }

    const facesIDs = Object.keys(faces);
    const reduxFacesIDsList = Object.keys(reduxStoredOpenPoi.faces || []);
    const reduxFacesIDsSet = new Set(reduxFacesIDsList);
    removedFaces = difference(reduxFacesIDsList, facesIDs);
    const filteredIDS = facesIDs.filter((faceID) => !reduxFacesIDsSet.has(faceID));
    addedFaces = filteredIDS.map((faceID) => faces[faceID]);

    return { removedFaces, addedFaces };
};

export const createImgPayload = (faceImg, options) => {
    const { force, failOnMultipleFaces, detect } = options;
    const parsedFace = new AddPOIFace();
    parsedFace.force = force;
    parsedFace.image_payload = new ImagePayload(faceImg);
    parsedFace.image_payload.fail_on_multiple_faces = failOnMultipleFaces;
    parsedFace.image_payload.detect = detect;

    return parsedFace;
};

const facesToArray = (faces) => Array.isArray(faces) ? faces : Object.values(faces);

const processFacesData = (faces, options) => {
    const facesArray = facesToArray(faces);
    const parsedFaces = facesArray.map(face => {
        return createImgPayload(face?.img || face, options);
    });

    return parsedFaces;
};

export const addFacesPrepareHandler = (faces, options) => {
    const parsedFaces = processFacesData(faces, options);
    const facesRequestData = new CreateFacesRequest(parsedFaces);

    return facesRequestData;
};

export const getPoiParsedFaces = (poiUpdateData, facesData, options) => {
    const { detect } = options;
    const facesArray = facesToArray(facesData);

    const parsedFaces = [];
    facesArray.forEach((face) => {
        parsedFaces.push(typeof face === 'string' ? { img: face, detect } : { ...face, detect });
    });
    poiUpdateData.face = createImgPayload(parsedFaces[0].img, options);

    return { parsedFaces };
};

export const createEditPoiFromImageHandler = async ({ clientsManager, poiId, selectedSteps, faceImage, url, navigate }) => {
    if (url) {
        const reqSucceed = await getPoisRequest(clientsManager, poiId);
        if (!reqSucceed) {
            return;
        }
        navigate(url);
    }
    dispatchToggleDialogEvent(poiDialogId);
    dispatchStepperEvent({
        initialedData: { finalStepData: faceImage },
        shouldOpenStepper: true,
        selectedSteps,
        handleExit: () => dispatchToggleDialogEvent(poiDialogId)
    });
};