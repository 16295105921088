import { connect } from "react-redux";

import { ToastContainer } from "react-toastify";

import { generalSettingsKeys } from "@/Logic/WebWorkers/IndexDBHandler/IndexDBHandler.model";

function ToastContainerWrapper({ isSnoozed }) {
    return (
        <ToastContainer className={isSnoozed && `invisible`} position="bottom-right" newestOnTop limit="2" />
    );
}

const mapStateToProps = (state) => {
    const isSnoozed = state.ApiActionsStore?.generalSettings[generalSettingsKeys.snoozeToasts];

    return { isSnoozed };
};

export default connect(mapStateToProps, null)(ToastContainerWrapper);