import { memo, useCallback, useEffect, useState } from "react";

import { ageGroupFilter, analysisModeFilter, camerasFilter, detectionFilter, genderFilter, historyFilters, livenessFilter, matchFilter, matchHeader, notMatchFilter, notMatchHeader, retainFilter, reviewedFilter, timeFilter, unauthorizedFilter, unauthorizedHeader } from "src/appConfig/Strings";
import { historyQueryModel } from "./HistoryQuery.model";
import RetentionFilter from "../RetentionFilter";
import ReviewedFilter from "../ReviewedFilter";
import { matchOutcomes, notInWlOutcome } from "@/Logic/ApiClients/EventsClient/EventsClient.model";
import AnalysisModeFilterOld, { analysisModeOptions } from "@/Components/Common/Filters/AnalysisModeFilterOld";
import CamerasFilter from "@/Components/Common/Filters/CamerasFilter";
import DetectionFilter from "@/Components/Common/Filters/DetectionFilter";
import FaceFeatureOutcomeFilter from "@/Components/Common/Filters/FaceFeatureOutcomeFilter";
import MatchOutcomeFilter from "@/Components/Common/Filters/MatchOutcomeFilter";
import TimeFilter from "@/Components/Common/Filters/TimeFilter";
import store from "src/appConfig/configureStore";
import { faceFeatureFilters } from "@/Components/Common/Filters/FilterOptions.model";
import { dateTimeType, timeType } from "@/Logic/Parsing/timeParsing";

export const defaultHistoryQueryValues = (defaultValue) => {
    const currentAnalysisMode = store.getState().ApiActionsStore.analysisMode;

    return ({
        [camerasFilter]: defaultValue?.[camerasFilter] || [null],
        [matchFilter]: defaultValue?.[matchFilter] || [null],
        [notMatchFilter]: defaultValue?.[notMatchFilter] || [null],
        [unauthorizedFilter]: defaultValue?.[unauthorizedFilter] || [null],
        [detectionFilter]: defaultValue?.[detectionFilter] || [null],
        [timeFilter]: defaultValue?.[timeFilter] || { start: "", end: "" },
        [ageGroupFilter]: defaultValue?.[ageGroupFilter] || [null],
        [livenessFilter]: defaultValue?.[livenessFilter] || [null],
        [genderFilter]: defaultValue?.[genderFilter] || [null],
        [retainFilter]: defaultValue?.[retainFilter] || [],
        [reviewedFilter]: defaultValue?.[reviewedFilter] || [null],
        [analysisModeFilter]: defaultValue?.[analysisModeFilter] || analysisModeOptions[currentAnalysisMode]
    });
};

function HistoryQuery({ defaultValue, onChange }) {
    const [queryState, setQueryState] = useState(defaultHistoryQueryValues(defaultValue));

    const isLiveAnalysisMode = store.getState().ApiActionsStore.isLiveAnalysisMode;

    const handleChange = useCallback((filterName, newValue) => {
        setQueryState((previousState) => ({ ...previousState, [filterName]: newValue }));
    }, []);

    useEffect(() => {
        onChange && onChange(queryState);
    }, [onChange, queryState]);

    return (
        <div className="flex flex-col items-center justify-start gap-2">
            <div className="flex flex-col flex-wrap justify-center gap-2">
                <TimeFilter
                    data={queryState.timeFilter}
                    setFunction={(newValue) => handleChange(timeFilter, newValue)}
                    filterType={historyFilters}
                    {...historyQueryModel.timeFilter}
                    format={isLiveAnalysisMode ? dateTimeType : timeType}
                />
                <CamerasFilter
                    data={queryState.camerasFilter}
                    setFunction={(newValue) => handleChange(camerasFilter, newValue)}
                    filterType={historyFilters}
                    {...historyQueryModel.camerasFilter}
                />
                <MatchOutcomeFilter
                    data={queryState.matchFilter}
                    filterType={historyFilters}
                    setFunction={(newValue) => handleChange(matchFilter, newValue)}
                    header={matchHeader}
                    outcome={matchOutcomes.matched}
                    {...historyQueryModel.matchOutcomeFilter}
                />
                <MatchOutcomeFilter
                    data={queryState.notMatchFilter}
                    filterType={historyFilters}
                    setFunction={(newValue) => handleChange(notMatchFilter, newValue)}
                    header={notMatchHeader}
                    outcome={matchOutcomes.matched}
                    {...historyQueryModel.matchOutcomeFilter}
                />
                <MatchOutcomeFilter
                    data={queryState.unauthorizedFilter}
                    filterType={historyFilters}
                    setFunction={(newValue) => handleChange(unauthorizedFilter, newValue)}
                    header={unauthorizedHeader}
                    outcome={matchOutcomes[notInWlOutcome]}
                    {...historyQueryModel.matchOutcomeFilter}
                />
                <DetectionFilter
                    data={queryState.detectionFilter}
                    setFunction={(newValue) => handleChange(detectionFilter, newValue)}
                    filterType={historyFilters}
                    {...historyQueryModel.matchOutcomeFilter}
                />
                <FaceFeatureOutcomeFilter
                    data={queryState.ageGroupFilter}
                    filterType={historyFilters}
                    setFunction={(newValue) => handleChange(ageGroupFilter, newValue)}
                    {...faceFeatureFilters[ageGroupFilter]}
                    {...historyQueryModel.ageGroupFilter}
                />
                <FaceFeatureOutcomeFilter
                    data={queryState.livenessFilter}
                    filterType={historyFilters}
                    setFunction={(newValue) => handleChange(livenessFilter, newValue)}
                    {...faceFeatureFilters[livenessFilter]}
                    {...historyQueryModel.livenessFilter}
                />
                <FaceFeatureOutcomeFilter
                    data={queryState.genderFilter}
                    filterType={historyFilters}
                    setFunction={(newValue) => handleChange(genderFilter, newValue)}
                    {...faceFeatureFilters[genderFilter]}
                    {...historyQueryModel.genderFilter}
                />
                <ReviewedFilter
                    data={queryState.reviewedFilter}
                    setFunction={(newValue) => handleChange(reviewedFilter, newValue)}
                    {...historyQueryModel.reviewedFilter}
                />
                <RetentionFilter
                    data={queryState.retainFilter}
                    setFunction={(newValue) => handleChange(retainFilter, newValue)}
                    {...historyQueryModel.retainFilter}
                />
                <AnalysisModeFilterOld
                    data={queryState.analysisModeFilter}
                    setFunction={(newValue) => handleChange(analysisModeFilter, newValue)}
                    {...historyQueryModel.analysisModeFilter}
                />
            </div>
        </div>
    );
}

export default memo(HistoryQuery);