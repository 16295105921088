import { combineReducers } from "redux";

import { configureStore } from "@reduxjs/toolkit";
import { instanceToPlain } from 'class-transformer';

import { actionTypes } from "../app/Logic/Redux/Stores/EventsStore";
import reducers from "../app/Logic/Redux/reducers";

const BATCH_SIZE = 100;
const BATCH_TIMER_INTERVAL = 100;

const throttleMiddleware = (batchSize, timeInterval) => {
    const eventsBuffer = [];
    const generativeInsightsEventsBuffer = [];
    const eventsBatchTimerId = null;
    const generativeInsightsEventsBatchTimerId = null;

    const handleBufferAndBatch = (buffer, timerId, addActionType, payload, store) => {
        buffer.push(payload);
        if (buffer.length >= batchSize || timerId === null) {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                if (buffer.length > 0) {
                    store.dispatch({ type: addActionType, payload: [...buffer] });
                    buffer.length = 0;
                }
                timerId = null;
            }, timeInterval);
        }
    };

    return store => next => action => {
        if (action.type === actionTypes.addEvent) {
            handleBufferAndBatch(eventsBuffer, eventsBatchTimerId, actionTypes.addEvents, action.payload, store);
        } else if (action.type === actionTypes.addGenerativeInsightsEvent) {
            handleBufferAndBatch(generativeInsightsEventsBuffer, generativeInsightsEventsBatchTimerId, actionTypes.addGenerativeInsightsEvents, action.payload, store);
        } else {
            next(action);
        }
    };
};

const classToPlainMiddleware = () => (next) => (action) => {
    if (action.payload && typeof action.payload === 'object') {
        action.payload = instanceToPlain(action.payload);
    }
    next(action);
};

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(throttleMiddleware(BATCH_SIZE, BATCH_TIMER_INTERVAL), classToPlainMiddleware),
    devTools: false
});
export default store;