import { useContext, useMemo } from "react";

import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";

import ApiContext from "@/Logic/Context/apiContext";
import store from "src/appConfig/configureStore";
import { ActionColumnsDefinitions, ColumnsDefinitions } from "./Columns/ColumnsDefinitions";
import ExportReportButton from "../Buttons/ExportReportButton";
import { TableReportClient } from "@/Logic/ApiClients/TableReportClient";
import AnalyticsClass from "../Analytics/AnalyticsClass";
import { analyticsModel } from "../Analytics/Analytics.model";

export const eventTable = "eventTable";
export const alertsTable = "alertsTable";
export const historyAppearanceTable = "historyAppearanceTable";
export const historyImgAppearanceTable = "historyImgAppearanceTable";
export const historyInsightsTable = "historyInsightsTable";
export const insightAppearanceTable = "insightAppearanceTable";

export const generateGridUiColumns = (headers, actionHeader, tableType) => {
    const columns = headers.map(({ name, props }) => {
        return props ? ColumnsDefinitions[name](props) : ColumnsDefinitions[name];
    });

    if (actionHeader) {
        columns.push(tableType ? ActionColumnsDefinitions[actionHeader](tableType) : ActionColumnsDefinitions[actionHeader]);
    }

    return columns;
};

export const GenerateToolbar = ({ tableName, tableCategory = null, columnsHeaders, rows, filtersData = null, shouldExportAnalytics = false, shouldShowExport = true, shouldShowFilters = true, customBtn, children }) => {
    const { clientsManager } = useContext(ApiContext);
    const reportClient = useMemo(() => new TableReportClient(clientsManager), [clientsManager]); // todo: try make this new instance part of client manager

    return (
        <GridToolbarContainer>
            {customBtn && (customBtn)}
            {shouldShowFilters && (
                <GridToolbarFilterButton className="normal-case" />
            )}
            {shouldShowExport && (
                <ExportReportButton
                    onClick={() => {
                        reportClient.GenerateReportName(tableName);
                        reportClient.GenerateReportObject({ columnsHeaders, rows, filtersData, tableCategory, shouldExportAnalytics });
                        reportClient.StartGenerateReport();
                    }}
                />
            )}
            {children}
        </GridToolbarContainer>
    );
};

export const getEventsAnalytics = () => {
    const getStoreSate = store.getState();
    const defaultFeatureOutcome = getStoreSate.GlobalStore.featureOutcome.feature_outcomes;
    const uncertaintyOptions = getStoreSate.GlobalStore.featureOutcome.uncertainty_options;
    const eventsAnalytics = getStoreSate.AnalyticsStore.eventsAnalytics;

    return { eventsAnalytics, defaultFeatureOutcome, uncertaintyOptions };
};

export const getHistoryAnalytics = () => {
    const getStoreSate = store.getState();
    const historyAnalytics = getStoreSate.AnalyticsStore.historyAnalytics || [];

    if (!historyAnalytics || !Object.keys(historyAnalytics).length) {
        return {};
    }

    const defaultFeatureOutcome = getStoreSate.GlobalStore.featureOutcome.feature_outcomes;
    const uncertaintyOptions = getStoreSate.GlobalStore.featureOutcome.uncertainty_options;
    const analyticsData = new AnalyticsClass(historyAppearanceTable, Object.values(historyAnalytics), defaultFeatureOutcome, uncertaintyOptions, historyAnalytics);

    return { analyticsData, defaultFeatureOutcome };
};

export const parseTableAnalytics = {
    [eventTable]: () => {
        const { eventsAnalytics, defaultFeatureOutcome, uncertaintyOptions } = getEventsAnalytics();

        return analyticsModel(eventTable, eventsAnalytics?.analytics, defaultFeatureOutcome, uncertaintyOptions);
    },
    [historyAppearanceTable]: () => {
        const { analyticsData, defaultFeatureOutcome } = getHistoryAnalytics();

        return analyticsModel(historyAppearanceTable, analyticsData?.analytics, defaultFeatureOutcome);
    },
    [historyInsightsTable]: () => {
        const { analyticsData, defaultFeatureOutcome } = getHistoryAnalytics();

        return analyticsModel(historyInsightsTable, analyticsData?.analytics, defaultFeatureOutcome);
    },
    [historyImgAppearanceTable]: () => {
        const { analyticsData, defaultFeatureOutcome } = getHistoryAnalytics();

        return analyticsModel(historyImgAppearanceTable, analyticsData?.analytics, defaultFeatureOutcome);
    }
};