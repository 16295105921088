import { useCallback, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import SearchCaseClient from "../ApiClients/SearchCaseClient";
import { routesUrl } from "../../Components/Common/Layout/SideBar/Routes";
import { appearancesFilters, camerasFilter, historyDB, historyDBQuery, image, matchParameters, poiDB, timeFilter, watchlists } from "src/appConfig/Strings";
import { defaultHistoryQueryValues } from "../../Components/Cases/Inputs/Filters/HistoryQuery/HistoryQuery";
import { saveAlteredCaseEvent } from "src/appConfig/customEvents";

export const navigateToCase = ({ navigate, caseID, isTemporary }) => {
    const searchParams = isTemporary ? `tempCaseID=${uuid()}` : `caseID=${caseID}`;
    navigate(`${routesUrl.casesScreen}?${searchParams}`);
};

function useCaseInjector() {
    const navigate = useNavigate();

    const saveAndNavigateToCase = useCallback((event) => {
        const { caseCreator, dataToChange } = event.detail;
        const newCaseData = caseCreator(dataToChange);
        SearchCaseClient.saveTempCase(newCaseData);
        navigateToCase({ navigate, isTemporary: true });
    }, [navigate]);

    useEffect(() => {
        document.addEventListener(saveAlteredCaseEvent, saveAndNavigateToCase);

        return () => {
            document.removeEventListener(saveAlteredCaseEvent, saveAndNavigateToCase);
        };
    }, [saveAndNavigateToCase]);
}

export function createCameraAppearancesCase(cameraData) {
    const { cameraId, appearTimeStarted, appearTimeEnded } = cameraData;

    return [
        {
            optionName: appearancesFilters,
            final: true
        },
        {
            optionName: historyDB,
            final: true
        },
        {
            optionName: historyDBQuery,
            final: defaultHistoryQueryValues({
                [timeFilter]: {
                    start: appearTimeStarted,
                    end: appearTimeEnded
                },
                [camerasFilter]: [cameraId]
            })
        }
    ];
}

export function createSearchAppearanceImageCase(imageToSearch) {
    return [
        {
            optionName: image,
            final: imageToSearch
        },
        {
            optionName: historyDB,
            final: true
        },
        {
            optionName: matchParameters,
            final: {}
        },
        {
            optionName: historyDBQuery,
            final: defaultHistoryQueryValues()
        }
    ];
}


export function createIdentifyAppearanceImageCase(imageToSearch) {
    return [
        {
            optionName: image,
            final: imageToSearch
        },
        {
            optionName: poiDB,
            final: true
        },
        {
            optionName: matchParameters,
            final: {}
        },
        {
            optionName: watchlists,
            final: [null]
        },
    ];
}

export default useCaseInjector;