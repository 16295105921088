import { formatImageToDataURL } from "@/Logic/Parsing/parseImages";
import { useEffect, useRef, useState } from "react";

import defaultFaceCrop from "src/assets/icon_default_poi.svg";

const drawImageOnCanvas = (image, canvas, bbox) => {
    const context = canvas.getContext("2d");

    if (bbox) {
        canvas.width = bbox.x2 - bbox.x1;
        canvas.height = bbox.y2 - bbox.y1;
        context.drawImage(
            image,
            bbox.x1, bbox.y1,
            bbox.x2 - bbox.x1, bbox.y2 - bbox.y1,
            0, 0,
            canvas.width, canvas.height
        );
    } else {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(
            image,
            0, 0,
            image.width, image.height
        );
    }
};

function FaceBoundingBoxCanvas({ image, bbox }) {
    const canvasRef = useRef(null);
    const [imageForCanvas, setImageForCanvas] = useState(null);

    useEffect(() => {
        if (!imageForCanvas) {
            const imageCanvas = new Image();
            imageCanvas.src = image ? formatImageToDataURL(image) : defaultFaceCrop;
            imageCanvas.onload = () => {
                setImageForCanvas(imageCanvas);
            };
        } else {
            drawImageOnCanvas(imageForCanvas, canvasRef.current, bbox);
        }
    }, [image, imageForCanvas, bbox]);

    return <canvas className="m-1 h-crop-img-container-height w-crop-img-container-width rounded bg-charcoal-blue object-contain" ref={canvasRef} />;
}

export default FaceBoundingBoxCanvas;