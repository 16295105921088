import { useCallback } from "react";

import { formatImageToDataURL } from "@/Logic/Parsing/parseImages";

import { ReactComponent as DefaultFaceCrop } from "src/assets/icon_default_poi.svg";

function CropImage({ srcImage, cropImageClassName, isFormatted = false, defaultFaceCropClassName, ...props }) {
    const imageSrc = srcImage ? (isFormatted ? srcImage : formatImageToDataURL(srcImage)) : null;

    const handleError = useCallback((event) => {
        event.target.onerror = null;
        event.target.src = '';
    }, []);

    return (
        imageSrc ? (
            <img
                className={`m-1 h-crop-img-container-height w-crop-img-container-width rounded bg-charcoal-blue object-contain ${cropImageClassName}`}
                alt=""
                draggable="false"
                src={imageSrc}
                onError={handleError}
                {...props}
            />
        ) : (
            <DefaultFaceCrop className={`m-1 h-crop-img-container-height w-crop-img-container-width rounded bg-transparent fill-neutral-400 object-contain ${defaultFaceCropClassName}`} />
        )
    );
}

export default CropImage;