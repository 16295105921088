import { investigateAnalysisModeBE } from "@/Logic/ApiClients/AnalysisModeClient";
import { getIsCameraInInvestigateMode } from "@/Logic/ApiClients/CamerasClient";
import { parseTimeObj } from "@/Logic/Parsing/timeParsing";
import { isCurrWlTypeWhitelist } from "../CropImagesColumns/CropImagesColumns.utils";

export const formatTimeRecorded = (row, mostSignificantMatchWl) => {
    let formattedTime;
    const isInvestigate = getIsCameraInInvestigateMode(row.camera_data?.analysis_mode, investigateAnalysisModeBE);
    const timestamp = isCurrWlTypeWhitelist(mostSignificantMatchWl) ? row.crop_data.frame_timestamp : row.match_data?.utc_time_matched;
    const timeRecorded = parseTimeObj(timestamp, isInvestigate);

    if (!isInvestigate) {
        const dateRecorded = parseTimeObj(timestamp, isInvestigate, true);
        formattedTime = `${timeRecorded}, ${dateRecorded}`;
    } else {
        formattedTime = timeRecorded;
    }

    return formattedTime;
};

export const formatTimeRange = (utcTimeStarted, utcTimeEnded, frameUtcTimeRecorded, utcTimeRecorded, isInvestigate) => {
    const startTime = parseTimeObj(utcTimeStarted, isInvestigate);
    const endTime = utcTimeEnded ? parseTimeObj(utcTimeEnded, isInvestigate) : parseTimeObj(frameUtcTimeRecorded || utcTimeRecorded, isInvestigate);

    return `${startTime} ${startTime && "-"} ${endTime}`;
};

export const processDataForTimeColumn = (data) => {
    const isInvestigate = getIsCameraInInvestigateMode(data.camera_data?.analysis_mode, investigateAnalysisModeBE);
    const timeRange = formatTimeRange(data.appearance_data?.utc_time_started, data.appearance_data?.utc_time_ended, data.frame_data?.utc_time_recorded, data.utc_time_recorded, isInvestigate);
    const formattedDate = parseTimeObj(data.appearance_data?.utc_time_started, false, true);

    return {
        timeRange,
        date: formattedDate,
        isInvestigate
    };
};