import { useCallback } from "react";

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

function RadioInput({ className, labelClassName, options, onChange, model, value = "", isDisabled }) {
    const handleChange = useCallback((event) => {
        onChange && onChange(event);
    }, [onChange]);

    return (
        <FormControl className={className}>
            <RadioGroup row value={value} onChange={handleChange} {...model}>
                {options?.map(({ label, option, isOptionDisabled = false }) => (
                    <FormControlLabel
                        classes={{ label: `label-input whitespace-pre-line ${className}` }}
                        key={option}
                        value={option}
                        control={<Radio className={className} value={option} size="small" inputProps={{ 'data-testid': label }} />}
                        label={label}
                        disabled={isDisabled || isOptionDisabled}
                        className={labelClassName}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default RadioInput;