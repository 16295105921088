import { useMemo } from "react";

import { active, error, idle, reconnecting } from "src/appConfig/Strings";
import { cameraStatuses } from "@/Logic/ApiClients/CamerasClient";
import StatusColumn from "./StatusColumn";

export const priorityTextMapping = {
    1: active,
    2: `${reconnecting}...`,
    3: error,
    4: idle
};

const activeProps = { colorClass: "bg-green-500", text: priorityTextMapping[1], priority: 1 };
const reconnectingProps = { colorClass: "bg-yellow-400", text: priorityTextMapping[2], priority: 2 };
const errorProps = { colorClass: "bg-red-600", text: priorityTextMapping[3], priority: 3 };
const idleProps = { colorClass: "bg-gray-400", text: priorityTextMapping[4], priority: 4 };

export const statusToPropsMap = {
    [cameraStatuses.active]: activeProps,
    [cameraStatuses.error_stopping]: errorProps,
    [cameraStatuses.attempting_reconnect]: reconnectingProps,
    [cameraStatuses.analyze_timeout]: errorProps,
    [cameraStatuses.input_error]: errorProps,
    [cameraStatuses.error_starting]: errorProps,
    [cameraStatuses.non_responsive]: errorProps,
    [cameraStatuses.serverError]: errorProps,
    [cameraStatuses.idle]: idleProps,
    [cameraStatuses.aborted]: idleProps,
    [cameraStatuses.EOF]: idleProps,
    [cameraStatuses.display]: idleProps,
};

function CameraStatusColumn({ camera_status: cameraStatus }) {
    const statusProps = useMemo(() => statusToPropsMap[cameraStatus.status] || idleProps, [cameraStatus.status]);

    return (
        <div>
            <span className="flex items-center">
                <StatusColumn filledColor={statusProps.colorClass} />
                <div className="data-txt text-white">
                    {statusProps.text}
                </div>
            </span>
        </div>
    );
}

export default CameraStatusColumn;