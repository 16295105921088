import { useCallback, useEffect, useState } from "react";

import Slider from "rc-slider";
import { Col, Input, Label } from "reactstrap";
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

import InfoTooltip from "../Tooltips/InfoTooltip";

export function RadioInput({ options, name, state, setState, isDisabled }) {
    const handleChange = useCallback((event) => {
        setState && setState(event.target.value);
    }, [setState]);

    return options?.map(({ label, value }) => (
        <div key={`radio_button_${label}`} className="flex gap-1">
            <Input disabled={isDisabled} type="radio" name={name} value={value} onChange={handleChange} checked={state === value} />
            <Label>
                {label}
            </Label>
        </div>
    ));
}

export function FormGroupRowLayout({ label, children, className, rowClassName = "mb-3", labelSize = 2, tooltip, iconClassName }) {
    return <div className={`flex items-center ${rowClassName}`}>
        <Label sm={labelSize} className="flex items-center justify-between px-2">
            {label}
            {tooltip && <InfoTooltip title={tooltip} iconClassName={iconClassName} />}
        </Label>
        <Col sm={10} className={className}>
            {children}
        </Col>
    </div>;
}

export function ToggleSwitchButton({ name, dataTestId, value = false, defaultValue = false, changeHandler = null, disabled = false, disabledTooltip = "" }) {
    const [state, setState] = useState(defaultValue);
    // todo!: Refactor components that use this ToggleSwitch so that they can use this component separately, this toggle should be part of FormInputs components
    useEffect(() => {
        setState(defaultValue);
    }, [defaultValue]);

    const handleClick = useCallback(() => {
        if (disabled) {
            return;
        }
        if (changeHandler) {
            changeHandler(!state);
        } else {
            setState((prevState) => !prevState);
        }
    }, [changeHandler, disabled, state]);

    useEffect(() => {
        if (changeHandler) {
            setState(value);
        }
    }, [changeHandler, value]);

    return (
        <div>
            <Tooltip title={disabled ? disabledTooltip : ''} placement="bottom-start" >
                <div>
                    <Switch inputProps={{ 'data-testid': dataTestId }} checked={Boolean(state)} onChange={handleClick} disabled={disabled} />
                    <input hidden type="checkbox" name={name} value={state && value} disabled={disabled} data-testid={dataTestId} />
                </div>
            </Tooltip>
        </div >
    );
}

export function SliderWithInput({ defaultValue, startPoint = 1, onChange, ...props }) {
    const [threshold, setThreshold] = useState(defaultValue);

    const handleThreshold = useCallback((event) => {
        const value = event.target ? event.target.valueAsNumber : event;
        onChange && onChange(value);
        setThreshold(value);
    }, [onChange]);

    return (
        <div>
            <Slider value={threshold} {...props} startPoint={startPoint} className="col col-5 mb-2" onChange={handleThreshold} />
            <Input {...props} value={threshold} onChange={handleThreshold} type="number" className="col col-1" />
        </div>
    );
}