import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const envSlice = createSlice({
    name: 'envVars',
    initialState,
    reducers: {
        setEnvVars: (state, action) => {
            state = action.payload;
            return state;
        }
    }
});

export const { setEnvVars } = envSlice.actions;

export default envSlice.reducer;
