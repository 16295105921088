import { useMemo } from "react";

import { DataGridPro } from "@mui/x-data-grid-pro";

import { ReactComponent as AddIcon } from "src/assets/Icons/NavIcons/icon_collapse.svg";
import { ReactComponent as CloseIcon } from "src/assets/Icons/UtilsIcons/icon_collapse_close.svg";
import SortIcon from '@mui/icons-material/Sort';

import StatusMsgCard from "../../Loaders/StatusMsgCard";
import { noRecords } from "src/appConfig/Strings";

const baseDataGridSlots = {
    noRowsOverlay: StatusMsgCard,
    noResultsOverlay: StatusMsgCard,
    detailPanelExpandIcon: AddIcon,
    detailPanelCollapseIcon: CloseIcon,
    columnUnsortedIcon: () => <SortIcon fontSize="small" className="opacity-50" />,
};

export const baseDataGridSlotsProps = (slotProps) => ({
    noRowsOverlay: { content: noRecords, ...slotProps },
    noResultsOverlay: { content: noRecords, ...slotProps },
});

function BaseDataGrid({ sx, rows, slots, slotProps = {}, ...props }) {
    const memoizedSlots = useMemo(() => ({
        ...baseDataGridSlots,
        ...slots
    }), [slots]);

    return (
        <DataGridPro
            sx={sx}
            rows={rows}
            disableMultipleRowSelection
            disableColumnMenu
            disableColumnResize
            slots={memoizedSlots}
            slotProps={baseDataGridSlotsProps(slotProps)}
            {...props}
        />
    );
}

export default BaseDataGrid;