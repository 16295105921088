import { useContext, useMemo } from "react";

import { appearanceLabel, appearanceNotes, successfullyLabeled } from "src/appConfig/Strings";
import { labelAppearanceDialog } from "@/Logic/Hooks/useDialog/useDialog.model";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { LabelAppearanceFieldNames, LabelAppearancesInputModel, labelOptions } from "./LabelAppearanceForm.model";
import SelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField";
import TextAreaInputField from "@/Components/Common/FormComponents/FormInputs/TextAreaInputField";
import ApiContext from "@/Logic/Context/apiContext";
import { notReviewed } from "@/Logic/ApiClients/EventsClient/EventsClient.model";
import { createLabelObject, labelHandleMapping } from "./AppearanceLabelDialog";
import { toastUtility } from "@/Logic/Hooks/useToast";
import { updateLocalAppearance } from "src/appConfig/customEvents";
import { useForm } from "@/Logic/Hooks/useForm";
import LabelImages from "./LabelImages";

function LabelAppearanceForm({ dialogDetails }) {
    const { appearance } = dialogDetails;
    const { clientsManager } = useContext(ApiContext);

    const initialFormData = useMemo(() => {
        const notes = dialogDetails.appearance?.appearance_notes?.free_notes;
        const labelOutcome = dialogDetails.appearance?.labeled_outcome || notReviewed;

        return { [LabelAppearanceFieldNames.labeledOutcome]: labelOutcome, [LabelAppearanceFieldNames.freeNotes]: notes };
    }, [dialogDetails]);

    async function handleSubmitForm(labelParams) {
        let response = null;
        const localUpdateFunc = labelHandleMapping[dialogDetails.tableType];
        const appearanceId = dialogDetails.appearance.appearance_data.appearance_id;
        const { labeled_outcome: labeledOutcome, free_notes: freeNotes } = labelParams;

        response = await clientsManager.historyClient.labelAppearance(appearanceId, labeledOutcome, freeNotes);
        if (response?.error) {
            return;
        }

        toastUtility.showSuccess(successfullyLabeled);
        if (localUpdateFunc) {
            localUpdateFunc(appearanceId, labeledOutcome, freeNotes);
        } else {
            document.dispatchEvent(new CustomEvent(updateLocalAppearance, { detail: { appearanceId: appearanceId, updateData: createLabelObject(labeledOutcome, freeNotes), tableType: dialogDetails.tableType } }));
        }
        dispatchToggleDialogEvent(labelAppearanceDialog);
    }

    const { formData, onFieldChange, submitForm } = useForm(false, initialFormData, LabelAppearancesInputModel, handleSubmitForm, false, false);

    return (
        <div className="flex w-full flex-col items-center gap-6">
            <LabelImages appearance={appearance} />
            <form id={labelAppearanceDialog} onSubmit={submitForm} className="flex w-3/4 flex-col items-center gap-3">
                <SelectInputField
                    label={appearanceLabel}
                    model={LabelAppearancesInputModel[LabelAppearanceFieldNames.labeledOutcome]}
                    value={formData.labeled_outcome}
                    options={labelOptions}
                    onChange={(event) =>
                        onFieldChange(LabelAppearanceFieldNames.labeledOutcome, event.target.value)
                    }
                />
                <TextAreaInputField
                    label={appearanceNotes}
                    model={LabelAppearancesInputModel[LabelAppearanceFieldNames.freeNotes]}
                    value={formData.free_notes}
                    onChange={(event) =>
                        onFieldChange(LabelAppearanceFieldNames.freeNotes, event.target.value)
                    }
                />
            </form>
        </div>
    );
}

export default LabelAppearanceForm;