import { memo } from "react";

function BaseLayout({ children, className }) {
    return (
        <div className={`h-full max-h-screen overflow-auto rounded bg-deep-navy text-white ${className}`}>
            {children}
        </div>
    );
}

export default memo(BaseLayout);