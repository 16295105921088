import { useContext, useEffect, useMemo } from "react";

import { useSelector } from "react-redux";

import store from "src/appConfig/configureStore";
import ApiContext from "../Context/apiContext";
import { cameraFormFieldsNames, capturingSourceMode } from "@/Components/Cameras/CreateCamera/CreateEditCamera/CreateEditCamera.model";
import { deviceType, mainNodeDeviceType } from "../ApiClients/ControllerClient";
import { deploymentFacesLimit } from "@/Components/Settings/License/License.model";

function usePrepareCreateCamera(openCamera, cameraID, isLiveAnalysisMode) {
    const { watchlists, privacyProfileId, streamMaxFaces, nodeId } = cameraFormFieldsNames;
    const { clientsManager } = useContext(ApiContext);
    const defaultAppearancesProfile = useSelector((state) => state.PrivacyProfilesStore.defaultAppearancesProfile);
    const licenseFeatures = useSelector((state) => state.ApiActionsStore.license?.license?.features);
    const nodes = Object.values(store.getState().ApiActionsStore.nodesList);
    const isMultiServerMode = store.getState().ApiActionsStore.isMultiServerMode;
    const mainNodeId = nodes.find((nodeObject) => nodeObject[deviceType] === mainNodeDeviceType)?.node_id;

    const updatedOpenCamera = useMemo(() => {
        const facesLimit = licenseFeatures && licenseFeatures[deploymentFacesLimit]?.value;
        const captureMode = cameraID ? {} : {
            [cameraFormFieldsNames.captureConfigMode]: isLiveAnalysisMode ? capturingSourceMode.rtsp : capturingSourceMode.file
        };

        return {
            [privacyProfileId]: defaultAppearancesProfile,
            [streamMaxFaces]: facesLimit || 0,
            ...openCamera,
            ...(isMultiServerMode && { [nodeId]: openCamera[nodeId] || mainNodeId }),
            [watchlists]: new Set(openCamera[watchlists]),
            ...captureMode
        };
    }, [cameraID, defaultAppearancesProfile, isLiveAnalysisMode, isMultiServerMode, licenseFeatures, mainNodeId, nodeId, openCamera, privacyProfileId, streamMaxFaces, watchlists]);

    useEffect(() => {
        async function getCameraRequest() {
            await clientsManager.camerasClient.GetCamera(cameraID);
        }

        if (cameraID) {
            getCameraRequest();
        }
    }, [cameraID, clientsManager.camerasClient]);

    useEffect(() => {
        async function prepareRequest() {
            await clientsManager.watchlistsClient.listAllWatchlists();
            await clientsManager.privacyProfilesClient.getPrivacyAppearancesProfiles();
            await clientsManager.privacyProfilesClient.getDefaultAppearancePrivacyProfile();
        }

        prepareRequest();
    }, [clientsManager]);

    return { updatedOpenCamera };
}

export default usePrepareCreateCamera;