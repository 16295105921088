import { useCallback, useEffect } from "react";

import RotateRightIcon from '@mui/icons-material/RotateRight';

import { Alert, Button, CircularProgress } from "@mui/material";
import FocusTrap from "@mui/material/Unstable_TrapFocus";
import { isEmpty, upperFirst } from "lodash";

import ToggleSwitchInput from "@/Components/Common/FormComponents/FormInputs/ToggleSwitchInput";
import { processDetection, rotateLabel } from "src/appConfig/Strings";
import DetectFaceCanvas from "@/Components/Common/Faces/DetectFaceCanvas";
import useDetect from "@/Logic/Hooks/useDetect";
import ImageWrapper from "@/Components/Common/Wrappers/ImageWrapper";
import { formatImageToDataURL, getBase64StringFromDataURL, getImageMimeType, rotateImage } from "@/Logic/Parsing/parseImages";
import { imgUploadInterface } from "./ImgUploadStep";

export const detectInterface = {
    processDetect: "processDetect",
    selectedDetectedCrop: "selectedDetectedCrop",
    imgData: "imgData",
    finalStepData: "finalStepData",
    isRotated: "isRotated"
};

function DetectRotateStep({ prevStepData, currData, setCurrData, shouldDetect, hideDetectSwitch }) {
    const { detectedFaces, isLoading, setIsLoading, error, responseMessage } = useDetect({ image: currData[detectInterface.imgData], processDetect: currData[detectInterface.processDetect] });
    const handleConfirmBBoxSelection = useCallback((selectedCrop) => {
        if (error) {
            return;
        }

        setCurrData({
            newCompData: {
                [detectInterface.selectedDetectedCrop]: selectedCrop?.img,
                [detectInterface.finalStepData]: !isEmpty(selectedCrop) ? selectedCrop?.img : currData[detectInterface.imgData],
            },
            isLeftBtnDisabled: false,
            newIsFinal: !isEmpty(selectedCrop)
        });
    }, [currData, error, setCurrData]);

    const handleToggleDetectSwitch = useCallback((event) => {
        setCurrData({
            newCompData: {
                [detectInterface.processDetect]: event.target.checked,
                [detectInterface.selectedDetectedCrop]: undefined,
                [detectInterface.finalStepData]: currData[detectInterface.imgData],
            },
            isLeftBtnDisabled: false,
            newIsFinal: !event.target.checked
        });
    }, [currData, setCurrData]);

    const handleRotateImg = useCallback(async () => {
        setIsLoading(true);
        const mimeType = getImageMimeType(currData[detectInterface.imgData]);
        const imageDataURL = formatImageToDataURL(currData[detectInterface.imgData], mimeType);
        const rotatedBase64 = await rotateImage(imageDataURL, mimeType);

        setCurrData({
            newCompData: {
                [detectInterface.imgData]: rotatedBase64,
                [detectInterface.finalStepData]: rotatedBase64,
            },
            isLeftBtnDisabled: false,
        });
    }, [currData, setCurrData, setIsLoading]);

    useEffect(() => {
        if (!isEmpty(currData)) {
            return;
        }
        const b64Data = getBase64StringFromDataURL(prevStepData?.[imgUploadInterface.imgData]) || "";
        setCurrData({
            newCompData: {
                [detectInterface.imgData]: b64Data,
                [detectInterface.finalStepData]: b64Data,
                [detectInterface.processDetect]: shouldDetect
            },
            isLeftBtnDisabled: false,
            newIsFinal: !shouldDetect
        });
    }, []);

    return (
        <div className="flex size-full flex-col items-center">
            <div className="mt-[3px] flex size-full max-h-[33rem] grow flex-col items-center justify-center">
                {currData[detectInterface.imgData] && currData[detectInterface.processDetect] ? (
                    <DetectFaceCanvas image={currData[detectInterface.imgData]} setSelectedFace={handleConfirmBBoxSelection} detectedFacesResponse={detectedFaces} />
                ) : (
                    <ImageWrapper className="max-h-[33rem]" src={currData[detectInterface.imgData]} />
                )}
            </div>
            <section className="mt-0.5 flex h-fit flex-col justify-end">
                {error && (
                    <FocusTrap disableEnforceFocus open >
                        <Alert className="w-full items-center justify-center" severity="error" tabIndex="0" data-testid="error-alert">
                            {upperFirst(responseMessage)}
                        </Alert>
                    </FocusTrap>
                )}
                <div className="flex items-center justify-center gap-3">
                    <Button className="w-auto normal-case" endIcon={isLoading ? <CircularProgress className="ml-1" size={15} /> : <RotateRightIcon />} onClick={handleRotateImg} disabled={isLoading}>
                        {rotateLabel}
                    </Button>
                    {shouldDetect && !hideDetectSwitch && (
                        <div>
                            <span>
                                {processDetection}
                            </span>
                            <ToggleSwitchInput
                                value={currData[detectInterface.processDetect]}
                                onChange={handleToggleDetectSwitch}
                            />
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
}

export default DetectRotateStep;