import LabelWrapper from "@/Components/Common/Wrappers/LabelWrapper";
import { poiProximityFormFieldsNames, PoiProximityInsightFormModel } from "./PoiProximityInsight.model";
import { alertingAppearanceDurationProximityInfo, alertingAppearanceDurationTxt, alertingWlLabel, alertingWlTooltipTxt, counterWlLabel, counterWlTooltipTxt } from "src/appConfig/Strings";
import { DurationSelector } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector";
import { daysHoursMinSecTimeMapping } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector.model";
import InsightCamerasSelector from "../../InsightCamerasSelector/InsightCamerasSelector";
import MultiSelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField/MultiSelectInputField";

const { alertingWatchlist, counterWatchlist, appearancesOverlapTimeout } = poiProximityFormFieldsNames;

function PoiProximityInsight({ formData, onFieldChange, watchlistsFromStore }) {
    return (
        <div className="flex flex-col gap-1">
            <InsightCamerasSelector model={PoiProximityInsightFormModel} formData={formData} onFieldChange={onFieldChange} />
            <LabelWrapper label={alertingWlLabel} tooltipTxt={alertingWlTooltipTxt} >
                <MultiSelectInputField
                    label={alertingWlLabel}
                    model={PoiProximityInsightFormModel[alertingWatchlist]}
                    options={Object.values(watchlistsFromStore)}
                    value={formData[alertingWatchlist]}
                    onChange={(value) => onFieldChange(alertingWatchlist, value)}
                    dataTestId="insight-alerting-watchlist"
                />
            </LabelWrapper>
            <LabelWrapper label={counterWlLabel} tooltipTxt={counterWlTooltipTxt} >
                <MultiSelectInputField
                    label={counterWlLabel}
                    model={PoiProximityInsightFormModel[counterWatchlist]}
                    options={Object.values(watchlistsFromStore)}
                    value={formData[counterWatchlist]}
                    onChange={(value) => onFieldChange(counterWatchlist, value)}
                    dataTestId="insight-counter-watchlist"
                />
            </LabelWrapper>
            <LabelWrapper label={alertingAppearanceDurationTxt} tooltipTxt={alertingAppearanceDurationProximityInfo} >
                <DurationSelector
                    model={PoiProximityInsightFormModel[appearancesOverlapTimeout]}
                    value={formData[appearancesOverlapTimeout]}
                    onChange={(value) => onFieldChange(appearancesOverlapTimeout, value)}
                    timeUnitsMap={daysHoursMinSecTimeMapping}
                />
            </LabelWrapper >
        </div>
    );
}

export default PoiProximityInsight;