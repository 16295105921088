
export const CreateEditUserFormFieldsNames = {
    userName: "name",
    roleID: "role_id",
    active: "active",
    isResetPassRequired: "password_reset_required",
    userContactInfo: "user_contact_info",
    fullName: "full_name",
    email: "email",
    phone: "phone",
    password: "password"
};

export const ExternalFieldModel = {
    confirmPassword: {
        name: "confirm_password",
        autoComplete: "new-password",
    },
};

const CreateEditUserModel = {
    [CreateEditUserFormFieldsNames.userName]: {
        name: CreateEditUserFormFieldsNames.userName,
        type: "text",
        required: true,
    },
    [CreateEditUserFormFieldsNames.password]: {
        name: CreateEditUserFormFieldsNames.password,
        autoComplete: "new-password",
    },
    [CreateEditUserFormFieldsNames.fullName]: {
        name: CreateEditUserFormFieldsNames.fullName,
        type: "text",
        maxLength: 40
    },
    [CreateEditUserFormFieldsNames.email]: {
        name: CreateEditUserFormFieldsNames.email,
        type: "email",
        maxLength: 40
    },
    [CreateEditUserFormFieldsNames.phone]: {
        name: CreateEditUserFormFieldsNames.phone,
        type: "tel",
        maxLength: 40
    },
    [CreateEditUserFormFieldsNames.roleID]: {
        name: CreateEditUserFormFieldsNames.roleID,
        textField: "role_name",
        dataKey: "role_id",
        required: true,
    },
    [CreateEditUserFormFieldsNames.active]: {
        name: CreateEditUserFormFieldsNames.active,
    }
};

export default CreateEditUserModel;