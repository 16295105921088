import { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { isLoadingSignalEvent, toggleDialogEvent } from "src/appConfig/customEvents";
import { setDialogOpenStatus } from "@/Logic/Redux/Stores/GlobalStore";

function useDialog(dialogID = "", openDialog = false) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isDialogOpen, setIsDialogOpen] = useState(openDialog);
    const prevIsDialogOpenRef = useRef(isDialogOpen);
    const dispatch = useDispatch();

    const handleToggleDialog = useCallback((shouldNavigate = false) => {
        setIsDialogOpen((prev) => !prev);
        if (shouldNavigate) {
            navigate(location.pathname, { replace: true });
        }
    }, [location.pathname, navigate]);

    useEffect(() => {
        const handleToggleDialogEvent = (event) => {
            const { isTriggered, dialogID: eventDialogID, closeWithNavigation, clearStoreAction } = event.detail;
            if (isTriggered && eventDialogID === dialogID) {
                handleToggleDialog(closeWithNavigation);
                clearStoreAction && clearStoreAction();
            }

        };

        document.addEventListener(toggleDialogEvent, handleToggleDialogEvent);
        return () => document.removeEventListener(toggleDialogEvent, handleToggleDialogEvent);
    }, [dialogID, handleToggleDialog]);

    useEffect(() => {
        openDialog && handleToggleDialog();
    }, [handleToggleDialog, openDialog]);

    useEffect(() => {
        if (prevIsDialogOpenRef.current !== isDialogOpen) {
            dispatch(setDialogOpenStatus({ dialogID, isDialogOpen }));
            prevIsDialogOpenRef.current = isDialogOpen;
        }
    }, [dialogID, dispatch, isDialogOpen]);

    return { isDialogOpen, handleToggleDialog };
}

export default useDialog;

function dispatchToggleDialogEvent(dialogID, closeWithNavigation = false, clearStoreAction = null) {
    const toggleDialogCustomEvent = new CustomEvent(toggleDialogEvent, { detail: { dialogID, isTriggered: true, closeWithNavigation, clearStoreAction } });
    document.dispatchEvent(toggleDialogCustomEvent);
}

function dispatchIsLoadingEvent(isLoading) {
    const isLoadingEvent = new CustomEvent(isLoadingSignalEvent, { detail: { isLoading } });
    document.dispatchEvent(isLoadingEvent);
}

export { dispatchToggleDialogEvent, dispatchIsLoadingEvent };