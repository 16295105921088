import { useCallback, useContext, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { isArray, isSet } from "lodash";
import { Alert, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ApiContext from '@/Logic/Context/apiContext';
import AffixInputField from './FormComponents/FormInputs/AffixInputField';
import { folderPathSelection, nestedFolderPath } from 'src/appConfig/Strings';
import { shareFolderPath } from 'src/appConfig/constants';
import { getFileNameFromPath } from '@/Logic/Parsing/stringsParsing';
import MultiSelectInputField from './FormComponents/FormInputs/SelectInputField/MultiSelectInputField';
import useUIResponseHandler from '@/Logic/Hooks/useUIResponseHandler';

import { setLastCameraFilesPath } from '@/Logic/Redux/Stores/CamerasStore';

function MultiSelectFiles({ model, className, label, onChange, value, disabled, lastCameraFilesPath, setLastCameraFilesPath }) {
    const { clientsManager } = useContext(ApiContext);

    const [folderFiles, setFolderFiles] = useState([]);
    const [isOpen, setIsOpen] = useState(Boolean(lastCameraFilesPath));
    const { isLoading, setIsLoading, responseMessage, error, parseResponse, clearResponseHandler } = useUIResponseHandler();
    const selectedOptions = useMemo(() => !value || isArray(value) || isSet(value) ? value : [value], [value]);

    const fetchFiles = useCallback(async () => {
        if (isLoading) {
            return;
        }

        clearResponseHandler();
        setIsLoading(true);
        const response = await clientsManager.webDockerClient.getShareVideoFiles(lastCameraFilesPath);
        parseResponse(response);
        setFolderFiles(response?.data || []);
    }, [clearResponseHandler, clientsManager.webDockerClient, lastCameraFilesPath, isLoading, parseResponse, setIsLoading]);

    const fileOptions = useMemo(() => folderFiles?.map(filePath => ({
        id: filePath,
        label: getFileNameFromPath(filePath),
    })), [folderFiles]);

    const handleChange = useCallback((newSelectedFiles) => {
        onChange && onChange(newSelectedFiles);
    }, [onChange]);

    const handleFolderChange = useCallback((folderPath) => {
        handleChange(undefined);
        setLastCameraFilesPath(folderPath);
    }, [handleChange, setLastCameraFilesPath]);

    return (
        <div className="mt-2 flex flex-col">
            {!disabled &&
                <div className="mb-2.5 flex justify-center">
                    <button type="button" data-testid="collapse-folder-selection" onClick={() => setIsOpen((prev) => !prev)}>
                        {folderPathSelection}
                        {isOpen ? <ExpandLess /> : <ExpandMore />}
                    </button>
                </div>
            }
            {error && <Alert severity="error" className='mb-2'>
                {responseMessage}
            </Alert>
            }
            <Collapse in={isOpen}>
                <AffixInputField className="mb-2 w-full"
                    value={lastCameraFilesPath}
                    onChange={(event) => handleFolderChange(event.target.value)}
                    dataTestId="file-search-path-input"
                    label={nestedFolderPath}
                    model={{ prefix: shareFolderPath }}
                    error={error}
                />
            </Collapse>
            <MultiSelectInputField
                value={selectedOptions}
                onChange={handleChange}
                options={fileOptions}
                model={model}
                label={label}
                onOpen={fetchFiles}
                className={className}
                dataTestId="select-video-files"
                disabled={disabled}
                isLoading={isLoading}
            />
        </div>
    );
}

const mapActionsToProps = {
    setLastCameraFilesPath
};

const mapStateToProps = (state) => {
    const { lastCameraFilesPath } = state.CamerasStore;

    return { lastCameraFilesPath };
};

export default connect(mapStateToProps, mapActionsToProps)(MultiSelectFiles);
