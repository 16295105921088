"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CreateGenerativeInsightParams = _interopRequireDefault(require("../model/CreateGenerativeInsightParams"));
var _DeleteGenerativeInsightResponse = _interopRequireDefault(require("../model/DeleteGenerativeInsightResponse"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GenerativeInsightResponse = _interopRequireDefault(require("../model/GenerativeInsightResponse"));
var _GenerativeInsightsQuery = _interopRequireDefault(require("../model/GenerativeInsightsQuery"));
var _GenerativeInsightsStatisticsQuery = _interopRequireDefault(require("../model/GenerativeInsightsStatisticsQuery"));
var _GetGenerativeInsightsResponseData = _interopRequireDefault(require("../model/GetGenerativeInsightsResponseData"));
var _GetGenerativeInsightsStatisticsResponse = _interopRequireDefault(require("../model/GetGenerativeInsightsStatisticsResponse"));
var _ListGenerativeInsightsResponse = _interopRequireDefault(require("../model/ListGenerativeInsightsResponse"));
var _UpdateGenerativeInsightParams = _interopRequireDefault(require("../model/UpdateGenerativeInsightParams"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* GenerativeInsights service.
* @module api/GenerativeInsightsApi
* @version 0.37.0
*/
class GenerativeInsightsApi {
  /**
  * Constructs a new GenerativeInsightsApi. 
  * @alias module:api/GenerativeInsightsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the clearGenerativeInsightRecords operation.
   * @callback module:api/GenerativeInsightsApi~clearGenerativeInsightRecordsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete Generative Insight Records
   * Delete a specific generative insight with its id
   * @param {module:model/GenerativeInsightsQuery} generativeInsightsQuery 
   * @param {module:api/GenerativeInsightsApi~clearGenerativeInsightRecordsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  clearGenerativeInsightRecords(generativeInsightsQuery, callback) {
    let postBody = generativeInsightsQuery;
    // verify the required parameter 'generativeInsightsQuery' is set
    if (generativeInsightsQuery === undefined || generativeInsightsQuery === null) {
      throw new Error("Missing the required parameter 'generativeInsightsQuery' when calling clearGenerativeInsightRecords");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/generative_insight_alerts/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the createGenerativeInsight operation.
   * @callback module:api/GenerativeInsightsApi~createGenerativeInsightCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GenerativeInsightResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Create Generative Insight
   * Create a new Generative Insight.
   * @param {module:model/CreateGenerativeInsightParams} createGenerativeInsightParams 
   * @param {module:api/GenerativeInsightsApi~createGenerativeInsightCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GenerativeInsightResponse}
   */
  createGenerativeInsight(createGenerativeInsightParams, callback) {
    let postBody = createGenerativeInsightParams;
    // verify the required parameter 'createGenerativeInsightParams' is set
    if (createGenerativeInsightParams === undefined || createGenerativeInsightParams === null) {
      throw new Error("Missing the required parameter 'createGenerativeInsightParams' when calling createGenerativeInsight");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GenerativeInsightResponse.default;
    return this.apiClient.callApi('/generative_insights/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the deleteGenerativeInsightRecord operation.
   * @callback module:api/GenerativeInsightsApi~deleteGenerativeInsightRecordCallback
   * @param {String} error Error message, if any.
   * @param {module:model/DeleteGenerativeInsightResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete Generative Insight Record
   * Delete a specific generative insight with its id
   * @param {Object} generativeInsightsId 
   * @param {module:api/GenerativeInsightsApi~deleteGenerativeInsightRecordCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/DeleteGenerativeInsightResponse}
   */
  deleteGenerativeInsightRecord(generativeInsightsId, callback) {
    let postBody = null;
    // verify the required parameter 'generativeInsightsId' is set
    if (generativeInsightsId === undefined || generativeInsightsId === null) {
      throw new Error("Missing the required parameter 'generativeInsightsId' when calling deleteGenerativeInsightRecord");
    }
    let pathParams = {
      'generative_insights_id': generativeInsightsId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DeleteGenerativeInsightResponse.default;
    return this.apiClient.callApi('/generative_insight_alerts/{generative_insights_id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getGenerativeInsight operation.
   * @callback module:api/GenerativeInsightsApi~getGenerativeInsightCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GenerativeInsightResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Generative Insight
   * Get A specific Generative Insight
   * @param {Object} insightId 
   * @param {module:api/GenerativeInsightsApi~getGenerativeInsightCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GenerativeInsightResponse}
   */
  getGenerativeInsight(insightId, callback) {
    let postBody = null;
    // verify the required parameter 'insightId' is set
    if (insightId === undefined || insightId === null) {
      throw new Error("Missing the required parameter 'insightId' when calling getGenerativeInsight");
    }
    let pathParams = {
      'insight_id': insightId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GenerativeInsightResponse.default;
    return this.apiClient.callApi('/generative_insights/{insight_id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getGenerativeInsights operation.
   * @callback module:api/GenerativeInsightsApi~getGenerativeInsightsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetGenerativeInsightsResponseData} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Generative Insight Records
   * Gets records of appearances within the search filtering parameters given, up to the maximum number of appearances which can be returned (300 records). Appearances are sorted by their start time so that the most recent results matching the search criteria are returned first.
   * @param {module:model/GenerativeInsightsQuery} generativeInsightsQuery 
   * @param {Object} opts Optional parameters
   * @param {Object} opts.count 
   * @param {Object} opts.afterId 
   * @param {Object} opts.limit 
   * @param {module:api/GenerativeInsightsApi~getGenerativeInsightsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetGenerativeInsightsResponseData}
   */
  getGenerativeInsights(generativeInsightsQuery, opts, callback) {
    opts = opts || {};
    let postBody = generativeInsightsQuery;
    // verify the required parameter 'generativeInsightsQuery' is set
    if (generativeInsightsQuery === undefined || generativeInsightsQuery === null) {
      throw new Error("Missing the required parameter 'generativeInsightsQuery' when calling getGenerativeInsights");
    }
    let pathParams = {};
    let queryParams = {
      'count': opts['count'],
      'after_id': opts['afterId'],
      'limit': opts['limit']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetGenerativeInsightsResponseData.default;
    return this.apiClient.callApi('/generative_insight_alerts/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getGenerativeInsightsStatistics operation.
   * @callback module:api/GenerativeInsightsApi~getGenerativeInsightsStatisticsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetGenerativeInsightsStatisticsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Generative Insight Statistics
   * Returns the statistics of the requested insights
   * @param {module:model/GenerativeInsightsStatisticsQuery} generativeInsightsStatisticsQuery 
   * @param {module:api/GenerativeInsightsApi~getGenerativeInsightsStatisticsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetGenerativeInsightsStatisticsResponse}
   */
  getGenerativeInsightsStatistics(generativeInsightsStatisticsQuery, callback) {
    let postBody = generativeInsightsStatisticsQuery;
    // verify the required parameter 'generativeInsightsStatisticsQuery' is set
    if (generativeInsightsStatisticsQuery === undefined || generativeInsightsStatisticsQuery === null) {
      throw new Error("Missing the required parameter 'generativeInsightsStatisticsQuery' when calling getGenerativeInsightsStatistics");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetGenerativeInsightsStatisticsResponse.default;
    return this.apiClient.callApi('/generative_insight_alerts/statistics/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the listGenerativeInsights operation.
   * @callback module:api/GenerativeInsightsApi~listGenerativeInsightsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ListGenerativeInsightsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List Generative Insights
   * Get all existing Generative Insights.
   * @param {module:api/GenerativeInsightsApi~listGenerativeInsightsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ListGenerativeInsightsResponse}
   */
  listGenerativeInsights(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ListGenerativeInsightsResponse.default;
    return this.apiClient.callApi('/generative_insights/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeGenerativeInsight operation.
   * @callback module:api/GenerativeInsightsApi~removeGenerativeInsightCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Generative Insight
   * Remove a specific Generative Insight
   * @param {Object} insightId 
   * @param {module:api/GenerativeInsightsApi~removeGenerativeInsightCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  removeGenerativeInsight(insightId, callback) {
    let postBody = null;
    // verify the required parameter 'insightId' is set
    if (insightId === undefined || insightId === null) {
      throw new Error("Missing the required parameter 'insightId' when calling removeGenerativeInsight");
    }
    let pathParams = {
      'insight_id': insightId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/generative_insights/{insight_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updateGenerativeInsight operation.
   * @callback module:api/GenerativeInsightsApi~updateGenerativeInsightCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GenerativeInsightResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update Generative Insight
   * Update a specific Generative Insight
   * @param {Object} insightId 
   * @param {module:model/UpdateGenerativeInsightParams} updateGenerativeInsightParams 
   * @param {module:api/GenerativeInsightsApi~updateGenerativeInsightCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GenerativeInsightResponse}
   */
  updateGenerativeInsight(insightId, updateGenerativeInsightParams, callback) {
    let postBody = updateGenerativeInsightParams;
    // verify the required parameter 'insightId' is set
    if (insightId === undefined || insightId === null) {
      throw new Error("Missing the required parameter 'insightId' when calling updateGenerativeInsight");
    }
    // verify the required parameter 'updateGenerativeInsightParams' is set
    if (updateGenerativeInsightParams === undefined || updateGenerativeInsightParams === null) {
      throw new Error("Missing the required parameter 'updateGenerativeInsightParams' when calling updateGenerativeInsight");
    }
    let pathParams = {
      'insight_id': insightId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GenerativeInsightResponse.default;
    return this.apiClient.callApi('/generative_insights/{insight_id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = GenerativeInsightsApi;