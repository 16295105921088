import { baseCamerasFieldNames, baseLiveCamerasModel, baseVideosModel } from "../../BaseModels";

export const poiProximityFormFieldsNames = {
    ...baseCamerasFieldNames,
    appearancesOverlapTimeout: "appearances_overlap_timeout",
    alertingWatchlist: "alerting_watchlists",
    counterWatchlist: "counter_watchlists",
};

export const PoiProximityInsightFormModel = {
    [poiProximityFormFieldsNames.alertingWatchlist]: {
        name: poiProximityFormFieldsNames.alertingWatchlist,
        getId: (watchlist) => watchlist.watchlist_id,
        getLabel: (watchlist) => watchlist.display_name,
        required: true,
    },
    [poiProximityFormFieldsNames.counterWatchlist]: {
        name: poiProximityFormFieldsNames.counterWatchlist,
        getId: (watchlist) => watchlist.watchlist_id,
        getLabel: (watchlist) => watchlist.display_name,
        required: true,
    },
    [poiProximityFormFieldsNames.cameras]: {
        ...baseLiveCamerasModel
    },
    [poiProximityFormFieldsNames.videos]: {
        ...baseVideosModel
    },
    [poiProximityFormFieldsNames.appearancesOverlapTimeout]: {
        name: poiProximityFormFieldsNames.appearancesOverlapTimeout,
        type: "number",
        min: 0,
        required: true,
    },
};