"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GenerativeInsightsResponseData = _interopRequireDefault(require("./GenerativeInsightsResponseData"));
var _MsgMetadata = _interopRequireDefault(require("./MsgMetadata"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GetGenerativeInsightsResponseData model module.
 * @module model/GetGenerativeInsightsResponseData
 * @version 0.37.0
 */
class GetGenerativeInsightsResponseData {
  /**
   * Constructs a new <code>GetGenerativeInsightsResponseData</code>.
   * @alias module:model/GetGenerativeInsightsResponseData
   * @param metadata {module:model/MsgMetadata} 
   * @param data {module:model/GenerativeInsightsResponseData} 
   */
  constructor(metadata, data) {
    GetGenerativeInsightsResponseData.initialize(this, metadata, data);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, metadata, data) {
    obj['metadata'] = metadata;
    obj['data'] = data;
  }

  /**
   * Constructs a <code>GetGenerativeInsightsResponseData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GetGenerativeInsightsResponseData} obj Optional instance to populate.
   * @return {module:model/GetGenerativeInsightsResponseData} The populated <code>GetGenerativeInsightsResponseData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GetGenerativeInsightsResponseData();
      if (data.hasOwnProperty('metadata')) {
        obj['metadata'] = _MsgMetadata.default.constructFromObject(data['metadata']);
      }
      if (data.hasOwnProperty('data')) {
        obj['data'] = _GenerativeInsightsResponseData.default.constructFromObject(data['data']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GetGenerativeInsightsResponseData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetGenerativeInsightsResponseData</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of GetGenerativeInsightsResponseData.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // validate the optional field `metadata`
    if (data['metadata']) {
      // data not null
      _MsgMetadata.default.validateJSON(data['metadata']);
    }
    // validate the optional field `data`
    if (data['data']) {
      // data not null
      _GenerativeInsightsResponseData.default.validateJSON(data['data']);
    }
    return true;
  }
}
GetGenerativeInsightsResponseData.RequiredProperties = ["metadata", "data"];

/**
 * @member {module:model/MsgMetadata} metadata
 */
GetGenerativeInsightsResponseData.prototype['metadata'] = undefined;

/**
 * @member {module:model/GenerativeInsightsResponseData} data
 */
GetGenerativeInsightsResponseData.prototype['data'] = undefined;
var _default = exports.default = GetGenerativeInsightsResponseData;