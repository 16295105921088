export const cameraDialogID = "CreateEditCameraDialog";
export const changeAnalysisModeDialogId = "toggleAnalysisModeDialog";
export const playbackDialogId = "playbackDialogId";
export const clearAnalyticsDialog = "clearAnalyticsDialog";
export const appearancePrivacyProfilesDialogId = "appearancePrivacyProfiles";
export const watchlistPrivacyProfilesDialogId = "watchlistPrivacyProfiles";
export const usersSettingsDialogId = "usersSettingsDialog";
export const auditDialog = "auditDialog";
export const rolesSettingsDialogId = "rolesSettingsDialog";
export const historyAutoClearDialogId = "historyAutoClearDialog";
export const auditAutoClearDialogId = "auditAutoClearDialog";
export const dataLongevityDialogId = "dataLongevityDialog";
export const removeAppearanceDialog = "removeAppearanceDialog";
export const retainAppearanceDialog = "retainAppearanceDialog";
export const labelAppearanceDialog = "labelAppearanceDialog";
export const insightDialogId = "createEditInsightDialog";
export const removeInsightsByQueryDialog = "removeInsightsByQueryDialog";
export const alertCenterDialogId = "alertCenterDialogId";
export const poiDialogId = "poiDialog";

export const clearHistoryDialog = "clearHistoryDialog";
export const clearFramesDialog = "clearFramesDialog";
export const clearInsightsDialog = "clearInsightsDialog";
export const removeAppearancesByQueryDialog = "removeAppearancesByQueryDialog";
export const clearWlDialog = "clearWlDialog";