"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Frame = _interopRequireDefault(require("./Frame"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Frames Storage API
 * Frames Storage API gives access to the frames of recorded appearances from cameras analyzed by the application.  It allows to read and remove frames that associated with an appearance record.
 *
 * The version of the OpenAPI document: 0.34.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AppearanceFramesResponse model module.
 * @module model/AppearanceFramesResponse
 * @version 0.34.0
 */
class AppearanceFramesResponse {
  /**
   * Constructs a new <code>AppearanceFramesResponse</code>.
   * @alias module:model/AppearanceFramesResponse
   * @param frame {module:model/Frame} 
   * @param appearanceSequence {Array.<Number>} This appearance available sequence ids
   */
  constructor(frame, appearanceSequence) {
    AppearanceFramesResponse.initialize(this, frame, appearanceSequence);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, frame, appearanceSequence) {
    obj['frame'] = frame;
    obj['appearance_sequence'] = appearanceSequence;
  }

  /**
   * Constructs a <code>AppearanceFramesResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AppearanceFramesResponse} obj Optional instance to populate.
   * @return {module:model/AppearanceFramesResponse} The populated <code>AppearanceFramesResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AppearanceFramesResponse();
      if (data.hasOwnProperty('frame')) {
        obj['frame'] = _Frame.default.constructFromObject(data['frame']);
      }
      if (data.hasOwnProperty('appearance_sequence')) {
        obj['appearance_sequence'] = _ApiClient.default.convertToType(data['appearance_sequence'], ['Number']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AppearanceFramesResponse</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AppearanceFramesResponse</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of AppearanceFramesResponse.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // validate the optional field `frame`
    if (data['frame']) {
      // data not null
      _Frame.default.validateJSON(data['frame']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['appearance_sequence'])) {
      throw new Error("Expected the field `appearance_sequence` to be an array in the JSON data but got " + data['appearance_sequence']);
    }
    return true;
  }
}
AppearanceFramesResponse.RequiredProperties = ["frame", "appearance_sequence"];

/**
 * @member {module:model/Frame} frame
 */
AppearanceFramesResponse.prototype['frame'] = undefined;

/**
 * This appearance available sequence ids
 * @member {Array.<Number>} appearance_sequence
 */
AppearanceFramesResponse.prototype['appearance_sequence'] = undefined;
var _default = exports.default = AppearanceFramesResponse;