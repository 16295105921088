export const historyQueryModel = {
    camerasFilter: {
        dataKey: "camera_id",
        textField: "description"
    },
    matchOutcomeFilter: {
        dataKey: "watchlist_id",
        textField: "display_name"
    },
    timeFilter: {
    },
    ageGroupFilter: {
        dataKey: "groupValue",
        textField: "name"
    },
    livenessFilter: {
        dataKey: "livenessValue",
        textField: "name"
    },
    genderFilter: {
        dataKey: "genderValue",
        textField: "name"
    },
    retainFilter: {
        dataKey: "id",
        textField: "display_name",
    },
    analysisModeFilter: {
        textField: "label"
    },
    reviewedFilter: {
        dataKey: "value",
        textField: "name"
    }
};
