import { analyzeStepLabel, detectStepLabel, duplicateStepLabel, fileStepLabel, selectFaceLabel, snapshotStepLabel } from "src/appConfig/Strings";
import AnalyzeStep from "./Steps/AnalyzeStep";
import CameraSnapshotStep from "./Steps/CameraSnapshotStep/CameraSnapshotStep";
import CheckDuplicateStep from "./Steps/CheckDuplicateStep";
import ImgUploadStep from "./Steps/ImgUploadStep";
import DetectRotateStep from "./Steps/DetectRotateStep";

export const progressStepperInterface = {
    rightBtnText: "rightBtnText"
};

export const initialStepperInfo = { id: "", initialedData: {}, isStepperOpen: false, onExit: null };
export const initialStepData = { compData: {}, isFinal: false, isPreCheckPassed: true, autoTriggerNextStep: false, handleNext: undefined };

export const stepperComponentsIds = {
    cameraSnapshotId: "cameraSnapshot",
    imgUploadId: "chooseFile",
    detectId: "detect",
    analyzeId: "analyze",
    duplicateId: "duplicate"
};

export const ProgressStepperModel = {
    cameraSnapshot: {
        id: stepperComponentsIds.cameraSnapshotId,
        label: snapshotStepLabel,
        component: CameraSnapshotStep,
    },
    imgUpload: {
        id: stepperComponentsIds.imgUploadId,
        label: fileStepLabel,
        component: ImgUploadStep,
    },
    detect: (shouldDetect = true, hideDetectSwitch = false) => ({
        id: stepperComponentsIds.detectId,
        dialogLabel: selectFaceLabel,
        label: detectStepLabel,
        component: (props) => <DetectRotateStep {...props} shouldDetect={shouldDetect} hideDetectSwitch={hideDetectSwitch} />,
    }),
    analyze: {
        id: stepperComponentsIds.analyzeId,
        label: analyzeStepLabel,
        component: AnalyzeStep,
    },
    duplicate: {
        id: stepperComponentsIds.duplicateId,
        label: duplicateStepLabel,
        component: CheckDuplicateStep,
    },
};

export const screenStepsMapping = {
    newPOIFromCamera: [
        ProgressStepperModel.cameraSnapshot,
        ProgressStepperModel.detect(true, true),
        ProgressStepperModel.analyze,
        ProgressStepperModel.duplicate
    ],
    newPOIFromFile: [
        ProgressStepperModel.imgUpload,
        ProgressStepperModel.detect(true, true),
        ProgressStepperModel.analyze,
        ProgressStepperModel.duplicate
    ],
    newPoiContextMenu: [
        ProgressStepperModel.analyze,
        ProgressStepperModel.duplicate
    ],
    existingPoiContextMenu: [
        ProgressStepperModel.analyze,
    ],
    casesSingeImgFromCamera: [
        ProgressStepperModel.cameraSnapshot,
        ProgressStepperModel.detect(true, true),
    ],
    casesSingeFaceFromFile: [
        ProgressStepperModel.imgUpload,
        ProgressStepperModel.detect(),
    ],
    casesMultiFacesFromCamera: [
        ProgressStepperModel.cameraSnapshot,
        ProgressStepperModel.detect(false, true),
    ],
    casesMultiFacesFromFile: [
        ProgressStepperModel.imgUpload,
        ProgressStepperModel.detect(false, true),
    ],
};