import { useMemo } from "react";

import { stabilityLabel } from "src/appConfig/Strings";
import { getServerStatusAndStability } from "@/Components/Servers/ServersNodesList/ServersNodesList";
import StatusColumn from "./StatusColumn";

function ServerStatusStabilityColumn({ serverData, label }) {
    const currentStatus = useMemo(() => {
        const { stability, status } = getServerStatusAndStability(serverData);

        return label === stabilityLabel ? stability : status;
    }, [serverData, label]);

    return (
        <div>
            <span className="flex items-center">
                <StatusColumn filledColor={currentStatus.color} />
                <div className="data-txt text-white">
                    {currentStatus.text}
                </div>
            </span>
        </div>
    );
}

export default ServerStatusStabilityColumn;