import { ageGroupFilter, ageGroupHeader, allAgeGroups, allLivenessOutcomes as allGendersOutcomes, allLivenessOutcomes, genderFilter, genderFilterHeader, livenessFilter, livenessFilterHeader } from "../../../../appConfig/Strings";
import { RequiredFaceAttributesConfig } from "../../Cameras/CreateCamera/CreateEditCamera/CreateEditCamera.model";

// todo-sdk
export const faceFeatureFiltersConfig = {
    groupValue: "groupValue",
    age_outcomes: "age_outcomes",
    genderValue: "genderValue",
    gender_outcomes: "gender_outcomes",
    livenessValue: "livenessValue",
    liveness_outcomes: "liveness_outcomes"
};

export const faceFeatureFilters = {
    [ageGroupFilter]: {
        valueKey: faceFeatureFiltersConfig.groupValue,
        categoryHeader: ageGroupHeader,
        allOptionTitle: allAgeGroups,
        featureOutcomes: RequiredFaceAttributesConfig.age_group,
        categoryRoleMatchesFilters: faceFeatureFiltersConfig.age_outcomes
    },
    [genderFilter]: {
        valueKey: faceFeatureFiltersConfig.genderValue,
        categoryHeader: genderFilterHeader,
        allOptionTitle: allGendersOutcomes,
        featureOutcomes: RequiredFaceAttributesConfig.gender,
        categoryRoleMatchesFilters: faceFeatureFiltersConfig.gender_outcomes
    },
    [livenessFilter]: {
        valueKey: faceFeatureFiltersConfig.livenessValue,
        categoryHeader: livenessFilterHeader,
        allOptionTitle: allLivenessOutcomes,
        featureOutcomes: RequiredFaceAttributesConfig.liveness,
        categoryRoleMatchesFilters: faceFeatureFiltersConfig.liveness_outcomes
    }
};