"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _FeatureMinWidth = _interopRequireDefault(require("./FeatureMinWidth"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The FeaturesMinFaceWidths model module.
 * @module model/FeaturesMinFaceWidths
 * @version 0.32.2
 */
class FeaturesMinFaceWidths {
  /**
   * Constructs a new <code>FeaturesMinFaceWidths</code>.
   * a dict of minimum face widths to determine face attributes. Each value is the minimum width required of a face to determine this specific feature for the face. They cannot be set lower than these values.
   * @alias module:model/FeaturesMinFaceWidths
   * @implements module:model/FeatureMinWidth
   */
  constructor() {
    _FeatureMinWidth.default.initialize(this);
    FeaturesMinFaceWidths.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>FeaturesMinFaceWidths</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FeaturesMinFaceWidths} obj Optional instance to populate.
   * @return {module:model/FeaturesMinFaceWidths} The populated <code>FeaturesMinFaceWidths</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FeaturesMinFaceWidths();
      _FeatureMinWidth.default.constructFromObject(data, obj);
      if (data.hasOwnProperty('mask_detect')) {
        obj['mask_detect'] = _ApiClient.default.convertToType(data['mask_detect'], 'Number');
      }
      if (data.hasOwnProperty('age_gender')) {
        obj['age_gender'] = _ApiClient.default.convertToType(data['age_gender'], 'Number');
      }
      if (data.hasOwnProperty('liveness')) {
        obj['liveness'] = _ApiClient.default.convertToType(data['liveness'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>FeaturesMinFaceWidths</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>FeaturesMinFaceWidths</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * min width to detect mask feature
 * @member {Number} mask_detect
 */
FeaturesMinFaceWidths.prototype['mask_detect'] = undefined;

/**
 * min width to detect age_gender feature
 * @member {Number} age_gender
 */
FeaturesMinFaceWidths.prototype['age_gender'] = undefined;

/**
 * min width to detect liveness feature
 * @member {Number} liveness
 */
FeaturesMinFaceWidths.prototype['liveness'] = undefined;

// Implement FeatureMinWidth interface:
/**
 * min width to detect mask feature
 * @member {Number} mask_detect
 */
_FeatureMinWidth.default.prototype['mask_detect'] = undefined;
/**
 * min width to detect age_gender feature
 * @member {Number} age_gender
 */
_FeatureMinWidth.default.prototype['age_gender'] = undefined;
/**
 * min width to detect liveness feature
 * @member {Number} liveness
 */
_FeatureMinWidth.default.prototype['liveness'] = undefined;
var _default = exports.default = FeaturesMinFaceWidths;