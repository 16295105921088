import { useCallback } from "react";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import InfoTooltip from "../../Tooltips/InfoTooltip";

function PrivacyConfigurationColumn({ settings, nameMapping, isEnabledPropName, showText = true, showValues = true, showTooltip = false }) {
    const isSettingEnabled = useCallback((settingName) => {
        const settingValue = isEnabledPropName ? settings[settingName][isEnabledPropName] : settings[settingName];
        return settingValue;
    }, [isEnabledPropName, settings]);

    return (
        <span className="my-2">
            {
                Object.entries(nameMapping).map(([settingName, data]) => (
                    <div key={settingName} className="mb-1 flex items-center gap-2">
                        {showText &&
                            <div className="flex">
                                {data.name}
                                {showTooltip && data.tooltipTxt && <InfoTooltip title={data.tooltipTxt} />}
                            </div>
                        }
                        {showValues &&
                            <span className="ml-auto">
                                {isSettingEnabled(settingName) ?
                                    <CheckIcon fontSize="small" className="text-green-500" /> :
                                    <CloseIcon fontSize="small" className="text-red-500" />
                                }
                            </span>
                        }
                    </div>
                ))
            }
        </span>
    );
}
export default PrivacyConfigurationColumn;