import { ColumnsDefinitions } from "@/Components/Common/MUITables/Columns/ColumnsDefinitions";
import { distributedLocalSiteDeviceType, hqDeviceType, mainNodeDeviceType, nodeDeviceType } from "@/Logic/ApiClients/ControllerClient";
import { formatUnixTimestamp, fullDataTimeFormat } from "@/Logic/Parsing/timeParsing";
import { active, distributedDeviceTypeLabel, distributedHQDeviceTypeLabel, inactive, lastUpdateTime, mainDeviceTypeLabel, nodeDeviceTypeLabel, nodeId, notStable, pendingStatus, serverStatus, serverType, stabilityLabel, stableLabel } from "src/appConfig/Strings";

const ACTIVE_COLOR = "bg-green-500";
const NOT_STABLE_COLOR = "bg-yellow-500";
const TIMEOUT_COLOR = "bg-red-600";
const PENDING_COLOR = "bg-gray-400";

export const ServerStatusesAndStabilityMapping = {
    pending: {
        text: pendingStatus,
        color: PENDING_COLOR
    },
    active: {
        text: active,
        color: ACTIVE_COLOR
    },
    inactive: {
        text: inactive,
        color: TIMEOUT_COLOR
    },
    stable: {
        text: stableLabel,
        color: ACTIVE_COLOR
    },
    unStable: {
        text: notStable,
        color: NOT_STABLE_COLOR
    },
};

export const ServerTypeMapping = {
    [mainNodeDeviceType]: mainDeviceTypeLabel,
    [nodeDeviceType]: nodeDeviceTypeLabel,
    [distributedLocalSiteDeviceType]: distributedDeviceTypeLabel,
    [hqDeviceType]: distributedHQDeviceTypeLabel,
};

export const ServersStatusModel = (rows) => {
    const serverStatusColumns = [
        ColumnsDefinitions[nodeId],
        ColumnsDefinitions[serverType],
        ColumnsDefinitions[lastUpdateTime],
        ColumnsDefinitions[serverStatus],
        ColumnsDefinitions[stabilityLabel],
    ];

    return {
        columns: serverStatusColumns,
        rows: (rows.length > 0) ? rows.map((row) => ({
            id: row.node_id,
            [nodeId]: row.node_id,
            [serverType]: ServerTypeMapping[row.device_type],
            [serverStatus]: row,
            [stabilityLabel]: row,
            [lastUpdateTime]: formatUnixTimestamp(row.last_update, fullDataTimeFormat),
        })
        ) : [],
        rowHeight: 170
    };
};