import { createSlice } from '@reduxjs/toolkit';

import { generativeInsightTypes, insightFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/CreateEditInsight.model";
import { crowdedAreaFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/InsightTypes/CrowdedAreaInsight/CrowdedAreaInsight.model";
import { repeatedIndividualFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/InsightTypes/RepeatedIndividualInsight/RepeatedIndividualInsight.model";
import { loiteringFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/InsightTypes/LoiteringInsight/LoiteringInsight.model";
import { poiProximityFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/InsightTypes/PoiProximityInsight/PoiProximityInsight.model";
import { personJourneyFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/InsightTypes/PersonJourneyInsight/PersonJourney.model";
import { daysHoursMinSecTimeMapping, minSecTimeMapping, valueToTimeMapping } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector.model";
import { baseCamerasFieldNames } from '@/Components/GenerativeInsights/CreateEditInsights/BaseModels';

const { insightNameField, activeField, generateEvent, insightNotesField, insightTypeField, excludeWatchlists, minDetectionWidth } = insightFormFieldsNames;
const { alertingLoiteringDuration } = loiteringFormFieldsNames;
const { alertingUniqueIndividuals, alertingCrowdedDuration } = crowdedAreaFormFieldsNames;
const { alertingNumberOfAppearances, alertingTimeFrame, notAlertingTimeFrame } = repeatedIndividualFormFieldsNames;
const { alertingWatchlist, counterWatchlist, appearancesOverlapTimeout } = poiProximityFormFieldsNames;
const { completedJourney, alertingJourneyDuration, journeyStartCamera, journeyEndCamera } = personJourneyFormFieldsNames;

export const insightsDefaultValue = {
    [generateEvent]: true,
    [alertingTimeFrame]: valueToTimeMapping(0, daysHoursMinSecTimeMapping),
    [notAlertingTimeFrame]: valueToTimeMapping(0, daysHoursMinSecTimeMapping),
    [alertingLoiteringDuration]: valueToTimeMapping(0, minSecTimeMapping),
    [alertingCrowdedDuration]: valueToTimeMapping(0, minSecTimeMapping),
    [appearancesOverlapTimeout]: valueToTimeMapping(0, daysHoursMinSecTimeMapping),
    [alertingJourneyDuration]: valueToTimeMapping(0, daysHoursMinSecTimeMapping),
    [insightTypeField]: generativeInsightTypes.loitering,
    [minDetectionWidth]: 0,
    [completedJourney]: false,
};

export const getInsightDataByType = (insightData, insightType) => {
    const insightTypesData = {
        [generativeInsightTypes.loitering]: {
            [baseCamerasFieldNames.cameras]: insightData?.generative_insight_config?.camera_ids,
            [baseCamerasFieldNames.videos]: insightData?.generative_insight_config?.videos_ids,
            [alertingLoiteringDuration]: insightData?.generative_insight_config?.alerting_loitering_duration !== undefined && valueToTimeMapping(insightData?.generative_insight_config?.alerting_loitering_duration, minSecTimeMapping),
        },
        [generativeInsightTypes.crowded_area]: {
            [baseCamerasFieldNames.cameras]: insightData?.generative_insight_config?.camera_ids,
            [baseCamerasFieldNames.videos]: insightData?.generative_insight_config?.videos_ids,
            [alertingUniqueIndividuals]: insightData?.generative_insight_config?.alerting_unique_individuals,
            [alertingCrowdedDuration]: insightData?.generative_insight_config?.alerting_crowded_duration !== undefined && valueToTimeMapping(insightData?.generative_insight_config?.alerting_crowded_duration, minSecTimeMapping),
        },
        [generativeInsightTypes.repeated_individual]: {
            [baseCamerasFieldNames.cameras]: insightData?.generative_insight_config?.camera_ids,
            [baseCamerasFieldNames.videos]: insightData?.generative_insight_config?.videos_ids,
            [alertingNumberOfAppearances]: insightData?.generative_insight_config?.alerting_number_of_appearances,
            [alertingTimeFrame]: insightData?.generative_insight_config?.alerting_time_frame !== undefined && valueToTimeMapping(insightData?.generative_insight_config?.alerting_time_frame, daysHoursMinSecTimeMapping),
            [notAlertingTimeFrame]: insightData?.generative_insight_config?.not_alerting_time_frame !== undefined && valueToTimeMapping(insightData?.generative_insight_config?.not_alerting_time_frame, daysHoursMinSecTimeMapping),
        },
        [generativeInsightTypes.poiProximity]: {
            [baseCamerasFieldNames.cameras]: insightData?.generative_insight_config?.camera_ids,
            [baseCamerasFieldNames.videos]: insightData?.generative_insight_config?.videos_ids,
            [alertingWatchlist]: insightData?.generative_insight_config?.alerting_watchlists,
            [counterWatchlist]: insightData?.generative_insight_config?.counter_watchlists,
            [appearancesOverlapTimeout]: insightData?.generative_insight_config?.appearances_overlap_timeout !== undefined && valueToTimeMapping(insightData?.generative_insight_config?.appearances_overlap_timeout, daysHoursMinSecTimeMapping),
        },
        [generativeInsightTypes.personJourney]: {
            [completedJourney]: insightData?.generative_insight_config?.completed_journey,
            [journeyStartCamera]: insightData?.generative_insight_config?.start_camera,
            [journeyEndCamera]: insightData?.generative_insight_config?.end_cameras,
            [alertingJourneyDuration]: insightData?.generative_insight_config?.alerting_journey_duration !== undefined && valueToTimeMapping(insightData?.generative_insight_config?.alerting_journey_duration, daysHoursMinSecTimeMapping),
        },
    };

    return insightTypesData[insightType];
};

const initialState = {
    insights: {},
    openInsight: {
        ...insightsDefaultValue
    }
};

const generativeInsightsSlice = createSlice({
    name: 'generativeInsights',
    initialState,
    reducers: {
        setGenerativeInsights: (state, action) => {
            const insightsData = action.payload;
            state.insights = insightsData;

            return state;
        },
        setOpenInsight: (state, action) => {
            const insightData = action.payload;
            let insightFormFields = {
                [insightNameField]: insightData.insight_name,
                [activeField]: insightData.active,
                [generateEvent]: insightData.generate_event,
                [insightNotesField]: insightData.insight_notes?.free_notes,
                [insightTypeField]: insightData.insight_type,
                [excludeWatchlists]: insightData.generative_insight_config?.excluded_watchlists,
                [minDetectionWidth]: insightData.generative_insight_config?.min_detection_width
            };

            insightFormFields = {
                ...insightFormFields,
                ...getInsightDataByType(insightData, insightData.insight_type)
            };

            state.openInsight = {
                ...state.openInsight,
                ...insightFormFields
            };

            return state;
        },
        addGenerativeInsight: (state, action) => {
            const { payload } = action;
            state[payload.insight_id] = payload;
        },
        removeGenerativeInsight: (state, action) => {
            delete state[action.payload.generativeInsightId];

            return state;
        },
        clearOpenInsight: (state) => {
            state.openInsight = {
                ...insightsDefaultValue
            };
        }
    }
});

export const { setGenerativeInsights, addGenerativeInsight, setOpenInsight, removeGenerativeInsight, clearOpenInsight } = generativeInsightsSlice.actions;

export default generativeInsightsSlice.reducer;