import { memo } from "react";

function TimeColumn({ timeAndDate, className }) {
    return (
        <div className={`flex flex-col items-center ${className}`}>
            <span className="whitespace-pre-line text-center">
                {timeAndDate}
            </span>
        </div>
    );
}

export default memo(TimeColumn);