import { useCallback, useState } from "react";

import { isEmpty } from "lodash";

import { resCodes } from "../ApiClients/BaseAPIClient";
import { successTxt } from "src/appConfig/Strings";

function useUIResponseHandler() {
    const [isLoading, setIsLoading] = useState(false);
    const [statusCode, setStatusCode] = useState();
    const [error, setError] = useState(false);
    const [successMsgs, setSuccessMsgs] = useState([]);
    const [data, setData] = useState(undefined);
    const [responseMessage, setResponseMessage] = useState("");

    const parseResponse = useCallback((response) => {
        setResponseMessage(response?.msg?.join(' '));
        setStatusCode(response?.statusCode);
        setError(response?.error);
        setIsLoading(false);
        setData(response?.data);
        if (response.error && !isEmpty(response.successMsgs)) {
            setSuccessMsgs(response.successMsgs);
        }
    }, [setResponseMessage, setStatusCode, setIsLoading]);

    const clearResponseHandler = useCallback((clearData = true) => {
        setResponseMessage("");
        setStatusCode();
        setError(false);
        setIsLoading(false);
        clearData && setData(undefined);
        setSuccessMsgs([]);
    }, [setResponseMessage, setStatusCode, setError, setIsLoading]);

    return { isLoading, setIsLoading, statusCode, responseMessage, error, successMsgs, parseResponse, clearResponseHandler, data, setData };
}

export default useUIResponseHandler;

export const parseResponseForUI = (error, response, data, shouldFilterSuccessList = true, endPointName) => {
    let finalError = error;
    let finalMsg = [response?.body?.metadata?.msg];
    const successList = response?.body?.metadata?.success_list;
    if (successList) {
        const errors = successList.filter(successObj => !successObj.success);
        if (errors.length) {
            const errorMessages = new Set();
            successList.forEach(item => {
                !item.success && errorMessages.add(item.msg);
            });
            finalMsg = shouldFilterSuccessList ? Array.from(errorMessages) : successList;
        } else
            finalMsg = shouldFilterSuccessList ? [successList[0].msg] : successList;
        finalError = errors.length !== 0;
    }


    return ({
        endPointName,
        statusCode: response?.status,
        error: finalError,
        msg: finalMsg,
        data: data
    });
};

export const responsesListHandler = (responseList) => {
    const failedResponses = responseList.filter(response => !isEmpty(response) && response.statusCode !== resCodes.ok);

    if (failedResponses.length > 0) {
        const uniqueMessages = new Set(failedResponses.flatMap(({ msg }) => msg));
        const parsedResponseErrMsg = Array.from(uniqueMessages).join(', ');
        failedResponses[0].msg = [parsedResponseErrMsg];

        return failedResponses[0];
    } else {
        return responseList;
    }
};

export function collectMultiResponses(responsesData) {
    const validResponses = responsesData.filter(response => response && response.response !== null);

    let hasError = false;
    const messages = [];
    const successMessages = new Set();

    validResponses.forEach((response) => {
        if (response?.error) {
            hasError = true;
            messages.push(`${response?.endPointName}: ${response.msg}\n`);
        } else {
            successMessages.add(`${response?.endPointName}: ${response?.msg || successTxt}\n`);
        }
    });

    if (!hasError) {
        messages.push(resCodes.success);
    }

    return { statusCode: resCodes.ok, error: hasError, msg: messages, successMsgs: Array.from(successMessages) };
}