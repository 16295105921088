import { useCallback, useMemo, useState } from "react";

import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import AffixInputField from "./AffixInputField";

function PasswordInputField({ value, model, label, className, dataTestId, defaultValue = "", onChange = null, disabled = false, ...props }) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = useCallback(() => {
        setShowPassword((show) => !show);
    }, []);

    const handleMouseDownPassword = useCallback((event) => {
        event.preventDefault();
    }, []);

    const suffix = useMemo(() => (
        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
            {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
    ), [showPassword, handleClickShowPassword, handleMouseDownPassword]);

    return (
        <AffixInputField
            className={`bg-white ${className}`}
            type={showPassword ? "text" : "password"}
            label={label}
            fullWidth={true}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            dataTestId={dataTestId}
            variant="outlined"
            disabled={disabled}
            model={{ suffix, ...model }}
            {...props}
        />
    );
}

export default PasswordInputField;