"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GenerativeInsightRecord model module.
 * @module model/GenerativeInsightRecord
 * @version 0.37.0
 */
class GenerativeInsightRecord {
  /**
   * Constructs a new <code>GenerativeInsightRecord</code>.
   * @alias module:model/GenerativeInsightRecord
   * @param generativeInsight {Object} The insight configuration that triggered this event
   * @param currAppearance {Object} The id of the appearance that triggered the generative insight
   * @param insightRelatedTime {Object} The time metric related to the insight
   */
  constructor(generativeInsight, currAppearance, insightRelatedTime) {
    GenerativeInsightRecord.initialize(this, generativeInsight, currAppearance, insightRelatedTime);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, generativeInsight, currAppearance, insightRelatedTime) {
    obj['generative_insight'] = generativeInsight;
    obj['curr_appearance'] = currAppearance;
    obj['insight_related_time'] = insightRelatedTime;
  }

  /**
   * Constructs a <code>GenerativeInsightRecord</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GenerativeInsightRecord} obj Optional instance to populate.
   * @return {module:model/GenerativeInsightRecord} The populated <code>GenerativeInsightRecord</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GenerativeInsightRecord();
      if (data.hasOwnProperty('event_type')) {
        obj['event_type'] = _ApiClient.default.convertToType(data['event_type'], Object);
      }
      if (data.hasOwnProperty('event_id')) {
        obj['event_id'] = _ApiClient.default.convertToType(data['event_id'], Object);
      }
      if (data.hasOwnProperty('msg_send_timestamp')) {
        obj['msg_send_timestamp'] = _ApiClient.default.convertToType(data['msg_send_timestamp'], Object);
      }
      if (data.hasOwnProperty('generative_insight')) {
        obj['generative_insight'] = _ApiClient.default.convertToType(data['generative_insight'], Object);
      }
      if (data.hasOwnProperty('curr_appearance')) {
        obj['curr_appearance'] = _ApiClient.default.convertToType(data['curr_appearance'], Object);
      }
      if (data.hasOwnProperty('related_appearances')) {
        obj['related_appearances'] = _ApiClient.default.convertToType(data['related_appearances'], Object);
      }
      if (data.hasOwnProperty('insight_related_time')) {
        obj['insight_related_time'] = _ApiClient.default.convertToType(data['insight_related_time'], Object);
      }
      if (data.hasOwnProperty('insight_record_id')) {
        obj['insight_record_id'] = _ApiClient.default.convertToType(data['insight_record_id'], Object);
      }
      if (data.hasOwnProperty('utc_time_recorded')) {
        obj['utc_time_recorded'] = _ApiClient.default.convertToType(data['utc_time_recorded'], Object);
      }
      if (data.hasOwnProperty('save_insight_time')) {
        obj['save_insight_time'] = _ApiClient.default.convertToType(data['save_insight_time'], Object);
      }
      if (data.hasOwnProperty('gender')) {
        obj['gender'] = _ApiClient.default.convertToType(data['gender'], Object);
      }
      if (data.hasOwnProperty('age_group')) {
        obj['age_group'] = _ApiClient.default.convertToType(data['age_group'], Object);
      }
      if (data.hasOwnProperty('person_id')) {
        obj['person_id'] = _ApiClient.default.convertToType(data['person_id'], Object);
      }
      if (data.hasOwnProperty('poi_id')) {
        obj['poi_id'] = _ApiClient.default.convertToType(data['poi_id'], Object);
      }
      if (data.hasOwnProperty('camera_id')) {
        obj['camera_id'] = _ApiClient.default.convertToType(data['camera_id'], Object);
      }
      if (data.hasOwnProperty('number_of_appearances')) {
        obj['number_of_appearances'] = _ApiClient.default.convertToType(data['number_of_appearances'], Object);
      }
      if (data.hasOwnProperty('analysis_mode')) {
        obj['analysis_mode'] = _ApiClient.default.convertToType(data['analysis_mode'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GenerativeInsightRecord</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GenerativeInsightRecord</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of GenerativeInsightRecord.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
GenerativeInsightRecord.RequiredProperties = ["generative_insight", "curr_appearance", "insight_related_time"];

/**
 * @member {Object} event_type
 */
GenerativeInsightRecord.prototype['event_type'] = undefined;

/**
 * a unique id of this event
 * @member {Object} event_id
 */
GenerativeInsightRecord.prototype['event_id'] = undefined;

/**
 * @member {Object} msg_send_timestamp
 */
GenerativeInsightRecord.prototype['msg_send_timestamp'] = undefined;

/**
 * The insight configuration that triggered this event
 * @member {Object} generative_insight
 */
GenerativeInsightRecord.prototype['generative_insight'] = undefined;

/**
 * The id of the appearance that triggered the generative insight
 * @member {Object} curr_appearance
 */
GenerativeInsightRecord.prototype['curr_appearance'] = undefined;

/**
 * List of associated appearances
 * @member {Object} related_appearances
 */
GenerativeInsightRecord.prototype['related_appearances'] = undefined;

/**
 * The time metric related to the insight
 * @member {Object} insight_related_time
 */
GenerativeInsightRecord.prototype['insight_related_time'] = undefined;

/**
 * The id of the generative insight record
 * @member {Object} insight_record_id
 */
GenerativeInsightRecord.prototype['insight_record_id'] = undefined;

/**
 * The UTC time at which the insight was recorded
 * @member {Object} utc_time_recorded
 */
GenerativeInsightRecord.prototype['utc_time_recorded'] = undefined;

/**
 * The UTC time at which the insight was saved
 * @member {Object} save_insight_time
 */
GenerativeInsightRecord.prototype['save_insight_time'] = undefined;

/**
 * The gender of the appearance related to the insight
 * @member {Object} gender
 */
GenerativeInsightRecord.prototype['gender'] = undefined;

/**
 * The age group of the appearance related to the insight
 * @member {Object} age_group
 */
GenerativeInsightRecord.prototype['age_group'] = undefined;

/**
 * The person_id of the appearance related to the insight
 * @member {Object} person_id
 */
GenerativeInsightRecord.prototype['person_id'] = undefined;

/**
 * The poi_id of the appearance related to the insight
 * @member {Object} poi_id
 */
GenerativeInsightRecord.prototype['poi_id'] = undefined;

/**
 * The camera id that the insight was generated from.
 * @member {Object} camera_id
 */
GenerativeInsightRecord.prototype['camera_id'] = undefined;

/**
 * The max number of crowd together
 * @member {Object} number_of_appearances
 */
GenerativeInsightRecord.prototype['number_of_appearances'] = undefined;

/**
 * Analysis mode of this camera, options: ['forensics', 'live']
 * @member {Object} analysis_mode
 */
GenerativeInsightRecord.prototype['analysis_mode'] = undefined;
var _default = exports.default = GenerativeInsightRecord;