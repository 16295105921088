"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetSearchFaceSettingsResponse = _interopRequireDefault(require("../model/GetSearchFaceSettingsResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Controller API
 * ## Control & Management - Status Endpoint allows the user of the API to see the very basic data of the deployment - and notably, the deployment_id - even if the user has no credentials or valid license - License Endpoint allows an authenticated user to view current license usage  ## System Settings **Privacy settings** - 'Privacy' is a global system mode that allows a user of the system to restrict the biometric data that is saved and/or outputted by SC. - The global system mode, as well as the specific privacy settings that control which biometric data the system is permitted to save and output, are configured using the privacy settings API of Controller Service.  **SearchFace settings** - Settings that apply to the SearchFace endpoints (in POI DB & History DB)  **Analysis settings** - System-wide settings related to the analysis of streams  ## Data Retention Perform actions & retrieve information regarding the data saved by the system - how many records exist, what is the max capcity, enable automatic clearing etc. 
 *
 * The version of the OpenAPI document: 0.36.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* SearchFaceSettings service.
* @module api/SearchFaceSettingsApi
* @version 0.36.2
*/
class SearchFaceSettingsApi {
  /**
  * Constructs a new SearchFaceSettingsApi. 
  * @alias module:api/SearchFaceSettingsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the getSearchFaceSettings operation.
   * @callback module:api/SearchFaceSettingsApi~getSearchFaceSettingsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetSearchFaceSettingsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Search Face Settings
   * Get the system-wide configuration of face-searching via the API. applies to searching in History DB as well as POI DB.
   * @param {module:api/SearchFaceSettingsApi~getSearchFaceSettingsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetSearchFaceSettingsResponse}
   */
  getSearchFaceSettings(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetSearchFaceSettingsResponse.default;
    return this.apiClient.callApi('/settings/search_face/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setSearchFaceSettings operation.
   * @callback module:api/SearchFaceSettingsApi~setSearchFaceSettingsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetSearchFaceSettingsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set Search Face Settings
   * Set the system-wide configuration of face-searching via the API. applies to searching in History DB as well as POI DB.
   * @param {Object} body 
   * @param {module:api/SearchFaceSettingsApi~setSearchFaceSettingsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetSearchFaceSettingsResponse}
   */
  setSearchFaceSettings(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setSearchFaceSettings");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetSearchFaceSettingsResponse.default;
    return this.apiClient.callApi('/settings/search_face/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = SearchFaceSettingsApi;