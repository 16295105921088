import { useCallback } from "react";

import store from "src/appConfig/configureStore";
import SelectFilter from "./SelectFilter";
import { allAppearancesFilterOption, detectHeader } from "src/appConfig/Strings";
import { matchOutcomes } from "@/Logic/ApiClients/EventsClient/EventsClient.model";

const allAppearancesFilterSelectionOption = [{
    display_name: allAppearancesFilterOption,
    watchlist_id: null
}];

function DetectionFilter({
    filterType,
    ...props
}) {

    const getWatchlistFilters = useCallback(() => {
        const state = store.getState();
        const roleMatchesFilters = state.UsersStore.roleDataFilters?.[filterType]?.matches;
        if (roleMatchesFilters?.length)
            return roleMatchesFilters.find(matchOutcome => matchOutcome.match_outcome === matchOutcomes.not_determined && matchOutcome.watchlist_id === null) ?
                allAppearancesFilterSelectionOption : [];
        else
            return allAppearancesFilterSelectionOption;
    }, [filterType]);

    return (
        <SelectFilter
            getOptionsFunc={getWatchlistFilters}
            header={detectHeader}
            {...props}
        />
    );
}

export default DetectionFilter;