import store from "../../../../appConfig/configureStore";

import { allCamerasFilterOption, camerasHeader } from "../../../../appConfig/Strings";
import SelectFilter from "./SelectFilter";
import { memo, useCallback } from "react";

const allCamerasFilterSelectionOption = {
    description: allCamerasFilterOption,
    camera_id: null
};

function CamerasFilter({ filterType, ...props }) {
    const getCameraFilters = useCallback(() => {
        const state = store.getState();
        const allCameras = Object.values(state.CamerasStore.cameras);
        const roleCameraFilters = state.UsersStore.roleDataFilters?.[filterType]?.camera_ids;
        return roleCameraFilters?.length ? allCameras.filter(camera => roleCameraFilters.includes(camera.camera_id)) : [allCamerasFilterSelectionOption, ...allCameras];
    }, [filterType]);

    return (
        <SelectFilter
            getOptionsFunc={getCameraFilters}
            header={camerasHeader}
            allowEmpty={false}
            {...props}
        />
    );
}

export default memo(CamerasFilter);