import Tooltip from '@mui/material/Tooltip';

import { tooltipWhiteStyle } from "../Styles/Tooltip.styles";

function TooltipWrapper({ tooltipText, isDisabled, children, ...props }) {
    return (
        <div className="flex">
            <Tooltip
                title={isDisabled ? tooltipText : ''}
                disableHoverListener={!isDisabled} {...props}
                slotProps={tooltipWhiteStyle}
            >
                <div>
                    {children}
                </div>
            </Tooltip>
        </div>
    );
}

export default TooltipWrapper;