"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GenerativeInsight model module.
 * @module model/GenerativeInsight
 * @version 0.37.0
 */
class GenerativeInsight {
  /**
   * Constructs a new <code>GenerativeInsight</code>.
   * @alias module:model/GenerativeInsight
   * @param insightName {Object} The name of the generative insight
   * @param insightType {Object} The type of the specific insight
   */
  constructor(insightName, insightType) {
    GenerativeInsight.initialize(this, insightName, insightType);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, insightName, insightType) {
    obj['insight_name'] = insightName;
    obj['insight_type'] = insightType;
  }

  /**
   * Constructs a <code>GenerativeInsight</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GenerativeInsight} obj Optional instance to populate.
   * @return {module:model/GenerativeInsight} The populated <code>GenerativeInsight</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GenerativeInsight();
      if (data.hasOwnProperty('insight_name')) {
        obj['insight_name'] = _ApiClient.default.convertToType(data['insight_name'], Object);
      }
      if (data.hasOwnProperty('generative_insight_config')) {
        obj['generative_insight_config'] = _ApiClient.default.convertToType(data['generative_insight_config'], Object);
      }
      if (data.hasOwnProperty('active')) {
        obj['active'] = _ApiClient.default.convertToType(data['active'], Object);
      }
      if (data.hasOwnProperty('insight_notes')) {
        obj['insight_notes'] = _ApiClient.default.convertToType(data['insight_notes'], Object);
      }
      if (data.hasOwnProperty('generate_event')) {
        obj['generate_event'] = _ApiClient.default.convertToType(data['generate_event'], Object);
      }
      if (data.hasOwnProperty('insight_type')) {
        obj['insight_type'] = _ApiClient.default.convertToType(data['insight_type'], Object);
      }
      if (data.hasOwnProperty('insight_id')) {
        obj['insight_id'] = _ApiClient.default.convertToType(data['insight_id'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GenerativeInsight</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GenerativeInsight</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of GenerativeInsight.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
GenerativeInsight.RequiredProperties = ["insight_name", "insight_type"];

/**
 * The name of the generative insight
 * @member {Object} insight_name
 */
GenerativeInsight.prototype['insight_name'] = undefined;

/**
 * the config related for this insight
 * @member {Object} generative_insight_config
 */
GenerativeInsight.prototype['generative_insight_config'] = undefined;

/**
 * Sate should be active, options: [0, 1, 2, 3]
 * @member {Object} active
 */
GenerativeInsight.prototype['active'] = undefined;

/**
 * Insight free notes
 * @member {Object} insight_notes
 */
GenerativeInsight.prototype['insight_notes'] = undefined;

/**
 * Get Event alert for a generative insight
 * @member {Object} generate_event
 */
GenerativeInsight.prototype['generate_event'] = undefined;

/**
 * The type of the specific insight
 * @member {Object} insight_type
 */
GenerativeInsight.prototype['insight_type'] = undefined;

/**
 * The id of the alert
 * @member {Object} insight_id
 */
GenerativeInsight.prototype['insight_id'] = undefined;
var _default = exports.default = GenerativeInsight;