"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AuditDBQuery = _interopRequireDefault(require("../model/AuditDBQuery"));
var _AuditRecord = _interopRequireDefault(require("../model/AuditRecord"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetAuditsResponse = _interopRequireDefault(require("../model/GetAuditsResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* AuditManagement service.
* @module api/AuditManagementApi
* @version 0.35.0
*/
class AuditManagementApi {
  /**
  * Constructs a new AuditManagementApi. 
  * @alias module:api/AuditManagementApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addAuditRecord operation.
   * @callback module:api/AuditManagementApi~addAuditRecordCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add Audit Record
   * Add a new record to the audits
   * @param {module:model/AuditRecord} auditRecord 
   * @param {module:api/AuditManagementApi~addAuditRecordCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  addAuditRecord(auditRecord, callback) {
    let postBody = auditRecord;
    // verify the required parameter 'auditRecord' is set
    if (auditRecord === undefined || auditRecord === null) {
      throw new Error("Missing the required parameter 'auditRecord' when calling addAuditRecord");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/auth/audit/add/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getAuditRecords operation.
   * @callback module:api/AuditManagementApi~getAuditRecordsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAuditsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Audit Records
   * Query the existing Audit records. Note that the relation between multiple query filters is an **and** relation.
   * @param {module:model/AuditDBQuery} auditDBQuery 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.countAuditLogs  (default to true)
   * @param {String} opts.afterId 
   * @param {Number} opts.limit  (default to 300)
   * @param {module:api/AuditManagementApi~getAuditRecordsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAuditsResponse}
   */
  getAuditRecords(auditDBQuery, opts, callback) {
    opts = opts || {};
    let postBody = auditDBQuery;
    // verify the required parameter 'auditDBQuery' is set
    if (auditDBQuery === undefined || auditDBQuery === null) {
      throw new Error("Missing the required parameter 'auditDBQuery' when calling getAuditRecords");
    }
    let pathParams = {};
    let queryParams = {
      'count_audit_logs': opts['countAuditLogs'],
      'after_id': opts['afterId'],
      'limit': opts['limit']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetAuditsResponse.default;
    return this.apiClient.callApi('/auth/audit/get/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = AuditManagementApi;