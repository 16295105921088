import {
    clearHistoryDB,
    searchFacesInPoiDb,
    addFacesToPoi,
    addPermissionToRole,
    addWatchlistToPoiRecord,
    analysisControl,
    analysisModeCtrl,
    analyzeFace,
    appearancePrivacyProfile,
    auditControl,
    clearFamesDb,
    clearHistoryInsightRecord,
    clearPoiDb,
    clearWatchlist,
    compareFaces,
    countAppearances,
    createAppearancesRecord,
    createAppearancesRecordFromFace,
    createLiveCameraRecord,
    createNewAppearancePrivacyProfileRecord,
    createNewAuditRecord,
    createNewInsightRecord,
    createNewInvestigateVideoRecord,
    createNewPoiRecord,
    createNewRoleRecord,
    createNewUserRecord,
    createNewWatchlistPrivacyProfileRecord,
    createWatchlistRecord,
    detectFacesInAnImg,
    editEventsSessionFilters,
    eventsSessionControl,
    faceAnalysis,
    frameStorageControl,
    getAnalysisMode,
    getAnalysisSettings,
    getAppearanceFrameData,
    getAppearancePrivacyProfileRecord,
    getAppearanceRecord,
    getAuditRecord,
    getAuditRetentionSettings,
    getAutoClearRetentionSettings,
    getDefaultAppearancePrivacyProfileRecord,
    getDefaultWatchlistPrivacyProfileRecord,
    getFeatureOutcomes,
    getFrameStorageRetentionSettings,
    getHistoryInsightRecord,
    getHistoryRetentionSettings,
    getInsightRecord,
    getInsightsRetentionSettings,
    getInvestigateVideoPrivacyProfile,
    getInvestigateVideoRecord,
    getLicenseData,
    getLiveCamera,
    getLiveCameraPrivacyProfile,
    getNodeRecord,
    getPoiFaces,
    getPoiRecords,
    getRoleRecord,
    getSearchFaceSettings,
    getUniqAppearances,
    getUserRecord,
    getWatchlistPrivacyProfileRecord,
    getWatchlistRecord,
    historyAppearances,
    historyDbControl,
    historyInsights,
    historySearchControl,
    insightManagement,
    investigateVideoManagement,
    labelAppearancesRecord,
    licenseInfo,
    listAllAppearancePrivacyProfileRecord,
    listAllInsightRecords,
    listAllInvestigateVideos,
    listAllLiveCameras,
    listAllNodeRecords,
    listAllPermissionRecords,
    listAllPoiRecords,
    listAllRoleRecords,
    listAllUsersRecords,
    listAllWatchlist,
    listAllWatchlistPrivacyProfileRecords,
    liveCameraManagement,
    multiServerControl,
    openNewEventsSession,
    poiDbControl,
    poiManagement,
    removeAllInvestigateVideoRecord,
    removeAllLiveCameraRecord,
    removeAppearanceFrameData,
    removeAppearancePrivacyProfileRecord,
    removeAppearancesRecord,
    removeFacesFromPoi,
    removeHistoryInsightRecord,
    removeInsightRecord,
    removeInvestigateVideoRecord,
    removeLiveCameraRecord,
    removePermissionFromRole,
    removePoiRecord,
    removeRoleRecord,
    removeUserRecord,
    removeWatchlistFromPoiRec,
    removeWatchlistPrivacyProfileRecord,
    removeWatchlistRecord,
    retainAppearancesRecord,
    retentionControl,
    roleManagement,
    searchFaceInPoiDb,
    searchHistoryProximity,
    searchImgInHistoryGroupRes,
    searchImgInHistoryUnGroupRes,
    searchImgInHistoryUnGroupResMultiFaces,
    searchPoiInHistoryGroupRes,
    searchPoiInHistoryUnGroupRes,
    searchPoiRecordByDispName,
    setAnalysisMode,
    setAnalysisSettings,
    setAuditRetentionSettings,
    setAutoClearRetentionSettings,
    setDefaultAppearancePrivacyProfileRecord,
    setDefaultWatchlistPrivacyProfileRecord,
    setFrameStorageRetentionSettings,
    setHistoryRetentionSettings,
    setInsightsRetentionSettings,
    setInvestigateVideoPrivacyProfile,
    setLiveCameraPrivacyProfile,
    setSearchFaceSettings,
    startAnalysisInvestigateVideo,
    startAnalysisLiveCamera,
    stopAnalysisInvestigateVideo,
    stopAnalysisLiveCamera,
    terminateUserSession,
    updateAppearancePrivacyProfileRecord,
    updateInsightRecord,
    updateInvestigateVideoRecord,
    updateLiveCameraRecord,
    updatePoiRecord,
    updateRoleRecord,
    updateUserRecord,
    updateWatchlistPrivacyProfileRecord,
    updateWatchlistRecord,
    userManagement,
    validateEventsSessionFilters,
    watchlistManagement,
    watchlistPrivacyProfile,
    addEventToHistoryTxt,
    searchLiveCameras,
    searchInvestigateVideos,
    getInsightsAnalytics,
    listAllCameraGroupsTxt,
    getCameraGroupTxt,
    createCameraGroupTxt,
    updateCameraGroupTxt,
    removeCameraGroupTxt,
    addCameraToGroupTxt,
    removeCameraFromGroupTxt,
    clearCameraGroupTxt,
    cameraGroupsManagementLabel
} from "src/appConfig/Strings";

export const permissionsKeys = {
    setLiveCameraPrivacy: "SetCameraPrivacy.live",
    setInvestigateCameraPrivacy: "SetCameraPrivacy.forensics",
};

export const permissionsKeysToStringsMapping = {
    //POIDBService
    //---POIManagement
    POIManagement: poiManagement,
    ListAllPOIs: listAllPoiRecords,
    GetPOIs: getPoiRecords,
    AddPOIs: createNewPoiRecord,
    UpdatePOI: updatePoiRecord,
    AddPOIToWatchlist: addWatchlistToPoiRecord,
    RemovePOIFromWatchlist: removeWatchlistFromPoiRec,
    GetFacesOfPOI: getPoiFaces,
    AddFacesToPOI: addFacesToPoi,
    RemoveFacesFromPOI: removeFacesFromPoi,
    RemovePOIs: removePoiRecord,
    SearchPOIs: searchPoiRecordByDispName,
    //---WatchlistManagement
    WatchlistManagement: watchlistManagement,
    ListAllWatchlists: listAllWatchlist,
    GetWatchlist: getWatchlistRecord,
    AddWatchlist: createWatchlistRecord,
    UpdateWatchlist: updateWatchlistRecord,
    RemoveWatchlist: removeWatchlistRecord,
    ClearWatchlist: clearWatchlist,
    //---FaceAnalysis
    FaceAnalysis: faceAnalysis,
    AnalyzeFace: analyzeFace,
    DetectFaces: detectFacesInAnImg,
    SearchFace: searchFaceInPoiDb,
    SearchFaces: searchFacesInPoiDb,
    CompareFaces: compareFaces,
    //---POIDBControl
    POIDBControl: poiDbControl,
    ClearPOIDB: clearPoiDb,
    //CameraService
    //---LiveCameraManagement
    LiveCameraManagement: liveCameraManagement,
    "ListAllCameras.live": listAllLiveCameras,
    "GetCamera.live": getLiveCamera,
    "CreateCamera.live": createLiveCameraRecord,
    "UpdateCamera.live": updateLiveCameraRecord,
    "RemoveCamera.live": removeLiveCameraRecord,
    "RemoveAllCameras.live": removeAllLiveCameraRecord,
    "StartCamera.live": startAnalysisLiveCamera,
    "StopCamera.live": stopAnalysisLiveCamera,
    "GetCameraPrivacy.live": getLiveCameraPrivacyProfile,
    [permissionsKeys.setLiveCameraPrivacy]: setLiveCameraPrivacyProfile,
    "SearchCameras.live": searchLiveCameras,
    //---ForensicsCameraManagement
    ForensicsCameraManagement: investigateVideoManagement,
    "ListAllCameras.forensics": listAllInvestigateVideos,
    "GetCamera.forensics": getInvestigateVideoRecord,
    "CreateCamera.forensics": createNewInvestigateVideoRecord,
    "UpdateCamera.forensics": updateInvestigateVideoRecord,
    "RemoveCamera.forensics": removeInvestigateVideoRecord,
    "RemoveAllCameras.forensics": removeAllInvestigateVideoRecord,
    "StartCamera.forensics": startAnalysisInvestigateVideo,
    "StopCamera.forensics": stopAnalysisInvestigateVideo,
    "GetCameraPrivacy.forensics": getInvestigateVideoPrivacyProfile,
    [permissionsKeys.setInvestigateCameraPrivacy]: setInvestigateVideoPrivacyProfile,
    "SearchCameras.forensics": searchInvestigateVideos,
    //--CameraGroupsManagement
    CameraGroupsManagement: cameraGroupsManagementLabel,
    ListAllCameraGroups: listAllCameraGroupsTxt,
    GetCameraGroup: getCameraGroupTxt,
    CreateCameraGroup: createCameraGroupTxt,
    UpdateCameraGroup: updateCameraGroupTxt,
    RemoveCameraGroup: removeCameraGroupTxt,
    AddCameraToGroup: addCameraToGroupTxt,
    RemoveCameraFromGroup: removeCameraFromGroupTxt,
    ClearCameraGroup: clearCameraGroupTxt,
    //---AnalysisModeControl
    AnalysisModeControl: analysisModeCtrl,
    GetAnalysisMode: getAnalysisMode,
    SetAnalysisMode: setAnalysisMode,
    //HistoryDBService
    //---HistoryAppearances
    HistoryAppearances: historyAppearances,
    GetAppearances: getAppearanceRecord,
    GetPersons: getUniqAppearances,
    CountAppearances: countAppearances,
    LabelAppearance: labelAppearancesRecord,
    SetAppearancesRetention: retainAppearancesRecord,
    AddAppearancesFromImage: createAppearancesRecord,
    AddFaceToHistory: createAppearancesRecordFromFace,
    RemoveAppearances: removeAppearancesRecord,
    AddEventToHistory: addEventToHistoryTxt,
    ProximityHistoryInsight: searchHistoryProximity,
    //---HistorySearchControl
    HistorySearchControl: historySearchControl,
    SearchImgInHistory: searchImgInHistoryGroupRes,
    SearchAppImgInHistory: searchImgInHistoryUnGroupRes,
    SearchAppearancesInImage: searchImgInHistoryUnGroupResMultiFaces,
    SearchPOIInHistory: searchPoiInHistoryGroupRes,
    SearchPOIInAppearances: searchPoiInHistoryUnGroupRes,
    //---HistoryInsights
    HistoryInsights: historyInsights,
    GetGenerativeInsights: getHistoryInsightRecord,
    DeleteGenerativeInsightRecord: removeHistoryInsightRecord,
    ClearGenerativeInsightRecords: clearHistoryInsightRecord,
    //---HistoryDBControl
    HistoryDBControl: historyDbControl,
    ClearHistoryDB: clearHistoryDB,
    //UsersService
    //---UserManagement
    UserManagement: userManagement,
    ListAllUsers: listAllUsersRecords,
    GetUser: getUserRecord,
    AddUser: createNewUserRecord,
    UpdateUser: updateUserRecord,
    DeleteUser: removeUserRecord,
    TerminateUserSession: terminateUserSession,
    //---RoleManagement
    RoleManagement: roleManagement,
    ListAllRoles: listAllRoleRecords,
    ListAllPermissions: listAllPermissionRecords,
    GetRole: getRoleRecord,
    AddRole: createNewRoleRecord,
    UpdateRole: updateRoleRecord,
    AddRolePermission: addPermissionToRole,
    RemoveRolePermission: removePermissionFromRole,
    DeleteRole: removeRoleRecord,
    //---AuditControl
    AuditControl: auditControl,
    GetAuditRecords: getAuditRecord,
    AddAuditRecord: createNewAuditRecord,
    //ControllerService
    //--LicenseInformation
    LicenseInformation: licenseInfo,
    GetLicense: getLicenseData,
    //--WatchlistPrivacyInformation
    WatchlistPrivacyInformation: watchlistPrivacyProfile,
    ListAllPOIPrivacyProfiles: listAllWatchlistPrivacyProfileRecords,
    GetPOIPrivacyProfile: getWatchlistPrivacyProfileRecord,
    AddPOIPrivacyProfile: createNewWatchlistPrivacyProfileRecord,
    UpdatePOIPrivacyProfile: updateWatchlistPrivacyProfileRecord,
    RemovePOIPrivacyProfile: removeWatchlistPrivacyProfileRecord,
    GetDefaultPOIPrivacyProfile: getDefaultWatchlistPrivacyProfileRecord,
    SetDefaultPOIPrivacyProfile: setDefaultWatchlistPrivacyProfileRecord,
    //--AppearancePrivacyProfile
    AppearancePrivacyProfile: appearancePrivacyProfile,
    ListAllAppearancePrivacyProfiles: listAllAppearancePrivacyProfileRecord,
    GetAppearancePrivacyProfile: getAppearancePrivacyProfileRecord,
    AddAppearancePrivacyProfile: createNewAppearancePrivacyProfileRecord,
    UpdateAppearancePrivacyProfile: updateAppearancePrivacyProfileRecord,
    RemoveAppearancePrivacyProfile: removeAppearancePrivacyProfileRecord,
    GetDefaultAppearancePrivacyProfile: getDefaultAppearancePrivacyProfileRecord,
    SetDefaultAppearancePrivacyProfile: setDefaultAppearancePrivacyProfileRecord,
    //--InsightsManagement
    InsightsManagement: insightManagement,
    ListGenerativeInsights: listAllInsightRecords,
    GetGenerativeInsight: getInsightRecord,
    CreateGenerativeInsight: createNewInsightRecord,
    UpdateGenerativeInsight: updateInsightRecord,
    RemoveGenerativeInsight: removeInsightRecord,
    GetGenerativeInsightsStatistics: getInsightsAnalytics,
    //--RetentionControl
    RetentionControl: retentionControl,
    GetHistoryRetention: getHistoryRetentionSettings,
    SetHistoryRetention: setHistoryRetentionSettings,
    GetAutoClearRetention: getAutoClearRetentionSettings,
    SetAutoClearRetention: setAutoClearRetentionSettings,
    GetFramesStorageRetention: getFrameStorageRetentionSettings,
    SetFramesStorageRetention: setFrameStorageRetentionSettings,
    GetAuditRetention: getAuditRetentionSettings,
    SetAuditRetention: setAuditRetentionSettings,
    GetGenerativeInsightsRetention: getInsightsRetentionSettings,
    SetGenerativeInsightsRetention: setInsightsRetentionSettings,
    //--AnalysisControl
    AnalysisControl: analysisControl,
    GetSearchFaceSettings: getSearchFaceSettings,
    SetSearchFaceSettings: setSearchFaceSettings,
    GetAnalysisSettings: getAnalysisSettings,
    SetAnalysisSettings: setAnalysisSettings,
    GetFeatureOutcomes: getFeatureOutcomes,
    //--MultiServerControl
    MultiServerControl: multiServerControl,
    ListAllNodes: listAllNodeRecords,
    GetNodeData: getNodeRecord,
    //FrameStorageControl
    //--FrameStorageControl
    FrameStorageControl: frameStorageControl,
    GetAppearanceFramesData: getAppearanceFrameData,
    DeleteFramesDataForAppearance: removeAppearanceFrameData,
    ClearAllFramesData: clearFamesDb,
    //--EventsService
    EventsService: eventsSessionControl,
    ListenToEventStream: openNewEventsSession,
    EditSessionFilters: editEventsSessionFilters,
    ValidateSession: validateEventsSessionFilters,
};

export const BuiltInPermissionsModel = {
    GetLicense: {
        name: "GetLicense",
        required: true
    },
    ListenToEventStream: {
        name: "ListenToEventStream",
        required: true
    },
    GetFeatureOutcomes: {
        name: "GetFeatureOutcomes",
        required: true
    },
    GetAnalysisMode: {
        name: "GetAnalysisMode",
        required: true
    },
    ValidateSession: {
        name: "ValidateSession",
        required: true
    },
    EditSessionFilters: {
        name: "EditSessionFilters",
        required: true
    }
};