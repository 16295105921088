"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _MsgMetadata = _interopRequireDefault(require("./MsgMetadata"));
var _RemoveCameraModel = _interopRequireDefault(require("./RemoveCameraModel"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The RemoveCameraResponse model module.
 * @module model/RemoveCameraResponse
 * @version 0.32.2
 */
class RemoveCameraResponse {
  /**
   * Constructs a new <code>RemoveCameraResponse</code>.
   * @alias module:model/RemoveCameraResponse
   * @param metadata {module:model/MsgMetadata} 
   * @param data {module:model/RemoveCameraModel} 
   */
  constructor(metadata, data) {
    RemoveCameraResponse.initialize(this, metadata, data);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, metadata, data) {
    obj['metadata'] = metadata;
    obj['data'] = data;
  }

  /**
   * Constructs a <code>RemoveCameraResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RemoveCameraResponse} obj Optional instance to populate.
   * @return {module:model/RemoveCameraResponse} The populated <code>RemoveCameraResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RemoveCameraResponse();
      if (data.hasOwnProperty('metadata')) {
        obj['metadata'] = _MsgMetadata.default.constructFromObject(data['metadata']);
      }
      if (data.hasOwnProperty('data')) {
        obj['data'] = _RemoveCameraModel.default.constructFromObject(data['data']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>RemoveCameraResponse</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RemoveCameraResponse</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of RemoveCameraResponse.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // validate the optional field `metadata`
    if (data['metadata']) {
      // data not null
      _MsgMetadata.default.validateJSON(data['metadata']);
    }
    // validate the optional field `data`
    if (data['data']) {
      // data not null
      _RemoveCameraModel.default.validateJSON(data['data']);
    }
    return true;
  }
}
RemoveCameraResponse.RequiredProperties = ["metadata", "data"];

/**
 * @member {module:model/MsgMetadata} metadata
 */
RemoveCameraResponse.prototype['metadata'] = undefined;

/**
 * @member {module:model/RemoveCameraModel} data
 */
RemoveCameraResponse.prototype['data'] = undefined;
var _default = exports.default = RemoveCameraResponse;