import { memo, useCallback, useMemo, useState } from "react";

import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import IconArrowsLeft from "src/assets/Icons/NavIcons/arrows_left.svg";

import SideNavigationList from "../SideNavigationList/SideNavigationList";

function SideNavigation() {
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);

    const toggleDrawer = useCallback(() => (
        setIsSideBarOpen((prevState) => !prevState)
    ), []);

    const sideBarContainerWidth = useMemo(() => isSideBarOpen ? 'w-main-side-bar-open' : 'w-main-side-bar-close', [isSideBarOpen]);
    const sideBarContentWidth = useMemo(() => isSideBarOpen ? 'w-full' : 'w-main-side-bar-close', [isSideBarOpen]);

    return (
        <div className={`bg-deep-space-blue ${sideBarContainerWidth} top-auto z-auto `}>
            <div className={`bg-inherit ${sideBarContainerWidth} fixed max-h-screen min-h-screen overflow-y-auto`}>
                <div className={`mt-1 pr-2 ${sideBarContentWidth} flex justify-end`}>
                    <IconButton onClick={toggleDrawer}>
                        {isSideBarOpen ? <img src={IconArrowsLeft} alt="" /> : <MenuIcon className="fill-gentle-azure" />}
                    </IconButton>
                </div>
                <hr className={`h-0.5 divide-gentle-azure bg-gentle-azure ${sideBarContentWidth} `} />
                <div className="w-full">
                    <SideNavigationList
                        setIsSideBarOpen={setIsSideBarOpen}
                        isSideBarOpen={isSideBarOpen}
                        sideBarContentWidth={sideBarContentWidth}
                    />
                </div>
            </div>
        </div>
    );
}

export default memo(SideNavigation);