import { AnalysisQuality } from "smart_cameras_cameras_api";

import { ageDetector, average, cameraName, cameraNotesHeader, file, genderDetector, livenessDetector, password, pixelsSuffix, quality, rtsp, speed, usb, username } from "src/appConfig/Strings";

// todo-sdk
export const capturingSourceMode = {
    rtsp: "RTSP",
    file: "Video",
    usb: "Device",
    edge: "Edge"
};

// todo-sdk
export const RequiredFaceAttributesConfig = {
    age_group: "age_group",
    gender: "gender",
    liveness: "liveness"
};

export const AnalysisQualityEnum = new AnalysisQuality();
export const videoDisplayResolutions = [
    { name: "640 x 480", resolution: 640 },
    { name: "1024 x 786", resolution: 1024 },
];

export const CapturingSourceOptions = [
    {
        label: rtsp,
        option: capturingSourceMode.rtsp
    },
    {
        label: usb,
        option: capturingSourceMode.usb
    },
    {
        label: file,
        option: capturingSourceMode.file
    }
];

export const AnalysisOptions = [
    {
        label: speed,
        option: AnalysisQualityEnum.fast
    },
    {
        label: average,
        option: AnalysisQualityEnum.balanced
    },
    {
        label: quality,
        option: AnalysisQualityEnum.deep
    }
];

export const RequiredFaceAttributesOptions = [
    {
        label: ageDetector,
        AttributeOption: RequiredFaceAttributesConfig.age_group
    },
    {
        label: genderDetector,
        AttributeOption: RequiredFaceAttributesConfig.gender
    },
    {
        label: livenessDetector,
        AttributeOption: RequiredFaceAttributesConfig.liveness
    }
];

export const cameraFormFieldsNames = {
    cameraName: "description",
    captureConfigMode: "mode",
    captureAddress: "capture_address",
    cameraStreamUsername: "camera_stream_username",
    cameraStreamPassword: "camera_stream_password",
    nodeId: "node_id",
    analysisQuality: "analysis_quality",
    watchlists: "watchlists",
    threshold: "face_recognition_threshold",
    cameraNotes: "camera_notes",
    requiredFaceAttributes: "required_face_attributes",
    recordFrames: "record_frames",
    minDetectionWidth: "min_detection_width",
    authorizedMinFaceWidth: "authorized_min_face_width",
    ageGenderMinWidth: "age_gender",
    livenessMinWidth: "liveness",
    videoDisplayResolution: "rebroadcast_frame_width",
    privacyProfileId: "privacy_profile_properties",
    streamMaxFaces: "stream_max_faces"
};

export const additionalUsbModeModel = {
    dataKey: "cameraLocation",
    textField: "cameraName",
};

export const fileCaptureAddressModel = {
    getId: option => option.id,
    getLabel: option => option.label
};

export const CameraFormModel = {
    [cameraFormFieldsNames.cameraName]: {
        name: cameraFormFieldsNames.cameraName,
        type: "text",
        placeholder: cameraName,
        required: true,
    },
    [cameraFormFieldsNames.captureConfigMode]: {
        name: cameraFormFieldsNames.captureConfigMode,
        type: "text",
        required: true,
    },
    [cameraFormFieldsNames.captureAddress]: {
        name: cameraFormFieldsNames.captureAddress,
        required: true,
    },
    [cameraFormFieldsNames.cameraStreamUsername]: {
        name: cameraFormFieldsNames.cameraStreamUsername,
        type: "text",
        placeholder: username,
        autoComplete: "new-password",
    },
    [cameraFormFieldsNames.cameraStreamPassword]: {
        name: cameraFormFieldsNames.cameraStreamPassword,
        placeholder: password,
        autoComplete: "new-password",
    },
    [cameraFormFieldsNames.nodeId]: {
        name: cameraFormFieldsNames.nodeId,
        dataKey: "node_id",
        textField: "node_id",
        required: true
    },
    [cameraFormFieldsNames.analysisQuality]: {
        name: cameraFormFieldsNames.analysisQuality,
        required: true
    },
    [cameraFormFieldsNames.watchlists]: {
        name: cameraFormFieldsNames.watchlists,
        getId: (watchlist) => watchlist.watchlist_id,
        getLabel: (watchlist) => watchlist.display_name,
    },
    [cameraFormFieldsNames.threshold]: {
        name: cameraFormFieldsNames.threshold,
        type: "number",
        min: 1,
        max: 100,
        required: true
    },
    [cameraFormFieldsNames.cameraNotes]: {
        name: cameraFormFieldsNames.cameraNotes,
        placeholder: cameraNotesHeader,
        type: "textarea",
    },
    [cameraFormFieldsNames.requiredFaceAttributes]: {
        name: cameraFormFieldsNames.requiredFaceAttributes,
    },
    [cameraFormFieldsNames.recordFrames]: {
        name: cameraFormFieldsNames.recordFrames,
    },
    [cameraFormFieldsNames.minDetectionWidth]: {
        name: cameraFormFieldsNames.minDetectionWidth,
        type: "number",
        min: 0,
        suffix: pixelsSuffix,
        required: true
    },
    [cameraFormFieldsNames.authorizedMinFaceWidth]: {
        name: cameraFormFieldsNames.authorizedMinFaceWidth,
        type: "number",
        min: 0,
        suffix: pixelsSuffix,
        required: true
    },
    [cameraFormFieldsNames.ageGenderMinWidth]: {
        name: cameraFormFieldsNames.ageGenderMinWidth,
        type: "number",
        min: 0,
        suffix: pixelsSuffix,
        required: true
    },
    [cameraFormFieldsNames.livenessMinWidth]: {
        name: cameraFormFieldsNames.livenessMinWidth,
        type: "number",
        min: 0,
        suffix: pixelsSuffix,
        required: true
    },
    [cameraFormFieldsNames.videoDisplayResolution]: {
        name: cameraFormFieldsNames.videoDisplayResolution,
        textField: "name",
        dataKey: "resolution",
        required: true
    },
    [cameraFormFieldsNames.privacyProfileId]: {
        name: cameraFormFieldsNames.privacyProfileId,
        textField: "name",
        dataKey: "profile_id",
    },
    [cameraFormFieldsNames.streamMaxFaces]: (isLiveAnalysisMode) => ({
        name: cameraFormFieldsNames.streamMaxFaces,
        type: "number",
        min: -1,
        required: isLiveAnalysisMode
    })
};