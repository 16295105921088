import { camera, cameraNotesHeader, matchSimilarityLabel, poiDisplayName, poiNotes, selectWatchlistHeader, timeRecordedLabel, video, videoNotesHeader, watchlistNotes } from "src/appConfig/Strings";
import { processMatchData } from "./PoiInfoColumn";
import OverflowingText from "../../Tooltips/OverflowingText";
import InfoTooltip from "../../Tooltips/InfoTooltip";
import { ColoredWatchlistName, isCurrWlTypeWhitelist } from "./CropImagesColumns/CropImagesColumns.utils";

function PoiAlertDetailColumn({ isLiveAnalysisMode, matchData }) {
    const { displayName, notes: poiNotes, cameraName, cameraNotes, timeRecorded, confidence, watchlists } = matchData;
    const mostSignificantWl = watchlists[0];
    const watchlistNotesData = mostSignificantWl.watchlist_notes?.free_notes;

    return (
        <div className="grid w-full grid-cols-2 gap-x-1 gap-y-2">
            {!isCurrWlTypeWhitelist(mostSignificantWl) && displayName && (
                <>
                    <span className="flex items-center font-bold">
                        {poiDisplayName}
                        {poiNotes && <InfoTooltip iconClassName="text-dodger-blue" description={poiNotes} />} :
                    </span>
                    <span><OverflowingText text={displayName} /></span>
                </>
            )}

            <div className="flex items-center">
                <span className="flex items-center font-bold">
                    {selectWatchlistHeader}
                    {watchlistNotesData && <InfoTooltip iconClassName="text-dodger-blue" description={watchlistNotesData} />}:
                </span>
            </div>
            <div className="flex items-center">
                <span className="w-min max-w-full">
                    {!isCurrWlTypeWhitelist(mostSignificantWl) ? (
                        <ColoredWatchlistName colorHex={mostSignificantWl.display_color} name={mostSignificantWl?.display_name} />
                    ) : (
                        <OverflowingText className="w-full" text={mostSignificantWl?.display_name} />
                    )}
                </span>
            </div>

            <span className="flex items-center font-bold">
                {isLiveAnalysisMode ? camera : video}
                {cameraNotes && <InfoTooltip iconClassName="text-dodger-blue" description={cameraNotes} />}:
            </span>
            <span>
                <OverflowingText text={cameraName} />
            </span>

            <span className="font-bold">{timeRecordedLabel}:</span>
            <span>{timeRecorded}</span>

            {!isCurrWlTypeWhitelist(mostSignificantWl) && confidence && (
                <>
                    <span className="font-bold">{matchSimilarityLabel}:</span>
                    <span>{confidence}</span>
                </>
            )}
        </div>
    );
}

export default PoiAlertDetailColumn;

export const poiAlertDetailValueGetter = (isLiveAnalysisMode, matchData, row) => {
    const { watchlists, displayName, notes: poiNotesData, cameraName, cameraNotes, timeRecorded, confidence } = processMatchData(matchData, row);
    const mostSignificantWl = watchlists[0];
    const watchlistNotesData = mostSignificantWl.watchlist_notes?.free_notes;
    let result = '';

    if (!isCurrWlTypeWhitelist(mostSignificantWl) && displayName) { // Poi Name and Notes
        result += `${poiDisplayName}: ${displayName}\n`;
        if (poiNotesData) {
            result += `${poiNotes}: ${poiNotesData}\n`;
        }
    }

    result += `${selectWatchlistHeader}: ${mostSignificantWl.display_name}\n`; // Watchlist Name and Notes
    if (watchlistNotesData) {
        result += `${watchlistNotes}: ${watchlistNotesData}\n`;
    }

    result += `${isLiveAnalysisMode ? camera : video}: ${cameraName}\n`; // Camera/Video Name and Notes
    if (cameraNotes) {
        result += `${isLiveAnalysisMode ? cameraNotesHeader : videoNotesHeader}: ${cameraNotes}\n`;
    }

    result += `${timeRecordedLabel}: ${timeRecorded}\n`; // Time Recorded

    if (!isCurrWlTypeWhitelist(mostSignificantWl) && confidence) { // Match Similarity Confidence
        result += `${matchSimilarityLabel}: ${confidence}\n`;
    }

    return result;
};