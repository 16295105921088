import { useState, useEffect, useCallback } from 'react';

import { isFormFieldsChanged, isLoadingSignalEvent } from "src/appConfig/customEvents";

function useDisabledButton(formID) {
    const [isDisabled, setIsDisabled] = useState(false);

    const handleDisableSubmitBtn = useCallback((event) => {
        const shouldUpdateIsDisabled = (event.detail.isEdit && !event.detail.isChanged) || event.detail.isLoading;
        setIsDisabled(shouldUpdateIsDisabled);
    }, []);

    useEffect(() => {
        if (!formID) {
            return;
        }

        document.addEventListener(isFormFieldsChanged, handleDisableSubmitBtn);
        document.addEventListener(isLoadingSignalEvent, handleDisableSubmitBtn);

        return () => {
            document.removeEventListener(isLoadingSignalEvent, handleDisableSubmitBtn);
            document.removeEventListener(isFormFieldsChanged, handleDisableSubmitBtn);
        };
    }, [formID, handleDisableSubmitBtn]);

    return isDisabled;
}

export default useDisabledButton;