import CropImage from "@/Components/Common/Images/CropImage";
import { getPoiDisplayInfo, renderNotInWatchlist } from "./CropImagesColumns.utils";

export function FaceCropImagesColumns({ crop_data: cropData }) {
    return (
        <CropImage srcImage={cropData?.face_crop_img} />
    );
}

export function POICropImagesColumns({ match_data: matchData }) {
    let resComponent = null;
    const { isTxtData, poiDisplayInfo } = getPoiDisplayInfo(matchData);

    if (isTxtData) {
        resComponent = renderNotInWatchlist(poiDisplayInfo.colorHex, poiDisplayInfo.data);
    } else if (poiDisplayInfo.isImgExit) {
        resComponent = (
            <CropImage srcImage={poiDisplayInfo.data} />
        );
    }

    return resComponent;
}