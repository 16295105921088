"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _LoginResponse = _interopRequireDefault(require("../model/LoginResponse"));
var _RefreshResponse = _interopRequireDefault(require("../model/RefreshResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Authentication service.
* @module api/AuthenticationApi
* @version 0.35.0
*/
class AuthenticationApi {
  /**
  * Constructs a new AuthenticationApi. 
  * @alias module:api/AuthenticationApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the loginUser operation.
   * @callback module:api/AuthenticationApi~loginUserCallback
   * @param {String} error Error message, if any.
   * @param {module:model/LoginResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Login User
   * Authenticate the username & password provided with this request and return OAuth2 access_token to be used in any subsequent API call, together with the data of the logged-in user
   * @param {String} username 
   * @param {String} password 
   * @param {Object} opts Optional parameters
   * @param {Number} opts.sessionTime 
   * @param {String} opts.newPassword 
   * @param {Boolean} opts.forceTerminate  (default to false)
   * @param {module:api/AuthenticationApi~loginUserCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/LoginResponse}
   */
  loginUser(username, password, opts, callback) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'username' is set
    if (username === undefined || username === null) {
      throw new Error("Missing the required parameter 'username' when calling loginUser");
    }
    // verify the required parameter 'password' is set
    if (password === undefined || password === null) {
      throw new Error("Missing the required parameter 'password' when calling loginUser");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'username': username,
      'password': password,
      'session_time': opts['sessionTime'],
      'new_password': opts['newPassword'],
      'force_terminate': opts['forceTerminate']
    };
    let authNames = [];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _LoginResponse.default;
    return this.apiClient.callApi('/auth/login/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the logoutUser operation.
   * @callback module:api/AuthenticationApi~logoutUserCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Logout User
   * Logout the user associated with the access_token provided with this request's Authorization headers. The access_token will no longer be valid.
   * @param {module:api/AuthenticationApi~logoutUserCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  logoutUser(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/auth/logout/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the refreshToken operation.
   * @callback module:api/AuthenticationApi~refreshTokenCallback
   * @param {String} error Error message, if any.
   * @param {module:model/RefreshResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Refresh User
   * When a user login, an access token is returned. If the token is not used (in an API call) for a specific amount of time, it expires, thus requiring the user to login again and receive a new token. This endpoint allows to just refresh the token provided with this request's Authorization headers.
   * @param {module:api/AuthenticationApi~refreshTokenCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/RefreshResponse}
   */
  refreshToken(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _RefreshResponse.default;
    return this.apiClient.callApi('/auth/refresh/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = AuthenticationApi;