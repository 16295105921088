export function createDelayPromise(seconds) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

export function downloadFile(data, fileName, fileType) {
    const blob = new Blob([data], { type: fileType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}