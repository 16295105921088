"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DroppedCropsRecord = _interopRequireDefault(require("./DroppedCropsRecord"));
var _DroppedFramesRecord = _interopRequireDefault(require("./DroppedFramesRecord"));
var _FrameLatencyRecord = _interopRequireDefault(require("./FrameLatencyRecord"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MngrRecord model module.
 * @module model/MngrRecord
 * @version 0.32.2
 */
class MngrRecord {
  /**
   * Constructs a new <code>MngrRecord</code>.
   * @alias module:model/MngrRecord
   * @param name {String} 
   */
  constructor(name) {
    MngrRecord.initialize(this, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, name) {
    obj['name'] = name;
  }

  /**
   * Constructs a <code>MngrRecord</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MngrRecord} obj Optional instance to populate.
   * @return {module:model/MngrRecord} The populated <code>MngrRecord</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MngrRecord();
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('dropped_crops_record')) {
        obj['dropped_crops_record'] = _DroppedCropsRecord.default.constructFromObject(data['dropped_crops_record']);
      }
      if (data.hasOwnProperty('dropped_frames_record')) {
        obj['dropped_frames_record'] = _DroppedFramesRecord.default.constructFromObject(data['dropped_frames_record']);
      }
      if (data.hasOwnProperty('frame_latency_record')) {
        obj['frame_latency_record'] = _FrameLatencyRecord.default.constructFromObject(data['frame_latency_record']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MngrRecord</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MngrRecord</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of MngrRecord.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // validate the optional field `dropped_crops_record`
    if (data['dropped_crops_record']) {
      // data not null
      _DroppedCropsRecord.default.validateJSON(data['dropped_crops_record']);
    }
    // validate the optional field `dropped_frames_record`
    if (data['dropped_frames_record']) {
      // data not null
      _DroppedFramesRecord.default.validateJSON(data['dropped_frames_record']);
    }
    // validate the optional field `frame_latency_record`
    if (data['frame_latency_record']) {
      // data not null
      _FrameLatencyRecord.default.validateJSON(data['frame_latency_record']);
    }
    return true;
  }
}
MngrRecord.RequiredProperties = ["name"];

/**
 * @member {String} name
 */
MngrRecord.prototype['name'] = undefined;

/**
 * @member {module:model/DroppedCropsRecord} dropped_crops_record
 */
MngrRecord.prototype['dropped_crops_record'] = undefined;

/**
 * @member {module:model/DroppedFramesRecord} dropped_frames_record
 */
MngrRecord.prototype['dropped_frames_record'] = undefined;

/**
 * @member {module:model/FrameLatencyRecord} frame_latency_record
 */
MngrRecord.prototype['frame_latency_record'] = undefined;
var _default = exports.default = MngrRecord;