"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _FeatureOutcomesResponse = _interopRequireDefault(require("../model/FeatureOutcomesResponse"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetAnalysisSettingsResponse = _interopRequireDefault(require("../model/GetAnalysisSettingsResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Controller API
 * ## Control & Management - Status Endpoint allows the user of the API to see the very basic data of the deployment - and notably, the deployment_id - even if the user has no credentials or valid license - License Endpoint allows an authenticated user to view current license usage  ## System Settings **Privacy settings** - 'Privacy' is a global system mode that allows a user of the system to restrict the biometric data that is saved and/or outputted by SC. - The global system mode, as well as the specific privacy settings that control which biometric data the system is permitted to save and output, are configured using the privacy settings API of Controller Service.  **SearchFace settings** - Settings that apply to the SearchFace endpoints (in POI DB & History DB)  **Analysis settings** - System-wide settings related to the analysis of streams  ## Data Retention Perform actions & retrieve information regarding the data saved by the system - how many records exist, what is the max capcity, enable automatic clearing etc. 
 *
 * The version of the OpenAPI document: 0.36.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* AnalysisSettings service.
* @module api/AnalysisSettingsApi
* @version 0.36.2
*/
class AnalysisSettingsApi {
  /**
  * Constructs a new AnalysisSettingsApi. 
  * @alias module:api/AnalysisSettingsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the getAnalysisSettings operation.
   * @callback module:api/AnalysisSettingsApi~getAnalysisSettingsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAnalysisSettingsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Analysis Settings
   * Get Analysis configuration.
   * @param {module:api/AnalysisSettingsApi~getAnalysisSettingsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAnalysisSettingsResponse}
   */
  getAnalysisSettings(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetAnalysisSettingsResponse.default;
    return this.apiClient.callApi('/settings/analysis/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getFeatureOutcomes operation.
   * @callback module:api/AnalysisSettingsApi~getFeatureOutcomesCallback
   * @param {String} error Error message, if any.
   * @param {module:model/FeatureOutcomesResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Features Outcomes
   * Get all possible face features outcomes.
   * @param {module:api/AnalysisSettingsApi~getFeatureOutcomesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/FeatureOutcomesResponse}
   */
  getFeatureOutcomes(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _FeatureOutcomesResponse.default;
    return this.apiClient.callApi('/settings/analysis/feature_outcomes/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setAnalysisSettings operation.
   * @callback module:api/AnalysisSettingsApi~setAnalysisSettingsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAnalysisSettingsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set Analysis Settings
   * Set general analysis configuration
   * @param {Object} body 
   * @param {module:api/AnalysisSettingsApi~setAnalysisSettingsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAnalysisSettingsResponse}
   */
  setAnalysisSettings(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setAnalysisSettings");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetAnalysisSettingsResponse.default;
    return this.apiClient.callApi('/settings/analysis/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = AnalysisSettingsApi;