import { baseCamerasFieldNames, baseLiveCamerasModel, baseVideosModel } from "../../BaseModels";

export const loiteringFormFieldsNames = {
    ...baseCamerasFieldNames,
    alertingLoiteringDuration: "alerting_loitering_duration",
};

export const LoiteringInsightFormModel = {
    [loiteringFormFieldsNames.cameras]: {
        ...baseLiveCamerasModel
    },
    [loiteringFormFieldsNames.videos]: {
        ...baseVideosModel
    },
    [loiteringFormFieldsNames.alertingLoiteringDuration]: {
        name: loiteringFormFieldsNames.alertingLoiteringDuration,
        type: "number",
        min: 0,
        required: true,
    },
};