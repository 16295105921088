import { createSlice } from '@reduxjs/toolkit';
import { omit } from "lodash";

import { PoiFormFieldsNames, poiConsentValues } from "@/Components/POIs/CreateEditPOI/CreateEditPOI.model";

const { poiId, watchlists, consent, notes, displayImage } = PoiFormFieldsNames;

export const CreatePOIDefaultValues = {
    [consent]: poiConsentValues.not_reviewed
};

const initialState = {
    openPoi: {
        ...CreatePOIDefaultValues
    },
    OpenPOIFailedFacesData: {},
    openPoiDuplicateFaceData: {},
    poisPage: {
        poisData: {},
        totalPois: 0,
        watchlistInfo: {
            watchlistId: null,
            afterId: null,
            searchedName: null
        }
    },
};

const POIStoreSlice = createSlice({
    name: 'poiStore',
    initialState,
    reducers: {
        setOpenPoi: (state, action) => {
            const { poiData } = action.payload;
            const faces = poiData.faces.reduce((accumulatedData, { img, face_id, face_attributes }) => {
                accumulatedData[face_id] = { face_id, img, face_attributes };

                return accumulatedData;
            }, {});

            const poiFormFields = {
                [poiId]: poiData.display_name,
                [notes]: poiData.poi_notes.free_notes,
                [watchlists]: poiData.poi_watchlists,
                [consent]: poiData.poi_consent.consent,
                [displayImage]: poiData.display_img,
                faces
            };

            state.openPoi = {
                ...state.openPoi,
                ...poiFormFields
            };

            return state;
        },
        clearOpenPoi: (state) => {
            state.openPoi = {
                ...CreatePOIDefaultValues
            };
        },
        setOpenPOIFailedFacesData: (state, action) => {
            const failedFacesParsedResponse = action.payload;
            state.OpenPOIFailedFacesData = failedFacesParsedResponse;

            return state;
        },
        clearOpenPOIFailedFacesData: (state) => {
            state.OpenPOIFailedFacesData = {};
        },
        setPoisPage: (state, action) => {
            const { poisData, totalPois } = action.payload;
            state.poisPage.poisData = poisData;
            state.poisPage.totalPois = totalPois;
        },
        removePoiFromPoisPage: (state, action) => {
            const { updatedPoiId } = action.payload;
            const pois = state.poisPage.poisData;
            const updatedPoisData = omit(pois, updatedPoiId);
            state.poisPage.poisData = updatedPoisData;
            state.poisPage.totalPois = state.poisPage.totalPois - 1;

            return state;
        },
        updatePoisPage: (state, action) => {
            const { poisData, totalPois, currWatchlistId, currAfterId, searchedName } = action.payload;

            state.poisPage = {
                ...state.poisPage,
                ...(poisData !== undefined && { poisData }),
                ...(totalPois !== undefined && { totalPois }),
                watchlistInfo: {
                    ...state.poisPage.watchlistInfo,
                    ...(currWatchlistId !== undefined && { watchlistId: currWatchlistId }),
                    ...(currAfterId !== undefined && { afterId: currAfterId }),
                    ...(searchedName !== undefined && { searchedName }),
                }
            };

            return state;
        },
        clearPoisPage: (state, action) => {
            state.poisPage = {
                poisData: {},
                totalPois: 0,
                watchlistInfo: {
                    watchlistId: null,
                    afterId: null,
                    searchedName: null
                }
            };

            return state;
        }
    }
});

export const { setOpenPoi, clearOpenPoi, setOpenPOIFailedFacesData, clearOpenPOIFailedFacesData, setPoisPage, removePoiFromPoisPage, updatePoisPage, clearPoisPage } = POIStoreSlice.actions;

export default POIStoreSlice.reducer;