"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CameraPrivacyProperties = _interopRequireDefault(require("./CameraPrivacyProperties"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PrivacyProfileProperties1 model module.
 * @module model/PrivacyProfileProperties1
 * @version 0.32.2
 */
class PrivacyProfileProperties1 {
  /**
   * Constructs a new <code>PrivacyProfileProperties1</code>.
   * Privacy profile of the camera
   * @alias module:model/PrivacyProfileProperties1
   * @implements module:model/CameraPrivacyProperties
   * @param privacyProfileId {String} The appearance privacy profile of this camera
   * @param privacyProfileName {String} The name of the appearance privacy profile of this camera
   */
  constructor(privacyProfileId, privacyProfileName) {
    _CameraPrivacyProperties.default.initialize(this, privacyProfileId, privacyProfileName);
    PrivacyProfileProperties1.initialize(this, privacyProfileId, privacyProfileName);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, privacyProfileId, privacyProfileName) {
    obj['privacy_profile_id'] = privacyProfileId;
    obj['privacy_profile_name'] = privacyProfileName;
  }

  /**
   * Constructs a <code>PrivacyProfileProperties1</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PrivacyProfileProperties1} obj Optional instance to populate.
   * @return {module:model/PrivacyProfileProperties1} The populated <code>PrivacyProfileProperties1</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PrivacyProfileProperties1();
      _CameraPrivacyProperties.default.constructFromObject(data, obj);
      if (data.hasOwnProperty('privacy_profile_id')) {
        obj['privacy_profile_id'] = _ApiClient.default.convertToType(data['privacy_profile_id'], 'String');
      }
      if (data.hasOwnProperty('privacy_profile_name')) {
        obj['privacy_profile_name'] = _ApiClient.default.convertToType(data['privacy_profile_name'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PrivacyProfileProperties1</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PrivacyProfileProperties1</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of PrivacyProfileProperties1.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['privacy_profile_id'] && !(typeof data['privacy_profile_id'] === 'string' || data['privacy_profile_id'] instanceof String)) {
      throw new Error("Expected the field `privacy_profile_id` to be a primitive type in the JSON string but got " + data['privacy_profile_id']);
    }
    // ensure the json data is a string
    if (data['privacy_profile_name'] && !(typeof data['privacy_profile_name'] === 'string' || data['privacy_profile_name'] instanceof String)) {
      throw new Error("Expected the field `privacy_profile_name` to be a primitive type in the JSON string but got " + data['privacy_profile_name']);
    }
    return true;
  }
}
PrivacyProfileProperties1.RequiredProperties = ["privacy_profile_id", "privacy_profile_name"];

/**
 * The appearance privacy profile of this camera
 * @member {String} privacy_profile_id
 */
PrivacyProfileProperties1.prototype['privacy_profile_id'] = undefined;

/**
 * The name of the appearance privacy profile of this camera
 * @member {String} privacy_profile_name
 */
PrivacyProfileProperties1.prototype['privacy_profile_name'] = undefined;

// Implement CameraPrivacyProperties interface:
/**
 * The appearance privacy profile of this camera
 * @member {String} privacy_profile_id
 */
_CameraPrivacyProperties.default.prototype['privacy_profile_id'] = undefined;
/**
 * The name of the appearance privacy profile of this camera
 * @member {String} privacy_profile_name
 */
_CameraPrivacyProperties.default.prototype['privacy_profile_name'] = undefined;
var _default = exports.default = PrivacyProfileProperties1;