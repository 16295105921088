import { useCallback, useContext, useMemo } from "react";

import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";

import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { ReactComponent as CreateCameraIcon } from "src/assets/Icons/Cameras/icon_create_camera.svg";

import { camerasHeader, changeAnalysisMode, confirm, confirmation, createCameraLabel, createVideo, disabledConfTooltip, disabledConfTooltipCamerasFilter, displayHeader, editCamera, editVideo, save, videos } from "src/appConfig/Strings";
import { CamerasListModel } from "./CamerasList.model";
import { clearOpenCamera, setCameras } from "@/Logic/Redux/Stores/CamerasStore";
import { investigateAnalysisMode, liveAnalysisMode } from "@/Logic/ApiClients/AnalysisModeClient";
import ApiContext from "@/Logic/Context/apiContext";
import CamerasListContextMenu from "./CamerasListContextMenu";
import { areCamerasInStatusList } from "@/Logic/ApiClients/CamerasClient";
import { closeFloatingModal, runnersNames } from "@/Logic/Redux/Stores/BaseRunnersStore";
import MainDialog from "@/Components/Common/Modals/MainDialog";
import ExtendedDataGrid from "@/Components/Common/MUITables/ExtendedDataGrid/ExtendedDataGrid";
import StreamDisplay from "@/Components/Display/StreamDisplay";
import MainLayout from "@/Components/Common/Layout/MainLayout/MainLayout";
import MainLayoutPanel from "@/Components/Common/Layout/MainLayout/MainLayoutPanel";
import SwitchablePanel from "@/Components/Common/PanelGroup/SwitchablePanel";
import CreateEditCamera from "../CreateCamera/CreateEditCamera/CreateEditCamera";
import { clearEventsTableMuiFilters } from "@/Logic/Redux/Stores/EventsFiltersStore";
import { specificOptionSelected } from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";
import { EventsWorkerInterface } from "@/Logic/WebWorkers/WorkersInterfaces";
import { cameraDialogID, changeAnalysisModeDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";
import { GenerateToolbar } from "@/Components/Common/MUITables/TablesLogic";

function CamerasList({ cameras, cameraID, hasAnalyzingCameras, isLiveAnalysisMode, startAllInProgress, isMultiServerMode, currEventFilters, clearOpenCamera, closeFloatingModal, setCameras, clearEventsTableMuiFilters }) {
    const { clientsManager } = useContext(ApiContext);
    const [searchParams] = useSearchParams();

    const analysisModeSwitchCleanup = useCallback(async () => {
        closeFloatingModal({ runnerName: runnersNames.camerasStarter });
        setCameras({});
        clearEventsTableMuiFilters();
        await clientsManager.camerasClient.listAllCameras();
        clientsManager.eventsClientWorker.postMessage([
            EventsWorkerInterface.resetSession, []
        ]);

    }, [clearEventsTableMuiFilters, clientsManager.camerasClient, clientsManager.eventsClientWorker, closeFloatingModal, setCameras]);

    const analysisModeSwitch = useCallback(async () => {
        await clientsManager.analysisModeClient.setAnalysisMode(isLiveAnalysisMode);
        analysisModeSwitchCleanup();
    }, [analysisModeSwitchCleanup, clientsManager.analysisModeClient, isLiveAnalysisMode]);

    const createEditCameraDialog = useMemo(() => {
        let headerTitle = "";
        if (isLiveAnalysisMode) {
            headerTitle = cameraID ? editCamera : createCameraLabel;
        } else {
            headerTitle = cameraID ? editVideo : createVideo;
        }

        return (
            <div className="ml-auto">
                <MainDialog
                    dialogID={cameraDialogID}
                    formID={createCameraLabel}
                    headerTitle={headerTitle}
                    triggerIcon={<CreateCameraIcon className="stroke-white" data-testid="create-camera-icon" />}
                    footerSubmitBtnText={cameraID ? save : undefined}
                    onExit={() => clearOpenCamera()}
                >
                    <CreateEditCamera data-testid="create-edit-camera" searchParams={searchParams} />
                </MainDialog>
            </div>
        );
    }, [cameraID, clearOpenCamera, isLiveAnalysisMode, searchParams]);

    const analysisModeSwitchDialog = useMemo(() => {
        let tooltipMessage;
        const oppositeAnalysisModeString = isLiveAnalysisMode ? investigateAnalysisMode : liveAnalysisMode;
        const msg = `${changeAnalysisMode} ${oppositeAnalysisModeString}`;
        const hasCameraFilters = currEventFilters.camera_ids.radioValue === specificOptionSelected;

        if (hasAnalyzingCameras) {
            tooltipMessage = disabledConfTooltip;
        } else if (hasCameraFilters) {
            tooltipMessage = disabledConfTooltipCamerasFilter;
        } else {
            tooltipMessage = msg;
        }

        return (
            <div className="ml-auto flex items-center">
                <MainDialog
                    dialogID={changeAnalysisModeDialogId}
                    iconDisabled={hasAnalyzingCameras || startAllInProgress || hasCameraFilters}
                    headerTitle={msg}
                    triggerIcon={isLiveAnalysisMode ? <OndemandVideoIcon className="text-white" /> : <CameraOutdoorIcon className="text-white" />}
                    footerSubmitBtnText={confirm}
                    onSubmit={async () => await analysisModeSwitch()}
                    tooltipText={tooltipMessage}
                >
                    {`${confirmation} ${changeAnalysisMode.toLowerCase()} ${oppositeAnalysisModeString}`}
                </MainDialog>
                <CamerasListContextMenu />
            </div>
        );
    }, [analysisModeSwitch, currEventFilters, hasAnalyzingCameras, isLiveAnalysisMode, startAllInProgress]);

    return (
        <MainLayout>
            <MainLayoutPanel headerTitle={isLiveAnalysisMode ? camerasHeader : videos} headerContent={createEditCameraDialog}>
                <ExtendedDataGrid
                    data-testid="cameras-table"
                    {...CamerasListModel(Object.values(cameras), isMultiServerMode, isLiveAnalysisMode)}
                    slots={{ toolbar: () => <GenerateToolbar shouldShowExport={false} /> }}
                />
            </MainLayoutPanel>
            <MainLayoutPanel isGrowEnabled={false} headerContent={analysisModeSwitchDialog}>
                <SwitchablePanel headerTitle={displayHeader}>
                    <StreamDisplay data-testid="cameras-display" screenId={camerasHeader} />
                </SwitchablePanel>
            </MainLayoutPanel>
        </MainLayout>
    );
}

const mapDispatchToProps = {
    clearOpenCamera,
    closeFloatingModal,
    setCameras,
    clearEventsTableMuiFilters,
};

const mapStateToProps = (state, ownProps) => {
    const { cameras } = state.CamerasStore;
    const cameraID = ownProps.searchParams?.get("camera_id");
    const hasAnalyzingCameras = areCamerasInStatusList(state.CamerasStore.cameras);
    const isLiveAnalysisMode = state.ApiActionsStore.isLiveAnalysisMode;
    const startAllInProgress = state.BaseRunnersStore.camerasStarter.inWork;
    const isMultiServerMode = state.ApiActionsStore.isMultiServerMode;
    const currEventFilters = state.EventsFiltersStore.event_filters;

    return { cameras, cameraID, hasAnalyzingCameras, isLiveAnalysisMode, startAllInProgress, isMultiServerMode, currEventFilters };
};

export default connect(mapStateToProps, mapDispatchToProps)(CamerasList);