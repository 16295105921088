import { crop, generativeInsightsEventsFeed, generativeInsightsHeader, insightTime, insightsData } from "src/appConfig/Strings";
import { resultsPerEventsPage } from "src/appConfig/constants";
import { ActionColumnsDefinitions, ColumnsDefinitions } from "@/Components/Common/MUITables/Columns/ColumnsDefinitions";
import { getAllRelatedAppearances } from "../insight.utils";

export const generativeInsightSearchModelColumns = (location, removeRow) => [
    ColumnsDefinitions[insightTime],
    ColumnsDefinitions[crop],
    ColumnsDefinitions[insightsData],
    ActionColumnsDefinitions[generativeInsightsHeader]({ location, removeRow })
];

export const insightsFeedModel = {
    table: (insightsEvents) => {
        return ({
            columns: generativeInsightSearchModelColumns(generativeInsightsEventsFeed),
            rows: Object.entries(insightsEvents).map(([eventId, insightEvent]) => ({
                id: eventId,
                ...insightEvent.curr_appearance,
                [crop]: insightEvent.curr_appearance.crop_data,
                currAppearanceId: insightEvent.curr_appearance.appearance_data.appearance_id,
                ...insightEvent,
                ...getAllRelatedAppearances(insightEvent)
            })),
            rowHeight: 170,
            pageSizeOptions: [resultsPerEventsPage],
        });
    }
};