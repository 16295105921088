import moment from "moment";
import { isEmpty } from "lodash";

import { allAppearancesFilterOption, allLivenessOutcomes, appearanceExpansionTimeframe, historyDBQuery, matchedAppearancesOnly, minAppearances, poiSignature, showRetained, sortByRecent, sortByRecentTitle, sortBySeenTitle, sortOptions, useSig } from "src/appConfig/Strings";
import { ManagingWebWorkerInterface } from "../WebWorkers/WorkersInterfaces";
import { getSearchWithImageOrPoi, matchParametersFilters, parseBaseFilters, parseOutcomesFilter } from "../WebWorkers/TablesReportGenerator/SearchReportSheet/SearchReportFilters";
import { excelReportFormat } from "../Parsing/timeParsing";
import { parseTableAnalytics } from "@/Components/Common/MUITables/TablesLogic";

export class TableReportClient {
    constructor(clientsManager) {
        this.clientsManager = clientsManager;
        this.reportName = 'GridData.xlsx';
        this.reportData = null;
    }

    parseAnalyticsData(tableCategory) {
        return parseTableAnalytics[tableCategory]();
    }

    parseSearchFiltersData(filtersData) {
        const { camerasFilter, matchFilter, notMatchFilter, unauthorizedFilter, detectionFilter, analysisModeFilter, retainFilter, ageGroupFilter, genderFilter, livenessFilter, reviewedFilter, ...restSearchData } = filtersData[historyDBQuery];

        const searchWithImage = getSearchWithImageOrPoi(filtersData);
        const searchWithPoi = getSearchWithImageOrPoi(filtersData);

        const baseFilters = parseBaseFilters({ camerasFilter, matchFilter, notMatchFilter, unauthorizedFilter, detectionFilter, ageGroupFilter, genderFilter, livenessFilter });
        const matchParamsFilters = matchParametersFilters(filtersData);

        const filters = {
            ...restSearchData,
            ...baseFilters,
            ...matchParamsFilters,
            reviewedFilter: parseOutcomesFilter(reviewedFilter, allLivenessOutcomes),
            analysisModeFilter: [analysisModeFilter?.label],
            retainFilter: isEmpty(retainFilter) ? [allAppearancesFilterOption] : [showRetained],
            ...filtersData[sortOptions] ? { sortBy: filtersData[sortOptions] === sortByRecent ? [sortByRecentTitle] : [sortBySeenTitle] } : {},
            ...filtersData[minAppearances] ? { minAppearances: filtersData[minAppearances] !== -1 ? [filtersData[minAppearances]] : [""] } : {},
            ...filtersData[poiSignature] ? { useSig: filtersData[poiSignature].useSig ? [useSig] : [matchedAppearancesOnly] } : {},
            ...filtersData.POI ? { selectedPoiName: [filtersData.POI.selectedPoi.display_name] } : {},
            ...filtersData[appearanceExpansionTimeframe] ? { overlapTimeframe: filtersData[appearanceExpansionTimeframe] } : {}
        };

        return { ...searchWithImage, ...searchWithPoi, filters };
    }

    GenerateReportName(tableName) {
        const currentDateTime = moment().format(excelReportFormat);
        const parsedReportName = `[${currentDateTime}]_${tableName}.xlsx`;

        this.reportName = parsedReportName;
    }

    GenerateReportObject({ columnsHeaders, rows, filtersData = null, configReport = {}, tableCategory, shouldExportAnalytics }) {
        const parsedSearchCriteria = filtersData ? this.parseSearchFiltersData(filtersData) : null;
        const parsedAnalytics = (shouldExportAnalytics && tableCategory) ? this.parseAnalyticsData(tableCategory) : null;

        this.reportData = {
            reportName: this.reportName,
            columnsHeaders,
            rows,
            configReport,
            filtersData: parsedSearchCriteria,
            analyticsData: parsedAnalytics
        };
    }

    StartGenerateReport() {
        const tableReportWorker = this.clientsManager.workersClient.createTablesReportWorker();
        tableReportWorker.postMessage([ManagingWebWorkerInterface.init, [this.clientsManager.hostIp, this.clientsManager.token, this.reportData.configReport]]);
        tableReportWorker.postMessage([ManagingWebWorkerInterface.run, [this.reportData]]);
    }
}