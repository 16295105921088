import { memo, useContext, useEffect, useMemo } from "react";

import { connect } from "react-redux";

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import TooltipIconButton from "../../Buttons/TooltipIconButton";
import AudioManagerContext from "@/Logic/Context/audioManagerContext";
import { acknowledgeAlertLabel } from "src/appConfig/Strings";
import { updateAlertsAcknowledgement } from "@/Logic/Redux/Stores/EventsStore";

function AcknowledgeAlertColumn({ row, className, updateAlertsAcknowledgement, isRepeatedSoundExist }) {
    const { audioManager } = useContext(AudioManagerContext);
    const alertId = row.appearance_data.appearance_id;
    const isAlertAcknowledged = useMemo(() => row.acknowledgement.isAcknowledged, [row.acknowledgement.isAcknowledged]);

    const handleAcknowledgeClick = () => {
        if (isAlertAcknowledged) {
            return;
        }

        updateAlertsAcknowledgement({ alertId, isAcknowledged: true, isSoundRepeat: false });
    };

    useEffect(() => {
        if (!audioManager.shouldRepeat) {
            return;
        }

        if (!isRepeatedSoundExist) {
            audioManager.stopRepeatSound();
        }
    }, [audioManager, isRepeatedSoundExist]);

    return (
        <div className="flex items-center justify-end">
            {isAlertAcknowledged ? (
                <div className={`flex items-center ${className}`}>
                    <CheckCircleOutlineIcon className="text-dodger-blue" />
                </div>
            ) : (
                <TooltipIconButton className="text-dodger-blue" iconElement={<RadioButtonUncheckedIcon />} tooltipText={acknowledgeAlertLabel} buttonAction={handleAcknowledgeClick} />
            )}
        </div >
    );
}

const mapActionsToProps = {
    updateAlertsAcknowledgement
};

const mapStateToProps = (state) => {
    const { isRepeatedSoundExist } = state.EventsStore;

    return { isRepeatedSoundExist };
};

export default connect(mapStateToProps, mapActionsToProps)(memo(AcknowledgeAlertColumn));