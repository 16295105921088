class AudioManager {
    constructor(audioUrl) {
        if (!audioUrl) {
            console.error('Invalid audio URL');
            return;
        }
        this.audio = new Audio(audioUrl);
        this.shouldRepeat = false;
        this.isMuted = false;
        this.isPlaying = false;

        this.audio.addEventListener('ended', this.handleEnded.bind(this));
        this.audio.addEventListener('error', this.handleError.bind(this));
    }

    play() {
        this.isPlaying = true;
        this.audio.play().catch(this.handleError.bind(this));
    }

    pause() {
        if (this.audio) {
            this.audio.pause();
            this.isPlaying = false;
            this.shouldRepeat = false;
        }
    }

    stop() {
        this.cleanup();
    }

    isSoundPlaying() {
        return this.isPlaying && !this.audio.paused && this.audio.currentTime > 0 && !this.audio.ended || this.shouldRepeat;
    }

    isSoundMuted() {
        return this.isMuted;
    }

    muteSound() {
        this.isMuted = true;
        this.audio.muted = true;
    }

    unMuteSound() {
        this.isMuted = false;
        this.audio.muted = false;
    }

    playRepeatSound() {
        this.play();
        this.shouldRepeat = true;
        if (this.audio) {
            this.audio.loop = true;
        }
    }

    stopRepeatSound() {
        this.cleanup();
    }

    handleEnded() {
        if (!this.shouldRepeat) {
            this.cleanup();
        }
    }

    handleError(error) {
        console.error('Error playing audio:', error);
        this.cleanup();
    }

    cleanup() {
        if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
            this.audio.removeEventListener('ended', this.handleEnded);
            this.audio.removeEventListener('error', this.handleError);
            this.isPlaying = false;
            this.shouldRepeat = false;
            this.audio.loop = false;
        }
    }
}

export default AudioManager;