import { useCallback, useContext, useMemo } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";
import ApiContext from "@/Logic/Context/apiContext";
import { activePoiPrivacyProfile, defaultAppearanceProfile, duplicate, editProfile, remove, removeAppearanceProfileConfirmation, removePoiProfileConfirmation, setActive, setDefault, viewProfile } from "src/appConfig/Strings";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { systemPrivacyProfiles } from "@/Logic/ApiClients/PrivacyProfilesClient";
import { appearancePrivacyProfilesDialogId, watchlistPrivacyProfilesDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";

function PrivacyContextMenu({ privacyProfile, isAppearancesProfile }) {
    const { clientsManager } = useContext(ApiContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isSystemProfile = systemPrivacyProfiles.includes(privacyProfile.name);
    const setDefaultText = useMemo(() => isAppearancesProfile ? setDefault : setActive, [isAppearancesProfile]);

    const setOptions = useCallback(() => {
        let options = {};
        const shouldShowSetDefault = !privacyProfile[isAppearancesProfile ? defaultAppearanceProfile : activePoiPrivacyProfile];
        options = {
            ...(shouldShowSetDefault) && {
                [setDefaultText]: () => clientsManager.privacyProfilesClient.setDefaultPrivacyProfile(privacyProfile.profile_id, isAppearancesProfile)
            },
            [duplicate]: () => {
                if (isAppearancesProfile) {
                    navigate(`${location.pathname}?duplicate=true&profile_id=${privacyProfile.profile_id}`);
                    dispatchToggleDialogEvent(appearancePrivacyProfilesDialogId);
                } else {
                    navigate(`${location.pathname}?duplicate=true&watchlist_profile_id=${privacyProfile.profile_id}`);
                    dispatchToggleDialogEvent(watchlistPrivacyProfilesDialogId);
                }
            },
            [isSystemProfile ? viewProfile : editProfile]: () => {
                navigate(`${location.pathname}?${isAppearancesProfile ? "profile_id" : "watchlist_profile_id"}=${privacyProfile.profile_id}`);
                dispatchToggleDialogEvent(isAppearancesProfile ? appearancePrivacyProfilesDialogId : watchlistPrivacyProfilesDialogId);
            },
            ...(!isSystemProfile) && {
                [remove]: async () => await clientsManager.privacyProfilesClient.removePrivacyProfile(privacyProfile.profile_id, isAppearancesProfile)
            },
        };

        return options;
    }, [clientsManager.privacyProfilesClient, isAppearancesProfile, isSystemProfile, location.pathname, navigate, privacyProfile, setDefaultText]);

    const confirmationOptions = useMemo(() => {
        return { [remove]: { acceptButtonText: remove, confirmationText: isAppearancesProfile ? removeAppearanceProfileConfirmation : removePoiProfileConfirmation } };
    }, [isAppearancesProfile]);

    return (
        <ContextMenuButton options={setOptions()} confirmationOptions={confirmationOptions} dataTestId={`${isAppearancesProfile ? "appearances" : "watchlists"}-privacy-context-menu-${privacyProfile.name}`} />
    );
}

export default PrivacyContextMenu;