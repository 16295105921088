import { useCallback, useContext, useMemo } from "react";

import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";
import ApiContext from "@/Logic/Context/apiContext";
import { removeUser, remove, removeUserConfirmation, editUser, logoutTitle, auditUser } from "src/appConfig/Strings";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { loggedIn } from "@/Logic/ApiClients/UsersClient";
import { auditDialog, usersSettingsDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";

function UsersContextMenu({ row, systemRoleIds, currentUserId, superadminId }) {
    const { clientsManager } = useContext(ApiContext);
    const navigate = useNavigate();
    const location = useLocation();

    const setOptions = useCallback(() => {
        const options = {
            [editUser]: () => {
                navigate(`${location.pathname}?user_id=${row.user_id}`);
                dispatchToggleDialogEvent(usersSettingsDialogId);
            },
            [auditUser]: () => {
                navigate(`${location.pathname}?audit_user_id=${row.user_id}`);
                dispatchToggleDialogEvent(auditDialog);
            }
        };
        if ((row.user_id !== currentUserId) && (row.role_id !== superadminId) && (row.status === loggedIn)) {
            options[logoutTitle] = async () => {
                await clientsManager.usersClient.terminateUserSession(row.user_id);
                await clientsManager.usersClient.listAllUsers();
            };
        }

        if (!systemRoleIds.includes(row.role_id)) {
            options[removeUser] = async () => {
                await clientsManager.usersClient.deleteUser(row.user_id);
            };
        }
        return options;
    }, [clientsManager.usersClient, currentUserId, location.pathname, navigate, row.role_id, row.status, row.user_id, superadminId, systemRoleIds]);

    const confirmationOptions = useMemo(() => {
        return { [removeUser]: { acceptButtonText: remove, confirmationText: removeUserConfirmation } };
    }, []);

    return (
        <ContextMenuButton options={setOptions()} confirmationOptions={confirmationOptions} />
    );
}

const mapStateToProp = (state) => {
    const systemRoleIds = state.UsersStore.systemRoleIds;
    const currentUserId = state.ApiActionsStore.login.user?.user_id;
    const superadminId = state.UsersStore.systemRolesDetail?.superadmin;

    return { systemRoleIds, currentUserId, superadminId };
};

export default connect(mapStateToProp, null)(UsersContextMenu);