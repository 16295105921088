import { splitString } from "@/Logic/Parsing/stringsParsing";
import { age, gender, liveness, mask } from "src/appConfig/Strings";

function FaceAttributesColumn({ faceAttrList }) {

    return (
        <div className="flex flex-col">
            {faceAttrList.map(({ label, value }) => {
                if (!value) {
                    return null;
                }

                return (
                    <div key={label}>
                        <p>
                            <span className="block pb-1">
                                <b>{label}: </b> {splitString(value)}
                            </span>
                        </p>
                    </div>
                );
            })}
        </div>

    );
}

export default FaceAttributesColumn;

export function processFaceAttrColData(FaceAttrData) {
    if (!FaceAttrData) {
        return null;
    }

    const { age_group_outcome, gender_outcome, liveness_outcome, mask_outcome } = FaceAttrData;

    return [
        { label: age, value: age_group_outcome },
        { label: gender, value: gender_outcome },
        { label: liveness, value: liveness_outcome },
        { label: mask, value: mask_outcome }
    ];
}