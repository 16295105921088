import { capitalize } from "lodash";

import { generateAlertLabel, generativeInsightsHeader, generativeInsightsList, insightActive, insightAnalysisMode, insightCameras, insightName, insightNotes, insightType } from "src/appConfig/Strings";
import { ActionColumnsDefinitions, ColumnsDefinitions } from "../../Common/MUITables/Columns/ColumnsDefinitions";
import { insightActiveInvestigate, insightActiveLive, insightActiveLiveAndInvestigate, insightInactive } from "../CreateEditInsights/CreateEditInsight.model";
import { investigateAnalysisMode, liveAnalysisMode } from "@/Logic/ApiClients/AnalysisModeClient";
import store from "src/appConfig/configureStore";

export const insightStatusTextMap = {
    [insightInactive]: "",
    [insightActiveLive]: capitalize(liveAnalysisMode),
    [insightActiveInvestigate]: capitalize(investigateAnalysisMode),
    [insightActiveLiveAndInvestigate]: `${capitalize(liveAnalysisMode)} & ${capitalize(investigateAnalysisMode)}`,
};

export const insightsListModel = (rows) => {
    const isLiveAnalysisMode = store.getState().ApiActionsStore.isLiveAnalysisMode;

    const columns = [
        ColumnsDefinitions[insightName],
        ColumnsDefinitions[insightType],
        ColumnsDefinitions[insightNotes],
        ColumnsDefinitions[insightCameras](isLiveAnalysisMode),
        ColumnsDefinitions[insightActive],
        ColumnsDefinitions[generateAlertLabel],
        ColumnsDefinitions[insightAnalysisMode],
        ActionColumnsDefinitions[generativeInsightsHeader]({ location: generativeInsightsList })
    ];

    return {
        columns,
        rows: Object.keys(rows).map((row) => (
            {
                id: row,
                [insightName]: rows[row].insight_name,
                [insightType]: rows[row].insight_type,
                [insightNotes]: rows[row]?.insight_notes?.free_notes,
                [insightActive]: rows[row].active,
                [insightAnalysisMode]: insightStatusTextMap[rows[row].active],
                [generateAlertLabel]: rows[row].generate_event,
                ...rows[row].generative_insight_config
            }
        )),
        rowHeight: 50
    };
};