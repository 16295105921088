import { useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import store from "src/appConfig/configureStore";
import { alertedInsight, cameraError, message } from "src/appConfig/Strings";
import { routesUrl } from "@/Components/Common/Layout/SideBar/Routes";
import { addServerStatus } from "../../Redux/Stores/ApiActionsStore";
import { addEvent, addGenerativeInsightsEvent } from "../../Redux/Stores/EventsStore";
import { ParseArrayBuffer } from "../../Parsing/objectsParsing";
import { errorStatuses } from "../../ApiClients/CamerasClient";
import { updateCameraStatus } from "../../Redux/Stores/CamerasStore";
import { eventTypes } from "../../ApiClients/EventsClient/EventsClient.model";
import AudioManagerContext from "../../Context/audioManagerContext";
import { alertSoundHandler, handleAlertOnEvent, sortWatchlistsByRank } from "./useEventListener.utils";
import { toastUtility } from "../useToast";

function handleAppearanceEvent(appearanceEvent, audioManager) {
    sortWatchlistsByRank(appearanceEvent);
    const eventShouldBeAlerted = handleAlertOnEvent(appearanceEvent);
    if (eventShouldBeAlerted) {
        alertSoundHandler(appearanceEvent, audioManager);
    }

    store.dispatch(addEvent(appearanceEvent));
}

const defaultEventHandler = (receivedEventType, event, navigate, audioManager) => {
    switch (receivedEventType) {
        case eventTypes.appearance:
            handleAppearanceEvent(event, audioManager);
            break;
        case eventTypes.server_status:
            store.dispatch(addServerStatus(event));
            break;
        case eventTypes.generative_insight:
            toastUtility.showInfo(alertedInsight, { onClick: () => navigate(routesUrl.generativeInsightsEvents) });
            store.dispatch(addGenerativeInsightsEvent(event));
            break;
        case eventTypes.camera_status:
            store.dispatch(updateCameraStatus(event));
            if (errorStatuses.includes(event.update_data.status)) {
                toastUtility.showError(`${cameraError}: ${event.camera_data.camera_description}`);
            }
            break;
        default:
            break;
    }
};

export function parseListenerData(data) {
    const [receivedEventType, buffer] = data;
    const event = ParseArrayBuffer(buffer);

    return { receivedEventType, event };
}

const useEventListener = (eventsClientWorker, eventHandler = defaultEventHandler) => {
    const { audioManager } = useContext(AudioManagerContext);
    const navigate = useNavigate();

    useEffect(() => {
        const eventParser = ({ data }) => {
            const { receivedEventType, event } = parseListenerData(data);
            eventHandler(receivedEventType, event, navigate, audioManager);
        };
        eventsClientWorker.addEventListener(message, eventParser);
        return () => {
            eventsClientWorker.removeEventListener(message, eventParser);
        };

    }, [audioManager, eventHandler, eventsClientWorker, navigate]);
};

export default useEventListener;