
import { clearAllButton, markAllAsReadButton } from "../../../../../appConfig/Strings";
import FilledBtn from "../../Buttons/FilledBtn";

function Footer({ clear, markAllAsRead }) {

    return (
        <div className="flex items-center justify-between rounded-b-lg bg-[#263F63] p-2">
            <FilledBtn onClick={clear}>
                {clearAllButton}
            </FilledBtn>
            <FilledBtn className="w-auto" onClick={markAllAsRead}>
                {markAllAsReadButton}
            </FilledBtn>
        </div>
    );
}

export default Footer;