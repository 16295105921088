import { useRef, useState, useEffect } from 'react';

import Tooltip from '@mui/material/Tooltip';

import { tooltipWhiteStyle } from "../Styles/Tooltip.styles";

function OverflowingText({ text, className }) {
    const textRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const { offsetWidth, scrollWidth } = textRef.current;
        setIsTruncated(scrollWidth > offsetWidth);
    }, [text]);

    return (
        <Tooltip
            title={text}
            disableHoverListener={!isTruncated}
            arrow
            enterDelay={300}
            slotProps={tooltipWhiteStyle}
        >
            <div ref={textRef} className={`${className} truncate transition-all duration-300 ease-in-out`} >
                {text}
            </div>
        </Tooltip>
    );
}

export default OverflowingText;
