import { AnalysisSettings, AnalysisSettingsApi, ApiClient, GeneralSystemStatusApi, SearchFaceSettings, SearchFaceSettingsApi } from "smart_cameras_controller_api";

import { noSystemStatusError, notDeterminedLabel } from "../../../appConfig/Strings";
import store from "../../../appConfig/configureStore";
import { setAnalysisSettings, setFaceSettings, setLicense, setNodesList, setPrivacyNotice, setSystemStatus } from "../Redux/Stores/ApiActionsStore";
import { controllerRoute } from "../Infrastructure/networkConf";
import BaseAPIClient, { resCodes } from "./BaseAPIClient";
import { INVALID, parseLicense } from "./UsersClient";
import { licenseMapping } from "@/Components/Settings/License/License.model";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";

// todo-sdk
export const genderOutcome = {
    male: "male",
    female: "female",
    [notDeterminedLabel]: notDeterminedLabel
};
export const insightLicenseName = "generative_insights";
export const mainNodeDeviceType = 0;
export const nodeDeviceType = 1;
export const distributedLocalSiteDeviceType = 2;
export const hqDeviceType = 4;
export const deviceType = "device_type";
export const valid = "valid";

export class ControllerClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(controllerRoute, clientInstance);
        this.systemStatusApi = new GeneralSystemStatusApi();
        this.searchFaceSettingsApi = new SearchFaceSettingsApi();
        this.analysisSettingsApi = new AnalysisSettingsApi();
    }

    initialize() {
        this.getLicense();
        this.getPrivacyNotice();
        this.getFaceSettings();
        this.getAnalysisSettings();
        this.getAllNodes();
    }

    async #handleGetAllNodes(nodesData) {
        const nodes = await this.serializeApiData(nodesData.data.nodes, (node) => node.node_id);
        store.dispatch(setNodesList(nodes));
    }

    async getPrivacyNotice(hostIp = "") {
        if (hostIp) {
            this.setHostIp(hostIp);
        }
        let res = "";
        const responseMap = {
            [resCodes.noResponse]: () => (res = noSystemStatusError)
        };
        await this.apiCall(
            "Get privacy notice",
            (callback) => this.systemStatusApi.getStatus(callback),
            (_, data) => {
                res = data.data.privacy_notice;
                store.dispatch(setPrivacyNotice(res));
            },
            responseMap
        );
        return res;
    }

    async getAllNodes() {
        await this.apiCall(
            "List All Nodes",
            (callback) => this.systemStatusApi.listAllNodes(callback),
            (_, data) => this.#handleGetAllNodes(data)
        );
    }

    async getLicense() {
        await this.apiCall(
            "Get License",
            (callback) => this.systemStatusApi.getLicense(callback),
            (_, data) => {
                const licenseData = data.data;
                const { license, ...rest } = licenseData;

                const parsedLicenseFeatures = Object.keys(licenseMapping).reduce((acc, licenseName) => {
                    const feature = license.features[licenseName];
                    acc[licenseName] = {
                        ...licenseMapping[licenseName],
                        value: parseLicense(feature) || INVALID,
                        type: feature?.feature_type
                    };
                    return acc;
                }, {});

                const parsedLicense = {
                    ...rest,
                    license: {
                        ...license,
                        features: {
                            ...parsedLicenseFeatures
                        }
                    }
                };
                store.dispatch(setLicense(parsedLicense));
            });
    }

    async getFaceSettings() {
        await this.apiCall(
            "Get Face Settings",
            (callback) => this.searchFaceSettingsApi.getSearchFaceSettings(callback),
            (_, data) => store.dispatch(setFaceSettings(data))
        );
    }

    async setFaceSettings(zeroThEnabled) {
        const searchFaceSettings = new SearchFaceSettings();
        searchFaceSettings.min_search_confidence = zeroThEnabled ? 0 : 1;
        await this.apiCall(
            "Set Face Settings",
            (callback) => this.searchFaceSettingsApi.setSearchFaceSettings(searchFaceSettings, callback),
            (_, data) => store.dispatch(setFaceSettings(data)),
        );
    }

    async getAnalysisSettings() {
        await this.apiCall(
            "Get Analysis Settings",
            (callback) => this.analysisSettingsApi.getAnalysisSettings(callback),
            (_, data) => store.dispatch(setAnalysisSettings(data))
        );
    }

    async setAnalysisSettings({ displayCalibration, signaturePayload }) {
        const analysisSettings = new AnalysisSettings();
        if (signaturePayload !== undefined) {
            analysisSettings.enable_signature_payload = signaturePayload;
        } else {
            analysisSettings.display_face_score = displayCalibration;
            analysisSettings.display_face_size = displayCalibration;
            analysisSettings.display_liveness = displayCalibration;
        }

        return await this.apiCall(
            "Set Analysis Settings",
            (callback) => this.analysisSettingsApi.setAnalysisSettings(analysisSettings, callback),
            (_, data) => store.dispatch(setAnalysisSettings(data)),
        );
    }

    async getStatus() {
        return await this.apiCall(
            "Get System Status",
            (callback) => this.systemStatusApi.getStatus(callback),
            (error, data, response) => {
                const serverStatus = data.data;
                serverStatus.server_time = String(serverStatus.server_time.local_time);
                store.dispatch(setSystemStatus(serverStatus));
                return parseResponseForUI(error, response, data);
            },
            {
                [resCodes.noResponse]: (error, data, response) => parseResponseForUI(error, response, data),
                [resCodes.badGateway]: (error, data, response) => parseResponseForUI(error, response, data)
            }
        );
    }
}
