import { useMemo } from "react";

import OverflowingText from "@/Components/Common/Tooltips/OverflowingText";
import { watchlistType } from "@/Components/POIs/Watchlists/CreateEditWatchlist/CreateWatchlist.model";
import { matchOutcomes, notInWlOutcome } from "@/Logic/ApiClients/EventsClient/EventsClient.model";
import { hexToBGR, RGBToHex } from "@/Logic/Parsing/stringsParsing";
import { notIn, selectWatchlistHeader } from "src/appConfig/Strings";

function ColoredWatchlistName({ colorHex, name }) {
    const wlColor = useMemo(() => RGBToHex(hexToBGR(colorHex)), [colorHex]);

    return (
        <div
            className="min-w-14 max-w-full rounded-full border-2 p-1"
            style={{
                borderColor: `${wlColor}`,
                backgroundColor: `${wlColor}80`,
            }}
        >
            <OverflowingText className="text-center" text={name} />
        </div>
    );
}

function renderNotInWatchlist(colorHex, name) {
    return (
        <div className="flex w-full flex-col items-center justify-center gap-2">
            <span>{notIn}</span>
            <ColoredWatchlistName colorHex={colorHex} name={name} />
            <span className="uppercase">{selectWatchlistHeader}</span>
        </div>
    );
}

function getPoiDisplayInfo(matchData) {
    const { watchlists, poi_confidence, poi_display_img } = matchData;
    const mostSignificantMatch = watchlists?.[0];
    const isNotAuthorizedMatch = isNotInWatchlistMatch(mostSignificantMatch);

    let poiDisplayInfo = {
        data: undefined,
        isImgExit: false,
    };

    if (isNotAuthorizedMatch) {
        const { display_color, display_name } = mostSignificantMatch;

        poiDisplayInfo = {
            isImgExit: false,
            colorHex: display_color,
            data: display_name,
        };
    } else if (poi_confidence) {
        poiDisplayInfo = {
            isImgExit: true,
            data: poi_display_img
        };
    }

    return { isTxtData: isNotAuthorizedMatch, poiDisplayInfo };
}

function isCurrWlTypeWhitelist(currWl) {
    if (!currWl) {
        return;
    }

    return currWl.watchlist_type === watchlistType.whitelist;
}

function isAuthorizedMatch(match) {
    if (!match) {
        return;
    }

    return match.watchlist_type === watchlistType.blacklist && match.match_outcome === matchOutcomes.matched;
}

function isUnauthorizedMatch(match) {
    return isCurrWlTypeWhitelist(match) && match.match_outcome === matchOutcomes.matched;
}

function isNotInWatchlistMatch(match) {
    return isCurrWlTypeWhitelist(match) && match.match_outcome === notInWlOutcome;
}

export { renderNotInWatchlist, getPoiDisplayInfo, isCurrWlTypeWhitelist, isAuthorizedMatch, isUnauthorizedMatch, isNotInWatchlistMatch, ColoredWatchlistName };