"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CameraStatus = _interopRequireDefault(require("./CameraStatus"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CameraStatusData model module.
 * @module model/CameraStatusData
 * @version 0.32.2
 */
class CameraStatusData {
  /**
   * Constructs a new <code>CameraStatusData</code>.
   * @alias module:model/CameraStatusData
   */
  constructor() {
    CameraStatusData.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>CameraStatusData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CameraStatusData} obj Optional instance to populate.
   * @return {module:model/CameraStatusData} The populated <code>CameraStatusData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CameraStatusData();
      if (data.hasOwnProperty('status')) {
        obj['status'] = _ApiClient.default.convertToType(data['status'], _CameraStatus.default);
      }
      if (data.hasOwnProperty('time_active')) {
        obj['time_active'] = _ApiClient.default.convertToType(data['time_active'], 'Number');
      }
      if (data.hasOwnProperty('elapsed_frames')) {
        obj['elapsed_frames'] = _ApiClient.default.convertToType(data['elapsed_frames'], 'Number');
      }
      if (data.hasOwnProperty('elapsed_progress')) {
        obj['elapsed_progress'] = _ApiClient.default.convertToType(data['elapsed_progress'], 'Number');
      }
      if (data.hasOwnProperty('status_str')) {
        obj['status_str'] = _ApiClient.default.convertToType(data['status_str'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CameraStatusData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CameraStatusData</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
      throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
    }
    // validate the optional field `status`
    if (data['status']) {
      // data not null
      _CameraStatus.default.validateJSON(data['status']);
    }
    // ensure the json data is a string
    if (data['status_str'] && !(typeof data['status_str'] === 'string' || data['status_str'] instanceof String)) {
      throw new Error("Expected the field `status_str` to be a primitive type in the JSON string but got " + data['status_str']);
    }
    return true;
  }
}

/**
 * status code of the camera. Corresponds to the status codes in Events description
 * @member {module:model/CameraStatus} status
 * @default '0'
 */
CameraStatusData.prototype['status'] = '0';

/**
 * seconds since this camera was started. Is 0 if idle
 * @member {Number} time_active
 * @default 0.0
 */
CameraStatusData.prototype['time_active'] = 0.0;

/**
 * Number of frames that have been analyzed by this camera. If the camera is not analyzing, this will be constantly 0
 * @member {Number} elapsed_frames
 * @default 0
 */
CameraStatusData.prototype['elapsed_frames'] = 0;

/**
 * Indicates progress (number between 0-1) for Cameras that are files which allow reading their duration in metadata. If Camera is a live stream or a file that does not allow this data, this will be -1
 * @member {Number} elapsed_progress
 * @default -1.0
 */
CameraStatusData.prototype['elapsed_progress'] = -1.0;

/**
 * a string description of the status code
 * @member {String} status_str
 */
CameraStatusData.prototype['status_str'] = undefined;
var _default = exports.default = CameraStatusData;