import { Paper } from "@mui/material";

import CropImage from './CropImage';

function CropImageWithButton({ srcImage, className, children, cropImageClassName, ...props }) {
    return (
        <div className={`relative flex w-fit items-center justify-center ${className}`}>
            <div className='absolute end-0 top-1'>
                {children}
            </div>
            <Paper elevation={5}>
                <CropImage srcImage={srcImage} {...props} cropImageClassName={cropImageClassName} />
            </Paper>
        </div>
    );
}

export default CropImageWithButton;