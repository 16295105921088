import { filterActions } from "redux-ignore";

import ThemeOptions from "../../../TemplateAssets/TemplateThemeOptions";
import EventsStore from "./Stores/EventsStore";
import EventsFiltersStore from "./Stores/EventsFiltersStore";
import CamerasStore from "./Stores/CamerasStore";
import WatchlistsStore from "./Stores/WatchlistsStore";
import ApiActionsStore from "./Stores/ApiActionsStore";
import PrivacyProfilesStore from "./Stores/PrivacyProfilesStore";
import GlobalStore from "./Stores/GlobalStore";
import EnvVarsStore from "./Stores/EnvVarsStore";
import SearchCasesStore from "./Stores/SearchCasesStore";
import GenerativeInsightsStore from "./Stores/GenerativeInsightsStore";
import FramesStore from "./Stores/FramesStore";
import BaseRunnersStore from "./Stores/BaseRunnersStore";
import AnalyticsStore from "./Stores/AnalyticsStore";
import POIStore from "./Stores/POIStore";
import DataRetentionStore from "./Stores/DataRetentionStore";
import UsersStore from "./Stores/UsersStore";

const reducer = {
    ThemeOptions: filterActions(ThemeOptions, (action) => action.type.match(/^THEME_OPTIONS\/*/)),
    EventsStore: filterActions(EventsStore, (action) => action.type.match(/^events\/*/)),
    EventsFiltersStore: filterActions(EventsFiltersStore, (action) => action.type.match(/^eventsFilters\/*/)),
    CamerasStore: filterActions(CamerasStore, (action) => action.type.match(/^cameras\/*/)),
    ApiActionsStore: filterActions(ApiActionsStore, (action) => action.type.match(/^apiActions\/*/)),
    WatchlistsStore: filterActions(WatchlistsStore, (action) => action.type.match(/^watchlists\/*/)),
    PrivacyProfilesStore: filterActions(PrivacyProfilesStore, (action) => action.type.match(/^privacyProfiles\/*/)),
    GlobalStore: filterActions(GlobalStore, (action) => action.type.match(/^global\/*/)),
    EnvStore: filterActions(EnvVarsStore, (action) => action.type.match(/^envVars\/*/)),
    SearchCaseStore: filterActions(SearchCasesStore, (action) => action.type.match(/^searchCases\/*/)),
    GenerativeInsightsStore: filterActions(GenerativeInsightsStore, (action) => action.type.match(/^generativeInsights\/*/)),
    FramesStore: filterActions(FramesStore, (action) => action.type.match(/^framesStore\/*/)),
    BaseRunnersStore: filterActions(BaseRunnersStore, (action) => action.type.match(/^baseRunners\/*/)),
    AnalyticsStore: filterActions(AnalyticsStore, (action) => action.type.match(/^analyticsStore\/*/)),
    POIStore: filterActions(POIStore, (action) => action.type.match(/^poiStore\/*/)),
    DataRetentionStore: filterActions(DataRetentionStore, (action) => action.type.match(/^dataRetention\/*/)),
    UsersStore: filterActions(UsersStore, (action) => action.type.match(/^usersStore\/*/))
};

export default reducer;