"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AddCameraParams", {
  enumerable: true,
  get: function () {
    return _AddCameraParams.default;
  }
});
Object.defineProperty(exports, "AnalysisMode", {
  enumerable: true,
  get: function () {
    return _AnalysisMode.default;
  }
});
Object.defineProperty(exports, "AnalysisModeApi", {
  enumerable: true,
  get: function () {
    return _AnalysisModeApi.default;
  }
});
Object.defineProperty(exports, "AnalysisModeResponse", {
  enumerable: true,
  get: function () {
    return _AnalysisModeResponse.default;
  }
});
Object.defineProperty(exports, "AnalysisModes", {
  enumerable: true,
  get: function () {
    return _AnalysisModes.default;
  }
});
Object.defineProperty(exports, "AnalysisQuality", {
  enumerable: true,
  get: function () {
    return _AnalysisQuality.default;
  }
});
Object.defineProperty(exports, "AnalysisStatusData", {
  enumerable: true,
  get: function () {
    return _AnalysisStatusData.default;
  }
});
Object.defineProperty(exports, "ApiClient", {
  enumerable: true,
  get: function () {
    return _ApiClient.default;
  }
});
Object.defineProperty(exports, "AppearancePrivacy", {
  enumerable: true,
  get: function () {
    return _AppearancePrivacy.default;
  }
});
Object.defineProperty(exports, "AppearancePrivacyProfile", {
  enumerable: true,
  get: function () {
    return _AppearancePrivacyProfile.default;
  }
});
Object.defineProperty(exports, "AppearancePrivacySettings", {
  enumerable: true,
  get: function () {
    return _AppearancePrivacySettings.default;
  }
});
Object.defineProperty(exports, "Authorized", {
  enumerable: true,
  get: function () {
    return _Authorized.default;
  }
});
Object.defineProperty(exports, "Authorized1", {
  enumerable: true,
  get: function () {
    return _Authorized2.default;
  }
});
Object.defineProperty(exports, "Camera", {
  enumerable: true,
  get: function () {
    return _Camera.default;
  }
});
Object.defineProperty(exports, "CameraDataWithPrivacy", {
  enumerable: true,
  get: function () {
    return _CameraDataWithPrivacy.default;
  }
});
Object.defineProperty(exports, "CameraNotes", {
  enumerable: true,
  get: function () {
    return _CameraNotes.default;
  }
});
Object.defineProperty(exports, "CameraPrivacyId", {
  enumerable: true,
  get: function () {
    return _CameraPrivacyId.default;
  }
});
Object.defineProperty(exports, "CameraPrivacyProperties", {
  enumerable: true,
  get: function () {
    return _CameraPrivacyProperties.default;
  }
});
Object.defineProperty(exports, "CameraPrivacyResponse", {
  enumerable: true,
  get: function () {
    return _CameraPrivacyResponse.default;
  }
});
Object.defineProperty(exports, "CameraResponse", {
  enumerable: true,
  get: function () {
    return _CameraResponse.default;
  }
});
Object.defineProperty(exports, "CameraStartRequest", {
  enumerable: true,
  get: function () {
    return _CameraStartRequest.default;
  }
});
Object.defineProperty(exports, "CameraStatus", {
  enumerable: true,
  get: function () {
    return _CameraStatus.default;
  }
});
Object.defineProperty(exports, "CameraStatusData", {
  enumerable: true,
  get: function () {
    return _CameraStatusData.default;
  }
});
Object.defineProperty(exports, "CamerasApi", {
  enumerable: true,
  get: function () {
    return _CamerasApi.default;
  }
});
Object.defineProperty(exports, "CaptureStreamConfig", {
  enumerable: true,
  get: function () {
    return _CaptureStreamConfig.default;
  }
});
Object.defineProperty(exports, "CaptureStreamConfigParams", {
  enumerable: true,
  get: function () {
    return _CaptureStreamConfigParams.default;
  }
});
Object.defineProperty(exports, "Config", {
  enumerable: true,
  get: function () {
    return _Config.default;
  }
});
Object.defineProperty(exports, "Detected", {
  enumerable: true,
  get: function () {
    return _Detected.default;
  }
});
Object.defineProperty(exports, "Detected1", {
  enumerable: true,
  get: function () {
    return _Detected2.default;
  }
});
Object.defineProperty(exports, "DropRecord", {
  enumerable: true,
  get: function () {
    return _DropRecord.default;
  }
});
Object.defineProperty(exports, "DroppedCropsRecord", {
  enumerable: true,
  get: function () {
    return _DroppedCropsRecord.default;
  }
});
Object.defineProperty(exports, "DroppedFramesRecord", {
  enumerable: true,
  get: function () {
    return _DroppedFramesRecord.default;
  }
});
Object.defineProperty(exports, "FeatureMinWidth", {
  enumerable: true,
  get: function () {
    return _FeatureMinWidth.default;
  }
});
Object.defineProperty(exports, "FeaturesMinFaceWidths", {
  enumerable: true,
  get: function () {
    return _FeaturesMinFaceWidths.default;
  }
});
Object.defineProperty(exports, "FrameLatencyRecord", {
  enumerable: true,
  get: function () {
    return _FrameLatencyRecord.default;
  }
});
Object.defineProperty(exports, "FrameTimeoutSettings", {
  enumerable: true,
  get: function () {
    return _FrameTimeoutSettings.default;
  }
});
Object.defineProperty(exports, "GeneralResponse", {
  enumerable: true,
  get: function () {
    return _GeneralResponse.default;
  }
});
Object.defineProperty(exports, "HTTPValidationError", {
  enumerable: true,
  get: function () {
    return _HTTPValidationError.default;
  }
});
Object.defineProperty(exports, "History", {
  enumerable: true,
  get: function () {
    return _History.default;
  }
});
Object.defineProperty(exports, "HistoryAPIPrivacy", {
  enumerable: true,
  get: function () {
    return _HistoryAPIPrivacy.default;
  }
});
Object.defineProperty(exports, "HistoryAppearancePrivacy", {
  enumerable: true,
  get: function () {
    return _HistoryAppearancePrivacy.default;
  }
});
Object.defineProperty(exports, "HistoryMatchedAppearancePrivacy", {
  enumerable: true,
  get: function () {
    return _HistoryMatchedAppearancePrivacy.default;
  }
});
Object.defineProperty(exports, "ListAllCamerasRespnseModel", {
  enumerable: true,
  get: function () {
    return _ListAllCamerasRespnseModel.default;
  }
});
Object.defineProperty(exports, "ListAllCamerasResponse", {
  enumerable: true,
  get: function () {
    return _ListAllCamerasResponse.default;
  }
});
Object.defineProperty(exports, "ListAllSummaryModel", {
  enumerable: true,
  get: function () {
    return _ListAllSummaryModel.default;
  }
});
Object.defineProperty(exports, "MatchInterest", {
  enumerable: true,
  get: function () {
    return _MatchInterest.default;
  }
});
Object.defineProperty(exports, "MatchInterest1", {
  enumerable: true,
  get: function () {
    return _MatchInterest2.default;
  }
});
Object.defineProperty(exports, "MatchedAppearancePrivacy", {
  enumerable: true,
  get: function () {
    return _MatchedAppearancePrivacy.default;
  }
});
Object.defineProperty(exports, "MngrRecord", {
  enumerable: true,
  get: function () {
    return _MngrRecord.default;
  }
});
Object.defineProperty(exports, "MsgMetadata", {
  enumerable: true,
  get: function () {
    return _MsgMetadata.default;
  }
});
Object.defineProperty(exports, "NotAuthorized", {
  enumerable: true,
  get: function () {
    return _NotAuthorized.default;
  }
});
Object.defineProperty(exports, "NotAuthorized1", {
  enumerable: true,
  get: function () {
    return _NotAuthorized2.default;
  }
});
Object.defineProperty(exports, "Notes", {
  enumerable: true,
  get: function () {
    return _Notes.default;
  }
});
Object.defineProperty(exports, "PrivacyProfileProperties", {
  enumerable: true,
  get: function () {
    return _PrivacyProfileProperties.default;
  }
});
Object.defineProperty(exports, "PrivacyProfileProperties1", {
  enumerable: true,
  get: function () {
    return _PrivacyProfileProperties2.default;
  }
});
Object.defineProperty(exports, "Properties", {
  enumerable: true,
  get: function () {
    return _Properties.default;
  }
});
Object.defineProperty(exports, "RemoveCameraModel", {
  enumerable: true,
  get: function () {
    return _RemoveCameraModel.default;
  }
});
Object.defineProperty(exports, "RemoveCameraResponse", {
  enumerable: true,
  get: function () {
    return _RemoveCameraResponse.default;
  }
});
Object.defineProperty(exports, "Settings", {
  enumerable: true,
  get: function () {
    return _Settings.default;
  }
});
Object.defineProperty(exports, "Stream", {
  enumerable: true,
  get: function () {
    return _Stream.default;
  }
});
Object.defineProperty(exports, "StreamAPIPrivacy", {
  enumerable: true,
  get: function () {
    return _StreamAPIPrivacy.default;
  }
});
Object.defineProperty(exports, "StreamConfig", {
  enumerable: true,
  get: function () {
    return _StreamConfig.default;
  }
});
Object.defineProperty(exports, "UpdateCameraParams", {
  enumerable: true,
  get: function () {
    return _UpdateCameraParams.default;
  }
});
Object.defineProperty(exports, "UpdateCaptureStreamConfig", {
  enumerable: true,
  get: function () {
    return _UpdateCaptureStreamConfig.default;
  }
});
Object.defineProperty(exports, "ValidationError", {
  enumerable: true,
  get: function () {
    return _ValidationError.default;
  }
});
Object.defineProperty(exports, "WatchlistIdParameter", {
  enumerable: true,
  get: function () {
    return _WatchlistIdParameter.default;
  }
});
var _ApiClient = _interopRequireDefault(require("./ApiClient"));
var _AddCameraParams = _interopRequireDefault(require("./model/AddCameraParams"));
var _AnalysisMode = _interopRequireDefault(require("./model/AnalysisMode"));
var _AnalysisModeResponse = _interopRequireDefault(require("./model/AnalysisModeResponse"));
var _AnalysisModes = _interopRequireDefault(require("./model/AnalysisModes"));
var _AnalysisQuality = _interopRequireDefault(require("./model/AnalysisQuality"));
var _AnalysisStatusData = _interopRequireDefault(require("./model/AnalysisStatusData"));
var _AppearancePrivacy = _interopRequireDefault(require("./model/AppearancePrivacy"));
var _AppearancePrivacyProfile = _interopRequireDefault(require("./model/AppearancePrivacyProfile"));
var _AppearancePrivacySettings = _interopRequireDefault(require("./model/AppearancePrivacySettings"));
var _Authorized = _interopRequireDefault(require("./model/Authorized"));
var _Authorized2 = _interopRequireDefault(require("./model/Authorized1"));
var _Camera = _interopRequireDefault(require("./model/Camera"));
var _CameraDataWithPrivacy = _interopRequireDefault(require("./model/CameraDataWithPrivacy"));
var _CameraNotes = _interopRequireDefault(require("./model/CameraNotes"));
var _CameraPrivacyId = _interopRequireDefault(require("./model/CameraPrivacyId"));
var _CameraPrivacyProperties = _interopRequireDefault(require("./model/CameraPrivacyProperties"));
var _CameraPrivacyResponse = _interopRequireDefault(require("./model/CameraPrivacyResponse"));
var _CameraResponse = _interopRequireDefault(require("./model/CameraResponse"));
var _CameraStartRequest = _interopRequireDefault(require("./model/CameraStartRequest"));
var _CameraStatus = _interopRequireDefault(require("./model/CameraStatus"));
var _CameraStatusData = _interopRequireDefault(require("./model/CameraStatusData"));
var _CaptureStreamConfig = _interopRequireDefault(require("./model/CaptureStreamConfig"));
var _CaptureStreamConfigParams = _interopRequireDefault(require("./model/CaptureStreamConfigParams"));
var _Config = _interopRequireDefault(require("./model/Config"));
var _Detected = _interopRequireDefault(require("./model/Detected"));
var _Detected2 = _interopRequireDefault(require("./model/Detected1"));
var _DropRecord = _interopRequireDefault(require("./model/DropRecord"));
var _DroppedCropsRecord = _interopRequireDefault(require("./model/DroppedCropsRecord"));
var _DroppedFramesRecord = _interopRequireDefault(require("./model/DroppedFramesRecord"));
var _FeatureMinWidth = _interopRequireDefault(require("./model/FeatureMinWidth"));
var _FeaturesMinFaceWidths = _interopRequireDefault(require("./model/FeaturesMinFaceWidths"));
var _FrameLatencyRecord = _interopRequireDefault(require("./model/FrameLatencyRecord"));
var _FrameTimeoutSettings = _interopRequireDefault(require("./model/FrameTimeoutSettings"));
var _GeneralResponse = _interopRequireDefault(require("./model/GeneralResponse"));
var _HTTPValidationError = _interopRequireDefault(require("./model/HTTPValidationError"));
var _History = _interopRequireDefault(require("./model/History"));
var _HistoryAPIPrivacy = _interopRequireDefault(require("./model/HistoryAPIPrivacy"));
var _HistoryAppearancePrivacy = _interopRequireDefault(require("./model/HistoryAppearancePrivacy"));
var _HistoryMatchedAppearancePrivacy = _interopRequireDefault(require("./model/HistoryMatchedAppearancePrivacy"));
var _ListAllCamerasRespnseModel = _interopRequireDefault(require("./model/ListAllCamerasRespnseModel"));
var _ListAllCamerasResponse = _interopRequireDefault(require("./model/ListAllCamerasResponse"));
var _ListAllSummaryModel = _interopRequireDefault(require("./model/ListAllSummaryModel"));
var _MatchInterest = _interopRequireDefault(require("./model/MatchInterest"));
var _MatchInterest2 = _interopRequireDefault(require("./model/MatchInterest1"));
var _MatchedAppearancePrivacy = _interopRequireDefault(require("./model/MatchedAppearancePrivacy"));
var _MngrRecord = _interopRequireDefault(require("./model/MngrRecord"));
var _MsgMetadata = _interopRequireDefault(require("./model/MsgMetadata"));
var _NotAuthorized = _interopRequireDefault(require("./model/NotAuthorized"));
var _NotAuthorized2 = _interopRequireDefault(require("./model/NotAuthorized1"));
var _Notes = _interopRequireDefault(require("./model/Notes"));
var _PrivacyProfileProperties = _interopRequireDefault(require("./model/PrivacyProfileProperties"));
var _PrivacyProfileProperties2 = _interopRequireDefault(require("./model/PrivacyProfileProperties1"));
var _Properties = _interopRequireDefault(require("./model/Properties"));
var _RemoveCameraModel = _interopRequireDefault(require("./model/RemoveCameraModel"));
var _RemoveCameraResponse = _interopRequireDefault(require("./model/RemoveCameraResponse"));
var _Settings = _interopRequireDefault(require("./model/Settings"));
var _Stream = _interopRequireDefault(require("./model/Stream"));
var _StreamAPIPrivacy = _interopRequireDefault(require("./model/StreamAPIPrivacy"));
var _StreamConfig = _interopRequireDefault(require("./model/StreamConfig"));
var _UpdateCameraParams = _interopRequireDefault(require("./model/UpdateCameraParams"));
var _UpdateCaptureStreamConfig = _interopRequireDefault(require("./model/UpdateCaptureStreamConfig"));
var _ValidationError = _interopRequireDefault(require("./model/ValidationError"));
var _WatchlistIdParameter = _interopRequireDefault(require("./model/WatchlistIdParameter"));
var _AnalysisModeApi = _interopRequireDefault(require("./api/AnalysisModeApi"));
var _CamerasApi = _interopRequireDefault(require("./api/CamerasApi"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }