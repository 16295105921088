import { memo, useCallback, useContext } from "react";

import { connect } from "react-redux";

import { ReactComponent as ClearIcon } from "src/assets/Icons/UtilsIcons/icon_btn_clear.svg";
import { ReactComponent as ClearAlertIcon } from "src/assets/Icons/Modal/icon_alert.svg";

import MainDialog from "../../Modals/MainDialog";
import { alerts, alertsRemovalWarn, clear, clearWarn } from "src/appConfig/Strings";
import { clearAlertedEvents } from "@/Logic/Redux/Stores/EventsStore";
import AudioManagerContext from "@/Logic/Context/audioManagerContext";

function ClearAlertedEvents({ clearAlertedEvents }) {
    const { audioManager } = useContext(AudioManagerContext);

    const handleClear = useCallback(() => {
        audioManager.stop();
        clearAlertedEvents();
    }, [audioManager, clearAlertedEvents]);

    return (
        <div className="ml-auto">
            <MainDialog headerIcon={<ClearAlertIcon />} tooltipText={clear} triggerIcon={<ClearIcon className="size-8 stroke-dodger-blue" />} headerTitle={clear} footerSubmitBtnText={clear} onSubmit={handleClear}>
                <div className="flex flex-col items-center justify-center">
                    <span data-testid="clear-warn">
                        {clearWarn(alerts)}
                    </span>
                    <span className="mt-2" data-testid="events-removal-warn">
                        {alertsRemovalWarn}
                    </span>
                </div>
            </MainDialog>
        </div >
    );
}

const mapActionsToProps = {
    clearAlertedEvents
};

export default connect(null, mapActionsToProps)(memo(ClearAlertedEvents));