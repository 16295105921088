import { activeInvestigateLabel, activeInvestigateLiveLabel, activeLiveLabel, crowdedArea, crowdedAreaBaseInfo, inactiveLabel, loitering, loiteringBaseInfo, personJourneyBaseInfo, personJourneyName, poiProximityName, proximityBaseInfo, repeatedBaseInfo, repeatedIndividual } from "src/appConfig/Strings";
import { InsightsTypesFormModel } from "./InsightTypes/InsightTypes";

export const insightIdQueryParam = "generativeInsightId";
export const generativeInsightTypes = {
    loitering: "loitering",
    crowded_area: "crowded_area",
    repeated_individual: "repeated_individual",
    poiProximity: "poi_proximity",
    personJourney: "person_journey"
};
export const insightInactive = 0;
export const insightActiveLive = 1;
export const insightActiveInvestigate = 2;
export const insightActiveLiveAndInvestigate = 3;

export const insightTypeOptions = (isLiveAnalysisMode) => ([
    {
        label: loitering,
        value: generativeInsightTypes.loitering,
    },
    {
        label: crowdedArea,
        value: generativeInsightTypes.crowded_area
    },
    {
        label: repeatedIndividual,
        value: generativeInsightTypes.repeated_individual
    },
    {
        label: poiProximityName,
        value: generativeInsightTypes.poiProximity
    },
    {
        label: personJourneyName,
        value: generativeInsightTypes.personJourney,
        disabled: !isLiveAnalysisMode
    }
]);

export const insightActiveOptions = (isLiveAnalysisMode, isPersonJourney) => ([
    { label: inactiveLabel, value: insightInactive },
    { label: activeLiveLabel, value: insightActiveLive, disabled: !isLiveAnalysisMode },
    { label: activeInvestigateLabel, value: insightActiveInvestigate, disabled: isLiveAnalysisMode },
    { label: activeInvestigateLiveLabel, value: insightActiveLiveAndInvestigate, disabled: isPersonJourney },
]);

export const insightFormFieldsNames = {
    insightNameField: "insight_name",
    activeField: "active",
    generateEvent: "generate_event",
    insightNotesField: "insight_notes",
    insightTypeField: "insight_type",
    excludeWatchlists: "excluded_watchlists",
    minDetectionWidth: "min_detection_width"
};

export const InsightFormModel = {
    [insightFormFieldsNames.insightNameField]: {
        name: insightFormFieldsNames.insightNameField,
        type: "text",
        required: true
    },
    [insightFormFieldsNames.activeField]: {
        name: insightFormFieldsNames.activeField,
        textField: "label",
        dataKey: "value",
        required: true
    },
    [insightFormFieldsNames.generateEvent]: {
        name: insightFormFieldsNames.generateEvent,
    },
    [insightFormFieldsNames.insightNotesField]: {
        name: insightFormFieldsNames.insightNotesField,
        type: "textarea"
    },
    [insightFormFieldsNames.insightTypeField]: {
        name: insightFormFieldsNames.insightTypeField,
        textField: "label",
        dataKey: "value",
        required: true
    },
    [insightFormFieldsNames.excludeWatchlists]: {
        name: insightFormFieldsNames.excludeWatchlists,
        getId: (watchlist) => watchlist.watchlist_id,
        getLabel: (watchlist) => watchlist.display_name,
    },
    [insightFormFieldsNames.minDetectionWidth]: {
        name: insightFormFieldsNames.minDetectionWidth,
        type: "number",
        min: 0,
    },
    ...InsightsTypesFormModel
};

export const insightsInfoNaming = {
    [generativeInsightTypes.loitering]: {
        insightName: loitering,
        insightInfo: loiteringBaseInfo
    },
    [generativeInsightTypes.crowded_area]: {
        insightName: crowdedArea,
        insightInfo: crowdedAreaBaseInfo
    },
    [generativeInsightTypes.repeated_individual]: {
        insightName: repeatedIndividual,
        insightInfo: repeatedBaseInfo
    },
    [generativeInsightTypes.poiProximity]: {
        insightName: poiProximityName,
        insightInfo: proximityBaseInfo
    },
    [generativeInsightTypes.personJourney]: {
        insightName: personJourneyName,
        insightInfo: personJourneyBaseInfo
    },
};