"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AuditRecord = _interopRequireDefault(require("./AuditRecord"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AuditRecordsResponse model module.
 * @module model/AuditRecordsResponse
 * @version 0.35.0
 */
class AuditRecordsResponse {
  /**
   * Constructs a new <code>AuditRecordsResponse</code>.
   * @alias module:model/AuditRecordsResponse
   * @param auditRecords {Array.<module:model/AuditRecord>} 
   * @param totalAuditRecords {Number} 
   */
  constructor(auditRecords, totalAuditRecords) {
    AuditRecordsResponse.initialize(this, auditRecords, totalAuditRecords);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, auditRecords, totalAuditRecords) {
    obj['audit_records'] = auditRecords;
    obj['total_audit_records'] = totalAuditRecords;
  }

  /**
   * Constructs a <code>AuditRecordsResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AuditRecordsResponse} obj Optional instance to populate.
   * @return {module:model/AuditRecordsResponse} The populated <code>AuditRecordsResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AuditRecordsResponse();
      if (data.hasOwnProperty('audit_records')) {
        obj['audit_records'] = _ApiClient.default.convertToType(data['audit_records'], [_AuditRecord.default]);
      }
      if (data.hasOwnProperty('total_audit_records')) {
        obj['total_audit_records'] = _ApiClient.default.convertToType(data['total_audit_records'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AuditRecordsResponse</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AuditRecordsResponse</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of AuditRecordsResponse.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    if (data['audit_records']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['audit_records'])) {
        throw new Error("Expected the field `audit_records` to be an array in the JSON data but got " + data['audit_records']);
      }
      // validate the optional field `audit_records` (array)
      for (const item of data['audit_records']) {
        _AuditRecord.default.validateJsonObject(item);
      }
      ;
    }
    return true;
  }
}
AuditRecordsResponse.RequiredProperties = ["audit_records", "total_audit_records"];

/**
 * @member {Array.<module:model/AuditRecord>} audit_records
 */
AuditRecordsResponse.prototype['audit_records'] = undefined;

/**
 * @member {Number} total_audit_records
 */
AuditRecordsResponse.prototype['total_audit_records'] = undefined;
var _default = exports.default = AuditRecordsResponse;