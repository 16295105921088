"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Authorized = _interopRequireDefault(require("./Authorized"));
var _Detected = _interopRequireDefault(require("./Detected"));
var _HistoryAPIPrivacy = _interopRequireDefault(require("./HistoryAPIPrivacy"));
var _MatchInterest = _interopRequireDefault(require("./MatchInterest"));
var _NotAuthorized = _interopRequireDefault(require("./NotAuthorized"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The History model module.
 * @module model/History
 * @version 0.32.2
 */
class History {
  /**
   * Constructs a new <code>History</code>.
   * Privacy settings for saving appearances in History
   * @alias module:model/History
   * @implements module:model/HistoryAPIPrivacy
   */
  constructor() {
    _HistoryAPIPrivacy.default.initialize(this);
    History.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>History</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/History} obj Optional instance to populate.
   * @return {module:model/History} The populated <code>History</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new History();
      _HistoryAPIPrivacy.default.constructFromObject(data, obj);
      if (data.hasOwnProperty('match_interest')) {
        obj['match_interest'] = _MatchInterest.default.constructFromObject(data['match_interest']);
      }
      if (data.hasOwnProperty('not_authorized')) {
        obj['not_authorized'] = _NotAuthorized.default.constructFromObject(data['not_authorized']);
      }
      if (data.hasOwnProperty('authorized')) {
        obj['authorized'] = _Authorized.default.constructFromObject(data['authorized']);
      }
      if (data.hasOwnProperty('detected')) {
        obj['detected'] = _Detected.default.constructFromObject(data['detected']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>History</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>History</code>.
   */
  static validateJSON(data) {
    // validate the optional field `match_interest`
    if (data['match_interest']) {
      // data not null
      _MatchInterest.default.validateJSON(data['match_interest']);
    }
    // validate the optional field `not_authorized`
    if (data['not_authorized']) {
      // data not null
      _NotAuthorized.default.validateJSON(data['not_authorized']);
    }
    // validate the optional field `authorized`
    if (data['authorized']) {
      // data not null
      _Authorized.default.validateJSON(data['authorized']);
    }
    // validate the optional field `detected`
    if (data['detected']) {
      // data not null
      _Detected.default.validateJSON(data['detected']);
    }
    return true;
  }
}

/**
 * @member {module:model/MatchInterest} match_interest
 */
History.prototype['match_interest'] = undefined;

/**
 * @member {module:model/NotAuthorized} not_authorized
 */
History.prototype['not_authorized'] = undefined;

/**
 * @member {module:model/Authorized} authorized
 */
History.prototype['authorized'] = undefined;

/**
 * @member {module:model/Detected} detected
 */
History.prototype['detected'] = undefined;

// Implement HistoryAPIPrivacy interface:
/**
 * @member {module:model/MatchInterest} match_interest
 */
_HistoryAPIPrivacy.default.prototype['match_interest'] = undefined;
/**
 * @member {module:model/NotAuthorized} not_authorized
 */
_HistoryAPIPrivacy.default.prototype['not_authorized'] = undefined;
/**
 * @member {module:model/Authorized} authorized
 */
_HistoryAPIPrivacy.default.prototype['authorized'] = undefined;
/**
 * @member {module:model/Detected} detected
 */
_HistoryAPIPrivacy.default.prototype['detected'] = undefined;
var _default = exports.default = History;