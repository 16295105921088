import { has } from "lodash";

import { disconnectedSession, unableToExecute } from "src/appConfig/Strings";
import { toastUtility } from "../Hooks/useToast";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { dispatchShowConnectionCheckDialog } from "@/Components/Common/UtilComponents/ConnectionCheckDialog";
import { sessionEndedSignalEvent } from "src/appConfig/customEvents";

export const resCodes = {
    ok: 200,
    badRequest: 400,
    unauthorized: 401,
    PaymentRequired: 402,
    forbidden: 403,
    notFound: 404,
    unprocessable: 422,
    internalServerError: 500,
    badGateway: 502,
    serviceUnavailable: 503,
    requestTimeout: 408,
    noResponse: "no response",
    success: "success"
};

export default class BaseAPIClient {
    constructor(route, client_instance) {
        this.route = route;
        this.client_instance = client_instance;
        this.client_instance.defaultHeaders = {};
        this.baseUIResponse = (error, response, data, shouldFilterSuccessList, endPointName) => parseResponseForUI(error, response, data, shouldFilterSuccessList, endPointName);
    }

    defaultHandler = (endpointName, resCode, error, response, message = "") => {
        const responseMsg = response?.body?.metadata?.msg;
        const basicResponseMessage = (message ? message : responseMsg);
        const toastMessage = `${endpointName} (${resCode}) ${basicResponseMessage && `Message: ${basicResponseMessage}`}`;
        toastUtility.showError(toastMessage);

        return parseResponseForUI(error, response, response?.body?.data, true, endpointName);
    };

    defaultCallbacks = {
        [resCodes.badRequest]: (error, _, response, endpointName) => this.defaultHandler(endpointName, resCodes.badRequest, error, response),
        [resCodes.unauthorized]: (error, _, response, endpointName) => this.handleErrorStatus(endpointName, resCodes.unauthorized, error, disconnectedSession, response, true),
        [resCodes.forbidden]: (error, _, response, endpointName) => this.defaultHandler(endpointName, resCodes.forbidden, error, response),
        [resCodes.notFound]: (error, _, response, endpointName) => this.defaultHandler(endpointName, resCodes.notFound, error, response),
        [resCodes.unprocessable]: (error, _, response, endpointName) => this.defaultHandler(endpointName, resCodes.unprocessable, error, response),
        [resCodes.internalServerError]: (error, _, response, endpointName) => this.defaultHandler(endpointName, resCodes.internalServerError, error, response),
        [resCodes.noResponse]: (error, _, __, endpointName) => {
            const response = { body: { metadata: { msg: `${endpointName}: ${unableToExecute}` } } };
            return this.handleErrorStatus(endpointName, resCodes.noResponse, error, unableToExecute, response);
        },
        [resCodes.PaymentRequired]: (error, _, response, endpointName, message = "") => this.defaultHandler(endpointName, resCodes.PaymentRequired, error, response, message),
        [resCodes.serviceUnavailable]: (error, _, response, endpointName, message = "") => this.defaultHandler(endpointName, resCodes.serviceUnavailable, error, response, message),
        [resCodes.badGateway]: (error, _, __, endpointName) => this.handleErrorStatus(endpointName, resCodes.badGateway, error, unableToExecute),
        [resCodes.requestTimeout]: (error, _, response, endpointName) => this.defaultHandler(endpointName, resCodes.requestTimeout, error, response),
    };

    handleErrorStatus(endpointName, resCode, error, message, response = {}, shouldDisconnect = false) {
        if (typeof document !== 'undefined') {
            shouldDisconnect ?
                document.dispatchEvent(new CustomEvent(sessionEndedSignalEvent, {})) :
                dispatchShowConnectionCheckDialog();
        }
        return this.defaultHandler(endpointName, resCode, error, response, message);
    }

    setHostIp(hostIp) {
        this.hostIp = hostIp;
        this.client_instance.basePath = `https://${hostIp}/${this.route}`;
    }

    setToken(token) {
        const OAuth2PasswordBearer = this.client_instance.authentications["OAuth2PasswordBearer"];
        OAuth2PasswordBearer.accessToken = token;
    }

    async apiCall(endpointName, endpoint, successHandler, callbackMap = {}) {
        try {
            callbackMap = { ...this.defaultCallbacks, ...callbackMap };
            const [error, data, response] = await new Promise((resolve) => {
                endpoint((tmpError, tmpData, tmpResponse) => {
                    resolve([tmpError, tmpData, tmpResponse]);
                });
            });
            const resStatus = response ? response.status : resCodes.noResponse;
            if (resStatus === resCodes.ok) {
                return await successHandler(error, data, response, endpointName);
            }
            else if (has(callbackMap, resStatus)) {
                return await callbackMap[resStatus](error, data, response, endpointName);
            }
        } catch (executionError) {
            return await callbackMap[resCodes.noResponse](true, null, null, endpointName, executionError);
        }
    }

    async serializeApiData(data, idGetter) {
        const serializedData = data.reduce(async (accPromise, item) => {
            const acc = await accPromise;
            acc[idGetter(item)] = structuredClone(item);
            return acc;
        }, Promise.resolve({}));

        return await serializedData;
    }
}