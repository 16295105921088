import { allInsightNames, allInsightTypes, faceAttributes, generativeInsightsSearch, poiImg, poiInfo, specificInsightNames, specificInsightType, watchlistInfo, camerasFilter, endTime, generativeInsightsFilter, minAppearancesLabel, minInsightIntervalTime, startTime, analysisMode } from "src/appConfig/Strings";
import { generativeInsightSearchModelColumns } from "../InsightsFeed/InsightsFeed.model";
import { resultsPerPage } from "src/appConfig/constants";
import { getAllRelatedAppearances } from "../insight.utils";
import { allOptionSelected, specificOptionSelected, defaultRadioMultiSelectData, defaultRadioSingleSelectData } from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";
import { filtersFormFieldsNames } from "@/Components/Events/EventsFeed/EventsFilters.model";
import { investigateAnalysisModeBE, liveAnalysisMode } from "@/Logic/ApiClients/AnalysisModeClient";

export const insightSearchFields = {
    insightIds: "insight_ids",
    insightType: "insight_type"
};

export const InsightSearchNumInputModel = {
    type: "number",
    min: 0,
};

export const radioInsightTypesOptions = (
    [
        {
            label: allInsightTypes,
            option: allOptionSelected
        },
        {
            label: specificInsightType,
            option: specificOptionSelected
        }
    ]
);

export const radioInsightNamesOptions = (
    [
        {
            label: allInsightNames,
            option: allOptionSelected
        },
        {
            label: specificInsightNames,
            option: specificOptionSelected
        }
    ]
);

export const InsightsSearchModel = {
    insightTypeFilter: {
        getId: (insightType) => insightType.value,
        getLabel: (insightType) => insightType.label,
        required: true
    },
    insightIdsFilter: {
        getId: (insightOption) => insightOption.value,
        getLabel: (insightOption) => insightOption.label,
        required: true
    },
    insightTable: (rowsIds, generativeInsights, removeRow) => ({
        columns: generativeInsightSearchModelColumns(generativeInsightsSearch, removeRow),
        rows: rowsIds.map((rowId, index) => {
            const insightEvent = generativeInsights[index];

            return {
                id: rowId,
                ...insightEvent,
                ...insightEvent?.curr_appearance,
                ...getAllRelatedAppearances(insightEvent)
            };
        }),
        rowHeight: 170,
        pageSizeOptions: [resultsPerPage],
    })
};

export const generativeInsightExpendableTableModel = {
    columnVisibilityModel: {
        [poiImg]: false,
        [poiInfo]: false,
        [watchlistInfo]: false,
        [faceAttributes]: false
    }
};

export const defaultInsightsSearchFilters = (isLiveAnalysisMode) => ({
    [generativeInsightsFilter]: { [insightSearchFields.insightIds]: defaultRadioMultiSelectData, [insightSearchFields.insightType]: defaultRadioSingleSelectData },
    [camerasFilter]: defaultRadioMultiSelectData,
    [faceAttributes]: { [filtersFormFieldsNames.facesAttrAgeFilter]: {}, [filtersFormFieldsNames.facesAttrGenderFilter]: {} },
    [minInsightIntervalTime]: 0,
    [minAppearancesLabel]: 0,
    [startTime]: null,
    [endTime]: null,
    [analysisMode]: isLiveAnalysisMode ? liveAnalysisMode : investigateAnalysisModeBE
});