import { useCallback, useMemo } from "react";

import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";
import { getCurrentRecords } from "@/Logic/ApiClients/DataRetentionClient";
import { clear, historyRecords, insightsRecords, playbackFrames } from "src/appConfig/Strings";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { clearFramesDialog, clearHistoryDialog, clearInsightsDialog } from "@/Logic/Hooks/useDialog/useDialog.model";

const clearableStorages = new Set([historyRecords, playbackFrames, insightsRecords]);

function SystemStorageContextMenu({ row }) {
    const clearCallbacks = useMemo(() => ({
        [historyRecords]: () => dispatchToggleDialogEvent(clearHistoryDialog),
        [playbackFrames]: () => dispatchToggleDialogEvent(clearFramesDialog),
        [insightsRecords]: () => dispatchToggleDialogEvent(clearInsightsDialog)
    }), []);

    const setOptions = useCallback(() => {
        let options = {};
        if (clearableStorages.has(row.name) && getCurrentRecords(row) !== 0) {
            options = {
                [clear]: () => clearCallbacks[row.name]()
            };
        }
        return options;
    }, [clearCallbacks, row]);

    return (
        <ContextMenuButton options={setOptions()} />
    );
}

export default SystemStorageContextMenu;