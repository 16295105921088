import { v4 as uuid } from "uuid";

import { ColumnsDefinitions } from "@/Components/Common/MUITables/Columns/ColumnsDefinitions";
import { average, maximum, minimum, notDeterminedLabel } from "src/appConfig/Strings";

const insightsStatisticsKey = "insightsStatisticsKey";

const InsightsStatisticsTableModel = (rows) => {
    const insightsStatisticsColumns = [
        ColumnsDefinitions[insightsStatisticsKey],
        ColumnsDefinitions[average],
        ColumnsDefinitions[minimum],
        ColumnsDefinitions[maximum]
    ];

    return {
        columns: insightsStatisticsColumns,
        rows: rows.length > 0 ? rows.map(row => ({
            id: uuid(),
            ...row
        })) : [],
        rowHeight: 50
    };
};

function getInsightsStatisticsRowData({ value }) {
    if (value === -1) {
        return notDeterminedLabel;
    }
    return value;
}

export { InsightsStatisticsTableModel, insightsStatisticsKey, getInsightsStatisticsRowData };