import Loader from 'react-loaders';

function LoaderWithText({ text = "", className, textClassName }) {
    return (
        < div className={`my-5 flex flex-col items-center gap-3 ${className}`}>
            <Loader type="ball-clip-rotate-multiple" />
            <div className={`filled-btn-txt my-2 border-0 ${textClassName}`}>
                {text}
            </div>
        </div >
    );
}

export default LoaderWithText;
