"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AnalyzeResult model module.
 * @module model/AnalyzeResult
 * @version 0.37.1
 */
class AnalyzeResult {
  /**
   * Constructs a new <code>AnalyzeResult</code>.
   * @alias module:model/AnalyzeResult
   * @param validFace {Object} whether the face passed system threshold or not
   */
  constructor(validFace) {
    AnalyzeResult.initialize(this, validFace);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, validFace) {
    obj['valid_face'] = validFace;
  }

  /**
   * Constructs a <code>AnalyzeResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AnalyzeResult} obj Optional instance to populate.
   * @return {module:model/AnalyzeResult} The populated <code>AnalyzeResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AnalyzeResult();
      if (data.hasOwnProperty('valid_face')) {
        obj['valid_face'] = _ApiClient.default.convertToType(data['valid_face'], Object);
      }
      if (data.hasOwnProperty('face_score')) {
        obj['face_score'] = _ApiClient.default.convertToType(data['face_score'], Object);
      }
      if (data.hasOwnProperty('face_width')) {
        obj['face_width'] = _ApiClient.default.convertToType(data['face_width'], Object);
      }
      if (data.hasOwnProperty('yaw')) {
        obj['yaw'] = _ApiClient.default.convertToType(data['yaw'], Object);
      }
      if (data.hasOwnProperty('mask_detect')) {
        obj['mask_detect'] = _ApiClient.default.convertToType(data['mask_detect'], Object);
      }
      if (data.hasOwnProperty('gender')) {
        obj['gender'] = _ApiClient.default.convertToType(data['gender'], Object);
      }
      if (data.hasOwnProperty('age_group')) {
        obj['age_group'] = _ApiClient.default.convertToType(data['age_group'], Object);
      }
      if (data.hasOwnProperty('liveness')) {
        obj['liveness'] = _ApiClient.default.convertToType(data['liveness'], Object);
      }
      if (data.hasOwnProperty('signature_payload')) {
        obj['signature_payload'] = _ApiClient.default.convertToType(data['signature_payload'], Object);
      }
      if (data.hasOwnProperty('face_requires_guardian_consent')) {
        obj['face_requires_guardian_consent'] = _ApiClient.default.convertToType(data['face_requires_guardian_consent'], Object);
      }
      if (data.hasOwnProperty('embedding')) {
        obj['embedding'] = _ApiClient.default.convertToType(data['embedding'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AnalyzeResult</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AnalyzeResult</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of AnalyzeResult.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
AnalyzeResult.RequiredProperties = ["valid_face"];

/**
 * whether the face passed system threshold or not
 * @member {Object} valid_face
 */
AnalyzeResult.prototype['valid_face'] = undefined;

/**
 * the determined face score of the face
 * @member {Object} face_score
 */
AnalyzeResult.prototype['face_score'] = undefined;

/**
 * width of the face crop
 * @member {Object} face_width
 */
AnalyzeResult.prototype['face_width'] = undefined;

/**
 * the determined yaw of the face in degrees
 * @member {Object} yaw
 */
AnalyzeResult.prototype['yaw'] = undefined;

/**
 * the mask outcome of the given face. Valid only if required & successful
 * @member {Object} mask_detect
 */
AnalyzeResult.prototype['mask_detect'] = undefined;

/**
 * the gender outcome of the given face. Valid only if required & successful
 * @member {Object} gender
 */
AnalyzeResult.prototype['gender'] = undefined;

/**
 * the age group outcome of the given face. Valid only if required & successful
 * @member {Object} age_group
 */
AnalyzeResult.prototype['age_group'] = undefined;

/**
 * the liveness outcome of the given face. Valid only if required & successful
 * @member {Object} liveness
 */
AnalyzeResult.prototype['liveness'] = undefined;

/**
 * the signature_payload that was generated for the input face, if required
 * @member {Object} signature_payload
 */
AnalyzeResult.prototype['signature_payload'] = undefined;

/**
 * The face analyzed is determined to be of an age group that requires guardian consent. This is returned only if age_group is required.
 * @member {Object} face_requires_guardian_consent
 */
AnalyzeResult.prototype['face_requires_guardian_consent'] = undefined;

/**
 * The signature of the face
 * @member {Object} embedding
 */
AnalyzeResult.prototype['embedding'] = undefined;
var _default = exports.default = AnalyzeResult;