"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _RoleDataFilters = _interopRequireDefault(require("./RoleDataFilters"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The RoleUpdateParameters model module.
 * @module model/RoleUpdateParameters
 * @version 0.35.0
 */
class RoleUpdateParameters {
  /**
   * Constructs a new <code>RoleUpdateParameters</code>.
   * @alias module:model/RoleUpdateParameters
   */
  constructor() {
    RoleUpdateParameters.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>RoleUpdateParameters</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RoleUpdateParameters} obj Optional instance to populate.
   * @return {module:model/RoleUpdateParameters} The populated <code>RoleUpdateParameters</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RoleUpdateParameters();
      if (data.hasOwnProperty('role_name')) {
        obj['role_name'] = _ApiClient.default.convertToType(data['role_name'], 'String');
      }
      if (data.hasOwnProperty('permission_names')) {
        obj['permission_names'] = _ApiClient.default.convertToType(data['permission_names'], ['String']);
      }
      if (data.hasOwnProperty('role_data_filters')) {
        obj['role_data_filters'] = _RoleDataFilters.default.constructFromObject(data['role_data_filters']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>RoleUpdateParameters</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RoleUpdateParameters</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['role_name'] && !(typeof data['role_name'] === 'string' || data['role_name'] instanceof String)) {
      throw new Error("Expected the field `role_name` to be a primitive type in the JSON string but got " + data['role_name']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['permission_names'])) {
      throw new Error("Expected the field `permission_names` to be an array in the JSON data but got " + data['permission_names']);
    }
    // validate the optional field `role_data_filters`
    if (data['role_data_filters']) {
      // data not null
      _RoleDataFilters.default.validateJSON(data['role_data_filters']);
    }
    return true;
  }
}

/**
 * role name
 * @member {String} role_name
 */
RoleUpdateParameters.prototype['role_name'] = undefined;

/**
 * list of end-point identifiers that this role is allowed to call
 * @member {Array.<String>} permission_names
 */
RoleUpdateParameters.prototype['permission_names'] = undefined;

/**
 * @member {module:model/RoleDataFilters} role_data_filters
 */
RoleUpdateParameters.prototype['role_data_filters'] = undefined;
var _default = exports.default = RoleUpdateParameters;