import { createSlice } from '@reduxjs/toolkit';

import { alertCenterDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";

import { camerasHeader, eventsList, generativeInsightsEventsFeed } from 'src/appConfig/Strings';

const initialState = {
    selectedDisplayStreams: [],
    selectedCamerasIds: [],
    featureOutcome: {},
    dialogOpenStatus: {
        [alertCenterDialogId]: false
    },
    openDisplay: {
        [camerasHeader]: {
            selectedCamera: undefined,
            isExpanded: false
        },
        [eventsList]: {
            selectedCamera: undefined,
            isExpanded: false
        },
        [generativeInsightsEventsFeed]: {
            selectedCamera: undefined,
            isExpanded: false
        }
    },
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        addDisplayStream: (state, action) => {
            const { streamUrl, index, cameraId } = action.payload;
            state.selectedDisplayStreams[index] = streamUrl;
            state.selectedCamerasIds[index] = cameraId;
        },
        removeDisplayStream: (state, action) => {
            const { index } = action.payload;
            delete state.selectedDisplayStreams[index];
            delete state.selectedCamerasIds[index];
        },
        clearDisplays: (state) => {
            state.selectedDisplayStreams = [];
            state.selectedCamerasIds = [];
        },
        clearDisplayById: (state, action) => {
            const cameraId = action.payload;
            Object.keys(state.openDisplay).forEach((feedKey) => {
                if (state.openDisplay[feedKey].selectedCamera?.camera_id === cameraId) {
                    state.openDisplay[feedKey] = {
                        selectedCamera: undefined,
                        isExpanded: false
                    };
                }
            });
        },
        setFeatureOutcome: (state, action) => {
            const { featureOutcome } = action.payload;
            state.featureOutcome = featureOutcome;
            return state;
        },
        setOpenDisplay: (state, action) => {
            const { screenId, isExpanded, selectedCamera } = action.payload;
            state.openDisplay[screenId] = {
                selectedCamera: selectedCamera,
                isExpanded: isExpanded
            };

            return state;
        },
        setDialogOpenStatus: (state, action) => {
            const { dialogID, isDialogOpen } = action.payload;
            state.dialogOpenStatus[dialogID] = isDialogOpen;

            return state;
        },
    }
});

export const { addDisplayStream, removeDisplayStream, clearDisplays, clearDisplayById, setFeatureOutcome, setOpenDisplay, setDialogOpenStatus } = globalSlice.actions;

export default globalSlice.reducer;