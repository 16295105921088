"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AnalyzeResponse = _interopRequireDefault(require("../model/AnalyzeResponse"));
var _CompareResponse = _interopRequireDefault(require("../model/CompareResponse"));
var _DetectionResponse = _interopRequireDefault(require("../model/DetectionResponse"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _SearchPOIsFacesInImageResponse = _interopRequireDefault(require("../model/SearchPOIsFacesInImageResponse"));
var _SearchResponse = _interopRequireDefault(require("../model/SearchResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Face service.
* @module api/FaceApi
* @version 0.37.1
*/
class FaceApi {
  /**
  * Constructs a new FaceApi. 
  * @alias module:api/FaceApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the analyzeFace operation.
   * @callback module:api/FaceApi~analyzeFaceCallback
   * @param {String} error Error message, if any.
   * @param {module:model/AnalyzeResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Analyze Face
   * Retrieve analysis attributes of a face
   * @param {Object} body 
   * @param {module:api/FaceApi~analyzeFaceCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/AnalyzeResponse}
   */
  analyzeFace(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling analyzeFace");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _AnalyzeResponse.default;
    return this.apiClient.callApi('/poi_db/face/analyze', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the compareFaces operation.
   * @callback module:api/FaceApi~compareFacesCallback
   * @param {String} error Error message, if any.
   * @param {module:model/CompareResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Compare Faces
   * Performs one to one comparison of the faces contained within each of a reference image and a test image. Returns a number between 1 and 100 which is the match confidence of the face in the reference image to the face in the test image.
   * @param {Object} body 
   * @param {module:api/FaceApi~compareFacesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/CompareResponse}
   */
  compareFaces(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling compareFaces");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _CompareResponse.default;
    return this.apiClient.callApi('/poi_db/face/compare', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the detectFaces operation.
   * @callback module:api/FaceApi~detectFacesCallback
   * @param {String} error Error message, if any.
   * @param {module:model/DetectionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Detect Faces
   * Detect faces in images. Return list of detected face bounding boxes per image, and optionally the face crops themselves
   * @param {Object} body 
   * @param {module:api/FaceApi~detectFacesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/DetectionResponse}
   */
  detectFaces(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling detectFaces");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _DetectionResponse.default;
    return this.apiClient.callApi('/poi_db/face/detect', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the searchFace operation.
   * @callback module:api/FaceApi~searchFaceCallback
   * @param {String} error Error message, if any.
   * @param {module:model/SearchResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Search Face
   * Search POIs by face image - looks for faces belonging to same person as input face. Return list of matches and confidence
   * @param {Object} body 
   * @param {module:api/FaceApi~searchFaceCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/SearchResponse}
   */
  searchFace(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchFace");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _SearchResponse.default;
    return this.apiClient.callApi('/poi_db/face/search', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the searchFaces operation.
   * @callback module:api/FaceApi~searchFacesCallback
   * @param {String} error Error message, if any.
   * @param {module:model/SearchPOIsFacesInImageResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Search Faces
   * Search POIs by image with multiple faces - looks for faces belonging to same person as input faces. Return list of matches, confidence, crop, bbox
   * @param {Object} body 
   * @param {module:api/FaceApi~searchFacesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/SearchPOIsFacesInImageResponse}
   */
  searchFaces(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchFaces");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _SearchPOIsFacesInImageResponse.default;
    return this.apiClient.callApi('/poi_db/face/search_faces', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = FaceApi;