import { memo, useCallback, useContext, useMemo, useState } from "react";

import { connect } from "react-redux";
import { ListItemButton, ListItemText, List, Collapse, Tooltip, Zoom } from "@mui/material";
import { useNavigate } from "react-router-dom";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import {
    camerasList, createCameraLabel, createNewCase, createWatchlistHeader, displayHeader,
    eventsList, generativeInsightsEventsFeed, generativeInsightsList, generativeInsightsSearch, importPOIHeader, serversHeader, videosList
} from "src/appConfig/Strings";
import { SideNavigationItems } from "../SideNavigation/SideNavigation.model";
import SideNavigationNestedList from "../SideNavigationNestedList/SideNavigationNestedList";
import { insightLicenseName } from "@/Logic/ApiClients/ControllerClient";
import ApiContext from "@/Logic/Context/apiContext";
import { checkLicenseValidity } from "@/Components/Common/UtilComponents/LockCheck";

function SideNavigationList({ setIsSideBarOpen, isSideBarOpen, sideBarContentWidth, searchCases, isLiveAnalysisMode, isMultiServerMode }) {
    const { clientsManager } = useContext(ApiContext);
    const navigate = useNavigate();
    const [activeNavItem, setActiveNavItem] = useState(null);
    const menuItems = useMemo(() => SideNavigationItems(searchCases, { isLiveAnalysisMode: isLiveAnalysisMode }), [searchCases, isLiveAnalysisMode]);

    const refreshedOnClick = useMemo(() => new Set([createWatchlistHeader, createCameraLabel, generativeInsightsList, createNewCase]), []);
    const listAllCamerasOnClick = useMemo(() => new Set([camerasList, videosList, displayHeader, eventsList, createNewCase, generativeInsightsList, generativeInsightsSearch, generativeInsightsEventsFeed]), []);
    const listAllWatchlistsOnClick = useMemo(() => new Set([eventsList, importPOIHeader, createNewCase, generativeInsightsList, generativeInsightsEventsFeed]), []);
    const listAllGenerativeInsightsOnClick = useMemo(() => new Set([generativeInsightsList, generativeInsightsSearch]), []);
    const listAllServerNodesOnClick = useMemo(() => new Set([serversHeader]), []);
    const listAllLicenseRequired = useMemo(() => ({
        [generativeInsightsList]: () => checkLicenseValidity(insightLicenseName),
        [generativeInsightsEventsFeed]: () => checkLicenseValidity(insightLicenseName),
        [generativeInsightsSearch]: () => checkLicenseValidity(insightLicenseName),
    }), []);

    const handleItemSelected = useCallback((selectedNavItem) => {
        refreshedOnClick.has(selectedNavItem) && navigate("", { replace: true });
        const licenseCheckFunction = listAllLicenseRequired[selectedNavItem];
        if (licenseCheckFunction && !licenseCheckFunction()) {
            return;
        }
        listAllCamerasOnClick.has(selectedNavItem) && clientsManager.camerasClient.listAllCameras();
        listAllWatchlistsOnClick.has(selectedNavItem) && clientsManager.watchlistsClient.listAllWatchlists();
        listAllGenerativeInsightsOnClick.has(selectedNavItem) && clientsManager.generativeInsightsClient.listAllInsights();
        listAllServerNodesOnClick.has(selectedNavItem) && isMultiServerMode && clientsManager.controllerClient.getAllNodes();
    }, [clientsManager.camerasClient, clientsManager.controllerClient, clientsManager.generativeInsightsClient, clientsManager.watchlistsClient, isMultiServerMode, listAllCamerasOnClick, listAllGenerativeInsightsOnClick, listAllLicenseRequired, listAllServerNodesOnClick, listAllWatchlistsOnClick, navigate, refreshedOnClick]);

    const onNavItemClick = useCallback((currNavItem, path, navItemLabel) => {
        if (path) {
            navigate(path);
            handleItemSelected(navItemLabel);
        } else {
            setActiveNavItem((prevNavItem) => prevNavItem === currNavItem ? null : currNavItem);
            if (!isSideBarOpen) {
                setIsSideBarOpen(true);
            }
        }
    }, [handleItemSelected, isSideBarOpen, navigate, setActiveNavItem, setIsSideBarOpen]);

    return (
        <List component="nav" className={`${sideBarContentWidth} max-h-[80vh] overflow-y-auto`} >
            {menuItems.map((navItem, index) => (
                <Tooltip
                    key={index}
                    title={navItem.disabled && navItem.disabledText}
                    TransitionComponent={Zoom}
                    slotProps={{
                        popper: {
                            modifiers: [{ name: 'offset', options: { offset: [0, -25] } }],
                        }
                    }}>
                    <div>
                        <ListItemButton className="text-silver-gray" onClick={() => onNavItemClick(index)} disabled={navItem.disabled}>
                            <button className="w-8 cursor-pointer border-0 bg-transparent p-0" >
                                {navItem.icon}
                            </button>
                            {isSideBarOpen && (
                                <span className="flex items-center">
                                    <ListItemText primaryTypographyProps={{ className: "side-navigation-txt" }} primary={navItem.label} />
                                    {activeNavItem === index ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            )}
                        </ListItemButton>
                        {isSideBarOpen && !navItem.disabled && (
                            <Collapse in={activeNavItem === index} timeout="auto" >
                                <SideNavigationNestedList navItem={navItem} onNavItemClick={onNavItemClick} />
                            </Collapse>
                        )}
                    </div>
                </Tooltip>
            ))}
        </List>
    );
}

const mapStateToProps = (state) => {
    const searchCases = state.SearchCaseStore;
    const { isLiveAnalysisMode, isMultiServerMode } = state.ApiActionsStore;

    return { searchCases, isLiveAnalysisMode, isMultiServerMode };
};

export default connect(mapStateToProps)(memo(SideNavigationList));