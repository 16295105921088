import { useContext, useEffect, useMemo, useState } from "react";

import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import { Alert, IconButton } from "@mui/material";
import { isEmpty } from "lodash";

import { ReactComponent as RefreshIcon } from "src/assets/Icons/UtilsIcons/icon_refresh.svg";

import { CreateEditPoiFormInputsModel, PoiConsentOptions, PoiFormFieldsNames } from "../CreateEditPOI.model";
import CropImageWithButton from "@/Components/Common/Images/CropImageWithButton";
import POIFaceContextMenu from "../Faces/POIFaceContextMenu";
import { displayImageEvent } from "src/appConfig/customEvents";
import MultiSelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField/MultiSelectInputField";
import { createPOIHeader, poiConsent, poiDisplayName, poiNotes, removeDisplayImg, removePoi, requireGuardianConsentLabel, watchlistsHeader } from "src/appConfig/Strings";
import TextAreaInputField from "@/Components/Common/FormComponents/FormInputs/TextAreaInputField";
import AffixInputField from "@/Components/Common/FormComponents/FormInputs/AffixInputField";
import SelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField";
import ImageSelector from "@/Components/Common/Buttons/ImageSelector";
import { screenStepsMapping } from "@/Components/Common/ProgressStepper/ProgressStepper.model";
import POIFacesManager from "../Faces/POIFacesManager";
import { removePoiRequest } from "../../POIData/POIContextMenu";
import { clearOpenPoi } from "@/Logic/Redux/Stores/POIStore";
import ApiContext from "@/Logic/Context/apiContext";
import { poiDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";

const poiContextMenuOptions = (clientsManager, poiId) => {
    const options = {
        [removePoi]: async () => {
            await removePoiRequest(clientsManager, poiId);
            dispatchToggleDialogEvent(poiDialogId, true, clearOpenPoi);
        },
    };

    return options;
};

function POIForm({ formData, onFieldChange, handleFormSubmit, poiId, watchlists }) {
    const { clientsManager } = useContext(ApiContext);
    const [isRequireGuardianConsent, setIsRequireGuardianConsent] = useState(false);
    const { poiId: poiName, watchlists: watchlistsModel, consent, notes, displayImage } = PoiFormFieldsNames;
    const poiFaces = useMemo(() => formData.faces || {}, [formData.faces]);

    const poiIdSuffixIcon = useMemo(() => (
        <IconButton onClick={() => onFieldChange(poiName, uuid())} edge="end">
            <RefreshIcon className="stroke-black" />
        </IconButton>
    ), [onFieldChange, poiName]);

    useEffect(() => {
        const handleChangeDisplayImage = (event) => {
            if (!event.detail.isTriggeredByUseEffect || !formData[displayImage]) {
                onFieldChange(displayImage, event.detail.displayImage);
            }
        };

        document.addEventListener(displayImageEvent, handleChangeDisplayImage);
        return () => document.removeEventListener(displayImageEvent, handleChangeDisplayImage);
    }, [displayImage, formData, onFieldChange]);

    return (
        <form id={createPOIHeader} onSubmit={handleFormSubmit}>
            <section className="flex">
                <div className="w-40">
                    <CropImageWithButton cropImageClassName="!bg-transparent" defaultFaceCropClassName="!fill-slate-blue" className="mr-2" srcImage={formData[displayImage]} onChange={(event) => onFieldChange(displayImage, event.target.value)}>
                        <POIFaceContextMenu displayImg={formData[displayImage]} removeLabel={removeDisplayImg} removeCallback={() => onFieldChange(displayImage, "")} contextMenuOptions={poiId ? poiContextMenuOptions(clientsManager, poiId) : null} />
                    </CropImageWithButton>
                </div>
                <div className="relative flex w-full flex-col justify-between">
                    <AffixInputField
                        className="bg-white"
                        label={poiDisplayName}
                        fullWidth={true}
                        value={formData[poiName]}
                        onChange={(event) => onFieldChange(poiName, event.target.value)}
                        variant="outlined"
                        model={{ ...CreateEditPoiFormInputsModel[poiName], suffix: poiIdSuffixIcon }}
                    />
                    <div>
                        <TextAreaInputField
                            className="absolute left-0 top-modal-header-height"
                            inputProps={{ style: { lineHeight: '1.9em' } }}
                            label={poiNotes}
                            model={CreateEditPoiFormInputsModel[notes]}
                            value={formData[notes]}
                            onChange={(event) => onFieldChange(notes, event.target.value)}
                            dataTestId="poi-notes"
                        />
                    </div>
                </div>
            </section>
            <section className="ml-2.5 flex h-11">
                <ImageSelector
                    cameraSelectedSteps={screenStepsMapping.newPOIFromCamera}
                    fileSelectedStep={screenStepsMapping.newPOIFromFile}
                />
            </section>
            <section className="mt-2 flex flex-col gap-3">
                <MultiSelectInputField
                    limitTags={4}
                    popperProps={{ placement: 'bottom', modifiers: [{ name: 'flip', enabled: false }] }}
                    ListboxProps={{ className: "max-h-48" }}
                    model={CreateEditPoiFormInputsModel[watchlistsModel]}
                    options={Object.values(watchlists)}
                    label={watchlistsHeader}
                    value={formData[watchlistsModel]}
                    onChange={(selectedWatchlists) => onFieldChange(watchlistsModel, selectedWatchlists)}
                    data-testid="poi-watchlist-field"
                />
                <SelectInputField
                    label={poiConsent}
                    model={{ ...CreateEditPoiFormInputsModel[consent], required: isRequireGuardianConsent }}
                    value={formData[consent]}
                    onChange={(event) => onFieldChange(consent, event.target.value)}
                    options={PoiConsentOptions(isRequireGuardianConsent)}
                />
                {isRequireGuardianConsent && (
                    <Alert className="flex items-start justify-start" severity="error">
                        <div className="w-full text-left text-sm">
                            {requireGuardianConsentLabel}
                        </div>
                    </Alert>
                )}
            </section>
            <POIFacesManager poiId={poiId} formData={formData} onFieldChange={onFieldChange} poiFaces={poiFaces} setIsRequireGuardianConsent={setIsRequireGuardianConsent} />
            {isEmpty(poiFaces) && <input value={undefined} className="pointer-events-none absolute ml-4 size-1 opacity-0" required />}
        </form >
    );
}

const mapStateToProps = (state) => {
    const watchlists = state.WatchlistsStore;

    return { watchlists };
};

export default connect(mapStateToProps, null)(POIForm);