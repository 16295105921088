"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The HistoryCountResponseData model module.
 * @module model/HistoryCountResponseData
 * @version 0.37.0
 */
class HistoryCountResponseData {
  /**
   * Constructs a new <code>HistoryCountResponseData</code>.
   * @alias module:model/HistoryCountResponseData
   * @param uniqueAppearances {Object} 
   * @param matchedAppearances {Object} 
   * @param unmatchedAppearances {Object} 
   * @param uniquePersons {Object} 
   * @param uniquePois {Object} 
   * @param uniqueUnknowns {Object} 
   */
  constructor(uniqueAppearances, matchedAppearances, unmatchedAppearances, uniquePersons, uniquePois, uniqueUnknowns) {
    HistoryCountResponseData.initialize(this, uniqueAppearances, matchedAppearances, unmatchedAppearances, uniquePersons, uniquePois, uniqueUnknowns);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, uniqueAppearances, matchedAppearances, unmatchedAppearances, uniquePersons, uniquePois, uniqueUnknowns) {
    obj['unique_appearances'] = uniqueAppearances;
    obj['matched_appearances'] = matchedAppearances;
    obj['unmatched_appearances'] = unmatchedAppearances;
    obj['unique_persons'] = uniquePersons;
    obj['unique_pois'] = uniquePois;
    obj['unique_unknowns'] = uniqueUnknowns;
  }

  /**
   * Constructs a <code>HistoryCountResponseData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/HistoryCountResponseData} obj Optional instance to populate.
   * @return {module:model/HistoryCountResponseData} The populated <code>HistoryCountResponseData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new HistoryCountResponseData();
      if (data.hasOwnProperty('unique_appearances')) {
        obj['unique_appearances'] = _ApiClient.default.convertToType(data['unique_appearances'], Object);
      }
      if (data.hasOwnProperty('matched_appearances')) {
        obj['matched_appearances'] = _ApiClient.default.convertToType(data['matched_appearances'], Object);
      }
      if (data.hasOwnProperty('unmatched_appearances')) {
        obj['unmatched_appearances'] = _ApiClient.default.convertToType(data['unmatched_appearances'], Object);
      }
      if (data.hasOwnProperty('unique_persons')) {
        obj['unique_persons'] = _ApiClient.default.convertToType(data['unique_persons'], Object);
      }
      if (data.hasOwnProperty('unique_pois')) {
        obj['unique_pois'] = _ApiClient.default.convertToType(data['unique_pois'], Object);
      }
      if (data.hasOwnProperty('unique_unknowns')) {
        obj['unique_unknowns'] = _ApiClient.default.convertToType(data['unique_unknowns'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>HistoryCountResponseData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>HistoryCountResponseData</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of HistoryCountResponseData.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
HistoryCountResponseData.RequiredProperties = ["unique_appearances", "matched_appearances", "unmatched_appearances", "unique_persons", "unique_pois", "unique_unknowns"];

/**
 * @member {Object} unique_appearances
 */
HistoryCountResponseData.prototype['unique_appearances'] = undefined;

/**
 * @member {Object} matched_appearances
 */
HistoryCountResponseData.prototype['matched_appearances'] = undefined;

/**
 * @member {Object} unmatched_appearances
 */
HistoryCountResponseData.prototype['unmatched_appearances'] = undefined;

/**
 * @member {Object} unique_persons
 */
HistoryCountResponseData.prototype['unique_persons'] = undefined;

/**
 * @member {Object} unique_pois
 */
HistoryCountResponseData.prototype['unique_pois'] = undefined;

/**
 * @member {Object} unique_unknowns
 */
HistoryCountResponseData.prototype['unique_unknowns'] = undefined;
var _default = exports.default = HistoryCountResponseData;