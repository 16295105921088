import moment from "moment";
import { isNil, isString } from "lodash";

import { trimNumber } from "./numbersParsing";

export const DateParsingString = "DD/MM/yyyy";
export const TimeParsingString = "HH:mm:ss";
export const HoursMinutesParsingString = "HH:mm";
export const specialCharTimeParsingString = "HH[h]mm[m]ss[s]";
export const fullDataTimeFormat = `${DateParsingString}, ${TimeParsingString}`;
export const reportFullTimeFormat = `${DateParsingString} ${TimeParsingString}`;
export const reportFormat = `MMM_DD_YY__${TimeParsingString}`;
export const excelReportFormat = 'MMM-DD-YY__HH-mm-ss';
export const timeFilterFormat = "YYYY-MM-DDTHH:mm:ss";
export const dateTimeType = 'datetime-local';
export const timeType = 'time';
export const baseDate = '1970-01-01T';

function formatUnixTimestamp(unixTimestamp, timeFormat, isUtcTimeZone) {
    return isUtcTimeZone ?
        moment.unix(unixTimestamp).utc().format(timeFormat) :
        moment.unix(unixTimestamp).local().format(timeFormat);
}

function localToUtc(dataString) {
    return moment(dataString).utc().format("x");
}

function dateStringToLocalDate(dateString) {
    if (!dateString) {
        return "";
    }

    return moment.utc(dateString).local().format(DateParsingString);
}

function dateStringToLocalTime(dateString, isUtcTimeZone, ParsingString = TimeParsingString) {
    if (!dateString) {
        return "";
    }

    return isUtcTimeZone ?
        moment.utc(dateString).format(ParsingString) :
        moment(dateString).format(ParsingString);
}

function getTimeDiff(dateStringA, dateStringB) {
    const timeA = moment.utc(dateStringA);
    const timeB = moment.utc(dateStringB);
    const duration = moment.duration(timeA.diff(timeB));

    return moment.utc(duration.asMilliseconds()).format("ms");
}

function parseTimeObj(timeObj, isInvestigate = false, parseDate = false) {
    let parsedTime;
    if (isNil(timeObj)) {
        return "";
    }

    if (isString(timeObj)) {
        parsedTime = parseDate ?
            dateStringToLocalDate(timeObj) :
            dateStringToLocalTime(timeObj, isInvestigate);
    } else {
        const timeFormat = parseDate ? DateParsingString : TimeParsingString;
        parsedTime = formatUnixTimestamp(timeObj, timeFormat, !parseDate && isInvestigate);
    }

    return parsedTime;
}

function convertSecondsToMinutes(seconds) {
    return trimNumber(moment.duration(seconds, 'seconds').asMinutes());
}

export { dateStringToLocalDate, dateStringToLocalTime, getTimeDiff, localToUtc, parseTimeObj, formatUnixTimestamp, convertSecondsToMinutes };