import { WatchlistTypes } from "smart_cameras_events_api";

import { authorized, authorizedListType, interest, interestListType, normalSeverity, popUpOnAlertSeverity, watchlistColor, watchlisThreshold, watchlistName, watchlistNotes } from "../../../../../appConfig/Strings";
import { watchlistSeverityValues } from "@/Logic/ApiClients/WatchlistsClient";

export const watchlistType = new WatchlistTypes();

export const createWatchlistModel = {
    watchlist_name: {
        type: "text",
        name: "display_name",
        placeholder: watchlistName,
        required: true
    },
    watchlist_color: {
        type: "color",
        name: "display_color",
        placeholder: watchlistColor
    },
    watchlist_type: {
        name: "watchlist_type"
    },
    watchlist_notes: {
        type: "textarea",
        name: "watchlist_notes",
        placeholder: watchlistNotes
    },
    watchlist_threshold: {
        name: "threshold_delta",
        placeholder: watchlisThreshold,
        min: -100,
        max: 100,
        startPoint: 0
    },
    watchlist_alerted: {
        name: "severity",
        dataKey: "value",
        textField: "label"
    },
    watchlist_sound_on_alert: {
        name: "sound_on_alert",
        type: "checkbox"
    },
    watchlist_repeated_alert: {
        name: "repeated_alert",
        type: "checkbox"
    }
};

export const watchlistTypeOptions = [{
    label: interestListType,
    value: watchlistType.blacklist
}, {
    label: authorizedListType,
    value: watchlistType.whitelist
}];

export const createWatchlistDefaultValues = {
    watchlist_threshold: 0,
    watchlist_color: "#dc0000"
};

export const watchlistDisplayType = [{
    label: interest,
    value: watchlistType.blacklist
}, {
    label: authorized,
    value: watchlistType.whitelist
}];

export const watchlistSeverityOptions = [{
    label: normalSeverity,
    value: watchlistSeverityValues.normal_severity
}, {
    label: popUpOnAlertSeverity,
    value: watchlistSeverityValues.popUpOnAlert_severity
}];