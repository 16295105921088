import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';

import Webcam from "react-webcam";
import { Alert, Button } from "@mui/material";

import { nextLabel, retakeLabel, takePhotoLabel, unableToLoadCamera, usbDevices, usePicLabel } from "src/appConfig/Strings";
import SelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField";
import { videoConstraints } from "@/Components/POIs/CreateEditPOI/CreateEditPOI.model";
import { getAllUSBCameras } from "@/Logic/Hooks/usePrepareCreateEditPoi";
import ImageWrapper from "@/Components/Common/Wrappers/ImageWrapper";
import { cameraSnapshotInterface, CameraSnapshotModel } from "./CameraSnapshotStep.model";
import { progressStepperInterface } from "../../ProgressStepper.model";

function CameraSnapshotStep({ currData, setCurrData }) {
    const [devices, setDevices] = useState([]);
    const [isWebcamReady, setIsWebcamReady] = useState(false);
    const [isWebcamError, setIsWebcamError] = useState(false);
    const webcamRef = useRef(null);

    const getCameraDevices = useCallback(async () => {
        const cameras = await getAllUSBCameras();
        setDevices(cameras);
        setCurrData({ newCompData: { [cameraSnapshotInterface.selectUsbDevice]: cameras[0]?.deviceId }, isLeftBtnDisabled: false });
    }, [setCurrData]);

    const handleSelectCaptureDevice = useCallback((event) => {
        setIsWebcamReady(false);
        setIsWebcamError(false);
        setCurrData({ newCompData: { [cameraSnapshotInterface.selectUsbDevice]: event.target.value }, isLeftBtnDisabled: false });
    }, [setCurrData]);

    const handleCaptureImage = useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot();
        setIsWebcamReady(false);
        const newData = {
            [cameraSnapshotInterface.imgData]: pictureSrc,
            [progressStepperInterface.rightBtnText]: usePicLabel
        };
        setCurrData({ newCompData: newData, newIsFinal: true, isLeftBtnDisabled: false });
    }, [setCurrData]);

    const handleRetakeImage = useCallback(() => {
        const newData = {
            [cameraSnapshotInterface.imgData]: undefined,
            [progressStepperInterface.rightBtnText]: nextLabel
        };
        setCurrData({ newCompData: newData, newIsFinal: false, isLeftBtnDisabled: false });
    }, [setCurrData]);

    useEffect(() => {
        getCameraDevices();
        return () => { };
    }, []);

    const DeviceSelector = useMemo(() => (
        <SelectInputField
            label={usbDevices}
            model={CameraSnapshotModel.selectUsbDevice}
            value={currData.selectUsbDevice}
            onChange={handleSelectCaptureDevice}
            options={devices}
            disabled={Boolean(currData.imgData)}
        />
    ), [currData.imgData, currData.selectUsbDevice, devices, handleSelectCaptureDevice]);

    const cameraSnapshot = useMemo(() => {
        let componentData = (
            <div className="flex size-96 w-full items-center justify-center">
                <Alert className="flex w-full justify-center" severity="error" >
                    {unableToLoadCamera}
                </Alert>
            </div>
        );

        if (!isWebcamError) {
            componentData = (
                <Webcam
                    ref={webcamRef}
                    height={videoConstraints.height}
                    width={videoConstraints.width}
                    screenshotFormat="image/png"
                    onUserMedia={() => {
                        setIsWebcamError(false);
                        setIsWebcamReady(true);
                    }}
                    onUserMediaError={(error) => {
                        setIsWebcamError(true);
                        setIsWebcamReady(false);
                    }}
                    videoConstraints={{
                        facingMode: "user",
                        deviceId: currData.selectUsbDevice
                    }}
                />
            );
        }

        return componentData;
    }, [currData.selectUsbDevice, isWebcamError]);

    return (
        <div className="mb-0.5 flex size-full flex-col gap-2">
            <section>
                {DeviceSelector}
            </section>
            <div className="flex grow flex-col">
                <section className="grow">
                    {!currData.imgData ? (cameraSnapshot) : (<ImageWrapper src={currData.imgData} />)}
                </section>
                <section className="w-full">
                    {!currData.imgData ? (
                        <Button className="w-auto normal-case" endIcon={<CenterFocusWeakIcon />} onClick={handleCaptureImage} disabled={!isWebcamReady}>
                            {takePhotoLabel}
                        </Button>
                    ) : (
                        <Button className="w-auto normal-case" endIcon={<FlipCameraAndroidIcon />} onClick={handleRetakeImage}>
                            {retakeLabel}
                        </Button>
                    )}
                </section>
            </div>
        </div>
    );
}

export default CameraSnapshotStep;