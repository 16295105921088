"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PoiProximityConfig model module.
 * @module model/PoiProximityConfig
 * @version 0.37.0
 */
class PoiProximityConfig {
  /**
   * Constructs a new <code>PoiProximityConfig</code>.
   * @alias module:model/PoiProximityConfig
   * @param alertingWatchlists {Object} 
   * @param counterWatchlists {Object} 
   * @param appearancesOverlapTimeout {Object} 
   */
  constructor(alertingWatchlists, counterWatchlists, appearancesOverlapTimeout) {
    PoiProximityConfig.initialize(this, alertingWatchlists, counterWatchlists, appearancesOverlapTimeout);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, alertingWatchlists, counterWatchlists, appearancesOverlapTimeout) {
    obj['alerting_watchlists'] = alertingWatchlists;
    obj['counter_watchlists'] = counterWatchlists;
    obj['appearances_overlap_timeout'] = appearancesOverlapTimeout;
  }

  /**
   * Constructs a <code>PoiProximityConfig</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PoiProximityConfig} obj Optional instance to populate.
   * @return {module:model/PoiProximityConfig} The populated <code>PoiProximityConfig</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PoiProximityConfig();
      if (data.hasOwnProperty('camera_ids')) {
        obj['camera_ids'] = _ApiClient.default.convertToType(data['camera_ids'], Object);
      }
      if (data.hasOwnProperty('videos_ids')) {
        obj['videos_ids'] = _ApiClient.default.convertToType(data['videos_ids'], Object);
      }
      if (data.hasOwnProperty('excluded_watchlists')) {
        obj['excluded_watchlists'] = _ApiClient.default.convertToType(data['excluded_watchlists'], Object);
      }
      if (data.hasOwnProperty('min_detection_width')) {
        obj['min_detection_width'] = _ApiClient.default.convertToType(data['min_detection_width'], Object);
      }
      if (data.hasOwnProperty('alerting_watchlists')) {
        obj['alerting_watchlists'] = _ApiClient.default.convertToType(data['alerting_watchlists'], Object);
      }
      if (data.hasOwnProperty('counter_watchlists')) {
        obj['counter_watchlists'] = _ApiClient.default.convertToType(data['counter_watchlists'], Object);
      }
      if (data.hasOwnProperty('appearances_overlap_timeout')) {
        obj['appearances_overlap_timeout'] = _ApiClient.default.convertToType(data['appearances_overlap_timeout'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PoiProximityConfig</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PoiProximityConfig</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of PoiProximityConfig.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
PoiProximityConfig.RequiredProperties = ["alerting_watchlists", "counter_watchlists", "appearances_overlap_timeout"];

/**
 * Live cameras that should trigger the insight
 * @member {Object} camera_ids
 */
PoiProximityConfig.prototype['camera_ids'] = undefined;

/**
 * Forensics videos that should trigger the insight
 * @member {Object} videos_ids
 */
PoiProximityConfig.prototype['videos_ids'] = undefined;

/**
 * List of Watchlists to exclude from the insight
 * @member {Object} excluded_watchlists
 */
PoiProximityConfig.prototype['excluded_watchlists'] = undefined;

/**
 * @member {Object} min_detection_width
 */
PoiProximityConfig.prototype['min_detection_width'] = undefined;

/**
 * @member {Object} alerting_watchlists
 */
PoiProximityConfig.prototype['alerting_watchlists'] = undefined;

/**
 * @member {Object} counter_watchlists
 */
PoiProximityConfig.prototype['counter_watchlists'] = undefined;

/**
 * @member {Object} appearances_overlap_timeout
 */
PoiProximityConfig.prototype['appearances_overlap_timeout'] = undefined;
var _default = exports.default = PoiProximityConfig;