import { createSlice } from '@reduxjs/toolkit';

import { daysInputMap, secondsInputMap, valueToTimeMapping } from '@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector.model';
import { appearancePrivacyNameMapping } from '@/Components/Settings/Privacy/CreateEditAppearancesPrivacy/CreateEditAppearancesPrivacy.model';

import { auditRecords, auditStorageLimitation, clearAuditRecordsAutomatically, clearHistoryRecordsAutomatically, clearRecordsTooltip, historyRecords, historyStorageAutoClear, historyStorageLimitation, insightsRecords, playbackFrames, retainedHistoryRecords, retainedHistoryRecordsTooltip, retainedPlaybackFrames, retainedPlaybackTooltip, unaffectedRetainedAppearances } from 'src/appConfig/Strings';

const initialState = {
    historyRetention: {},
    framesRetention: {},
    auditRetention: {},
    insightsRetention: {},
    historyAutoClear: {}
};

export const ttlSettingsMapping = {
    [historyStorageLimitation]: {
        isEnabledPropName: "ttl_enabled",
        nameMapping: appearancePrivacyNameMapping,
    },
    [auditStorageLimitation]: {
        isEnabledPropName: "",
        nameMapping: {
            ttl_enabled: {
                name: clearAuditRecordsAutomatically,
            }
        }
    },
    [historyStorageAutoClear]: {
        isEnabledPropName: "",
        nameMapping: {
            enable_autoclear: {
                name: clearHistoryRecordsAutomatically,
                tooltipTxt: unaffectedRetainedAppearances
            }
        }
    }
};

const dataRetentionSlice = createSlice({
    name: 'dataRetention',
    initialState,
    reducers: {
        setHistoryRetentionData: (state, action) => {
            const retentionInfo = action.payload;
            setupCapacityInfo(retentionInfo, historyRecords, retainedHistoryRecords, retainedHistoryRecordsTooltip);
            Object.keys(retentionInfo.ttl_settings).forEach(key => {
                retentionInfo.ttl_settings[key].ttl_seconds = valueToTimeMapping(retentionInfo.ttl_settings[key].ttl_seconds, secondsInputMap);
            });
            retentionInfo.ttl_settings.name = historyStorageLimitation;

            state.historyRetention = retentionInfo;
            return state;
        },
        setFramesRetentionData: (state, action) => {
            const retentionInfo = action.payload;
            setupCapacityInfo(retentionInfo, playbackFrames, retainedPlaybackFrames, retainedPlaybackTooltip);

            state.framesRetention = retentionInfo;
            return state;
        },
        setAuditRetentionData: (state, action) => {
            const retentionInfo = action.payload;
            setupCapacityInfo(retentionInfo, auditRecords);

            retentionInfo.ttl_settings.name = auditStorageLimitation;
            retentionInfo.ttl_settings.ttl_seconds = valueToTimeMapping(retentionInfo.ttl_settings.ttl_seconds, secondsInputMap);
            state.auditRetention = retentionInfo;
            return state;
        },
        setInsightsRetentionData: (state, action) => {
            const retentionInfo = action.payload;
            setupCapacityInfo(retentionInfo, insightsRecords);

            state.insightsRetention = retentionInfo;
            return state;
        },
        setHistoryAutoClearData: (state, action) => {
            const autoClearInfo = action.payload;
            autoClearInfo.name = historyStorageAutoClear;
            autoClearInfo.days_interval = valueToTimeMapping(autoClearInfo.days_interval, daysInputMap);

            state.historyAutoClear = autoClearInfo;
            return state;
        },
    }
});

function setupCapacityInfo(retentionInfo, type, retainName, retainedTooltip) {
    retentionInfo.total_capacity.name = type;
    retentionInfo.total_capacity.tooltip = clearRecordsTooltip(retentionInfo.total_capacity.fraction_to_remove_when_max_reached * 100, type);
    if (retainName) {
        retentionInfo.retained_capacity.name = retainName;
        retentionInfo.retained_capacity.tooltip = retainedTooltip;
    }
}

export const { setHistoryRetentionData, setFramesRetentionData, setAuditRetentionData, setInsightsRetentionData
    , setHistoryAutoClearData } = dataRetentionSlice.actions;

export default dataRetentionSlice.reducer;
