"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AddDetectedFacesResponseModel = _interopRequireDefault(require("../model/AddDetectedFacesResponseModel"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Stream service.
* @module api/StreamApi
* @version 0.37.1
*/
class StreamApi {
  /**
  * Constructs a new StreamApi. 
  * @alias module:api/StreamApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addAppearancesFromImage operation.
   * @callback module:api/StreamApi~addAppearancesFromImageCallback
   * @param {String} error Error message, if any.
   * @param {module:model/AddDetectedFacesResponseModel} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add Appearances From Image
   * Get an image with faces and a camera name. This endpoint will processes a given image as a source by detecting faces and matching them to the given watchlists. The endpoint returns success list for each face in the image and the created camera id.
   * @param {Object} body 
   * @param {module:api/StreamApi~addAppearancesFromImageCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/AddDetectedFacesResponseModel}
   */
  addAppearancesFromImage(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling addAppearancesFromImage");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _AddDetectedFacesResponseModel.default;
    return this.apiClient.callApi('/poi_db/stream/img', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the addEventToHistory operation.
   * @callback module:api/StreamApi~addEventToHistoryCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add Event
   * Get an appearance event. This endpoint will processes a given event as a source by matching to the given watchlists and save it in history.
   * @param {Object} body 
   * @param {module:api/StreamApi~addEventToHistoryCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  addEventToHistory(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling addEventToHistory");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/poi_db/stream/event', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the addFaceToHistory operation.
   * @callback module:api/StreamApi~addFaceToHistoryCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add Face
   * Get a signature of a face or cropped face. This endpoint will processes a given face as a source by matching to the given watchlists.
   * @param {Object} body 
   * @param {module:api/StreamApi~addFaceToHistoryCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  addFaceToHistory(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling addFaceToHistory");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/poi_db/stream/face', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = StreamApi;