import { useState, useCallback, useMemo } from "react";

import BaseDataGrid from "../BaseDataGrid/BaseDataGrid";
import { baseDatagridStyle } from "../../Styles/DataGridSx";

function ExtendedDataGrid({ expandable = true, enablePinning = false, sx = baseDatagridStyle, ...props }) {
    const [expandedRowId, setExpandedRowId] = useState([]);
    const [pinnedRowId, setPinnedRowId] = useState(null);

    const handleRowExpand = useCallback((rowIds) => {
        const rowId = rowIds.at(-1);
        if (expandedRowId === rowId || !rowId) {
            setExpandedRowId([]);
        } else if (rowId) {
            setExpandedRowId([rowId]);
        }
    }, [expandedRowId]);

    const handleRowClick = useCallback((rowElement) => {
        if (!enablePinning) {
            return;
        }

        setPinnedRowId((prevPinnedRowId) => (
            prevPinnedRowId === rowElement.id ? null : rowElement.id
        ));
    }, [enablePinning]);

    const detailPanelProps = useMemo(() => {
        return expandable ? {
            detailPanelExpandedRowIds: expandedRowId,
            onDetailPanelExpandedRowIdsChange: (rowId) => handleRowExpand(rowId)
        } : {};
    }, [expandable, expandedRowId, handleRowExpand]);

    const pinnedRows = useMemo(() => {
        if (!enablePinning || !pinnedRowId) {
            return undefined;
        }

        const pinnedRow = props.rows.find(row => pinnedRowId === props.getRowId(row));
        return pinnedRow ? { bottom: [pinnedRow] } : undefined;
    }, [enablePinning, pinnedRowId, props]);

    const rows = useMemo(() => {
        if (!enablePinning || !pinnedRowId) {
            return props.rows;
        }

        return props.rows.filter(row => props.getRowId(row) !== pinnedRowId);
    }, [enablePinning, pinnedRowId, props]);

    return (
        <BaseDataGrid
            className="max-h-main-layout overflow-hidden"
            sx={sx}
            {...props}
            rows={rows}
            pinnedRows={pinnedRows}
            onRowDoubleClick={(rowElement) => handleRowClick(rowElement)}
            getRowHeight={() => 'auto'}
            {...detailPanelProps}
        />
    );
}

export default ExtendedDataGrid;