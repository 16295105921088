import { useCallback, useContext, useEffect } from "react";

import store from "src/appConfig/configureStore";
import { setIsLoggedIn } from "../Redux/Stores/ApiActionsStore";
import useEventListener from "./useEventListener/useEventListener";
import { sessionEnded } from "../ApiClients/EventsClient/EventsClient.model";
import { sessionEndedSignalEvent } from "src/appConfig/customEvents";
import ApiContext from "../Context/apiContext";

function useSessionEndListener() {
    const { clientsManager } = useContext(ApiContext);

    const handleSseDisconnected = useCallback((receivedEventType) => {
        if (receivedEventType !== sessionEnded) {
            return;
        }
        store.dispatch(setIsLoggedIn(false));
    }, []);

    useEventListener(clientsManager.eventsClientWorker, handleSseDisconnected);

    useEffect(() => {
        const endSession = () => {
            store.dispatch(setIsLoggedIn(false));
        };

        document.addEventListener(sessionEndedSignalEvent, endSession);
        return () => {
            document.removeEventListener(sessionEndedSignalEvent, endSession);
        };
    }, []);
}

export default useSessionEndListener;