import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const watchlistsSlice = createSlice({
    name: 'watchlists',
    initialState,
    reducers: {
        setWatchlists: (state, action) => {
            return action.payload;
        },
        addWatchlist: (state, action) => {
            const { payload } = action;
            state[payload.watchlist_id] = payload;
        },
        removeWatchlist: (state, action) => {
            delete state[action.payload];

            return state;
        }
    }
});

export const { setWatchlists, addWatchlist, removeWatchlist } = watchlistsSlice.actions;

export default watchlistsSlice.reducer;
