"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetAuditRetentionResponse = _interopRequireDefault(require("../model/GetAuditRetentionResponse"));
var _GetAutoClearRetentionResponse = _interopRequireDefault(require("../model/GetAutoClearRetentionResponse"));
var _GetFSMRetentionResponse = _interopRequireDefault(require("../model/GetFSMRetentionResponse"));
var _GetGenerativeInsightsRetentionResponse = _interopRequireDefault(require("../model/GetGenerativeInsightsRetentionResponse"));
var _GetHistoryRetentionResponse = _interopRequireDefault(require("../model/GetHistoryRetentionResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Controller API
 * ## Control & Management - Status Endpoint allows the user of the API to see the very basic data of the deployment - and notably, the deployment_id - even if the user has no credentials or valid license - License Endpoint allows an authenticated user to view current license usage  ## System Settings **Privacy settings** - 'Privacy' is a global system mode that allows a user of the system to restrict the biometric data that is saved and/or outputted by SC. - The global system mode, as well as the specific privacy settings that control which biometric data the system is permitted to save and output, are configured using the privacy settings API of Controller Service.  **SearchFace settings** - Settings that apply to the SearchFace endpoints (in POI DB & History DB)  **Analysis settings** - System-wide settings related to the analysis of streams  ## Data Retention Perform actions & retrieve information regarding the data saved by the system - how many records exist, what is the max capcity, enable automatic clearing etc. 
 *
 * The version of the OpenAPI document: 0.36.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* DataRetention service.
* @module api/DataRetentionApi
* @version 0.36.2
*/
class DataRetentionApi {
  /**
  * Constructs a new DataRetentionApi. 
  * @alias module:api/DataRetentionApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the getAuditRetention operation.
   * @callback module:api/DataRetentionApi~getAuditRetentionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAuditRetentionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Audit Retention
   * Get the retention config of Audit records
   * @param {module:api/DataRetentionApi~getAuditRetentionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAuditRetentionResponse}
   */
  getAuditRetention(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetAuditRetentionResponse.default;
    return this.apiClient.callApi('/data_retention/audit/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getAutoClearRetention operation.
   * @callback module:api/DataRetentionApi~getAutoClearRetentionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAutoClearRetentionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Auto Clear Config
   * Get the appearances auto clear settings
   * @param {module:api/DataRetentionApi~getAutoClearRetentionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAutoClearRetentionResponse}
   */
  getAutoClearRetention(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetAutoClearRetentionResponse.default;
    return this.apiClient.callApi('/data_retention/autoclear/history/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getFramesStorageRetention operation.
   * @callback module:api/DataRetentionApi~getFramesStorageRetentionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetFSMRetentionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Frames Storage Retention
   * Get the retention config of the Frames storage. Frames storage retention uses sequences of appearances as a record. For example when capacity is 100 records it means frames storage's current capacity is 100 frames sequences.
   * @param {module:api/DataRetentionApi~getFramesStorageRetentionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetFSMRetentionResponse}
   */
  getFramesStorageRetention(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetFSMRetentionResponse.default;
    return this.apiClient.callApi('/data_retention/frames_storage/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getGenerativeInsightsRetention operation.
   * @callback module:api/DataRetentionApi~getGenerativeInsightsRetentionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetGenerativeInsightsRetentionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Generative Insights Retention
   * Get the retention config of Generative Insights records
   * @param {module:api/DataRetentionApi~getGenerativeInsightsRetentionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetGenerativeInsightsRetentionResponse}
   */
  getGenerativeInsightsRetention(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetGenerativeInsightsRetentionResponse.default;
    return this.apiClient.callApi('/data_retention/generative_insights/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getHistoryRetention operation.
   * @callback module:api/DataRetentionApi~getHistoryRetentionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetHistoryRetentionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get History Retention
   * Get the retention config of History records
   * @param {module:api/DataRetentionApi~getHistoryRetentionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetHistoryRetentionResponse}
   */
  getHistoryRetention(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetHistoryRetentionResponse.default;
    return this.apiClient.callApi('/data_retention/history/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setAuditRetention operation.
   * @callback module:api/DataRetentionApi~setAuditRetentionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAuditRetentionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set Audit Retention
   * Set the retention config of Audit records
   * @param {Object} body 
   * @param {module:api/DataRetentionApi~setAuditRetentionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAuditRetentionResponse}
   */
  setAuditRetention(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setAuditRetention");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetAuditRetentionResponse.default;
    return this.apiClient.callApi('/data_retention/audit/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setAutoClearRetention operation.
   * @callback module:api/DataRetentionApi~setAutoClearRetentionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAutoClearRetentionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set Auto Clear Config
   * Set the appearances auto clear settings
   * @param {Object} body 
   * @param {module:api/DataRetentionApi~setAutoClearRetentionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAutoClearRetentionResponse}
   */
  setAutoClearRetention(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setAutoClearRetention");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetAutoClearRetentionResponse.default;
    return this.apiClient.callApi('/data_retention/autoclear/history/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setFramesStorageRetention operation.
   * @callback module:api/DataRetentionApi~setFramesStorageRetentionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetFSMRetentionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set Frames Storage Retention
   * Set the retention config of the Frames sequence records
   * @param {Object} body 
   * @param {module:api/DataRetentionApi~setFramesStorageRetentionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetFSMRetentionResponse}
   */
  setFramesStorageRetention(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setFramesStorageRetention");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetFSMRetentionResponse.default;
    return this.apiClient.callApi('/data_retention/frames_storage/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setGenerativeInsightsRetention operation.
   * @callback module:api/DataRetentionApi~setGenerativeInsightsRetentionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetGenerativeInsightsRetentionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set Generative Insights Retention
   * Set the retention config of Generative Insights records
   * @param {Object} body 
   * @param {module:api/DataRetentionApi~setGenerativeInsightsRetentionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetGenerativeInsightsRetentionResponse}
   */
  setGenerativeInsightsRetention(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setGenerativeInsightsRetention");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetGenerativeInsightsRetentionResponse.default;
    return this.apiClient.callApi('/data_retention/generative_insights/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setHistoryRetention operation.
   * @callback module:api/DataRetentionApi~setHistoryRetentionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetHistoryRetentionResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set History Retention
   * Set the retention config of History records
   * @param {Object} body 
   * @param {module:api/DataRetentionApi~setHistoryRetentionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetHistoryRetentionResponse}
   */
  setHistoryRetention(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setHistoryRetention");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetHistoryRetentionResponse.default;
    return this.apiClient.callApi('/data_retention/history/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = DataRetentionApi;