import { alertingAppearanceDurationCrowdedInfo, alertingAppearanceDurationTxt, alertingUniqueIndividualsCrowdedInfo, alertingUniqueIndividualsTxt } from "src/appConfig/Strings";
import LabelWrapper from "@/Components/Common/Wrappers/LabelWrapper";
import BaseInput from "@/Components/Common/FormComponents/FormInputs/BaseInput";
import { crowdedAreaFormFieldsNames, CrowdedAreaInsightFormModel } from "./CrowdedAreaInsight.model";
import { minSecTimeMapping } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector.model";
import { DurationSelector } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector";
import InsightCamerasSelector from "../../InsightCamerasSelector/InsightCamerasSelector";

const { alertingUniqueIndividuals, alertingCrowdedDuration } = crowdedAreaFormFieldsNames;

function CrowdedAreaInsight({ formData, onFieldChange }) {
    return (
        <div className="flex flex-col gap-1">
            <InsightCamerasSelector model={CrowdedAreaInsightFormModel} formData={formData} onFieldChange={onFieldChange} />
            <LabelWrapper label={alertingUniqueIndividualsTxt} tooltipTxt={alertingUniqueIndividualsCrowdedInfo}>
                <BaseInput
                    label={alertingUniqueIndividualsTxt}
                    model={CrowdedAreaInsightFormModel[alertingUniqueIndividuals]}
                    value={formData[alertingUniqueIndividuals]}
                    onChange={(event) => onFieldChange(alertingUniqueIndividuals, event.target.valueAsNumber)}
                    className="w-[25rem]"
                />
            </LabelWrapper>
            <LabelWrapper label={alertingAppearanceDurationTxt} tooltipTxt={alertingAppearanceDurationCrowdedInfo} >
                <DurationSelector
                    model={CrowdedAreaInsightFormModel[alertingCrowdedDuration]}
                    value={formData[alertingCrowdedDuration]}
                    onChange={(value) => onFieldChange(alertingCrowdedDuration, value)}
                    timeUnitsMap={minSecTimeMapping}
                />
            </LabelWrapper>
        </div>
    );
}

export default CrowdedAreaInsight;