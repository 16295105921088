import { useMemo } from "react";

import { Stack } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";

import NoNewMessages from "./NoNewMessages";
import AlertElement from "./AlertElement";

function NotificationStack({ notifications, unreadCount, showUnreadOnly, markAsRead }) {

    const filteredNotifications = useMemo(() => {
        if (showUnreadOnly) {
            return notifications.filter(notification => !notification.read);
        }
        return notifications;
    }, [showUnreadOnly, notifications]);

    const noNotificationsToShow = useMemo(() =>
        filteredNotifications.length === 0 || (unreadCount === 0 && showUnreadOnly), [filteredNotifications, unreadCount, showUnreadOnly]
    );

    return (
        <Stack className="notification-stack bg-white p-3" spacing={2}>
            {noNotificationsToShow ? (
                <PerfectScrollbar className="scroll-area-sm w-100">
                    <NoNewMessages className="p-3" />
                </PerfectScrollbar>
            ) : (
                filteredNotifications.map(notification => (
                    <AlertElement key={notification.id} notification={notification} markAsRead={markAsRead} />
                ))
            )}
        </Stack>
    );
}

export default NotificationStack;
