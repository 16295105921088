import { createSlice } from '@reduxjs/toolkit';
import { last } from 'lodash';

import { appearancesPrivacyFormFieldsNames } from "@/Components/Settings/Privacy/CreateEditAppearancesPrivacy/CreateEditAppearancesPrivacy.model";
import { watchlistPrivacyFormFieldsNames } from "@/Components/Settings/Privacy/CreateEditWatchlistPrivacy/CreateEditWatchlistPrivacy.model";
import { guardianConsentAgeGroup } from "@/Logic/ApiClients/PrivacyProfilesClient";

const {
    stream,
    history,
    matchInterest,
    saveSignature,
    detected,
    showFaceImage,
    showAge,
    showGender,
    showWatchlistNotes,
    showMatchDisplayName,
    showMatchDisplayImage,
    showMatchPoiNotes,
    authorized,
    notAuthorized,
    saveFaceImage,
    saveAge,
    saveGender,
    saveWatchlistNotes,
    saveMatchDisplayName,
    saveMatchDisplayImage,
    saveMatchPoiNotes,
    sendEvent,
    saveEvent,
    privacyName,
    sendSignature
} = appearancesPrivacyFormFieldsNames;

const {
    watchlistPrivacyName,
    savePoiDisplayImg,
    savePoiFaceCrop,
    savePoiFaceGenderOutcome,
    savePoiFaceAgeGroupOutcome,
    requireGuardianConsent,
    requireGuardianConsentAgeGroup
} = watchlistPrivacyFormFieldsNames;

const commonStreamConfig = {
    [sendEvent]: true,
    [showFaceImage]: true,
    [showWatchlistNotes]: true,
    [showAge]: true,
    [showGender]: true,
    [sendSignature]: true,
};

const streamMatchInterestAndAuthorizedConfig = {
    [sendEvent]: true,
    [showFaceImage]: true,
    [showMatchDisplayName]: true,
    [showMatchDisplayImage]: true,
    [showMatchPoiNotes]: true,
    [showWatchlistNotes]: true,
    [showAge]: true,
    [showGender]: true,
    [sendSignature]: true
};

const commonHistoryConfig = {
    [saveEvent]: true,
    [saveFaceImage]: true,
    [saveAge]: true,
    [saveGender]: true,
    [saveWatchlistNotes]: true,
    [saveSignature]: true
};

const historyMatchInterestAndAuthorizedConfig = {
    [saveEvent]: true,
    [saveFaceImage]: true,
    [saveMatchDisplayName]: true,
    [saveMatchDisplayImage]: true,
    [saveMatchPoiNotes]: true,
    [saveWatchlistNotes]: true,
    [saveAge]: true,
    [saveGender]: true,
    [saveSignature]: true
};

export const privacyProfilesDefaultValue = {
    [stream]: {
        [detected]: {
            ...commonStreamConfig
        },
        [matchInterest]: {
            ...streamMatchInterestAndAuthorizedConfig
        },
        [authorized]: {
            ...streamMatchInterestAndAuthorizedConfig
        },
        [notAuthorized]: {
            ...commonStreamConfig
        }
    },
    [history]: {
        [detected]: {
            ...commonHistoryConfig
        },
        [matchInterest]: {
            ...historyMatchInterestAndAuthorizedConfig
        },
        [authorized]: {
            ...historyMatchInterestAndAuthorizedConfig
        },
        [notAuthorized]: {
            ...commonHistoryConfig
        }
    }
};

export const watchlistPrivacyProfilesDefaultValue = {
    [savePoiDisplayImg]: true,
    [savePoiFaceCrop]: true,
    [savePoiFaceGenderOutcome]: true,
    [savePoiFaceAgeGroupOutcome]: true,
    [requireGuardianConsent]: false,
    [requireGuardianConsentAgeGroup]: [guardianConsentAgeGroup[0].group]
};

const copySettingsBasedOnConfig = (config, settings) => {
    return Object.keys(config).reduce((acc, key) => {
        acc[key] = settings[key];
        return acc;
    }, {});
};

const INITIAL_STATE = {
    appearancesProfiles: {},
    watchlistProfiles: {},
    defaultAppearancesProfile: "",
    defaultPoiProfile: "",
    openAppearancesPrivacy: {
        ...privacyProfilesDefaultValue
    },
    openWatchlistPrivacy: {
        ...watchlistPrivacyProfilesDefaultValue
    }
};

const PrivacyProfilesStore = createSlice({
    name: 'privacyProfiles',
    initialState: INITIAL_STATE,
    reducers: {
        setAppearancesProfiles: (state, action) => {
            const { payload } = action;
            state.appearancesProfiles = payload;
        },
        setWatchlistProfiles: (state, action) => {
            const { payload } = action;
            state.watchlistProfiles = payload;
        },
        setOpenWatchlistProfile: (state, action) => {
            const watchlistPrivacyData = action.payload;
            const watchlistPrivacyFormFields = {
                [watchlistPrivacyName]: watchlistPrivacyData.name,
                [savePoiDisplayImg]: watchlistPrivacyData.settings.save_poi_display_image,
                [savePoiFaceCrop]: watchlistPrivacyData.settings.save_poi_face_crops,
                [savePoiFaceGenderOutcome]: watchlistPrivacyData.settings.save_poi_face_gender_outcome,
                [savePoiFaceAgeGroupOutcome]: watchlistPrivacyData.settings.save_poi_face_age_group_outcome,
                [requireGuardianConsent]: watchlistPrivacyData.settings.require_guardian_consent,
                [requireGuardianConsentAgeGroup]: [last(watchlistPrivacyData.settings.require_guardian_consent_age_groups)]
            };

            state.openWatchlistPrivacy = {
                ...state.openWatchlistPrivacy,
                ...watchlistPrivacyFormFields
            };

            return state;
        },
        setOpenAppearancesPrivacy: (state, action) => {
            const appearancesPrivacyData = action.payload;
            const appearancesPrivacyFormFields = {
                [privacyName]: appearancesPrivacyData.name,
                [stream]: {
                    [detected]: copySettingsBasedOnConfig(commonStreamConfig, appearancesPrivacyData.settings.stream.detected),
                    [matchInterest]: copySettingsBasedOnConfig(streamMatchInterestAndAuthorizedConfig, appearancesPrivacyData.settings.stream.match_interest),
                    [authorized]: copySettingsBasedOnConfig(streamMatchInterestAndAuthorizedConfig, appearancesPrivacyData.settings.stream.authorized),
                    [notAuthorized]: copySettingsBasedOnConfig(commonStreamConfig, appearancesPrivacyData.settings.stream.not_authorized)
                },
                [history]: {
                    [detected]: copySettingsBasedOnConfig(commonHistoryConfig, appearancesPrivacyData.settings.history.detected),
                    [matchInterest]: copySettingsBasedOnConfig(historyMatchInterestAndAuthorizedConfig, appearancesPrivacyData.settings.history.match_interest),
                    [authorized]: copySettingsBasedOnConfig(historyMatchInterestAndAuthorizedConfig, appearancesPrivacyData.settings.history.authorized),
                    [notAuthorized]: copySettingsBasedOnConfig(commonHistoryConfig, appearancesPrivacyData.settings.history.not_authorized)
                }
            };
            state.openAppearancesPrivacy = {
                ...state.openAppearancesPrivacy,
                ...appearancesPrivacyFormFields
            };

            return state;
        },
        clearOpenAppearancesPrivacy: (state) => {
            state.openAppearancesPrivacy = {
                ...privacyProfilesDefaultValue
            };
        },
        clearOpenWatchlistPrivacy: (state) => {
            state.openWatchlistPrivacy = {
                ...watchlistPrivacyProfilesDefaultValue
            };
        },
        storeDefaultPrivacyProfile: (state, action) => {
            const { id, isAppearanceProfile } = action.payload;
            isAppearanceProfile ?
                state.defaultAppearancesProfile = id :
                state.defaultPoiProfile = id;
        },
    }
});

export const {
    setAppearancesProfiles,
    setWatchlistProfiles,
    storeDefaultPrivacyProfile,
    clearOpenAppearancesPrivacy,
    clearOpenWatchlistPrivacy,
    setOpenAppearancesPrivacy,
    setOpenWatchlistProfile,
} = PrivacyProfilesStore.actions;

export default PrivacyProfilesStore.reducer;