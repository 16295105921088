import { useEffect, useMemo } from "react";

import { isEmpty, upperFirst } from "lodash";
import { Alert, Paper } from "@mui/material";
import FocusTrap from "@mui/material/Unstable_TrapFocus";
import Loader from "react-loaders";

import useAnalyze from "@/Logic/Hooks/useAnalyze";
import ImageWrapper from "../../Wrappers/ImageWrapper";
import { progressStepperInterface } from "../ProgressStepper.model";
import { analyzeFaceNote, confirm, forceUploadNote, lowQuality } from "src/appConfig/Strings";
import { detectInterface } from "./DetectRotateStep";
import BadgeWrapper from "../../Wrappers/BadgeWrapper";
import { analyzeCardCenterBottomBadge } from "../../Styles/BadgeSx";

export const analyzeInterface = {
    analyzedImg: "analyzedImg",
    isValidFace: "isValidFace",
    faceAttributes: "faceAttributes",
    forceUpload: "forceUpload"
};

function AnalyzeStep({ prevStepData, currData, setCurrData }) {
    const preparedImg = prevStepData[detectInterface.finalStepData];
    const { isLoading, error, responseMessage, analyzeData } = useAnalyze(preparedImg);

    useEffect(() => {
        if (isEmpty(analyzeData) || isLoading || error) {
            return;
        }

        setCurrData({
            newCompData: {
                [analyzeInterface.isValidFace]: analyzeData.valid_face,
                [analyzeInterface.faceAttributes]: analyzeData.faceAttributes,
                [analyzeInterface.forceUpload]: true,
                [progressStepperInterface.rightBtnText]: confirm,
            },
            newIsFinal: !analyzeData.valid_face,
            autoTriggerNextStep: analyzeData.valid_face,
            isLeftBtnDisabled: false,
        });
    }, [analyzeData, error, isLoading, setCurrData]);

    useEffect(() => {
        if (!isEmpty(currData)) {
            return;
        }

        setCurrData({
            newCompData: {
                [analyzeInterface.analyzedImg]: preparedImg,
            },
            isLeftBtnDisabled: false,
        });
    }, []);

    const analyzeRes = useMemo(() => {
        let analyzeResults = (
            <div className="flex w-full flex-col items-center gap-3">
                <Paper elevation={5}>
                    <ImageWrapper className="h-52 !w-48 rounded" src={preparedImg} />
                </Paper>
                <Alert className="flex h-fit w-full items-center justify-center" severity="info">
                    <div className="w-full text-center text-base">
                        {analyzeFaceNote}
                    </div>
                </Alert>
                <div className="flex h-full min-h-28 flex-1 items-center justify-center">
                    <Loader type="ball-spin-fade-loader" />
                </div>
            </div>
        );

        if (error) {
            analyzeResults = (
                <div className="flex size-full items-center justify-center">
                    <FocusTrap disableEnforceFocus open >
                        <Alert className="main-text-style mb-2 w-full items-center justify-center" severity="error" tabIndex="0" data-testid="error-alert">
                            {upperFirst(responseMessage)}
                        </Alert>
                    </FocusTrap>
                </div>
            );
        } else if (analyzeData.valid_face === false) {
            analyzeResults = (
                <div className="flex w-full flex-col items-center gap-3">
                    <Paper elevation={5}>
                        <BadgeWrapper badgeContent={lowQuality} color="error" sx={analyzeCardCenterBottomBadge}>
                            <ImageWrapper className="h-52 !w-48 rounded" src={preparedImg} />
                        </BadgeWrapper>
                    </Paper>
                    <Alert className="flex w-full items-center justify-center" severity="warning">
                        <div className="w-full text-center text-base">
                            {forceUploadNote}
                        </div>
                    </Alert>
                </div>
            );
        }

        return analyzeResults;
    }, [analyzeData.valid_face, error, preparedImg, responseMessage]);

    return (
        <div className="flex size-full max-h-tooltip-box flex-col items-center">
            {analyzeRes}
        </div>
    );
}

export default AnalyzeStep;