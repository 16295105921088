function FilterContainer({ header, children, headerElement = null, className }) {
    return (
        <div className="data-txt w-80 bg-transparent">
            <div>
                <span className={`mb-1 flex flex-col gap-2 ${className}`}>
                    {header}
                    {headerElement && headerElement}
                </span>
                <div>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default FilterContainer;
