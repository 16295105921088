import { useCallback } from "react";

import Switch from '@mui/material/Switch';

function ToggleSwitchInput({ model, dataTestId, value = false, onChange = null, isDisabled, ...props }) {

    const onChangeHandler = useCallback((event) => {
        onChange && onChange(event);
    }, [onChange]);

    return (
        <Switch
            checked={value}
            value={value}
            onChange={onChangeHandler}
            data-testid={dataTestId}
            {...model}
            {...props}
            disabled={isDisabled}
        />
    );
}

export default ToggleSwitchInput;