"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GeneralStatisticsData model module.
 * @module model/GeneralStatisticsData
 * @version 0.37.0
 */
class GeneralStatisticsData {
  /**
   * Constructs a new <code>GeneralStatisticsData</code>.
   * @alias module:model/GeneralStatisticsData
   */
  constructor() {
    GeneralStatisticsData.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>GeneralStatisticsData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GeneralStatisticsData} obj Optional instance to populate.
   * @return {module:model/GeneralStatisticsData} The populated <code>GeneralStatisticsData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GeneralStatisticsData();
      if (data.hasOwnProperty('total')) {
        obj['total'] = _ApiClient.default.convertToType(data['total'], Object);
      }
      if (data.hasOwnProperty('matched_insights')) {
        obj['matched_insights'] = _ApiClient.default.convertToType(data['matched_insights'], Object);
      }
      if (data.hasOwnProperty('unmatched_insights')) {
        obj['unmatched_insights'] = _ApiClient.default.convertToType(data['unmatched_insights'], Object);
      }
      if (data.hasOwnProperty('unique_insight_persons')) {
        obj['unique_insight_persons'] = _ApiClient.default.convertToType(data['unique_insight_persons'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GeneralStatisticsData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GeneralStatisticsData</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * @member {Object} total
 */
GeneralStatisticsData.prototype['total'] = undefined;

/**
 * @member {Object} matched_insights
 */
GeneralStatisticsData.prototype['matched_insights'] = undefined;

/**
 * @member {Object} unmatched_insights
 */
GeneralStatisticsData.prototype['unmatched_insights'] = undefined;

/**
 * @member {Object} unique_insight_persons
 */
GeneralStatisticsData.prototype['unique_insight_persons'] = undefined;
var _default = exports.default = GeneralStatisticsData;