import { RequiredFaceAttributesConfig } from "@/Components/Cameras/CreateCamera/CreateEditCamera/CreateEditCamera.model";
import { notInWlOutcome } from "@/Logic/ApiClients/EventsClient/EventsClient.model";
import { notDeterminedLabel } from "src/appConfig/Strings";
import { eventTable } from "../MUITables/TablesLogic";

const AnalyticsInterfaceObject = {
    matches: "matches",
    uniquePois: "uniquePois",
    detections: "detections",
    totalAppearances: "totalAppearances",
    notInWlOutcome: "notInWlOutcome",
    uniquePersons: "uniquePersons"
};

class AnalyticsClass {
    constructor(analyticsType, analytics, defaultFeatureOutcome, uncertaintyOptions, { unique_pois, unique_persons } = {}) {
        this.uncertaintyAttributes = ["-", ...uncertaintyOptions];
        this.defaultFeatureOutcome = { ...defaultFeatureOutcome };
        this.#getStatistics(analytics);
        if (analyticsType !== eventTable) {
            this.analytics.uniquePois = this.#setCounterObject(this.analytics, AnalyticsInterfaceObject.uniquePois, unique_pois);
            this.analytics.uniquePersons = this.#setCounterObject(this.analytics, AnalyticsInterfaceObject.uniquePersons, unique_persons);
        } else {
            this.analytics.matches = this.#setCounterObject(this.analytics, AnalyticsInterfaceObject.matches, Object.values(this.analytics.matched).reduce((counter, value) => counter += value.counter, 0));
        }
        this.analytics.notInWlOutcome = this.#setCounterObject(this.analytics, AnalyticsInterfaceObject.notInWlOutcome, Object.values(this.analytics[notInWlOutcome]).reduce((counter, value) => counter += value.counter, 0));
    }

    #getStatistics(analytics) {
        this.defaultFeatureOutcome.age_group = this.defaultFeatureOutcome.age_group ?
            [...this.defaultFeatureOutcome.age_group, notDeterminedLabel] : [notDeterminedLabel];
        this.defaultFeatureOutcome.gender = this.defaultFeatureOutcome.gender ?
            [...this.defaultFeatureOutcome.gender, notDeterminedLabel] : [notDeterminedLabel];

        const initializeCounterObject = {
            ...Object.keys(this.defaultFeatureOutcome).reduce((accumulatedAttributes, faceAttribute) => ({
                ...accumulatedAttributes,
                [faceAttribute]: this.defaultFeatureOutcome[faceAttribute] ? this.defaultFeatureOutcome[faceAttribute].reduce((accumulatedObject, attributeOutcome) => ({
                    ...accumulatedObject,
                    [attributeOutcome]: { counter: 0 }
                }), {}) : {}
            }),
                {}),
            matched: {},
            [notInWlOutcome]: {},
            not_determined: {},
            detections: this.#setCounterObject({}, AnalyticsInterfaceObject.detections, 0),
            totalAppearances: this.#setCounterObject({}, AnalyticsInterfaceObject.totalAppearances, 0)
        };
        this.analytics = analytics.reduce((accumulatedCamerasStatistics, statistics) => {
            if (typeof statistics === typeof {}) {
                const counters = statistics.counters ? statistics.counters.total_appearances : statistics.total_appearances;
                return Object.keys(counters).reduce((accumulatedCounter, statisticString) => {
                    accumulatedCounter.totalAppearances.counter += counters[statisticString];
                    const [parsedCounter, hasMatchOutcome] = this.#parseStringCounter(statisticString);
                    this.#setStatistics(parsedCounter, hasMatchOutcome, counters[statisticString], accumulatedCounter);
                    return accumulatedCounter;
                }, accumulatedCamerasStatistics);
            }
            return accumulatedCamerasStatistics;
        }, initializeCounterObject);
    }

    #parseStringCounter(string) {
        const [analyticsAttributes, matchOutcomeAttributes] = string.split("::");
        const [age_group, gender, mask_detect, liveness] = analyticsAttributes.split(".");
        const watchlists = matchOutcomeAttributes ? matchOutcomeAttributes.split("|") : [];
        const matchOutcome = watchlists.reduce((accumulatedMatchOutcome, watchlistString) => {
            const [watchlist, , match] = watchlistString ? watchlistString.split(".") : [];
            accumulatedMatchOutcome[match] = watchlist;
            return accumulatedMatchOutcome;
        }, {});
        return [{
            age_group,
            gender,
            mask_detect,
            liveness,
            ...matchOutcome
        }, Object.values(matchOutcome).length !== 0];
    }

    #setStatistics(parsedCounter, hasMatchOutcome, counter, accumulatedCounter) {
        !hasMatchOutcome && this.#setCounterObject(accumulatedCounter, AnalyticsInterfaceObject.detections, counter);
        Object.keys(parsedCounter).forEach(attribute => {
            if (attribute === RequiredFaceAttributesConfig.age_group) {
                let ageGroupAttribute = notDeterminedLabel;
                let gender = notDeterminedLabel;
                if (!this.uncertaintyAttributes.includes(parsedCounter.age_group)) {
                    ageGroupAttribute = parsedCounter.age_group;
                }
                if (!this.uncertaintyAttributes.includes(parsedCounter.gender)) {
                    gender = parsedCounter.gender;
                }
                this.#setCounterObject(accumulatedCounter.age_group, [ageGroupAttribute], counter);
                this.#setStatisticsObject(accumulatedCounter.age_group[ageGroupAttribute], [gender], counter);
            } else if (!this.uncertaintyAttributes.includes(parsedCounter[attribute])) {
                this.#setCounterObject(accumulatedCounter[attribute], [parsedCounter[attribute]], counter);
            }
        });
    }

    #setStatisticsObject(object, attribute, counter) {
        object[attribute] ? object[attribute] += counter : object[attribute] = counter;
    }

    #setCounterObject(object, attribute, counter) {
        return object[attribute] ? object[attribute].counter += counter : object[attribute] = { counter: counter };
    }
}

export default AnalyticsClass;