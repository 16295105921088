import { routesUrl } from "@/Components/Common/Layout/SideBar/Routes";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { editGenerativeInsight, generativeInsightsList, generativeInsightsSearch, removeGenerativeInsight, successfullyRemovedInsight } from "src/appConfig/Strings";
import { toastUtility } from "@/Logic/Hooks/useToast";
import { insightIdQueryParam } from "../CreateEditInsights/CreateEditInsight.model";
import { insightDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";

export const insightsContextsOptions = {
    [generativeInsightsList]: ({ id, navigate, clientsManager }) => ({
        [editGenerativeInsight]: () => {
            navigate(`${routesUrl.generativeInsightsList}?${insightIdQueryParam}=${id}`);
            dispatchToggleDialogEvent(insightDialogId);
        },
        [removeGenerativeInsight]: async () => {
            const response = await clientsManager.generativeInsightsClient.removeGenerativeInsight(id);
            if (response?.error) {
                toastUtility.showError(response.error);
            } else {
                toastUtility.showSuccess(successfullyRemovedInsight);
            }

            await clientsManager.generativeInsightsClient.listAllInsights();
        }
    }),
    [generativeInsightsSearch]: ({ id, clientsManager, removeRowHandler }) => {
        const options = {};
        options[removeGenerativeInsight] = () => clientsManager.historyGenerativeInsightClient.removeGenerativeInsight(id, removeRowHandler);
        return options;
    }
};