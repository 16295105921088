import { useCallback } from "react";

import { Multiselect } from "react-widgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import FilterContainer from "./FilterContainer";

export default function SelectFilter({
    data,
    getOptionsFunc,
    setFunction,
    header,
    allowEmpty = true,
    dataKey,
    ...props
}) {

    const handleChange = useCallback((selectedOptions, { lastValue }) => {
        if (!allowEmpty && selectedOptions.length === 0)
            setFunction(lastValue.map(option => option));
        else if (selectedOptions.at(-1)?.[dataKey] !== null) {
            const selectedValues = selectedOptions.reduce((accumulatedOptions, option) => {
                if (option[dataKey])
                    accumulatedOptions.push(option[dataKey]);
                return accumulatedOptions;
            }, []);
            setFunction(selectedValues);
        } else
            setFunction([null]);
    }, [allowEmpty, dataKey, setFunction]);

    return (
        <FilterContainer header={header}>
            <Multiselect
                data={getOptionsFunc()}
                value={data}
                onChange={handleChange}
                busy
                busySpinner={<FontAwesomeIcon icon={faCaretDown} />}
                dataKey={dataKey}
                {...props} />
        </FilterContainer>
    );
}
