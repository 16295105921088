"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AnalysisQuality = _interopRequireDefault(require("./AnalysisQuality"));
var _FeaturesMinFaceWidths = _interopRequireDefault(require("./FeaturesMinFaceWidths"));
var _FrameTimeoutSettings = _interopRequireDefault(require("./FrameTimeoutSettings"));
var _StreamConfig = _interopRequireDefault(require("./StreamConfig"));
var _WatchlistIdParameter = _interopRequireDefault(require("./WatchlistIdParameter"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Config model module.
 * @module model/Config
 * @version 0.32.2
 */
class Config {
  /**
   * Constructs a new <code>Config</code>.
   * configuration properties for AI analysis (e.g. face recognition) of this camera
   * @alias module:model/Config
   * @implements module:model/StreamConfig
   */
  constructor() {
    _StreamConfig.default.initialize(this);
    Config.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Config</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Config} obj Optional instance to populate.
   * @return {module:model/Config} The populated <code>Config</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Config();
      _StreamConfig.default.constructFromObject(data, obj);
      if (data.hasOwnProperty('face_recognition_threshold')) {
        obj['face_recognition_threshold'] = _ApiClient.default.convertToType(data['face_recognition_threshold'], 'Number');
      }
      if (data.hasOwnProperty('watchlists')) {
        obj['watchlists'] = _ApiClient.default.convertToType(data['watchlists'], [_WatchlistIdParameter.default]);
      }
      if (data.hasOwnProperty('analysis_quality')) {
        obj['analysis_quality'] = _ApiClient.default.convertToType(data['analysis_quality'], _AnalysisQuality.default);
      }
      if (data.hasOwnProperty('required_face_attributes')) {
        obj['required_face_attributes'] = _ApiClient.default.convertToType(data['required_face_attributes'], ['String']);
      }
      if (data.hasOwnProperty('timeout_settings')) {
        obj['timeout_settings'] = _FrameTimeoutSettings.default.constructFromObject(data['timeout_settings']);
      }
      if (data.hasOwnProperty('authorized_min_face_width')) {
        obj['authorized_min_face_width'] = _ApiClient.default.convertToType(data['authorized_min_face_width'], 'Number');
      }
      if (data.hasOwnProperty('features_min_face_widths')) {
        obj['features_min_face_widths'] = _FeaturesMinFaceWidths.default.constructFromObject(data['features_min_face_widths']);
      }
      if (data.hasOwnProperty('record_frames')) {
        obj['record_frames'] = _ApiClient.default.convertToType(data['record_frames'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Config</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Config</code>.
   */
  static validateJSON(data) {
    if (data['watchlists']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['watchlists'])) {
        throw new Error("Expected the field `watchlists` to be an array in the JSON data but got " + data['watchlists']);
      }
      // validate the optional field `watchlists` (array)
      for (const item of data['watchlists']) {
        _WatchlistIdParameter.default.validateJsonObject(item);
      }
      ;
    }
    // ensure the json data is a string
    if (data['analysis_quality'] && !(typeof data['analysis_quality'] === 'string' || data['analysis_quality'] instanceof String)) {
      throw new Error("Expected the field `analysis_quality` to be a primitive type in the JSON string but got " + data['analysis_quality']);
    }
    // validate the optional field `analysis_quality`
    if (data['analysis_quality']) {
      // data not null
      _AnalysisQuality.default.validateJSON(data['analysis_quality']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['required_face_attributes'])) {
      throw new Error("Expected the field `required_face_attributes` to be an array in the JSON data but got " + data['required_face_attributes']);
    }
    // validate the optional field `timeout_settings`
    if (data['timeout_settings']) {
      // data not null
      _FrameTimeoutSettings.default.validateJSON(data['timeout_settings']);
    }
    // validate the optional field `features_min_face_widths`
    if (data['features_min_face_widths']) {
      // data not null
      _FeaturesMinFaceWidths.default.validateJSON(data['features_min_face_widths']);
    }
    return true;
  }
}

/**
 * match threshold for faces appearing in this camera
 * @member {Number} face_recognition_threshold
 */
Config.prototype['face_recognition_threshold'] = undefined;

/**
 * POI watchlists that this camera's faces will be matched against
 * @member {Array.<module:model/WatchlistIdParameter>} watchlists
 */
Config.prototype['watchlists'] = undefined;

/**
 * frame sampling settings of this camera
 * @member {module:model/AnalysisQuality} analysis_quality
 */
Config.prototype['analysis_quality'] = undefined;

/**
 * list of face attributes that should be noted during analysis. Options ['mask_detect', 'age_group', 'gender', 'liveness']
 * @member {Array.<String>} required_face_attributes
 */
Config.prototype['required_face_attributes'] = undefined;

/**
 * @member {module:model/FrameTimeoutSettings} timeout_settings
 */
Config.prototype['timeout_settings'] = undefined;

/**
 * min face width to check if an appearance is authorized in a whitelist
 * @member {Number} authorized_min_face_width
 */
Config.prototype['authorized_min_face_width'] = undefined;

/**
 * @member {module:model/FeaturesMinFaceWidths} features_min_face_widths
 */
Config.prototype['features_min_face_widths'] = undefined;

/**
 * @member {Boolean} record_frames
 */
Config.prototype['record_frames'] = undefined;

// Implement StreamConfig interface:
/**
 * match threshold for faces appearing in this camera
 * @member {Number} face_recognition_threshold
 */
_StreamConfig.default.prototype['face_recognition_threshold'] = undefined;
/**
 * POI watchlists that this camera's faces will be matched against
 * @member {Array.<module:model/WatchlistIdParameter>} watchlists
 */
_StreamConfig.default.prototype['watchlists'] = undefined;
/**
 * frame sampling settings of this camera
 * @member {module:model/AnalysisQuality} analysis_quality
 */
_StreamConfig.default.prototype['analysis_quality'] = undefined;
/**
 * list of face attributes that should be noted during analysis. Options ['mask_detect', 'age_group', 'gender', 'liveness']
 * @member {Array.<String>} required_face_attributes
 */
_StreamConfig.default.prototype['required_face_attributes'] = undefined;
/**
 * @member {module:model/FrameTimeoutSettings} timeout_settings
 */
_StreamConfig.default.prototype['timeout_settings'] = undefined;
/**
 * min face width to check if an appearance is authorized in a whitelist
 * @member {Number} authorized_min_face_width
 */
_StreamConfig.default.prototype['authorized_min_face_width'] = undefined;
/**
 * @member {module:model/FeaturesMinFaceWidths} features_min_face_widths
 */
_StreamConfig.default.prototype['features_min_face_widths'] = undefined;
/**
 * @member {Boolean} record_frames
 */
_StreamConfig.default.prototype['record_frames'] = undefined;
var _default = exports.default = Config;