import { SavePoiFaceAgeOutcome, SavePoiFaceCrops, privacyProfilesNameLabel, requireGuardianConsent, savePoiDisplayImage, savePoiFaceGenderOutcome } from "src/appConfig/Strings";

const watchlistPrivacyFormFieldsNames = {
    watchlistPrivacyName: "name",
    savePoiDisplayImg: "save_poi_display_image",
    savePoiFaceCrop: "save_poi_face_crops",
    savePoiFaceGenderOutcome: "save_poi_face_gender_outcome",
    savePoiFaceAgeGroupOutcome: "save_poi_face_age_group_outcome",
    requireGuardianConsent: "require_guardian_consent",
    requireGuardianConsentAgeGroup: "require_guardian_consent_age_groups"
};

const watchlistPrivacyNameMapping = {
    [watchlistPrivacyFormFieldsNames.savePoiDisplayImg]: {
        name: savePoiDisplayImage
    },
    [watchlistPrivacyFormFieldsNames.savePoiFaceCrop]: {
        name: SavePoiFaceCrops
    },
    [watchlistPrivacyFormFieldsNames.savePoiFaceGenderOutcome]: {
        name: savePoiFaceGenderOutcome
    },
    [watchlistPrivacyFormFieldsNames.savePoiFaceAgeGroupOutcome]: {
        name: SavePoiFaceAgeOutcome
    },
    [watchlistPrivacyFormFieldsNames.requireGuardianConsent]: {
        name: requireGuardianConsent
    },
};

const WatchlistPrivacyFormModel = {
    [watchlistPrivacyFormFieldsNames.watchlistPrivacyName]: {
        name: watchlistPrivacyFormFieldsNames.privacyName,
        type: "text",
        placeholder: privacyProfilesNameLabel,
        required: true,
    },
    [watchlistPrivacyFormFieldsNames.requireGuardianConsentAgeGroup]: {
        name: watchlistPrivacyFormFieldsNames.requireGuardianConsentAgeGroup,
        textField: "name",
        dataKey: "group",
        required: true,
    }
};

export { watchlistPrivacyFormFieldsNames, WatchlistPrivacyFormModel, watchlistPrivacyNameMapping };