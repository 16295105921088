export const baseBadgeSx = {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
};

export const poiCardCenterBottomBadge = {
    '& .MuiBadge-badge': {
        ...baseBadgeSx,
        transform: 'translate(18px, 14px)',
    },
};

export const analyzeCardCenterBottomBadge = {
    '& .MuiBadge-badge': {
        ...baseBadgeSx,
        transform: 'translate(60px, 10px)',
    },
};