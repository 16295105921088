import { memo, useMemo } from "react";

import { connect } from "react-redux";
import { Alert } from "@mui/material";

import { analysisQualityHeader, analysisQualityWarn, cameraName, cameraWatchlistsHeader, createCameraLabel, thresholdLabel, videoName, videoWatchlistsHeader, watchlistsHeader } from "src/appConfig/Strings";
import { AnalysisOptions, CameraFormModel, cameraFormFieldsNames, capturingSourceMode } from "./CreateEditCamera.model";
import LabelWrapper from "@/Components/Common/Wrappers/LabelWrapper";
import RadioInput from "@/Components/Common/FormComponents/FormInputs/RadioInput";
import SliderWithInput from "@/Components/Common/FormComponents/FormInputs/SliderWithInput";
import AdvancedCreateCameraForm from "../AdvancedCreateCameraForm";
import CaptureSourceSubForm from "../CaptureSourceSubForm";
import BaseInput from "@/Components/Common/FormComponents/FormInputs/BaseInput";
import MultiSelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField/MultiSelectInputField";

function CreateEditCameraForm({ cameraID, formData, onFieldChange, handleSubmitForm, watchlists, isLiveAnalysisMode }) {
    const { cameraName: cameraNameModel, watchlists: watchlistsModel, analysisQuality, threshold } = cameraFormFieldsNames;

    const isCameraNameInputAvailable = useMemo(() => {
        const { mode, capture_address: captureAddress } = formData;

        return Boolean(cameraID) || (mode !== capturingSourceMode.file || captureAddress?.size === 1);
    }, [cameraID, formData]);

    return (
        <form id={createCameraLabel} className="modal-input-title flex flex-col" onSubmit={handleSubmitForm}>
            {isCameraNameInputAvailable &&
                <BaseInput
                    label={isLiveAnalysisMode ? cameraName : videoName}
                    model={CameraFormModel[cameraNameModel]}
                    value={formData[cameraNameModel]}
                    onChange={(event) => onFieldChange(cameraNameModel, event.target.value)}
                    dataTestId="camera-name-field"
                />}
            <CaptureSourceSubForm cameraID={cameraID} formData={formData} onFieldChange={onFieldChange} isLiveAnalysisMode={isLiveAnalysisMode} />
            <LabelWrapper label={analysisQualityHeader}>
                <RadioInput
                    model={CameraFormModel[analysisQuality]}
                    options={AnalysisOptions}
                    value={formData[analysisQuality]}
                    onChange={(event) => onFieldChange(analysisQuality, event.target.value)}
                    data-testid="analysis-quality-field"
                />
                <Alert className="text-sm" severity="info" >{analysisQualityWarn}</Alert>
            </LabelWrapper>
            <LabelWrapper label={isLiveAnalysisMode ? cameraWatchlistsHeader : videoWatchlistsHeader}>
                <MultiSelectInputField
                    label={watchlistsHeader}
                    model={CameraFormModel[watchlistsModel]}
                    options={Object.values(watchlists)}
                    value={formData[watchlistsModel]}
                    onChange={(value) => onFieldChange(watchlistsModel, value)}
                    dataTestId="camera-watchlists"
                />
            </LabelWrapper>
            <LabelWrapper label={thresholdLabel} >
                <SliderWithInput
                    model={CameraFormModel[threshold]}
                    value={formData[threshold]}
                    onChange={(value) => onFieldChange(threshold, value)}
                    dataTestId="threshold-field"
                />
            </LabelWrapper>
            <AdvancedCreateCameraForm
                formData={formData}
                onFieldChange={onFieldChange}
                isLiveAnalysisMode={isLiveAnalysisMode}
            />
        </form>
    );
}

const mapStateToProps = (state) => {
    const watchlists = state.WatchlistsStore;

    return { watchlists };
};

export default connect(mapStateToProps)(memo(CreateEditCameraForm));