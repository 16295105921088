import { connect } from "react-redux";

import LabelWrapper from "@/Components/Common/Wrappers/LabelWrapper";
import { completedJourneyOptions, personJourneyFormFieldsNames, PersonJourneyInsightFormModel } from "./PersonJourney.model";
import SelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField";
import MultiSelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField/MultiSelectInputField";
import { alertingAppearanceDurationJourneyInfo, alertingTimeFrameTxt, entryCameraLabel, existCamerasLabel, journeyType, journeyTypeTooltipInfo } from "src/appConfig/Strings";
import { DurationSelector } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector";
import { daysHoursMinSecTimeMapping } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector.model";

const { completedJourney, journeyStartCamera, journeyEndCamera, alertingJourneyDuration } = personJourneyFormFieldsNames;

function PersonJourneyInsight({ insightId, formData, onFieldChange, camerasFromStore }) {
    return (
        <div className="flex flex-col gap-2">
            <LabelWrapper label={journeyType} tooltipTxt={journeyTypeTooltipInfo} >
                <SelectInputField
                    label={journeyType}
                    model={PersonJourneyInsightFormModel[completedJourney]}
                    value={formData[completedJourney]}
                    onChange={(event) => onFieldChange(completedJourney, event.target.value)}
                    options={completedJourneyOptions}
                    disabled={Boolean(insightId)}
                    dataTestId="insight-journey-type"
                />
            </LabelWrapper>
            <SelectInputField
                label={entryCameraLabel}
                model={PersonJourneyInsightFormModel[journeyStartCamera]}
                value={formData[journeyStartCamera]}
                onChange={(event) => onFieldChange(journeyStartCamera, event.target.value)}
                options={Object.values(camerasFromStore)}
                dataTestId="insight-start-camera"
            />
            <MultiSelectInputField
                label={existCamerasLabel}
                model={PersonJourneyInsightFormModel[journeyEndCamera]}
                options={Object.values(camerasFromStore)}
                value={formData[journeyEndCamera]}
                onChange={(value) => onFieldChange(journeyEndCamera, value)}
                dataTestId="insight-end-camera"
            />
            <LabelWrapper label={alertingTimeFrameTxt} tooltipTxt={alertingAppearanceDurationJourneyInfo} >
                <DurationSelector
                    model={PersonJourneyInsightFormModel[alertingJourneyDuration]}
                    value={formData[alertingJourneyDuration]}
                    onChange={(value) => onFieldChange(alertingJourneyDuration, value)}
                    timeUnitsMap={daysHoursMinSecTimeMapping}
                />
            </LabelWrapper>
        </div>
    );
}

const mapStateToProps = (state) => {
    const camerasFromStore = state.CamerasStore.cameras;

    return { camerasFromStore };
};

export default connect(mapStateToProps, null)(PersonJourneyInsight);