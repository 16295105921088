export const EventsWorkerInterface = {
    initialize: "initialize",
    startListener: "startListener",
    setSseFilters: "setSseFilters",
    stopListener: "stopListener",
    resetSession: "resetSession"
};

export const IndexDBHandlerInterface = {
    addFile: "addFile",
    getImagesByPage: "getImagesByPage",
    getImage: "getImage",
    destroyFilesStore: "destroyFilesStore",
    addCase: "addCase",
    getCase: "getCase",
    updateCase: "updateCase",
    deleteCase: "deleteCase",
    getAllCasesIdentifiers: "getAllCasesIdentifiers",
    getAllGeneralSettings: "getAllGeneralSettings",
    updateGeneralSetting: "updateGeneralSetting"
};

export const BaseWebWorkerInterface = {
    init: "init",
    run: "run",
    setSuspendSignal: "setSuspendSignal",
};

export const ManagingWebWorkerInterface = {
    ...BaseWebWorkerInterface,
    onTerminated: "onTerminated",
    updateProgress: "updateProgress",
    updateTime: "updateTime",
    setImages: "setImages",
    splitImagesIntoGroups: "splitImagesIntoGroups",
    createNewWorkingChild: "createNewWorkingChild",
    childWorkerFinishedWork: "childWorkerFinishedWork",
    finishedUpload: "finishedUpload"
};

export const BaseWorkingChildInterface = {
    ...BaseWebWorkerInterface,
    calculatedBatchPositions: "calculatedBatchPositions",
    loadImage: "loadImage"
};

export const MainThreadMessages = {
    progressUpdate: "progressUpdate",
    finishedUpload: "finishedUpload",
    updateTime: "updateTime",
    storageWorker: "storageWorker",
    dispatchFileToRedux: "dispatchFileToRedux",
    dispatchFilesToRedux: "dispatchFilesToRedux",
    dispatchGetCaseToRedux: "dispatchGetCaseToRedux",
    dispatchCasesKeysToRedux: "dispatchCasesKeysToRedux",
    dispatchDeleteCaseToRedux: "dispatchDeleteCaseToRedux",
    dispatchCreateCaseToRedux: "dispatchCreateCaseToRedux",
    dispatchSettingsToRedux: "dispatchSettingsToRedux",
    shouldShowWorkersNumberAlert: "shouldShowWorkersNumberAlert"
};

export const cameraStarterInterface = {
    startCamera: "startCamera",
    handleStatusEvents: "handleStatusEvents",
    handleResponse: "handleResponse",
    errorStarting: "errorStarting",
    cameraStarted: "cameraStarted"
};

export const auditWorkerInterface = {
    downloadCsv: "downloadCsv"
};

export const importPoiInterface = {
    onErrResponse: "onErrResponse",
    childInitializedSuccessfully: "childInitializedSuccessfully"
};