"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetPOIPrivacyProfileResponse = _interopRequireDefault(require("../model/GetPOIPrivacyProfileResponse"));
var _ListPOIPrivacyProfilesResponse = _interopRequireDefault(require("../model/ListPOIPrivacyProfilesResponse"));
var _RemoveProfileResponse = _interopRequireDefault(require("../model/RemoveProfileResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Controller API
 * ## Control & Management - Status Endpoint allows the user of the API to see the very basic data of the deployment - and notably, the deployment_id - even if the user has no credentials or valid license - License Endpoint allows an authenticated user to view current license usage  ## System Settings **Privacy settings** - 'Privacy' is a global system mode that allows a user of the system to restrict the biometric data that is saved and/or outputted by SC. - The global system mode, as well as the specific privacy settings that control which biometric data the system is permitted to save and output, are configured using the privacy settings API of Controller Service.  **SearchFace settings** - Settings that apply to the SearchFace endpoints (in POI DB & History DB)  **Analysis settings** - System-wide settings related to the analysis of streams  ## Data Retention Perform actions & retrieve information regarding the data saved by the system - how many records exist, what is the max capcity, enable automatic clearing etc. 
 *
 * The version of the OpenAPI document: 0.36.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* POIPrivacyProfiles service.
* @module api/POIPrivacyProfilesApi
* @version 0.36.2
*/
class POIPrivacyProfilesApi {
  /**
  * Constructs a new POIPrivacyProfilesApi. 
  * @alias module:api/POIPrivacyProfilesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addPOIPrivacyProfile operation.
   * @callback module:api/POIPrivacyProfilesApi~addPOIPrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetPOIPrivacyProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Create Poi Privacy Profile
   * Create a new POI Privacy profile. Note that the name must be unique (case-insensitive)
   * @param {Object} body 
   * @param {module:api/POIPrivacyProfilesApi~addPOIPrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetPOIPrivacyProfileResponse}
   */
  addPOIPrivacyProfile(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling addPOIPrivacyProfile");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetPOIPrivacyProfileResponse.default;
    return this.apiClient.callApi('/privacy/poi/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getDefaultPOIPrivacyProfile operation.
   * @callback module:api/POIPrivacyProfilesApi~getDefaultPOIPrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetPOIPrivacyProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Default Poi Privacy Profile
   * Get the current default POI profile id
   * @param {module:api/POIPrivacyProfilesApi~getDefaultPOIPrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetPOIPrivacyProfileResponse}
   */
  getDefaultPOIPrivacyProfile(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetPOIPrivacyProfileResponse.default;
    return this.apiClient.callApi('/privacy/poi/default/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getPOIPrivacyProfile operation.
   * @callback module:api/POIPrivacyProfilesApi~getPOIPrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetPOIPrivacyProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Poi Privacy Profile
   * Get Specific POI Privacy Profile
   * @param {Object} profileId 
   * @param {module:api/POIPrivacyProfilesApi~getPOIPrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetPOIPrivacyProfileResponse}
   */
  getPOIPrivacyProfile(profileId, callback) {
    let postBody = null;
    // verify the required parameter 'profileId' is set
    if (profileId === undefined || profileId === null) {
      throw new Error("Missing the required parameter 'profileId' when calling getPOIPrivacyProfile");
    }
    let pathParams = {
      'profile_id': profileId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetPOIPrivacyProfileResponse.default;
    return this.apiClient.callApi('/privacy/poi/{profile_id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the listAllPOIPrivacyProfiles operation.
   * @callback module:api/POIPrivacyProfilesApi~listAllPOIPrivacyProfilesCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ListPOIPrivacyProfilesResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List Poi Privacy Profiles
   * Get all existing POI Privacy Profiles
   * @param {module:api/POIPrivacyProfilesApi~listAllPOIPrivacyProfilesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ListPOIPrivacyProfilesResponse}
   */
  listAllPOIPrivacyProfiles(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ListPOIPrivacyProfilesResponse.default;
    return this.apiClient.callApi('/privacy/poi/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removePOIPrivacyProfile operation.
   * @callback module:api/POIPrivacyProfilesApi~removePOIPrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/RemoveProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Poi Privacy Profile
   * Remove Specific POI Privacy Profile. The default profile and the built-in profiles cannot be removed.
   * @param {Object} profileId 
   * @param {module:api/POIPrivacyProfilesApi~removePOIPrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/RemoveProfileResponse}
   */
  removePOIPrivacyProfile(profileId, callback) {
    let postBody = null;
    // verify the required parameter 'profileId' is set
    if (profileId === undefined || profileId === null) {
      throw new Error("Missing the required parameter 'profileId' when calling removePOIPrivacyProfile");
    }
    let pathParams = {
      'profile_id': profileId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _RemoveProfileResponse.default;
    return this.apiClient.callApi('/privacy/poi/{profile_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setDefaultPOIPrivacyProfile operation.
   * @callback module:api/POIPrivacyProfilesApi~setDefaultPOIPrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetPOIPrivacyProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set Default Poi Privacy Profile
   * Set the default POI profile id to be the profile identified by the input id.
   * @param {Object} body 
   * @param {module:api/POIPrivacyProfilesApi~setDefaultPOIPrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetPOIPrivacyProfileResponse}
   */
  setDefaultPOIPrivacyProfile(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setDefaultPOIPrivacyProfile");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetPOIPrivacyProfileResponse.default;
    return this.apiClient.callApi('/privacy/poi/default/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updatePOIPrivacyProfile operation.
   * @callback module:api/POIPrivacyProfilesApi~updatePOIPrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetPOIPrivacyProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update Poi Privacy Profile
   * Update Specific POI Privacy Profile. The built-in profiles cannot be updated.
   * @param {Object} profileId 
   * @param {Object} body 
   * @param {module:api/POIPrivacyProfilesApi~updatePOIPrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetPOIPrivacyProfileResponse}
   */
  updatePOIPrivacyProfile(profileId, body, callback) {
    let postBody = body;
    // verify the required parameter 'profileId' is set
    if (profileId === undefined || profileId === null) {
      throw new Error("Missing the required parameter 'profileId' when calling updatePOIPrivacyProfile");
    }
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updatePOIPrivacyProfile");
    }
    let pathParams = {
      'profile_id': profileId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetPOIPrivacyProfileResponse.default;
    return this.apiClient.callApi('/privacy/poi/{profile_id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = POIPrivacyProfilesApi;