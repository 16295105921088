import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    frames: {},
    appearanceSequence: []
};

const framesSlice = createSlice({
    name: 'framesStore',
    initialState,
    reducers: {
        clearFrames: (state, action) => {
            return initialState;
        },
        setFrame: (state, action) => {
            const { frames, appearanceSequence } = action.payload;
            state.frames = frames;
            state.appearanceSequence = appearanceSequence;

            return state;
        }
    },
});

export const { clearFrames, setFrame } = framesSlice.actions;

export default framesSlice.reducer;