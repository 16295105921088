import { caughtUp, noNewNotifications } from "../../../../../appConfig/Strings";

function NoNewMessages() {

    return (
        <div className="no-results notificationTabElement pb-0">
            <div className="sa-icon sa-success animate mt-0">
                <span className="sa-line sa-tip animateSuccessTip" />
                <span className="sa-line sa-long animateSuccessLong" />
                <div className="sa-placeholder" />
                <div className="sa-fix" />
            </div>
            <div className="results-subtitle">{caughtUp}</div>
            <div className="results-title">{`${noNewNotifications}!`}</div>
        </div>
    );
}

export default NoNewMessages;