import { memo, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { InputGroup } from "reactstrap";

import FilterContainer from "./FilterContainer";
import { timeFilterHeader } from "src/appConfig/Strings";
import { dateTimeType } from "@/Logic/Parsing/timeParsing";

function TimeFilter({ data, setFunction, format = dateTimeType, ...props }) {
    const handleChange = useCallback((type, newValue) => {
        setFunction && setFunction({
            ...data,
            [type]: newValue
        });
    }, [data, setFunction]);

    return (
        <FilterContainer header={timeFilterHeader}>
            <DateAndTimeInputs type="start" timeString={data?.start} setFunction={handleChange} format={format} {...props} />
            <DateAndTimeInputs type="end" timeString={data?.end} setFunction={handleChange} format={format} {...props} />
        </FilterContainer>
    );
}

function DateAndTimeInputs({ type, timeString, setFunction, format }) {
    return (
        <InputGroup className="my-2 flex">
            <div className="input-group-text col-4">
                <FontAwesomeIcon icon={faCalendarAlt} />
                <div className="px-3 ">
                    {type.toUpperCase()}
                </div>
            </div>
            <input
                className="col form-control"
                type={format}
                defaultValue={timeString}
                onChange={(event) => setFunction(type, event.target.value)}
                step="1"
            />

        </InputGroup>
    );
}

export default memo(TimeFilter);
