import { gridClasses } from "@mui/x-data-grid-pro";

export const baseDatagridStyle = {
    boxShadow: 2,
    border: 0,
    color: "white",
    borderColor: "transparent",
    background: 'rgba(8, 14, 24, 0.5)',
    '& .MuiDataGrid-toolbarContainer': {
        '& .MuiButtonBase-root:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: 'white',
        },
        '& .MuiInputBase-root': {
            color: 'white',
            '&:before': {
                borderBottomColor: 'white',
            },
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
        '& .MuiButton-root': {
            color: 'white',
        },
    },
    "& .MuiDataGrid-detailPanel": {
        backgroundColor: "transparent",
        overflow: 'visible',
        "& .MuiDataGrid-pagination": {
            color: "white",
        },
    },
    "& .MuiTablePagination-toolbar": {
        color: "white"
    },
    "& .MuiIconButton-root": {
        color: "white"
    },
    "& .MuiTablePagination-actions .Mui-disabled": {
        color: "grey",
    },
    [`& .${gridClasses.detailPanel}`]: {
        backgroundColor: "transparent"
    },
    "& .MuiDataGrid-cell": {
        borderColor: "#819CB4",
        '&:focus': {
            outline: 'none',
        },
        '&.Mui-selected': {
            backgroundColor: 'inherit',
        },
    },
    "& .MuiDataGrid-withBorderColor": {
        borderColor: "#819CB4",
    },
    "& .MuiDataGrid-columnHeaders": {
        minHeight: "50px !important",
        maxHeight: "50px !important",
    },
    "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
        visibility: "visible",
        width: "auto"
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
        minHeight: "50px !important"
    },
    "& .MuiDataGrid-pinnedRows": {
        backgroundColor: 'rgba(68,107,143)',
        '& .MuiDataGrid-cell': {
            color: 'white',
            borderColor: '#819CB4',
        },
    },
    "& .MuiDataGrid-row.Mui-selected": {
        backgroundColor: "#4682B44D !important",
        color: "white",
        "&:hover": {
            backgroundColor: "#4682B44D"
        },
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
        borderTop: "1px solid #4682B4",
        borderBottom: "1px solid #4682B4"
    },
};

export const alertCenterDataGridStyle = {
    '& .MuiDataGrid-columnHeaders': {
        display: 'none'
    },
    '& .MuiDataGrid-row': {
        borderTop: '1px solid #e0e0e0',
    },
    "& .MuiDataGrid-cell": {
        borderColor: 'transparent',
        '&:focus': {
            outline: 'none',
        },
        '&:focus-within': {
            outline: 'none',
            border: 'none',
        },
        '&.Mui-selected': {
            backgroundColor: 'inherit',
        },
    },
};