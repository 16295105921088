import { memo } from "react";

import Card from '@mui/material/Card';

function StatusMsgCard({ content, children, className }) {
    return (
        <Card className={`h-no-rows-overlay flex justify-center border-dusty-blue bg-transparent p-5 text-base text-white ${className}`} variant="outlined">
            <div>
                {content}
            </div>
            {children}
        </Card>
    );
}

export default memo(StatusMsgCard);