import { Badge, IconButton } from "@mui/material";

import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';

function NotificationsBadgeIcon({ unreadCount, toggleNotificationCenter }) {
    return (
        <IconButton onClick={toggleNotificationCenter}>
            <Badge badgeContent={unreadCount} color="primary">
                <LibraryBooksOutlinedIcon className="text-[21px] text-white/80" />
            </Badge>
        </IconButton>
    );
}

export default NotificationsBadgeIcon;