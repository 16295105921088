import { useCallback, useContext } from "react";

import { FormControlLabel, FormGroup, IconButton, Switch } from "@mui/material";
import { connect } from "react-redux";

import { ReactComponent as IconClose } from 'src/assets/Icons/Modal/icon_close.svg';

import { notificationCenterHeader, showUnreadOnlyLabel, snoozeNotifications } from "src/appConfig/Strings";
import ApiContext from "@/Logic/Context/apiContext";
import { IndexDBHandlerInterface } from "@/Logic/WebWorkers/WorkersInterfaces";
import { generalSettingsKeys } from "@/Logic/WebWorkers/IndexDBHandler/IndexDBHandler.model";

function Header({ toggleFilter, toggleNotificationCenter, showUnreadOnly, isSnoozed }) {
    const { clientsManager } = useContext(ApiContext);

    const toggleSnoozeHandler = useCallback(() => {
        clientsManager.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.updateGeneralSetting, [generalSettingsKeys.snoozeToasts, !isSnoozed]]);
    }, [clientsManager.workersClient.storageWorker, isSnoozed]);

    return (
        <div className="relative flex flex-col items-center justify-between rounded-t-lg bg-[#263F63] p-2">
            <IconButton className="absolute right-0 top-0" onClick={toggleNotificationCenter}>
                <IconClose className="stroke-white" />
            </IconButton>
            <h5 className="text-lg text-white">
                {notificationCenterHeader}
            </h5>
            <FormGroup className="unread-toggle flex flex-row gap-5 text-white">
                <FormControlLabel control={<Switch onChange={toggleFilter} checked={showUnreadOnly} />} label={showUnreadOnlyLabel} />
                <FormControlLabel control={<Switch onChange={toggleSnoozeHandler} checked={isSnoozed || false} />} label={snoozeNotifications} />
            </FormGroup>
        </div>
    );
}

const mapStateToProps = (state) => {
    const isSnoozed = state.ApiActionsStore?.generalSettings[generalSettingsKeys.snoozeToasts];

    return { isSnoozed };
};

export default connect(mapStateToProps, null)(Header);