import { ApiClient, GenerativeInsightsApi } from "smart_cameras_generative_insights_api";

import { isEmpty } from "lodash";

import BaseAPIClient from "./BaseAPIClient";
import { insightsRoute } from "../Infrastructure/networkConf";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { analysisMode, camerasFilter, endTime, faceAttributes, generativeInsightsFilter, minAppearancesLabel, minInsightIntervalTime, startTime } from "src/appConfig/Strings";
import { queryDBOptions } from "./HistoryClient";
import { areOptionsSelected } from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";
import { filtersFormFieldsNames } from "@/Components/Events/EventsFeed/EventsFilters.model";
import { insightSearchFields } from "@/Components/GenerativeInsights/InsightsSearch/InsightsSearch.model";

const { facesAttrAgeFilter, facesAttrGenderFilter } = filtersFormFieldsNames;
const { insightIds, insightType } = insightSearchFields;

export default class HistoryGenerativeInsightClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(insightsRoute, clientInstance);
        this.generativeInsightApi = new GenerativeInsightsApi();
    }

    async searchGenerativeInsights(clientsManager, filters, pageId = "") {
        const searchRequestParams = this.#parseSearchGenerativeInsights(filters);
        const analyticsResponse = await this.getInsightsAnalytics(searchRequestParams);

        return this.apiCall("Search Generative Insights",
            (callback) => this.generativeInsightApi.getGenerativeInsights(searchRequestParams, {
                afterId: pageId,
                count: pageId === ""
            }, callback),
            async (_, __, response) => {
                const data = await this.serializeApiData(response.body.data.matches, (record) => record.insight_record_id);
                if (Object.keys(data).length) {
                    const appearanceIds = Object.values(data).map((record) => record.curr_appearance);
                    const currAppearances = await clientsManager.historyClient.searchAppearances({ appearanceIds }, "", false, queryDBOptions.liveAndHistory);
                    for (const generativeInsight in data) {
                        const appearanceId = data[generativeInsight].curr_appearance;
                        data[generativeInsight] = {
                            ...data[generativeInsight],
                            curr_appearance: { ...currAppearances?.data?.records?.[appearanceId] },
                            currAppearanceId: appearanceId
                        };
                    }
                }
                return parseResponseForUI(response.error, response, {
                    ...(response.body.data.matched_entries && { counters: response.body.data.matched_entries }),
                    records: data,
                    analytics: analyticsResponse?.data,
                    query: searchRequestParams
                });
            }
        );
    }

    #parseSearchGenerativeInsights(filters) {
        const {
            [generativeInsightsFilter]: { [insightIds]: insightIdsData, [insightType]: insightTypeData },
            [camerasFilter]: { radioValue: camerasRadioValue, selectedOptions: camerasSelectedOptions },
            [startTime]: startTimeVal,
            [endTime]: endTimeVal,
            [faceAttributes]: { [facesAttrAgeFilter]: ageOutcomes, [facesAttrGenderFilter]: genderOutcomes },
            [minInsightIntervalTime]: minIntervalTime,
            [minAppearancesLabel]: minNumOfAppearances,
            [analysisMode]: analysisModeValue
        } = filters;
        const { radioValue: ageRadioValue, selectedOptions: ageSelectedOptions } = ageOutcomes;
        const { radioValue: genderRadioValue, selectedOptions: genderSelectedOptions } = genderOutcomes;
        const { radioValue: insightIdsRadioValue, selectedOptions: insightIdsSelectedOptions } = insightIdsData;
        const { radioValue: insightTypeRadioValue, selectedOption: insightTypeSelectedOption } = insightTypeData;

        const insightIdsSelected = areOptionsSelected(insightIdsSelectedOptions, insightIdsRadioValue);
        return {
            ...(insightIdsSelected && { insight_ids: Array.from(insightIdsSelectedOptions) }),
            ...(!insightIdsSelected && areOptionsSelected(insightTypeSelectedOption, insightTypeRadioValue) && { insight_type: insightTypeSelectedOption }),
            ...(areOptionsSelected(camerasSelectedOptions, camerasRadioValue) && { camera_ids: Array.from(camerasSelectedOptions) }),
            ...(startTimeVal && { start_time: startTimeVal.unix() }),
            ...(endTimeVal && { end_time: endTimeVal.unix() }),
            ...(areOptionsSelected(ageSelectedOptions, ageRadioValue) && { age_group_outcomes: Array.from(ageSelectedOptions) }),
            ...(areOptionsSelected(genderSelectedOptions, genderRadioValue) && { gender_outcomes: Array.from(genderSelectedOptions) }),
            ...(minIntervalTime && { min_insight_time_interval: minIntervalTime }),
            ...(minNumOfAppearances && { min_number_of_appearances: minNumOfAppearances }),
            analysis_mode: analysisModeValue
        };
    }

    async removeGenerativeInsight(insightId, removeRowHandler) {
        const insightsQuery = {
            ...(insightId ? { record_ids: [insightId] } : {}),
        };

        return await this.callRemoveGenerativeInsights(insightsQuery, "Remove Generative Insight", () => removeRowHandler(insightId));
    }

    async removeGenerativeInsights(query, firstRecordStartTime) {
        const insightsQuery = {
            ...query,
            ...{ end_time: query.end_time || firstRecordStartTime }
        };

        return await this.callRemoveGenerativeInsights(insightsQuery, "Remove Generative Insights By Query");
    }

    async callRemoveGenerativeInsights(removeQuery, msg, successHandler) {
        return await this.apiCall("Remove Generative Insight",
            (callback) => this.generativeInsightApi.clearGenerativeInsightRecords(removeQuery, callback),
            (err, data, response) => successHandler ? successHandler() : parseResponseForUI(err, data, response));
    }

    async getInsightsAnalytics(parsedFilters) {
        if (isEmpty(parsedFilters.insight_ids) && !parsedFilters.insight_type) {
            return;
        }

        return await this.apiCall("Get Generative Insights Analytics",
            (callback) => this.generativeInsightApi.getGenerativeInsightsStatistics(parsedFilters, callback),
            (_, data,) => data
        );
    }
}