export const usersRoute = "users_service";
export const controllerRoute = "control_service";
export const insightsRoute = "generative_insights_service";
export const camerasRoute = "cameras_service";
export const eventsRoute = "events_service";
export const poiDbRoute = "poi_service";
export const historyRoute = "history_service";
export const webDockerRoute = "";
export const framesStorageRoute = "frames_service";

const devEnvironment = "development";
export function getHostAddress() {
    const { hostname, port } = window.location;
    return port && process.env.NODE_ENV !== devEnvironment ? `${hostname}:${port}` : hostname;
}