import { useMemo } from "react";

import { startCase, tail, values } from "lodash";

import store from "src/appConfig/configureStore";
import { alerts } from "src/appConfig/Strings";
import { alertsTable, eventTable, GenerateToolbar } from "@/Components/Common/MUITables/TablesLogic";
import BaseDataGrid from "@/Components/Common/MUITables/BaseDataGrid/BaseDataGrid";
import { alertsColumnsHeaders, AlertsModel } from "./AlertsTable.model";
import ClearAlertedEvents from "./ClearAlertedEvents";
import AcknowledgeAll from "./AcknowledgeAll";
import { alertCenterDataGridStyle } from "../../Styles/DataGridSx";

function AlertsTable({ alertedEvents }) {
    const { isLiveAnalysisMode } = store.getState().ApiActionsStore;
    const alertedEventsModel = useMemo(() => AlertsModel(Object.values(alertedEvents), alertsTable, isLiveAnalysisMode), [alertedEvents, isLiveAnalysisMode]);

    const slots = useMemo(() => ({
        toolbar: () => {
            const currentAlertedEvents = store.getState().EventsStore.alertedEvents;

            const AcknowledgeAllBtn = (
                <div className="ml-1.5">
                    <AcknowledgeAll />
                </div>
            );

            return (
                <GenerateToolbar customBtn={AcknowledgeAllBtn} shouldShowFilters={false} tableCategory={eventTable} tableName={startCase(alerts)} columnsHeaders={tail(alertsColumnsHeaders(isLiveAnalysisMode))} rows={values(currentAlertedEvents)} >
                    <ClearAlertedEvents />
                </GenerateToolbar >
            );
        }
    }), []);

    return (
        <div className="flex h-full flex-col overflow-auto">
            <BaseDataGrid
                sx={alertCenterDataGridStyle}
                className="min-h-no-rows-overlay"
                {...alertedEventsModel}
                slots={slots}
                hideFooter
                slotProps={{ className: "!text-black" }}
                disableRowSelectionOnClick
            />
        </div>
    );
}

export default AlertsTable;