"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _BodyAddPermissionsToRoleAuthRolesRoleIdAddPost = _interopRequireDefault(require("../model/BodyAddPermissionsToRoleAuthRolesRoleIdAddPost"));
var _BodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost = _interopRequireDefault(require("../model/BodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost"));
var _DeletedRoleResponse = _interopRequireDefault(require("../model/DeletedRoleResponse"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetPermissionsResponse = _interopRequireDefault(require("../model/GetPermissionsResponse"));
var _GetRoleResponse = _interopRequireDefault(require("../model/GetRoleResponse"));
var _GetRolesResponse = _interopRequireDefault(require("../model/GetRolesResponse"));
var _RoleParameters = _interopRequireDefault(require("../model/RoleParameters"));
var _RoleUpdateParameters = _interopRequireDefault(require("../model/RoleUpdateParameters"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* RolesManagement service.
* @module api/RolesManagementApi
* @version 0.35.0
*/
class RolesManagementApi {
  /**
  * Constructs a new RolesManagementApi. 
  * @alias module:api/RolesManagementApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addRole operation.
   * @callback module:api/RolesManagementApi~addRoleCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add Role
   * Create new User Role
   * @param {module:model/RoleParameters} roleParameters 
   * @param {module:api/RolesManagementApi~addRoleCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetRoleResponse}
   */
  addRole(roleParameters, callback) {
    let postBody = roleParameters;
    // verify the required parameter 'roleParameters' is set
    if (roleParameters === undefined || roleParameters === null) {
      throw new Error("Missing the required parameter 'roleParameters' when calling addRole");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the addRolePermission operation.
   * @callback module:api/RolesManagementApi~addRolePermissionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add Permissions To Role
   * With this endpoint, a role can be updated to be permitted an additional endpoints. Note that if any users that are assigned this role are logged in, they will be logged out.
   * @param {Object} roleId 
   * @param {module:model/BodyAddPermissionsToRoleAuthRolesRoleIdAddPost} bodyAddPermissionsToRoleAuthRolesRoleIdAddPost 
   * @param {module:api/RolesManagementApi~addRolePermissionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetRoleResponse}
   */
  addRolePermission(roleId, bodyAddPermissionsToRoleAuthRolesRoleIdAddPost, callback) {
    let postBody = bodyAddPermissionsToRoleAuthRolesRoleIdAddPost;
    // verify the required parameter 'roleId' is set
    if (roleId === undefined || roleId === null) {
      throw new Error("Missing the required parameter 'roleId' when calling addRolePermission");
    }
    // verify the required parameter 'bodyAddPermissionsToRoleAuthRolesRoleIdAddPost' is set
    if (bodyAddPermissionsToRoleAuthRolesRoleIdAddPost === undefined || bodyAddPermissionsToRoleAuthRolesRoleIdAddPost === null) {
      throw new Error("Missing the required parameter 'bodyAddPermissionsToRoleAuthRolesRoleIdAddPost' when calling addRolePermission");
    }
    let pathParams = {
      'role_id': roleId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/{role_id}/add/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the addRolePermission_0 operation.
   * @callback module:api/RolesManagementApi~addRolePermission_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/GetRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add Permissions To Role
   * With this endpoint, a role can be updated to be permitted an additional endpoints. Note that if any users that are assigned this role are logged in, they will be logged out.
   * @param {Object} roleId 
   * @param {module:model/BodyAddPermissionsToRoleAuthRolesRoleIdAddPost} bodyAddPermissionsToRoleAuthRolesRoleIdAddPost 
   * @param {module:api/RolesManagementApi~addRolePermission_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetRoleResponse}
   */
  addRolePermission_0(roleId, bodyAddPermissionsToRoleAuthRolesRoleIdAddPost, callback) {
    let postBody = bodyAddPermissionsToRoleAuthRolesRoleIdAddPost;
    // verify the required parameter 'roleId' is set
    if (roleId === undefined || roleId === null) {
      throw new Error("Missing the required parameter 'roleId' when calling addRolePermission_0");
    }
    // verify the required parameter 'bodyAddPermissionsToRoleAuthRolesRoleIdAddPost' is set
    if (bodyAddPermissionsToRoleAuthRolesRoleIdAddPost === undefined || bodyAddPermissionsToRoleAuthRolesRoleIdAddPost === null) {
      throw new Error("Missing the required parameter 'bodyAddPermissionsToRoleAuthRolesRoleIdAddPost' when calling addRolePermission_0");
    }
    let pathParams = {
      'role_id': roleId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/{role_id}/add/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the deleteRole operation.
   * @callback module:api/RolesManagementApi~deleteRoleCallback
   * @param {String} error Error message, if any.
   * @param {module:model/DeletedRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete Role
   * With this endpoint, a role can be removed. Note that if any users that are assigned this role are logged in, they will be logged out.
   * @param {Object} roleId 
   * @param {module:api/RolesManagementApi~deleteRoleCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/DeletedRoleResponse}
   */
  deleteRole(roleId, callback) {
    let postBody = null;
    // verify the required parameter 'roleId' is set
    if (roleId === undefined || roleId === null) {
      throw new Error("Missing the required parameter 'roleId' when calling deleteRole");
    }
    let pathParams = {
      'role_id': roleId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DeletedRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/{role_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the deleteRole_0 operation.
   * @callback module:api/RolesManagementApi~deleteRole_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/DeletedRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete Role
   * With this endpoint, a role can be removed. Note that if any users that are assigned this role are logged in, they will be logged out.
   * @param {Object} roleId 
   * @param {module:api/RolesManagementApi~deleteRole_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/DeletedRoleResponse}
   */
  deleteRole_0(roleId, callback) {
    let postBody = null;
    // verify the required parameter 'roleId' is set
    if (roleId === undefined || roleId === null) {
      throw new Error("Missing the required parameter 'roleId' when calling deleteRole_0");
    }
    let pathParams = {
      'role_id': roleId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DeletedRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/{role_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getRole operation.
   * @callback module:api/RolesManagementApi~getRoleCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Role
   * Get Role
   * @param {Object} roleId 
   * @param {module:api/RolesManagementApi~getRoleCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetRoleResponse}
   */
  getRole(roleId, callback) {
    let postBody = null;
    // verify the required parameter 'roleId' is set
    if (roleId === undefined || roleId === null) {
      throw new Error("Missing the required parameter 'roleId' when calling getRole");
    }
    let pathParams = {
      'role_id': roleId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/{role_id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getRole_0 operation.
   * @callback module:api/RolesManagementApi~getRole_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/GetRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Role
   * Get Role
   * @param {Object} roleId 
   * @param {module:api/RolesManagementApi~getRole_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetRoleResponse}
   */
  getRole_0(roleId, callback) {
    let postBody = null;
    // verify the required parameter 'roleId' is set
    if (roleId === undefined || roleId === null) {
      throw new Error("Missing the required parameter 'roleId' when calling getRole_0");
    }
    let pathParams = {
      'role_id': roleId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/{role_id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the listAllPermissions operation.
   * @callback module:api/RolesManagementApi~listAllPermissionsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetPermissionsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List All Permissions
   * List all Permissions in the system
   * @param {module:api/RolesManagementApi~listAllPermissionsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetPermissionsResponse}
   */
  listAllPermissions(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetPermissionsResponse.default;
    return this.apiClient.callApi('/auth/roles/permissions/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the listAllRoles operation.
   * @callback module:api/RolesManagementApi~listAllRolesCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetRolesResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List All Roles
   * List all roles
   * @param {module:api/RolesManagementApi~listAllRolesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetRolesResponse}
   */
  listAllRoles(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetRolesResponse.default;
    return this.apiClient.callApi('/auth/roles/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeRolePermission operation.
   * @callback module:api/RolesManagementApi~removeRolePermissionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Permissions Of Role
   * With this endpoint, a role can be updated to not be permitted to a specific endpoint anymore. Note that if any users that are assigned this role are logged in, they will be logged out.
   * @param {Object} roleId 
   * @param {module:model/BodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost} bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost 
   * @param {module:api/RolesManagementApi~removeRolePermissionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetRoleResponse}
   */
  removeRolePermission(roleId, bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost, callback) {
    let postBody = bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost;
    // verify the required parameter 'roleId' is set
    if (roleId === undefined || roleId === null) {
      throw new Error("Missing the required parameter 'roleId' when calling removeRolePermission");
    }
    // verify the required parameter 'bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost' is set
    if (bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost === undefined || bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost === null) {
      throw new Error("Missing the required parameter 'bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost' when calling removeRolePermission");
    }
    let pathParams = {
      'role_id': roleId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/{role_id}/remove/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeRolePermission_0 operation.
   * @callback module:api/RolesManagementApi~removeRolePermission_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/GetRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Permissions Of Role
   * With this endpoint, a role can be updated to not be permitted to a specific endpoint anymore. Note that if any users that are assigned this role are logged in, they will be logged out.
   * @param {Object} roleId 
   * @param {module:model/BodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost} bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost 
   * @param {module:api/RolesManagementApi~removeRolePermission_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetRoleResponse}
   */
  removeRolePermission_0(roleId, bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost, callback) {
    let postBody = bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost;
    // verify the required parameter 'roleId' is set
    if (roleId === undefined || roleId === null) {
      throw new Error("Missing the required parameter 'roleId' when calling removeRolePermission_0");
    }
    // verify the required parameter 'bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost' is set
    if (bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost === undefined || bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost === null) {
      throw new Error("Missing the required parameter 'bodyRemovePermissionsOfRoleAuthRolesRoleIdRemovePost' when calling removeRolePermission_0");
    }
    let pathParams = {
      'role_id': roleId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/{role_id}/remove/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updateRole operation.
   * @callback module:api/RolesManagementApi~updateRoleCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update Role
   * With this endpoint, a role can be updated. Note that if any users that are assigned this role are logged in, they will be logged out.
   * @param {Object} roleId 
   * @param {module:model/RoleUpdateParameters} roleUpdateParameters 
   * @param {module:api/RolesManagementApi~updateRoleCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetRoleResponse}
   */
  updateRole(roleId, roleUpdateParameters, callback) {
    let postBody = roleUpdateParameters;
    // verify the required parameter 'roleId' is set
    if (roleId === undefined || roleId === null) {
      throw new Error("Missing the required parameter 'roleId' when calling updateRole");
    }
    // verify the required parameter 'roleUpdateParameters' is set
    if (roleUpdateParameters === undefined || roleUpdateParameters === null) {
      throw new Error("Missing the required parameter 'roleUpdateParameters' when calling updateRole");
    }
    let pathParams = {
      'role_id': roleId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/{role_id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updateRole_0 operation.
   * @callback module:api/RolesManagementApi~updateRole_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/GetRoleResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update Role
   * With this endpoint, a role can be updated. Note that if any users that are assigned this role are logged in, they will be logged out.
   * @param {Object} roleId 
   * @param {module:model/RoleUpdateParameters} roleUpdateParameters 
   * @param {module:api/RolesManagementApi~updateRole_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetRoleResponse}
   */
  updateRole_0(roleId, roleUpdateParameters, callback) {
    let postBody = roleUpdateParameters;
    // verify the required parameter 'roleId' is set
    if (roleId === undefined || roleId === null) {
      throw new Error("Missing the required parameter 'roleId' when calling updateRole_0");
    }
    // verify the required parameter 'roleUpdateParameters' is set
    if (roleUpdateParameters === undefined || roleUpdateParameters === null) {
      throw new Error("Missing the required parameter 'roleUpdateParameters' when calling updateRole_0");
    }
    let pathParams = {
      'role_id': roleId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetRoleResponse.default;
    return this.apiClient.callApi('/auth/roles/{role_id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = RolesManagementApi;