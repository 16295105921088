"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AppearancePrivacy = _interopRequireDefault(require("./AppearancePrivacy"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Detected1 model module.
 * @module model/Detected1
 * @version 0.32.2
 */
class Detected1 {
  /**
   * Constructs a new <code>Detected1</code>.
   * Privacy settings for outputting detected appearances
   * @alias module:model/Detected1
   * @implements module:model/AppearancePrivacy
   */
  constructor() {
    _AppearancePrivacy.default.initialize(this);
    Detected1.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Detected1</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Detected1} obj Optional instance to populate.
   * @return {module:model/Detected1} The populated <code>Detected1</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Detected1();
      _AppearancePrivacy.default.constructFromObject(data, obj);
      if (data.hasOwnProperty('send_event')) {
        obj['send_event'] = _ApiClient.default.convertToType(data['send_event'], 'Boolean');
      }
      if (data.hasOwnProperty('send_signature')) {
        obj['send_signature'] = _ApiClient.default.convertToType(data['send_signature'], 'Boolean');
      }
      if (data.hasOwnProperty('show_face_image')) {
        obj['show_face_image'] = _ApiClient.default.convertToType(data['show_face_image'], 'Boolean');
      }
      if (data.hasOwnProperty('show_age')) {
        obj['show_age'] = _ApiClient.default.convertToType(data['show_age'], 'Boolean');
      }
      if (data.hasOwnProperty('show_gender')) {
        obj['show_gender'] = _ApiClient.default.convertToType(data['show_gender'], 'Boolean');
      }
      if (data.hasOwnProperty('show_watchlist_notes')) {
        obj['show_watchlist_notes'] = _ApiClient.default.convertToType(data['show_watchlist_notes'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Detected1</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Detected1</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * Events are allowed to be sent
 * @member {Boolean} send_event
 * @default true
 */
Detected1.prototype['send_event'] = true;

/**
 * Events will include signature payload. Events are sent with signature payload only if this is enabled in system settings.
 * @member {Boolean} send_signature
 * @default true
 */
Detected1.prototype['send_signature'] = true;

/**
 * Events will include face crop
 * @member {Boolean} show_face_image
 * @default true
 */
Detected1.prototype['show_face_image'] = true;

/**
 * Events will include age, if determined
 * @member {Boolean} show_age
 * @default true
 */
Detected1.prototype['show_age'] = true;

/**
 * Events will include gender, if determined
 * @member {Boolean} show_gender
 * @default true
 */
Detected1.prototype['show_gender'] = true;

/**
 * Events will include the associated watchlists notes
 * @member {Boolean} show_watchlist_notes
 * @default true
 */
Detected1.prototype['show_watchlist_notes'] = true;

// Implement AppearancePrivacy interface:
/**
 * Events are allowed to be sent
 * @member {Boolean} send_event
 * @default true
 */
_AppearancePrivacy.default.prototype['send_event'] = true;
/**
 * Events will include signature payload. Events are sent with signature payload only if this is enabled in system settings.
 * @member {Boolean} send_signature
 * @default true
 */
_AppearancePrivacy.default.prototype['send_signature'] = true;
/**
 * Events will include face crop
 * @member {Boolean} show_face_image
 * @default true
 */
_AppearancePrivacy.default.prototype['show_face_image'] = true;
/**
 * Events will include age, if determined
 * @member {Boolean} show_age
 * @default true
 */
_AppearancePrivacy.default.prototype['show_age'] = true;
/**
 * Events will include gender, if determined
 * @member {Boolean} show_gender
 * @default true
 */
_AppearancePrivacy.default.prototype['show_gender'] = true;
/**
 * Events will include the associated watchlists notes
 * @member {Boolean} show_watchlist_notes
 * @default true
 */
_AppearancePrivacy.default.prototype['show_watchlist_notes'] = true;
var _default = exports.default = Detected1;