import { useCallback, useContext, useMemo } from "react";

import { connect } from "react-redux";
import { isEmpty } from "lodash";

import ContextMenuButton from "../../Common/Buttons/ContextMenuButton";
import { remove, removeAllIdle, removeAllIdleCamerasWarnInvestigate, removeAllIdleCamerasWarnLive, removedAllCameras, removedAllVideos, startAll, stopAll, stoppedAllCameras, stoppedAllVideos, viewProgress } from "src/appConfig/Strings";
import ApiContext from "@/Logic/Context/apiContext";
import { ManagingWebWorkerInterface } from "@/Logic/WebWorkers/WorkersInterfaces";
import { analyzingInvestigateVideos, analyzingLiveCameras } from "@/Logic/ApiClients/UsersClient";
import { activeCapturingStatuses, areCamerasInStatusList, nonActiveCapturingStatuses } from "@/Logic/ApiClients/CamerasClient";
import store from "src/appConfig/configureStore";
import { abortRunner, closeFloatingModal, openFloatingModal, runnerSetSum, runnersNames, startRunner } from "@/Logic/Redux/Stores/BaseRunnersStore";
import { Terminate } from "@/Logic/WebWorkers/BaseRunners/ManagingWebWorker";
import { toastUtility } from "@/Logic/Hooks/useToast";

function CamerasListContextMenu({ cameras, camerasLicense, isLiveAnalysisMode, startAllInProgress, startAllInfoOpen, hasAnalyzingCameras, hasIdleCameras }) {
    const { clientsManager } = useContext(ApiContext);

    const createStartAllCamerasWorker = useCallback(() => {
        const cameraStartWorker = clientsManager.workersClient.createCameraStarterWorker(clientsManager.eventsClientWorker);

        const { inactiveCamerasIds, runningCameras } = Object.values(cameras).reduce((accumulator, camera) => {
            if (activeCapturingStatuses.includes(camera.camera_status.status)) {
                accumulator.runningCameras += 1;
            } else {
                accumulator.inactiveCamerasIds.push(camera.camera_id);
            }

            return accumulator;
        }, { inactiveCamerasIds: [], runningCameras: 0 });

        const messageData = {
            camerasIds: inactiveCamerasIds,
            camerasLicense: camerasLicense - runningCameras,
            isLiveAnalysisMode: isLiveAnalysisMode
        };

        store.dispatch(startRunner({
            runnerName: runnersNames.camerasStarter
        }));
        store.dispatch(runnerSetSum({
            runnerName: runnersNames.camerasStarter,
            sum: inactiveCamerasIds.length
        }));
        cameraStartWorker.postMessage([ManagingWebWorkerInterface.init, [clientsManager.hostIp, clientsManager.token, messageData]]);
    }, [cameras, camerasLicense, clientsManager.eventsClientWorker, clientsManager.hostIp, clientsManager.token, clientsManager.workersClient, isLiveAnalysisMode]);

    const stopAllCameras = useCallback(async () => {
        if (startAllInProgress) {
            if (startAllInfoOpen) {
                store.dispatch(closeFloatingModal({ runnerName: runnersNames.camerasStarter }));
            }
            Terminate(clientsManager.workersClient.cameraStarterWorker);
            store.dispatch(abortRunner({ runnerName: runnersNames.camerasStarter }));
        }
        const camerasToStop = Object.values(cameras).filter((camera) => activeCapturingStatuses.includes(camera.camera_status.status));
        for (const camera of camerasToStop) {
            await clientsManager.camerasClient.stopCamera(camera.camera_id, false);
        }
        toastUtility.showSuccess(isLiveAnalysisMode ? stoppedAllCameras : stoppedAllVideos);
    }, [cameras, clientsManager.camerasClient, clientsManager.workersClient.cameraStarterWorker, isLiveAnalysisMode, startAllInProgress, startAllInfoOpen]);

    const removeAllIdleCameras = useCallback(async () => {
        const camerasToRemove = Object.values(cameras).filter((camera) => nonActiveCapturingStatuses.includes(camera.camera_status.status));
        for (const camera of camerasToRemove) {
            await clientsManager.camerasClient.removeCamera(camera.camera_id);
        }
        toastUtility.showSuccess(isLiveAnalysisMode ? removedAllCameras : removedAllVideos);
    }, [cameras, clientsManager.camerasClient, isLiveAnalysisMode]);

    const setOptions = useCallback(() => {
        const options = {};
        if (isEmpty(cameras)) {
            return options;
        }

        if (!startAllInProgress) {
            options[startAll] = createStartAllCamerasWorker;
            if (hasIdleCameras) {
                options[removeAllIdle] = removeAllIdleCameras;
            }
        } else if (!startAllInfoOpen) {
            options[viewProgress] = () => store.dispatch(openFloatingModal({ runnerName: runnersNames.camerasStarter }));
        }
        if (hasAnalyzingCameras) {
            options[stopAll] = stopAllCameras;
        }

        return options;
    }, [cameras, createStartAllCamerasWorker, hasAnalyzingCameras, hasIdleCameras, removeAllIdleCameras, startAllInProgress, startAllInfoOpen, stopAllCameras]);

    const confirmationOptions = useMemo(() => ({
        [removeAllIdle]: {
            acceptButtonText: remove,
            confirmationText: isLiveAnalysisMode ? removeAllIdleCamerasWarnLive : removeAllIdleCamerasWarnInvestigate
        }
    }), [isLiveAnalysisMode]);

    return (
        <ContextMenuButton options={setOptions()} dataTestId="cameras-list-context-menu" confirmationOptions={confirmationOptions} />
    );
}

const mapStateToProps = (state) => {
    const isLiveAnalysisMode = state.ApiActionsStore.isLiveAnalysisMode;
    const { cameras } = state.CamerasStore;
    const licenseName = isLiveAnalysisMode ? analyzingLiveCameras : analyzingInvestigateVideos;
    const startAllInProgress = state.BaseRunnersStore.camerasStarter.inWork;
    const startAllInfoOpen = state.BaseRunnersStore.camerasStarter.showFloatingModal;
    const hasAnalyzingCameras = areCamerasInStatusList(state.CamerasStore.cameras);
    const hasIdleCameras = areCamerasInStatusList(state.CamerasStore.cameras, nonActiveCapturingStatuses);

    const { license } = state.ApiActionsStore.license;
    let camerasLicense = 0;
    if (license) {
        camerasLicense = license?.features[licenseName].value.maxAvailable;
    }
    return { cameras, camerasLicense, isLiveAnalysisMode, startAllInProgress, startAllInfoOpen, hasAnalyzingCameras, hasIdleCameras };
};

export default connect(mapStateToProps, null)(CamerasListContextMenu);