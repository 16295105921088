import { memo } from "react";

import { IconButton, Tooltip, Zoom } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { tooltipWhiteStyle } from "../Styles/Tooltip.styles";

const InfoTooltip = ({ title, description, iconClassName, iconSize = "text-base" }) => {
    const tooltipCard = (
        <div>
            <h6 className="mb-2">{title}</h6>
            {description && (
                <div className="whitespace-pre-line">
                    {description}
                </div>
            )}
        </div>
    );

    return (
        <Tooltip
            title={tooltipCard}
            TransitionComponent={Zoom}
            arrow
            slotProps={tooltipWhiteStyle}
        >
            <IconButton size="small" className={`pt-1 ${iconClassName}`}>
                <InfoOutlinedIcon fontSize="small" className={iconSize} />
            </IconButton>
        </Tooltip>
    );
};

export default memo(InfoTooltip);