function MainLayoutPanel({ headerTitle, headerContent, children, className, isGrowEnabled = true }) {
    return (
        <div className={`flex ${isGrowEnabled ? "grow" : ""} min-w-switchable-panel-narrow flex-col overflow-hidden ${className}`}>
            <div className="flex h-9 min-h-9 w-full items-center">
                {headerTitle && (
                    <div className="main-title">
                        {headerTitle}
                    </div>
                )}
                {headerContent}
            </div>
            {children}
        </div>
    );
}

export default MainLayoutPanel;