import moment from "moment";

import { runnersNames, setImagesToView, setImageToView, setWorkersNumBelowThresholdAlert, stopPoiImporter, stopRunner, updateRunnerProgress, updateTimer } from "../Redux/Stores/BaseRunnersStore";
import { deleteCase, setAllSavedCases, setOpenCase } from "../Redux/Stores/SearchCasesStore";
import { auditWorkerInterface, cameraStarterInterface, importPoiInterface, IndexDBHandlerInterface, MainThreadMessages, ManagingWebWorkerInterface } from "../WebWorkers/WorkersInterfaces";
import { toastUtility } from "../Hooks/useToast";
import { updateCamera } from "../Redux/Stores/CamerasStore";
import { setAllGeneralSettings } from "../Redux/Stores/ApiActionsStore";
import { downloadFile } from "../Common";
import { failedAuditReport, message, startCameraFailed } from "src/appConfig/Strings";
import store from "src/appConfig/configureStore";
import { auditReportPrefix } from "src/appConfig/constants";
import { reportFormat } from "../Parsing/timeParsing";
import { createImageBlobUrlFromArrayBuffer } from "../Parsing/parseImages";

export default class WorkersClient {  //todo!: lets make it extend from base api such that we will be able to use hostIp and token without passing it as it works now 
    createStorageWorker() {
        this.storageWorker = new Worker(new URL("../WebWorkers/IndexDBHandler/IndexDBHandler.worker.js", import.meta.url), { name: `index_db_worker` });
        this.storageWorker.postMessage([IndexDBHandlerInterface.destroyFilesStore, []]);
        this.storageWorker.onmessage = ({ data }) => {
            const [receivedEventType, buffer] = data;
            switch (receivedEventType) {
                case MainThreadMessages.dispatchFilesToRedux: {
                    const imagesData = buffer[0];

                    const imagesObject = imagesData.reduce((acc, imageData) => {
                        const blobUrl = createImageBlobUrlFromArrayBuffer(imageData?.data);
                        acc[imageData?.name] = blobUrl;
                        return acc;
                    }, {});

                    store.dispatch(setImagesToView({ images: imagesObject }));

                    break;
                }
                case MainThreadMessages.dispatchFileToRedux: {
                    const imageData = buffer[0];

                    const blobUrl = createImageBlobUrlFromArrayBuffer(imageData?.data);
                    store.dispatch(setImageToView({
                        imageData: {
                            name: imageData?.name,
                            data: blobUrl
                        }
                    }));

                    break;
                }
                case MainThreadMessages.dispatchCasesKeysToRedux: {
                    store.dispatch(setAllSavedCases({ cases: buffer[0] }));
                    break;
                }
                case MainThreadMessages.dispatchGetCaseToRedux: {
                    store.dispatch(setOpenCase({ caseData: buffer[1] }));
                    break;
                }
                case MainThreadMessages.dispatchDeleteCaseToRedux: {
                    store.dispatch(deleteCase({ caseID: buffer[0] }));
                    break;
                }
                case MainThreadMessages.dispatchSettingsToRedux: {
                    store.dispatch(setAllGeneralSettings({ settings: buffer[0] }));
                    break;
                }
                default:
                    break;
            }
        };
    }

    createPoisImportWorker() {
        this.poisImporterWorker = new Worker(new URL("../WebWorkers/PoisImporter/MainPoisImporter.worker.js", import.meta.url), { name: `import_worker_manager` });
        this.poisImporterWorker.onmessage = ({ data }) => {
            const [receivedEventType, buffer] = data;
            switch (receivedEventType) {
                case MainThreadMessages.progressUpdate: {
                    this.updateProgress(runnersNames.poiImporter, buffer);
                    break;
                }
                case MainThreadMessages.shouldShowWorkersNumberAlert: {
                    store.dispatch(setWorkersNumBelowThresholdAlert(buffer[0]));
                    break;
                }
                case MainThreadMessages.finishedUpload: {
                    this.finishUpload(this.poisImporterWorker, stopPoiImporter, buffer);
                    break;
                }
                case MainThreadMessages.updateTime: {
                    store.dispatch(updateTimer({
                        runnerName: runnersNames.poiImporter,
                        updateData: buffer
                    }));
                    break;
                }
                case MainThreadMessages.storageWorker: {
                    const [funcName, ...prams] = buffer;
                    this.storageWorker.postMessage([funcName, [...prams]]);
                    break;
                }
                case importPoiInterface.onErrResponse: {
                    toastUtility.showError(buffer);
                    break;
                }
                default:
                    break;
            }
        };

        return this.poisImporterWorker;
    }

    createTablesReportWorker() {
        this.tableReportWorker = new Worker(new URL("../WebWorkers/TablesReportGenerator/TablesReportGenerator.worker.js", import.meta.url), { name: `table_report_worker` });
        this.tableReportWorker.onmessage = ({ data }) => {
            const [receivedEventType, buffer] = data;
            switch (receivedEventType) {
                case MainThreadMessages.finishedUpload: {
                    const fileName = buffer[1];
                    const data = buffer[0];
                    downloadFile(data, fileName, 'application/octet-stream');
                    break;
                }
                default:
                    break;
            }
        };

        return this.tableReportWorker;
    }

    createAuditReportWorker() {
        this.auditReportWorker = new Worker(new URL("../WebWorkers/AuditReportGenerator/MainAuditReportGenerator.worker.js", import.meta.url), { name: `audit_report_worker` });
        this.auditReportWorker.onmessage = ({ data }) => {
            const [receivedEventType, buffer] = data;
            switch (receivedEventType) {
                case MainThreadMessages.finishedUpload: {
                    const err = buffer[0];
                    if (err) {
                        toastUtility.showError(`${failedAuditReport}: ${err}`);
                    }
                    this.finishUpload(this.auditReportWorker);
                    break;
                }
                case auditWorkerInterface.downloadCsv: {
                    const fileName = `${auditReportPrefix}_${moment().format(reportFormat)}.csv`;
                    downloadFile(buffer, fileName, 'text/csv');
                    break;
                }
                default:
                    break;
            }
        };
        return this.auditReportWorker;
    }

    createCameraStarterWorker(eventsClientWorker) {
        this.cameraStarterWorker = new Worker(new URL("../WebWorkers/CamerasStarter/MainCamerasStarter/MainCamerasStarter.worker.js", import.meta.url), { name: `cameras_starter_worker_manager` });
        const camerasWorkerMessageSender = ({ data }) => {
            this.cameraStarterWorker.postMessage([cameraStarterInterface.handleStatusEvents, [data]]);
        };

        this.cameraStarterWorker.onmessage = ({ data }) => {
            const [receivedEventType, msgData] = data;
            switch (receivedEventType) {
                case MainThreadMessages.progressUpdate: {
                    this.updateProgress(runnersNames.camerasStarter, msgData);
                    break;
                }
                case MainThreadMessages.finishedUpload: {
                    this.finishUpload(this.cameraStarterWorker, stopRunner, runnersNames.camerasStarter);
                    eventsClientWorker.removeEventListener(message, camerasWorkerMessageSender);
                    break;
                }
                case cameraStarterInterface.errorStarting: {
                    toastUtility.showError(`${startCameraFailed}: ${msgData}`);
                    break;
                }
                case cameraStarterInterface.cameraStarted: {
                    store.dispatch(updateCamera({ camera: msgData[0] }));
                    break;
                }
                default:
                    break;
            }
        };

        eventsClientWorker.addEventListener(message, camerasWorkerMessageSender);
        return this.cameraStarterWorker;
    }

    updateProgress(runnerName, buffer) {
        store.dispatch(updateRunnerProgress({
            runnerName: runnerName,
            updateData: buffer
        }));
    }

    finishUpload(worker, stopAction, buffer) {
        worker.postMessage([ManagingWebWorkerInterface.onTerminated, []]);
        worker.terminate();
        stopAction && store.dispatch(stopAction(buffer));
    }
}