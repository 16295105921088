import { AnalysisSettingsApi, ApiClient } from "smart_cameras_controller_api";

import BaseAPIClient from "./BaseAPIClient";
import store from "src/appConfig/configureStore";
import { controllerRoute } from "../Infrastructure/networkConf";
import { setFeatureOutcome } from "../Redux/Stores/GlobalStore";

export default class AnalysisSettingsClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(controllerRoute, clientInstance);
        this.analysisSettingsApi = new AnalysisSettingsApi();
    }

    initialize() {
        this.getFeatureOutcomes();
    }

    async getFeatureOutcomes() {
        return this.apiCall("Get all possible face features outcomes",
            (callback) => this.analysisSettingsApi.getFeatureOutcomes(callback),
            (error, data, response) => store.dispatch(setFeatureOutcome({ featureOutcome: data.data })));
    }
}
