"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The POIFace model module.
 * @module model/POIFace
 * @version 0.37.1
 */
class POIFace {
  /**
   * Constructs a new <code>POIFace</code>.
   * @alias module:model/POIFace
   * @param faceId {Object} unique face id
   */
  constructor(faceId) {
    POIFace.initialize(this, faceId);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, faceId) {
    obj['face_id'] = faceId;
  }

  /**
   * Constructs a <code>POIFace</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/POIFace} obj Optional instance to populate.
   * @return {module:model/POIFace} The populated <code>POIFace</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new POIFace();
      if (data.hasOwnProperty('face_id')) {
        obj['face_id'] = _ApiClient.default.convertToType(data['face_id'], Object);
      }
      if (data.hasOwnProperty('face_score')) {
        obj['face_score'] = _ApiClient.default.convertToType(data['face_score'], Object);
      }
      if (data.hasOwnProperty('yaw')) {
        obj['yaw'] = _ApiClient.default.convertToType(data['yaw'], Object);
      }
      if (data.hasOwnProperty('pitch')) {
        obj['pitch'] = _ApiClient.default.convertToType(data['pitch'], Object);
      }
      if (data.hasOwnProperty('width')) {
        obj['width'] = _ApiClient.default.convertToType(data['width'], Object);
      }
      if (data.hasOwnProperty('img')) {
        obj['img'] = _ApiClient.default.convertToType(data['img'], Object);
      }
      if (data.hasOwnProperty('signature_payload')) {
        obj['signature_payload'] = _ApiClient.default.convertToType(data['signature_payload'], Object);
      }
      if (data.hasOwnProperty('face_attributes')) {
        obj['face_attributes'] = _ApiClient.default.convertToType(data['face_attributes'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>POIFace</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>POIFace</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of POIFace.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
POIFace.RequiredProperties = ["face_id"];

/**
 * unique face id
 * @member {Object} face_id
 */
POIFace.prototype['face_id'] = undefined;

/**
 * @member {Object} face_score
 */
POIFace.prototype['face_score'] = undefined;

/**
 * @member {Object} yaw
 */
POIFace.prototype['yaw'] = undefined;

/**
 * @member {Object} pitch
 */
POIFace.prototype['pitch'] = undefined;

/**
 * @member {Object} width
 */
POIFace.prototype['width'] = undefined;

/**
 * The saved face crop. If not saved or not required in response, this is null
 * @member {Object} img
 */
POIFace.prototype['img'] = undefined;

/**
 * The signature payload of the face. If not saved or not required in response, this is null
 * @member {Object} signature_payload
 */
POIFace.prototype['signature_payload'] = undefined;

/**
 * The face attributes results of the face
 * @member {Object} face_attributes
 */
POIFace.prototype['face_attributes'] = undefined;
var _default = exports.default = POIFace;