"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GenerativeInsightsResponseData model module.
 * @module model/GenerativeInsightsResponseData
 * @version 0.37.0
 */
class GenerativeInsightsResponseData {
  /**
   * Constructs a new <code>GenerativeInsightsResponseData</code>.
   * @alias module:model/GenerativeInsightsResponseData
   */
  constructor() {
    GenerativeInsightsResponseData.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>GenerativeInsightsResponseData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GenerativeInsightsResponseData} obj Optional instance to populate.
   * @return {module:model/GenerativeInsightsResponseData} The populated <code>GenerativeInsightsResponseData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GenerativeInsightsResponseData();
      if (data.hasOwnProperty('matches')) {
        obj['matches'] = _ApiClient.default.convertToType(data['matches'], Object);
      }
      if (data.hasOwnProperty('matched_entries')) {
        obj['matched_entries'] = _ApiClient.default.convertToType(data['matched_entries'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GenerativeInsightsResponseData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GenerativeInsightsResponseData</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * A list of retrieved matched generative insight records
 * @member {Object} matches
 */
GenerativeInsightsResponseData.prototype['matches'] = undefined;

/**
 * The number of records in the database that match the filter. Can be the size of the matches array or larger (if exceeds page limit)
 * @member {Object} matched_entries
 */
GenerativeInsightsResponseData.prototype['matched_entries'] = undefined;
var _default = exports.default = GenerativeInsightsResponseData;