import { v4 as uuid } from "uuid";

import { addSearchCase, setTempCaseData } from "../Redux/Stores/SearchCasesStore";
import { IndexDBHandlerInterface } from "../WebWorkers/WorkersInterfaces";
import store from "src/appConfig/configureStore";

export default class SearchCaseClient {

    static getAllCasesIdentifiers(clientsManager) {
        clientsManager.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.getAllCasesIdentifiers, []]);
    }

    static saveCase(clientsManager, caseName, caseData) {
        const caseID = uuid();
        const caseDataJSON = JSON.stringify(caseData);
        clientsManager.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.addCase, [caseID, caseName, caseDataJSON]]);
        store.dispatch(addSearchCase({ caseID, caseName }));

        return caseID;
    }

    static saveTempCase(tempCaseData) {
        store.dispatch(setTempCaseData({ tempCaseData }));
    }

    static getCase(clientsManager, caseID) {
        clientsManager.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.getCase, [caseID]]);
    }

    static updateCase(clientsManager, caseID, newCaseName, caseData) {
        const caseDataJSON = JSON.stringify(caseData);
        clientsManager.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.updateCase, [caseID, newCaseName, caseDataJSON]]);
    }

    static deleteCase(clientsManager, caseID) {
        clientsManager.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.deleteCase, [caseID]]);
    }
}
