import { useCallback, useMemo } from "react";

import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";
import { remove, removeDisplayImg, removeFaceImg, removePoi, removePoiConfirmationNote, removePoiFaceConfirmationNote, searchInHistory, searchInPoiDb, setDisplay } from "src/appConfig/Strings";
import { displayImageEvent, saveAlteredCaseEvent } from "src/appConfig/customEvents";
import { createIdentifyAppearanceImageCase, createSearchAppearanceImageCase } from "@/Logic/Hooks/useCaseInjector";
import { poiDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";

function POIFaceContextMenu({ poiFace, displayImg, removeCallback, removeLabel = removeFaceImg, contextMenuOptions }) {
    const setOptions = useCallback(() => {
        let options = {
            ...contextMenuOptions
        };

        if (removeCallback) {
            options[removeLabel] = () => {
                removeCallback(poiFace?.face_id);
            };
        }

        options = {
            ...options,
            [searchInHistory]: () => {
                document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent, { detail: { caseCreator: createSearchAppearanceImageCase, dataToChange: displayImg || poiFace?.img } }));
                dispatchToggleDialogEvent(poiDialogId);
            },
            [searchInPoiDb]: () => {
                document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent, { detail: { caseCreator: createIdentifyAppearanceImageCase, dataToChange: displayImg || poiFace?.img } }));
                dispatchToggleDialogEvent(poiDialogId);
            },
        };

        if (poiFace?.face_id) {
            options[setDisplay] = () => {
                const event = new CustomEvent(displayImageEvent, { detail: { displayImage: poiFace.img } });
                document.dispatchEvent(event);
            };
        }

        return options;
    }, [contextMenuOptions, displayImg, poiFace?.face_id, poiFace?.img, removeCallback, removeLabel]);

    const confirmationOptions = useMemo(() => {
        return {
            [removePoi]: {
                acceptButtonText: remove, confirmationText: removePoiConfirmationNote
            },
            [removeLabel]: {
                acceptButtonText: remove, confirmationText: removePoiFaceConfirmationNote
            }
        };
    }, [removeLabel]);

    const disabledOptions = useMemo(() => {
        const noFaceAndDisplayImg = !poiFace?.img && !displayImg;

        return {
            [searchInHistory]: () => noFaceAndDisplayImg,
            [searchInPoiDb]: () => noFaceAndDisplayImg,
            [setDisplay]: () => !poiFace?.img,
            [removeDisplayImg]: () => !displayImg
        };
    }, [displayImg, poiFace?.img]);

    return (
        <ContextMenuButton confirmationOptions={confirmationOptions} disabledOptions={disabledOptions} className="flex w-6 justify-center rounded bg-pie-line-bright-blue" modalClassName="!w-inner-stepper" options={setOptions()} />
    );
}

export default POIFaceContextMenu;