import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { debounce } from "lodash";

import store from "src/appConfig/configureStore";
import ApiContext from "../Context/apiContext";
import { poiPageSize } from "src/appConfig/constants";
import { wlClickedEvent } from "src/appConfig/customEvents";
import { updatePoisPage } from "../Redux/Stores/POIStore";

export async function getWlPoisRequest(clientsManager, afterId, watchlistId) {
    const { ids, totalPois } = watchlistId ?
        await clientsManager.watchlistsClient.getWatchlistWithPois(watchlistId, afterId, true) :
        await clientsManager.poisClient.getAllPois(afterId);
    await clientsManager.poisClient.getWatchlistPois(ids, totalPois);
}

function useWatchlistPois({ isPoiDbClicked, selectedWl, searchedName, isSearchedNameValid, setShouldFetchPoiData, shouldFetchPoiData }) {
    const { clientsManager } = useContext(ApiContext);
    const [isLoading, setIsLoading] = useState(false);
    const [afterIdsList, setAfterIdsList] = useState([]);

    const searchPoiByNameRequest = useCallback(async (watchlistId, searchedName) => {
        setIsLoading(true);
        store.dispatch(updatePoisPage({ currWatchlistId: watchlistId, searchedName: searchedName }));
        await clientsManager.poisClient.searchPoiByName(searchedName, watchlistId, poiPageSize, true);
        setIsLoading(false);
    }, [clientsManager]);

    const fetchPoisData = useCallback(async (watchlistId, afterId, isSelectedWl) => {
        const updatedWlId = isSelectedWl ? watchlistId : null;
        store.dispatch(updatePoisPage({ currWatchlistId: updatedWlId, currAfterId: afterId, searchedName: null }));
        setIsLoading(true);
        await getWlPoisRequest(clientsManager, afterId, updatedWlId);
        setIsLoading(false);
    }, [clientsManager]);

    const debouncedSearch = useMemo(() => debounce((watchlistId, name) => {
        if (!selectedWl.watchlist_id && !isPoiDbClicked) {
            return;
        }

        searchPoiByNameRequest(watchlistId, name);
    }, 400), [isPoiDbClicked, searchPoiByNameRequest, selectedWl.watchlist_id]);

    useEffect(() => {
        if (Boolean(searchedName) && isSearchedNameValid) {
            debouncedSearch(selectedWl.watchlist_id, searchedName);
            setShouldFetchPoiData(false);
        } else if (shouldFetchPoiData) {
            fetchPoisData(selectedWl.watchlist_id, afterIdsList, Boolean(selectedWl.watchlist_id));
            setShouldFetchPoiData(false);
        }

        return () => {
            debouncedSearch.cancel();
        };
    }, [afterIdsList, debouncedSearch, fetchPoisData, isSearchedNameValid, searchedName, selectedWl.watchlist_id, setShouldFetchPoiData, shouldFetchPoiData]);

    useEffect(() => {
        const handleWlRowClicked = () => {
            setAfterIdsList([]);
            setShouldFetchPoiData(true);
        };

        document.addEventListener(wlClickedEvent, handleWlRowClicked);
        return () => document.removeEventListener(wlClickedEvent, handleWlRowClicked);
    }, [setShouldFetchPoiData]);

    return {
        isLoading,
        setIsLoading,
        setAfterIdsList,
    };
}

export default useWatchlistPois;