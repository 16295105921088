import { ApiClient, AuditManagementApi, AuthenticationApi, RolesManagementApi, UsersManagementApi } from "smart_cameras_users_api";

import { isEmpty } from "lodash";

import store from "src/appConfig/configureStore";
import { toastUtility } from "../Hooks/useToast";
import { logoutUserSuccessMsg, passwordMatchWarning, unreachableHostError } from "src/appConfig/Strings";
import { setLoginResponse } from "../Redux/Stores/ApiActionsStore";
import { setDefaultRoleDataFilters } from "../Redux/Stores/EventsFiltersStore";
import { usersRoute } from "../Infrastructure/networkConf";
import BaseAPIClient, { resCodes } from "./BaseAPIClient";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { resultsPerPage } from "src/appConfig/constants";
import { setOpenUser, setRoleDataFilters, setUsers } from "../Redux/Stores/UsersStore";

// todo-sdk
const resetPass = "reset password";
export const analyzingLiveCameras = "analyzing_live_cameras";
export const analyzingInvestigateVideos = "analyzing_forensics_videos";
export const loggedIn = "logged in";
export const SUPERADMIN = "superadmin";
export const SUPPORT = "support";
export const systemRoleNames = [SUPERADMIN, SUPPORT];
export const INVALID = "invalid";

const parsers = {
    BoolFeatureData: (license) => license.valid ? license.valid : INVALID,
    CountedFeatureData: (license) => license.max_count ? (
        { currInUse: license.current_count, maxAvailable: license.max_count }
    ) : INVALID,
    ValueFeatureData: (license) => license.feature_value ? license.feature_value : INVALID
};

export function parseLicense(license) {
    if (!license) {
        return;
    }
    return parsers[license.feature_type](license) ?? INVALID;
}

export class UsersClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(usersRoute, clientInstance);
        this.authApi = new AuthenticationApi();
        this.usersManagementApi = new UsersManagementApi();
        this.rolesManagementApi = new RolesManagementApi();
        this.auditManagementApi = new AuditManagementApi();
        this.refreshTokenInterval = null;
    }

    #handleSuccessfulLogin(hostIp, loginData, initializeClients) {
        initializeClients(hostIp, loginData.access_token, loginData.data.role_data_filters.event_filters);
        this.refreshTokenInterval = setInterval(async () => {
            this.refreshToken();
        }, 0.7 * loginData.expires_in * 1000);
        store.dispatch(setLoginResponse([true, false, "", loginData.data]));
        store.dispatch(setRoleDataFilters(loginData.data));
        store.dispatch(setDefaultRoleDataFilters(loginData.data));
    }

    #handleUnauthorizedLogin(response) {
        let resetPassRequired = false;
        const errorMsg = response.body.metadata.msg;
        if (errorMsg.includes(resetPass)) {
            resetPassRequired = true;
        }
        store.dispatch(setLoginResponse([false, resetPassRequired, errorMsg]));
    }

    #parseUserSettingObject(userSettingObj) {
        const { full_name, email, phone, password, confirm_password, ...rest } = userSettingObj;
        if (password) {
            const isPasswordsEqual = confirmPasswordValidity(password, confirm_password);
            if (!isPasswordsEqual) {
                return null;
            }
        }

        const newUser = {
            ...rest,
            ...(password && { password: password }),
            user_contact_info: {
                ...(full_name && { full_name }),
                ...(email && { email }),
                ...(phone && { phone }),
            }
        };

        if (isEmpty(newUser.user_contact_info)) {
            delete newUser.user_contact_info;
        }

        return newUser;
    }

    async loginUser(loginForm, hostAddress, initializeClients) {
        const loginUserResponseMap = {
            [resCodes.noResponse]: () => store.dispatch(setLoginResponse([false, false, unreachableHostError])),
            [resCodes.badGateway]: () => store.dispatch(setLoginResponse([false, false, unreachableHostError])),
            [resCodes.badRequest]: (_, __, response) => store.dispatch(setLoginResponse([false, false, response.body.metadata.msg])),
            [resCodes.unauthorized]: (_, __, response) => this.#handleUnauthorizedLogin(response),
        };
        let options = {};
        const isPasswordsEqual = confirmPasswordValidity(loginForm.newPassword?.value, loginForm.confirmNewPassword?.value);
        const newPassword = isPasswordsEqual && loginForm.newPassword?.value;
        if (newPassword) {
            options = { newPassword: newPassword };
        }

        this.setHostIp(hostAddress);
        await this.apiCall("Login user",
            (callback) => this.authApi.loginUser(loginForm.username.value, loginForm.password.value, options, callback),
            (_, data) => this.#handleSuccessfulLogin(hostAddress, data, initializeClients),
            loginUserResponseMap);
    }

    async logoutUser() {
        await this.apiCall("Logout user",
            (callback) => this.authApi.logoutUser(callback),
            () => {
                clearInterval(this.refreshTokenInterval);
                store.dispatch(setLoginResponse([false, false, ""]));
            }
        );
    }

    refreshToken() {
        this.apiCall("Refresh token",
            (callback) => this.authApi.refreshToken(callback),
            () => console.info("Successfully refreshed token")
        );
    }

    listAllUsers() {
        this.apiCall("List all users",
            (callback) => this.usersManagementApi.listAllUsers(callback),
            (_, data) => store.dispatch(setUsers(data.data.users))
        );
    }

    async deleteUser(userId) {
        this.apiCall("Delete User",
            (callback) => this.usersManagementApi.deleteUser(userId, callback),
            () => this.listAllUsers()
        );
    }

    async getUser(userId) {
        return await this.apiCall("Get User",
            (callback) => this.usersManagementApi.getUser(userId, callback),
            (_, data) => {
                const payload = data.data;
                store.dispatch(setOpenUser(payload));
            }
        );
    }

    async createUser(userSettingObj) {
        const userSettingParams = this.#parseUserSettingObject(userSettingObj);
        if (!userSettingParams) {
            return;
        }

        return await this.apiCall("Create User",
            (callback) => this.usersManagementApi.addUser(userSettingParams, callback),
            (error, __, response) => parseResponseForUI(error, response)
        );
    }

    async editUser(userId, userSettingObj) {
        const userSettingParams = this.#parseUserSettingObject(userSettingObj);
        if (!userSettingParams) {
            return;
        }

        return await this.apiCall("Edit User",
            (callback) => this.usersManagementApi.updateUser(userId, userSettingParams, callback),
            (error, __, response) => parseResponseForUI(error, response)
        );
    }

    async terminateUserSession(userId) {
        return await this.apiCall("Terminate User Session",
            (callback) => this.usersManagementApi.terminateUserSession(userId, callback),
            () => {
                toastUtility.showSuccess(logoutUserSuccessMsg);
            }
        );
    }

    async getAuditRecords(userIds, from, till, afterId) {
        const auditDBQuery = { user_ids: userIds, from: from, till: till };
        const options = { afterId: afterId, limit: resultsPerPage };
        return await this.apiCall("Get audit",
            (callback) => this.auditManagementApi.getAuditRecords(auditDBQuery, options, callback),
            (error, data, response) => parseResponseForUI(error, response, data?.data)
        );
    }
}

export const confirmPasswordValidity = (newPassword, newPasswordConfirmation) => {
    if (!newPassword) {
        return;
    }
    const isPasswordsEqual = newPassword === newPasswordConfirmation;

    if (!isPasswordsEqual) {
        toastUtility.showWarning(passwordMatchWarning);
    }

    return isPasswordsEqual;
};

export function hasAllPermissions(permissionNames) {
    const { permission_names } = store.getState().ApiActionsStore?.login?.user;
    if (isEmpty(permission_names)) {
        return true;
    }

    return permissionNames.every((permission) => permission_names.includes(permission));
}