export const dataURIPrefix = 'data:';
export const base64Affix = ";base64,";
export const imgJpegType = "image/jpeg";
export const dataUrlBase64Pattern = /^.+,/;
export const dataUrlMimeTypePattern = /^data:(image\/\w+);base64,/;

export async function imageToBase64(image) {
    const imageBlob = await fetch(image).then(image => image.blob());
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(imageBlob);
    });
}

export async function arrayBufferToBase64(buffer) {
    const uint8Array = new Uint8Array(buffer);
    const binaryChunks = [];
    const chunkSize = 1024;

    for (let i = 0; i < uint8Array.length; i += chunkSize) {
        const chunk = uint8Array.subarray(i, i + chunkSize);
        binaryChunks.push(String.fromCharCode.apply(null, chunk));
    }

    const binaryString = binaryChunks.join('');
    return btoa(binaryString);
}

export function base64ToArrayBuffer(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export async function loadImageAsArrayBuffer(image) {
    const reader = new FileReader();
    return await new Promise((resolve) => {
        reader.onload = (event) => resolve(event.target.result);
        reader.readAsArrayBuffer(image);
    });
}

export async function createImageFromArrayBuffer(arrayBuffer) { // It is no longer in use, but it is worth keeping
    return await new Promise((resolve, reject) => {
        const blob = new Blob([arrayBuffer]);
        const reader = new FileReader();
        reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
        };
        reader.readAsDataURL(blob);
    });
}

export function createImageBlobUrlFromArrayBuffer(arrayBuffer) {
    const blob = new Blob([arrayBuffer]);
    const url = URL.createObjectURL(blob);

    return url;
}

export async function loadImageFromArrayToBase64(file) {
    return await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
            const base64 = await arrayBufferToBase64(event.target.result);
            resolve(base64);
        };
        reader.readAsArrayBuffer(file);
    });
}

export const parseImageBase64 = async (image) => image.includes(dataURIPrefix) ? await imageToBase64(image) : image;
export const formatImageToDataURL = (image, mimeType = imgJpegType) => image.includes(dataURIPrefix) ? image : `${dataURIPrefix}${mimeType}${base64Affix}${image}`;
export const getBase64StringFromDataURL = (dataURL) => dataURL && dataURL.replace(dataURIPrefix, '').replace(dataUrlBase64Pattern, '');
export const getImageMimeType = (image) => image.includes(dataURIPrefix) ? image.match(dataUrlMimeTypePattern)[1] : imgJpegType;

export async function rotateImage(dataUrl, mimeType) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;

    const image = new Image();
    image.src = dataUrl;
    await image.decode();

    const currentWidth = image.width;
    const currentHeight = image.height;

    canvas.width = currentHeight;
    canvas.height = currentWidth;

    ctx.translate(Math.floor(canvas.width / 2), Math.floor(canvas.height / 2));
    ctx.rotate(90 * Math.PI / 180);
    ctx.drawImage(image, currentWidth / -2, currentHeight / -2);

    return canvas.toDataURL(mimeType, 1.0).split(base64Affix)[1];
}