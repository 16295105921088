import { confirmNewPassword, newPassword, password, username } from "../../../appConfig/Strings";

export const LoginFormModel = {
    username: {
        name: "username",
        type: "text",
        placeholder: username,
        required: true
    },
    password: {
        name: "password",
        placeholder: password,
        required: true
    },
    newPassword: {
        name: "newPassword",
        placeholder: newPassword,
        required: true
    },
    confirmNewPassword: {
        name: "confirmNewPassword",
        placeholder: confirmNewPassword,
        required: true
    }
};