import { cloneElement, useCallback, useEffect, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Menu, MenuItem, IconButton, CircularProgress } from '@mui/material';
import { isEmpty } from "lodash";

import BaseModal from "../Modals/BaseModal/BaseModal";

function ContextMenuButton({ options, className, modalClassName, confirmationOptions = {}, disabledOptions = {}, dataTestId = "", iconElement, iconClassName = "text-white" }) {
    //todo! : The functionality and style of this component should be refactored
    const baseConfirmationData = useMemo(() => ({
        isOpen: false,
        option: "",
        acceptButtonText: "",
        confirmationText: ""
    }), []);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isUnmounted, setIsUnmounted] = useState(false);
    const [confirmationData, setConfirmationData] = useState(baseConfirmationData);

    const handleMenuOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const executeAction = useCallback(async (action) => {
        if (!isUnmounted) {
            setIsLoading(true);
            await action();
            setIsLoading(false);
        }
    }, [isUnmounted]);

    useEffect(() => {
        return () => setIsUnmounted(true);
    }, []);

    const contextAction = useCallback((label, action) => {
        handleMenuClose();
        if (confirmationOptions.hasOwnProperty(label)) {
            setConfirmationData({
                isOpen: true,
                actionName: label,
                acceptButtonText: confirmationOptions[label].acceptButtonText,
                confirmationText: confirmationOptions[label].confirmationText
            });
        } else {
            executeAction(action);
        }
    }, [confirmationOptions, executeAction]);

    const handleModalAction = useCallback((confirmed) => {
        if (confirmed) {
            executeAction(options[confirmationData.actionName]);
        }
        setConfirmationData(baseConfirmationData);
    }, [baseConfirmationData, confirmationData.actionName, executeAction, options]);

    if (isEmpty(options)) {
        return <span></span>;
    }

    return (
        <div className={className}>
            {isLoading ? (
                <CircularProgress size={24} className="text-white" />
            ) : (
                <div>
                    {iconElement ? (
                        cloneElement(iconElement, { onClick: handleMenuOpen })
                    ) : (
                        <IconButton
                            size="small"
                            className={iconClassName}
                            onClick={handleMenuOpen}
                            data-testid={dataTestId}
                        >
                            {isLoading ? <CircularProgress size={24} /> : <MoreVertIcon />}
                        </IconButton>
                    )}
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                        {Object.entries(options).map(([label, handler]) => (
                            <MenuItem data-testid={label} disabled={disabledOptions[label] ? disabledOptions[label]() : false} key={label} onClick={() => contextAction(label, handler)}>
                                {label}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
            <BaseModal
                className={modalClassName}
                isOpen={confirmationData.isOpen}
                handleLeftBtnAction={() => handleModalAction(false)}
                handleRightBtnAction={() => handleModalAction(true)}
                rightBtnText={confirmationData.acceptButtonText}
                onClickHeaderCancel={() => handleModalAction(false)}
            >
                {confirmationData.confirmationText}
            </BaseModal>
        </div>
    );
}

export default ContextMenuButton;
