import store from "src/appConfig/configureStore";

import { EventsWorkerInterface, IndexDBHandlerInterface } from "../WebWorkers/WorkersInterfaces";
import AnalysisModeClient from "./AnalysisModeClient";
import AnalysisSettingsClient from "./AnalysisSettingsClient";
import { CamerasClient } from "./CamerasClient";
import { ControllerClient } from "./ControllerClient";
import { DataRetentionClient } from "./DataRetentionClient";
import FaceClient from "./FaceClient";
import FramesStorageClient from "./FramesStorageClient";
import { GenerativeInsightsClient } from "./GenerativeInsightsClient";
import { HistoryClient } from "./HistoryClient";
import HistoryGenerativeInsightClient from "./HistoryGenerativeInsightClient";
import { InsightsClient } from "./InsightsClient";
import POIClient from "./POIClient";
import SearchCaseClient from "./SearchCaseClient";
import { UsersClient } from "./UsersClient";
import { WatchlistsClient } from "./WatchlistsClient";
import WorkersClient from "./WorkersClient";
import { WebDockerClient } from "./WebDockerClient";
import PrivacyProfilesClient from "./PrivacyProfilesClient";
import { RolesClient } from "./RolesClient";
import { setIsLoggedIn } from "../Redux/Stores/ApiActionsStore";
import { defaultNetworkTimeout } from "src/appConfig/constants";

export default class ClientsManager {
    constructor() {
        this.hostIp = "";
        this.token = "";
        this.webDockerClient = new WebDockerClient();
        this.webDockerClient.initialize();
        this.eventsClientWorker = new Worker(new URL("../WebWorkers/Event.worker.js", import.meta.url), { name: `events_client_worker` });
        this.workersClient = new WorkersClient();
        this.usersClient = new UsersClient();
        this.rolesClient = new RolesClient();
        this.controllerClient = new ControllerClient();
        this.watchlistsClient = new WatchlistsClient();
        this.historyClient = new HistoryClient();
        this.camerasClient = new CamerasClient();
        this.poisClient = new POIClient();
        this.faceClient = new FaceClient();
        this.poisImporterWorker = null;
        this.analysisSettingsClient = new AnalysisSettingsClient();
        this.analysisModeClient = new AnalysisModeClient();
        this.insightsClient = new InsightsClient();
        this.generativeInsightsClient = new GenerativeInsightsClient();
        this.framesStorageClient = new FramesStorageClient();
        this.historyGenerativeInsightClient = new HistoryGenerativeInsightClient();
        this.dataRetentionClient = new DataRetentionClient();
        this.privacyProfilesClient = new PrivacyProfilesClient();
        this.clientList = [
            this.usersClient,
            this.rolesClient,
            this.controllerClient,
            this.camerasClient,
            this.watchlistsClient,
            this.historyClient,
            this.poisClient,
            this.faceClient,
            this.webDockerClient,
            this.analysisSettingsClient,
            this.analysisModeClient,
            this.insightsClient,
            this.historyGenerativeInsightClient,
            this.generativeInsightsClient,
            this.framesStorageClient,
            this.dataRetentionClient,
            this.privacyProfilesClient
        ];
    }

    initializeClients(hostIp, token, roleDataFilters) {
        this.hostIp = hostIp;
        this.token = token;
        const { NETWORK_TIMEOUT_SECONDS: networkTimeout } = store.getState().EnvStore;
        for (const client of this.clientList) {
            client.client_instance.timeout = (networkTimeout || defaultNetworkTimeout) * 1000;
            client.setHostIp(hostIp);
            client.setToken(token);
        }
        this.workersClient.createStorageWorker();
        this.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.getAllGeneralSettings, []]);
        this.eventsClientWorker.postMessage([EventsWorkerInterface.initialize, [this.hostIp, this.token, roleDataFilters]]);
        this.camerasClient.initialize();
        this.watchlistsClient.initialize();
        this.controllerClient.initialize();
        this.analysisSettingsClient.initialize();
        this.analysisModeClient.initialize();
        this.privacyProfilesClient.initialize();
        SearchCaseClient.getAllCasesIdentifiers(this);
    }

    logoutUserAndCleanup(sessionEnded = false) {
        this.eventsClientWorker.postMessage([EventsWorkerInterface.stopListener, []]);
        if (!sessionEnded) {
            this.usersClient.logoutUser();
        }
        this.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.destroyFilesStore, []]);
        store.dispatch(setIsLoggedIn(false));
    }
}