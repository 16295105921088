"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _ValidationError = _interopRequireDefault(require("./ValidationError"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The HTTPValidationError model module.
 * @module model/HTTPValidationError
 * @version 0.35.0
 */
class HTTPValidationError {
  /**
   * Constructs a new <code>HTTPValidationError</code>.
   * @alias module:model/HTTPValidationError
   */
  constructor() {
    HTTPValidationError.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>HTTPValidationError</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/HTTPValidationError} obj Optional instance to populate.
   * @return {module:model/HTTPValidationError} The populated <code>HTTPValidationError</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new HTTPValidationError();
      if (data.hasOwnProperty('detail')) {
        obj['detail'] = _ApiClient.default.convertToType(data['detail'], [_ValidationError.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>HTTPValidationError</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>HTTPValidationError</code>.
   */
  static validateJSON(data) {
    if (data['detail']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['detail'])) {
        throw new Error("Expected the field `detail` to be an array in the JSON data but got " + data['detail']);
      }
      // validate the optional field `detail` (array)
      for (const item of data['detail']) {
        _ValidationError.default.validateJsonObject(item);
      }
      ;
    }
    return true;
  }
}

/**
 * @member {Array.<module:model/ValidationError>} detail
 */
HTTPValidationError.prototype['detail'] = undefined;
var _default = exports.default = HTTPValidationError;