import { useCallback, useContext, useEffect, useState } from "react";

import store from "src/appConfig/configureStore";
import ApiContext from "../Context/apiContext";
import useUIResponseHandler from "./useUIResponseHandler";
import { RequiredFaceAttributesConfig } from "@/Components/Cameras/CreateCamera/CreateEditCamera/CreateEditCamera.model";
import { INVALID } from "../ApiClients/UsersClient";

function useAnalyze(selectedImage) {
    const { clientsManager } = useContext(ApiContext);
    const [analyzeData, setAnalyzeData] = useState({});
    const { isLoading, setIsLoading, parseResponse, error, responseMessage } = useUIResponseHandler();

    const analyzeRequest = useCallback(async () => {
        setIsLoading(true);
        const { license } = store.getState().ApiActionsStore.license;
        const analyzeRequiredFaceAttributes = {
            ...(license.features[RequiredFaceAttributesConfig.age_group].value !== INVALID ? { [RequiredFaceAttributesConfig.age_group]: RequiredFaceAttributesConfig.age_group } : {}),
            ...(license.features[RequiredFaceAttributesConfig.gender].value !== INVALID ? { [RequiredFaceAttributesConfig.gender]: RequiredFaceAttributesConfig.gender } : {}),
            ...(license.features[RequiredFaceAttributesConfig.liveness].value !== INVALID ? { [RequiredFaceAttributesConfig.liveness]: RequiredFaceAttributesConfig.liveness } : {}),
        };

        const response = await clientsManager.faceClient.analyzeFace(selectedImage, analyzeRequiredFaceAttributes);
        parseResponse(response);

        if (!response.data) {
            return;
        } else {
            const { age_group, gender, valid_face, liveness, face_requires_guardian_consent } = response.data;
            setAnalyzeData((prev) => ({ valid_face, faceAttributes: { age_group, gender, liveness, face_requires_guardian_consent } }));
        }
    }, [clientsManager.faceClient, parseResponse, selectedImage, setIsLoading]);

    useEffect(() => {
        if (selectedImage) {
            analyzeRequest(selectedImage);
        }

        return () => {
            setIsLoading(false);
        };
    }, [analyzeRequest, selectedImage, setIsLoading]);

    return { isLoading, error, responseMessage, analyzeData };
}

export default useAnalyze;