import { useMemo, useState, useCallback, useEffect } from "react";

import { connect } from "react-redux";

import { cameraName, frame, matchInfo, playback } from "src/appConfig/Strings";
import PlaybackFooter from "./PlaybackFooter/PlaybackFooter";
import { setDialogData } from "src/appConfig/customEvents";
import OverflowingText from "../Common/Tooltips/OverflowingText";
import { clearFrames } from "@/Logic/Redux/Stores/FramesStore";
import { matchFrameIndexVal } from "@/Logic/ApiClients/FramesStorageClient";
import { framesIntervalMs } from "src/appConfig/constants";
import { playbackDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";
import ImageWrapper from "../Common/Wrappers/ImageWrapper";
import MainDialog from "../Common/Modals/MainDialog";

function PlaybackDialog({ frames, appearanceSequence, firstFrameIndex }) {
    const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [dialogDetails, setAppearanceDetails] = useState({});

    const matchInfoText = useMemo(() => `${matchInfo} ${dialogDetails.matchTime} (${frame} #${firstFrameIndex + 1})`, [dialogDetails.matchTime, firstFrameIndex]);
    const cameraInfo = useMemo(() => ` ${cameraName}: ${dialogDetails?.cameraDisplayName}`, [dialogDetails?.cameraDisplayName]);
    const currentFrameImage = useMemo(() => frames[appearanceSequence[currentFrameIndex]]?.image, [appearanceSequence, currentFrameIndex, frames]);

    useEffect(() => {
        setCurrentFrameIndex(firstFrameIndex);
    }, [firstFrameIndex]);

    useEffect(() => {
        if (isPlaying) {
            const playInterval = setInterval(() => {
                setCurrentFrameIndex((prevIndex) => {
                    const nextIndex = prevIndex + 1;
                    return nextIndex > appearanceSequence.length - 1 ? 0 : nextIndex;
                });
            }, framesIntervalMs);

            return () => clearInterval(playInterval);
        }
    }, [isPlaying, appearanceSequence.length]);

    const handlePlayToggle = useCallback(() => {
        setIsPlaying((prev) => !prev);
    }, []);

    const setPlaybackDetailsFunc = useCallback((event) => {
        setAppearanceDetails(event.detail);
    }, []);

    useEffect(() => {
        document.addEventListener(setDialogData, setPlaybackDetailsFunc);

        return () => {
            document.removeEventListener(setDialogData, setPlaybackDetailsFunc);
        };
    }, [setPlaybackDetailsFunc]);

    const clearPlayback = useCallback(() => {
        clearFrames();
        setIsPlaying(false);
    }, []);

    return (
        <MainDialog headerTitle={playback} dialogID={playbackDialogId} isFooterVisible={false} onExit={clearPlayback}>
            <div className="flex size-full flex-col items-center justify-center gap-2">
                <OverflowingText className="playback-info w-full" text={cameraInfo} />
                <span className="playback-info mt-1">{matchInfoText}</span>
                <ImageWrapper src={currentFrameImage} className="w-full" />
                <PlaybackFooter
                    currentFrameIndex={currentFrameIndex}
                    appearanceSequence={appearanceSequence}
                    setCurrentFrameIndex={setCurrentFrameIndex}
                    isPlaying={isPlaying}
                    handlePlayToggle={handlePlayToggle}
                    cameraName={dialogDetails?.cameraDisplayName}
                    frames={frames}
                    appearanceId={dialogDetails?.appearanceId}
                />
            </div>
        </MainDialog>
    );
}

const mapDispatchToProps = {
    clearFrames
};

const mapStateToProps = (state) => {
    const frames = state.FramesStore.frames;
    const appearanceSequence = state.FramesStore.appearanceSequence;
    const firstFrameIndex = appearanceSequence.indexOf(matchFrameIndexVal);

    return { frames, appearanceSequence, firstFrameIndex };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaybackDialog);