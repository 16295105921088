"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetAppearancePrivacyProfileResponse = _interopRequireDefault(require("../model/GetAppearancePrivacyProfileResponse"));
var _ListAppearanceProfilesResponse = _interopRequireDefault(require("../model/ListAppearanceProfilesResponse"));
var _RemoveProfileResponse = _interopRequireDefault(require("../model/RemoveProfileResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Controller API
 * ## Control & Management - Status Endpoint allows the user of the API to see the very basic data of the deployment - and notably, the deployment_id - even if the user has no credentials or valid license - License Endpoint allows an authenticated user to view current license usage  ## System Settings **Privacy settings** - 'Privacy' is a global system mode that allows a user of the system to restrict the biometric data that is saved and/or outputted by SC. - The global system mode, as well as the specific privacy settings that control which biometric data the system is permitted to save and output, are configured using the privacy settings API of Controller Service.  **SearchFace settings** - Settings that apply to the SearchFace endpoints (in POI DB & History DB)  **Analysis settings** - System-wide settings related to the analysis of streams  ## Data Retention Perform actions & retrieve information regarding the data saved by the system - how many records exist, what is the max capcity, enable automatic clearing etc. 
 *
 * The version of the OpenAPI document: 0.36.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* AppearancePrivacyProfiles service.
* @module api/AppearancePrivacyProfilesApi
* @version 0.36.2
*/
class AppearancePrivacyProfilesApi {
  /**
  * Constructs a new AppearancePrivacyProfilesApi. 
  * @alias module:api/AppearancePrivacyProfilesApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addAppearancePrivacyProfile operation.
   * @callback module:api/AppearancePrivacyProfilesApi~addAppearancePrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAppearancePrivacyProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Create Appearance Privacy Profile
   * Create a new Appearance Privacy profile. Note that the name must be unique (case-insensitive)
   * @param {Object} body 
   * @param {module:api/AppearancePrivacyProfilesApi~addAppearancePrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAppearancePrivacyProfileResponse}
   */
  addAppearancePrivacyProfile(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling addAppearancePrivacyProfile");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetAppearancePrivacyProfileResponse.default;
    return this.apiClient.callApi('/privacy/appearance/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getAppearancePrivacyProfile operation.
   * @callback module:api/AppearancePrivacyProfilesApi~getAppearancePrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAppearancePrivacyProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Appearance Privacy Profile
   * Get Specific Appearance Privacy Profile
   * @param {Object} profileId 
   * @param {module:api/AppearancePrivacyProfilesApi~getAppearancePrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAppearancePrivacyProfileResponse}
   */
  getAppearancePrivacyProfile(profileId, callback) {
    let postBody = null;
    // verify the required parameter 'profileId' is set
    if (profileId === undefined || profileId === null) {
      throw new Error("Missing the required parameter 'profileId' when calling getAppearancePrivacyProfile");
    }
    let pathParams = {
      'profile_id': profileId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetAppearancePrivacyProfileResponse.default;
    return this.apiClient.callApi('/privacy/appearance/{profile_id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getDefaultAppearancePrivacyProfile operation.
   * @callback module:api/AppearancePrivacyProfilesApi~getDefaultAppearancePrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAppearancePrivacyProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Default Appearance Privacy Profile
   * Get the current default Appearance profile id
   * @param {module:api/AppearancePrivacyProfilesApi~getDefaultAppearancePrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAppearancePrivacyProfileResponse}
   */
  getDefaultAppearancePrivacyProfile(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetAppearancePrivacyProfileResponse.default;
    return this.apiClient.callApi('/privacy/appearance/default/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the listAllAppearancePrivacyProfiles operation.
   * @callback module:api/AppearancePrivacyProfilesApi~listAllAppearancePrivacyProfilesCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ListAppearanceProfilesResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List Appearance Privacy Profiles
   * Get all existing Appearance Privacy Profiles
   * @param {module:api/AppearancePrivacyProfilesApi~listAllAppearancePrivacyProfilesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ListAppearanceProfilesResponse}
   */
  listAllAppearancePrivacyProfiles(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ListAppearanceProfilesResponse.default;
    return this.apiClient.callApi('/privacy/appearance/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeAppearancePrivacyProfile operation.
   * @callback module:api/AppearancePrivacyProfilesApi~removeAppearancePrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/RemoveProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Appearance Privacy Profile
   * Remove Specific Appearance Privacy Profile. The default profile and the built-in profiles cannot be removed.
   * @param {Object} profileId 
   * @param {module:api/AppearancePrivacyProfilesApi~removeAppearancePrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/RemoveProfileResponse}
   */
  removeAppearancePrivacyProfile(profileId, callback) {
    let postBody = null;
    // verify the required parameter 'profileId' is set
    if (profileId === undefined || profileId === null) {
      throw new Error("Missing the required parameter 'profileId' when calling removeAppearancePrivacyProfile");
    }
    let pathParams = {
      'profile_id': profileId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _RemoveProfileResponse.default;
    return this.apiClient.callApi('/privacy/appearance/{profile_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setDefaultAppearancePrivacyProfile operation.
   * @callback module:api/AppearancePrivacyProfilesApi~setDefaultAppearancePrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAppearancePrivacyProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set Default Appearance Privacy Profile
   * Set the default Appearance profile id to be the profile identified by the input id.
   * @param {Object} body 
   * @param {module:api/AppearancePrivacyProfilesApi~setDefaultAppearancePrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAppearancePrivacyProfileResponse}
   */
  setDefaultAppearancePrivacyProfile(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setDefaultAppearancePrivacyProfile");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetAppearancePrivacyProfileResponse.default;
    return this.apiClient.callApi('/privacy/appearance/default/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updateAppearancePrivacyProfile operation.
   * @callback module:api/AppearancePrivacyProfilesApi~updateAppearancePrivacyProfileCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAppearancePrivacyProfileResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update Appearance Privacy Profile
   * Update Specific Appearance Privacy Profile. The built-in profiles cannot be updated.
   * @param {Object} profileId 
   * @param {Object} body 
   * @param {module:api/AppearancePrivacyProfilesApi~updateAppearancePrivacyProfileCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAppearancePrivacyProfileResponse}
   */
  updateAppearancePrivacyProfile(profileId, body, callback) {
    let postBody = body;
    // verify the required parameter 'profileId' is set
    if (profileId === undefined || profileId === null) {
      throw new Error("Missing the required parameter 'profileId' when calling updateAppearancePrivacyProfile");
    }
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updateAppearancePrivacyProfile");
    }
    let pathParams = {
      'profile_id': profileId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetAppearancePrivacyProfileResponse.default;
    return this.apiClient.callApi('/privacy/appearance/{profile_id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = AppearancePrivacyProfilesApi;