import { formatImageToDataURL } from "@/Logic/Parsing/parseImages";
import { useEffect, useState } from "react";


import defaultFaceCrop from "src/assets/icon_default_poi.svg";

function ImageWrapper({ src, className }) {
    const [imageSrc, setImageSrc] = useState(defaultFaceCrop);

    useEffect(() => {
        if (!src) {
            setImageSrc(defaultFaceCrop);
            return;
        }

        const img = new Image();
        img.src = formatImageToDataURL(src);
        setImageSrc(img.src);
    }, [src]);

    return (
        <img alt="" src={imageSrc} className={`object-contain ${className}`} />
    );
}

export default ImageWrapper;