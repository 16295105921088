"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The RemoveCameraModel model module.
 * @module model/RemoveCameraModel
 * @version 0.32.2
 */
class RemoveCameraModel {
  /**
   * Constructs a new <code>RemoveCameraModel</code>.
   * @alias module:model/RemoveCameraModel
   * @param cameraId {String} the deleted camera_id
   */
  constructor(cameraId) {
    RemoveCameraModel.initialize(this, cameraId);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, cameraId) {
    obj['camera_id'] = cameraId;
  }

  /**
   * Constructs a <code>RemoveCameraModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RemoveCameraModel} obj Optional instance to populate.
   * @return {module:model/RemoveCameraModel} The populated <code>RemoveCameraModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RemoveCameraModel();
      if (data.hasOwnProperty('camera_id')) {
        obj['camera_id'] = _ApiClient.default.convertToType(data['camera_id'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>RemoveCameraModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RemoveCameraModel</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of RemoveCameraModel.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['camera_id'] && !(typeof data['camera_id'] === 'string' || data['camera_id'] instanceof String)) {
      throw new Error("Expected the field `camera_id` to be a primitive type in the JSON string but got " + data['camera_id']);
    }
    return true;
  }
}
RemoveCameraModel.RequiredProperties = ["camera_id"];

/**
 * the deleted camera_id
 * @member {String} camera_id
 */
RemoveCameraModel.prototype['camera_id'] = undefined;
var _default = exports.default = RemoveCameraModel;