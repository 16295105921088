"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AuditDBQuery model module.
 * @module model/AuditDBQuery
 * @version 0.35.0
 */
class AuditDBQuery {
  /**
   * Constructs a new <code>AuditDBQuery</code>.
   * @alias module:model/AuditDBQuery
   */
  constructor() {
    AuditDBQuery.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>AuditDBQuery</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AuditDBQuery} obj Optional instance to populate.
   * @return {module:model/AuditDBQuery} The populated <code>AuditDBQuery</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AuditDBQuery();
      if (data.hasOwnProperty('api_names')) {
        obj['api_names'] = _ApiClient.default.convertToType(data['api_names'], ['String']);
      }
      if (data.hasOwnProperty('action_names')) {
        obj['action_names'] = _ApiClient.default.convertToType(data['action_names'], ['String']);
      }
      if (data.hasOwnProperty('from')) {
        obj['from'] = _ApiClient.default.convertToType(data['from'], 'Number');
      }
      if (data.hasOwnProperty('till')) {
        obj['till'] = _ApiClient.default.convertToType(data['till'], 'Number');
      }
      if (data.hasOwnProperty('user_ids')) {
        obj['user_ids'] = _ApiClient.default.convertToType(data['user_ids'], ['String']);
      }
      if (data.hasOwnProperty('user_names')) {
        obj['user_names'] = _ApiClient.default.convertToType(data['user_names'], ['String']);
      }
      if (data.hasOwnProperty('target_ids')) {
        obj['target_ids'] = _ApiClient.default.convertToType(data['target_ids'], ['String']);
      }
      if (data.hasOwnProperty('return_codes')) {
        obj['return_codes'] = _ApiClient.default.convertToType(data['return_codes'], ['Number']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AuditDBQuery</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AuditDBQuery</code>.
   */
  static validateJSON(data) {
    // ensure the json data is an array
    if (!Array.isArray(data['api_names'])) {
      throw new Error("Expected the field `api_names` to be an array in the JSON data but got " + data['api_names']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['action_names'])) {
      throw new Error("Expected the field `action_names` to be an array in the JSON data but got " + data['action_names']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['user_ids'])) {
      throw new Error("Expected the field `user_ids` to be an array in the JSON data but got " + data['user_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['user_names'])) {
      throw new Error("Expected the field `user_names` to be an array in the JSON data but got " + data['user_names']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['target_ids'])) {
      throw new Error("Expected the field `target_ids` to be an array in the JSON data but got " + data['target_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['return_codes'])) {
      throw new Error("Expected the field `return_codes` to be an array in the JSON data but got " + data['return_codes']);
    }
    return true;
  }
}

/**
 * names of the general API services that are of interest to this query
 * @member {Array.<String>} api_names
 */
AuditDBQuery.prototype['api_names'] = undefined;

/**
 * action_names that are of interest to this query
 * @member {Array.<String>} action_names
 */
AuditDBQuery.prototype['action_names'] = undefined;

/**
 * the earliest UTC timestamp to fetch audits from. If absent, will begin from start of audit log
 * @member {Number} from
 */
AuditDBQuery.prototype['from'] = undefined;

/**
 * the latest UTC timestamp to fetch audits from. If absent, will finish at current time.
 * @member {Number} till
 */
AuditDBQuery.prototype['till'] = undefined;

/**
 * user_ids (of users that performed actions that are recorded in the audit) that are of interest to this query
 * @member {Array.<String>} user_ids
 */
AuditDBQuery.prototype['user_ids'] = undefined;

/**
 * user names (of users that performed actions that are recorded in the audit) that are of interest to this query
 * @member {Array.<String>} user_names
 */
AuditDBQuery.prototype['user_names'] = undefined;

/**
 * ids of targets of operations that are of interest to this query
 * @member {Array.<String>} target_ids
 */
AuditDBQuery.prototype['target_ids'] = undefined;

/**
 * return codes that are of interest to this query
 * @member {Array.<Number>} return_codes
 */
AuditDBQuery.prototype['return_codes'] = undefined;
var _default = exports.default = AuditDBQuery;