import { useCallback, useContext, useMemo } from "react";

import { Form, Input } from "reactstrap";
import Button from '@mui/material/Button';

import ApiContext from "../../Logic/Context/apiContext";
import { eula, loginButton, privacyConsent, privacyNoticeTitle, version } from "../../../appConfig/Strings";
import { useSelector } from "react-redux";
import { LoginFormModel } from "./Login.model";
import { eulaPdf, loginLogo } from "../../../appConfig/constants";
import BaseModalOld from "../Common/Modals/BaseModalOld";
import PasswordInputField from "../Common/FormComponents/FormInputs/PasswordInputField";
import packages from "../../../../package.json";
import loginGif from "../../../assets/gifs/Login/login_screen_animation.gif";
import { getHostAddress } from "@/Logic/Infrastructure/networkConf";

function Login({ initializeClients }) {
    const { clientsManager } = useContext(ApiContext);
    const loginStatus = useSelector((state) => state.ApiActionsStore.login);
    const hostAddress = useMemo(() => getHostAddress(), []);

    const getsystemSettings = useCallback(async () => {
        const privacyNotice = await clientsManager.controllerClient.getPrivacyNotice(hostAddress);
        return privacyNotice;
    }, [clientsManager.controllerClient, hostAddress]);

    const handleSubmitForm = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        clientsManager.usersClient.loginUser(event.currentTarget, hostAddress, initializeClients);
    }, [clientsManager.usersClient, hostAddress, initializeClients]);

    return (
        <div className="flex h-full items-center justify-center bg-login-screen">
            <section className="flex h-main-modal-width">
                <div className="flex flex-col items-center justify-center rounded-l-lg bg-white p-5">
                    <div className="my-2">
                        <img alt="" src={loginLogo} />
                    </div>
                    <div className="mb-3">
                        <span>{version} {packages.version}</span>
                    </div>
                    <Form onSubmit={handleSubmitForm} className="flex w-login-form flex-col gap-y-2.5">
                        <Input {...LoginFormModel.username} disabled={loginStatus.resetPassRequired} />
                        <PasswordInputField className="rounded " innerClassName="h-1.5" model={LoginFormModel.password} disabled={loginStatus.resetPassRequired} />
                        {loginStatus.resetPassRequired &&
                            <div>
                                <div className="mb-2 border border-gray-300"></div>
                                <PasswordInputField className="rounded" innerClassName="h-1.5" model={LoginFormModel.newPassword} />
                                <div className="mt-1" />
                                <PasswordInputField className="rounded" innerClassName="h-1.5" model={LoginFormModel.confirmNewPassword} />
                            </div>
                        }
                        <div className="ml-2 flex w-80 justify-center">
                            {loginStatus.errorMsg && <span className="text-center text-sm text-red-600">{loginStatus.errorMsg}</span>}
                        </div>
                        <div className=" flex h-20 flex-col items-center justify-center">
                            <BaseModalOld buttonText={privacyConsent} buttonColor="link" getBody={getsystemSettings} title={privacyNoticeTitle} footerButtonText={eula} buttonLink={eulaPdf} underlineButton={true} />
                            <Button variant="outlined" className="rounded bg-oceanic-azure p-2.5 px-4 py-2 font-bold text-white hover:bg-pie-line-bright-blue" type="submit" size="small">
                                {loginButton}
                            </Button>
                        </div>
                    </Form>
                </div>
                <div className="flex size-main-modal-width">
                    <img src={loginGif} className="rounded-r-lg" />
                </div>
            </section>
        </div>
    );
}

export default Login;