"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AddAppearanceParams", {
  enumerable: true,
  get: function () {
    return _AddAppearanceParams.default;
  }
});
Object.defineProperty(exports, "AddDetectedFaceResponse", {
  enumerable: true,
  get: function () {
    return _AddDetectedFaceResponse.default;
  }
});
Object.defineProperty(exports, "AddDetectedFacesResponseModel", {
  enumerable: true,
  get: function () {
    return _AddDetectedFacesResponseModel.default;
  }
});
Object.defineProperty(exports, "AddEventParams", {
  enumerable: true,
  get: function () {
    return _AddEventParams.default;
  }
});
Object.defineProperty(exports, "AddFaceParams", {
  enumerable: true,
  get: function () {
    return _AddFaceParams.default;
  }
});
Object.defineProperty(exports, "AddPOIFace", {
  enumerable: true,
  get: function () {
    return _AddPOIFace.default;
  }
});
Object.defineProperty(exports, "AnalyzeRequest", {
  enumerable: true,
  get: function () {
    return _AnalyzeRequest.default;
  }
});
Object.defineProperty(exports, "AnalyzeResponse", {
  enumerable: true,
  get: function () {
    return _AnalyzeResponse.default;
  }
});
Object.defineProperty(exports, "AnalyzeResult", {
  enumerable: true,
  get: function () {
    return _AnalyzeResult.default;
  }
});
Object.defineProperty(exports, "ApiClient", {
  enumerable: true,
  get: function () {
    return _ApiClient.default;
  }
});
Object.defineProperty(exports, "AppearanceData", {
  enumerable: true,
  get: function () {
    return _AppearanceData.default;
  }
});
Object.defineProperty(exports, "AppearanceEventObject", {
  enumerable: true,
  get: function () {
    return _AppearanceEventObject.default;
  }
});
Object.defineProperty(exports, "AppearanceFrameData", {
  enumerable: true,
  get: function () {
    return _AppearanceFrameData.default;
  }
});
Object.defineProperty(exports, "BasicPOIData", {
  enumerable: true,
  get: function () {
    return _BasicPOIData.default;
  }
});
Object.defineProperty(exports, "BatchMsgMetadata", {
  enumerable: true,
  get: function () {
    return _BatchMsgMetadata.default;
  }
});
Object.defineProperty(exports, "BatchPOIIDResponse", {
  enumerable: true,
  get: function () {
    return _BatchPOIIDResponse.default;
  }
});
Object.defineProperty(exports, "BatchPOIsRequest", {
  enumerable: true,
  get: function () {
    return _BatchPOIsRequest.default;
  }
});
Object.defineProperty(exports, "BatchResponse", {
  enumerable: true,
  get: function () {
    return _BatchResponse.default;
  }
});
Object.defineProperty(exports, "BatchSuccessData", {
  enumerable: true,
  get: function () {
    return _BatchSuccessData.default;
  }
});
Object.defineProperty(exports, "BodyClearWatchlist", {
  enumerable: true,
  get: function () {
    return _BodyClearWatchlist.default;
  }
});
Object.defineProperty(exports, "BoundingBox", {
  enumerable: true,
  get: function () {
    return _BoundingBox.default;
  }
});
Object.defineProperty(exports, "CameraData", {
  enumerable: true,
  get: function () {
    return _CameraData.default;
  }
});
Object.defineProperty(exports, "CameraGroupData", {
  enumerable: true,
  get: function () {
    return _CameraGroupData.default;
  }
});
Object.defineProperty(exports, "ChangeWLPOIsRequest", {
  enumerable: true,
  get: function () {
    return _ChangeWLPOIsRequest.default;
  }
});
Object.defineProperty(exports, "CompareRequest", {
  enumerable: true,
  get: function () {
    return _CompareRequest.default;
  }
});
Object.defineProperty(exports, "CompareResponse", {
  enumerable: true,
  get: function () {
    return _CompareResponse.default;
  }
});
Object.defineProperty(exports, "CompareResult", {
  enumerable: true,
  get: function () {
    return _CompareResult.default;
  }
});
Object.defineProperty(exports, "ConsentValue", {
  enumerable: true,
  get: function () {
    return _ConsentValue.default;
  }
});
Object.defineProperty(exports, "CreateFacesRequest", {
  enumerable: true,
  get: function () {
    return _CreateFacesRequest.default;
  }
});
Object.defineProperty(exports, "CreatePOIData", {
  enumerable: true,
  get: function () {
    return _CreatePOIData.default;
  }
});
Object.defineProperty(exports, "CreatePOIsRequest", {
  enumerable: true,
  get: function () {
    return _CreatePOIsRequest.default;
  }
});
Object.defineProperty(exports, "CreatePOIsResponse", {
  enumerable: true,
  get: function () {
    return _CreatePOIsResponse.default;
  }
});
Object.defineProperty(exports, "CreatePOIsResponseData", {
  enumerable: true,
  get: function () {
    return _CreatePOIsResponseData.default;
  }
});
Object.defineProperty(exports, "CropData", {
  enumerable: true,
  get: function () {
    return _CropData.default;
  }
});
Object.defineProperty(exports, "DetectRequest", {
  enumerable: true,
  get: function () {
    return _DetectRequest.default;
  }
});
Object.defineProperty(exports, "Detection", {
  enumerable: true,
  get: function () {
    return _Detection.default;
  }
});
Object.defineProperty(exports, "DetectionResponse", {
  enumerable: true,
  get: function () {
    return _DetectionResponse.default;
  }
});
Object.defineProperty(exports, "DetectionResult", {
  enumerable: true,
  get: function () {
    return _DetectionResult.default;
  }
});
Object.defineProperty(exports, "DisplayName", {
  enumerable: true,
  get: function () {
    return _DisplayName.default;
  }
});
Object.defineProperty(exports, "EventType", {
  enumerable: true,
  get: function () {
    return _EventType.default;
  }
});
Object.defineProperty(exports, "FaceAnalysisAttributes", {
  enumerable: true,
  get: function () {
    return _FaceAnalysisAttributes.default;
  }
});
Object.defineProperty(exports, "FaceApi", {
  enumerable: true,
  get: function () {
    return _FaceApi.default;
  }
});
Object.defineProperty(exports, "FaceAttribute", {
  enumerable: true,
  get: function () {
    return _FaceAttribute.default;
  }
});
Object.defineProperty(exports, "FaceDataResult", {
  enumerable: true,
  get: function () {
    return _FaceDataResult.default;
  }
});
Object.defineProperty(exports, "FaceFeaturesData", {
  enumerable: true,
  get: function () {
    return _FaceFeaturesData.default;
  }
});
Object.defineProperty(exports, "FacesListRequest", {
  enumerable: true,
  get: function () {
    return _FacesListRequest.default;
  }
});
Object.defineProperty(exports, "FacesListResponse", {
  enumerable: true,
  get: function () {
    return _FacesListResponse.default;
  }
});
Object.defineProperty(exports, "GeneralResponse", {
  enumerable: true,
  get: function () {
    return _GeneralResponse.default;
  }
});
Object.defineProperty(exports, "GetFacesRequest", {
  enumerable: true,
  get: function () {
    return _GetFacesRequest.default;
  }
});
Object.defineProperty(exports, "GetPOIsList", {
  enumerable: true,
  get: function () {
    return _GetPOIsList.default;
  }
});
Object.defineProperty(exports, "GetPOIsRequest", {
  enumerable: true,
  get: function () {
    return _GetPOIsRequest.default;
  }
});
Object.defineProperty(exports, "GetPOIsResponse", {
  enumerable: true,
  get: function () {
    return _GetPOIsResponse.default;
  }
});
Object.defineProperty(exports, "GetWatchlistAndPOIsResponse", {
  enumerable: true,
  get: function () {
    return _GetWatchlistAndPOIsResponse.default;
  }
});
Object.defineProperty(exports, "GetWatchlistResponse", {
  enumerable: true,
  get: function () {
    return _GetWatchlistResponse.default;
  }
});
Object.defineProperty(exports, "HTTPValidationError", {
  enumerable: true,
  get: function () {
    return _HTTPValidationError.default;
  }
});
Object.defineProperty(exports, "ImagePayload", {
  enumerable: true,
  get: function () {
    return _ImagePayload.default;
  }
});
Object.defineProperty(exports, "ImgParameter", {
  enumerable: true,
  get: function () {
    return _ImgParameter.default;
  }
});
Object.defineProperty(exports, "ListAllPOIs", {
  enumerable: true,
  get: function () {
    return _ListAllPOIs.default;
  }
});
Object.defineProperty(exports, "ListAllPOIsResponse", {
  enumerable: true,
  get: function () {
    return _ListAllPOIsResponse.default;
  }
});
Object.defineProperty(exports, "ListWatchlistsResponse", {
  enumerable: true,
  get: function () {
    return _ListWatchlistsResponse.default;
  }
});
Object.defineProperty(exports, "MatchData", {
  enumerable: true,
  get: function () {
    return _MatchData.default;
  }
});
Object.defineProperty(exports, "MatchOutcomes", {
  enumerable: true,
  get: function () {
    return _MatchOutcomes.default;
  }
});
Object.defineProperty(exports, "MatchedFace", {
  enumerable: true,
  get: function () {
    return _MatchedFace.default;
  }
});
Object.defineProperty(exports, "MatchedPOI", {
  enumerable: true,
  get: function () {
    return _MatchedPOI.default;
  }
});
Object.defineProperty(exports, "MsgMetadata", {
  enumerable: true,
  get: function () {
    return _MsgMetadata.default;
  }
});
Object.defineProperty(exports, "Notes", {
  enumerable: true,
  get: function () {
    return _Notes.default;
  }
});
Object.defineProperty(exports, "POIAPIRecord", {
  enumerable: true,
  get: function () {
    return _POIAPIRecord.default;
  }
});
Object.defineProperty(exports, "POIApi", {
  enumerable: true,
  get: function () {
    return _POIApi.default;
  }
});
Object.defineProperty(exports, "POIBatchData", {
  enumerable: true,
  get: function () {
    return _POIBatchData.default;
  }
});
Object.defineProperty(exports, "POIBatchMsgMetadata", {
  enumerable: true,
  get: function () {
    return _POIBatchMsgMetadata.default;
  }
});
Object.defineProperty(exports, "POIConsentData", {
  enumerable: true,
  get: function () {
    return _POIConsentData.default;
  }
});
Object.defineProperty(exports, "POIDataWithImage", {
  enumerable: true,
  get: function () {
    return _POIDataWithImage.default;
  }
});
Object.defineProperty(exports, "POIDatabaseApi", {
  enumerable: true,
  get: function () {
    return _POIDatabaseApi.default;
  }
});
Object.defineProperty(exports, "POIFace", {
  enumerable: true,
  get: function () {
    return _POIFace.default;
  }
});
Object.defineProperty(exports, "POIUpdateParameters", {
  enumerable: true,
  get: function () {
    return _POIUpdateParameters.default;
  }
});
Object.defineProperty(exports, "RecordFaceAnalysisAttributes", {
  enumerable: true,
  get: function () {
    return _RecordFaceAnalysisAttributes.default;
  }
});
Object.defineProperty(exports, "SearchFacesInImage", {
  enumerable: true,
  get: function () {
    return _SearchFacesInImage.default;
  }
});
Object.defineProperty(exports, "SearchFacesResponse", {
  enumerable: true,
  get: function () {
    return _SearchFacesResponse.default;
  }
});
Object.defineProperty(exports, "SearchPOIsFacesInImageResponse", {
  enumerable: true,
  get: function () {
    return _SearchPOIsFacesInImageResponse.default;
  }
});
Object.defineProperty(exports, "SearchPOIsRequest", {
  enumerable: true,
  get: function () {
    return _SearchPOIsRequest.default;
  }
});
Object.defineProperty(exports, "SearchPOIsResponse", {
  enumerable: true,
  get: function () {
    return _SearchPOIsResponse.default;
  }
});
Object.defineProperty(exports, "SearchPOIsResponseData", {
  enumerable: true,
  get: function () {
    return _SearchPOIsResponseData.default;
  }
});
Object.defineProperty(exports, "SearchRequest", {
  enumerable: true,
  get: function () {
    return _SearchRequest.default;
  }
});
Object.defineProperty(exports, "SearchResponse", {
  enumerable: true,
  get: function () {
    return _SearchResponse.default;
  }
});
Object.defineProperty(exports, "SearchResult", {
  enumerable: true,
  get: function () {
    return _SearchResult.default;
  }
});
Object.defineProperty(exports, "StreamApi", {
  enumerable: true,
  get: function () {
    return _StreamApi.default;
  }
});
Object.defineProperty(exports, "Trigger", {
  enumerable: true,
  get: function () {
    return _Trigger.default;
  }
});
Object.defineProperty(exports, "UpdatePOIResponse", {
  enumerable: true,
  get: function () {
    return _UpdatePOIResponse.default;
  }
});
Object.defineProperty(exports, "ValidationError", {
  enumerable: true,
  get: function () {
    return _ValidationError.default;
  }
});
Object.defineProperty(exports, "Watchlist", {
  enumerable: true,
  get: function () {
    return _Watchlist.default;
  }
});
Object.defineProperty(exports, "WatchlistAndCount", {
  enumerable: true,
  get: function () {
    return _WatchlistAndCount.default;
  }
});
Object.defineProperty(exports, "WatchlistAndPOIs", {
  enumerable: true,
  get: function () {
    return _WatchlistAndPOIs.default;
  }
});
Object.defineProperty(exports, "WatchlistApi", {
  enumerable: true,
  get: function () {
    return _WatchlistApi.default;
  }
});
Object.defineProperty(exports, "WatchlistObject", {
  enumerable: true,
  get: function () {
    return _WatchlistObject.default;
  }
});
Object.defineProperty(exports, "WatchlistParameters", {
  enumerable: true,
  get: function () {
    return _WatchlistParameters.default;
  }
});
Object.defineProperty(exports, "WatchlistTypes", {
  enumerable: true,
  get: function () {
    return _WatchlistTypes.default;
  }
});
Object.defineProperty(exports, "WatchlistUpdateParameters", {
  enumerable: true,
  get: function () {
    return _WatchlistUpdateParameters.default;
  }
});
Object.defineProperty(exports, "WatchlistsList", {
  enumerable: true,
  get: function () {
    return _WatchlistsList.default;
  }
});
var _ApiClient = _interopRequireDefault(require("./ApiClient"));
var _AddAppearanceParams = _interopRequireDefault(require("./model/AddAppearanceParams"));
var _AddDetectedFaceResponse = _interopRequireDefault(require("./model/AddDetectedFaceResponse"));
var _AddDetectedFacesResponseModel = _interopRequireDefault(require("./model/AddDetectedFacesResponseModel"));
var _AddEventParams = _interopRequireDefault(require("./model/AddEventParams"));
var _AddFaceParams = _interopRequireDefault(require("./model/AddFaceParams"));
var _AddPOIFace = _interopRequireDefault(require("./model/AddPOIFace"));
var _AnalyzeRequest = _interopRequireDefault(require("./model/AnalyzeRequest"));
var _AnalyzeResponse = _interopRequireDefault(require("./model/AnalyzeResponse"));
var _AnalyzeResult = _interopRequireDefault(require("./model/AnalyzeResult"));
var _AppearanceData = _interopRequireDefault(require("./model/AppearanceData"));
var _AppearanceEventObject = _interopRequireDefault(require("./model/AppearanceEventObject"));
var _AppearanceFrameData = _interopRequireDefault(require("./model/AppearanceFrameData"));
var _BasicPOIData = _interopRequireDefault(require("./model/BasicPOIData"));
var _BatchMsgMetadata = _interopRequireDefault(require("./model/BatchMsgMetadata"));
var _BatchPOIIDResponse = _interopRequireDefault(require("./model/BatchPOIIDResponse"));
var _BatchPOIsRequest = _interopRequireDefault(require("./model/BatchPOIsRequest"));
var _BatchResponse = _interopRequireDefault(require("./model/BatchResponse"));
var _BatchSuccessData = _interopRequireDefault(require("./model/BatchSuccessData"));
var _BodyClearWatchlist = _interopRequireDefault(require("./model/BodyClearWatchlist"));
var _BoundingBox = _interopRequireDefault(require("./model/BoundingBox"));
var _CameraData = _interopRequireDefault(require("./model/CameraData"));
var _CameraGroupData = _interopRequireDefault(require("./model/CameraGroupData"));
var _ChangeWLPOIsRequest = _interopRequireDefault(require("./model/ChangeWLPOIsRequest"));
var _CompareRequest = _interopRequireDefault(require("./model/CompareRequest"));
var _CompareResponse = _interopRequireDefault(require("./model/CompareResponse"));
var _CompareResult = _interopRequireDefault(require("./model/CompareResult"));
var _ConsentValue = _interopRequireDefault(require("./model/ConsentValue"));
var _CreateFacesRequest = _interopRequireDefault(require("./model/CreateFacesRequest"));
var _CreatePOIData = _interopRequireDefault(require("./model/CreatePOIData"));
var _CreatePOIsRequest = _interopRequireDefault(require("./model/CreatePOIsRequest"));
var _CreatePOIsResponse = _interopRequireDefault(require("./model/CreatePOIsResponse"));
var _CreatePOIsResponseData = _interopRequireDefault(require("./model/CreatePOIsResponseData"));
var _CropData = _interopRequireDefault(require("./model/CropData"));
var _DetectRequest = _interopRequireDefault(require("./model/DetectRequest"));
var _Detection = _interopRequireDefault(require("./model/Detection"));
var _DetectionResponse = _interopRequireDefault(require("./model/DetectionResponse"));
var _DetectionResult = _interopRequireDefault(require("./model/DetectionResult"));
var _DisplayName = _interopRequireDefault(require("./model/DisplayName"));
var _EventType = _interopRequireDefault(require("./model/EventType"));
var _FaceAnalysisAttributes = _interopRequireDefault(require("./model/FaceAnalysisAttributes"));
var _FaceAttribute = _interopRequireDefault(require("./model/FaceAttribute"));
var _FaceDataResult = _interopRequireDefault(require("./model/FaceDataResult"));
var _FaceFeaturesData = _interopRequireDefault(require("./model/FaceFeaturesData"));
var _FacesListRequest = _interopRequireDefault(require("./model/FacesListRequest"));
var _FacesListResponse = _interopRequireDefault(require("./model/FacesListResponse"));
var _GeneralResponse = _interopRequireDefault(require("./model/GeneralResponse"));
var _GetFacesRequest = _interopRequireDefault(require("./model/GetFacesRequest"));
var _GetPOIsList = _interopRequireDefault(require("./model/GetPOIsList"));
var _GetPOIsRequest = _interopRequireDefault(require("./model/GetPOIsRequest"));
var _GetPOIsResponse = _interopRequireDefault(require("./model/GetPOIsResponse"));
var _GetWatchlistAndPOIsResponse = _interopRequireDefault(require("./model/GetWatchlistAndPOIsResponse"));
var _GetWatchlistResponse = _interopRequireDefault(require("./model/GetWatchlistResponse"));
var _HTTPValidationError = _interopRequireDefault(require("./model/HTTPValidationError"));
var _ImagePayload = _interopRequireDefault(require("./model/ImagePayload"));
var _ImgParameter = _interopRequireDefault(require("./model/ImgParameter"));
var _ListAllPOIs = _interopRequireDefault(require("./model/ListAllPOIs"));
var _ListAllPOIsResponse = _interopRequireDefault(require("./model/ListAllPOIsResponse"));
var _ListWatchlistsResponse = _interopRequireDefault(require("./model/ListWatchlistsResponse"));
var _MatchData = _interopRequireDefault(require("./model/MatchData"));
var _MatchOutcomes = _interopRequireDefault(require("./model/MatchOutcomes"));
var _MatchedFace = _interopRequireDefault(require("./model/MatchedFace"));
var _MatchedPOI = _interopRequireDefault(require("./model/MatchedPOI"));
var _MsgMetadata = _interopRequireDefault(require("./model/MsgMetadata"));
var _Notes = _interopRequireDefault(require("./model/Notes"));
var _POIAPIRecord = _interopRequireDefault(require("./model/POIAPIRecord"));
var _POIBatchData = _interopRequireDefault(require("./model/POIBatchData"));
var _POIBatchMsgMetadata = _interopRequireDefault(require("./model/POIBatchMsgMetadata"));
var _POIConsentData = _interopRequireDefault(require("./model/POIConsentData"));
var _POIDataWithImage = _interopRequireDefault(require("./model/POIDataWithImage"));
var _POIFace = _interopRequireDefault(require("./model/POIFace"));
var _POIUpdateParameters = _interopRequireDefault(require("./model/POIUpdateParameters"));
var _RecordFaceAnalysisAttributes = _interopRequireDefault(require("./model/RecordFaceAnalysisAttributes"));
var _SearchFacesInImage = _interopRequireDefault(require("./model/SearchFacesInImage"));
var _SearchFacesResponse = _interopRequireDefault(require("./model/SearchFacesResponse"));
var _SearchPOIsFacesInImageResponse = _interopRequireDefault(require("./model/SearchPOIsFacesInImageResponse"));
var _SearchPOIsRequest = _interopRequireDefault(require("./model/SearchPOIsRequest"));
var _SearchPOIsResponse = _interopRequireDefault(require("./model/SearchPOIsResponse"));
var _SearchPOIsResponseData = _interopRequireDefault(require("./model/SearchPOIsResponseData"));
var _SearchRequest = _interopRequireDefault(require("./model/SearchRequest"));
var _SearchResponse = _interopRequireDefault(require("./model/SearchResponse"));
var _SearchResult = _interopRequireDefault(require("./model/SearchResult"));
var _Trigger = _interopRequireDefault(require("./model/Trigger"));
var _UpdatePOIResponse = _interopRequireDefault(require("./model/UpdatePOIResponse"));
var _ValidationError = _interopRequireDefault(require("./model/ValidationError"));
var _Watchlist = _interopRequireDefault(require("./model/Watchlist"));
var _WatchlistAndCount = _interopRequireDefault(require("./model/WatchlistAndCount"));
var _WatchlistAndPOIs = _interopRequireDefault(require("./model/WatchlistAndPOIs"));
var _WatchlistObject = _interopRequireDefault(require("./model/WatchlistObject"));
var _WatchlistParameters = _interopRequireDefault(require("./model/WatchlistParameters"));
var _WatchlistTypes = _interopRequireDefault(require("./model/WatchlistTypes"));
var _WatchlistUpdateParameters = _interopRequireDefault(require("./model/WatchlistUpdateParameters"));
var _WatchlistsList = _interopRequireDefault(require("./model/WatchlistsList"));
var _FaceApi = _interopRequireDefault(require("./api/FaceApi"));
var _POIApi = _interopRequireDefault(require("./api/POIApi"));
var _POIDatabaseApi = _interopRequireDefault(require("./api/POIDatabaseApi"));
var _StreamApi = _interopRequireDefault(require("./api/StreamApi"));
var _WatchlistApi = _interopRequireDefault(require("./api/WatchlistApi"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }