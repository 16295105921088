"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _UserContactInfo = _interopRequireDefault(require("./UserContactInfo"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The User model module.
 * @module model/User
 * @version 0.35.0
 */
class User {
  /**
   * Constructs a new <code>User</code>.
   * @alias module:model/User
   * @param userId {String} user id
   * @param name {String} a unique user name used to log in
   * @param roleId {String} an assigned role for this user
   */
  constructor(userId, name, roleId) {
    User.initialize(this, userId, name, roleId);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, userId, name, roleId) {
    obj['user_id'] = userId;
    obj['name'] = name;
    obj['role_id'] = roleId;
  }

  /**
   * Constructs a <code>User</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/User} obj Optional instance to populate.
   * @return {module:model/User} The populated <code>User</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new User();
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('role_id')) {
        obj['role_id'] = _ApiClient.default.convertToType(data['role_id'], 'String');
      }
      if (data.hasOwnProperty('active')) {
        obj['active'] = _ApiClient.default.convertToType(data['active'], 'Boolean');
      }
      if (data.hasOwnProperty('password_reset_required')) {
        obj['password_reset_required'] = _ApiClient.default.convertToType(data['password_reset_required'], 'Boolean');
      }
      if (data.hasOwnProperty('user_contact_info')) {
        obj['user_contact_info'] = _UserContactInfo.default.constructFromObject(data['user_contact_info']);
      }
      if (data.hasOwnProperty('last_session_identifier')) {
        obj['last_session_identifier'] = _ApiClient.default.convertToType(data['last_session_identifier'], 'String');
      }
      if (data.hasOwnProperty('create_time_utc')) {
        obj['create_time_utc'] = _ApiClient.default.convertToType(data['create_time_utc'], 'Number');
      }
      if (data.hasOwnProperty('system_user')) {
        obj['system_user'] = _ApiClient.default.convertToType(data['system_user'], 'Boolean');
      }
      if (data.hasOwnProperty('token')) {
        obj['token'] = _ApiClient.default.convertToType(data['token'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = _ApiClient.default.convertToType(data['status'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>User</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>User</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of User.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['role_id'] && !(typeof data['role_id'] === 'string' || data['role_id'] instanceof String)) {
      throw new Error("Expected the field `role_id` to be a primitive type in the JSON string but got " + data['role_id']);
    }
    // validate the optional field `user_contact_info`
    if (data['user_contact_info']) {
      // data not null
      _UserContactInfo.default.validateJSON(data['user_contact_info']);
    }
    // ensure the json data is a string
    if (data['last_session_identifier'] && !(typeof data['last_session_identifier'] === 'string' || data['last_session_identifier'] instanceof String)) {
      throw new Error("Expected the field `last_session_identifier` to be a primitive type in the JSON string but got " + data['last_session_identifier']);
    }
    // ensure the json data is a string
    if (data['token'] && !(typeof data['token'] === 'string' || data['token'] instanceof String)) {
      throw new Error("Expected the field `token` to be a primitive type in the JSON string but got " + data['token']);
    }
    // ensure the json data is a string
    if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
      throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
    }
    return true;
  }
}
User.RequiredProperties = ["user_id", "name", "role_id"];

/**
 * user id
 * @member {String} user_id
 */
User.prototype['user_id'] = undefined;

/**
 * a unique user name used to log in
 * @member {String} name
 */
User.prototype['name'] = undefined;

/**
 * an assigned role for this user
 * @member {String} role_id
 */
User.prototype['role_id'] = undefined;

/**
 * whether or not user is disabled (allowed to login at all)
 * @member {Boolean} active
 * @default true
 */
User.prototype['active'] = true;

/**
 * should the user reset their password on their next login
 * @member {Boolean} password_reset_required
 * @default true
 */
User.prototype['password_reset_required'] = true;

/**
 * @member {module:model/UserContactInfo} user_contact_info
 */
User.prototype['user_contact_info'] = undefined;

/**
 * A unique identifier for a logged in user session
 * @member {String} last_session_identifier
 */
User.prototype['last_session_identifier'] = undefined;

/**
 * timestamp of the user's creation time
 * @member {Number} create_time_utc
 */
User.prototype['create_time_utc'] = undefined;

/**
 * Flag for users created by the system
 * @member {Boolean} system_user
 * @default false
 */
User.prototype['system_user'] = false;

/**
 * the access_token with which the user is currently logged in. If user is not logged in, this is null.
 * @member {String} token
 */
User.prototype['token'] = undefined;

/**
 * whether or not the user is currently logged in. Options: ['logged in', 'logged out']
 * @member {String} status
 */
User.prototype['status'] = undefined;
var _default = exports.default = User;