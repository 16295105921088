"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AppearanceData = _interopRequireDefault(require("./AppearanceData"));
var _AppearanceFrameData = _interopRequireDefault(require("./AppearanceFrameData"));
var _CameraData = _interopRequireDefault(require("./CameraData"));
var _CropData = _interopRequireDefault(require("./CropData"));
var _FaceFeaturesData = _interopRequireDefault(require("./FaceFeaturesData"));
var _MatchData = _interopRequireDefault(require("./MatchData"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AppearanceEventObject model module.
 * @module model/AppearanceEventObject
 * @version 0.37.1
 */
class AppearanceEventObject {
  /**
   * Constructs a new <code>AppearanceEventObject</code>.
   * @alias module:model/AppearanceEventObject
   */
  constructor() {
    AppearanceEventObject.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>AppearanceEventObject</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AppearanceEventObject} obj Optional instance to populate.
   * @return {module:model/AppearanceEventObject} The populated <code>AppearanceEventObject</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AppearanceEventObject();
      if (data.hasOwnProperty('event_type')) {
        obj['event_type'] = _ApiClient.default.convertToType(data['event_type'], Object);
      }
      if (data.hasOwnProperty('event_id')) {
        obj['event_id'] = _ApiClient.default.convertToType(data['event_id'], Object);
      }
      if (data.hasOwnProperty('msg_send_timestamp')) {
        obj['msg_send_timestamp'] = _ApiClient.default.convertToType(data['msg_send_timestamp'], Object);
      }
      if (data.hasOwnProperty('camera_data')) {
        obj['camera_data'] = _CameraData.default.constructFromObject(data['camera_data']);
      }
      if (data.hasOwnProperty('frame_data')) {
        obj['frame_data'] = _AppearanceFrameData.default.constructFromObject(data['frame_data']);
      }
      if (data.hasOwnProperty('appearance_data')) {
        obj['appearance_data'] = _AppearanceData.default.constructFromObject(data['appearance_data']);
      }
      if (data.hasOwnProperty('crop_data')) {
        obj['crop_data'] = _CropData.default.constructFromObject(data['crop_data']);
      }
      if (data.hasOwnProperty('match_data')) {
        obj['match_data'] = _MatchData.default.constructFromObject(data['match_data']);
      }
      if (data.hasOwnProperty('face_features_data')) {
        obj['face_features_data'] = _FaceFeaturesData.default.constructFromObject(data['face_features_data']);
      }
      if (data.hasOwnProperty('updates')) {
        obj['updates'] = _ApiClient.default.convertToType(data['updates'], Object);
      }
      if (data.hasOwnProperty('trigger')) {
        obj['trigger'] = _ApiClient.default.convertToType(data['trigger'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AppearanceEventObject</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AppearanceEventObject</code>.
   */
  static validateJSON(data) {
    // validate the optional field `camera_data`
    if (data['camera_data']) {
      // data not null
      _CameraData.default.validateJSON(data['camera_data']);
    }
    // validate the optional field `frame_data`
    if (data['frame_data']) {
      // data not null
      _AppearanceFrameData.default.validateJSON(data['frame_data']);
    }
    // validate the optional field `appearance_data`
    if (data['appearance_data']) {
      // data not null
      _AppearanceData.default.validateJSON(data['appearance_data']);
    }
    // validate the optional field `crop_data`
    if (data['crop_data']) {
      // data not null
      _CropData.default.validateJSON(data['crop_data']);
    }
    // validate the optional field `match_data`
    if (data['match_data']) {
      // data not null
      _MatchData.default.validateJSON(data['match_data']);
    }
    // validate the optional field `face_features_data`
    if (data['face_features_data']) {
      // data not null
      _FaceFeaturesData.default.validateJSON(data['face_features_data']);
    }
    return true;
  }
}

/**
 * type of this event
 * @member {Object} event_type
 */
AppearanceEventObject.prototype['event_type'] = undefined;

/**
 * a unique id of this event
 * @member {Object} event_id
 */
AppearanceEventObject.prototype['event_id'] = undefined;

/**
 * @member {Object} msg_send_timestamp
 */
AppearanceEventObject.prototype['msg_send_timestamp'] = undefined;

/**
 * @member {module:model/CameraData} camera_data
 */
AppearanceEventObject.prototype['camera_data'] = undefined;

/**
 * @member {module:model/AppearanceFrameData} frame_data
 */
AppearanceEventObject.prototype['frame_data'] = undefined;

/**
 * @member {module:model/AppearanceData} appearance_data
 */
AppearanceEventObject.prototype['appearance_data'] = undefined;

/**
 * @member {module:model/CropData} crop_data
 */
AppearanceEventObject.prototype['crop_data'] = undefined;

/**
 * @member {module:model/MatchData} match_data
 */
AppearanceEventObject.prototype['match_data'] = undefined;

/**
 * @member {module:model/FaceFeaturesData} face_features_data
 */
AppearanceEventObject.prototype['face_features_data'] = undefined;

/**
 * **Contains the fields that were _meaningfully updated_ since the last sent events. Is empty on the event that starts the appearance (trigger 0) and the event that ends it (trigger 2). The changed fields name will appear in the correct hierarchy, i.e. if the field `event['match_data']['poi_confidence']` is updated in this event, the 'updates' dict will be `{'match_data': ['poi_confidence']}`**
 * @member {Object} updates
 */
AppearanceEventObject.prototype['updates'] = undefined;

/**
 * The reason this tracker data is outputted:['0=appearance_start', '1=forced_update', '2=appearance_end', '3=periodic_update']
 * @member {Object} trigger
 */
AppearanceEventObject.prototype['trigger'] = undefined;
var _default = exports.default = AppearanceEventObject;