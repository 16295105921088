import { alertingAppearanceDurationLoiteringInfo, alertingAppearanceDurationTxt } from "src/appConfig/Strings";
import LabelWrapper from "@/Components/Common/Wrappers/LabelWrapper";
import { loiteringFormFieldsNames, LoiteringInsightFormModel } from "./LoiteringInsight.model";
import { DurationSelector } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector";
import { minSecTimeMapping } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector.model";
import InsightCamerasSelector from "../../InsightCamerasSelector/InsightCamerasSelector";

const { alertingLoiteringDuration } = loiteringFormFieldsNames;

function LoiteringInsight({ formData, onFieldChange }) {
    return (
        <div className="flex flex-col gap-1">
            <InsightCamerasSelector model={LoiteringInsightFormModel} formData={formData} onFieldChange={onFieldChange} />
            <LabelWrapper label={alertingAppearanceDurationTxt} tooltipTxt={alertingAppearanceDurationLoiteringInfo} >
                <DurationSelector
                    model={LoiteringInsightFormModel[alertingLoiteringDuration]}
                    value={formData[alertingLoiteringDuration]}
                    onChange={(value) => onFieldChange(alertingLoiteringDuration, value)}
                    timeUnitsMap={minSecTimeMapping}
                />
            </LabelWrapper>
        </div>
    );
}

export default LoiteringInsight;