import { createSlice } from "@reduxjs/toolkit";

import { filesNotFoundInImageListTitle, filesNotFoundInXlsListTitle, invalidXlsFileTitle } from "../../../../appConfig/Strings";

export const runnersNames = {
    poiImporter: "poiImporter",
    camerasStarter: "camerasStarter"
};

const baseRunnerInitialState = {
    showFloatingModal: false,
    inWork: false,
    finished: false,
    progress: 0,
    responses: [],
    sum: 0,
    remainingTime: 0,
    sumFailedImages: 0,
    successfulUploadedImage: 0,
    shouldShowWorkersNumberAlert: false
};

const initialState = {
    inViewImages: {},
    [runnersNames.poiImporter]: {
        ...baseRunnerInitialState,
        saveImages: false
    },
    [runnersNames.camerasStarter]: {
        ...baseRunnerInitialState
    }
};

const baseRunnersSlice = createSlice({
    name: "baseRunners",
    initialState,
    reducers: {
        runnerSetSum: (state, action) => {
            const { runnerName, sum } = action.payload;
            state[runnerName].sum = sum;
            return state;
        },
        startRunner: (state, action) => {
            const { runnerName } = action.payload;
            state[runnerName].inWork = true;
            state[runnerName].finished = false;
            state[runnerName].responses = [];
            state[runnerName].progress = 0;
            state[runnerName].remainingTime = 0;
            state[runnerName].sumFailedImages = 0;
            state[runnerName].showFloatingModal = true;
            return state;
        },
        stopPoiImporter: (state, action) => {
            const [responses, failedLength, totalProcessedPois, successfulUploadedImage, filesNotFound, saveImages, errMsg] = action.payload;
            state.poiImporter.inWork = false;
            state.poiImporter.responses = filesNotFound.notInImagesList || filesNotFound.notInXlsList ? {
                ...responses,
                [filesNotFoundInImageListTitle]: filesNotFound.notInImagesList,
                [filesNotFoundInXlsListTitle]: filesNotFound.notInXlsList,
                [invalidXlsFileTitle]: filesNotFound.invalidPoisXls
            } : responses;
            state.poiImporter.sumFailedImages = failedLength;
            state.poiImporter.finished = true;
            state.poiImporter.totalProcessedPois = totalProcessedPois;
            state.poiImporter.successfulUploadedImage = successfulUploadedImage;
            state.poiImporter.progress = 0;
            state.poiImporter.remainingTime = 0;
            state.poiImporter.saveImages = saveImages;
            state.poiImporter.errMsg = errMsg;
            return state;
        },
        stopRunner: (state, action) => {
            const runnerName = action.payload;
            state[runnerName].inWork = false;
            state[runnerName].finished = true;
        },
        updateRunnerProgress: (state, action) => {
            const { runnerName, updateData: [progress] } = action.payload;
            state[runnerName].progress = progress;
            return state;
        },
        abortRunner: (state, action) => {
            const { runnerName } = action.payload;
            state[runnerName].finished = false;
            state[runnerName].inWork = false;
            return state;
        },
        closeFloatingModal: (state, action) => {
            const { runnerName } = action.payload;
            state[runnerName].showFloatingModal = false;
            return state;
        },
        openFloatingModal: (state, action) => {
            const { runnerName } = action.payload;
            state[runnerName].showFloatingModal = true;
            return state;
        },
        updateTimer: (state, action) => {
            const { runnerName, updateData: [remandingImages, requestTime] } = action.payload;
            state[runnerName].remainingTime = (state[runnerName].sum - remandingImages) * requestTime;
            return state;
        },
        setImagesToView: (state, action) => {
            const { images } = action.payload;
            state.inViewImages = { ...state.inViewImages, ...images };

            return state;
        },
        setImageToView: (state, action) => {
            const { imageData } = action.payload;
            state.inViewImages[imageData.name] = imageData.data;

            return state;
        },
        setWorkersNumBelowThresholdAlert: (state, action) => {
            state.shouldShowWorkersNumberAlert = action.payload;
        },
        clearRunner: (state, action) => {
            const { runnerName } = action.payload;
            state[runnerName] = initialState[runnerName];
            return state;
        }
    }
});

export const {
    runnerSetSum,
    startRunner,
    setImageToView,
    stopPoiImporter,
    stopRunner,
    updateRunnerProgress,
    abortRunner,
    updateTimer,
    setImagesToView,
    clearRunner,
    closeFloatingModal,
    openFloatingModal,
    setWorkersNumBelowThresholdAlert
} = baseRunnersSlice.actions;

export default baseRunnersSlice.reducer;