"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AppearancePrivacySettings = _interopRequireDefault(require("./AppearancePrivacySettings"));
var _History = _interopRequireDefault(require("./History"));
var _Stream = _interopRequireDefault(require("./Stream"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Settings model module.
 * @module model/Settings
 * @version 0.32.2
 */
class Settings {
  /**
   * Constructs a new <code>Settings</code>.
   * Appearance Privacy settings associated with this profile
   * @alias module:model/Settings
   * @implements module:model/AppearancePrivacySettings
   * @param history {module:model/History} 
   * @param stream {module:model/Stream} 
   */
  constructor(history, stream) {
    _AppearancePrivacySettings.default.initialize(this, history, stream);
    Settings.initialize(this, history, stream);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, history, stream) {
    obj['history'] = history;
    obj['stream'] = stream;
  }

  /**
   * Constructs a <code>Settings</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Settings} obj Optional instance to populate.
   * @return {module:model/Settings} The populated <code>Settings</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Settings();
      _AppearancePrivacySettings.default.constructFromObject(data, obj);
      if (data.hasOwnProperty('history')) {
        obj['history'] = _History.default.constructFromObject(data['history']);
      }
      if (data.hasOwnProperty('stream')) {
        obj['stream'] = _Stream.default.constructFromObject(data['stream']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Settings</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Settings</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Settings.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // validate the optional field `history`
    if (data['history']) {
      // data not null
      _History.default.validateJSON(data['history']);
    }
    // validate the optional field `stream`
    if (data['stream']) {
      // data not null
      _Stream.default.validateJSON(data['stream']);
    }
    return true;
  }
}
Settings.RequiredProperties = ["history", "stream"];

/**
 * @member {module:model/History} history
 */
Settings.prototype['history'] = undefined;

/**
 * @member {module:model/Stream} stream
 */
Settings.prototype['stream'] = undefined;

// Implement AppearancePrivacySettings interface:
/**
 * @member {module:model/History} history
 */
_AppearancePrivacySettings.default.prototype['history'] = undefined;
/**
 * @member {module:model/Stream} stream
 */
_AppearancePrivacySettings.default.prototype['stream'] = undefined;
var _default = exports.default = Settings;