function MainLayout({ children }) {
    return (
        <div className="flex size-full flex-col">
            <div className="flex max-h-main-layout grow flex-col gap-3 lg:flex-row">
                {children}
            </div>
        </div>
    );
}

export default MainLayout;