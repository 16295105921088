import { useCallback, useContext } from "react";

import { IconButton } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

import ContextMenuButton from "../../Common/Buttons/ContextMenuButton";
import { saveCurrentFrame, saveFullPlayback } from "src/appConfig/Strings";
import { downloadSingleFrame, downloadVideoFromFrames } from "../playback.utils";
import ApiContext from "@/Logic/Context/apiContext";

const ContextMenuIcon = (
    < IconButton className="rounded-full bg-pie-line-bright-blue p-1 text-white" >
        <DownloadIcon fontSize="medium" />
    </IconButton >
);

function DownloadFramesContextMenu({ frames, appearanceSequence, currentFrameIndex, cameraName }) {
    const { clientsManager } = useContext(ApiContext);

    const setOptions = useCallback(() => {
        const options = {
            [saveCurrentFrame]: () => downloadSingleFrame(frames[appearanceSequence[currentFrameIndex]].image, currentFrameIndex, cameraName),
            [saveFullPlayback]: async () => {
                const reorderedFrames = appearanceSequence.map(appSeq => frames[appSeq]?.image);
                await downloadVideoFromFrames(clientsManager, reorderedFrames, cameraName);
            },
        };

        return options;
    }, [appearanceSequence, cameraName, clientsManager, currentFrameIndex, frames]);

    return (
        <ContextMenuButton options={setOptions()} iconElement={ContextMenuIcon} />
    );
}

export default DownloadFramesContextMenu;