import { isEmpty } from "lodash";

import CollapseDrawer from "@/Components/Common/Drawers/CollapseDrawer";
import { specificOptionSelected } from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";

export const isDrawerLockedCheck = (radioValue, selectedOptions) => {
    return radioValue === specificOptionSelected && isEmpty(selectedOptions);
};

function FilterWrapper({ label, isDrawerOpen, children, isFilterCollapsible, isDrawerLocked, drawerLockedTooltip }) {
    return (
        <div className="mt-3 flex w-full rounded border p-3">
            {isFilterCollapsible ? (
                <CollapseDrawer label={label} isDrawerOpen={isDrawerOpen} isDrawerLocked={isDrawerLocked} drawerLockedTooltip={drawerLockedTooltip} isLabelClickable={false} className="w-full">
                    {children}
                </CollapseDrawer>
            ) : (
                <div className="w-full">
                    <label>
                        {label}
                    </label>
                    {children}
                </div>
            )}
        </div>
    );
}

export default FilterWrapper;