"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GenerativeInsightsStatistics = _interopRequireDefault(require("./GenerativeInsightsStatistics"));
var _HistoryCountResponseData = _interopRequireDefault(require("./HistoryCountResponseData"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GenerativeInsightsStatisticsResponseData model module.
 * @module model/GenerativeInsightsStatisticsResponseData
 * @version 0.37.0
 */
class GenerativeInsightsStatisticsResponseData {
  /**
   * Constructs a new <code>GenerativeInsightsStatisticsResponseData</code>.
   * @alias module:model/GenerativeInsightsStatisticsResponseData
   * @param historyStatistics {module:model/HistoryCountResponseData} 
   * @param generativeInsightsStatistics {module:model/GenerativeInsightsStatistics} 
   */
  constructor(historyStatistics, generativeInsightsStatistics) {
    GenerativeInsightsStatisticsResponseData.initialize(this, historyStatistics, generativeInsightsStatistics);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, historyStatistics, generativeInsightsStatistics) {
    obj['history_statistics'] = historyStatistics;
    obj['generative_insights_statistics'] = generativeInsightsStatistics;
  }

  /**
   * Constructs a <code>GenerativeInsightsStatisticsResponseData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GenerativeInsightsStatisticsResponseData} obj Optional instance to populate.
   * @return {module:model/GenerativeInsightsStatisticsResponseData} The populated <code>GenerativeInsightsStatisticsResponseData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GenerativeInsightsStatisticsResponseData();
      if (data.hasOwnProperty('history_statistics')) {
        obj['history_statistics'] = _HistoryCountResponseData.default.constructFromObject(data['history_statistics']);
      }
      if (data.hasOwnProperty('generative_insights_statistics')) {
        obj['generative_insights_statistics'] = _GenerativeInsightsStatistics.default.constructFromObject(data['generative_insights_statistics']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GenerativeInsightsStatisticsResponseData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GenerativeInsightsStatisticsResponseData</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of GenerativeInsightsStatisticsResponseData.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // validate the optional field `history_statistics`
    if (data['history_statistics']) {
      // data not null
      _HistoryCountResponseData.default.validateJSON(data['history_statistics']);
    }
    // validate the optional field `generative_insights_statistics`
    if (data['generative_insights_statistics']) {
      // data not null
      _GenerativeInsightsStatistics.default.validateJSON(data['generative_insights_statistics']);
    }
    return true;
  }
}
GenerativeInsightsStatisticsResponseData.RequiredProperties = ["history_statistics", "generative_insights_statistics"];

/**
 * @member {module:model/HistoryCountResponseData} history_statistics
 */
GenerativeInsightsStatisticsResponseData.prototype['history_statistics'] = undefined;

/**
 * @member {module:model/GenerativeInsightsStatistics} generative_insights_statistics
 */
GenerativeInsightsStatisticsResponseData.prototype['generative_insights_statistics'] = undefined;
var _default = exports.default = GenerativeInsightsStatisticsResponseData;