import { isEmpty } from "lodash";

import { ApiClient, DataRetentionApi } from "smart_cameras_controller_api";
import { AppearancesApi } from "smart_cameras_history_api";
import { FramesAndBboxesStoredDataApi } from "smart_cameras_frames_storage_api";
import { GenerativeInsightsApi } from "smart_cameras_generative_insights_api";

import { controllerRoute } from "../Infrastructure/networkConf";
import BaseAPIClient from "./BaseAPIClient";
import store from "src/appConfig/configureStore";
import { setAuditRetentionData, setFramesRetentionData, setHistoryAutoClearData, setHistoryRetentionData, setInsightsRetentionData } from "../Redux/Stores/DataRetentionStore";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { updatePropertyIfPresent } from "../Parsing/objectsParsing";
import { daysInputMap, secondsInputMap, timeMappingToValue } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector.model";
import { historyAutoClearFormFieldsNames } from "@/Components/Settings/DataRetention/HistoryAutoClear/EditHistoryAutoClear.model";
import { AutoClearFormFieldsNames } from "@/Components/Settings/DataRetention/AutoClear.model";
import { clearedSuccessfully, framesStorage, historyStorage, insightsStorage } from "src/appConfig/Strings";
import { toastUtility } from "../Hooks/useToast";

export class DataRetentionClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(controllerRoute, clientInstance);
        this.dataRetentionApi = new DataRetentionApi();
        this.generativeInsightsApi = new GenerativeInsightsApi();
        this.appearancesApi = new AppearancesApi();
        this.framesStoredDataApi = new FramesAndBboxesStoredDataApi();
    }

    async getHistoryRetention() {
        return await this.apiCall(
            "Get History Data Retention Information",
            (callback) => this.dataRetentionApi.getHistoryRetention(callback),
            (_, data) => store.dispatch(setHistoryRetentionData(data.data))
        );
    }

    async getFramesRetention() {
        return await this.apiCall(
            "Get Frames Data Retention Information",
            (callback) => this.dataRetentionApi.getFramesStorageRetention(callback),
            (_, data) => store.dispatch(setFramesRetentionData(data.data))
        );
    }

    async getAuditRetention() {
        return await this.apiCall(
            "Get Audit Data Retention Storage Information",
            (callback) => this.dataRetentionApi.getAuditRetention(callback),
            (_, data) => store.dispatch(setAuditRetentionData(data.data))
        );
    }

    async getInsightsRetention() {
        return await this.apiCall(
            "Get Audit Data Retention Storage Information",
            (callback) => this.dataRetentionApi.getGenerativeInsightsRetention(callback),
            (_, data) => store.dispatch(setInsightsRetentionData(data.data))
        );
    }

    async clearHistoryDb(clearRetained = false) {
        const removeRetained = clearRetained ? { removeRetained: true } : undefined;
        return await this.apiCall("Clear History DB",
            (callback) => this.appearancesApi.clearHistoryDB(removeRetained, callback),
            () => {
                toastUtility.showSuccess(`${historyStorage} ${clearedSuccessfully}`);
                this.getHistoryRetention();
            }
        );
    }

    async clearAllFrames(clearRetained = false) {
        const removeRetained = clearRetained ? { removeRetained: true } : undefined;
        return await this.apiCall("Clear All Frames Data",
            (callback) => this.framesStoredDataApi.clearAllFramesData(removeRetained, callback),
            () => {
                toastUtility.showSuccess(`${framesStorage} ${clearedSuccessfully}`);
                this.getFramesRetention();
            }
        );
    }

    async clearGenerativeInsights() {
        return await this.apiCall("Clear Generative Insights",
            (callback) => this.generativeInsightsApi.clearGenerativeInsightRecords({}, callback),
            () => {
                toastUtility.showSuccess(`${insightsStorage} ${clearedSuccessfully}`);
                this.getInsightsRetention();
            }
        );
    }

    async getHistoryAutoClear() {
        return await this.apiCall("Get History Auto Clear",
            (callback) => this.dataRetentionApi.getAutoClearRetention(callback),
            (_, data) => store.dispatch(setHistoryAutoClearData(data.data))
        );
    }

    async setHistoryAutoClear(autoClearData) {
        const autoClearParams = {};
        updatePropertyIfPresent(autoClearData, historyAutoClearFormFieldsNames.enableAutoClear, autoClearParams);
        updatePropertyIfPresent(autoClearData, historyAutoClearFormFieldsNames.daysInterval, autoClearParams, (timeUnits) => timeMappingToValue(timeUnits, daysInputMap));
        updatePropertyIfPresent(autoClearData, historyAutoClearFormFieldsNames.autoClearStartTime, autoClearParams);

        return await this.apiCall("Set History Auto Clear",
            (callback) => this.dataRetentionApi.setAutoClearRetention(autoClearParams, callback),
            (error, _, response) => {
                this.getHistoryAutoClear();
                return parseResponseForUI(error, response);
            }
        );
    }

    async setAuditAutoClear(autoClearData) {
        const autoClearParams = {
            ttl_settings: {}
        };

        updatePropertyIfPresent(autoClearData, AutoClearFormFieldsNames.ttlEnabled, autoClearParams.ttl_settings);
        updatePropertyIfPresent(autoClearData, AutoClearFormFieldsNames.ttlSeconds, autoClearParams.ttl_settings, (timeUnits) => timeMappingToValue(timeUnits, secondsInputMap));

        return await this.apiCall("Set Audit Retention",
            (callback) => this.dataRetentionApi.setAuditRetention(autoClearParams, callback),
            (error, _, response) => {
                this.getAuditRetention();
                return parseResponseForUI(error, response);
            }
        );
    }

    async setHistoryRetention(historyRetentionData) {
        const ttlParams = {
            ttl_settings: {}
        };

        Object.keys(historyRetentionData).forEach((settingKey) => {
            const settingData = {};
            updatePropertyIfPresent(historyRetentionData[settingKey], AutoClearFormFieldsNames.ttlEnabled, settingData);
            updatePropertyIfPresent(historyRetentionData[settingKey], AutoClearFormFieldsNames.ttlSeconds, settingData, (timeUnits) => timeMappingToValue(timeUnits, secondsInputMap));
            if (!isEmpty(settingData)) {
                ttlParams.ttl_settings[settingKey] = settingData;
            }
        });

        return await this.apiCall(
            "Set History Data Retention",
            (callback) => this.dataRetentionApi.setHistoryRetention(ttlParams, callback),
            (error, _, response) => {
                this.getHistoryRetention();
                return parseResponseForUI(error, response);
            }
        );
    }
}

export function getMaxRecords(capacityObj) {
    const maxRecords = capacityObj.max_records;
    return maxRecords !== undefined ? maxRecords : capacityObj.max_capacity;
}

export function getCurrentRecords(capacityObj) {
    const currentCapacity = capacityObj.current_capacity;
    return currentCapacity !== undefined ? currentCapacity : capacityObj.current_retained_appearances;
}