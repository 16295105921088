import { createSlice } from '@reduxjs/toolkit';

import { isEmpty } from 'lodash';

import { liveAnalysisMode } from '@/Logic/ApiClients/AnalysisModeClient';
import { CreateEditUserFormFieldsNames } from "@/Components/Settings/Users/CreateEditUser/CreateEditUser.model";
import { deviceType, hqDeviceType, mainNodeDeviceType } from "@/Logic/ApiClients/ControllerClient";
import { toastUtility } from '@/Logic/Hooks/useToast';
import { notResponsiveLabel, unStableLabel } from 'src/appConfig/Strings';

export const userSettingDefaultValue = {
    [CreateEditUserFormFieldsNames.active]: true
};
const mainNodesDeviceTypes = new Set([mainNodeDeviceType, hqDeviceType]);

const isMultiServerModeActive = (nodes) => {
    const hasMultipleServers = nodes.length > 1;
    if (!hasMultipleServers) {
        return false;
    }
    const hasMatchingMainNode = nodes.some((nodeObject) => mainNodesDeviceTypes.has(nodeObject[deviceType]));

    return (hasMatchingMainNode);
};

const initialState = {
    login: {
        isLoggedIn: false,
        resetPassRequired: false,
        errorMsg: "",
        user: undefined
    },
    nodesList: {},
    isMultiServerMode: false,
    license: {},
    faceSettings: {},
    analysisSettings: {},
    generalSettings: {},
    privacyNotice: "",
    analysisMode: "",
    systemStatus: {},
    isLiveAnalysisMode: true,
};

export const serverNodesToastUpdate = (nodeId, oldNodeData, newNodeData) => {
    if (oldNodeData?.active && !newNodeData.active) {
        toastUtility.showError(`${nodeId} ${newNodeData.node_id} ${notResponsiveLabel}`);
    }
    if (newNodeData.active && !newNodeData.stable) {
        toastUtility.showWarning(`${nodeId} ${newNodeData.node_id} ${unStableLabel}`);
    }
};

const apiActionsSlice = createSlice({
    name: 'apiActions',
    initialState,
    reducers: {
        setLoginResponse: (state, action) => {
            let [isLoggedIn, resetPassRequired, errorMsg, user] = action.payload;
            if (!resetPassRequired && state.login.resetPassRequired && !isLoggedIn) {
                resetPassRequired = true;
            }
            state.login = { isLoggedIn, resetPassRequired, errorMsg, user };
        },
        setIsLoggedIn: (state, action) => {
            state.login.isLoggedIn = action.payload;
        },
        addServerStatus: (state, action) => {
            const { payload } = action;
            const existingNode = state.nodesList[payload.node_id];

            if (existingNode) {
                serverNodesToastUpdate(payload.node_id, existingNode, payload);
                state.nodesList[payload.node_id] = {
                    ...existingNode,
                    ...payload,
                };
            } else {
                state.nodesList[payload.node_id] = payload;
            }

            return state;
        },
        setNodesList: (state, action) => {
            const newNodesListObj = action.payload;
            const nodes = Object.values(newNodesListObj);
            state.isMultiServerMode = isMultiServerModeActive(nodes);
            if (!isEmpty(state.nodesList)) {
                Object.keys(newNodesListObj).forEach((nodeKey) => {
                    const oldNodeData = state.nodesList[nodeKey];
                    const newNodeData = newNodesListObj[nodeKey];
                    serverNodesToastUpdate(newNodeData.node_id, oldNodeData, newNodeData);
                });

            }
            state.nodesList = newNodesListObj;
        },
        setLicense: (state, action) => {
            state.license = action.payload;
        },
        setPrivacyNotice: (state, action) => {
            state.privacyNotice = action.payload;
        },
        setFaceSettings: (state, action) => {
            const faceSettings = action.payload;
            state.faceSettings = faceSettings.data;
        },
        setAnalysisSettings: (state, action) => {
            const analysisSettings = action.payload;
            state.analysisSettings = analysisSettings.data;
        },
        setAnalysisMode: (state, action) => {
            const analysisMode = action.payload.mode;
            state.analysisMode = analysisMode;
            state.isLiveAnalysisMode = analysisMode === liveAnalysisMode;
        },
        setSystemStatus: (state, action) => {
            const systemStatus = action.payload;
            state.systemStatus = systemStatus;
            return state;
        },
        setAllGeneralSettings: (state, action) => {
            const { settings } = action.payload;
            settings.forEach(setting => {
                state.generalSettings[setting.name] = setting.data;
            });

            return state;
        },
    }
});

export const {
    setLoginResponse,
    setIsLoggedIn,
    addServerStatus,
    setNodesList,
    setLicense,
    setPrivacyNotice,
    setFaceSettings,
    setAnalysisSettings,
    setAnalysisMode,
    setSystemStatus,
    setAllGeneralSettings
} = apiActionsSlice.actions;

export default apiActionsSlice.reducer;