import {
    activeCamerasLabel, ageEstimationLicense, analysisLoadControlLicense, analysisModelLicense,
    analyzeFacesCountLicense, apiRequestsLabel, appearancesPerCameraLabel, compareFacesCountLicense,
    detectFacesCountLicense, enabled, genderEstimationLicense, generativeInsightsHeader, historyRecords,
    historyStorage, investigateVideosLicense, liveCamerasLicense, livenessFacesCountLicense, poiFacesLabel,
    poiFacesLicense, registeredUsersLicense, searchFacesCountLicense, seatsLabel, unlimited, videosSimultaneouslyLabel
} from "src/appConfig/Strings";

export const unlimitedLicenseFeature = 555 || -1;
export const perpetualLicenseVal = 0;
export const modelFeatureName = "model";
export const poiFacesLimit = "POI_faces";
export const deploymentFacesLimit = "deployment_faces_limit";

export const licenseMapping = {
    [modelFeatureName]: { name: analysisModelLicense },
    [poiFacesLimit]: { name: poiFacesLicense, suffix: poiFacesLabel },
    "analyzing_live_cameras": { name: liveCamerasLicense, suffix: activeCamerasLabel },
    "analyzing_forensics_videos": { name: investigateVideosLicense, suffix: videosSimultaneouslyLabel },
    [deploymentFacesLimit]: { name: analysisLoadControlLicense, suffix: appearancesPerCameraLabel },
    "registered_custom_users": { name: registeredUsersLicense, suffix: seatsLabel },
    "age_group": { name: ageEstimationLicense },
    "gender": { name: genderEstimationLicense },
    "compare_faces_API_calls": { name: compareFacesCountLicense, suffix: apiRequestsLabel },
    "detect_faces_API_calls": { name: detectFacesCountLicense, suffix: apiRequestsLabel },
    "analyze_face_API_calls": { name: analyzeFacesCountLicense, suffix: apiRequestsLabel },
    "search_face_API_calls": { name: searchFacesCountLicense, suffix: apiRequestsLabel },
    "liveness": { name: livenessFacesCountLicense },
    "generative_insights": { name: generativeInsightsHeader },
    "history_capacity": { name: historyStorage, suffix: historyRecords }
};

export const modelMap = {
    "-1": "Unlimited", 1: "Hermes_v1", 3: "Hermes_v2", 4: "Artemis_v2",
    5: "Apollo_v2", 6: "Hermes_v3", 7: "Artemis_v3", 8: "Apollo_v3",
    9: "Hermes_v4", 10: "Artemis_v4", 11: "Apollo_v4"
};

export const featuresTypeMapping = {
    CountedFeatureData: (featureKey, { currInUse, maxAvailable }) => {
        return maxAvailable === unlimitedLicenseFeature ? unlimited : `${currInUse}/${maxAvailable}`;
    },
    ValueFeatureData: (featureKey, featureValue) => {
        let featureStatus;
        if (featureValue === unlimitedLicenseFeature) {
            featureStatus = unlimited;
        } else {
            featureStatus = (featureKey === modelFeatureName) ?
                modelMap[featureValue] : featureValue;
        }

        return featureStatus;
    },
    BoolFeatureData: () => {
        return enabled;
    }
};