"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DeletedUserResponse = _interopRequireDefault(require("../model/DeletedUserResponse"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetUserResponse = _interopRequireDefault(require("../model/GetUserResponse"));
var _GetUsersResponse = _interopRequireDefault(require("../model/GetUsersResponse"));
var _UserParameters = _interopRequireDefault(require("../model/UserParameters"));
var _UserUpdateParameters = _interopRequireDefault(require("../model/UserUpdateParameters"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* UsersManagement service.
* @module api/UsersManagementApi
* @version 0.35.0
*/
class UsersManagementApi {
  /**
  * Constructs a new UsersManagementApi. 
  * @alias module:api/UsersManagementApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addUser operation.
   * @callback module:api/UsersManagementApi~addUserCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetUserResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add User
   * Create new User
   * @param {module:model/UserParameters} userParameters 
   * @param {module:api/UsersManagementApi~addUserCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetUserResponse}
   */
  addUser(userParameters, callback) {
    let postBody = userParameters;
    // verify the required parameter 'userParameters' is set
    if (userParameters === undefined || userParameters === null) {
      throw new Error("Missing the required parameter 'userParameters' when calling addUser");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetUserResponse.default;
    return this.apiClient.callApi('/auth/users/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the deleteUser operation.
   * @callback module:api/UsersManagementApi~deleteUserCallback
   * @param {String} error Error message, if any.
   * @param {module:model/DeletedUserResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete User
   * With this endpoint, a user can be removed. Note that if the user is logged in, they will be logged out.
   * @param {Object} userId 
   * @param {module:api/UsersManagementApi~deleteUserCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/DeletedUserResponse}
   */
  deleteUser(userId, callback) {
    let postBody = null;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling deleteUser");
    }
    let pathParams = {
      'user_id': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DeletedUserResponse.default;
    return this.apiClient.callApi('/auth/users/{user_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the deleteUser_0 operation.
   * @callback module:api/UsersManagementApi~deleteUser_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/DeletedUserResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete User
   * With this endpoint, a user can be removed. Note that if the user is logged in, they will be logged out.
   * @param {Object} userId 
   * @param {module:api/UsersManagementApi~deleteUser_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/DeletedUserResponse}
   */
  deleteUser_0(userId, callback) {
    let postBody = null;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling deleteUser_0");
    }
    let pathParams = {
      'user_id': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DeletedUserResponse.default;
    return this.apiClient.callApi('/auth/users/{user_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getUser operation.
   * @callback module:api/UsersManagementApi~getUserCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetUserResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get User
   * Get User
   * @param {Object} userId 
   * @param {module:api/UsersManagementApi~getUserCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetUserResponse}
   */
  getUser(userId, callback) {
    let postBody = null;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling getUser");
    }
    let pathParams = {
      'user_id': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetUserResponse.default;
    return this.apiClient.callApi('/auth/users/{user_id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getUser_0 operation.
   * @callback module:api/UsersManagementApi~getUser_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/GetUserResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get User
   * Get User
   * @param {Object} userId 
   * @param {module:api/UsersManagementApi~getUser_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetUserResponse}
   */
  getUser_0(userId, callback) {
    let postBody = null;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling getUser_0");
    }
    let pathParams = {
      'user_id': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetUserResponse.default;
    return this.apiClient.callApi('/auth/users/{user_id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the listAllUsers operation.
   * @callback module:api/UsersManagementApi~listAllUsersCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetUsersResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List All Users
   * List all users
   * @param {module:api/UsersManagementApi~listAllUsersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetUsersResponse}
   */
  listAllUsers(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetUsersResponse.default;
    return this.apiClient.callApi('/auth/users/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the terminateUserSession operation.
   * @callback module:api/UsersManagementApi~terminateUserSessionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/DeletedUserResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Terminate User Session
   * Terminate the session of the user specified by the user_id provided in the url - which does not need to be the one associated with the access_token provided with this request's Authorization headers. If that user was logged in, they will now be logged out.
   * @param {Object} userId 
   * @param {module:api/UsersManagementApi~terminateUserSessionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/DeletedUserResponse}
   */
  terminateUserSession(userId, callback) {
    let postBody = null;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling terminateUserSession");
    }
    let pathParams = {
      'user_id': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DeletedUserResponse.default;
    return this.apiClient.callApi('/auth/users/{user_id}/terminate_session', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the terminateUserSession_0 operation.
   * @callback module:api/UsersManagementApi~terminateUserSession_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/DeletedUserResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Terminate User Session
   * Terminate the session of the user specified by the user_id provided in the url - which does not need to be the one associated with the access_token provided with this request's Authorization headers. If that user was logged in, they will now be logged out.
   * @param {Object} userId 
   * @param {module:api/UsersManagementApi~terminateUserSession_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/DeletedUserResponse}
   */
  terminateUserSession_0(userId, callback) {
    let postBody = null;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling terminateUserSession_0");
    }
    let pathParams = {
      'user_id': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _DeletedUserResponse.default;
    return this.apiClient.callApi('/auth/users/{user_id}/terminate_session', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updateUser operation.
   * @callback module:api/UsersManagementApi~updateUserCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetUserResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update User
   * With this endpoint, a user can be updated. Note that if the user is logged in, an update will log them out.
   * @param {Object} userId 
   * @param {module:model/UserUpdateParameters} userUpdateParameters 
   * @param {module:api/UsersManagementApi~updateUserCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetUserResponse}
   */
  updateUser(userId, userUpdateParameters, callback) {
    let postBody = userUpdateParameters;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling updateUser");
    }
    // verify the required parameter 'userUpdateParameters' is set
    if (userUpdateParameters === undefined || userUpdateParameters === null) {
      throw new Error("Missing the required parameter 'userUpdateParameters' when calling updateUser");
    }
    let pathParams = {
      'user_id': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetUserResponse.default;
    return this.apiClient.callApi('/auth/users/{user_id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updateUser_0 operation.
   * @callback module:api/UsersManagementApi~updateUser_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/GetUserResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update User
   * With this endpoint, a user can be updated. Note that if the user is logged in, an update will log them out.
   * @param {Object} userId 
   * @param {module:model/UserUpdateParameters} userUpdateParameters 
   * @param {module:api/UsersManagementApi~updateUser_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetUserResponse}
   */
  updateUser_0(userId, userUpdateParameters, callback) {
    let postBody = userUpdateParameters;
    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling updateUser_0");
    }
    // verify the required parameter 'userUpdateParameters' is set
    if (userUpdateParameters === undefined || userUpdateParameters === null) {
      throw new Error("Missing the required parameter 'userUpdateParameters' when calling updateUser_0");
    }
    let pathParams = {
      'user_id': userId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetUserResponse.default;
    return this.apiClient.callApi('/auth/users/{user_id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = UsersManagementApi;