
import { DropdownList } from "react-widgets";

import FilterContainer from "./FilterContainer";
import { analysisMode } from "../../../../appConfig/Strings";
import { investigateAnalysisMode, investigateAnalysisModeBE, liveAnalysisMode } from "@/Logic/ApiClients/AnalysisModeClient";
import { capitalizeWord } from "@/Logic/Parsing/stringsParsing";

export const analysisModeOptions = {
    live: {
        label: capitalizeWord(liveAnalysisMode),
        value: liveAnalysisMode
    },
    forensics: {
        label: capitalizeWord(investigateAnalysisMode),
        value: investigateAnalysisModeBE
    }
};

function AnalysisModeFilterOld({ data, setFunction, ...props }) {
    return (
        <FilterContainer header={analysisMode}>
            <DropdownList
                data={Object.values(analysisModeOptions)}
                value={data}
                onChange={(value) => {
                    setFunction(value);
                }}
                {...props}
            />
        </FilterContainer>
    );
}

export default AnalysisModeFilterOld;