import { useState, useEffect, useMemo, useCallback } from "react";

import { Collapse } from "@mui/material";
import { isEmpty } from "lodash";

import TooltipWrapper from "@/Components/Common/Tooltips/TooltipWrapper";
import RadioInput from "./RadioInput";
import MultiSelectInputField from "./SelectInputField/MultiSelectInputField";
import BaseSelectInputField from "./SelectInputField/BaseSelectInputField";

export const allOptionSelected = 1;
export const specificOptionSelected = 2;

export const radioKey = "radioKey";
export const multiSelectKey = "selectedOptions";
export const singleSelectKey = "selectedOption";
export const defaultRadioMultiSelectData = { [radioKey]: allOptionSelected, [multiSelectKey]: new Set() };
export const defaultRadioSingleSelectData = { [radioKey]: allOptionSelected, [singleSelectKey]: null };

function RadioEnabledSelect({ isMulti = false, className, sx,
    radioValue, radioOnChange, radioOptions, isRadioDisabled, radioDisableTooltipTxt,
    selectModel, selectOptions, selectValue, selectOnChange, isToggledOn = true, selectLabel, ...props }) {
    const [radioSelectedOption, setRadioSelectedOption] = useState();

    useEffect(() => {
        if (radioValue !== undefined) {
            setRadioSelectedOption(radioValue);
        } else {
            setRadioSelectedOption(isEmpty(selectValue) ? allOptionSelected : specificOptionSelected);
        }
    }, [radioValue, selectValue]);

    const isCollapseOpen = useMemo(() => radioSelectedOption === specificOptionSelected, [radioSelectedOption]);

    const onChangeRadioHandler = useCallback((event) => {
        const newValue = Number(event.target.value);
        setRadioSelectedOption(newValue);
        radioOnChange && radioOnChange(newValue);
        if (!radioOnChange && newValue === allOptionSelected) {
            selectOnChange(new Set());
        }
    }, [selectOnChange, radioOnChange]);


    const selectElement = useMemo(() => (
        isMulti ?
            <MultiSelectInputField
                label={selectLabel}
                model={selectModel}
                options={selectOptions}
                value={selectValue}
                onChange={selectOnChange}
                className={className}
                sx={sx}
                {...props}
            /> :
            <BaseSelectInputField
                label={selectLabel}
                model={selectModel}
                options={selectOptions}
                value={selectValue}
                onChange={selectOnChange}
                className={className}
                sx={sx}
                isMultiple={false}
                {...props}
            />
    ), [className, isMulti, props, selectLabel, selectModel, selectOnChange, selectOptions, selectValue, sx]);

    return (
        <Collapse in={isToggledOn} className={`w-full ${isRadioDisabled && 'opacity-50'}`}>
            {isToggledOn && (
                <div>
                    <TooltipWrapper tooltipText={radioDisableTooltipTxt} isDisabled={isRadioDisabled} placement="left">
                        <RadioInput
                            options={radioOptions}
                            value={radioSelectedOption}
                            onChange={onChangeRadioHandler}
                            isDisabled={isRadioDisabled}
                            className={className}
                        />
                    </TooltipWrapper>
                    <Collapse in={isCollapseOpen}>
                        {isCollapseOpen && selectElement}
                    </Collapse>
                </div>
            )}
        </Collapse>
    );
}

export function areOptionsSelected(selectedValues, radioValue) {
    return !isEmpty(selectedValues) && radioValue !== allOptionSelected;
}

export default RadioEnabledSelect;