import { useCallback } from "react";

import SelectFilter from "@/Components/Common/Filters/SelectFilter";
import { allLivenessOutcomes, reviewedFilterHeader } from "src/appConfig/Strings";
import { labelOptions } from "@/Components/Common/Modals/dialogs/labelAppearance/LabelAppearanceForm.model";

function ReviewedFilter({ ...props }) {
    const getReviewedOptions = useCallback(() => {

        return [{
            name: allLivenessOutcomes,
            value: null
        },
        ...labelOptions.map(labelOption => ({
            name: labelOption.label,
            value: labelOption.option
        }))];
    }, []);

    return (
        <SelectFilter
            getOptionsFunc={getReviewedOptions}
            header={reviewedFilterHeader}
            {...props}
        />
    );
}

export default ReviewedFilter;
