import { useCallback } from "react";

import { Slider, Input } from '@mui/material';

function SliderWithInput({ model, value = 0, dataTestId, onChange }) {
    const { min, max } = model;

    const handleSliderChange = useCallback((event) => {
        const newValue = event.target.value === "" ? min : Number(event.target.value);
        onChange && onChange(newValue);
    }, [min, onChange]);

    return (
        <div className="flex">
            <Slider min={min} max={max} className="mx-7 flex" value={value} onChange={handleSliderChange} />
            <Input className="w-14" {...model} inputProps={{ ...model, 'data-testid': dataTestId }} type='number' value={value} onChange={handleSliderChange} />
        </div>
    );
}

export default SliderWithInput;