import store from "src/appConfig/configureStore";
import { noUsbCameraFound, tooManyFiles } from "src/appConfig/Strings";
import { toastUtility } from "../Hooks/useToast";
import { getHostAddress, webDockerRoute } from "../Infrastructure/networkConf";
import BaseAPIClient, { resCodes } from "./BaseAPIClient";
import { setEnvVars } from "../Redux/Stores/EnvVarsStore";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";

export class WebDockerClient extends BaseAPIClient {
    constructor() {
        super(webDockerRoute, {});
        this.route = webDockerRoute;
        this.setHostIp(getHostAddress());
    }

    setHostIp(hostIp) {
        this.host = hostIp;
        super.setHostIp(hostIp);
    }

    setToken() {
        return;
    }

    async fetchWithTimeout(resource, timeout = 60000, timeoutCallback) {
        const controller = new AbortController();
        const id = setTimeout(() => {
            controller.abort();
            timeoutCallback();
        }, timeout);

        const response = await fetch(resource, {
            signal: controller.signal
        });
        clearTimeout(id);

        return response;
    }

    parseRtspUrl(displayUrl) {
        const [hostWithOutPort] = this.hostIp.split(":");
        return `wss://${this.host}/api/stream/${encodeURIComponent(`${displayUrl}`)}?host=${hostWithOutPort}`;
    }

    async initialize() {
        await this.getEnvVars();
    }

    async getAllUSBCameras() {
        return await this.apiCall("Get All Usb Cameras",
            async (callback) => {
                const getUsbCamerasResponse = await fetch(`${this.client_instance.basePath}api/usb_cameras`);
                callback(false, getUsbCamerasResponse.body, getUsbCamerasResponse);
            },
            (_, __, response) => this.#handleGetAllUSBCamerasSuccessful(response), {
            [resCodes.notFound]: () => {
                toastUtility.showError(noUsbCameraFound);
                return [];
            }
        });
    }

    async #handleGetAllUSBCamerasSuccessful(response) {
        const parsedResponse = await response.json();
        return Object.values(parsedResponse.data);
    }

    async getEnvVars() {
        return await this.apiCall("Get Web ENV Vars",
            async (callback) => {
                const getEnvResponse = await fetch(`${this.client_instance.basePath}api/env`);
                callback(false, getEnvResponse.body, getEnvResponse);
            },
            async (_, __, response) => {
                const envs = await response.json();
                store.dispatch(setEnvVars(envs.data));
            });
    }

    async getShareVideoFiles(folder) {
        return await this.apiCall("Get Video Files From Share Directory",
            async (callback) => {
                let errMsg;
                try {
                    const getFilesResponse = await this.fetchWithTimeout(
                        `${this.client_instance.basePath}api/files?folder=${folder}`,
                        10000,
                        () => {
                            errMsg = tooManyFiles;
                            console.warn("Get share folder video files timed out:", errMsg);
                        }
                    );
                    const responseBody = await getFilesResponse.json();
                    const fullResponse = { status: getFilesResponse.status, body: { ...responseBody } };
                    return callback(!getFilesResponse.ok, null, fullResponse);
                } catch (error) {
                    const fullResponse = { error: error.message, status: resCodes.requestTimeout, body: { metadata: { msg: errMsg } } };
                    return callback(true, null, fullResponse);
                }
            },
            async (_, __, response) => parseResponseForUI(false, response, response.body.data)
        );
    }

    async getVideoFromPlaybackFrames(framesList) {
        return await this.apiCall("Get Video From Playback Frames", async (callback) => {
            const getVideoFromPlaybackResponse = await fetch(`${this.client_instance.basePath}api/frames`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ framesList })
            });

            callback(false, getVideoFromPlaybackResponse, getVideoFromPlaybackResponse);
        }, async (error, data, response) => {
            const blob = await data.blob();
            return blob;
        });
    }
}