import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    savedCases: {},
    openCase: {},
    tempCaseData: {}
};

const searchCasesSlice = createSlice({
    name: 'searchCases',
    initialState,
    reducers: {
        setAllSavedCases: (state, action) => {
            const { cases } = action.payload;
            state.savedCases = cases;

            return state;
        },
        addSearchCase: (state, action) => {
            const { caseID, caseName } = action.payload;
            state.savedCases = {
                ...state.savedCases,
                [caseID]: caseName
            };

            return state;
        },
        setOpenCase: (state, action) => {
            const { caseData } = action.payload;
            state.savedCases = {
                ...state.savedCases,
                [caseData.id]: caseData.name
            };
            state.openCase[caseData.id] = JSON.parse(caseData.data);

            return state;
        },
        deleteCase: (state, action) => {
            const { caseID } = action.payload;
            state.openCase = {};
            delete state.savedCases[caseID];

            return state;
        },
        setTempCaseData: (state, action) => {
            //todo nothing clears this as of now
            const { tempCaseData } = action.payload;
            state.tempCaseData = tempCaseData;

            return state;
        },
    },
});

export const { setAllSavedCases, addSearchCase, setOpenCase, deleteCase, setTempCaseData } = searchCasesSlice.actions;

export default searchCasesSlice.reducer;