"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _MatchOutcomes = _interopRequireDefault(require("./MatchOutcomes"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The WatchlistsQuery model module.
 * @module model/WatchlistsQuery
 * @version 0.35.0
 */
class WatchlistsQuery {
  /**
   * Constructs a new <code>WatchlistsQuery</code>.
   * @alias module:model/WatchlistsQuery
   * @param matchOutcome {module:model/MatchOutcomes} the specific match outcome of interest.Valid match_outcome strings are- ['matched', 'not in watchlist', 'not_determined', 'low_quality_face']
   */
  constructor(matchOutcome) {
    WatchlistsQuery.initialize(this, matchOutcome);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, matchOutcome) {
    obj['match_outcome'] = matchOutcome;
  }

  /**
   * Constructs a <code>WatchlistsQuery</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WatchlistsQuery} obj Optional instance to populate.
   * @return {module:model/WatchlistsQuery} The populated <code>WatchlistsQuery</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WatchlistsQuery();
      if (data.hasOwnProperty('watchlist_id')) {
        obj['watchlist_id'] = _ApiClient.default.convertToType(data['watchlist_id'], 'String');
      }
      if (data.hasOwnProperty('match_outcome')) {
        obj['match_outcome'] = _ApiClient.default.convertToType(data['match_outcome'], _MatchOutcomes.default);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>WatchlistsQuery</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>WatchlistsQuery</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of WatchlistsQuery.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['watchlist_id'] && !(typeof data['watchlist_id'] === 'string' || data['watchlist_id'] instanceof String)) {
      throw new Error("Expected the field `watchlist_id` to be a primitive type in the JSON string but got " + data['watchlist_id']);
    }
    // ensure the json data is a string
    if (data['match_outcome'] && !(typeof data['match_outcome'] === 'string' || data['match_outcome'] instanceof String)) {
      throw new Error("Expected the field `match_outcome` to be a primitive type in the JSON string but got " + data['match_outcome']);
    }
    // validate the optional field `match_outcome`
    if (data['match_outcome']) {
      // data not null
      _MatchOutcomes.default.validateJSON(data['match_outcome']);
    }
    return true;
  }
}
WatchlistsQuery.RequiredProperties = ["match_outcome"];

/**
 * uuid of the watchlist of interest. If not provided,then only the match_outcome is of interest
 * @member {String} watchlist_id
 */
WatchlistsQuery.prototype['watchlist_id'] = undefined;

/**
 * the specific match outcome of interest.Valid match_outcome strings are- ['matched', 'not in watchlist', 'not_determined', 'low_quality_face']
 * @member {module:model/MatchOutcomes} match_outcome
 */
WatchlistsQuery.prototype['match_outcome'] = undefined;
var _default = exports.default = WatchlistsQuery;