"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetWatchlistAndPOIsResponse = _interopRequireDefault(require("../model/GetWatchlistAndPOIsResponse"));
var _GetWatchlistResponse = _interopRequireDefault(require("../model/GetWatchlistResponse"));
var _ListWatchlistsResponse = _interopRequireDefault(require("../model/ListWatchlistsResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Watchlist service.
* @module api/WatchlistApi
* @version 0.37.1
*/
class WatchlistApi {
  /**
  * Constructs a new WatchlistApi. 
  * @alias module:api/WatchlistApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addWatchlist operation.
   * @callback module:api/WatchlistApi~addWatchlistCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetWatchlistResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Create Watchlist
   * Create new Watchlist
   * @param {Object} body 
   * @param {module:api/WatchlistApi~addWatchlistCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetWatchlistResponse}
   */
  addWatchlist(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling addWatchlist");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetWatchlistResponse.default;
    return this.apiClient.callApi('/poi_db/watchlist/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the clearWatchlist operation.
   * @callback module:api/WatchlistApi~clearWatchlistCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Clear Watchlist
   * Clear the watchlist from all associated POIs. If the delete_pois flag is set to True, these POIs and their associated faces will be completely removed from the DB (even if they are associated with different watchlists as well as this one). If False, the POIs and their faces will remain in the DB but will no longer be associated with this watchlist
   * @param {Object} watchlistId 
   * @param {Object} opts Optional parameters
   * @param {Object} opts.body 
   * @param {module:api/WatchlistApi~clearWatchlistCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  clearWatchlist(watchlistId, opts, callback) {
    opts = opts || {};
    let postBody = opts['body'];
    // verify the required parameter 'watchlistId' is set
    if (watchlistId === undefined || watchlistId === null) {
      throw new Error("Missing the required parameter 'watchlistId' when calling clearWatchlist");
    }
    let pathParams = {
      'watchlist_id': watchlistId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/poi_db/watchlist/{watchlist_id}/clear', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the clearWatchlist_0 operation.
   * @callback module:api/WatchlistApi~clearWatchlist_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Clear Watchlist
   * Clear the watchlist from all associated POIs. If the delete_pois flag is set to True, these POIs and their associated faces will be completely removed from the DB (even if they are associated with different watchlists as well as this one). If False, the POIs and their faces will remain in the DB but will no longer be associated with this watchlist
   * @param {Object} watchlistId 
   * @param {Object} opts Optional parameters
   * @param {Object} opts.body 
   * @param {module:api/WatchlistApi~clearWatchlist_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  clearWatchlist_0(watchlistId, opts, callback) {
    opts = opts || {};
    let postBody = opts['body'];
    // verify the required parameter 'watchlistId' is set
    if (watchlistId === undefined || watchlistId === null) {
      throw new Error("Missing the required parameter 'watchlistId' when calling clearWatchlist_0");
    }
    let pathParams = {
      'watchlist_id': watchlistId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/poi_db/watchlist/{watchlist_id}/clear', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getWatchlist operation.
   * @callback module:api/WatchlistApi~getWatchlistCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetWatchlistAndPOIsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Watchlist
   * Get Watchlist
   * @param {Object} watchlistId 
   * @param {Object} opts Optional parameters
   * @param {Object} opts.countPois should the request count the POIs of the watchlist
   * @param {Object} opts.afterId 
   * @param {Object} opts.limit 
   * @param {module:api/WatchlistApi~getWatchlistCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetWatchlistAndPOIsResponse}
   */
  getWatchlist(watchlistId, opts, callback) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'watchlistId' is set
    if (watchlistId === undefined || watchlistId === null) {
      throw new Error("Missing the required parameter 'watchlistId' when calling getWatchlist");
    }
    let pathParams = {
      'watchlist_id': watchlistId
    };
    let queryParams = {
      'count_pois': opts['countPois'],
      'after_id': opts['afterId'],
      'limit': opts['limit']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetWatchlistAndPOIsResponse.default;
    return this.apiClient.callApi('/poi_db/watchlist/{watchlist_id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getWatchlist_0 operation.
   * @callback module:api/WatchlistApi~getWatchlist_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/GetWatchlistAndPOIsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Watchlist
   * Get Watchlist
   * @param {Object} watchlistId 
   * @param {Object} opts Optional parameters
   * @param {Object} opts.countPois should the request count the POIs of the watchlist
   * @param {Object} opts.afterId 
   * @param {Object} opts.limit 
   * @param {module:api/WatchlistApi~getWatchlist_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetWatchlistAndPOIsResponse}
   */
  getWatchlist_0(watchlistId, opts, callback) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'watchlistId' is set
    if (watchlistId === undefined || watchlistId === null) {
      throw new Error("Missing the required parameter 'watchlistId' when calling getWatchlist_0");
    }
    let pathParams = {
      'watchlist_id': watchlistId
    };
    let queryParams = {
      'count_pois': opts['countPois'],
      'after_id': opts['afterId'],
      'limit': opts['limit']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetWatchlistAndPOIsResponse.default;
    return this.apiClient.callApi('/poi_db/watchlist/{watchlist_id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the listAllWatchlists operation.
   * @callback module:api/WatchlistApi~listAllWatchlistsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ListWatchlistsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List All Watchlists
   * List all Watchlists
   * @param {Object} opts Optional parameters
   * @param {Object} opts.countPois should the request count the POIs of the watchlists
   * @param {module:api/WatchlistApi~listAllWatchlistsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ListWatchlistsResponse}
   */
  listAllWatchlists(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'count_pois': opts['countPois']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ListWatchlistsResponse.default;
    return this.apiClient.callApi('/poi_db/watchlist/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeWatchlist operation.
   * @callback module:api/WatchlistApi~removeWatchlistCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetWatchlistResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Watchlist
   * Remove an empty watchlist. Request will be rejected if watchlist is not empty of POIs.
   * @param {Object} watchlistId 
   * @param {module:api/WatchlistApi~removeWatchlistCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetWatchlistResponse}
   */
  removeWatchlist(watchlistId, callback) {
    let postBody = null;
    // verify the required parameter 'watchlistId' is set
    if (watchlistId === undefined || watchlistId === null) {
      throw new Error("Missing the required parameter 'watchlistId' when calling removeWatchlist");
    }
    let pathParams = {
      'watchlist_id': watchlistId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetWatchlistResponse.default;
    return this.apiClient.callApi('/poi_db/watchlist/{watchlist_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeWatchlist_0 operation.
   * @callback module:api/WatchlistApi~removeWatchlist_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/GetWatchlistResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Watchlist
   * Remove an empty watchlist. Request will be rejected if watchlist is not empty of POIs.
   * @param {Object} watchlistId 
   * @param {module:api/WatchlistApi~removeWatchlist_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetWatchlistResponse}
   */
  removeWatchlist_0(watchlistId, callback) {
    let postBody = null;
    // verify the required parameter 'watchlistId' is set
    if (watchlistId === undefined || watchlistId === null) {
      throw new Error("Missing the required parameter 'watchlistId' when calling removeWatchlist_0");
    }
    let pathParams = {
      'watchlist_id': watchlistId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetWatchlistResponse.default;
    return this.apiClient.callApi('/poi_db/watchlist/{watchlist_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updateWatchlist operation.
   * @callback module:api/WatchlistApi~updateWatchlistCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetWatchlistResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update Watchlist
   * Update a specific Watchlist's parameters
   * @param {Object} watchlistId 
   * @param {Object} body 
   * @param {module:api/WatchlistApi~updateWatchlistCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetWatchlistResponse}
   */
  updateWatchlist(watchlistId, body, callback) {
    let postBody = body;
    // verify the required parameter 'watchlistId' is set
    if (watchlistId === undefined || watchlistId === null) {
      throw new Error("Missing the required parameter 'watchlistId' when calling updateWatchlist");
    }
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updateWatchlist");
    }
    let pathParams = {
      'watchlist_id': watchlistId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetWatchlistResponse.default;
    return this.apiClient.callApi('/poi_db/watchlist/{watchlist_id}/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updateWatchlist_0 operation.
   * @callback module:api/WatchlistApi~updateWatchlist_0Callback
   * @param {String} error Error message, if any.
   * @param {module:model/GetWatchlistResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update Watchlist
   * Update a specific Watchlist's parameters
   * @param {Object} watchlistId 
   * @param {Object} body 
   * @param {module:api/WatchlistApi~updateWatchlist_0Callback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetWatchlistResponse}
   */
  updateWatchlist_0(watchlistId, body, callback) {
    let postBody = body;
    // verify the required parameter 'watchlistId' is set
    if (watchlistId === undefined || watchlistId === null) {
      throw new Error("Missing the required parameter 'watchlistId' when calling updateWatchlist_0");
    }
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updateWatchlist_0");
    }
    let pathParams = {
      'watchlist_id': watchlistId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetWatchlistResponse.default;
    return this.apiClient.callApi('/poi_db/watchlist/{watchlist_id}/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = WatchlistApi;