import { memo, useCallback } from "react";

import store from "../../../../appConfig/configureStore";
import SelectFilter from "./SelectFilter";
import { allWatchlistFilterOption } from "../../../../appConfig/Strings";

export const allWatchlistFilterSelectionOption = {
    display_name: allWatchlistFilterOption,
    watchlist_id: null
};

function MatchOutcomeFilter({
    filterType,
    header,
    outcome,
    ...props
}) {

    const getWatchlistFilters = useCallback(() => {
        const state = store.getState();
        const allWatchlists = Object.values(state.WatchlistsStore);
        const roleMatchOutcomeFilters = state.UsersStore.roleDataFilters?.[filterType]?.matches;
        if (roleMatchOutcomeFilters?.length) {
            const moreOptions = roleMatchOutcomeFilters.find(matchOutcome => matchOutcome.match_outcome === outcome && matchOutcome.watchlist_id === null) ?
                [allWatchlistFilterSelectionOption, ...allWatchlists] : [];
            return allWatchlists.reduce((accumulatedWatchlistsIds, watchlist) => {
                if (roleMatchOutcomeFilters.find(matchOutcome => matchOutcome.match_outcome === outcome && matchOutcome.watchlist_id === watchlist.watchlist_id))
                    accumulatedWatchlistsIds.push(watchlist);
                return accumulatedWatchlistsIds;
            }, [...moreOptions]);
        } else
            return [allWatchlistFilterSelectionOption, ...allWatchlists];
    }, [filterType, outcome]);

    return (
        <SelectFilter
            getOptionsFunc={getWatchlistFilters}
            header={header}
            {...props}
        />
    );
}

export default memo(MatchOutcomeFilter);