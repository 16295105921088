import { useCallback, useContext, useEffect, useState } from "react";

import ApiContext from "../Context/apiContext";
import useUIResponseHandler from "./useUIResponseHandler";
import { resultsPerPage, searchFaceMinConfidence } from "src/appConfig/constants";

function useDuplicatePOI(editedPoiId, imgToCheck) {
    const { clientsManager } = useContext(ApiContext);
    const [searchResult, setSearchResult] = useState(null);
    const { isLoading, setIsLoading, responseMessage, error, parseResponse } = useUIResponseHandler();

    const searchFaceRequest = useCallback(async () => {
        setIsLoading(true);
        const responseList = await clientsManager.faceClient.searchFace(imgToCheck, {
            minConfidence: searchFaceMinConfidence,
            maxMatches: resultsPerPage,
            failOnMultipleFaces: true,
            detect: false
        });
        parseResponse(responseList);
        const filteredResponseList = editedPoiId ? responseList.filter((matchedPOI) => matchedPOI.poi_id !== editedPoiId) : responseList;
        const hasOnlyEditedPOIDuplicates = editedPoiId && filteredResponseList.length === 0 && responseList.length === 1;
        setSearchResult(hasOnlyEditedPOIDuplicates ? [] : filteredResponseList);
    }, [clientsManager.faceClient, editedPoiId, imgToCheck, parseResponse, setIsLoading]);

    useEffect(() => {
        if (imgToCheck) {
            searchFaceRequest(imgToCheck);
        }

        return () => {
            setIsLoading(false);
        };
    }, [imgToCheck, searchFaceRequest, setIsLoading]);

    return { searchResult, isLoading, error, responseMessage };
}

export default useDuplicatePOI;