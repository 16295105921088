import { useState, useCallback } from "react";

import { Tooltip, Zoom } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import useDisabledButton from "@/Logic/Hooks/useDisabledButton";
import useDialog from "@/Logic/Hooks/useDialog/useDialog";
import { back, cancel, create, confirm } from "src/appConfig/Strings";
import ModalHeader from "./BaseModal/ModalHeader";
import ModalFooter from "./BaseModal/ModalFooter";
import { tooltipWhiteStyle } from "../Styles/Tooltip.styles";

const backdropClick = "backdropClick";

function MainDialog({ triggerIcon, headerIcon, headerTitle, headerRightSection, footerCancelBtnText, footerSubmitBtnText,
    onCancel, onExit, onSubmit, formID, children, confirmationElement, dialogID, isFooterVisible = true, openDialog = false,
    dataTestId = "dialog-trigger-icon-btn", iconDisabled = false, tooltipText = "",
    shouldNavigateOnCancel = Boolean(formID), dialogSxClass, triggerIconClassName, disableEscapeKeyDown = false,
    showCancelButton = true, showXButton = true, shouldShowDialog = false, dialogContentClassName }) {
    const isDisabled = useDisabledButton(formID);
    const { isDialogOpen, handleToggleDialog } = useDialog(dialogID, openDialog);
    const [shouldShowConfirmation, setShouldShowConfirmation] = useState(false);

    const cancelText = shouldShowConfirmation ? back : footerCancelBtnText || cancel;
    const acceptText = shouldShowConfirmation ? confirm : footerSubmitBtnText || create;
    const shouldOpenDialog = isDialogOpen || shouldShowDialog;

    const handleCancel = useCallback((event, reason) => {
        if (reason === backdropClick) {
            return;
        }

        if (shouldShowConfirmation) {
            setShouldShowConfirmation(false);
        } else {
            handleToggleDialog(shouldNavigateOnCancel);
            onCancel && onCancel();
        }
    }, [shouldShowConfirmation, handleToggleDialog, onCancel, shouldNavigateOnCancel]);

    const handleSaveClick = useCallback(() => {
        if (confirmationElement && !shouldShowConfirmation) {
            setShouldShowConfirmation(true);
        } else {
            if (!formID) {
                handleToggleDialog();
                onSubmit && onSubmit();
            }
            shouldShowConfirmation && setShouldShowConfirmation(false);
        }
    }, [confirmationElement, shouldShowConfirmation, formID, handleToggleDialog, onSubmit]);

    return (
        <div>
            {triggerIcon && (
                <Tooltip slotProps={tooltipWhiteStyle} title={tooltipText} TransitionComponent={Zoom} arrow placement="top">
                    <IconButton
                        onClick={iconDisabled ? undefined : () => handleToggleDialog()}
                        data-testid={dataTestId}
                        className={`${triggerIconClassName} ${iconDisabled ? "opacity-60" : ""}`}
                    >
                        {triggerIcon}
                    </IconButton>
                </Tooltip>
            )}
            <Dialog
                open={shouldOpenDialog}
                onClose={handleCancel}
                TransitionProps={{ onExited: onExit }}
                sx={dialogSxClass}
                fullWidth={Boolean(dialogSxClass)}
                disableEscapeKeyDown={disableEscapeKeyDown}
            >
                {shouldOpenDialog && (
                    <div>
                        <ModalHeader headerIcon={headerIcon} headerRightSection={headerRightSection} headerTitle={headerTitle} handleCancelClick={handleCancel} showXButton={showXButton} />
                        <DialogContent className={`flex w-full content-center justify-center ${dialogSxClass ? "" : "lg:w-main-modal-width"} ${dialogContentClassName}`} data-testid="dialog-content">
                            <div className={shouldShowConfirmation ? "hidden" : "flex w-full content-center justify-center"}>
                                {children}
                            </div>
                            {confirmationElement && (
                                <div className={!shouldShowConfirmation ? 'hidden' : undefined}>
                                    {confirmationElement}
                                </div>
                            )}
                        </DialogContent>
                        {isFooterVisible && (
                            <DialogActions className="flex justify-center" data-testid="dialog-actions-box">
                                <ModalFooter
                                    type={formID && !shouldShowConfirmation ? "submit" : "button"}
                                    formID={formID && !shouldShowConfirmation ? formID : undefined}
                                    leftBtnText={cancelText}
                                    rightBtnText={acceptText}
                                    handleLeftBtnAction={handleCancel}
                                    isRightBtnDisabled={isDisabled}
                                    handleRightBtnAction={handleSaveClick}
                                    showCancelButton={showCancelButton}
                                />
                            </DialogActions>
                        )}
                    </div>
                )}
            </Dialog>
        </div>
    );
}

export default MainDialog;