import { connect } from "react-redux";

import "react-toastify/ReactToastify.min.css";

import { ReactComponent as LogoutIcon } from "src/assets/Icons/TopBar/icon_logout.svg";
import { ReactComponent as AvatarIcon } from "src/assets/Icons/TopBar/icon_avatar.svg";

import { investigateAnalysisMode } from "@/Logic/ApiClients/AnalysisModeClient";
import NotificationCenter from "../../NotificationCenter/NotificationCenter";
import { logout, logoutPrompt, logoutTitle } from "src/appConfig/Strings";
import { headerLogo } from "src/appConfig/constants";
import AlertsCenter from "../../AlertsCenter/AlertsCenter";
import MainDialog from "../../Modals/MainDialog";

function Header({ logoutUser, currentUserName, isLiveAnalysisMode }) {
    return (
        <div className="flex h-main-top-bar-height items-center bg-top-bar drop-shadow-lg">
            <img alt="" src={headerLogo} data-testid="header-logo" />
            {!isLiveAnalysisMode && <span className="capitalize text-white"> {investigateAnalysisMode}</span>}
            <div className="ml-auto flex items-center">
                <AlertsCenter />
                <NotificationCenter />
                <div className="ml-4 flex items-center">
                    <AvatarIcon className="stroke-white" />
                    <div className="header-user ml-1">{currentUserName}</div>
                </div>
                <div className="ml-14">
                    <MainDialog triggerIcon={<LogoutIcon className="stroke-white" />} headerTitle={logoutTitle} footerSubmitBtnText={logout} onSubmit={logoutUser} >
                        {logoutPrompt}
                    </MainDialog>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const currentUserName = state.ApiActionsStore.login.user?.name;
    const isLiveAnalysisMode = state.ApiActionsStore.isLiveAnalysisMode;

    return { currentUserName, isLiveAnalysisMode };
};

export default connect(mapStateToProps, null)(Header);