import { isEmpty } from "lodash";

import { consent, noConsent, not_reviewed, poiConsent, poiIdPlaceholder, notes, selectCamera } from "src/appConfig/Strings";

export const CreateEditPOIModel = {
    SubmitButton: {
        name: "submitBtn",
        type: "submit",
    }
};

export const videoConstraints = {
    width: 552,
    height: 400,
    canvasMaxHeight: 528,
    facingMode: "user",
};

export const poiConsentValues = {
    consent: 0,
    no_consent: 1,
    not_reviewed: 2
};

export const PoiConsentOptions = (isDisabled = false) => [{
    label: consent,
    value: poiConsentValues.consent,
}, {
    label: noConsent,
    value: poiConsentValues.no_consent,
    disabled: isDisabled
}, {
    label: not_reviewed,
    value: poiConsentValues.not_reviewed,
    disabled: isDisabled
}];

export const PoiFormFieldsNames = {
    poiId: "display_name",
    watchlists: "poi_watchlists",
    notes: "poi_notes",
    consent: "poi_consent",
    displayImage: "display_img",
    faces: "faces",
    allowLowQuality: "force"
};

export const CreateEditPoiFormInputsModel = {
    [PoiFormFieldsNames.poiId]: {
        name: PoiFormFieldsNames.poiId,
        type: "text",
        placeholder: poiIdPlaceholder,
        required: true,
    },
    [PoiFormFieldsNames.watchlists]: {
        name: PoiFormFieldsNames.watchlists,
        getId: (watchlist) => watchlist.watchlist_id,
        getLabel: (watchlist) => watchlist.display_name,
        required: true,
    },
    [PoiFormFieldsNames.notes]: {
        name: PoiFormFieldsNames.notes,
        type: "textarea",
        placeholder: notes,
    },
    [PoiFormFieldsNames.consent]: {
        name: PoiFormFieldsNames.consent,
        type: "number",
        dataKey: "value",
        textField: "label",
        label: poiConsent,
    },
    [PoiFormFieldsNames.displayImage]: {
        name: PoiFormFieldsNames.displayImage,
        type: "text",
    },
    [PoiFormFieldsNames.faces]: {
        name: PoiFormFieldsNames.faces,
        validator: (formData, facesField) => {
            const hasNoFaces = isEmpty(formData[facesField]);
            let validForm = true;
            if (hasNoFaces) {
                validForm = false;
            }

            return validForm;
        }
    },
    [PoiFormFieldsNames.allowLowQuality]: {
        name: PoiFormFieldsNames.allowLowQuality,
        type: "checkbox",
        id: "flexSwitchCheckDefault",
    }
};

export const AddImagesPoiModel = {
    uploadInput: {
        name: "upload",
        type: "file",
        style: { display: "none" }
    },
    iconButtons: {
        name: "captureFromCamera",
        outline: true,
        color: "secondary",
        className: "icon-button"
    },
    capture_device_select: {
        dataKey: "deviceId",
        textField: "label",
        className: "my-2",
        placeholder: selectCamera
    },
    faceInput: {
        name: "faces",
        type: "text"
    },
    facesIDs: {
        name: "facesIDs",
        type: "text"
    }
};