import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DropdownList } from "react-widgets"; //todo replace later
import { Alert, IconButton } from "@mui/material";

import { ReactComponent as ShrinkIcon } from "../../../assets/Icons/Display/icon_shrink.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/Icons/Display/icon_expand.svg";

import { selectDisplay, streamDisconnectedAlert } from "../../../appConfig/Strings";
import DisplayPlayerCanvas from "../Common/Drawers/DisplayPlayerCanvas";
import ApiContext from "../../Logic/Context/apiContext";
import { activeCapturingStatuses, cameraStatuses } from "../../Logic/ApiClients/CamerasClient";
import useEventListener from "../../Logic/Hooks/useEventListener/useEventListener";
import { emptyStreamOption } from "./GridDisplay";
import { ParseRtspUrl } from "../../Logic/Parsing/stringsParsing";
import { createExpansionChangeEvent } from "@/Logic/Hooks/useDrawer/useDrawer";
import { setOpenDisplay } from "@/Logic/Redux/Stores/GlobalStore";
import { eventTypes } from "@/Logic/ApiClients/EventsClient/EventsClient.model";

function StreamDisplay({ camerasStreamsList, cameras, addSelectedCamera, removeSelectedCamera, selectedCamera, isExpanded, screenId, setOpenDisplay, isLiveAnalysisMode }) {
    const { clientsManager } = useContext(ApiContext);

    const [streamUrl, setStreamUrl] = useState("");
    const [selectedCameraName, setSelectedCameraName] = useState(selectDisplay);
    const [streamDisconnected, setDisconnected] = useState(false);
    const [cameraId, setCameraId] = useState({});
    const [isDisplayExpanded, setIsDisplayExpanded] = useState(isExpanded);

    const fullStreamList = useMemo(() => camerasStreamsList ? camerasStreamsList : [emptyStreamOption, ...Object.values(cameras).filter(camera => camera.display_rtsp_address)]
        , [cameras, camerasStreamsList]);

    useEffect(() => {
        if (isDisplayExpanded) {
            createExpansionChangeEvent(isExpanded);
        }
    }, []);

    useEffect(() => {
        if (selectedCamera && activeCapturingStatuses.includes(selectedCamera.camera_status.status)) {
            changeStream(selectedCamera);
        }
    }, []);

    useEffect(() => {
        if (!isLiveAnalysisMode) {
            setStreamUrl("");
            setSelectedCameraName(selectDisplay);
            setDisconnected(false);
            setOpenDisplay({ screenId, isExpanded: false, selectedCamera: undefined });
            setIsDisplayExpanded(false);
        }
    }, [isLiveAnalysisMode, screenId, setOpenDisplay]);

    const toggleExpansion = useCallback(() => {
        setIsDisplayExpanded((prevValue) => {
            const isExpanded = !prevValue;
            setOpenDisplay({ screenId, isExpanded, selectedCamera });
            createExpansionChangeEvent(isExpanded);
            return isExpanded;
        });
    }, [screenId, selectedCamera, setOpenDisplay]);

    const checkCameraStatus = useCallback((cameraStatus) => {
        setDisconnected(cameraStatus?.status !== cameraStatuses.active && cameraStatus?.status !== cameraStatuses.display);
    }, []);

    const changeStream = useCallback((camera) => {
        const parsedDisplayStream = camera.display_rtsp_address ? ParseRtspUrl(camera.display_rtsp_address, clientsManager.hostIp) : camera.display_rtsp_address;
        setSelectedCameraName(`${camera.description} | ${parsedDisplayStream}`);
        setStreamUrl(camera.display_rtsp_address);
        setCameraId(camera.camera_id);
        checkCameraStatus(camera?.camera_status);
        setOpenDisplay({ screenId, isExpanded: isDisplayExpanded, selectedCamera: camera.camera_id ? camera : undefined });
    }, [checkCameraStatus, clientsManager.hostIp, isDisplayExpanded, screenId, setOpenDisplay]);

    const handleStreamChange = useCallback((camera) => {
        removeSelectedCamera && removeSelectedCamera(streamUrl);
        addSelectedCamera && addSelectedCamera(camera.camera_id, camera.display_rtsp_address);
        changeStream(camera);
        if (camera.display_rtsp_address.length === 0) {
            setDisconnected(false);
        }
    }, [addSelectedCamera, changeStream, removeSelectedCamera, streamUrl]);

    const cameraStatusHandler = useCallback((receivedEventType, event) => {
        if (receivedEventType === eventTypes.camera_status && event.camera_data.camera_id === cameraId) {
            checkCameraStatus(event.update_data);
        }
    }, [cameraId, checkCameraStatus]);

    useEventListener(clientsManager.eventsClientWorker, cameraStatusHandler);

    const displayContainerClass = useMemo(() => `flex flex-col max-w-full justify-center p-3
     ${isDisplayExpanded ? "w-switchable-panel-wide" : "w-switchable-panel-narrow"}
     ${isLiveAnalysisMode ? "opacity-100" : "opacity-60"}`, [isDisplayExpanded, isLiveAnalysisMode]);

    return (
        <div className={displayContainerClass} data-testid="display-container" >
            <DropdownList
                disabled={!isLiveAnalysisMode}
                data={fullStreamList}
                value={{
                    display_rtsp_address: streamUrl,
                    description: selectedCameraName
                }}
                onChange={handleStreamChange}
                dataKey="display_rtsp_address"
                textField="description"
                placeholder={selectDisplay}
                data-testid="display-dropdown"
            />
            {isLiveAnalysisMode && (
                <section className="flex flex-col">
                    <IconButton onClick={toggleExpansion} className="ml-auto" data-testid="display-expand-button">
                        {isDisplayExpanded ? <ShrinkIcon /> : <ExpandIcon />}
                    </IconButton>
                    {streamUrl && !streamDisconnected && <DisplayPlayerCanvas url={clientsManager.webDockerClient.parseRtspUrl(streamUrl)} />}
                    {streamDisconnected && (<Alert variant="filled" severity="warning">{streamDisconnectedAlert} </Alert>)}
                </section>
            )}
        </div>
    );
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({ setOpenDisplay }, dispatch);
};

const mapStateToProps = (state, ownProps) => {
    const isLiveAnalysisMode = state.ApiActionsStore.isLiveAnalysisMode;
    const camerasStreamsList = ownProps.camerasStreamsList;
    let selectedCamera = isLiveAnalysisMode ? ownProps.selectedCamera : undefined;
    let isExpanded = false;
    const screenId = ownProps.screenId;
    if (screenId && isLiveAnalysisMode) {
        const openDisplayInfo = state.GlobalStore.openDisplay[screenId];
        selectedCamera = selectedCamera || state.CamerasStore.cameras[openDisplayInfo.selectedCamera?.camera_id];
        isExpanded = openDisplayInfo.isExpanded;
    }

    return { camerasStreamsList, cameras: state.CamerasStore.cameras, selectedCamera, isExpanded, isLiveAnalysisMode };
};

export default connect(mapStateToProps, mapActionsToProps)(StreamDisplay);