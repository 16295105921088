import { createSlice } from "@reduxjs/toolkit";
import { has } from "lodash";

import { faceCropImg } from "@/Logic/ApiClients/EventsClient/EventsClient.model";
import { clearAlertsEvents } from "@/Logic/Hooks/useEventListener/useEventListener.utils";

const INITIAL_STATE = {
    max: 400,
    events: {},
    alertedEvents: {},
    generativeInsightsEvents: {},
    unacknowledgedAlertsCount: 0,
    isRepeatedSoundExist: false,
};

function getEventId(event) {
    return event.appearance_data.appearance_id;
}

const eventsSlice = createSlice({
    name: "events",
    initialState: INITIAL_STATE,
    reducers: {
        addEvent: (state, action) => {
        },
        addEvents: (state, action) => {
            const eventsBatch = action.payload;
            state.events = processEventsBatch(eventsBatch, state.events, state.max, getEventId);

            return state;
        },
        addAlertedEvents: (state, action) => {
            const alertedEvent = action.payload;
            const newAlertedEventId = getEventId(alertedEvent);
            const existingEvent = state.alertedEvents[newAlertedEventId];
            const updatedAlertedEvent = {
                ...alertedEvent,
                crop_data: {
                    ...alertedEvent.crop_data,
                    frame_timestamp: existingEvent ? existingEvent?.crop_data?.frame_timestamp : alertedEvent?.crop_data?.frame_timestamp
                },
                acknowledgement: {
                    isAcknowledged: existingEvent ? existingEvent?.acknowledgement?.isAcknowledged : false,
                    isSoundRepeat: existingEvent ? existingEvent?.acknowledgement?.isSoundRepeat : false
                }
            };

            if (!existingEvent) {
                state.unacknowledgedAlertsCount = state.unacknowledgedAlertsCount + 1;
            }

            state.alertedEvents = processEventsBatch([updatedAlertedEvent], state.alertedEvents, state.max, getEventId, false,
                (removedAppearanceId) => clearAlertsEvents(removedAppearanceId)
            );

            return state;
        },
        updateAlertsAcknowledgement: (state, action) => {
            const { alertId, isSoundRepeat, isAcknowledged } = action.payload;
            const existingEventData = state.alertedEvents[alertId];

            if (!existingEventData) {
                return;
            }
            const wasAcknowledged = existingEventData?.acknowledgement?.isAcknowledged || false;
            const updatedAcknowledgement = {
                ...existingEventData?.acknowledgement,
                ...(isAcknowledged !== undefined && { isAcknowledged }),
                ...(isSoundRepeat !== undefined && { isSoundRepeat })
            };

            state.alertedEvents[alertId] = {
                ...existingEventData,
                acknowledgement: updatedAcknowledgement
            };


            if (isAcknowledged && !wasAcknowledged) {
                state.unacknowledgedAlertsCount = Math.max(INITIAL_STATE.unacknowledgedAlertsCount, state.unacknowledgedAlertsCount - 1);
            } else if (!isAcknowledged && wasAcknowledged) {
                state.unacknowledgedAlertsCount = state.unacknowledgedAlertsCount + 1;
            }

            state.isRepeatedSoundExist = Object.values(state.alertedEvents).some((alertData) => alertData.acknowledgement?.isSoundRepeat === true);

            return state;
        },
        clearAlertedEvents: (state) => {
            state.alertedEvents = INITIAL_STATE.alertedEvents;
            state.unacknowledgedAlertsCount = INITIAL_STATE.unacknowledgedAlertsCount;
            state.isRepeatedSoundExist = INITIAL_STATE.isRepeatedSoundExist;
            clearAlertsEvents();

            return state;
        },
        clearEventsFeed: (state) => {
            state.events = INITIAL_STATE.events;

            return state;
        },
        clearGenerativeInsightsEvents: (state) => {
            state.generativeInsightsEvents = INITIAL_STATE.generativeInsightsEvents;

            return state;
        },
        addGenerativeInsightsEvent: (state, action) => {
        },
        addGenerativeInsightsEvents: (state, action) => {
            const eventsBatch = action.payload;
            const isInsightEvent = true;
            state.generativeInsightsEvents = processEventsBatch(eventsBatch, state.generativeInsightsEvents, state.max, (event) => event.event_id, isInsightEvent);

            return state;
        },
        removeEvent: (state, action) => {
            const { removedAppearanceId, shouldRemoveHistory } = action.payload;
            delete state.events[removedAppearanceId];
            if (shouldRemoveHistory && has(state.alertedEvents, removedAppearanceId)) {
                delete state.alertedEvents[removedAppearanceId];
                clearAlertsEvents(removedAppearanceId);
            }

            return state;
        },
        removeAlert: (state, action) => {
            const { removedAppearanceId, shouldRemoveHistory } = action.payload;
            delete state.alertedEvents[removedAppearanceId];
            state.unacknowledgedAlertsCount = Math.max(INITIAL_STATE.unacknowledgedAlertsCount, state.unacknowledgedAlertsCount - 1);
            state.isRepeatedSoundExist = Object.values(state.alertedEvents).some((alertData) => alertData.acknowledgement.isSoundRepeat === true);

            clearAlertsEvents(removedAppearanceId);
            if (shouldRemoveHistory) {
                delete state.events[removedAppearanceId];
            }

            return state;
        },
        updateEvent: (state, action) => {
            const { appearanceId, updateData } = action.payload;
            if (has(state.events, appearanceId)) {
                state.events[appearanceId] = {
                    ...state.events[appearanceId],
                    ...updateData
                };
            }

            if (has(state.alertedEvents, appearanceId)) {
                state.alertedEvents[appearanceId] = {
                    ...state.alertedEvents[appearanceId],
                    ...updateData
                };
            }

            return state;
        },
        clearAlertsAcknowledgement: (state, action) => {
            const { disableAlertsRepeatedSound = false } = action.payload;

            Object.keys(state.alertedEvents).forEach((alertId) => {
                const existingAcknowledgement = state.alertedEvents[alertId].acknowledgement || {};

                state.alertedEvents[alertId] = {
                    ...state.alertedEvents[alertId],
                    acknowledgement: {
                        ...existingAcknowledgement,
                        isAcknowledged: disableAlertsRepeatedSound ? existingAcknowledgement.isAcknowledged : true,
                        isSoundRepeat: false
                    }
                };
            });

            if (!disableAlertsRepeatedSound) {
                state.unacknowledgedAlertsCount = INITIAL_STATE.unacknowledgedAlertsCount;
            }

            state.isRepeatedSoundExist = INITIAL_STATE.isRepeatedSoundExist;

            return state;
        },
    }
});

function processEventsBatch(eventsBatch, eventsInStore, max, idSelector, insightEvent = false, eventCleanupFunction) {
    eventsBatch.forEach((event) => {
        const eventId = idSelector(event);
        if (eventsInStore.hasOwnProperty(eventId)) {
            if (!insightEvent && !event.updates?.crop_data?.includes(faceCropImg)) {
                event.crop_data = {
                    ...event.crop_data,
                    face_crop_img: eventsInStore[eventId].crop_data.face_crop_img
                };
            }
            eventsInStore[eventId] = event;
        } else {
            eventsInStore = { [eventId]: event, ...eventsInStore };
        }
    });
    removeOverflowEvents(eventsInStore, max, eventCleanupFunction);

    return eventsInStore;
}

function removeOverflowEvents(obj, max, eventCleanupFunction) {
    const keys = Object.keys(obj);
    while (keys.length > max) {
        const removedKey = keys.pop();
        delete obj[removedKey];
        eventCleanupFunction && eventCleanupFunction(removedKey);
    }
}

export const {
    addEvent,
    addAlertedEvents,
    clearAlertedEvents,
    clearEventsFeed,
    addGenerativeInsightsEvent,
    clearGenerativeInsightsEvents,
    addGenerativeInsightsEvents,
    updateEvent,
    removeEvent,
    removeAlert,
    isRepeatedIdSoundExist,
    clearAlertsAcknowledgement,
    updateAlertsAcknowledgement
} = eventsSlice.actions;

export const actionTypes = {
    addEvent: "events/addEvent",
    addEvents: "events/addEvents",
    addAlertedEvents: "events/addAlertedEvents",
    addGenerativeInsightsEvent: "events/addGenerativeInsightsEvent",
    addGenerativeInsightsEvents: "events/addGenerativeInsightsEvents"
};

export default eventsSlice.reducer;