import BaseBar from "../Bars/BaseBar";

function SwitchablePanel({ headerTitle, headerChildren, children }) {
    return (
        <div className="flex h-full flex-col">
            <BaseBar leftContent={headerTitle} rightContent={headerChildren} />
            <div className="mt-separator flex grow flex-col overflow-auto rounded-sm bg-panels">
                {children}
            </div>
        </div >
    );
}

export default SwitchablePanel;
