import { Badge } from "@mui/material";

function BadgeWrapper({ badgeContent, color, sx, children }) {
    return (
        <Badge
            badgeContent={badgeContent}
            color={color}
            sx={sx}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            {children}
        </Badge>
    );
}

export default BadgeWrapper;