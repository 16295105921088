import { ApiClient, RolesManagementApi } from "smart_cameras_users_api";

import { isEmpty, omit, pick } from "lodash";

import store from "../../../appConfig/configureStore";
import { usersRoute } from "../Infrastructure/networkConf";
import BaseAPIClient from "./BaseAPIClient";
import { parseResponseForUI, responsesListHandler } from "../Hooks/useUIResponseHandler";
import { CreateEditRoleFormFieldsNames } from "@/Components/Settings/Users/CreateEditRole/CreateEditRole.model";
import { setRoles } from "../Redux/Stores/UsersStore";
import { parseFieldIfDuplicate } from "./PrivacyProfilesClient";
import { filtersFormFieldsNames } from "@/Components/Events/EventsFeed/EventsFilters.model";
import { getCamerasFilterData, getFaceAttrFilters, matchesFilterParser } from "./EventsClient/EventsClient";

export class RolesClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(usersRoute, clientInstance);
        this.rolesManagementApi = new RolesManagementApi();
    }

    #parseRoleSettingObject(roleSettingObj, isCreate = false) {
        const { role_name, event_filters, ...permissionsData } = roleSettingObj;

        const roleDataFilters = !isEmpty(event_filters) ? this.#parseRoleDataFiltersData(event_filters) : {};
        const permissionsToAdd = [];
        const permissionsToRemove = [];

        for (const [permission, isChecked] of Object.entries(permissionsData)) {
            if (isChecked === true) {
                permissionsToAdd.push(permission);
            } else if (!isCreate && isChecked === false) {
                permissionsToRemove.push(permission);
            }
        }

        const roleParameters = {
            ...(role_name && { role_name }),
            ...(!isEmpty(roleDataFilters) && {
                role_data_filters: {
                    event_filters: {
                        ...roleDataFilters
                    }
                }
            }),
            permissionsToAdd,
            permissionsToRemove,
        };

        return roleParameters;
    }

    listAllRoles() {
        this.apiCall("List all roles",
            (callback) => this.rolesManagementApi.listAllRoles(callback),
            (_, data) => store.dispatch(setRoles(data.data.roles))
        );
    }

    async listAllPermissions() {
        return await this.apiCall("List all Permissions",
            (callback) => this.rolesManagementApi.listAllPermissions(callback),
            (error, data, response) => {
                parseResponseForUI(error, response, data.data);
                return data.data.permissions;
            }
        );
    }

    async addPermissionsToRole(roleId, addedPermissions) {
        const addedData = {
            permission_names: addedPermissions
        };

        return await this.apiCall("Add Permissions To Role",
            (callback) => this.rolesManagementApi.addRolePermission(roleId, addedData, callback),
            (error, __, response) => parseResponseForUI(error, response)
        );
    }

    async removePermissionsOfRole(roleId, removedPermissions) {
        const removeData = {
            permission_names: removedPermissions
        };

        return await this.apiCall("Remove Permissions of Role",
            (callback) => this.rolesManagementApi.removeRolePermission(roleId, removeData, callback),
            (error, __, response) => parseResponseForUI(error, response)
        );
    }

    async deleteRole(roleId) {
        this.apiCall("Delete Role",
            (callback) => this.rolesManagementApi.deleteRole(roleId, callback),
            () => this.listAllRoles()
        );
    }

    async getRole(roleId, isDuplicate) {
        return await this.apiCall("Get Role",
            (callback) => this.rolesManagementApi.getRole(roleId, callback),
            (error, data, response) => {
                const payload = data.data;
                parseFieldIfDuplicate(payload, CreateEditRoleFormFieldsNames.roleName, isDuplicate);
                parseResponseForUI(error, response, payload);
                return payload;
            }
        );
    }

    async createRole(roleSettingObj) {
        const { permissionsToAdd, ...otherParams } = this.#parseRoleSettingObject(roleSettingObj, true);
        const createRoleParameters = {
            permission_names: permissionsToAdd,
            ...otherParams
        };

        return await this.apiCall("Create Role",
            (callback) => this.rolesManagementApi.addRole(createRoleParameters, callback),
            (error, __, response) => parseResponseForUI(error, response)
        );
    }

    async editRole(roleId, roleSettingObj) {
        const roleSettingParams = this.#parseRoleSettingObject(roleSettingObj);
        const { permissionsToAdd, permissionsToRemove, ...restEditedData } = roleSettingParams;

        const addPermissionsResponse = !isEmpty(permissionsToAdd) ? this.addPermissionsToRole(roleId, permissionsToAdd) : [];
        const removePermissionsResponse = !isEmpty(permissionsToRemove) ? this.removePermissionsOfRole(roleId, permissionsToRemove) : [];

        const editRoleResponse = !isEmpty(restEditedData) ? this.apiCall("Edit Role",
            (callback) => this.rolesManagementApi.updateRole(roleId, restEditedData, callback),
            (error, __, response) => parseResponseForUI(error, response)
        ) : [];

        const responseList = await Promise.all([addPermissionsResponse, removePermissionsResponse, editRoleResponse]);

        return responsesListHandler(responseList);
    }

    #parseRoleDataFiltersData(roleDataFilters) {
        const { camerasFilter, matched, unauthorized, detections, facesAttrAgeFilter, facesAttrGenderFilter, facesAttrLivenessFilter } = filtersFormFieldsNames;
        const matchedFilters = pick(roleDataFilters, [detections, matched, unauthorized]);
        const restEventsFilters = omit(roleDataFilters, [detections, matched, unauthorized]);
        const parsedRestFiltersData = {
            camera_ids: getCamerasFilterData(undefined, restEventsFilters[camerasFilter]),
            age_outcomes: getFaceAttrFilters(restEventsFilters[facesAttrAgeFilter]),
            gender_outcomes: getFaceAttrFilters(restEventsFilters[facesAttrGenderFilter]),
            liveness_outcomes: getFaceAttrFilters(restEventsFilters[facesAttrLivenessFilter]),
            mask_outcomes: []
        };
        const updatedEventFilters = parseMatchEventFilter(parsedRestFiltersData, matchedFilters);

        return updatedEventFilters;
    }
}

const parseMatchEventFilter = (eventFilters, matchedEventFilters) => {
    const { matchedWatchlists, unauthorizedWatchlists, detectionsWatchlists } = matchesFilterParser(matchedEventFilters);
    const matchEventFilterExist = [matchedWatchlists, unauthorizedWatchlists, detectionsWatchlists].some((watchlist) => !isEmpty(watchlist));

    if (matchEventFilterExist) {
        eventFilters.matches = [
            ...matchedWatchlists,
            ...unauthorizedWatchlists,
            ...detectionsWatchlists
        ];
    }

    return eventFilters;
};

export const checkIsSystemRole = (id) => {
    const systemRoleIds = store.getState().UsersStore.systemRoleIds;

    return systemRoleIds.includes(id);
};