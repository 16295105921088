import { useCallback } from "react";

import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { allInvestigateVideos, endCamerasTitle, insightAnalysisMode, insightType, noInvestigateVideos, noLiveCameras, startCamera } from "src/appConfig/Strings";
import { generativeInsightTypes, insightActiveLive } from "@/Components/GenerativeInsights/CreateEditInsights/CreateEditInsight.model";
import { insightStatusTextMap } from "@/Components/GenerativeInsights/InsightsList/InsightsList.model";

function InsightCamerasColumn({ row, camerasStore, isLiveAnalysisMode }) {
    const processCameras = useCallback((cameras) => {
        const filteredCameras = cameras?.filter(camera => camera && camerasStore.cameras[camera]) || [];
        if (!filteredCameras.length) {
            let msg = noLiveCameras;
            if (!isLiveAnalysisMode) {
                msg = row[insightAnalysisMode] === insightStatusTextMap[insightActiveLive] ? noInvestigateVideos : allInvestigateVideos;
            }
            return msg;
        }

        return filteredCameras.map(camera => camerasStore.cameras[camera]?.description || camera).join(', ');
    }, [camerasStore.cameras, isLiveAnalysisMode, row]);

    const renderJourney = useCallback(() => {
        const startCameraElement = processCameras([row.start_camera]);
        const endCameraElements = processCameras(row.end_cameras);
        if (isEmpty(startCameraElement) || isEmpty(endCameraElements)) {
            return isLiveAnalysisMode ? noLiveCameras : noInvestigateVideos;
        }

        return (
            <div className="overflow-hidden">
                <span>
                    {startCamera}: {startCameraElement}
                </span>
                <div>
                    {endCamerasTitle}: {endCameraElements}
                </div>
            </div>
        );
    }, [isLiveAnalysisMode, processCameras, row.end_cameras, row.start_camera]);

    if (row[insightType] === generativeInsightTypes.personJourney) {
        return renderJourney();
    } else {
        return (
            <div className="overflow-hidden">
                {processCameras(isLiveAnalysisMode ? row.camera_ids : row.videos_ids)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const camerasStore = state.CamerasStore;
    const { isLiveAnalysisMode } = state.ApiActionsStore;

    return { camerasStore, isLiveAnalysisMode };
};

export default connect(mapStateToProps, null)(InsightCamerasColumn);