import { useEffect, useState } from "react";

import { drawerExpansionChange, drawerSelectionChange } from "src/appConfig/customEvents";
import useDrawerEvent from "./useDrawerEvent";

const useDrawer = () => {
    const [currentDrawerIndex, setCurrentDrawerIndex] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);

    useDrawerEvent(drawerSelectionChange, (drawerIndex) => {
        setCurrentDrawerIndex(drawerIndex);
        setIsExpanded(false);
    });

    useEffect(() => {
        const changeDrawerExpansion = (event) => {
            event.preventDefault();
            const { isExpanded } = event.detail;
            setIsExpanded(isExpanded);
        };

        document.addEventListener(drawerExpansionChange, changeDrawerExpansion);
        return () => document.removeEventListener(drawerExpansionChange, changeDrawerExpansion);
    }, []);

    return { currentDrawerIndex, setCurrentDrawerIndex, isExpanded };
};

export default useDrawer;

export function createDrawerChangeEvent(drawerIndex) {
    const changeDrawerEvent = new CustomEvent(drawerSelectionChange, { detail: { drawerIndex } });
    document.dispatchEvent(changeDrawerEvent);
}

export function createExpansionChangeEvent(isExpanded) {
    const expansionChangeEvent = new CustomEvent(drawerExpansionChange, { detail: { isExpanded } });
    document.dispatchEvent(expansionChangeEvent);
}