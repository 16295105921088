import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { Alert } from "@mui/material";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";

import { editPoiSuccessfulMessage, loading, poiFailedAddFaceImages } from "src/appConfig/Strings";
import { CreateEditPoiFormInputsModel } from "./CreateEditPOI.model";
import { useForm } from "@/Logic/Hooks/useForm";
import { clearOpenPoi, clearOpenPOIFailedFacesData } from "@/Logic/Redux/Stores/POIStore";
import ApiContext from "@/Logic/Context/apiContext";
import useUIResponseHandler from "@/Logic/Hooks/useUIResponseHandler";
import usePrepareCreateEditPoi from "@/Logic/Hooks/usePrepareCreateEditPoi";
import CropImage from "@/Components/Common/Images/CropImage";
import POIForm from "./POIForm/POIForm";
import { getWlPoisRequest } from "@/Logic/Hooks/useWatchlistPois";
import LoaderWithText from "@/Components/Common/Loaders/LoaderWithText";
import { routesUrl } from "@/Components/Common/Layout/SideBar/Routes";
import { poiPageSize } from "src/appConfig/constants";
import { poiDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";
import { dispatchIsLoadingEvent, dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";

export async function updateWlScreen(pathname, clientsManager, currWatchlistInfo) {
    if (pathname !== routesUrl.watchlistsScreen) {
        return;
    }

    if (!isEmpty(currWatchlistInfo.searchedName)) {
        await clientsManager.poisClient.searchPoiByName(currWatchlistInfo.searchedName, currWatchlistInfo.watchlistId, poiPageSize, true);
    } else {
        await getWlPoisRequest(clientsManager, currWatchlistInfo.afterId, currWatchlistInfo.watchlistId);
    }
    clientsManager.watchlistsClient.listAllWatchlists(true);
    clientsManager.controllerClient.getLicense();
}

function CreateEditPOI({ openPoi, poiId, failedData, clearOpenPoi, clearOpenPOIFailedFacesData, currWatchlistInfo }) {
    const { clientsManager } = useContext(ApiContext);
    const [shouldFetchPOIData, setShouldFetchPOIData] = useState(false);
    const location = useLocation();
    const poiIdRef = useRef(null);
    const { setIsLoading, responseMessage, error, successMsgs, parseResponse, clearResponseHandler, isLoading } = useUIResponseHandler();
    const { updatedOpenPoi } = usePrepareCreateEditPoi(poiId, openPoi, currWatchlistInfo, shouldFetchPOIData);

    const handleFormSubmit = useCallback(async (formObjData) => {
        setIsLoading(true);
        dispatchIsLoadingEvent(true);
        clearOpenPOIFailedFacesData();
        clearResponseHandler();
        let response;
        if (!poiId) {
            response = await clientsManager.poisClient.createPoi(formObjData);
        } else {
            response = await clientsManager.poisClient.editPoi(formObjData, updatedOpenPoi, poiId);
        }
        parseResponse(response);
        const isSubmittedSuccessfully = !response.error;

        if (isSubmittedSuccessfully) {
            dispatchToggleDialogEvent(poiDialogId, true, clearOpenPoi);
            await updateWlScreen(location.pathname, clientsManager, currWatchlistInfo);
        } else if (poiId) {
            setShouldFetchPOIData((prev) => !prev);
            if (!isEmpty(response.successMsgs)) {
                await updateWlScreen(location.pathname, clientsManager, currWatchlistInfo);
            }
        }

        setIsLoading(false);
        dispatchIsLoadingEvent(false);

        return isSubmittedSuccessfully;
    }, [clearOpenPOIFailedFacesData, clearOpenPoi, clearResponseHandler, clientsManager, currWatchlistInfo, location.pathname, parseResponse, poiId, setIsLoading, updatedOpenPoi]);

    const { formData, onFieldChange, submitForm, resetForm } = useForm(poiId, updatedOpenPoi, CreateEditPoiFormInputsModel, handleFormSubmit);

    const renderFailedImages = useCallback(() => (
        Object.keys(failedData).map((faceID) => <CropImage key={faceID} srcImage={failedData[faceID].image} />)
    ), [failedData]);

    useEffect(() => {
        if (!poiId) {
            return;
        }

        if (poiId !== poiIdRef.current) {
            resetForm();
            poiIdRef.current = poiId;
        }
    }, [poiId, resetForm]);

    useEffect(() => {
        return () => {
            clearOpenPoi();
            clearOpenPOIFailedFacesData();
        };
    }, []);

    return (
        <div className="size-full">
            <POIForm
                poiId={poiId}
                formData={formData}
                onFieldChange={onFieldChange}
                handleFormSubmit={submitForm}
            />
            {(responseMessage || (failedData && !isEmpty(failedData))) && (
                <div className="flex w-full flex-col items-center justify-center gap-2">
                    {!isEmpty(successMsgs) && (
                        <Alert className="mb-2 flex w-full items-center justify-center whitespace-pre-line" severity="success">
                            {successMsgs}
                        </Alert>
                    )}
                    <Alert className="mb-2 flex w-full items-center justify-center whitespace-pre-line" severity={error ? "error" : "success"}>
                        {error ? responseMessage : editPoiSuccessfulMessage}
                        {!isEmpty(failedData) && (
                            <div className="flex flex-col">
                                {poiFailedAddFaceImages}
                                <div className="flex">
                                    {renderFailedImages()}
                                </div>
                            </div>
                        )}
                    </Alert>
                </div>
            )}
            <div label="" className="justify-center">
                {isLoading && <LoaderWithText text={loading} className="!my-0" />}
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    clearOpenPoi,
    clearOpenPOIFailedFacesData,
};

const mapStateToProps = (state, ownProps) => {
    const { openPoi, poisPage, OpenPOIFailedFacesData: failedData } = state.POIStore;
    const poiId = ownProps.searchParams.get("poiId");

    return { openPoi, poiId, failedData, currWatchlistInfo: poisPage?.watchlistInfo };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditPOI);