import { useCallback, useContext, useMemo } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { addFaceToPoi, removeAppearance, retainAppearance, reviewAppearance, showPlayback, unretainAppearance, searchInHistory, searchInPoiDb, createNewPoi, goToCamera } from "src/appConfig/Strings";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import ApiContext from "@/Logic/Context/apiContext";
import { getColorFromMatchData } from "@/Logic/ApiClients/WatchlistsClient";
import { dispatchSetRemovedAppearanceDetailsEvent } from "../../Common/Modals/dialogs/AppearanceRemoveDialog";
import { dispatchRetainAppearanceDetailsEvent } from "@/Components/Common/Modals/dialogs/AppearanceRetainDialog";
import { lastAppearanceTrigger } from "@/Logic/ApiClients/EventsClient/EventsClient.model";
import { createIdentifyAppearanceImageCase, createSearchAppearanceImageCase } from "@/Logic/Hooks/useCaseInjector";
import { saveAlteredCaseEvent } from "src/appConfig/customEvents";
import { dispatchLabelAppearanceDetailsEvent } from "@/Components/Common/Modals/dialogs/labelAppearance/AppearanceLabelDialog";
import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";
import { loadPlaybackDialog } from "@/Components/PlaybackDialog/playback.utils";
import { alertsTable } from "@/Components/Common/MUITables/TablesLogic";
import { alertCenterDialogId, labelAppearanceDialog, removeAppearanceDialog, retainAppearanceDialog } from "@/Logic/Hooks/useDialog/useDialog.model";
import { activeCapturingStatuses } from "@/Logic/ApiClients/CamerasClient";
import { createEditPoiFromImageHandler } from "@/Logic/ApiClients/POIClient";
import { routesUrl } from "@/Components/Common/Layout/SideBar/Routes";
import { screenStepsMapping } from "@/Components/Common/ProgressStepper/ProgressStepper.model";

function EventsTableContextMenu({ event, cameras, tableType, isLiveAnalysisMode }) {
    const { clientsManager } = useContext(ApiContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { appearance_data: appearanceData, match_data: matchData, trigger, retain: isEventRetained = false, crop_data: cropData, camera_data: cameraData } = event;
    const hasImage = useMemo(() => Boolean(cropData.face_crop_img), [cropData.face_crop_img]);
    const isAlertsTable = tableType === alertsTable;

    const setOptions = useCallback(() => {
        const poiId = event.match_data?.poi_id;
        const cameraId = event.camera_data?.camera_id;
        const appearanceId = appearanceData.appearance_id;
        const isLastAppearance = trigger === lastAppearanceTrigger;
        const checkedCameraStatus = cameras?.[cameraId]?.camera_status?.status;
        const shouldShowGoToCamera = isAlertsTable && isLiveAnalysisMode && activeCapturingStatuses.includes(checkedCameraStatus);

        const options = {
            ...(shouldShowGoToCamera ? {
                [goToCamera]: () => {
                    navigate(`${routesUrl.eventList}?cameraId=${cameraId}`);
                    closeAlertsCenter();
                }
            } : {}),
            [createNewPoi]: () => {
                createEditPoiFromImageHandler({ selectedSteps: screenStepsMapping.newPoiContextMenu, faceImage: cropData.face_crop_img });
                isAlertsTable && closeAlertsCenter();
            },
            ...(poiId ? {
                [addFaceToPoi]: () => {
                    createEditPoiFromImageHandler({ clientsManager, poiId, selectedSteps: screenStepsMapping.existingPoiContextMenu, faceImage: cropData.face_crop_img, url: `${location.pathname}?poiId=${poiId}`, navigate: navigate });
                    isAlertsTable && closeAlertsCenter();
                }
            } : {}),
            [searchInHistory]: () => {
                document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent, { detail: { caseCreator: createSearchAppearanceImageCase, dataToChange: cropData.face_crop_img } }));
                isAlertsTable && closeAlertsCenter();
            },
            [searchInPoiDb]: () => {
                document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent, { detail: { caseCreator: createIdentifyAppearanceImageCase, dataToChange: cropData.face_crop_img } }));
                isAlertsTable && closeAlertsCenter();
            },
        };
        if (appearanceData.fs_store) {
            options[showPlayback] = () => loadPlaybackDialog(clientsManager, appearanceData.appearance_id, matchData.poi_display_name, getColorFromMatchData(matchData), cameraData.camera_description, matchData.utc_time_matched);
        }

        if (isLastAppearance) {
            options[isEventRetained ? unretainAppearance : retainAppearance] = () => {
                dispatchToggleDialogEvent(retainAppearanceDialog);
                dispatchRetainAppearanceDetailsEvent(appearanceId, tableType, isEventRetained);
            };
            options[reviewAppearance] = () => {
                dispatchToggleDialogEvent(labelAppearanceDialog);
                dispatchLabelAppearanceDetailsEvent(event, tableType);
            };
        }

        options[removeAppearance] = () => {
            dispatchToggleDialogEvent(removeAppearanceDialog);
            dispatchSetRemovedAppearanceDetailsEvent(appearanceId, tableType, isEventRetained, isLastAppearance);
        };

        return options;
    }, [appearanceData.appearance_id, appearanceData.fs_store, cameraData.camera_description, cameras, clientsManager, cropData.face_crop_img, event, isAlertsTable, isEventRetained, isLiveAnalysisMode, location.pathname, matchData, navigate, tableType, trigger]);

    const disabledOptions = useMemo(() => {
        return {
            [createNewPoi]: () => !hasImage,
            [addFaceToPoi]: () => !hasImage,
            [searchInHistory]: () => !hasImage,
            [searchInPoiDb]: () => !hasImage,
        };
    }, [hasImage]);

    return (
        <ContextMenuButton options={setOptions()} disabledOptions={disabledOptions} iconClassName={isAlertsTable ? "text-dodger-blue" : "text-white"} />
    );
}

const mapStateToProps = (state) => {
    const { cameras } = state.CamerasStore;
    const { isLiveAnalysisMode } = state.ApiActionsStore;

    return { cameras, isLiveAnalysisMode };
};

export default connect(mapStateToProps, null)(EventsTableContextMenu);

export const closeAlertsCenter = () => {
    dispatchToggleDialogEvent(alertCenterDialogId);
};