import { alertingNumberOfAppearancesRepeatedInfo, alertingNumberOfAppearancesTxt, alertingTimeFrameRepeatedInfo, alertingTimeFrameTxt, notAlertingTimeFrameRepeatedInfo, notAlertingTimeFrameTxt } from "src/appConfig/Strings";
import LabelWrapper from "@/Components/Common/Wrappers/LabelWrapper";
import BaseInput from "@/Components/Common/FormComponents/FormInputs/BaseInput";
import { repeatedIndividualFormFieldsNames, RepeatedIndividualInsightFormModel } from "./RepeatedIndividualInsight.model";
import { daysHoursMinSecTimeMapping } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector.model";
import { DurationSelector } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector";
import InsightCamerasSelector from "../../InsightCamerasSelector/InsightCamerasSelector";

const { alertingNumberOfAppearances, alertingTimeFrame, notAlertingTimeFrame } = repeatedIndividualFormFieldsNames;

function RepeatedIndividualInsight({ formData, onFieldChange }) {
    return (
        <div className="flex flex-col gap-1">
            <InsightCamerasSelector model={RepeatedIndividualInsightFormModel} formData={formData} onFieldChange={onFieldChange} />
            <LabelWrapper label={alertingNumberOfAppearancesTxt} tooltipTxt={alertingNumberOfAppearancesRepeatedInfo}>
                <BaseInput
                    label={alertingNumberOfAppearancesTxt}
                    model={RepeatedIndividualInsightFormModel[alertingNumberOfAppearances]}
                    value={formData[alertingNumberOfAppearances]}
                    onChange={(event) => onFieldChange(alertingNumberOfAppearances, event.target.valueAsNumber)}
                    className="w-[25rem]"
                />
            </LabelWrapper>
            <LabelWrapper label={alertingTimeFrameTxt} tooltipTxt={alertingTimeFrameRepeatedInfo}>
                <DurationSelector
                    model={RepeatedIndividualInsightFormModel[alertingTimeFrame]}
                    value={formData[alertingTimeFrame]}
                    onChange={(value) => onFieldChange(alertingTimeFrame, value)}
                    timeUnitsMap={daysHoursMinSecTimeMapping}
                />
            </LabelWrapper>
            <LabelWrapper label={notAlertingTimeFrameTxt} tooltipTxt={notAlertingTimeFrameRepeatedInfo}>
                <DurationSelector
                    model={RepeatedIndividualInsightFormModel[notAlertingTimeFrame]}
                    value={formData[notAlertingTimeFrame]}
                    onChange={(value) => onFieldChange(notAlertingTimeFrame, value)}
                    timeUnitsMap={daysHoursMinSecTimeMapping}
                />
            </LabelWrapper>
        </div>
    );
}

export default RepeatedIndividualInsight;