import { useCallback } from "react";

import store from "../../../../appConfig/configureStore";
import { uncertaintyOptions } from "../../../Logic/ApiClients/EventsClient/EventsClient";
import SelectFilter from "./SelectFilter";

function FaceFeatureOutcomeFilter({
    filterType,
    valueKey,
    categoryHeader,
    categoryRoleMatchesFilters,
    featureOutcomes,
    allOptionTitle,
    ...props
}) {
    const getCategoryFilter = useCallback(() => {
        const state = store.getState();
        const globalStoreFeatureOutcomes = state.GlobalStore?.featureOutcome;
        const roleMatchesFilters =
            state.UsersStore.roleDataFilters?.[filterType]?.[categoryRoleMatchesFilters];
        const outcomes = globalStoreFeatureOutcomes?.feature_outcomes?.[featureOutcomes] || [];

        const allCategoryFilterSelectionOption = {
            name: allOptionTitle,
            [valueKey]: null
        };
        const uncertaintyParsedOptions = globalStoreFeatureOutcomes?.uncertainty_options?.map((option) => ({
            name: uncertaintyOptions[option],
            [valueKey]: option
        })) || [];

        if (roleMatchesFilters?.length) {
            return roleMatchesFilters.map((outcome) => ({
                name: outcome,
                [valueKey]: outcome
            }));
        } else {
            return [
                allCategoryFilterSelectionOption,
                ...outcomes.map((outcome) => ({
                    name: outcome,
                    [valueKey]: outcome
                })),
                ...uncertaintyParsedOptions
            ];
        }
    }, [filterType, allOptionTitle, valueKey, featureOutcomes, categoryRoleMatchesFilters]);

    return <SelectFilter getOptionsFunc={getCategoryFilter} header={categoryHeader} {...props} />;
}

export default FaceFeatureOutcomeFilter;
