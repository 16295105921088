import { useMemo } from "react";

import InputAdornment from "@mui/material/InputAdornment";

import BaseInput from "./BaseInput";

function AffixInputField({ className, innerClassName, label, model, value, dataTestId, onChange = null, defaultValue = "", ...props }) {
    const { prefix, suffix, ...restModel } = model;

    const startAdornment = useMemo(() => (
        <InputAdornment position="start">
            {prefix}
        </InputAdornment>
    ), [prefix]);

    const endAdornment = useMemo(() => (
        <InputAdornment position="end">
            {suffix}
        </InputAdornment>
    ), [suffix]);

    return (
        <BaseInput
            className={className}
            InputProps={{
                startAdornment: prefix ? startAdornment : null,
                endAdornment: suffix ? endAdornment : null,
                inputProps: { className: innerClassName, "data-testid": dataTestId, ...restModel }
            }}
            label={label}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            model={restModel}
            {...props}
        />
    );
}

export default AffixInputField;