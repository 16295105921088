import { camera, notes } from "src/appConfig/Strings";
import OverflowingText from "../../Tooltips/OverflowingText";

function InsightDataColumn({
    generative_insight: { insight_name: insightName, insight_notes: insightNotes },
    curr_appearance: { camera_data: cameraData }
}) {

    return (
        <div className="flex flex-col gap-2 overflow-hidden">
            <span className="data-txt">
                <OverflowingText text={insightName} className="w-full" />
            </span>
            {cameraData?.camera_description && (
                <span>
                    {`${camera}: ${cameraData.camera_description}`}
                </span>
            )}
            {insightNotes?.free_notes && (
                <span>
                    {`${notes}: ${insightNotes.free_notes}`}
                </span>
            )}
        </div>
    );
}

export default InsightDataColumn;