"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _MsgMetadata = _interopRequireDefault(require("./MsgMetadata"));
var _Nodes = _interopRequireDefault(require("./Nodes"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Controller API
 * ## Control & Management - Status Endpoint allows the user of the API to see the very basic data of the deployment - and notably, the deployment_id - even if the user has no credentials or valid license - License Endpoint allows an authenticated user to view current license usage  ## System Settings **Privacy settings** - 'Privacy' is a global system mode that allows a user of the system to restrict the biometric data that is saved and/or outputted by SC. - The global system mode, as well as the specific privacy settings that control which biometric data the system is permitted to save and output, are configured using the privacy settings API of Controller Service.  **SearchFace settings** - Settings that apply to the SearchFace endpoints (in POI DB & History DB)  **Analysis settings** - System-wide settings related to the analysis of streams  ## Data Retention Perform actions & retrieve information regarding the data saved by the system - how many records exist, what is the max capcity, enable automatic clearing etc. 
 *
 * The version of the OpenAPI document: 0.36.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ListAllNodesResponse model module.
 * @module model/ListAllNodesResponse
 * @version 0.36.2
 */
class ListAllNodesResponse {
  /**
   * Constructs a new <code>ListAllNodesResponse</code>.
   * @alias module:model/ListAllNodesResponse
   * @param metadata {module:model/MsgMetadata} 
   * @param data {module:model/Nodes} 
   */
  constructor(metadata, data) {
    ListAllNodesResponse.initialize(this, metadata, data);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, metadata, data) {
    obj['metadata'] = metadata;
    obj['data'] = data;
  }

  /**
   * Constructs a <code>ListAllNodesResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ListAllNodesResponse} obj Optional instance to populate.
   * @return {module:model/ListAllNodesResponse} The populated <code>ListAllNodesResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ListAllNodesResponse();
      if (data.hasOwnProperty('metadata')) {
        obj['metadata'] = _MsgMetadata.default.constructFromObject(data['metadata']);
      }
      if (data.hasOwnProperty('data')) {
        obj['data'] = _Nodes.default.constructFromObject(data['data']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ListAllNodesResponse</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ListAllNodesResponse</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ListAllNodesResponse.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // validate the optional field `metadata`
    if (data['metadata']) {
      // data not null
      _MsgMetadata.default.validateJSON(data['metadata']);
    }
    // validate the optional field `data`
    if (data['data']) {
      // data not null
      _Nodes.default.validateJSON(data['data']);
    }
    return true;
  }
}
ListAllNodesResponse.RequiredProperties = ["metadata", "data"];

/**
 * @member {module:model/MsgMetadata} metadata
 */
ListAllNodesResponse.prototype['metadata'] = undefined;

/**
 * @member {module:model/Nodes} data
 */
ListAllNodesResponse.prototype['data'] = undefined;
var _default = exports.default = ListAllNodesResponse;