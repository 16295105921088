import { useCallback, useContext, useEffect, useState } from "react";

import ApiContext from "../Context/apiContext";
import useUIResponseHandler from "./useUIResponseHandler";

function useDetect({ image, processDetect }) {
    const { clientsManager } = useContext(ApiContext);
    const [detectedFaces, setDetectedFaces] = useState([]);
    const { isLoading, setIsLoading, responseMessage, error, parseResponse, clearResponseHandler } = useUIResponseHandler();

    const detectFace = useCallback(async () => {
        setIsLoading(true);
        const response = await clientsManager.faceClient.detectFaces([image]);
        if (response.error) {
            parseResponse(response);
        } else {
            const [detections] = response.data.detections;
            setDetectedFaces(detections);
        }
        setIsLoading(false);
    }, [clientsManager.faceClient, image, parseResponse, setIsLoading]);

    useEffect(() => {
        clearResponseHandler();
        setDetectedFaces([]);
        if (processDetect && image) {
            detectFace();
        }
    }, [clearResponseHandler, detectFace, image, processDetect]);

    return { detectedFaces, isLoading, setIsLoading, error, responseMessage };
}

export default useDetect;