import { useState } from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Button from '@mui/material/Button';

import FilledBtn from "../Buttons/FilledBtn";

function BaseModalOld(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [bodyInfo, setBodyInfo] = useState(false);

    async function toggle() {
        if (!isOpen && props.getBody) {
            setBodyInfo(await props.getBody());
        }
        setIsOpen((prev) => !prev);
    }

    function buttonAction() {
        if (props.buttonAction) {
            props.buttonAction();
        }
        if (props.closeOnAcion) {
            setIsOpen((prev) => !prev);
        }
    }

    return (
        <span>
            <Button className={`${props.buttonClass} mb-2 w-72 text-sm`} onClick={toggle} disabled={props.buttonDisabled ? props.buttonDisabled : false}>
                {props.icon ? props.icon : props.underlineButton ? <u>{props.buttonText}</u> : props.buttonText}
            </Button>
            <Modal isOpen={isOpen} className={props.className}>
                <ModalHeader close={<button className="hover:opacity-70" onClick={toggle}>X</button>}>
                    {props.title}
                </ModalHeader>
                <ModalBody>
                    {bodyInfo || props.modalBody}
                </ModalBody>
                {!props.noFooter && (
                    <ModalFooter>
                        {props.buttonLink && (
                            <a href={props.buttonLink} target="_blank" rel="noreferrer">
                                <button className="rounded bg-oceanic-azure p-2.5 px-4 py-2 text-white hover:bg-pie-line-bright-blue">
                                    {props.footerButtonText || 'Cancel'}
                                </button>
                            </a>
                        )}
                        {props.buttonAction &&
                            <FilledBtn onClick={buttonAction} >
                                {props.footerButtonText || 'Cancel'}
                            </FilledBtn>
                        }
                    </ModalFooter>
                )}
            </Modal>
        </span>
    );
}

export default BaseModalOld;