import { FaceCropImagesColumns, POICropImagesColumns } from "@/Components/Common/MUITables/Columns/CropImagesColumns/CropImagesColumns";
import { getPoiName } from "@/Components/Common/MUITables/Columns/PoiInfoColumn";
import TimeColumn from "@/Components/Common/MUITables/Columns/TimeColumn/TimeColumn";
import { processDataForTimeColumn } from "@/Components/Common/MUITables/Columns/TimeColumn/TimeColumn.utils";
import { getMatchedWatchlists } from "@/Components/Common/MUITables/Columns/WatchlistsColumn";
import OverflowingText from "@/Components/Common/Tooltips/OverflowingText";
import { trimNumber } from "@/Logic/Parsing/numbersParsing";
import { detectedFace, poi, similarityScore } from "src/appConfig/Strings";

function LabelImages({ appearance }) {
    return (
        <div className="flex w-11/12 justify-center gap-2">
            <div className="flex w-1/2 flex-col items-center">
                <span className="data-txt-black">{detectedFace}</span>
                <div className="main-text-style flex flex-col items-center">
                    <FaceCropImagesColumns {...appearance} />
                    <TimeColumn {...processDataForTimeColumn(appearance)} />
                    <OverflowingText
                        text={appearance.camera_data.camera_description}
                        className="max-w-60"
                    />
                </div>
            </div>

            {appearance.match_data.poi_confidence && (
                <div className="flex w-1/2 flex-col items-center">
                    <span className="data-txt-black">{poi}</span>
                    <div className="main-text-style flex w-full flex-col items-center">
                        <POICropImagesColumns {...appearance} />
                        <OverflowingText
                            text={getPoiName(appearance.match_data)}
                            className="max-w-60"
                        />
                        <span>
                            {similarityScore}: {trimNumber(appearance.match_data.poi_confidence)}
                        </span>
                        <span>
                            {getMatchedWatchlists(appearance.match_data.watchlists).map(
                                (watchlist) => (
                                    <OverflowingText
                                        key={watchlist.watchlist_id}
                                        text={watchlist.display_name}
                                        className="max-w-60"
                                    />
                                )
                            )}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default LabelImages;