import { useCallback, useEffect, useState } from "react";

import _ from "lodash";

import { isFormFieldsChanged } from "src/appConfig/customEvents";

export function useForm(identifier, initialFormData, modelData, handleFormSubmit, isDuplicate = false, shouldTrackChanges = true) {
    const [formData, setFormData] = useState(initialFormData);
    const [submittedFormData, setSubmittedFormData] = useState({});
    const [isFormChanged, setIsFormChanged] = useState(false);
    const isEdit = Boolean(identifier) && !isDuplicate;

    const initializeSubmittedFormData = useCallback(() => {
        if (isDuplicate) {
            setSubmittedFormData(initialFormData);
        } else {
            const requiredFields = Object.keys(modelData).reduce((acc, key) => {
                const field = modelData[key];
                if (Object.hasOwn(initialFormData, field.name) && field.required) {
                    acc[field.name] = initialFormData[field.name];
                }
                return acc;
            }, {});

            setSubmittedFormData(requiredFields);
        }
    }, [initialFormData, isDuplicate, modelData]);

    const onFieldChange = useCallback((fieldID, value) => {
        setFormData((prevData) => ({ ...prevData, [fieldID]: value }));
        setSubmittedFormData((prevData) => {
            const isFieldRequired = !isEdit && modelData[fieldID]?.required;
            const isValueChanged = initialFormData[fieldID] !== value;
            if (isFieldRequired || isValueChanged) {
                return { ...prevData, [fieldID]: value };
            }
            const { [fieldID]: _, ...rest } = prevData;

            return rest;
        });
    }, [initialFormData, isEdit, modelData]);

    useEffect(() => {
        const isFormDataChanged = !_.isEqual(formData, initialFormData);
        setIsFormChanged(isFormDataChanged);
        dispatchIsFormDataChangedEvent(isEdit, isFormDataChanged);
    }, [formData, initialFormData, isEdit]);

    useEffect(() => {
        if (!isFormChanged) {
            setFormData(initialFormData);
            if (!isEdit) {
                initializeSubmittedFormData();
            }
        }
    }, [initialFormData, initializeSubmittedFormData, isEdit, isFormChanged, shouldTrackChanges]);

    const isFormValidated = useCallback((submittedData) => {
        const isDataEmpty = _.isEmpty(submittedData);
        const noChangesFound = shouldTrackChanges && !isFormChanged;
        if (isDataEmpty || noChangesFound) {
            return false;
        }

        return Object.keys(modelData).every((key) => {
            const field = modelData[key];
            return field.validator ? field.validator(formData, field.name) : true;
        });
    }, [formData, isFormChanged, modelData, shouldTrackChanges]);

    const submitForm = useCallback(async (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!isFormValidated(submittedFormData)) {
            return;
        }

        const isSubmittedSuccessfully = await handleFormSubmit(submittedFormData, formData);

        //todo! Remove this reset states after poi will be dialog, then it will be automatically when dialog will close 
        if (isSubmittedSuccessfully) {
            setIsFormChanged(false);
            setSubmittedFormData({});
        }
    }, [formData, handleFormSubmit, isFormValidated, submittedFormData]);


    const resetForm = useCallback(() => {
        setFormData(initialFormData);
        setIsFormChanged(false);
        setSubmittedFormData({});
    }, [initialFormData]);

    return { submitForm, formData, submittedFormData, onFieldChange, resetForm };
}

export function dispatchIsFormDataChangedEvent(isEdit, isChanged) {
    const isFormDataChangedEvent = new CustomEvent(isFormFieldsChanged, { detail: { isEdit, isChanged } });
    document.dispatchEvent(isFormDataChangedEvent);
}