import { ReactComponent as IconCamera } from 'src/assets/Icons/NavIcons/icon_camera.svg';
import { ReactComponent as IconDisplay } from 'src/assets/Icons/NavIcons/icon_display.svg';
import { ReactComponent as IconEvents } from 'src/assets/Icons/NavIcons/icon_events.svg';
import { ReactComponent as IconPOI } from 'src/assets/Icons/NavIcons/icon_POI.svg';
import { ReactComponent as IconSetting } from 'src/assets/Icons/NavIcons/icon_settings.svg';
import { ReactComponent as IconHistory } from 'src/assets/Icons/NavIcons/icon_history.svg';
import { ReactComponent as IconInsights } from 'src/assets/Icons/NavIcons/icon_insights.svg';

import { routesUrl } from "../Routes";
import {
    camerasHeader, camerasList, configurationHeader, createNewCase, createWatchlistHeader, dataRetention, displayHeader,
    eventsHeader, eventsList, generativeInsightsEventsFeed, generativeInsightsHeader, generativeInsightsList, generativeInsightsSearch,
    importPOIHeader, licenseHeader, mainCasesHeader, poisHeader, privacyHeader, serversHeader, settingsHeader,
    systemInfoHeader, usersHeader, watchlistsHeader, disabledDisplayInvestigate, videos, videosList,
} from "src/appConfig/Strings.js";

const getSavedCasesContent = (savedCases) => {
    if (Object.keys(savedCases).length === 0) {
        return [];
    }

    return Object.entries(savedCases).map(([caseID, caseName]) => ({
        label: caseName,
        path: `${routesUrl.casesScreen}?caseID=${caseID}`
    }));
};

export const SideNavigationItems = (SearchCaseStore, navigationProps) => {
    const { isLiveAnalysisMode } = navigationProps;

    return [
        {
            icon: <IconCamera className="stroke-soft-sky-blue" />,
            label: isLiveAnalysisMode ? camerasHeader : videos,
            content: [
                {
                    label: isLiveAnalysisMode ? camerasList : videosList,
                    path: routesUrl.cameraList,
                }
            ]
        },
        {
            icon: <IconDisplay className="stroke-soft-sky-blue" />,
            label: displayHeader,
            disabled: !isLiveAnalysisMode,
            disabledText: disabledDisplayInvestigate,
            content: [
                {
                    label: displayHeader,
                    path: routesUrl.displayCameras,
                }
            ]
        },
        {
            icon: <IconEvents className="stroke-soft-sky-blue" />,
            label: eventsHeader,
            content: [
                {
                    label: eventsList,
                    path: routesUrl.eventList,
                }
            ]
        },
        {
            icon: <IconPOI className="stroke-soft-sky-blue" />,
            label: poisHeader,
            content: [
                {
                    label: watchlistsHeader,
                    path: routesUrl.watchlistsScreen,
                },
                {
                    label: createWatchlistHeader,
                    path: routesUrl.createEditWatchlist,
                },
                {
                    label: importPOIHeader,
                    path: routesUrl.importPois,
                }
            ]
        },
        {
            icon: <IconHistory className="stroke-soft-sky-blue" />,
            label: mainCasesHeader,
            path: routesUrl.casesScreen,
            content: [
                {
                    label: createNewCase,
                    path: routesUrl.casesScreen
                },
                ...getSavedCasesContent(SearchCaseStore.savedCases)
            ]
        },
        {
            icon: <IconInsights className="stroke-soft-sky-blue" />,
            label: generativeInsightsHeader,
            content: [
                {
                    label: generativeInsightsList,
                    path: routesUrl.generativeInsightsList
                },
                {
                    label: generativeInsightsEventsFeed,
                    path: routesUrl.generativeInsightsEvents
                },
                {
                    label: generativeInsightsSearch,
                    path: routesUrl.generativeInsightsSearch
                }
            ]
        },
        {
            icon: <IconSetting className="stroke-soft-sky-blue" />,
            label: settingsHeader,
            content: [
                {
                    label: systemInfoHeader,
                    path: routesUrl.settingsSystemInfo
                },
                {
                    label: licenseHeader,
                    path: routesUrl.settingsLicense
                },
                {
                    label: configurationHeader,
                    path: routesUrl.settingsConfiguration
                },
                {
                    label: usersHeader,
                    path: routesUrl.settingsUsers
                },
                {
                    label: privacyHeader,
                    path: routesUrl.settingsPrivacy
                },
                {
                    label: dataRetention,
                    path: routesUrl.settingsDataRetention
                },
                {
                    label: serversHeader,
                    path: routesUrl.serverStatus
                }
            ]
        },
    ];
};