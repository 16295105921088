import { baseCamerasFieldNames, baseLiveCamerasModel, baseVideosModel } from "../../BaseModels";

export const repeatedIndividualFormFieldsNames = {
    ...baseCamerasFieldNames,
    alertingTimeFrame: "alerting_time_frame",
    notAlertingTimeFrame: "not_alerting_time_frame",
    alertingNumberOfAppearances: "alerting_number_of_appearances",
};

export const RepeatedIndividualInsightFormModel = {
    [repeatedIndividualFormFieldsNames.cameras]: {
        ...baseLiveCamerasModel
    },
    [repeatedIndividualFormFieldsNames.videos]: {
        ...baseVideosModel
    },
    [repeatedIndividualFormFieldsNames.alertingTimeFrame]: {
        name: repeatedIndividualFormFieldsNames.alertingTimeFrame,
        type: "number",
        min: 0,
    },
    [repeatedIndividualFormFieldsNames.notAlertingTimeFrame]: {
        name: repeatedIndividualFormFieldsNames.notAlertingTimeFrame,
        type: "number",
        min: 0,
    },
    [repeatedIndividualFormFieldsNames.alertingNumberOfAppearances]: {
        name: repeatedIndividualFormFieldsNames.alertingNumberOfAppearances,
        type: "number",
        min: 0,
        required: true,
    },
};