"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AppearanceRoleFilters = _interopRequireDefault(require("./AppearanceRoleFilters"));
var _WatchlistsQuery = _interopRequireDefault(require("./WatchlistsQuery"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The EventFilters model module.
 * @module model/EventFilters
 * @version 0.35.0
 */
class EventFilters {
  /**
   * Constructs a new <code>EventFilters</code>.
   * The allowed event filters for this role
   * @alias module:model/EventFilters
   * @implements module:model/AppearanceRoleFilters
   */
  constructor() {
    _AppearanceRoleFilters.default.initialize(this);
    EventFilters.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>EventFilters</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EventFilters} obj Optional instance to populate.
   * @return {module:model/EventFilters} The populated <code>EventFilters</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EventFilters();
      _AppearanceRoleFilters.default.constructFromObject(data, obj);
      if (data.hasOwnProperty('camera_ids')) {
        obj['camera_ids'] = _ApiClient.default.convertToType(data['camera_ids'], ['String']);
      }
      if (data.hasOwnProperty('age_outcomes')) {
        obj['age_outcomes'] = _ApiClient.default.convertToType(data['age_outcomes'], ['String']);
      }
      if (data.hasOwnProperty('gender_outcomes')) {
        obj['gender_outcomes'] = _ApiClient.default.convertToType(data['gender_outcomes'], ['String']);
      }
      if (data.hasOwnProperty('mask_outcomes')) {
        obj['mask_outcomes'] = _ApiClient.default.convertToType(data['mask_outcomes'], ['String']);
      }
      if (data.hasOwnProperty('liveness_outcomes')) {
        obj['liveness_outcomes'] = _ApiClient.default.convertToType(data['liveness_outcomes'], ['String']);
      }
      if (data.hasOwnProperty('matches')) {
        obj['matches'] = _ApiClient.default.convertToType(data['matches'], [_WatchlistsQuery.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>EventFilters</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>EventFilters</code>.
   */
  static validateJSON(data) {
    // ensure the json data is an array
    if (!Array.isArray(data['camera_ids'])) {
      throw new Error("Expected the field `camera_ids` to be an array in the JSON data but got " + data['camera_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['age_outcomes'])) {
      throw new Error("Expected the field `age_outcomes` to be an array in the JSON data but got " + data['age_outcomes']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['gender_outcomes'])) {
      throw new Error("Expected the field `gender_outcomes` to be an array in the JSON data but got " + data['gender_outcomes']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['mask_outcomes'])) {
      throw new Error("Expected the field `mask_outcomes` to be an array in the JSON data but got " + data['mask_outcomes']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['liveness_outcomes'])) {
      throw new Error("Expected the field `liveness_outcomes` to be an array in the JSON data but got " + data['liveness_outcomes']);
    }
    if (data['matches']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['matches'])) {
        throw new Error("Expected the field `matches` to be an array in the JSON data but got " + data['matches']);
      }
      // validate the optional field `matches` (array)
      for (const item of data['matches']) {
        _WatchlistsQuery.default.validateJsonObject(item);
      }
      ;
    }
    return true;
  }
}

/**
 * list of camera_ids to subscribe to
 * @member {Array.<String>} camera_ids
 */
EventFilters.prototype['camera_ids'] = undefined;

/**
 * List of the age outcomes that we want to subscribe to
 * @member {Array.<String>} age_outcomes
 */
EventFilters.prototype['age_outcomes'] = undefined;

/**
 * List of the gender outcomes that we want to subscribe to
 * @member {Array.<String>} gender_outcomes
 */
EventFilters.prototype['gender_outcomes'] = undefined;

/**
 * List of the mask outcomes that we want to subscribe to
 * @member {Array.<String>} mask_outcomes
 */
EventFilters.prototype['mask_outcomes'] = undefined;

/**
 * List of the liveness outcomes that we want to subscribe to
 * @member {Array.<String>} liveness_outcomes
 */
EventFilters.prototype['liveness_outcomes'] = undefined;

/**
 * List of matches types that we want to subscribe to.
 * @member {Array.<module:model/WatchlistsQuery>} matches
 */
EventFilters.prototype['matches'] = undefined;

// Implement AppearanceRoleFilters interface:
/**
 * list of camera_ids to subscribe to
 * @member {Array.<String>} camera_ids
 */
_AppearanceRoleFilters.default.prototype['camera_ids'] = undefined;
/**
 * List of the age outcomes that we want to subscribe to
 * @member {Array.<String>} age_outcomes
 */
_AppearanceRoleFilters.default.prototype['age_outcomes'] = undefined;
/**
 * List of the gender outcomes that we want to subscribe to
 * @member {Array.<String>} gender_outcomes
 */
_AppearanceRoleFilters.default.prototype['gender_outcomes'] = undefined;
/**
 * List of the mask outcomes that we want to subscribe to
 * @member {Array.<String>} mask_outcomes
 */
_AppearanceRoleFilters.default.prototype['mask_outcomes'] = undefined;
/**
 * List of the liveness outcomes that we want to subscribe to
 * @member {Array.<String>} liveness_outcomes
 */
_AppearanceRoleFilters.default.prototype['liveness_outcomes'] = undefined;
/**
 * List of matches types that we want to subscribe to.
 * @member {Array.<module:model/WatchlistsQuery>} matches
 */
_AppearanceRoleFilters.default.prototype['matches'] = undefined;
var _default = exports.default = EventFilters;