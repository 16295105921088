"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetLicenseDataResponse = _interopRequireDefault(require("../model/GetLicenseDataResponse"));
var _GetStatusResponse = _interopRequireDefault(require("../model/GetStatusResponse"));
var _ListAllNodesResponse = _interopRequireDefault(require("../model/ListAllNodesResponse"));
var _NodeDataResponse = _interopRequireDefault(require("../model/NodeDataResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Controller API
 * ## Control & Management - Status Endpoint allows the user of the API to see the very basic data of the deployment - and notably, the deployment_id - even if the user has no credentials or valid license - License Endpoint allows an authenticated user to view current license usage  ## System Settings **Privacy settings** - 'Privacy' is a global system mode that allows a user of the system to restrict the biometric data that is saved and/or outputted by SC. - The global system mode, as well as the specific privacy settings that control which biometric data the system is permitted to save and output, are configured using the privacy settings API of Controller Service.  **SearchFace settings** - Settings that apply to the SearchFace endpoints (in POI DB & History DB)  **Analysis settings** - System-wide settings related to the analysis of streams  ## Data Retention Perform actions & retrieve information regarding the data saved by the system - how many records exist, what is the max capcity, enable automatic clearing etc. 
 *
 * The version of the OpenAPI document: 0.36.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* GeneralSystemStatus service.
* @module api/GeneralSystemStatusApi
* @version 0.36.2
*/
class GeneralSystemStatusApi {
  /**
  * Constructs a new GeneralSystemStatusApi. 
  * @alias module:api/GeneralSystemStatusApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the getLicense operation.
   * @callback module:api/GeneralSystemStatusApi~getLicenseCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetLicenseDataResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get License Data
   * Get the general system data, including version and current license data
   * @param {module:api/GeneralSystemStatusApi~getLicenseCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetLicenseDataResponse}
   */
  getLicense(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetLicenseDataResponse.default;
    return this.apiClient.callApi('/license/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getNodeData operation.
   * @callback module:api/GeneralSystemStatusApi~getNodeDataCallback
   * @param {String} error Error message, if any.
   * @param {module:model/NodeDataResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Node Data
   * Get The data of a specific node
   * @param {Object} opts Optional parameters
   * @param {Object} opts.nodeId 
   * @param {module:api/GeneralSystemStatusApi~getNodeDataCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/NodeDataResponse}
   */
  getNodeData(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'node_id': opts['nodeId']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _NodeDataResponse.default;
    return this.apiClient.callApi('/node/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getStatus operation.
   * @callback module:api/GeneralSystemStatusApi~getStatusCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetStatusResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get General Status
   * Get the general system data, including version and deployment id
   * @param {module:api/GeneralSystemStatusApi~getStatusCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetStatusResponse}
   */
  getStatus(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GetStatusResponse.default;
    return this.apiClient.callApi('/status/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the listAllNodes operation.
   * @callback module:api/GeneralSystemStatusApi~listAllNodesCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ListAllNodesResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List All Nodes
   * Get all the node ids of the running deployment
   * @param {module:api/GeneralSystemStatusApi~listAllNodesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ListAllNodesResponse}
   */
  listAllNodes(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ListAllNodesResponse.default;
    return this.apiClient.callApi('/nodes/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = GeneralSystemStatusApi;