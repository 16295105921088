const SPACES_AND_DASHES_REGEX = /[_\s]+/;

export function splitString(word, splitBy = SPACES_AND_DASHES_REGEX, capitalize = true) {
    let splitWord = word.split(splitBy);
    if (capitalize) {
        splitWord = capitalizeWords(splitWord);
    }
    return splitWord.join(" ");
}

export function capitalizeWord(word) {
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
}

export function capitalizeWords(words) {
    return words.map((word) => capitalizeWord(word));
}

export function hexToBGR(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return {
        blue: Number.parseInt(result[3], 16),
        green: Number.parseInt(result[2], 16),
        red: Number.parseInt(result[1], 16),
    };
}

export function RGBToHex(rgb) {
    return '#' + Object.values(rgb).map(color => {
        const hex = color.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }).join('');
}

export function ParseRtspUrl(displayUrl, host) {
    const [usernamePassword, ipPortType] = displayUrl.split("@");
    const [, portType] = ipPortType.split(":");
    const [parsedHost,] = host.split(":");
    return `${usernamePassword}@${parsedHost}:${portType}`;
}

export function formatDataTestId(inputString) {
    const stringToFormat = inputString || "";

    return stringToFormat.replace(/[\s_]+/g, '-').toLowerCase();
}

export function getFileNameFromPath(filePath) {
    return filePath.split('/').pop();
}

export const removeFileNameExtensionRegex = /\.[^/.]+$/;
