import { startCase } from "lodash";

import { getCamerasNames } from "@/Logic/ApiClients/CamerasClient";
import { getWatchlistsNames } from "@/Logic/ApiClients/WatchlistsClient";

import { allAgeGroups, allAppearancesFilterOption, allCamerasFilterOption, allLivenessOutcomes, allWatchlistFilterOption, imageWithMultipleFaces, matchParameters, noDetections, poiSignature } from "src/appConfig/Strings";

export function parseFilter(filter, getNamesFunction, defaultOption) {
    return filter[0] !== null ? getNamesFunction(filter) : [defaultOption];
}

export function parseOutcomesFilter(filter, defaultOption, transformFunction = (value) => startCase(value)) {
    return filter[0] !== null ? filter.map(transformFunction) : [defaultOption];
}

export function parseBaseFilters({ camerasFilter, matchFilter, notMatchFilter, unauthorizedFilter, detectionFilter, ageGroupFilter, livenessFilter, genderFilter }) {
    return {
        camerasFilter: parseFilter(camerasFilter, getCamerasNames, allCamerasFilterOption),
        matchFilter: parseFilter(matchFilter, getWatchlistsNames, allWatchlistFilterOption),
        notMatchFilter: parseFilter(notMatchFilter, getWatchlistsNames, allWatchlistFilterOption),
        unauthorizedFilter: parseFilter(unauthorizedFilter, getWatchlistsNames, allWatchlistFilterOption),
        detectionFilter: detectionFilter && detectionFilter[0] === null ? [allAppearancesFilterOption] : [noDetections],
        ageGroupFilter: parseOutcomesFilter(ageGroupFilter, allAgeGroups, (ageRange) => (ageRange)),
        livenessFilter: parseOutcomesFilter(livenessFilter, allLivenessOutcomes),
        genderFilter: parseOutcomesFilter(genderFilter, allLivenessOutcomes),
    };
}

export function matchParametersFilters(filtersData) {
    const matchParams = {};

    if (filtersData[matchParameters] || filtersData[poiSignature]?.useSig) {
        matchParams.matchThreshold = [filtersData[matchParameters]?.threshold || filtersData[poiSignature].matchParametersData?.threshold];
        matchParams.maxMatches = [filtersData[matchParameters]?.maxMatches || filtersData[poiSignature].matchParametersData?.maxMatches];
    }

    return matchParams;
}

export function getSearchWithImageOrPoi(filtersData) {
    let searchWithImgOrPoi = {};

    if (filtersData.Image || filtersData[imageWithMultipleFaces]) {
        searchWithImgOrPoi = { searchWithImage: filtersData.Image || filtersData[imageWithMultipleFaces] };
    } else if (filtersData.POI?.selectedPoi?.display_img) {
        searchWithImgOrPoi = { searchWithPoi: filtersData.POI.selectedPoi.display_img };
    }

    return searchWithImgOrPoi;
}