import { startCase, capitalize } from "lodash";

import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import {
    actions, appearancesCountHeader, appearanceSimilarityScore,
    cameraInfo, cameras, camerasList, source, crop, detectedFace, faceAttributes, generativeInsightsHeader,
    historyList, insightActive, insightName, insightNotes, insightsData, insightType, lastUpdateTime, matchesHeader,
    nodeId, poiImg, poiInfo, serverStatus, status, time, topMatch, topMatchInformation, usedFaceImage,
    watchlistColor, watchlistInfo, watchlistName, watchlistPoisNumber, watchlistsHeader, profileName, appearancesPrivacy, poisPrivacy, username, role, fullName, phoneNumber,
    email, userState, usersHeader, rolesHeader, roleName, dataFiltersApplied, disabled,
    defaultAppearanceProfile, poiPrivacyConfiguration, activePoiPrivacyProfile, userStatus, streams,
    historyHeader, ProfileConfiguration, progress, enabled, storedRecords, recordsCapacity, usage, recordedData,
    retentionConfig, retentionValue, retentionSettings, nameTxt, insightTime, eventsHeader, appearanceNotes, reviewAppearance,
    dateAndTime, poiDB, insightCameras, videos, insightAnalysisMode, generateAlertLabel, minimum, maximum, average, colorTitle, watchlistTypeLabel, alertedWatchList,
    poiProximityName, notes, poiAlertDetailLabel, acknowledgeAlertLabel, videoInfo, stabilityLabel, serverType, notIn, selectWatchlistHeader, nodeId as nodeIdStr
} from "src/appConfig/Strings";
import FaceBoundingBoxCanvas from "../../Faces/FaceBoundingBoxCanvas";
import CameraStatusColumn, { statusToPropsMap } from "./CameraStatusColumn";
import { FaceCropImagesColumns, POICropImagesColumns } from "./CropImagesColumns/CropImagesColumns";
import FaceAttributesColumn, { processFaceAttrColData } from "./FaceAttributesColumn";
import InsightDataColumn from "./InsightDataColumn";
import PoiInfoColumn, { poiMatchValueGetter, processMatchData } from "./PoiInfoColumn";
import TimeColumn from "./TimeColumn/TimeColumn";
import CropImage from "../../Images/CropImage";
import { loggedIn } from "@/Logic/ApiClients/UsersClient";
import PrivacyConfigurationColumn from "@/Components/Common/MUITables/Columns/privacyConfigurationColumn";
import StatusColumn from "./StatusColumn";
import UsersContextMenu from "@/Components/Settings/Users/UsersContextMenu";
import RolesContextMenu from "@/Components/Settings/Users/RolesContextMenu";
import PrivacyContextMenu from "@/Components/Settings/Privacy/PrivacyContextMenu";
import { appearancePrivacyNameMapping } from "@/Components/Settings/Privacy/CreateEditAppearancesPrivacy/CreateEditAppearancesPrivacy.model";
import { watchlistPrivacyNameMapping } from "@/Components/Settings/Privacy/CreateEditWatchlistPrivacy/CreateEditWatchlistPrivacy.model";
import { getCurrentRecords, getMaxRecords } from "@/Logic/ApiClients/DataRetentionClient";
import SystemStorageContextMenu from "@/Components/Settings/DataRetention/SystemStorageContextMenu/SystemStorageContextMenu";
import { ttlSettingsMapping } from "@/Logic/Redux/Stores/DataRetentionStore";
import DataRetentionConfigContextMenu from "@/Components/Settings/DataRetention/DataRetentionConfigContextMenu";
import InfoTooltip from "../../Tooltips/InfoTooltip";
import CameraProgressColumn from "./CameraProgressColumn";
import InsightsSearchTimeColumn from "./InsightsSearchTimeColumn";
import EventsTableContextMenu from "@/Components/Events/EventsFeed/EventsTableContextMenu";
import { processDataForTimeColumn } from "./TimeColumn/TimeColumn.utils";
import { trimNumber } from "@/Logic/Parsing/numbersParsing";
import WatchlistsColumn, { processWatchlistsData } from "./WatchlistsColumn";
import InsightsContextsMenu from "@/Components/GenerativeInsights/InsightsContextsMenu/InsightsContextsMenu";
import CameraContextMenu from "@/Components/Cameras/CamerasList/CameraContextMenu";
import WatchlistContextMenu from "@/Components/POIs/Watchlists/WatchlistContextMenu";
import { hexToBGR, RGBToHex, splitString } from "@/Logic/Parsing/stringsParsing";
import CameraInfoColumn, { processCameraInfoData } from "./CameraInfoColumn";
import IdentifyContextMenu from "@/Components/Cases/Results/MultipleFacesIdentifyList/IdentifyContextMenu";
import HistoryListContextMenu from "@/Components/History/HistoryList/HistoryListContextMenu";
import InsightCamerasColumn from "./InsightCamerasColumn";
import { getInsightsStatisticsRowData, insightsStatisticsKey } from '@/Components/GenerativeInsights/InsightsSearch/SearchAnalytics/SearchAnalytics.model';
import { createWatchlistDefaultValues, watchlistDisplayType, watchlistSeverityOptions } from "@/Components/POIs/Watchlists/CreateEditWatchlist/CreateWatchlist.model";
import OverflowingText from "../../Tooltips/OverflowingText";
import { generativeInsightTypes } from "@/Components/GenerativeInsights/CreateEditInsights/CreateEditInsight.model";
import PoiAlertDetailColumn, { poiAlertDetailValueGetter } from "./PoiAlertDetailColumn";
import AcknowledgeAlertColumn from "./AcknowledgeAlertColumn";
import { cameraNodeId } from "@/Components/Cameras/CamerasList/CamerasList.model";
import ServerStatusStabilityColumn from "./ServerStatusStabilityColumn";
import { getServerStatusAndStability } from "@/Components/Servers/ServersNodesList/ServersNodesList";
import { getPoiDisplayInfo } from "./CropImagesColumns/CropImagesColumns.utils";

export const ColumnsDefinitions = {
    [dateAndTime]: {
        headerName: dateAndTime,
        field: dateAndTime,
        minWidth: 156,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: ({ value }) => <TimeColumn timeAndDate={value} className="gap-2" />,
        valueGetter: ({ row }) => {
            const { date, timeRange, isInvestigate } = processDataForTimeColumn(row);
            return `${isInvestigate ? "" : date + "\n"}${timeRange}`;
        },
    },
    [cameras]: {
        headerName: nameTxt,
        field: cameras,
        flex: 1,
        minWidth: 170,
        renderCell: ({ row }) => <OverflowingText className="w-full " text={row.description} />,
    },
    [cameraInfo]: ({ isLiveAnalysisMode }) => {
        return {
            headerName: isLiveAnalysisMode ? cameraInfo : videoInfo,
            headerAlign: "center",
            field: cameraInfo,
            flex: 1,
            minWidth: 170,
            sortable: false,
            renderCell: ({ row }) => <CameraInfoColumn {...processCameraInfoData(row.camera_data)} />,
            valueGetter: ({ row }) => {
                const { displayName, notesData, nodeId } = processCameraInfoData(row.camera_data);
                let valueGetterData = displayName;
                if (nodeId) {
                    valueGetterData += `\n${nodeIdStr}: ${nodeId}`;
                }
                if (notesData) {
                    valueGetterData += `\n${notes}: ${notesData}`;
                }
                return valueGetterData;
            },
        };
    },
    [cameraNodeId]: {
        headerName: nodeId,
        field: "node_id",
        flex: 1,
        sortable: true,
    },
    [progress]: {
        headerName: progress,
        field: progress,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => <CameraProgressColumn camera_status={row.camera_status} />
    },
    [crop]: {
        headerName: detectedFace,
        headerAlign: "center",
        field: crop,
        minWidth: 130,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => <FaceCropImagesColumns {...row} />,
        valueGetter: ({ row }) => row.crop_data?.face_crop_img
    },
    [poiImg]: {
        headerName: poiImg,
        field: poiImg,
        minWidth: 130,
        sortable: false,
        filterable: false,
        headerAlign: "center",
        renderCell: ({ row }) => <POICropImagesColumns {...row} />,
        valueGetter: ({ row }) => {
            let poiValueGetterRes;
            const { isTxtData, poiDisplayInfo } = getPoiDisplayInfo(row.match_data);

            if (isTxtData) {
                poiValueGetterRes = { data: ` ${notIn} ${poiDisplayInfo.data} ${selectWatchlistHeader}` };
            } else if (poiDisplayInfo.isImgExit) {
                poiValueGetterRes = poiDisplayInfo.data;
            }

            return poiValueGetterRes;
        },
    },
    [poiInfo]: {
        headerName: poiInfo,
        headerAlign: "center",
        field: poiInfo,
        flex: 1,
        minWidth: 140,
        sortable: false,
        renderCell: ({ row }) => <PoiInfoColumn {...processMatchData(row.match_data)} />,
        valueGetter: ({ row }) => poiMatchValueGetter(row.match_data)
    },
    [poiAlertDetailLabel]: ({ isLiveAnalysisMode }) => ({
        headerName: poiAlertDetailLabel,
        field: poiAlertDetailLabel,
        sortable: false,
        minWidth: 290,
        renderCell: ({ row }) => {
            const matchData = processMatchData(row.match_data, row);
            return (
                <PoiAlertDetailColumn isLiveAnalysisMode={isLiveAnalysisMode} matchData={matchData} />
            );
        },
        valueGetter: ({ row }) => poiAlertDetailValueGetter(isLiveAnalysisMode, row.match_data, row)
    }),
    [topMatchInformation]: {
        headerName: topMatchInformation,
        field: topMatchInformation,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <PoiInfoColumn {...processMatchData(row.topMatch)} />,
        valueGetter: ({ row }) => poiMatchValueGetter(row.topMatch)
    },
    [watchlistInfo]: {
        headerName: watchlistInfo,
        headerAlign: "center",
        field: watchlistInfo,
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
            <div className="w-full overflow-hidden break-words">
                <WatchlistsColumn watchlists={row.match_data?.watchlists} />
            </div>
        ),
        valueGetter: ({ row }) => {
            return row.match_data?.watchlists.map((watchlist) => {
                const { displayName, matchOutcome, notes } = processWatchlistsData(watchlist);
                const displayNameTxt = displayName ? `${displayName} - ` : '';
                const notesTxt = notes?.free_notes ? ` - ${notes.free_notes} ` : '';

                return `${displayNameTxt}${capitalize(matchOutcome)}${notesTxt}`;
            }).join('\n');
        }
    },
    [faceAttributes]: {
        headerName: faceAttributes,
        field: faceAttributes,
        minWidth: 127,
        sortable: false,
        renderCell: ({ row }) => <FaceAttributesColumn faceAttrList={processFaceAttrColData(row.face_features_data)} />,
        valueGetter: ({ row }) => {
            const validAttributes = processFaceAttrColData(row.face_features_data).filter(attr => attr.value).map((attr) => `${attr.label}: ${splitString(attr.value)} `);
            return validAttributes.length > 0 ? validAttributes.join('\n') : "";
        },
    },
    [usedFaceImage]: {
        headerName: usedFaceImage,
        field: usedFaceImage,
        width: 150,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => <CropImage srcImage={row.image} />
    },
    [detectedFace]: {
        headerName: detectedFace,
        field: detectedFace,
        width: 150,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ({ value, row }) => <FaceBoundingBoxCanvas image={row.image} bbox={value} />,
    },
    [topMatch]: {
        headerName: topMatch,
        field: topMatch,
        width: 150,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => row.topMatch && <CropImage srcImage={row.topMatch?.display_img} />
    },
    [status]: {
        id: status,
        headerName: status,
        field: status,
        minWidth: 150,
        sortable: true,
        renderCell: ({ row }) => <CameraStatusColumn {...row} />,
        valueGetter: ({ row }) => statusToPropsMap[row.camera_status.status].text,
    },
    [source]: {
        headerName: source,
        field: source,
        flex: 1,
        renderCell: ({ row }) => (
            <span className="overflow-hidden break-words">
                {row.capture_config.capture_address}
            </span>
        ),
        valueGetter: ({ row }) => row.capture_config.capture_address,
    },
    [insightName]: {
        headerName: insightName,
        field: insightName,
        minWidth: 200,
        flex: 1,
        sortable: false,
    },
    [insightType]: {
        headerName: insightType,
        field: insightType,
        minWidth: 200,
        sortable: false,
        renderCell: ({ value }) => {
            const isPoiProx = value === generativeInsightTypes.poiProximity;
            return isPoiProx ? poiProximityName : splitString(value);
        },
    },
    [insightNotes]: {
        headerName: insightNotes,
        field: insightNotes,
        minWidth: 200,
        flex: 1,
        sortable: false,
    },
    [insightActive]: {
        headerName: insightActive,
        field: insightActive,
        align: "center",
        headerAlign: "center",
        minWidth: 100,
        sortable: false,
        filterable: false,
        renderCell: ({ value }) => value ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />,
    },
    [generateAlertLabel]: {
        headerName: generateAlertLabel,
        field: generateAlertLabel,
        align: "center",
        headerAlign: "center",
        minWidth: 140,
        sortable: false,
        filterable: false,
        renderCell: ({ value }) => value ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />,
    },
    [insightAnalysisMode]: {
        headerName: insightAnalysisMode,
        field: insightAnalysisMode,
        align: "center",
        headerAlign: "center",
        minWidth: 150,
        sortable: false,
        filterable: false,
        renderCell: ({ value }) => value,
    },
    [insightsData]: {
        headerName: insightsData,
        field: insightsData,
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => InsightDataColumn({ ...row }),
    },
    [insightTime]: {
        headerName: time,
        field: time,
        minWidth: 170,
        align: "center",
        sortable: false,
        renderCell: ({ row }) => <InsightsSearchTimeColumn {...row} />,
    },
    [appearancesCountHeader]: {
        headerName: appearancesCountHeader,
        field: appearancesCountHeader,
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => (
            <div>
                {row.appearance_count}
            </div>
        ),
        valueGetter: ({ row }) => row.appearance_count,
    },
    [matchesHeader]: {
        flex: 1,
        headerName: matchesHeader,
        field: matchesHeader,
        width: 150,
        align: "center",
        sortable: false,
        renderCell: ({ row }) => <span>{row.matches.length}</span>,
    },
    [watchlistColor]: {
        headerName: colorTitle,
        field: watchlistColor,
        width: 60,
        align: "center",
        headerAlign: "center",
        sortable: false,
        filterable: false,
        renderCell: ({ value }) => (
            <div className="rounded-full p-2" style={{ backgroundColor: RGBToHex(hexToBGR(value || createWatchlistDefaultValues.watchlist_color)), opacity: "1" }} />
        ),
    },
    [watchlistName]: {
        headerName: watchlistName,
        field: watchlistName,
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: ({ value }) => (
            <OverflowingText text={value} />
        ),
    },
    [watchlistPoisNumber]: {
        headerName: watchlistPoisNumber,
        field: watchlistPoisNumber,
        width: 60,
        align: "center",
        headerAlign: "center",
        sortable: false,
        flex: 1,
    },
    [watchlistTypeLabel]: {
        headerName: watchlistTypeLabel,
        field: watchlistTypeLabel,
        width: 100,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: ({ value }) => (watchlistDisplayType.find((options) => options.value === value).label),
    },
    [alertedWatchList]: {
        headerName: alertedWatchList,
        field: alertedWatchList,
        width: 130,
        align: "center",
        sortable: false,
        renderCell: ({ value }) => {
            const severityOption = watchlistSeverityOptions.find((options) => options.value === value?.severity)?.label;
            const soundOnAlertOption = value?.sound_on_alert ? <VolumeUpOutlinedIcon /> : undefined;
            return (
                <div className="flex flex-row items-center gap-2"> {severityOption}{soundOnAlertOption}</div>);
        },
    },
    [nodeId]: {
        headerName: nodeId,
        field: nodeId,
        minWidth: 200,
    },
    [serverType]: {
        headerName: watchlistTypeLabel,
        field: serverType,
        minWidth: 200,
    },
    [lastUpdateTime]: {
        headerName: lastUpdateTime,
        field: lastUpdateTime,
        minWidth: 200,
    },
    [serverStatus]: {
        headerName: status,
        field: serverStatus,
        minWidth: 200,
        renderCell: ({ row }) => (<ServerStatusStabilityColumn label={serverStatus} serverData={row[serverStatus]} />),
        valueGetter: ({ value }) => getServerStatusAndStability(value).status.text
    },
    [stabilityLabel]: {
        headerName: stabilityLabel,
        field: stabilityLabel,
        minWidth: 200,
        renderCell: ({ row }) => (<ServerStatusStabilityColumn label={stabilityLabel} serverData={row[serverStatus]} />),
        valueGetter: ({ value }) => getServerStatusAndStability(value).stability.text
    },
    [insightCameras]: (isLiveAnalysisMode) => ({
        headerName: isLiveAnalysisMode ? cameras : videos,
        field: insightCameras,
        flex: 1,
        minWidth: 300,
        sortable: false,
        renderCell: ({ row }) => <InsightCamerasColumn row={row} />
    }),
    [appearanceSimilarityScore]: {
        headerName: appearanceSimilarityScore,
        field: appearanceSimilarityScore,
        flex: 1,
        sortable: true,
        minWidth: 54,
        renderCell: ({ value }) => <span>{value}</span>,
        valueGetter: ({ row }) => {
            const similarityScore = row.match_confidence || row[appearanceSimilarityScore];
            return trimNumber(similarityScore, 2);
        },
    },
    [profileName]: {
        headerName: profileName,
        field: "name",
        sortable: false,
        minWidth: 120,
        flex: 1,
        renderCell: ({ value }) => <span className="overflow-hidden break-words">{value}</span>,
    },
    [ProfileConfiguration]: {
        headerName: ProfileConfiguration,
        field: ProfileConfiguration,
        minWidth: 300,
        sortable: false,
        renderCell: () => <PrivacyConfigurationColumn nameMapping={appearancePrivacyNameMapping} showValues={false} />,
    },
    [streams]: {
        headerName: streams,
        field: streams,
        width: 90,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => <PrivacyConfigurationColumn settings={row.settings.stream} nameMapping={appearancePrivacyNameMapping} isEnabledPropName="send_event" showText={false} />,
        headerClassName: "break-all",
    },
    [historyHeader]: {
        headerName: historyHeader,
        field: historyHeader,
        minWidth: 90,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => <PrivacyConfigurationColumn settings={row.settings.history} nameMapping={appearancePrivacyNameMapping} isEnabledPropName="save_event" showText={false} />,
    },
    [defaultAppearanceProfile]: {
        headerName: defaultAppearanceProfile,
        field: defaultAppearanceProfile,
        minWidth: 130,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => <StatusColumn isColored={value} />,
    },
    [poiPrivacyConfiguration]: {
        headerName: poiPrivacyConfiguration,
        field: poiPrivacyConfiguration,
        minWidth: 380,
        sortable: false,
        renderCell: ({ row }) => <PrivacyConfigurationColumn row={row} settings={row.settings} nameMapping={watchlistPrivacyNameMapping} />,
    },
    [activePoiPrivacyProfile]: {
        headerName: activePoiPrivacyProfile,
        field: activePoiPrivacyProfile,
        minWidth: 115,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => <StatusColumn isColored={value} />,
    },
    [userStatus]: {
        headerName: status,
        field: "status",
        minWidth: 105,
        maxWidth: 400,
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => <StatusColumn isColored={value === loggedIn} />,
    },
    [username]: {
        headerName: username,
        field: "name",
        minWidth: 140,
        maxWidth: 400,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => <span className="overflow-hidden break-words">{value}</span>,
    },
    [role]: {
        headerName: role,
        field: "role",
        minWidth: 200,
        maxWidth: 400,
        sortable: false,
        renderCell: ({ value }) => <span className="overflow-hidden break-words">{value}</span>,
    },
    [fullName]: {
        headerName: fullName,
        field: fullName,
        maxWidth: 400,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <span className="overflow-hidden break-words">{row.user_contact_info.full_name}</span>,
    },
    [email]: {
        headerName: email,
        field: email,
        minWidth: 140,
        maxWidth: 400,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => <span className="overflow-hidden break-words">{row.user_contact_info.email}</span>,
    },
    [phoneNumber]: {
        headerName: phoneNumber,
        field: phoneNumber,
        flex: 1,
        maxWidth: 400,
        sortable: false,
        renderCell: ({ row }) => <span className="overflow-hidden break-words">{row.user_contact_info.phone}</span>,
    },
    [userState]: {
        headerName: userState,
        field: "active",
        flex: 1,
        maxWidth: 60,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: ({ value }) => <span>{value ? enabled : disabled}</span>,
    },
    [roleName]: {
        headerName: roleName,
        field: "role_name",
        sortable: false,
        minWidth: 554,
        maxWidth: 700,
        flex: 5,
    },
    [dataFiltersApplied]: {
        headerName: dataFiltersApplied,
        field: dataFiltersApplied,
        minWidth: 200,
        sortable: false,
        //todo implement after data filters are part of the roles
    },
    [recordedData]: {
        headerName: recordedData,
        field: "name",
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: ({ row }) => (
            <div className="flex">
                {row.name}
                {row.tooltip && <InfoTooltip title={row.tooltip} />}
            </div >
        ),
    },
    [retentionSettings]: {
        headerName: retentionSettings,
        field: "name",
        flex: 1,
        minWidth: 200,
        sortable: false,
    },
    [storedRecords]: {
        headerName: storedRecords,
        field: storedRecords,
        minWidth: 130,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row }) => <span>{getCurrentRecords(row)}</span>,
    },
    [recordsCapacity]: {
        headerName: recordsCapacity,
        field: recordsCapacity,
        minWidth: 150,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row }) => <span>{getMaxRecords(row)}</span>,
    },
    [usage]: {
        headerName: usage,
        field: usage,
        minWidth: 100,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row }) => <span>{Math.ceil((getCurrentRecords(row) / getMaxRecords(row)) * 100)}%</span>,
    },
    [retentionConfig]: {
        headerName: retentionConfig,
        field: retentionConfig,
        minWidth: 100,
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
            < PrivacyConfigurationColumn
                nameMapping={ttlSettingsMapping[row.name].nameMapping}
                showValues={false}
                showTooltip={true}
            />
        ),
    },
    [retentionValue]: {
        headerName: "",
        field: retentionValue,
        minWidth: 5,
        sortable: false,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row }) => < PrivacyConfigurationColumn settings={row}
            nameMapping={ttlSettingsMapping[row.name].nameMapping}
            isEnabledPropName={ttlSettingsMapping[row.name].isEnabledPropName} showText={false} />,
    },
    [appearanceNotes]: {
        field: appearanceNotes,
        filterable: false,
        valueGetter: ({ row }) => row.appearance_notes.free_notes,
    },
    [reviewAppearance]: {
        field: reviewAppearance,
        filterable: false,
        valueGetter: ({ row }) => startCase(row.labeled_outcome),
    },
    [minimum]: {
        headerName: minimum,
        field: "min",
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        valueGetter: getInsightsStatisticsRowData,
    },
    [maximum]: {
        headerName: maximum,
        field: "max",
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        valueGetter: getInsightsStatisticsRowData,
    },
    [average]: {
        headerName: average,
        field: "avg",
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        valueGetter: getInsightsStatisticsRowData,
    },
    [insightsStatisticsKey]: {
        headerName: "",
        field: insightsStatisticsKey,
        flex: 1,
        minWidth: 200,
        sortable: false,
    },
    [acknowledgeAlertLabel]: {
        headerName: "",
        field: acknowledgeAlertLabel,
        flex: 1,
        width: 30,
        sortable: false,
        renderCell: ({ row }) => (
            <AcknowledgeAlertColumn row={row} className="ml-2" />
        ),
    }
};

export const ActionColumnsDefinitions = {
    [generativeInsightsHeader]: ({ location, removeRow }) => ({
        headerName: "",
        field: actions,
        maxWidth: 5,
        sortable: false,
        align: "center",
        renderCell: ({ row }) =>
            <InsightsContextsMenu
                id={row.id}
                insightTypeValue={row[insightType]}
                location={location}
                removeRowHandler={removeRow}
            />
    }),
    [camerasList]: {
        headerName: "",
        field: actions,
        maxWidth: 5,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: ({ value }) => <CameraContextMenu camera={value} />
    },
    [watchlistsHeader]: {
        headerName: "",
        field: actions,
        maxWidth: 5,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: ({ value }) => <WatchlistContextMenu watchlist={value} />
    },
    [eventsHeader]: (tableType) => ({
        headerName: "",
        field: actions,
        maxWidth: 5,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: ({ row }) => <EventsTableContextMenu event={row} tableType={tableType} />
    }),
    [historyList]: (tableType) => ({
        headerName: "",
        field: actions,
        sortable: false,
        maxWidth: 5,
        filterable: false,
        align: "center",
        renderCell: ({ row }) => <HistoryListContextMenu row={row} tableType={tableType} />
    }),
    [appearancesPrivacy]: {
        headerName: "",
        field: actions,
        maxWidth: 5,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: ({ row }) => <PrivacyContextMenu privacyProfile={row} isAppearancesProfile={true} />
    },
    [poisPrivacy]: {
        headerName: "",
        field: actions,
        maxWidth: 5,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: ({ row }) => <PrivacyContextMenu privacyProfile={row} isAppearancesProfile={false} />
    },
    [usersHeader]: {
        headerName: "",
        field: actions,
        maxWidth: 5,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: ({ row }) => <UsersContextMenu row={row} />
    },
    [rolesHeader]: {
        headerName: "",
        field: actions,
        minWidth: 50,
        flex: 1,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: ({ row }) => <RolesContextMenu row={row} />
    },
    [recordedData]: {
        headerName: "",
        field: actions,
        maxWidth: 5,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: ({ row }) => <SystemStorageContextMenu row={row} />
    },
    [retentionConfig]: {
        headerName: "",
        field: actions,
        maxWidth: 5,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: ({ row }) => <DataRetentionConfigContextMenu row={row} />
    },
    [poiDB]: {
        headerName: "",
        field: actions,
        maxWidth: 5,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: ({ row }) => <IdentifyContextMenu row={row} />
    }
};