import { memo, useCallback, useState } from "react";

import { Box, Fade, Popper } from "@mui/material";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";

import NotificationsBadgeIcon from "./NotificationPanel/NotificationsBadgeIcon";
import NotificationStack from "./NotificationStack/NotificationStack";
import Header from "./NotificationPanel/Header";
import Footer from "./NotificationPanel/Footer";

function NotificationCenter() {
    const { notifications, unreadCount, markAsRead, markAllAsRead, clear } = useNotificationCenter();
    const [showUnreadOnly, setShowUnreadOnly] = useState(false);
    const [anchorElement, setAnchorElement] = useState(null);

    const toggleNotificationCenter = useCallback((event) => {
        setAnchorElement((prevAnchorElement) => prevAnchorElement ? null : event.currentTarget);
    }, []);

    const toggleUnreadFilter = useCallback(() => {
        setShowUnreadOnly((prevState) => !prevState);
    }, []);

    return (
        <Box>
            <NotificationsBadgeIcon toggleNotificationCenter={toggleNotificationCenter} unreadCount={unreadCount} />
            <Popper className="z-[9999]" open={anchorElement !== null} anchorEl={anchorElement} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box className="m-1" placement="left">
                            <Header toggleNotificationCenter={toggleNotificationCenter} toggleFilter={toggleUnreadFilter} showUnreadOnly={showUnreadOnly} />
                            <NotificationStack notifications={notifications} unreadCount={unreadCount} markAsRead={markAsRead} showUnreadOnly={showUnreadOnly} />
                            <Footer clear={clear} markAllAsRead={markAllAsRead} />
                        </Box>
                    </Fade>
                )}
            </Popper>
        </Box>
    );
}

export default memo(NotificationCenter);