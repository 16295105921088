import { useCallback, useMemo } from "react";

import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";
import { createIdentifyAppearanceImageCase, createSearchAppearanceImageCase } from "@/Logic/Hooks/useCaseInjector";
import { searchInHistory, searchInPoiDb } from "src/appConfig/Strings";
import { saveAlteredCaseEvent } from "src/appConfig/customEvents";

function IdentifyContextMenu({ row }) {
    const { crop_data: cropData } = row;
    const hasImage = useMemo(() => Boolean(cropData.face_crop_img), [cropData.face_crop_img]);

    const setOptions = useCallback(() => {
        const options = {
            [searchInHistory]: () => document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent,
                { detail: { caseCreator: createSearchAppearanceImageCase, dataToChange: cropData.face_crop_img } })),
            [searchInPoiDb]: () => document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent,
                { detail: { caseCreator: createIdentifyAppearanceImageCase, dataToChange: cropData.face_crop_img } })),
        };

        return options;
    }, [cropData.face_crop_img]);

    const disabledOptions = useMemo(() => ({
        [searchInHistory]: () => !hasImage,
        [searchInPoiDb]: () => !hasImage,
    }), [hasImage]);

    return (
        <ContextMenuButton options={setOptions()} disabledOptions={disabledOptions} />
    );
}

export default IdentifyContextMenu;