import { useCallback } from 'react';

import { matchOutcomes } from "@/Logic/ApiClients/EventsClient/EventsClient.model";
import { lowQualityFace, notDeterminedHeader } from "src/appConfig/Strings";
import OverflowingText from '../../Tooltips/OverflowingText';
import { ColoredWatchlistName } from './CropImagesColumns/CropImagesColumns.utils';

export const outComeText = {
    not_determined: notDeterminedHeader,
    low_quality_face: lowQualityFace
};

function WatchlistsColumn({ watchlists }) {
    const renderWatchlist = useCallback((watchlist) => {
        const { watchlistId, displayName, matchOutcome: matchOutcomeText, notes, watchlistColor } = processWatchlistsData(watchlist);
        const matchOutcome = watchlist.match_outcome;
        if (matchOutcome === matchOutcomes.not_determined) {
            return;
        }

        return (
            <div key={watchlistId} className="flex w-full flex-col items-center justify-center py-2">
                {matchOutcome === matchOutcomes.matched ?
                    <ColoredWatchlistName colorHex={watchlistColor} name={displayName} /> :
                    <OverflowingText className="w-full text-center font-bold" text={displayName} />
                }
                <span className='text-center capitalize'>{matchOutcomeText}</span>
                {notes && (
                    <span className='notes-text'>{notes?.free_notes}</span>
                )}
            </div>
        );
    }, []);

    return (
        <div className="flex flex-col gap-1 overflow-hidden">
            {watchlists.map((watchlist) => renderWatchlist(watchlist))}
        </div>
    );
}

export default WatchlistsColumn;

export function getMatchedWatchlists(watchlists) {
    const onlyMatchedWl = watchlists.filter((watchlist) => watchlist.match_outcome === matchOutcomes.matched);

    return onlyMatchedWl;
}

export function processWatchlistsData(watchlists) {
    if (!watchlists) {
        return {};
    }

    const { watchlist_id: watchlistId, display_name: displayName, match_outcome: matchOutcome, watchlist_notes: watchlistNotes, display_color: watchlistColor } = watchlists;

    const processedData = {
        watchlistId,
        displayName,
        matchOutcome: outComeText[matchOutcome] || matchOutcome,
        notes: watchlistNotes,
        watchlistColor
    };

    return processedData;
}