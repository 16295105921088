import { useEffect, useState, memo, useCallback, useContext, useMemo } from "react";

import store from "src/appConfig/configureStore";
import { additionalUsbModeModel, fileCaptureAddressModel, cameraFormFieldsNames, CameraFormModel, capturingSourceMode, CapturingSourceOptions } from "./CreateEditCamera/CreateEditCamera.model";
import { cameraAnalysisNode, captureSource, deviceNotAvailable, nodeId, password, rtspAdress, usbDevices, username, videoFiles } from "src/appConfig/Strings";
import SelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField";
import RadioInput from "@/Components/Common/FormComponents/FormInputs/RadioInput";
import PasswordInputField from "@/Components/Common/FormComponents/FormInputs/PasswordInputField";
import LabelWrapper from "@/Components/Common/Wrappers/LabelWrapper";
import BaseInput from "@/Components/Common/FormComponents/FormInputs/BaseInput";
import MultiSelectFiles from "@/Components/Common/MultiSelectFiles";
import ApiContext from "@/Logic/Context/apiContext";
import { distributedLocalSiteDeviceType, hqDeviceType, mainNodeDeviceType } from "@/Logic/ApiClients/ControllerClient";

function CaptureSourceSubForm({ cameraID, formData, onFieldChange, isLiveAnalysisMode }) {
    const { clientsManager } = useContext(ApiContext);
    const { captureConfigMode, captureAddress, cameraStreamUsername, cameraStreamPassword, nodeId: nodeIdModel } = cameraFormFieldsNames;
    const [capturingMode, setCaptureSourceMode] = useState(capturingSourceMode.rtsp);
    const [updatedUSBCameras, setUpdatedUSBCameras] = useState([]);
    const isEditMode = useMemo(() => Boolean(cameraID), [cameraID]);
    const { isMultiServerMode } = store.getState().ApiActionsStore;
    const { nodesList } = store.getState().ApiActionsStore;

    const currNodeData = useMemo(() => {
        if (!isMultiServerMode) {
            return undefined;
        }

        return Object.values(nodesList).find((nodeData) => nodeData.node_id === formData[nodeIdModel]);
    }, [formData, isMultiServerMode, nodeIdModel, nodesList]);

    const nodes = useMemo(() => {
        if (!isMultiServerMode) {
            return [];
        }

        const allNodes = Object.values(nodesList);
        const allNodesAreLocalSiteType = allNodes.length === 1 && allNodes[0].device_type === distributedLocalSiteDeviceType;
        const isNotRTSPMode = capturingMode !== capturingSourceMode.rtsp;

        return allNodes.map((nodeData) => {
            const isNotMainOrHQDevice = (nodeData.device_type !== mainNodeDeviceType) && (nodeData.device_type !== hqDeviceType);

            return {
                ...nodeData,
                disabled: !allNodesAreLocalSiteType && isNotRTSPMode && isNotMainOrHQDevice
            };
        });
    }, [capturingMode, isMultiServerMode, nodesList]);

    const fetchUsbCameras = useCallback(async () => {
        const camerasData = await clientsManager.webDockerClient.getAllUSBCameras();
        setUpdatedUSBCameras(camerasData);
    }, [clientsManager.webDockerClient]);

    useEffect(() => {
        if (capturingMode === capturingSourceMode.usb) {
            fetchUsbCameras();
        }
    }, [capturingMode, fetchUsbCameras]);

    useEffect(() => {
        const mode = formData[captureConfigMode];
        const currentAddress = formData[captureAddress];

        const addUnavailableUsbCamera = () => {
            const newUSBCamera = {
                cameraName: `${currentAddress} - ${deviceNotAvailable}`,
                cameraLocation: currentAddress
            };
            setUpdatedUSBCameras(prevCameras => [...prevCameras, newUSBCamera]);
        };

        if (!mode) {
            return;
        }

        setCaptureSourceMode(mode);
        if (mode === capturingSourceMode.usb) {
            const unavailableUsbCameraExists = !updatedUSBCameras.some(usbCamera => usbCamera.cameraLocation === currentAddress);
            if (cameraID && unavailableUsbCameraExists) {
                addUnavailableUsbCamera();
            }
        }
    }, [cameraID, captureAddress, captureConfigMode, formData, updatedUSBCameras]);

    return (
        <div className="flex flex-col">
            {isLiveAnalysisMode &&
                <LabelWrapper label={captureSource}>
                    <div className="flex flex-row">
                        <RadioInput
                            options={CapturingSourceOptions}
                            model={CameraFormModel[captureConfigMode]}
                            value={formData[captureConfigMode]}
                            onChange={(event) => {
                                const newValue = event.target.value;
                                setCaptureSourceMode(newValue);
                                onFieldChange(captureConfigMode, newValue);
                                onFieldChange(captureAddress, undefined);
                            }}
                            isDisabled={isEditMode}
                            data-testid="radio-capture-source"
                        />
                    </div>
                </LabelWrapper>
            }
            {capturingMode === capturingSourceMode.rtsp && (
                <div>
                    <BaseInput
                        label={rtspAdress}
                        model={CameraFormModel[captureAddress]}
                        value={formData[captureAddress]}
                        onChange={(event) => onFieldChange(captureAddress, event.target.value)}
                        fullWidth={true}
                        dataTestId="ip-address-field"
                    />
                    <div className="mt-2.5 flex flex-row">
                        <BaseInput
                            className="mr-main-elements-space"
                            model={CameraFormModel[cameraStreamUsername]}
                            value={formData[cameraStreamUsername]}
                            onChange={(event) => onFieldChange(cameraStreamUsername, event.target.value)}
                            label={username}
                            fullWidth={true}
                            dataTestId="username-field"
                        />
                        <PasswordInputField
                            model={CameraFormModel[cameraStreamPassword]}
                            value={formData[cameraStreamPassword]}
                            onChange={(event) => onFieldChange(cameraStreamPassword, event.target.value)}
                            label={password}
                            dataTestId="password-field"
                        />
                    </div>
                </div>
            )}
            {capturingMode === capturingSourceMode.usb && (
                <div>
                    <SelectInputField
                        label={usbDevices}
                        model={{ ...CameraFormModel[captureAddress], ...additionalUsbModeModel }}
                        value={formData[captureAddress]}
                        onChange={(event) => onFieldChange(captureAddress, event.target.value)}
                        onOpen={fetchUsbCameras}
                        options={updatedUSBCameras}
                        dataTestId="usb-devices"
                    />
                </div>
            )}
            {capturingMode === capturingSourceMode.file && (
                <MultiSelectFiles
                    label={videoFiles}
                    model={{ ...CameraFormModel[captureAddress], ...fileCaptureAddressModel }}
                    value={formData[captureAddress]}
                    onChange={(newSelectedFiles) => onFieldChange(captureAddress, newSelectedFiles)}
                    dataTestId="video-file-selector"
                    disabled={isEditMode}
                />
            )}
            {isMultiServerMode && (
                <LabelWrapper label={cameraAnalysisNode}>
                    <SelectInputField
                        label={nodeId}
                        model={CameraFormModel[nodeIdModel]}
                        value={formData[nodeIdModel]}
                        onChange={(event) => onFieldChange(nodeIdModel, event.target.value)}
                        options={nodes}
                        disabled={isEditMode && currNodeData && (currNodeData.device_type === hqDeviceType || currNodeData.device_type === distributedLocalSiteDeviceType)}
                        dataTestId="analysis-node"
                    />
                </LabelWrapper>
            )}
        </div>
    );
}

export default memo(CaptureSourceSubForm);