import { useCallback, useContext } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";
import ApiContext from "@/Logic/Context/apiContext";
import { editPoi, removePoi, removePoiConfirmation, removePoiNote, searchInHistory, searchInPoiDb } from "src/appConfig/Strings";
import { saveAlteredCaseEvent } from "src/appConfig/customEvents";
import { createIdentifyAppearanceImageCase, createSearchAppearanceImageCase } from "@/Logic/Hooks/useCaseInjector";
import { getPoisRequest } from "@/Logic/Hooks/usePrepareCreateEditPoi";
import { poiDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";

export const removePoiRequest = async (clientsManager, poiId) => {
    await clientsManager.poisClient.removePoi(poiId);
    await clientsManager.watchlistsClient.listAllWatchlists(true); // Update WL counter
    await clientsManager.controllerClient.getLicense(); // Update POI-DB counter
};

export const removePoiConfirmationOption = {
    [removePoi]: {
        acceptButtonText: removePoi,
        confirmationText:
            <div className="flex flex-col items-center gap-3">
                <span>{removePoiConfirmation}</span>
                <span>{removePoiNote}</span>
            </div>
    }
};

function POIContextMenu({ poiData, poiId, setIsLoading }) {
    const { clientsManager } = useContext(ApiContext);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleEditPOIDialog = useCallback(async () => {
        navigate(`${location.pathname}?poiId=${poiId}`);
        const reqSucceed = await getPoisRequest(clientsManager, poiId);
        if (!reqSucceed) {
            return;
        }
        dispatchToggleDialogEvent(poiDialogId);
    }, [clientsManager, location.pathname, navigate, poiId]);

    const setOptions = useCallback(() => {
        const options = {
            [editPoi]: () => toggleEditPOIDialog(),
            [searchInHistory]: () => document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent,
                { detail: { caseCreator: createSearchAppearanceImageCase, dataToChange: poiData.display_img } })),
            [searchInPoiDb]: () => document.dispatchEvent(new CustomEvent(saveAlteredCaseEvent,
                { detail: { caseCreator: createIdentifyAppearanceImageCase, dataToChange: poiData.display_img } })),
            [removePoi]: async () => {
                setIsLoading(true);
                await removePoiRequest(clientsManager, poiId);
                setIsLoading(false);
            }
        };

        return options;
    }, [clientsManager, poiData.display_img, poiId, setIsLoading, toggleEditPOIDialog]);

    return (
        <ContextMenuButton
            className="flex w-6 justify-center rounded bg-pie-line-bright-blue"
            options={setOptions()}
            confirmationOptions={removePoiConfirmationOption}
        />
    );
}

export default POIContextMenu;