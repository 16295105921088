import moment from "moment";

import { downloadFile } from "@/Logic/Common";
import { base64ToArrayBuffer, imgJpegType } from "@/Logic/Parsing/parseImages";
import { TimeParsingString, formatUnixTimestamp, fullDataTimeFormat, reportFormat } from "@/Logic/Parsing/timeParsing";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { setDialogData } from "src/appConfig/customEvents";
import store from "src/appConfig/configureStore";
import { playbackDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";

function downloadSingleFrame(frameImage, frameIndex, cameraName) {
    if (!frameImage) {
        return;
    }

    const arrayBuffer = base64ToArrayBuffer(frameImage);
    const frameFileName = `${cameraName}_${moment().format(reportFormat)}_frame_${frameIndex + 1}.jpeg`;
    downloadFile(arrayBuffer, frameFileName, imgJpegType);
}

async function downloadVideoFromFrames(clientsManager, framesList, cameraName) {
    const videoFileName = `${cameraName}_${moment().format('YYYYMMDD_HHmmss')}.mp4`;
    const videoBlob = await clientsManager.webDockerClient.getVideoFromPlaybackFrames(framesList);
    if (videoBlob instanceof Blob) {
        downloadFile(videoBlob, videoFileName, 'video/mp4');
    }
}

async function loadPlaybackDialog(clientsManager, appearanceId, nameToDisplay, color, cameraDisplayName, matchTime) {
    const res = await clientsManager.framesStorageClient.getPlayback(appearanceId, nameToDisplay, color);
    if (res.error) {
        return;
    }

    dispatchToggleDialogEvent(playbackDialogId);
    dispatchPlaybackDetailsEvent(cameraDisplayName, matchTime, appearanceId);
}

function dispatchPlaybackDetailsEvent(cameraDisplayName, matchTime, appearanceId) {
    const isLiveAnalysisMode = store.getState().ApiActionsStore.isLiveAnalysisMode;
    let parsedMatchTime;

    if (!matchTime) {
        parsedMatchTime = "";
    } else {
        const timeFormat = isLiveAnalysisMode ? fullDataTimeFormat : TimeParsingString;
        const isUtcTimeZone = !isLiveAnalysisMode;
        parsedMatchTime = formatUnixTimestamp(matchTime, timeFormat, isUtcTimeZone);
    }

    const setPlaybackDetailsEvent = new CustomEvent(setDialogData, { detail: { appearanceId, cameraDisplayName, matchTime: parsedMatchTime } });
    document.dispatchEvent(setPlaybackDetailsEvent);
}

export { downloadSingleFrame, downloadVideoFromFrames, loadPlaybackDialog, dispatchPlaybackDetailsEvent };