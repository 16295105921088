import { ApiClient, AppearancePrivacyProfilesApi, POIPrivacyProfilesApi } from "smart_cameras_controller_api";

import { isEmpty } from "lodash";

import store from "src/appConfig/configureStore";
import { setAppearancesProfiles, storeDefaultPrivacyProfile, setOpenAppearancesPrivacy, setWatchlistProfiles, setOpenWatchlistProfile } from "../Redux/Stores/PrivacyProfilesStore";
import BaseAPIClient from "./BaseAPIClient";
import { controllerRoute } from "../Infrastructure/networkConf";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { guardianAgeGroupTxt, olderGuardianAgeGroupTxt, setAppearancesPrivacySuccessMsg, setWatchlistPrivacySuccessMsg } from "src/appConfig/Strings";
import { toastUtility } from "../Hooks/useToast";
import { updatePropertyIfPresent } from "../Parsing/objectsParsing";
import { appearancesPrivacyFormFieldsNames } from "@/Components/Settings/Privacy/CreateEditAppearancesPrivacy/CreateEditAppearancesPrivacy.model";

const aboveSeventyGroup = "70–120";
export const guardianConsentAgeGroup = [
    { name: guardianAgeGroupTxt(2), group: "0–2" },
    { name: guardianAgeGroupTxt(9), group: "3–9" },
    { name: guardianAgeGroupTxt(19), group: "10–19" },
    { name: guardianAgeGroupTxt(29), group: "20–29" },
    { name: guardianAgeGroupTxt(39), group: "30–39" },
    { name: guardianAgeGroupTxt(49), group: "40–49" },
    { name: guardianAgeGroupTxt(59), group: "50–59" },
    { name: guardianAgeGroupTxt(69), group: "60–69" },
    { name: olderGuardianAgeGroupTxt, group: aboveSeventyGroup },
];

export const systemPrivacyProfiles = ["Unrestricted", "Recommended"];

export default class PrivacyProfilesClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(controllerRoute, clientInstance);
        this.privacyAppearancesApi = new AppearancePrivacyProfilesApi();
        this.watchlistPrivacyProfilesApi = new POIPrivacyProfilesApi();
    }

    initialize() {
        this.getDefaultPoiPrivacyProfile();
        this.getPrivacyWatchlistProfiles();
    }

    #parsePrivacyObject(privacyProfileObj, isCreate = true) {
        const { name } = privacyProfileObj;
        let settings = {};
        if (isCreate) {
            settings = {
                stream: {},
                history: {}
            };
        }
        const newPrivacyProfile = {};
        updatePropertyIfPresent(privacyProfileObj, appearancesPrivacyFormFieldsNames.stream, settings);
        updatePropertyIfPresent(privacyProfileObj, appearancesPrivacyFormFieldsNames.history, settings);
        parseBaseProfileFields(newPrivacyProfile, name, settings, isCreate);

        return newPrivacyProfile;
    }

    #parseWatchlistPrivacyObject(privacyProfileObj, isCreate = true) {
        const { name, require_guardian_consent_age_groups: requireGuardianConsentAgeGroup, ...rest } = privacyProfileObj;
        const newWatchlistPrivacyProfile = {};
        const settings = {
            ...rest
        };
        parseAgeGroups(settings, requireGuardianConsentAgeGroup);
        parseBaseProfileFields(newWatchlistPrivacyProfile, name, settings, isCreate);

        return newWatchlistPrivacyProfile;
    }

    async getAppearancesPrivacyProfileById(profileId, isDuplicate) {
        return await this.apiCall("Get Appearances Privacy Profile",
            (callback) => this.privacyAppearancesApi.getAppearancePrivacyProfile(profileId, callback),
            (_, data) => {
                const payload = data.data;
                parseFieldIfDuplicate(payload, appearancesPrivacyFormFieldsNames.privacyName, isDuplicate);
                store.dispatch(setOpenAppearancesPrivacy(payload));
            }
        );
    }

    async getPrivacyAppearancesProfiles() {
        await this.apiCall(
            "Get Appearances Privacy Profiles",
            (callback) => this.privacyAppearancesApi.listAllAppearancePrivacyProfiles(callback),
            (_, data) => store.dispatch(setAppearancesProfiles(data.data.profiles))
        );
    }

    async getPrivacyWatchlistProfiles() {
        await this.apiCall(
            "Get Watchlist Privacy Profiles",
            (callback) => this.watchlistPrivacyProfilesApi.listAllPOIPrivacyProfiles(callback),
            (_, data) => store.dispatch(setWatchlistProfiles(data.data.profiles))
        );
    }

    async getWatchlistPrivacyProfileById(profileId, isDuplicate) {
        await this.apiCall(
            "Get Watchlist Privacy Profile",
            (callback) => this.watchlistPrivacyProfilesApi.getPOIPrivacyProfile(profileId, callback),
            (_, data) => {
                const payload = data.data;
                parseFieldIfDuplicate(payload, appearancesPrivacyFormFieldsNames.privacyName, isDuplicate);
                store.dispatch(setOpenWatchlistProfile(payload));
            });
    }

    async getDefaultAppearancePrivacyProfile() {
        await this.apiCall(
            "Get Default Appearance Privacy Profile",
            (callback) => this.privacyAppearancesApi.getDefaultAppearancePrivacyProfile(callback),
            (_, data) => store.dispatch(storeDefaultPrivacyProfile({ id: data.data.profile_id, isAppearanceProfile: true }))
        );
    }

    async getDefaultPoiPrivacyProfile() {
        await this.apiCall(
            "Get Default POI Privacy Profile",
            (callback) => this.watchlistPrivacyProfilesApi.getDefaultPOIPrivacyProfile(callback),
            (_, data) => store.dispatch(storeDefaultPrivacyProfile({ id: data.data.profile_id, isAppearanceProfile: false }))
        );
    }

    async setDefaultPrivacyProfile(profileId, isAppearanceProfile) {
        const text = `Set Default ${isAppearanceProfile ? "Appearance" : "POI"} Privacy Profile`;
        const profile = { profile_id: profileId };
        await this.apiCall(
            text,
            (callback) => isAppearanceProfile ?
                this.privacyAppearancesApi.setDefaultAppearancePrivacyProfile(profile, callback) :
                this.watchlistPrivacyProfilesApi.setDefaultPOIPrivacyProfile(profile, callback),
            (_, data) => {
                store.dispatch(storeDefaultPrivacyProfile({ id: data.data.profile_id, isAppearanceProfile: isAppearanceProfile }));
                isAppearanceProfile ? toastUtility.showSuccess(setAppearancesPrivacySuccessMsg) : toastUtility.showSuccess(setWatchlistPrivacySuccessMsg);
            }
        );
    }

    async removePrivacyProfile(profileId, isAppearanceProfile) {
        const text = `Remove ${isAppearanceProfile ? "Appearance" : "POI"} Privacy Profile`;
        await this.apiCall(
            text,
            (callback) => isAppearanceProfile ? this.privacyAppearancesApi.removeAppearancePrivacyProfile(profileId, callback) :
                this.watchlistPrivacyProfilesApi.removePOIPrivacyProfile(profileId, callback),
            () => isAppearanceProfile ? this.getPrivacyAppearancesProfiles() : this.getPrivacyWatchlistProfiles()
        );
    }

    async createPrivacyProfile(privacyProfileObj) {
        const privacyParams = this.#parsePrivacyObject(privacyProfileObj);
        return await this.apiCall("Create Privacy Profile",
            (callback) => this.privacyAppearancesApi.addAppearancePrivacyProfile(privacyParams, callback),
            (error, __, response) => parseResponseForUI(error, response)
        );
    }

    async editPrivacyProfile(privacyProfileId, privacyProfileObj) {
        const privacyParams = this.#parsePrivacyObject(privacyProfileObj, false);
        return await this.apiCall("Edit Privacy Profile",
            (callback) => this.privacyAppearancesApi.updateAppearancePrivacyProfile(privacyProfileId, privacyParams, callback),
            (error, __, response) => parseResponseForUI(error, response)
        );
    }

    async createWatchlistPrivacyProfile(privacyProfileObj) {
        const privacyParams = this.#parseWatchlistPrivacyObject(privacyProfileObj);
        return await this.apiCall("Create Watchlist Privacy Profile",
            (callback) => this.watchlistPrivacyProfilesApi.addPOIPrivacyProfile(privacyParams, callback),
            (error, __, response) => parseResponseForUI(error, response)
        );
    }

    async editWatchlistPrivacyProfile(privacyProfileId, privacyProfileObj) {
        const privacyParams = this.#parseWatchlistPrivacyObject(privacyProfileObj, false);
        return await this.apiCall("Edit Watchlist Privacy Profile",
            (callback) => this.watchlistPrivacyProfilesApi.updatePOIPrivacyProfile(privacyProfileId, privacyParams, callback),
            (error, __, response) => parseResponseForUI(error, response)
        );
    }
}

export function parseFieldIfDuplicate(data, fieldName, isDuplicate) {
    if (isDuplicate) {
        data[fieldName] = "";
    }
}

function parseBaseProfileFields(objToUpdate, name, settings, isCreate) {
    if (name) {
        objToUpdate.name = name;
    }
    if (isCreate || !isEmpty(settings)) {
        objToUpdate.settings = settings;
    }
}

function parseAgeGroups(objToUpdate, highestAgeRange) {
    if (isEmpty(highestAgeRange)) {
        return;
    }

    const ageRange = highestAgeRange[0];
    const ageGroups = [];
    if (ageRange === aboveSeventyGroup) {
        ageGroups.push(aboveSeventyGroup);
    } else {
        for (const group of guardianConsentAgeGroup) {
            ageGroups.push(group.group);
            if (group.group === ageRange) {
                break;
            }
        }
    }
    objToUpdate.require_guardian_consent_age_groups = ageGroups;
}