import { allOptionSelected, specificOptionSelected } from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";
import { allVideosOption, specificVideosFilterOption } from "src/appConfig/Strings";

export const baseCamerasFieldNames = {
    cameras: "camera_ids",
    videos: "videos_ids",
};

export const baseCamerasModel = {
    getId: (camera) => camera.camera_id,
    getLabel: (camera) => camera.description,
    required: true,
};

export const baseLiveCamerasModel = {
    name: baseCamerasFieldNames.cameras,
    ...baseCamerasModel
};

export const baseVideosModel = {
    name: baseCamerasFieldNames.videos,
    ...baseCamerasModel
};

export const baseCamerasRadioModel = [
    {
        label: allVideosOption,
        option: allOptionSelected
    },
    {
        label: specificVideosFilterOption,
        option: specificOptionSelected
    }
];