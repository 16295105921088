import { useCallback, useContext, useMemo } from "react";

import { IconButton, LinearProgress } from "@mui/material";
import { connect } from "react-redux";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';

import { calculatePercentage } from "@/Logic/Parsing/numbersParsing";
import DownloadFramesContextMenu from "./DownloadFramesContextMenu";
import MainDialog from "@/Components/Common/Modals/MainDialog";
import { deletePlayback, deletePlaybackConfirm, deletePlaybackSuccess, deleteStr } from "src/appConfig/Strings";
import ApiContext from "@/Logic/Context/apiContext";
import { useToast } from "@/Logic/Hooks/useToast";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { clearFrames } from "@/Logic/Redux/Stores/FramesStore";
import { playbackDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";

const removePlaybackDialogSx = { '& .MuiDialog-paper': { width: '450px' } };

function PlaybackFooter({ frames, appearanceSequence, currentFrameIndex, setCurrentFrameIndex, isPlaying, handlePlayToggle, cameraName, appearanceId, clearFrames }) {
    const { clientsManager } = useContext(ApiContext);
    const { showSuccessToast } = useToast();
    const normalizedProgressPercentage = useMemo(() => calculatePercentage(currentFrameIndex, appearanceSequence.length - 1), [appearanceSequence.length, currentFrameIndex]);

    const handleFrameIndexChange = useCallback((isNext) => {
        const indexChangeValue = isNext ? 1 : -1;
        setCurrentFrameIndex((prevValue) => prevValue += indexChangeValue);
    }, [setCurrentFrameIndex]);

    const deletePlaybackHandler = useCallback(async () => {
        const res = clientsManager.framesStorageClient.deletePlayback(appearanceId);
        if (res.error) {
            return;
        }

        showSuccessToast(deletePlaybackSuccess);
        clearFrames();
        dispatchToggleDialogEvent(playbackDialogId);
    }, [appearanceId, clearFrames, clientsManager.framesStorageClient, showSuccessToast]);

    return (
        <div className="flex h-14 w-full items-center rounded-lg bg-deep-navy-blue p-3 shadow-md">
            <div className="mr-9">
                <DownloadFramesContextMenu frames={frames} currentFrameIndex={currentFrameIndex} appearanceSequence={appearanceSequence} cameraName={cameraName} />
            </div>
            <IconButton className="mr-2 text-white" onClick={handlePlayToggle}>
                {isPlaying ? <PauseIcon fontSize="medium" /> : <PlayArrowIcon fontSize="medium" />}
            </IconButton>
            <div className="min-w-24 flex-1">
                <LinearProgress variant="determinate" value={normalizedProgressPercentage} className="h-2 rounded-full" />
            </div>
            <div className="ml-2 flex items-center gap-1 text-xs">
                <IconButton size="small" className="p-1 text-white" onClick={() => handleFrameIndexChange(false)} disabled={currentFrameIndex === 0}>
                    <ArrowBackIosNewIcon fontSize="small" />
                </IconButton>
                <span className="text-white">{currentFrameIndex + 1}</span>
                <IconButton size="small" className="p-1 text-white" onClick={() => handleFrameIndexChange(true)} disabled={currentFrameIndex >= appearanceSequence.length - 1}>
                    <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
            </div>
            <div className="ml-9">
                <MainDialog triggerIcon={<DeleteIcon fontSize="medium" />} headerTitle={deletePlayback} footerSubmitBtnText={deleteStr} dialogSxClass={removePlaybackDialogSx}
                    onSubmit={deletePlaybackHandler} triggerIconClassName="rounded-full bg-pie-line-bright-blue p-1 text-white">
                    <div className="flex w-full items-center justify-center" >
                        <span className="text-center">{deletePlaybackConfirm}</span>
                    </div>
                </MainDialog>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    clearFrames
};

export default connect(null, mapDispatchToProps)(PlaybackFooter);