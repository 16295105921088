"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AnalysisStatusData", {
  enumerable: true,
  get: function () {
    return _AnalysisStatusData.default;
  }
});
Object.defineProperty(exports, "AnalyticsEvent", {
  enumerable: true,
  get: function () {
    return _AnalyticsEvent.default;
  }
});
Object.defineProperty(exports, "ApiClient", {
  enumerable: true,
  get: function () {
    return _ApiClient.default;
  }
});
Object.defineProperty(exports, "Appearance", {
  enumerable: true,
  get: function () {
    return _Appearance.default;
  }
});
Object.defineProperty(exports, "AppearanceData", {
  enumerable: true,
  get: function () {
    return _AppearanceData.default;
  }
});
Object.defineProperty(exports, "AppearanceEventObject", {
  enumerable: true,
  get: function () {
    return _AppearanceEventObject.default;
  }
});
Object.defineProperty(exports, "AppearanceFilters", {
  enumerable: true,
  get: function () {
    return _AppearanceFilters.default;
  }
});
Object.defineProperty(exports, "AppearanceFrameData", {
  enumerable: true,
  get: function () {
    return _AppearanceFrameData.default;
  }
});
Object.defineProperty(exports, "AttributesCounters", {
  enumerable: true,
  get: function () {
    return _AttributesCounters.default;
  }
});
Object.defineProperty(exports, "Bbox", {
  enumerable: true,
  get: function () {
    return _Bbox.default;
  }
});
Object.defineProperty(exports, "Bbox1", {
  enumerable: true,
  get: function () {
    return _Bbox2.default;
  }
});
Object.defineProperty(exports, "BoundingBox", {
  enumerable: true,
  get: function () {
    return _BoundingBox.default;
  }
});
Object.defineProperty(exports, "CameraAnalyticsCount", {
  enumerable: true,
  get: function () {
    return _CameraAnalyticsCount.default;
  }
});
Object.defineProperty(exports, "CameraData", {
  enumerable: true,
  get: function () {
    return _CameraData.default;
  }
});
Object.defineProperty(exports, "CameraNotes", {
  enumerable: true,
  get: function () {
    return _CameraNotes.default;
  }
});
Object.defineProperty(exports, "CameraStatus", {
  enumerable: true,
  get: function () {
    return _CameraStatus.default;
  }
});
Object.defineProperty(exports, "CameraStatusData", {
  enumerable: true,
  get: function () {
    return _CameraStatusData.default;
  }
});
Object.defineProperty(exports, "CameraStatusFilters", {
  enumerable: true,
  get: function () {
    return _CameraStatusFilters.default;
  }
});
Object.defineProperty(exports, "CropData", {
  enumerable: true,
  get: function () {
    return _CropData.default;
  }
});
Object.defineProperty(exports, "DropRecord", {
  enumerable: true,
  get: function () {
    return _DropRecord.default;
  }
});
Object.defineProperty(exports, "DroppedCropsRecord", {
  enumerable: true,
  get: function () {
    return _DroppedCropsRecord.default;
  }
});
Object.defineProperty(exports, "DroppedFramesRecord", {
  enumerable: true,
  get: function () {
    return _DroppedFramesRecord.default;
  }
});
Object.defineProperty(exports, "Edge", {
  enumerable: true,
  get: function () {
    return _Edge.default;
  }
});
Object.defineProperty(exports, "EdgeEventMsg", {
  enumerable: true,
  get: function () {
    return _EdgeEventMsg.default;
  }
});
Object.defineProperty(exports, "EdgeFilters", {
  enumerable: true,
  get: function () {
    return _EdgeFilters.default;
  }
});
Object.defineProperty(exports, "EdgeTracker", {
  enumerable: true,
  get: function () {
    return _EdgeTracker.default;
  }
});
Object.defineProperty(exports, "EditSessionFiltersApi", {
  enumerable: true,
  get: function () {
    return _EditSessionFiltersApi.default;
  }
});
Object.defineProperty(exports, "EventFilters", {
  enumerable: true,
  get: function () {
    return _EventFilters.default;
  }
});
Object.defineProperty(exports, "EventType", {
  enumerable: true,
  get: function () {
    return _EventType.default;
  }
});
Object.defineProperty(exports, "EventsResponseDocumentation", {
  enumerable: true,
  get: function () {
    return _EventsResponseDocumentation.default;
  }
});
Object.defineProperty(exports, "FaceAnalysisAttributes", {
  enumerable: true,
  get: function () {
    return _FaceAnalysisAttributes.default;
  }
});
Object.defineProperty(exports, "FaceAttribute", {
  enumerable: true,
  get: function () {
    return _FaceAttribute.default;
  }
});
Object.defineProperty(exports, "FaceFeaturesData", {
  enumerable: true,
  get: function () {
    return _FaceFeaturesData.default;
  }
});
Object.defineProperty(exports, "FaceProperties", {
  enumerable: true,
  get: function () {
    return _FaceProperties.default;
  }
});
Object.defineProperty(exports, "FrameData", {
  enumerable: true,
  get: function () {
    return _FrameData.default;
  }
});
Object.defineProperty(exports, "FrameLatencyRecord", {
  enumerable: true,
  get: function () {
    return _FrameLatencyRecord.default;
  }
});
Object.defineProperty(exports, "GeneralResponse", {
  enumerable: true,
  get: function () {
    return _GeneralResponse.default;
  }
});
Object.defineProperty(exports, "GetEventsApi", {
  enumerable: true,
  get: function () {
    return _GetEventsApi.default;
  }
});
Object.defineProperty(exports, "HTTPValidationError", {
  enumerable: true,
  get: function () {
    return _HTTPValidationError.default;
  }
});
Object.defineProperty(exports, "MatchData", {
  enumerable: true,
  get: function () {
    return _MatchData.default;
  }
});
Object.defineProperty(exports, "MatchOutcomes", {
  enumerable: true,
  get: function () {
    return _MatchOutcomes.default;
  }
});
Object.defineProperty(exports, "MngrRecord", {
  enumerable: true,
  get: function () {
    return _MngrRecord.default;
  }
});
Object.defineProperty(exports, "MsgMetadata", {
  enumerable: true,
  get: function () {
    return _MsgMetadata.default;
  }
});
Object.defineProperty(exports, "Notes", {
  enumerable: true,
  get: function () {
    return _Notes.default;
  }
});
Object.defineProperty(exports, "PoiNotes", {
  enumerable: true,
  get: function () {
    return _PoiNotes.default;
  }
});
Object.defineProperty(exports, "PossibleDataEventTypes", {
  enumerable: true,
  get: function () {
    return _PossibleDataEventTypes.default;
  }
});
Object.defineProperty(exports, "PossibleEvents", {
  enumerable: true,
  get: function () {
    return _PossibleEvents.default;
  }
});
Object.defineProperty(exports, "Properties", {
  enumerable: true,
  get: function () {
    return _Properties.default;
  }
});
Object.defineProperty(exports, "PushNotification", {
  enumerable: true,
  get: function () {
    return _PushNotification.default;
  }
});
Object.defineProperty(exports, "ScSdkDomainObjectsEventsEventAppearanceEventObjectTrigger", {
  enumerable: true,
  get: function () {
    return _ScSdkDomainObjectsEventsEventAppearanceEventObjectTrigger.default;
  }
});
Object.defineProperty(exports, "ScSdkDomainObjectsEventsSystemNotificationSystemNotificationTrigger", {
  enumerable: true,
  get: function () {
    return _ScSdkDomainObjectsEventsSystemNotificationSystemNotificationTrigger.default;
  }
});
Object.defineProperty(exports, "SentEvent", {
  enumerable: true,
  get: function () {
    return _SentEvent.default;
  }
});
Object.defineProperty(exports, "Status", {
  enumerable: true,
  get: function () {
    return _Status.default;
  }
});
Object.defineProperty(exports, "StatusEventObject", {
  enumerable: true,
  get: function () {
    return _StatusEventObject.default;
  }
});
Object.defineProperty(exports, "SystemNotification", {
  enumerable: true,
  get: function () {
    return _SystemNotification.default;
  }
});
Object.defineProperty(exports, "ValidateSessionApi", {
  enumerable: true,
  get: function () {
    return _ValidateSessionApi.default;
  }
});
Object.defineProperty(exports, "ValidationError", {
  enumerable: true,
  get: function () {
    return _ValidationError.default;
  }
});
Object.defineProperty(exports, "WatchlistObject", {
  enumerable: true,
  get: function () {
    return _WatchlistObject.default;
  }
});
Object.defineProperty(exports, "WatchlistTypes", {
  enumerable: true,
  get: function () {
    return _WatchlistTypes.default;
  }
});
Object.defineProperty(exports, "WatchlistsQuery", {
  enumerable: true,
  get: function () {
    return _WatchlistsQuery.default;
  }
});
var _ApiClient = _interopRequireDefault(require("./ApiClient"));
var _AnalysisStatusData = _interopRequireDefault(require("./model/AnalysisStatusData"));
var _AnalyticsEvent = _interopRequireDefault(require("./model/AnalyticsEvent"));
var _Appearance = _interopRequireDefault(require("./model/Appearance"));
var _AppearanceData = _interopRequireDefault(require("./model/AppearanceData"));
var _AppearanceEventObject = _interopRequireDefault(require("./model/AppearanceEventObject"));
var _AppearanceFilters = _interopRequireDefault(require("./model/AppearanceFilters"));
var _AppearanceFrameData = _interopRequireDefault(require("./model/AppearanceFrameData"));
var _AttributesCounters = _interopRequireDefault(require("./model/AttributesCounters"));
var _Bbox = _interopRequireDefault(require("./model/Bbox"));
var _Bbox2 = _interopRequireDefault(require("./model/Bbox1"));
var _BoundingBox = _interopRequireDefault(require("./model/BoundingBox"));
var _CameraAnalyticsCount = _interopRequireDefault(require("./model/CameraAnalyticsCount"));
var _CameraData = _interopRequireDefault(require("./model/CameraData"));
var _CameraNotes = _interopRequireDefault(require("./model/CameraNotes"));
var _CameraStatus = _interopRequireDefault(require("./model/CameraStatus"));
var _CameraStatusData = _interopRequireDefault(require("./model/CameraStatusData"));
var _CameraStatusFilters = _interopRequireDefault(require("./model/CameraStatusFilters"));
var _CropData = _interopRequireDefault(require("./model/CropData"));
var _DropRecord = _interopRequireDefault(require("./model/DropRecord"));
var _DroppedCropsRecord = _interopRequireDefault(require("./model/DroppedCropsRecord"));
var _DroppedFramesRecord = _interopRequireDefault(require("./model/DroppedFramesRecord"));
var _Edge = _interopRequireDefault(require("./model/Edge"));
var _EdgeEventMsg = _interopRequireDefault(require("./model/EdgeEventMsg"));
var _EdgeFilters = _interopRequireDefault(require("./model/EdgeFilters"));
var _EdgeTracker = _interopRequireDefault(require("./model/EdgeTracker"));
var _EventFilters = _interopRequireDefault(require("./model/EventFilters"));
var _EventType = _interopRequireDefault(require("./model/EventType"));
var _EventsResponseDocumentation = _interopRequireDefault(require("./model/EventsResponseDocumentation"));
var _FaceAnalysisAttributes = _interopRequireDefault(require("./model/FaceAnalysisAttributes"));
var _FaceAttribute = _interopRequireDefault(require("./model/FaceAttribute"));
var _FaceFeaturesData = _interopRequireDefault(require("./model/FaceFeaturesData"));
var _FaceProperties = _interopRequireDefault(require("./model/FaceProperties"));
var _FrameData = _interopRequireDefault(require("./model/FrameData"));
var _FrameLatencyRecord = _interopRequireDefault(require("./model/FrameLatencyRecord"));
var _GeneralResponse = _interopRequireDefault(require("./model/GeneralResponse"));
var _HTTPValidationError = _interopRequireDefault(require("./model/HTTPValidationError"));
var _MatchData = _interopRequireDefault(require("./model/MatchData"));
var _MatchOutcomes = _interopRequireDefault(require("./model/MatchOutcomes"));
var _MngrRecord = _interopRequireDefault(require("./model/MngrRecord"));
var _MsgMetadata = _interopRequireDefault(require("./model/MsgMetadata"));
var _Notes = _interopRequireDefault(require("./model/Notes"));
var _PoiNotes = _interopRequireDefault(require("./model/PoiNotes"));
var _PossibleDataEventTypes = _interopRequireDefault(require("./model/PossibleDataEventTypes"));
var _PossibleEvents = _interopRequireDefault(require("./model/PossibleEvents"));
var _Properties = _interopRequireDefault(require("./model/Properties"));
var _PushNotification = _interopRequireDefault(require("./model/PushNotification"));
var _ScSdkDomainObjectsEventsEventAppearanceEventObjectTrigger = _interopRequireDefault(require("./model/ScSdkDomainObjectsEventsEventAppearanceEventObjectTrigger"));
var _ScSdkDomainObjectsEventsSystemNotificationSystemNotificationTrigger = _interopRequireDefault(require("./model/ScSdkDomainObjectsEventsSystemNotificationSystemNotificationTrigger"));
var _SentEvent = _interopRequireDefault(require("./model/SentEvent"));
var _Status = _interopRequireDefault(require("./model/Status"));
var _StatusEventObject = _interopRequireDefault(require("./model/StatusEventObject"));
var _SystemNotification = _interopRequireDefault(require("./model/SystemNotification"));
var _ValidationError = _interopRequireDefault(require("./model/ValidationError"));
var _WatchlistObject = _interopRequireDefault(require("./model/WatchlistObject"));
var _WatchlistTypes = _interopRequireDefault(require("./model/WatchlistTypes"));
var _WatchlistsQuery = _interopRequireDefault(require("./model/WatchlistsQuery"));
var _EditSessionFiltersApi = _interopRequireDefault(require("./api/EditSessionFiltersApi"));
var _GetEventsApi = _interopRequireDefault(require("./api/GetEventsApi"));
var _ValidateSessionApi = _interopRequireDefault(require("./api/ValidateSessionApi"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }