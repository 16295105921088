import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import store from "src/appConfig/configureStore";
import { retain, retainAppearance, retainConfirm, retainNote, retainSuccess, retainUndoConfirm, retainUndoNote, unretain, unretainAppearance, unretainSuccess } from "src/appConfig/Strings";
import { retainAppearanceDialog } from "@/Logic/Hooks/useDialog/useDialog.model";
import ApiContext from "@/Logic/Context/apiContext";
import { updateLocalAppearance, setDialogData } from "src/appConfig/customEvents";
import { updateEvent } from "@/Logic/Redux/Stores/EventsStore";
import { toastUtility } from "@/Logic/Hooks/useToast";
import MainDialog from "../MainDialog";
import { alertsTable, eventTable } from "../../MUITables/TablesLogic";

function retainAppearanceHandler(appearanceId, isEventRetained) {
    const updateData = { appearanceId, updateData: { retain: !isEventRetained } };
    store.dispatch(updateEvent(updateData));
}

const retainTypeHandleMapping = {
    [eventTable]: retainAppearanceHandler,
    [alertsTable]: retainAppearanceHandler
};

function AppearanceRetainDialog() {
    const { clientsManager } = useContext(ApiContext);
    const [dialogDetails, setAppearanceDetails] = useState({});

    const retainedAppearanceDetailsFunc = useCallback((event) => {
        setAppearanceDetails(event.detail);
    }, []);

    useEffect(() => {
        document.addEventListener(setDialogData, retainedAppearanceDetailsFunc);
        return () => {
            document.removeEventListener(setDialogData, retainedAppearanceDetailsFunc);
        };
    }, [retainedAppearanceDetailsFunc]);

    async function setAppearanceRetention() {
        let response = null;
        const localUpdateFunc = retainTypeHandleMapping[dialogDetails.tableType];
        const { appearanceId, isEventRetained } = dialogDetails;
        response = await clientsManager.historyClient.setAppearanceRetention(appearanceId, !isEventRetained);
        if (response?.error) {
            return;
        }

        toastUtility.showSuccess(dialogDetails.isEventRetained ? unretainSuccess : retainSuccess);
        if (localUpdateFunc) {
            localUpdateFunc(appearanceId, isEventRetained);
        } else {
            document.dispatchEvent(new CustomEvent(updateLocalAppearance, { detail: { ...dialogDetails, updateData: { retain: !isEventRetained } } }));
        }
    }

    const { headerTitle, footerButtonText, dialogContent } = useMemo(() => {
        return dialogDetails.isEventRetained
            ? {
                headerTitle: unretainAppearance,
                footerButtonText: unretain,
                dialogContent: [retainUndoConfirm, retainUndoNote]
            }
            : {
                headerTitle: retainAppearance,
                footerButtonText: retain,
                dialogContent: [retainConfirm, retainNote]
            };
    }, [dialogDetails.isEventRetained]);

    return (
        <MainDialog dialogID={retainAppearanceDialog} headerTitle={headerTitle} footerSubmitBtnText={footerButtonText} onSubmit={setAppearanceRetention}>
            <div className="flex flex-col items-center justify-center gap-4 text-center">
                {dialogContent.map((text, index) => <span key={index}>{text}</span>)}
            </div>
        </MainDialog>
    );
}

export function dispatchRetainAppearanceDetailsEvent(appearanceId, tableType, isEventRetained) {
    const setRetainAppearanceDetailsEvent = new CustomEvent(setDialogData, { detail: { appearanceId, tableType, isEventRetained } });
    document.dispatchEvent(setRetainAppearanceDetailsEvent);
}

export default AppearanceRetainDialog;