import { IconButton } from "@mui/material";

import { ReactComponent as IconClose } from 'src/assets/Icons/Modal/icon_close.svg';

function ModalHeader({ headerIcon = null, headerRightSection = null, headerTitle, handleCancelClick, showXButton = true }) {
    return (
        <header className="flex h-modal-header-height items-center justify-between rounded-t bg-teal-blue py-main-elements-space text-white">
            <section id="header-left" data-testid="header-left" className="ml-main-elements-space mt-1.5 flex items-center">
                <i className="mr-1">
                    {headerIcon}
                </i>
                <h3 className="modal-header-title ml-1">
                    {headerTitle}
                </h3>
            </section>
            <section className="flex gap-1" id="header-right" data-testid="header-right">
                {headerRightSection}
                {showXButton && (
                    <IconButton id="icon-btn" className="mr-2.5 p-0" onClick={handleCancelClick} >
                        <IconClose className="stroke-white" />
                    </IconButton>
                )}
            </section>
        </header>
    );
}

export default ModalHeader;