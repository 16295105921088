import { isAuthorizedMatch, isNotInWatchlistMatch, isUnauthorizedMatch } from "@/Components/Common/MUITables/Columns/CropImagesColumns/CropImagesColumns.utils";
import { alertedMatchOutcomes, severityAlertValue } from "@/Logic/ApiClients/EventsClient/EventsClient";
import { addAlertedEvents, updateAlertsAcknowledgement } from "@/Logic/Redux/Stores/EventsStore";
import { generalSettingsKeys } from "@/Logic/WebWorkers/IndexDBHandler/IndexDBHandler.model";
import store from "src/appConfig/configureStore";
import { dispatchToggleDialogEvent } from "../useDialog/useDialog";
import { alertCenterDialogId } from "../useDialog/useDialog.model";

// TODO-info this is a state to hold info about what alerts were already handled and their sound states 
const triggeredAlertsInfo = {};

// TODO- should be done in the BE 
export function getWatchlistRank(watchlistData) {
    const { severity, sound_on_alert: soundOnAlert, repeated_alert: repeatedAlert } = watchlistData;
    const isBlacklistMatch = isAuthorizedMatch(watchlistData);
    const isAlerted = severity === severityAlertValue;
    const isNotInWatchlist = isNotInWatchlistMatch(watchlistData);
    let watchlistRank;
    if (isBlacklistMatch && isAlerted && soundOnAlert && repeatedAlert) {
        watchlistRank = 1;
    } else if (isBlacklistMatch && isAlerted && soundOnAlert) {
        watchlistRank = 2;
    } else if (isBlacklistMatch && isAlerted) {
        watchlistRank = 3;
    } else if (isNotInWatchlist && isAlerted && soundOnAlert && repeatedAlert) {
        watchlistRank = 4;
    } else if (isNotInWatchlist && isAlerted && soundOnAlert) {
        watchlistRank = 5;
    } else if (isNotInWatchlist && isAlerted) {
        watchlistRank = 6;
    } else if (isBlacklistMatch) {
        watchlistRank = 7;
    } else if (isUnauthorizedMatch(watchlistData)) {
        watchlistRank = 8;
    } else if (isNotInWatchlist) {
        watchlistRank = 9;
    } else {
        watchlistRank = 10;
    }

    return watchlistRank;
}

export function sortWatchlistsByRank(appearanceEvent) {
    appearanceEvent.match_data.watchlists.sort((a, b) => getWatchlistRank(a) - getWatchlistRank(b));
}

export function getWatchlistSoundDetails(watchlists) {
    const alertingDetails = { playedSingleSound: false, playedRepeatedSound: false };
    for (const watchlist of watchlists) {
        const { sound_on_alert: soundOnAlert, repeated_alert: repeatedAlert, match_outcome: matchOutcome, watchlist_type: watchlistType } = watchlist;
        const isAlertableMatchOutcome = (matchOutcome === alertedMatchOutcomes[watchlistType]);
        if (!(soundOnAlert && isAlertableMatchOutcome)) {
            continue;
        }

        if (repeatedAlert) {
            alertingDetails.playedRepeatedSound = true;
        } else {
            alertingDetails.playedSingleSound = true;
        }
    }

    return alertingDetails;
}

function playSound(alertedEventId, currentAlertingDetails, audioManager) {
    const soundMuted = store.getState().ApiActionsStore?.generalSettings[generalSettingsKeys.muteAlertsSound];
    if (soundMuted) {
        return;
    }

    if (currentAlertingDetails.playedRepeatedSound) {
        store.dispatch(updateAlertsAcknowledgement({ alertId: alertedEventId, isAcknowledged: false, isSoundRepeat: true }));
        audioManager.playRepeatSound();
    } else if (currentAlertingDetails.playedSingleSound) {
        audioManager.play();
    }
}

export function alertSoundHandler(appearanceEvent, audioManager) {
    const { appearance_data: appearanceData, match_data: matchData } = appearanceEvent;
    const alertedEventId = appearanceData.appearance_id;
    const previousAlertingDetails = triggeredAlertsInfo[alertedEventId];
    const currentAlertingDetails = getWatchlistSoundDetails(matchData.watchlists);
    triggeredAlertsInfo[alertedEventId] = currentAlertingDetails;
    const detectedSoundChanges =
        (!previousAlertingDetails?.playedRepeatedSound && currentAlertingDetails.playedRepeatedSound) ||
        (!previousAlertingDetails?.playedSingleSound && currentAlertingDetails.playedSingleSound);

    if (!previousAlertingDetails || detectedSoundChanges) {
        if (detectedSoundChanges) {
            openAlertsPopup(appearanceEvent);
        }

        playSound(alertedEventId, currentAlertingDetails, audioManager);
    }
}

export function openAlertsPopup() {
    const storesState = store.getState();
    const isAlertsSnoozed = storesState.ApiActionsStore?.generalSettings[generalSettingsKeys.snoozeAlerts];
    const isAlertsDialogOpen = storesState.GlobalStore?.dialogOpenStatus[alertCenterDialogId];
    if (!isAlertsSnoozed && !isAlertsDialogOpen) {
        dispatchToggleDialogEvent(alertCenterDialogId);
    }
}

export function handleAlertOnEvent(appearanceEvent) {
    let eventShouldBeAlerted = false;
    for (const watchlist of appearanceEvent.match_data.watchlists) {
        const { watchlist_type: watchlistType, match_outcome: matchOutcome, severity } = watchlist;
        const isAlertableMatchOutcome = (matchOutcome === alertedMatchOutcomes[watchlistType]);
        const isAlertableSeverity = severity === severityAlertValue;
        if (isAlertableSeverity && isAlertableMatchOutcome) {
            eventShouldBeAlerted = true;
            break;
        }
    }

    if (eventShouldBeAlerted) {
        store.dispatch(addAlertedEvents(appearanceEvent));
        const previousAlertDetailsForEvent = triggeredAlertsInfo[appearanceEvent.appearance_data.appearance_id];
        if (!previousAlertDetailsForEvent) {
            openAlertsPopup(appearanceEvent);
        }
    }

    return eventShouldBeAlerted;
}

export function clearAlertsEvents(removedAppearanceId) {
    if (removedAppearanceId && triggeredAlertsInfo[removedAppearanceId]) {
        delete triggeredAlertsInfo[removedAppearanceId];
    } else {
        Object.keys(triggeredAlertsInfo).forEach((key) => delete triggeredAlertsInfo[key]);
    }
}