import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Step, StepLabel, Stepper } from "@mui/material";
import { isEmpty } from "lodash";

import BaseModal from "../Modals/BaseModal/BaseModal";
import { stepperEvent } from "src/appConfig/customEvents";
import { backLabel, cancel, finishLabel, nextLabel } from "src/appConfig/Strings";
import { initialStepData, initialStepperInfo } from "./ProgressStepper.model";

function ProgressStepper() {
    const [steps, setSteps] = useState([]);
    const [stepperInfo, setStepperInfo] = useState(initialStepperInfo);
    const [stepsData, setStepsData] = useState([initialStepData]);

    const currentStepIndex = stepsData?.length - 1;
    const StepComponent = steps?.[currentStepIndex]?.component;
    const stepLabel = steps?.[currentStepIndex]?.label;
    const dialogLabel = steps?.[currentStepIndex]?.dialogLabel;

    const rightBtnText = useMemo(() => {
        let btnText = nextLabel;
        if (currentStepIndex === steps?.length - 1) {
            btnText = stepsData[currentStepIndex]?.compData?.rightBtnText || finishLabel;
        } else if (stepsData[currentStepIndex]?.compData?.rightBtnText) {
            btnText = stepsData[currentStepIndex]?.compData?.rightBtnText;
        }

        return btnText;
    }, [currentStepIndex, steps?.length, stepsData]);

    const leftBtnText = useMemo(() => currentStepIndex === 0 ? cancel : backLabel, [currentStepIndex]);

    const handleCloseStepper = useCallback(() => {
        setSteps([]);
        setStepsData([initialStepData]);
        setStepperInfo({ id: "", initialedData: {}, isStepperOpen: false, onExit: null });
    }, []);

    const closeWithHandleExit = useCallback(() => {
        handleCloseStepper();
        stepperInfo.onExit && stepperInfo.onExit();
    }, [handleCloseStepper, stepperInfo]);

    const handleBackStep = useCallback(() => {
        if (currentStepIndex === 0 || (currentStepIndex <= 1 && stepsData[0]?.autoTriggerNextStep)) {
            closeWithHandleExit();
            return;
        } else {
            const stepsToRemove = stepsData[currentStepIndex - 1]?.autoTriggerNextStep ? 2 : 1;
            setStepsData((prevStepsData) => prevStepsData.slice(0, -stepsToRemove));
        }
    }, [closeWithHandleExit, currentStepIndex, stepsData]);

    const handleNextStep = useCallback(async () => {
        const { handleNext } = stepsData[currentStepIndex];
        const isLastStep = currentStepIndex === steps.length - 1;

        handleNext && await handleNext();
        if (isLastStep) {
            dispatchStepperEvent({ id: stepperInfo.id, selectedSteps: steps, stepsData });
            handleCloseStepper();
        } else {
            setStepsData((prevStepsData) => [...prevStepsData, initialStepData]);
        }
    }, [currentStepIndex, handleCloseStepper, stepperInfo.id, steps, stepsData]);

    const updateStepDataByIndex = useCallback(({ stepIndex = currentStepIndex, isPreCheckPassed, newCompData, newIsFinal, autoTriggerNextStep, handleNext, isLeftBtnDisabled = true }) => {
        setStepsData((prevStepsData) => {
            const clonedStepsData = [...prevStepsData];
            const currStepData = clonedStepsData[stepIndex];
            const updatedStepData = {
                ...currStepData,
                isPreCheckPassed: isPreCheckPassed ?? currStepData.isPreCheckPassed,
                compData: !isEmpty(newCompData) ? { ...currStepData.compData, ...newCompData } : currStepData.compData,
                isFinal: newIsFinal ?? currStepData.isFinal,
                autoTriggerNextStep: autoTriggerNextStep ?? currStepData.autoTriggerNextStep,
                handleNext: handleNext,
                isLeftBtnDisabled,
            };
            clonedStepsData[stepIndex] = updatedStepData;

            return clonedStepsData;
        });
    }, [currentStepIndex]);

    useEffect(() => {
        if (isEmpty(steps)) {
            return;
        }

        if (stepsData[currentStepIndex].autoTriggerNextStep) {
            const timeoutId = setTimeout(() => {
                handleNextStep(stepsData[currentStepIndex].autoTriggerNextStep);
            }, 1000);

            return () => clearTimeout(timeoutId);
        }
    }, [currentStepIndex, handleNextStep, steps, stepsData]);

    useEffect(() => {
        const handleToggleStepperEvent = (event) => {
            const { id, initialedData, shouldOpenStepper, selectedSteps, handleExit } = event.detail;

            if (!shouldOpenStepper) {
                return;
            }

            setStepsData([initialStepData]);
            setStepperInfo((prev) => ({ ...prev, id, initialedData, isStepperOpen: true, onExit: handleExit }));
            setSteps(selectedSteps);
        };

        document.addEventListener(stepperEvent, handleToggleStepperEvent);
        return () => document.removeEventListener(stepperEvent, handleToggleStepperEvent);
    }, [handleCloseStepper]);

    if (!stepperInfo.isStepperOpen) {
        return null;
    }

    return (
        <BaseModal
            isOpen={stepperInfo.isStepperOpen}
            onClickHeaderCancel={closeWithHandleExit}
            isRightBtnDisabled={!stepsData[currentStepIndex]?.isFinal}
            isLeftBtnDisabled={stepsData[currentStepIndex]?.isLeftBtnDisabled && !stepsData[currentStepIndex]?.isFinal}
            handleLeftBtnAction={handleBackStep}
            handleRightBtnAction={handleNextStep}
            headerTitle={dialogLabel || stepLabel}
            leftBtnText={leftBtnText}
            rightBtnText={rightBtnText}
            className="h-full max-h-[48rem]"
        >
            <Stepper className="flex items-center justify-center pb-3" activeStep={currentStepIndex}>
                {steps?.map((step, index) => (
                    <Step key={index} className="text-center">
                        <StepLabel className="text-center">{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className="flex h-full max-h-modal-layout items-center justify-center">
                {StepComponent && (
                    <StepComponent
                        prevStepData={currentStepIndex > 0 ? stepsData[currentStepIndex - 1]?.compData : stepperInfo.initialedData}
                        currData={stepsData[currentStepIndex]?.compData}
                        setCurrData={updateStepDataByIndex}
                    />
                )}
            </div>
        </BaseModal >
    );
}

export default memo(ProgressStepper);

export function dispatchStepperEvent({ id, initialedData, shouldOpenStepper, selectedSteps, stepsData, handleExit }) {
    const stepperCustomEvent = new CustomEvent(stepperEvent, { detail: { id, initialedData, shouldOpenStepper, selectedSteps, stepsData, handleExit } });
    document.dispatchEvent(stepperCustomEvent);
}

export function findStepDataIndex(selectedSteps, stepId) {
    const stepDataIndex = selectedSteps.findIndex((step) => step.id === stepId);

    return stepDataIndex === -1 ? null : stepDataIndex;
}