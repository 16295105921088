function BaseBar({ leftContent, rightContent, className }) {
    return (
        <div className={`flex min-h-switchable-panel-header items-center justify-between rounded-t bg-panels ${className}`}>
            <section className="flex items-center" data-testid="bar-left-section">
                <span className="panel-title ml-5">
                    {leftContent}
                </span>
            </section>
            <section className="flex items-center" data-testid="bar-right-section">
                <span className="panel-title mr-5">
                    {rightContent}
                </span>
            </section>
        </div>
    );
}

export default BaseBar;