import { useCallback, useContext, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { editGenerativeInsight, remove, removeGenerativeInsight, removeGenerativeInsightConfirmation } from "src/appConfig/Strings";
import ContextMenuButton from "../../Common/Buttons/ContextMenuButton";
import { insightsContextsOptions } from "./insightsContextsOptions";
import ApiContext from "@/Logic/Context/apiContext";
import { generativeInsightTypes } from "../CreateEditInsights/CreateEditInsight.model";

function InsightsContextsMenu({ id, location, removeRowHandler, insightTypeValue, isLiveAnalysisMode }) {
    const { clientsManager } = useContext(ApiContext);
    const navigate = useNavigate();

    const options = useCallback(() => {
        const optionsMap = insightsContextsOptions[location];
        return optionsMap ? optionsMap({ id, navigate, clientsManager, removeRowHandler }) : {};
    }, [clientsManager, id, location, navigate, removeRowHandler]);

    const confirmationOptions = useMemo(() => ({
        [removeGenerativeInsight]: {
            acceptButtonText: remove,
            confirmationText: removeGenerativeInsightConfirmation
        },
        [remove]: {
            acceptButtonText: remove,
            confirmationText: removeGenerativeInsightConfirmation
        }
    }), []);

    const disabledOptions = useMemo(() => ({
        [editGenerativeInsight]: () => !isLiveAnalysisMode && insightTypeValue === generativeInsightTypes.personJourney
    }), [insightTypeValue, isLiveAnalysisMode]);

    return (
        <ContextMenuButton options={options()} confirmationOptions={confirmationOptions} disabledOptions={disabledOptions} />
    );
}

const mapStateToProps = (state) => {
    const { isLiveAnalysisMode } = state.ApiActionsStore;

    return { isLiveAnalysisMode };
};

export default connect(mapStateToProps, null)(InsightsContextsMenu);