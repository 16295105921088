"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Notes model module.
 * @module model/Notes
 * @version 0.37.0
 */
class Notes {
  /**
   * Constructs a new <code>Notes</code>.
   * @alias module:model/Notes
   */
  constructor() {
    Notes.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Notes</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Notes} obj Optional instance to populate.
   * @return {module:model/Notes} The populated <code>Notes</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Notes();
      if (data.hasOwnProperty('properties')) {
        obj['properties'] = _ApiClient.default.convertToType(data['properties'], {
          'String': Object
        });
      }
      if (data.hasOwnProperty('free_notes')) {
        obj['free_notes'] = _ApiClient.default.convertToType(data['free_notes'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Notes</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Notes</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * @member {Object.<String, Object>} properties
 */
Notes.prototype['properties'] = undefined;

/**
 * @member {Object} free_notes
 */
Notes.prototype['free_notes'] = undefined;
var _default = exports.default = Notes;