import moment from "moment";
import React, {useEffect, useState} from "react";
import {EstimatedTimeTitle, minutesTimeParseTitle} from "../../../../appConfig/Strings";

export default function Timer({remainingTime}) {
	const [formattedTime, setFormattedTime] = useState(remainingTime);

	useEffect(() => {
		const duration = moment.duration(remainingTime);
		const newFormattedTime = duration.asMinutes().toFixed(0);
		setFormattedTime(newFormattedTime);
	}, [remainingTime]);

	return <div>{EstimatedTimeTitle}: {minutesTimeParseTitle(formattedTime)}</div>;
}