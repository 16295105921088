import { poiDB, cameras, controllerHeader, eventsHeader, historyHeader, playback, usersHeader, allCamerasFilterOption, specificCamerasFilterOption, allWatchlistFilterOption, specificWatchlistsFilterOption, allLivenessOutcomes, specificOutcomes, generativeInsightsHeader } from "src/appConfig/Strings";

import { BuiltInPermissionsModel } from "./RoleForm/Permissions/PermissionFieldsMapping.model";
import { allOptionSelected, specificOptionSelected } from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";
import { EventsFiltersModel, filtersFormFieldsNames } from "@/Components/Events/EventsFeed/EventsFilters.model";

export const CreateEditRoleFormFieldsNames = {
    roleName: "role_name",
    roleDataFilters: "role_data_filters",
};

export const filtersDefaultValue = {
    [filtersFormFieldsNames.camerasFilter]: {
        radioValue: allOptionSelected,
    },
    [filtersFormFieldsNames.matched]: {
        isToggledOn: true,
        radioValue: allOptionSelected,
    },
    [filtersFormFieldsNames.detections]: {
        isToggledOn: true
    },
    [filtersFormFieldsNames.unauthorized]: {
        isToggledOn: true,
        radioValue: allOptionSelected,
    },
    [filtersFormFieldsNames.facesAttrAgeFilter]: {
        radioValue: allOptionSelected
    },
    [filtersFormFieldsNames.facesAttrGenderFilter]: {
        radioValue: allOptionSelected
    },
    [filtersFormFieldsNames.facesAttrLivenessFilter]: {
        radioValue: allOptionSelected
    }
};

export const roleDataRadioOptions = {
    cameras: [
        {
            label: allCamerasFilterOption,
            option: allOptionSelected
        },
        {
            label: specificCamerasFilterOption,
            option: specificOptionSelected
        }
    ],
    matchInfo: [
        {
            label: allWatchlistFilterOption,
            option: allOptionSelected
        },
        {
            label: specificWatchlistsFilterOption,
            option: specificOptionSelected
        }
    ],
    facesAttr: [
        {
            label: allLivenessOutcomes,
            option: allOptionSelected
        },
        {
            label: specificOutcomes,
            option: specificOptionSelected
        }
    ]
};

export const tabsListModel = {
    POIDBService: poiDB,
    CameraService: cameras,
    HistoryDBService: historyHeader,
    UsersService: usersHeader,
    ControllerService: controllerHeader,
    FramesStorageService: playback,
    GenerativeInsightsService: generativeInsightsHeader,
    EventsService: eventsHeader,
};

const CreateEditRoleModel = {
    [CreateEditRoleFormFieldsNames.roleName]: {
        name: CreateEditRoleFormFieldsNames.roleName,
        type: "text",
        required: true,
    },
    ...EventsFiltersModel,
    ...BuiltInPermissionsModel
};

export default CreateEditRoleModel;