import { useCallback, useContext, memo } from "react";

import { connect } from "react-redux";
import { Alert } from "@mui/material";
import { FocusTrap } from '@mui/base/FocusTrap';
import { isEmpty } from "lodash";

import { camera, cameras, createCameraLoadingMessage, createdSuccessfully, editedSuccessfully, video, videos } from "src/appConfig/Strings";
import { CameraFormModel, capturingSourceMode } from "./CreateEditCamera.model";
import { dispatchIsLoadingEvent, dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { useToast } from "@/Logic/Hooks/useToast";
import { useForm } from "@/Logic/Hooks/useForm";
import { clearOpenCamera } from "@/Logic/Redux/Stores/CamerasStore";
import CreateEditCameraForm from "./CreateEditCameraForm";
import usePrepareCreateCamera from "@/Logic/Hooks/usePrepareCreateCamera";
import ApiContext from "@/Logic/Context/apiContext";
import LoaderWithText from "@/Components/Common/Loaders/LoaderWithText";
import useUIResponseHandler from "@/Logic/Hooks/useUIResponseHandler";
import { cameraDialogID } from "@/Logic/Hooks/useDialog/useDialog.model";

function CreateEditCamera({ openCamera, cameraID, clearOpenCamera, isLiveAnalysisMode }) {
    const { clientsManager } = useContext(ApiContext);
    const { isLoading, setIsLoading, responseMessage, error, successMsgs, clearResponseHandler, parseResponse } = useUIResponseHandler();
    const { updatedOpenCamera } = usePrepareCreateCamera(openCamera, cameraID, isLiveAnalysisMode);
    const { showSuccessToast } = useToast();
    const createSuccessMessage = useCallback((formObjData) => {
        let cameraType = isLiveAnalysisMode ? camera : video;
        let msg = `${cameraType} ${cameraID ? editedSuccessfully : createdSuccessfully}`;
        if (formObjData.mode === capturingSourceMode.file && !cameraID) {
            const camerasCreated = formObjData.capture_address.size;
            if (camerasCreated > 1) {
                cameraType = isLiveAnalysisMode ? cameras : videos;
                msg = `${camerasCreated} ${cameraType.toLocaleLowerCase()} ${createdSuccessfully}`;
            }
        }
        return msg;
    }, [cameraID, isLiveAnalysisMode]);

    const handleSubmitForm = useCallback(async (formObjData) => {
        setIsLoading(true);
        clearResponseHandler();
        dispatchIsLoadingEvent(true);
        let response;
        if (cameraID) {
            response = await clientsManager.camerasClient.editCamera(cameraID, formObjData, updatedOpenCamera.privacy_profile_id);
        } else {
            response = await clientsManager.camerasClient.createCamera(formObjData);
        }

        parseResponse(response);
        setIsLoading(false);
        const isSubmittedSuccessfully = !response.error;

        if (isSubmittedSuccessfully) {
            const msg = createSuccessMessage(formObjData);
            dispatchToggleDialogEvent(cameraDialogID, true, clearOpenCamera);
            await clientsManager.camerasClient.listAllCameras();
            showSuccessToast(msg);
        } else if (!isEmpty(response.successMsgs)) {
            await clientsManager.camerasClient.listAllCameras();
        }
        dispatchIsLoadingEvent(false);

        return isSubmittedSuccessfully;
    }, [cameraID, clearOpenCamera, clearResponseHandler, clientsManager.camerasClient, createSuccessMessage, parseResponse, setIsLoading, showSuccessToast, updatedOpenCamera.privacy_profile_id]);

    const { formData, onFieldChange, submitForm } = useForm(cameraID, updatedOpenCamera, CameraFormModel, handleSubmitForm);

    return (
        <div className="max-h-modal-layout w-full">
            <CreateEditCameraForm cameraID={cameraID} formData={formData} onFieldChange={onFieldChange} handleSubmitForm={submitForm} isLiveAnalysisMode={isLiveAnalysisMode} />
            <div>
                {error && (
                    <div className="mt-2 w-full">
                        {!isEmpty(successMsgs) && (
                            <Alert className="mb-2 flex w-full items-center justify-center whitespace-pre-line" severity="success">
                                {successMsgs}
                            </Alert>
                        )}
                        <FocusTrap disableEnforceFocus open>
                            <Alert className="flex w-full items-center justify-center whitespace-pre-line" severity="error" tabIndex="0" data-testid="error-alert">
                                {responseMessage}
                            </Alert>
                        </FocusTrap>
                    </div>
                )}
            </div>
            <div label="" className="justify-center">
                {isLoading && <LoaderWithText text={createCameraLoadingMessage} />}
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    clearOpenCamera
};

const mapStateToProps = (state, ownProps) => {
    const { openCamera } = state.CamerasStore;
    const { isLiveAnalysisMode } = state.ApiActionsStore;
    const cameraID = ownProps.searchParams?.get("camera_id");

    return { openCamera, cameraID, isLiveAnalysisMode };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(CreateEditCamera));