"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetAppearanceFramesResponse = _interopRequireDefault(require("../model/GetAppearanceFramesResponse"));
var _GetFramesRequest = _interopRequireDefault(require("../model/GetFramesRequest"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Frames Storage API
 * Frames Storage API gives access to the frames of recorded appearances from cameras analyzed by the application.  It allows to read and remove frames that associated with an appearance record.
 *
 * The version of the OpenAPI document: 0.34.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* FramesAndBboxesStoredData service.
* @module api/FramesAndBboxesStoredDataApi
* @version 0.34.0
*/
class FramesAndBboxesStoredDataApi {
  /**
  * Constructs a new FramesAndBboxesStoredDataApi. 
  * @alias module:api/FramesAndBboxesStoredDataApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the clearAllFramesData operation.
   * @callback module:api/FramesAndBboxesStoredDataApi~clearAllFramesDataCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Clear Stored Frames Data
   * Clears all the stored frames & corresponding saved metadata. Depending on input, may remove only the images or only the corresponding saved metadata
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.removeRetained Should remove also frames sequence of retained appearances (default to false)
   * @param {module:api/FramesAndBboxesStoredDataApi~clearAllFramesDataCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  clearAllFramesData(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'remove_retained': opts['removeRetained']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/frames_data/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the deleteFramesDataForAppearance operation.
   * @callback module:api/FramesAndBboxesStoredDataApi~deleteFramesDataForAppearanceCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete Stored Frames Data For Appearance
   * Remove all stored frames data of specific appearance. Depending on input, may remove only the images or only the corresponding saved metadata
   * @param {String} appearanceId 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.removeRetained Should remove also frames sequence of retained appearances (default to false)
   * @param {module:api/FramesAndBboxesStoredDataApi~deleteFramesDataForAppearanceCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  deleteFramesDataForAppearance(appearanceId, opts, callback) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'appearanceId' is set
    if (appearanceId === undefined || appearanceId === null) {
      throw new Error("Missing the required parameter 'appearanceId' when calling deleteFramesDataForAppearance");
    }
    let pathParams = {
      'appearance_id': appearanceId
    };
    let queryParams = {
      'remove_retained': opts['removeRetained']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/frames_data/{appearance_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getAppearanceFramesData operation.
   * @callback module:api/FramesAndBboxesStoredDataApi~getAppearanceFramesDataCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetAppearanceFramesResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Frames Data For Appearance
   * Returns the saved frames & data for appearance.
   * @param {String} appearanceId 
   * @param {module:model/GetFramesRequest} getFramesRequest 
   * @param {module:api/FramesAndBboxesStoredDataApi~getAppearanceFramesDataCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetAppearanceFramesResponse}
   */
  getAppearanceFramesData(appearanceId, getFramesRequest, callback) {
    let postBody = getFramesRequest;
    // verify the required parameter 'appearanceId' is set
    if (appearanceId === undefined || appearanceId === null) {
      throw new Error("Missing the required parameter 'appearanceId' when calling getAppearanceFramesData");
    }
    // verify the required parameter 'getFramesRequest' is set
    if (getFramesRequest === undefined || getFramesRequest === null) {
      throw new Error("Missing the required parameter 'getFramesRequest' when calling getAppearanceFramesData");
    }
    let pathParams = {
      'appearance_id': appearanceId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetAppearanceFramesResponse.default;
    return this.apiClient.callApi('/frames_data/{appearance_id}/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = FramesAndBboxesStoredDataApi;