import { lazy } from "react";

const CamerasList = lazy(() => import("@/Components/Cameras/CamerasList/CamerasList"));
const EventsList = lazy(() => import("@/Components/Events/EventsFeed/EventsFeed"));
const ServersNodesList = lazy(() => import("@/Components/Servers/ServersNodesList/ServersNodesList"));
const WatchlistScreen = lazy(() => import("@/Components/POIs/Watchlists/WatchlistScreen"));
const CreateEditWatchlist = lazy(() => import("@/Components/POIs/Watchlists/CreateEditWatchlist/CreateEditWatchlist"));
const GridDisplay = lazy(() => import("@/Components/Display/GridDisplay"));
const ImportPois = lazy(() => import("@/Components/POIs/ImportPoi/ImportPoisForm"));
const SystemInformation = lazy(() => import("@/Components/Settings/SystemInformation"));
const LicenseInformation = lazy(() => import("@/Components/Settings/License/LicenseInformation"));
const settingsConfiguration = lazy(() => import("@/Components/Settings/Configuration/SettingsConfiguration"));
const settingsUsers = lazy(() => import("@/Components/Settings/Users/UsersList/UsersList"));
const settingsPrivacy = lazy(() => import("@/Components/Settings/Privacy/PrivacyList/PrivacyList"));
const settingsDataRetention = lazy(() => import("@/Components/Settings/DataRetention/DataRetentionList/DataRetentionList"));
const generativeInsightsEventsFeed = lazy(() => import("@/Components/GenerativeInsights/InsightsFeed/InsightsFeed"));
const generativeInsightsSearch = lazy(() => import("@/Components/GenerativeInsights/InsightsSearch/InsightsSearch"));
const generativeInsightsList = lazy(() => import("@/Components/GenerativeInsights/InsightsList/InsightsList"));
const casesScreen = lazy(() => import("@/Components/Cases/Cases"));

export const routesUrl = {
    cameraList: "/cameras/cameras-list",
    eventList: "/events/events-feed",
    serverStatus: "/servers/servers-status",
    createEditWatchlist: "/poi/create-edit-watchlist",
    watchlistsScreen: "/poi/watchlists",
    displayCameras: "/display/cameras-display",
    importPois: "/poi/import-pois",
    settingsSystemInfo: "/settings/system-information",
    settingsLicense: "/settings/license",
    settingsConfiguration: "/settings/system-configuration",
    settingsUsers: "/settings/users",
    settingsPrivacy: "/settings/privacy",
    settingsDataRetention: "/settings/data-retention",
    generativeInsightsEvents: "/generative-insights/events-feed",
    generativeInsightsSearch: "/generative-insights/search",
    generativeInsightsList: "/generative-insights/generative-insights-list",
    casesScreen: "/search/cases-screen"
};

export const routes = {
    [routesUrl.cameraList]: CamerasList,
    [routesUrl.eventList]: EventsList,
    [routesUrl.serverStatus]: ServersNodesList,
    [routesUrl.watchlistsScreen]: WatchlistScreen,
    [routesUrl.createEditWatchlist]: CreateEditWatchlist,
    [routesUrl.displayCameras]: GridDisplay,
    [routesUrl.importPois]: ImportPois,
    [routesUrl.settingsSystemInfo]: SystemInformation,
    [routesUrl.settingsLicense]: LicenseInformation,
    [routesUrl.settingsConfiguration]: settingsConfiguration,
    [routesUrl.settingsUsers]: settingsUsers,
    [routesUrl.settingsPrivacy]: settingsPrivacy,
    [routesUrl.settingsDataRetention]: settingsDataRetention,
    [routesUrl.casesScreen]: casesScreen,
    [routesUrl.generativeInsightsEvents]: generativeInsightsEventsFeed,
    [routesUrl.generativeInsightsSearch]: generativeInsightsSearch,
    [routesUrl.generativeInsightsList]: generativeInsightsList,
};