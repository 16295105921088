import { useMemo } from "react";

import { connect } from "react-redux";

import { multiServerModeUnavailable, serversHeader } from "src/appConfig/Strings";
import { ServersStatusModel, ServerStatusesAndStabilityMapping } from "./ServersNodesList.model";
import ExtendedDataGrid from "@/Components/Common/MUITables/ExtendedDataGrid/ExtendedDataGrid";
import StatusMsgCard from "@/Components/Common/Loaders/StatusMsgCard";
import MainLayout from "@/Components/Common/Layout/MainLayout/MainLayout";
import MainLayoutPanel from "@/Components/Common/Layout/MainLayout/MainLayoutPanel";

export const getServerStatusAndStability = ({ active, stable }) => {
    let serverStatusRes = ServerStatusesAndStabilityMapping.pending;
    let serverStabilityRes = ServerStatusesAndStabilityMapping.pending;

    if (active !== undefined) {
        serverStatusRes = active ? ServerStatusesAndStabilityMapping.active : ServerStatusesAndStabilityMapping.inactive;
    }

    if (stable !== undefined) {
        serverStabilityRes = stable ?
            ServerStatusesAndStabilityMapping.stable :
            active ? ServerStatusesAndStabilityMapping.unStable : ServerStatusesAndStabilityMapping.inactive;
    }

    return {
        status: serverStatusRes,
        stability: serverStabilityRes
    };
};

function ServersNodesList({ isMultiServerMode, nodesList }) {
    const nodesListValues = useMemo(() => Object.values(nodesList), [nodesList]);

    return (
        isMultiServerMode ? (
            <MainLayout>
                <MainLayoutPanel headerTitle={serversHeader}>
                    <ExtendedDataGrid {...ServersStatusModel(nodesListValues)} />
                </MainLayoutPanel>
            </MainLayout>
        ) : <StatusMsgCard content={multiServerModeUnavailable} />
    );
}

const mapStateToProps = (state) => {
    const { isMultiServerMode, nodesList } = state.ApiActionsStore;

    return { isMultiServerMode, nodesList };
};

export default connect(mapStateToProps)(ServersNodesList);