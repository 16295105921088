import { useCallback, useMemo, useState } from "react";

import { connect } from "react-redux";
import Collapse from "@mui/material/Collapse";
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { CameraFormModel, RequiredFaceAttributesOptions, cameraFormFieldsNames, videoDisplayResolutions } from "./CreateEditCamera/CreateEditCamera.model";
import { advancedSettingsHeader, ageGenderDetection, analysisLoadControl, cameraNotesHeader, framePlayback, livenessDetection, maxAppearancesInFrame, minimumFaceWidth, overallDetection, personAttributes, playback, privacyProfile, unauthorizedAlert, videoDisplayResolution, videoNotesHeader } from "../../../../appConfig/Strings";
import BaseInput from "@/Components/Common/FormComponents/FormInputs/BaseInput";
import AffixInputField from "@/Components/Common/FormComponents/FormInputs/AffixInputField";
import LabelWrapper from "@/Components/Common/Wrappers/LabelWrapper";
import SelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField";
import ToggleSwitchInput from "@/Components/Common/FormComponents/FormInputs/ToggleSwitchInput";
import TextAreaInputField from "@/Components/Common/FormComponents/FormInputs/TextAreaInputField";
import { hasAllPermissions } from "@/Logic/ApiClients/UsersClient";
import { permissionsKeys } from "@/Components/Settings/Users/CreateEditRole/RoleForm/Permissions/PermissionFieldsMapping.model";

function AdvancedCreateCameraForm({ formData, onFieldChange, appearancesProfiles, isLiveAnalysisMode }) {
    const { requiredFaceAttributes, cameraNotes, recordFrames, minDetectionWidth, authorizedMinFaceWidth, ageGenderMinWidth, livenessMinWidth, videoDisplayResolution: videoDisplayResolutionField, privacyProfileId, streamMaxFaces } = cameraFormFieldsNames;
    const [showAdvancedSetting, setShowAdvancedSetting] = useState(false);
    const hasEditPrivacyPermission = useMemo(() => {
        const permissions = [isLiveAnalysisMode ? permissionsKeys.setLiveCameraPrivacy : permissionsKeys.setInvestigateCameraPrivacy];

        return hasAllPermissions(permissions);
    }, [isLiveAnalysisMode]);

    const faceAttributesElements = useMemo(() => {
        const handleToggleChange = (AttributeOption, value) => {
            const updatedAttributeOptions = {
                ...formData[requiredFaceAttributes],
                [AttributeOption]: value
            };
            onFieldChange(requiredFaceAttributes, updatedAttributeOptions);
        };

        return RequiredFaceAttributesOptions.map(({ AttributeOption, label }) => {
            const isSelected = formData[requiredFaceAttributes]?.[AttributeOption];

            return (
                <LabelWrapper inline={true} className="ml-main-elements-space" key={label} label={label}>
                    <ToggleSwitchInput
                        value={isSelected}
                        onChange={(event) => handleToggleChange(AttributeOption, event.target.checked)}
                        dataTestId={label}
                    />
                </LabelWrapper>
            );
        });
    }, [formData, onFieldChange, requiredFaceAttributes]);

    const toggleShowAdvance = useCallback(() => {
        setShowAdvancedSetting((show) => !show);
    }, [setShowAdvancedSetting]);

    return (
        <div>
            <div className="mt-2.5 flex justify-center">
                <button type="button" data-testid="advanced-settings-btn" onClick={toggleShowAdvance}>
                    {advancedSettingsHeader}
                </button>
                {showAdvancedSetting ? <ExpandLess /> : <ExpandMore />}
            </div>
            <Collapse in={showAdvancedSetting} >
                <TextAreaInputField
                    label={isLiveAnalysisMode ? cameraNotesHeader : videoNotesHeader}
                    model={CameraFormModel[cameraNotes]}
                    value={formData[cameraNotes]}
                    onChange={(event) => onFieldChange(cameraNotes, event.target.value)}
                    dataTestId="camera-notes"
                />
                <LabelWrapper label={personAttributes}>
                    <div className="flex flex-col">
                        {faceAttributesElements}
                    </div>
                    <LabelWrapper label={playback}>
                        <LabelWrapper inline={true} className="ml-main-elements-space" label={framePlayback}>
                            <ToggleSwitchInput
                                model={CameraFormModel[recordFrames]}
                                value={formData[recordFrames]}
                                onChange={(event) => onFieldChange(recordFrames, event.target.checked)}
                                dataTestId="playback-toggle"
                            />
                        </LabelWrapper>
                    </LabelWrapper>
                </LabelWrapper>
                <LabelWrapper label={minimumFaceWidth}>
                    <div className="ml-main-elements-space flex flex-col gap-2.5">
                        <LabelWrapper inline={true} label={overallDetection}>
                            <AffixInputField
                                model={CameraFormModel[minDetectionWidth]}
                                value={formData[minDetectionWidth]}
                                onChange={(event) => onFieldChange(minDetectionWidth, event.target.valueAsNumber)}
                                dataTestId="overall-detection"
                            />
                        </LabelWrapper>
                        <LabelWrapper inline={true} label={unauthorizedAlert}>
                            <AffixInputField
                                model={CameraFormModel[authorizedMinFaceWidth]}
                                value={formData[authorizedMinFaceWidth]}
                                onChange={(event) => onFieldChange(authorizedMinFaceWidth, event.target.valueAsNumber)}
                                dataTestId="unauthorized-alert"
                            />
                        </LabelWrapper>
                        <LabelWrapper inline={true} label={ageGenderDetection}>
                            <AffixInputField
                                model={CameraFormModel[ageGenderMinWidth]}
                                value={formData[ageGenderMinWidth]}
                                onChange={(event) => onFieldChange(ageGenderMinWidth, event.target.valueAsNumber)}
                                dataTestId="age-gender-detection"
                            />
                        </LabelWrapper>
                        <LabelWrapper inline={true} label={livenessDetection}>
                            <AffixInputField
                                model={CameraFormModel[livenessMinWidth]}
                                value={formData[livenessMinWidth]}
                                onChange={(event) => onFieldChange(livenessMinWidth, event.target.valueAsNumber)}
                                dataTestId="liveness-detection"
                            />
                        </LabelWrapper>
                    </div>
                </LabelWrapper>
                {isLiveAnalysisMode &&
                    <LabelWrapper label={videoDisplayResolution}>
                        <SelectInputField
                            model={CameraFormModel[videoDisplayResolutionField]}
                            value={formData[videoDisplayResolutionField]}
                            onChange={(event) => onFieldChange(videoDisplayResolutionField, event.target.value)}
                            options={Object.values(videoDisplayResolutions)}
                            dataTestId="video-display-resolution"
                        />
                    </LabelWrapper>
                }
                <LabelWrapper label={privacyProfile}>
                    <SelectInputField
                        label={privacyProfile}
                        model={CameraFormModel[privacyProfileId]}
                        value={formData[privacyProfileId]}
                        onChange={(event) => onFieldChange(privacyProfileId, event.target.value)}
                        options={Object.values(appearancesProfiles)}
                        disabled={!hasEditPrivacyPermission}
                        dataTestId="privacy-profile"
                    />
                </LabelWrapper>
                {isLiveAnalysisMode &&
                    <LabelWrapper label={analysisLoadControl} >
                        <div className="mt-2">
                            <BaseInput
                                label={maxAppearancesInFrame}
                                model={CameraFormModel[streamMaxFaces](isLiveAnalysisMode)}
                                value={formData[streamMaxFaces]}
                                onChange={(event) => onFieldChange(streamMaxFaces, event.target.valueAsNumber)}
                                fullWidth
                                dataTestId="analysis-load-control"
                            />
                        </div>
                    </LabelWrapper>
                }
            </Collapse>
        </div>
    );
}

const mapStateToProps = (state) => {
    const appearancesProfiles = state.PrivacyProfilesStore.appearancesProfiles;

    return { appearancesProfiles };
};

export default connect(mapStateToProps, null)(AdvancedCreateCameraForm);