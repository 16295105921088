import Grid from '@mui/material/Grid';

function GridItem({ className, onClick, children }) {
    return (
        <Grid item className={`flex items-center justify-center rounded ${className}`} onClick={onClick}>
            {children}
        </Grid>
    );
}

export default GridItem;