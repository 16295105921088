import { ApiClient, GenerativeInsightsApi } from "smart_cameras_generative_insights_api";

import { has, includes, isEmpty, isSet, values } from "lodash";

import store from "src/appConfig/configureStore";
import BaseAPIClient, { resCodes } from "./BaseAPIClient";
import { addGenerativeInsight, removeGenerativeInsight, setGenerativeInsights, setOpenInsight } from "../Redux/Stores/GenerativeInsightsStore";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { repeatedIndividualFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/InsightTypes/RepeatedIndividualInsight/RepeatedIndividualInsight.model";
import { crowdedAreaFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/InsightTypes/CrowdedAreaInsight/CrowdedAreaInsight.model";
import { loiteringFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/InsightTypes/LoiteringInsight/LoiteringInsight.model";
import { insightsRoute } from "../Infrastructure/networkConf";
import { generativeInsightTypes, insightFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/CreateEditInsight.model";
import { personJourneyFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/InsightTypes/PersonJourneyInsight/PersonJourney.model";
import { poiProximityFormFieldsNames } from "@/Components/GenerativeInsights/CreateEditInsights/InsightTypes/PoiProximityInsight/PoiProximityInsight.model";
import { daysHoursMinSecTimeMapping, minSecTimeMapping, timeMappingToValue } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector.model";
import { baseCamerasFieldNames } from "@/Components/GenerativeInsights/CreateEditInsights/BaseModels";
import { specificOptionSelected } from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";

export class GenerativeInsightsClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(insightsRoute, clientInstance);
        this.generativeInsightsApi = new GenerativeInsightsApi();
    }

    async #handleListAllInsights(generativeInsightsData) {
        const generativeInsights = await this.serializeApiData(generativeInsightsData.data.generative_insights,
            (generativeInsight) => generativeInsight.insight_id);
        store.dispatch(setGenerativeInsights(generativeInsights));
    }

    async listAllInsights() {
        await this.apiCall("List all generative insights",
            (callback) => this.generativeInsightsApi.listGenerativeInsights(callback),
            (_, data) => {
                this.#handleListAllInsights(data);
            }
        );
    }

    async getInsight(insightId) {
        return await this.apiCall("Get Generative Insight",
            (callback) => this.generativeInsightsApi.getGenerativeInsight(insightId, callback),
            (_, data) => {
                const payload = data.data;
                store.dispatch(setOpenInsight(payload));
            });
    }

    #parseGenerativeInsightForm(formObjData, typeOfUpdateInsight) {
        const { insight_name, insight_type, active, insight_notes, excluded_watchlists, min_detection_width, generate_event } = formObjData;

        const generativeInsightsPrams = {
            ...(generate_event !== undefined && { generate_event }),
            ...(insight_name && { insight_name }),
            ...(insight_type && { insight_type }),
            ...(active !== undefined && { active }),
            ...(insight_notes && {
                insight_notes: {
                    free_notes: insight_notes
                }
            }),
        };

        const parsedDataByInsightType = parseInsightConfigData(formObjData, insightConfigParsers[typeOfUpdateInsight], typeOfUpdateInsight);

        const generativeInsightConfig = {
            ...parsedDataByInsightType,
            ...(excluded_watchlists && { excluded_watchlists: Array.from(excluded_watchlists) }),
            ...(min_detection_width !== undefined && { min_detection_width }),
        };

        if (!isEmpty(generativeInsightConfig)) {
            generativeInsightsPrams.generative_insight_config = generativeInsightConfig;
        }

        return generativeInsightsPrams;
    }

    #handleAddGenerativeInsight(error, generativeInsight, response) {
        store.dispatch(addGenerativeInsight(generativeInsight.data));
        return parseResponseForUI(error, response);
    }

    async createGenerativeInsight(formObjData) {
        const parsedGenerativeInsightForm = this.#parseGenerativeInsightForm(formObjData, formObjData.insight_type);

        return await this.apiCall("Create Generative Insight",
            (callback) => this.generativeInsightsApi.createGenerativeInsight(parsedGenerativeInsightForm, callback),
            (error, data, response) => this.#handleAddGenerativeInsight(error, data, response),
            {
                [resCodes.badRequest]: (error, __, response) => parseResponseForUI(error, response),
                [resCodes.unprocessable]: (error, __, response) => parseResponseForUI(error, response),
                [resCodes.notFound]: (error, __, response) => parseResponseForUI(error, response)
            });
    }

    async updateGenerativeInsight(generativeInsightId, typeOfUpdateInsight, generativeInsightForm) {
        const parsedGenerativeInsightForm = this.#parseGenerativeInsightForm(generativeInsightForm, typeOfUpdateInsight);

        return await this.apiCall("Update Generative Insight",
            (callback) => this.generativeInsightsApi.updateGenerativeInsight(generativeInsightId, parsedGenerativeInsightForm, callback),
            (error, data, response) => this.#handleAddGenerativeInsight(error, data, response),
            {
                [resCodes.badRequest]: (error, __, response) => parseResponseForUI(error, response),
                [resCodes.unprocessable]: (error, __, response) => parseResponseForUI(error, response),
                [resCodes.notFound]: (error, __, response) => parseResponseForUI(error, response)
            });
    }

    async removeGenerativeInsight(generativeInsightId) {
        return await this.apiCall("Remove Generative Insight",
            (callback) => this.generativeInsightsApi.removeGenerativeInsight(generativeInsightId, callback),
            () => store.dispatch(removeGenerativeInsight({ generativeInsightId })));
    }
}

const insightFieldParseMap = {
    [loiteringFormFieldsNames.alertingLoiteringDuration]: (value) => timeMappingToValue(value, minSecTimeMapping),
    [crowdedAreaFormFieldsNames.alertingCrowdedDuration]: (value) => timeMappingToValue(value, minSecTimeMapping),
    [repeatedIndividualFormFieldsNames.alertingTimeFrame]: (value) => timeMappingToValue(value, daysHoursMinSecTimeMapping),
    [repeatedIndividualFormFieldsNames.notAlertingTimeFrame]: (value) => timeMappingToValue(value, daysHoursMinSecTimeMapping),
    [personJourneyFormFieldsNames.alertingJourneyDuration]: (value) => timeMappingToValue(value, daysHoursMinSecTimeMapping),
    [poiProximityFormFieldsNames.appearancesOverlapTimeout]: (value) => timeMappingToValue(value, daysHoursMinSecTimeMapping),
    [baseCamerasFieldNames.videos]: (value) => value.radioValue == specificOptionSelected ? Array.from(value.selectedOptions) : []
};

const insightConfigParsers = {
    [generativeInsightTypes.loitering]: loiteringFormFieldsNames,
    [generativeInsightTypes.crowded_area]: crowdedAreaFormFieldsNames,
    [generativeInsightTypes.repeated_individual]: repeatedIndividualFormFieldsNames,
    [generativeInsightTypes.poiProximity]: poiProximityFormFieldsNames,
    [generativeInsightTypes.personJourney]: personJourneyFormFieldsNames,
};

export const parseInsightConfigData = (formObjData, insightFieldsKeys, typeOfUpdateInsight) => {
    const parsedValue = Object.values(insightFieldsKeys).reduce((acc, insightKey) => {
        const fieldData = formObjData[insightKey];
        const doesBaseKeyExist = has(insightFormFieldsNames, insightKey);
        const doesInsightConfigKeyExist = includes(values(insightConfigParsers[typeOfUpdateInsight]), insightKey);
        const doesSpecialHandlingKeyExist = has(insightFieldParseMap, insightKey);
        if (fieldData === undefined || (!doesBaseKeyExist && !doesInsightConfigKeyExist)) {
            return acc;
        }

        if (isSet(fieldData)) {
            acc[insightKey] = Array.from(fieldData);
        } else if (doesSpecialHandlingKeyExist) {
            acc[insightKey] = insightFieldParseMap[insightKey](fieldData);
        } else {
            acc[insightKey] = formObjData[insightKey];
        }

        return acc;
    }, {});

    return parsedValue;
};