"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _MsgMetadata = _interopRequireDefault(require("./MsgMetadata"));
var _PermissionsResp = _interopRequireDefault(require("./PermissionsResp"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GetPermissionsResponse model module.
 * @module model/GetPermissionsResponse
 * @version 0.35.0
 */
class GetPermissionsResponse {
  /**
   * Constructs a new <code>GetPermissionsResponse</code>.
   * @alias module:model/GetPermissionsResponse
   * @param metadata {module:model/MsgMetadata} 
   * @param data {module:model/PermissionsResp} 
   */
  constructor(metadata, data) {
    GetPermissionsResponse.initialize(this, metadata, data);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, metadata, data) {
    obj['metadata'] = metadata;
    obj['data'] = data;
  }

  /**
   * Constructs a <code>GetPermissionsResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GetPermissionsResponse} obj Optional instance to populate.
   * @return {module:model/GetPermissionsResponse} The populated <code>GetPermissionsResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GetPermissionsResponse();
      if (data.hasOwnProperty('metadata')) {
        obj['metadata'] = _MsgMetadata.default.constructFromObject(data['metadata']);
      }
      if (data.hasOwnProperty('data')) {
        obj['data'] = _PermissionsResp.default.constructFromObject(data['data']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GetPermissionsResponse</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetPermissionsResponse</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of GetPermissionsResponse.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // validate the optional field `metadata`
    if (data['metadata']) {
      // data not null
      _MsgMetadata.default.validateJSON(data['metadata']);
    }
    // validate the optional field `data`
    if (data['data']) {
      // data not null
      _PermissionsResp.default.validateJSON(data['data']);
    }
    return true;
  }
}
GetPermissionsResponse.RequiredProperties = ["metadata", "data"];

/**
 * @member {module:model/MsgMetadata} metadata
 */
GetPermissionsResponse.prototype['metadata'] = undefined;

/**
 * @member {module:model/PermissionsResp} data
 */
GetPermissionsResponse.prototype['data'] = undefined;
var _default = exports.default = GetPermissionsResponse;