import { useContext, useEffect, useMemo, useState } from "react";

import _ from "lodash";

import ApiContext from "../Context/apiContext";
import { CreateEditRoleFormFieldsNames, filtersDefaultValue } from "@/Components/Settings/Users/CreateEditRole/CreateEditRole.model";
import { BuiltInPermissionsModel } from "@/Components/Settings/Users/CreateEditRole/RoleForm/Permissions/PermissionFieldsMapping.model";
import { filtersFormFieldsNames } from "@/Components/Events/EventsFeed/EventsFilters.model";
import { allOptionSelected, specificOptionSelected } from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";

function usePrepareCreateEditRole(roleId, isDuplicate = false) {
    const { clientsManager } = useContext(ApiContext);
    const [roleData, setRoleData] = useState({ permissionsData: null, openRole: null });
    const [expandedPermissionsGroups, setExpandedPermissionsGroups] = useState({});

    useEffect(() => {
        const getPermissionsAndRolesData = async () => {
            const permissions = await clientsManager.rolesClient.listAllPermissions();
            let openRoleData = null;
            if (roleId) {
                openRoleData = await clientsManager.rolesClient.getRole(roleId, isDuplicate);
            }
            setExpandedPermissionsGroups(markFirstPermissionsGroupAsOpen(permissions));
            setRoleData({ permissionsData: permissions, openRole: openRoleData });
        };

        getPermissionsAndRolesData();
    }, [clientsManager.rolesClient, isDuplicate, roleId]);

    const permissionsUpdatedValues = useMemo(() => {
        if (!roleData.permissionsData || !roleData.openRole || roleData.permissionsData.error) {
            return {};
        }

        return Object.entries(roleData.permissionsData).reduce((acc, [, permissionsGroups]) => {
            Object.values(permissionsGroups).flat().forEach((key) => {
                acc[key] = roleData.openRole?.permission_names?.includes(key);
            });

            return acc;
        }, {});
    }, [roleData.permissionsData, roleData.openRole]);

    const updatedOpenRole = useMemo(() => {
        const roleName = roleData?.openRole?.role_name;
        const isRoleEditable = roleId && roleData?.openRole;
        const isEventFiltersExist = !_.isEmpty(roleData?.openRole?.role_data_filters?.event_filters);
        const mandatoryPermissions = roleId ? {} : Object.fromEntries(Object.keys(BuiltInPermissionsModel).map(key => [key, true]));
        let eventFilters = {
            ...filtersDefaultValue,
            ...isRoleEditable && isEventFiltersExist && roleFiltersParser(roleData?.openRole?.role_data_filters?.event_filters),
        };

        eventFilters = parseEventFiltersToSet(eventFilters);

        const roleDataObj = {
            ...permissionsUpdatedValues,
            ...mandatoryPermissions,
            event_filters: eventFilters,
        };

        if (roleName) {
            roleDataObj[CreateEditRoleFormFieldsNames.roleName] = roleName;
        }

        return roleDataObj;
    }, [permissionsUpdatedValues, roleData?.openRole, roleId]);

    return { updatedOpenRole, permissionsData: roleData.permissionsData, expandedPermissionsGroups };
}

export default usePrepareCreateEditRole;

export const parseFetchedEventsFilters = (eventsFilters) => {
    const parsedFilters = Object.keys(eventsFilters).reduce((acc, key) => {
        acc[key] = {
            radioValue: _.isEmpty(eventsFilters[key]) ? allOptionSelected : specificOptionSelected,
            selectedOptions: _.isEmpty(eventsFilters[key]) ? [] : eventsFilters[key]
        };
        return acc;
    }, {});

    return parsedFilters;
};

export const parseFetchedMatchesFilters = (matches, filtersFormFieldsNames) => {
    const hasMatches = Boolean(matches && matches.length > 0);

    const parsedFetchedMatches = matches?.reduce((acc, { match_outcome, watchlist_id }) => {
        const outcomeCategory = match_outcome === filtersFormFieldsNames.detectionsLowQualityFace
            ? filtersFormFieldsNames.detections
            : match_outcome;

        if (!acc[outcomeCategory]) {
            acc[outcomeCategory] = {
                isToggledOn: true,
                radioValue: allOptionSelected,
                selectedOptions: []
            };
        }

        if (watchlist_id) {
            acc[outcomeCategory].selectedOptions.push(watchlist_id);
            if (outcomeCategory !== filtersFormFieldsNames.detections) {
                acc[outcomeCategory].radioValue = specificOptionSelected;
            }
        }

        return acc;
    }, {});

    const parsedMatchedFilters = {
        [filtersFormFieldsNames.matched]: {
            isToggledOn: !hasMatches,
            radioValue: allOptionSelected,
            selectedOptions: []
        },
        [filtersFormFieldsNames.detections]: {
            selectedOptions: [],
            isToggledOn: !hasMatches,
        },
        [filtersFormFieldsNames.unauthorized]: {
            isToggledOn: !hasMatches,
            radioValue: allOptionSelected,
            selectedOptions: []
        },
        ...parsedFetchedMatches
    };

    return parsedMatchedFilters;
};

export const roleFiltersParser = ({ matches, mask_outcomes, ...restEventsFilters }) => {
    const parsedEventsFilters = parseFetchedEventsFilters(restEventsFilters);
    const parsedMatchesFilters = parseFetchedMatchesFilters(matches, filtersFormFieldsNames);

    return {
        mask_outcomes,
        ...parsedEventsFilters,
        ...parsedMatchesFilters
    };
};

export const markFirstPermissionsGroupAsOpen = (permissions) => {
    if (!permissions) {
        return {};
    }

    return _.mapValues(permissions, (keys) => {
        return _.fromPairs(
            _.keys(keys).map((key, index) => [key, index === 0])
        );
    });
};

export const parseEventFiltersToSet = (eventFilters) => { // todo redux: make redux work with "Set", this is the only purpose of this func
    const parsedFilters = { ...eventFilters };

    return Object.entries(parsedFilters).reduce((acc, [filterName, filterData]) => {
        if (filterData?.selectedOptions && Array.isArray(filterData?.selectedOptions)) {
            acc[filterName] = {
                ...filterData,
                selectedOptions: new Set(filterData.selectedOptions)
            };
        } else {
            acc[filterName] = filterData;
        }

        return acc;
    }, {});
};