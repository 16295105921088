import { matchOutcomes, notInWlOutcome } from "@/Logic/ApiClients/EventsClient/EventsClient.model";

export const filtersFormFieldsNames = {
    camerasFilter: "camera_ids",
    matched: matchOutcomes.matched,
    unauthorized: notInWlOutcome,
    detections: matchOutcomes.not_determined,
    detectionsLowQualityFace: matchOutcomes.low_quality_face,
    facesAttrAgeFilter: "age_outcomes",
    facesAttrGenderFilter: "gender_outcomes",
    facesAttrLivenessFilter: "liveness_outcomes",
    eventFilters: "event_filters"
};

export const EventsFiltersModel = {
    [filtersFormFieldsNames.eventFilters]: {
        name: filtersFormFieldsNames.eventFilters,
        type: "text",
        required: true
    },
    [filtersFormFieldsNames.camerasFilter]: {
        name: filtersFormFieldsNames.camerasFilter,
        getId: (camera) => camera.camera_id,
        getLabel: (camera) => camera.description,
        required: true
    },
    [filtersFormFieldsNames.matched]: {
        name: filtersFormFieldsNames.matched,
        getId: (watchlist) => watchlist.watchlist_id,
        getLabel: (watchlist) => watchlist.display_name,
        required: true
    },
    [filtersFormFieldsNames.detections]: {
        name: filtersFormFieldsNames.detections,
        required: true
    },
    [filtersFormFieldsNames.unauthorized]: {
        name: filtersFormFieldsNames.unauthorized,
        getId: (watchlist) => watchlist.watchlist_id,
        getLabel: (watchlist) => watchlist.display_name,
        required: true
    },
    [filtersFormFieldsNames.facesAttrAgeFilter]: {
        name: filtersFormFieldsNames.facesAttrAgeFilter,
        getId: (ageGroup) => ageGroup.value,
        getLabel: (ageGroup) => ageGroup.name,
        required: true
    },
    [filtersFormFieldsNames.facesAttrGenderFilter]: {
        name: filtersFormFieldsNames.facesAttrGenderFilter,
        getId: (genderAttributes) => genderAttributes.value,
        getLabel: (genderAttributes) => genderAttributes.name,
        required: true
    },
    [filtersFormFieldsNames.facesAttrLivenessFilter]: {
        name: filtersFormFieldsNames.facesAttrLivenessFilter,
        getId: (liveness) => liveness.value,
        getLabel: (liveness) => liveness.name,
        required: true
    },

    [filtersFormFieldsNames.eventFilters]: {
        name: filtersFormFieldsNames.eventFilters,
        required: true
    },
};