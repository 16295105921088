"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AuditTarget model module.
 * @module model/AuditTarget
 * @version 0.35.0
 */
class AuditTarget {
  /**
   * Constructs a new <code>AuditTarget</code>.
   * @alias module:model/AuditTarget
   * @param label {String} label of this action's target; i.e. if this action removed a POI, this field will contain the label \"POI\"
   */
  constructor(label) {
    AuditTarget.initialize(this, label);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, label) {
    obj['label'] = label;
  }

  /**
   * Constructs a <code>AuditTarget</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AuditTarget} obj Optional instance to populate.
   * @return {module:model/AuditTarget} The populated <code>AuditTarget</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AuditTarget();
      if (data.hasOwnProperty('label')) {
        obj['label'] = _ApiClient.default.convertToType(data['label'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AuditTarget</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AuditTarget</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of AuditTarget.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['label'] && !(typeof data['label'] === 'string' || data['label'] instanceof String)) {
      throw new Error("Expected the field `label` to be a primitive type in the JSON string but got " + data['label']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    return true;
  }
}
AuditTarget.RequiredProperties = ["label"];

/**
 * label of this action's target; i.e. if this action removed a POI, this field will contain the label \"POI\"
 * @member {String} label
 */
AuditTarget.prototype['label'] = undefined;

/**
 * id of this action's target; i.e. if this action removed a POI, this field will contain the poi_id of the removed POI. This may not always be present (may be -1) - for example when creation of a POI failed, there is no poi_id to provide.
 * @member {String} id
 */
AuditTarget.prototype['id'] = undefined;

/**
 * name of this action's target; i.e. if this action removed a POI, this field will contain the display_name of the removed POI. This may not always be present (may be -1).
 * @member {String} name
 */
AuditTarget.prototype['name'] = undefined;
var _default = exports.default = AuditTarget;