
import Button from '@mui/material/Button';

function OutlinedBtn({ children, className, ...props }) {
    return (
        <Button
            variant="outlined"
            className={`outlined-btn-txt inline-flex h-main-btn-height w-main-btn-width items-center justify-center gap-2.5 rounded border-oceanic-azure p-2.5 ${className}`}
            {...props}
        >
            {children}
        </Button>
    );
}

export default OutlinedBtn;