import SelectFilter from "../../../Common/Filters/SelectFilter";
import {allAppearancesFilterOption, retainedAppearances, showRetained} from "../../../../../appConfig/Strings";

const showRetainedOptions = {
	showRetained: {
		display_name: showRetained,
		id: null
	},
	showAll: {
		display_name: allAppearancesFilterOption,
		id: 1
	}
};

export default function RetentionFilter({data, ...props}) {
	const initialData = data.length ? data : [showRetainedOptions.showAll];
	return (
		<SelectFilter
			getOptionsFunc={() => Object.values(showRetainedOptions)}
			header={retainedAppearances}
			data={initialData}
			{...props}
		/>
	);
}
