import { useMemo } from "react";

import { connect } from "react-redux";

import LoiteringInsight from "./LoiteringInsight/LoiteringInsight";
import CrowdedAreaInsight from "./CrowdedAreaInsight/CrowdedAreaInsight";
import RepeatedIndividualInsight from "./RepeatedIndividualInsight/RepeatedIndividualInsight";
import { generativeInsightTypes } from "../CreateEditInsight.model";
import PoiProximityInsight from "./PoiProximityInsight/PoiProximityInsight";
import PersonJourneyInsight from "./PersonJourneyInsight/PersonJourneyInsight";
import { LoiteringInsightFormModel } from "./LoiteringInsight/LoiteringInsight.model";
import { CrowdedAreaInsightFormModel } from "./CrowdedAreaInsight/CrowdedAreaInsight.model";
import { RepeatedIndividualInsightFormModel } from "./RepeatedIndividualInsight/RepeatedIndividualInsight.model";
import { PoiProximityInsightFormModel } from "./PoiProximityInsight/PoiProximityInsight.model";
import { PersonJourneyInsightFormModel } from "./PersonJourneyInsight/PersonJourney.model";

export const InsightsTypesFormModel = {
    ...LoiteringInsightFormModel,
    ...CrowdedAreaInsightFormModel,
    ...RepeatedIndividualInsightFormModel,
    ...PoiProximityInsightFormModel,
    ...PersonJourneyInsightFormModel,
};

function InsightTypes({ insightId, insightType, formData, onFieldChange, watchlistsFromStore }) {
    const insightTypes = useMemo(() => ({
        [generativeInsightTypes.loitering]: () => <LoiteringInsight formData={formData} onFieldChange={onFieldChange} />,
        [generativeInsightTypes.crowded_area]: () => <CrowdedAreaInsight formData={formData} onFieldChange={onFieldChange} />,
        [generativeInsightTypes.repeated_individual]: () => <RepeatedIndividualInsight formData={formData} onFieldChange={onFieldChange} />,
        [generativeInsightTypes.poiProximity]: () => <PoiProximityInsight formData={formData} onFieldChange={onFieldChange} watchlistsFromStore={watchlistsFromStore} />,
        [generativeInsightTypes.personJourney]: () => <PersonJourneyInsight insightId={insightId} formData={formData} onFieldChange={onFieldChange} />,
    }), [formData, insightId, onFieldChange, watchlistsFromStore]);

    return insightTypes[insightType]();
}

const mapStateToProps = (state) => {
    const watchlistsFromStore = state.WatchlistsStore;

    return { watchlistsFromStore };
};

export default connect(mapStateToProps, null)(InsightTypes);