"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _UserContactInfo = _interopRequireDefault(require("./UserContactInfo"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UserParameters model module.
 * @module model/UserParameters
 * @version 0.35.0
 */
class UserParameters {
  /**
   * Constructs a new <code>UserParameters</code>.
   * @alias module:model/UserParameters
   * @param name {String} a unique user name used to log in
   * @param roleId {String} an assigned role for this user
   * @param password {String} 
   */
  constructor(name, roleId, password) {
    UserParameters.initialize(this, name, roleId, password);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, name, roleId, password) {
    obj['name'] = name;
    obj['role_id'] = roleId;
    obj['password'] = password;
  }

  /**
   * Constructs a <code>UserParameters</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserParameters} obj Optional instance to populate.
   * @return {module:model/UserParameters} The populated <code>UserParameters</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserParameters();
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('role_id')) {
        obj['role_id'] = _ApiClient.default.convertToType(data['role_id'], 'String');
      }
      if (data.hasOwnProperty('active')) {
        obj['active'] = _ApiClient.default.convertToType(data['active'], 'Boolean');
      }
      if (data.hasOwnProperty('password_reset_required')) {
        obj['password_reset_required'] = _ApiClient.default.convertToType(data['password_reset_required'], 'Boolean');
      }
      if (data.hasOwnProperty('user_contact_info')) {
        obj['user_contact_info'] = _UserContactInfo.default.constructFromObject(data['user_contact_info']);
      }
      if (data.hasOwnProperty('password')) {
        obj['password'] = _ApiClient.default.convertToType(data['password'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UserParameters</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserParameters</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of UserParameters.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['role_id'] && !(typeof data['role_id'] === 'string' || data['role_id'] instanceof String)) {
      throw new Error("Expected the field `role_id` to be a primitive type in the JSON string but got " + data['role_id']);
    }
    // validate the optional field `user_contact_info`
    if (data['user_contact_info']) {
      // data not null
      _UserContactInfo.default.validateJSON(data['user_contact_info']);
    }
    // ensure the json data is a string
    if (data['password'] && !(typeof data['password'] === 'string' || data['password'] instanceof String)) {
      throw new Error("Expected the field `password` to be a primitive type in the JSON string but got " + data['password']);
    }
    return true;
  }
}
UserParameters.RequiredProperties = ["name", "role_id", "password"];

/**
 * a unique user name used to log in
 * @member {String} name
 */
UserParameters.prototype['name'] = undefined;

/**
 * an assigned role for this user
 * @member {String} role_id
 */
UserParameters.prototype['role_id'] = undefined;

/**
 * whether or not user is disabled (allowed to login at all)
 * @member {Boolean} active
 * @default true
 */
UserParameters.prototype['active'] = true;

/**
 * should the user reset their password on their next login
 * @member {Boolean} password_reset_required
 * @default true
 */
UserParameters.prototype['password_reset_required'] = true;

/**
 * @member {module:model/UserContactInfo} user_contact_info
 */
UserParameters.prototype['user_contact_info'] = undefined;

/**
 * @member {String} password
 */
UserParameters.prototype['password'] = undefined;
var _default = exports.default = UserParameters;