import { forwardRef, useCallback } from "react";

import { Button } from "@mui/material";

import ChipElement from "@/Components/Common/Buttons/ChipElement";
import BaseSelectInputField from "./BaseSelectInputField";
import { selectAll } from "src/appConfig/Strings";

//todo style- move to style folder
export const baseWhiteStyle = {
    '& .MuiButtonBase-root': {
        color: 'white',
    },
    '& .MuiOutlinedInput-root': {
        borderColor: 'white',
        background: 'rgba(255, 255, 255, 0.1)',
        color: 'white',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiInputBase-input': {
            color: 'white',
            '&::placeholder': {
                color: 'white',
                opacity: 1,
            },
            '&.Mui-disabled': {
                opacity: 0.5,
            },
        },
    },
    '& .MuiInputLabel-root': {
        color: 'white',
        '&.Mui-focused': {
            color: 'white',
        },
        '&.MuiInputLabel-shrink': {
            color: 'white',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
    },
};

export const multiSelectWhiteStyle = {
    ...baseWhiteStyle,
    '& .MuiSelect-icon': {
        color: 'white',
        '&.Mui-disabled': {
            opacity: 0.5,
        },
    },
    '& .MuiMenuItem-root': {
        color: 'white',
    },
};

function MultiSelectInputField({ value = new Set(), onChange, onOpen, options, model, label, className, disabled, sx, ...props }) {
    const renderTags = useCallback((value, getTagProps, getOptionLabel) =>
        value.map((option, index) => (
            <ChipElement
                key={index}
                label={getOptionLabel(option)}
                {...getTagProps({ index })}
                className={className}
                classes={{
                    deleteIcon: className,
                }}
            />
        )), [className]);

    const renderListBox = (handleSelectAllClick) => forwardRef(function ListboxComponent(props, ref) {
        return (
            <div ref={ref} {...props}>
                <Button onClick={handleSelectAllClick} className="w-full">
                    {selectAll}
                </Button>
                {props.children}
            </div>
        );
    });

    const handleChange = useCallback((newValue) => {
        onChange && onChange(new Set(newValue));
    }, [onChange]);

    return (
        <BaseSelectInputField
            value={value ? Array.from(value) : []}
            onChange={handleChange}
            options={options}
            model={model}
            label={label}
            className={className}
            disabled={disabled}
            sx={sx}
            renderTags={renderTags}
            renderListBox={renderListBox}
            isMultiple={true}
            onOpen={onOpen}
            {...props}
        />
    );
}

export default MultiSelectInputField;