import InfoTooltip from "../Tooltips/InfoTooltip";

function LabelWrapper({ className, label, children, inline = false, tooltipTxt }) {
    const labelContainer = inline ? `label-input flex justify-between ${className} ` : `mt-3 ${className}`;
    const labelWrapper = `flex items-center mb-1.5  ${inline && ("mt-3 h-8 pt-2" + className)}`;

    return (
        <div className={labelContainer}>
            <label className={labelWrapper}>
                {label}
                {tooltipTxt && (
                    <InfoTooltip title={tooltipTxt} />
                )}
            </label>
            {children}
        </div>
    );
}

export default LabelWrapper;