import { memo, useCallback, useContext, useEffect, useRef, useState } from "react";

import ApiContext from "@/Logic/Context/apiContext";
import LoaderWithText from "../Loaders/LoaderWithText";
import MainDialog from "../Modals/MainDialog";
import { showConnectionCheckDialog } from "src/appConfig/customEvents";
import { checkingServerConnection, logout, lostServerCOnnection, terminateConnectionAttempt } from "src/appConfig/Strings";
import { toastUtility } from "@/Logic/Hooks/useToast";

const connectionCheckInterval = 5000;
const totalCheckFailTimeout = 60000;

function ConnectionCheckDialog() {
    const { clientsManager } = useContext(ApiContext);
    const [isConnectionEstablished, setIsConnectionEstablished] = useState(false);
    const [shouldCheckConnection, setShouldCheckConnection] = useState(false);
    const [shouldShowDialog, setShouldShowDialog] = useState(false);

    const repeatStatusCheckIntervalId = useRef(null);
    const abortConnectionTimeoutId = useRef(null);

    const cleanupConnectionCheck = useCallback(() => {
        clearTimeout(abortConnectionTimeoutId.current);
        clearInterval(repeatStatusCheckIntervalId.current);
        abortConnectionTimeoutId.current = null;
        repeatStatusCheckIntervalId.current = null;
        setIsConnectionEstablished(false);
        setShouldCheckConnection(false);
        setShouldShowDialog(false);
    }, []);

    const logoutAndCleanup = useCallback(() => {
        cleanupConnectionCheck();
        clientsManager.logoutUserAndCleanup();
    }, [clientsManager, cleanupConnectionCheck]);

    useEffect(() => {
        const startConnectionCheck = () => {
            if (shouldShowDialog) {
                return;
            }
            setShouldCheckConnection(true);
            setShouldShowDialog(true);
        };

        document.addEventListener(showConnectionCheckDialog, startConnectionCheck);
        return () => {
            document.removeEventListener(showConnectionCheckDialog, startConnectionCheck);
        };
    }, [shouldShowDialog]);

    useEffect(() => {
        if (!shouldCheckConnection) {
            return;
        }

        const checkConnection = async () => {
            const res = await clientsManager.controllerClient.getStatus();
            const isConnected = !res.error;
            if (isConnected) {
                cleanupConnectionCheck();
            }
        };

        abortConnectionTimeoutId.current = setTimeout(() => {
            toastUtility.showError(terminateConnectionAttempt);
            logoutAndCleanup();
        }, totalCheckFailTimeout);

        repeatStatusCheckIntervalId.current = setInterval(checkConnection, connectionCheckInterval);

        checkConnection();
    }, [clientsManager, shouldCheckConnection, cleanupConnectionCheck, logoutAndCleanup]);

    return (
        <MainDialog
            headerTitle={lostServerCOnnection}
            disableEscapeKeyDown={true}
            footerSubmitBtnText={logout}
            onSubmit={logoutAndCleanup}
            showCancelButton={false}
            showXButton={false}
            shouldShowDialog={shouldShowDialog}
        >
            {!isConnectionEstablished &&
                <LoaderWithText textClassName="outlined-btn-txt" text={checkingServerConnection} />
            }
        </MainDialog>
    );
}

export function dispatchShowConnectionCheckDialog() {
    const setConnectionCheckDetailsEvent = new CustomEvent(showConnectionCheckDialog);
    document.dispatchEvent(setConnectionCheckDetailsEvent);
}

export default memo(ConnectionCheckDialog);
