import { baseCamerasFieldNames, baseLiveCamerasModel, baseVideosModel } from "../../BaseModels";

export const crowdedAreaFormFieldsNames = {
    ...baseCamerasFieldNames,
    alertingUniqueIndividuals: "alerting_unique_individuals",
    alertingCrowdedDuration: "alerting_crowded_duration",
};

export const CrowdedAreaInsightFormModel = {
    [crowdedAreaFormFieldsNames.cameras]: {
        ...baseLiveCamerasModel
    },
    [crowdedAreaFormFieldsNames.videos]: {
        ...baseVideosModel
    },
    [crowdedAreaFormFieldsNames.alertingUniqueIndividuals]: {
        name: crowdedAreaFormFieldsNames.alertingUniqueIndividuals,
        type: "number",
        min: 0,
        required: true,
    },
    [crowdedAreaFormFieldsNames.alertingCrowdedDuration]: {
        name: crowdedAreaFormFieldsNames.alertingCrowdedDuration,
        type: "number",
        min: 0,
        required: true,
    },
};