import { IconButton, Tooltip } from "@mui/material";

import { tooltipWhiteStyle } from "../Styles/Tooltip.styles";

function TooltipIconButton({ iconElement, tooltipText, buttonAction, className, tooltipPlacement = "top" }) {
    return (
        <Tooltip
            title={tooltipText}
            placement={tooltipPlacement}
            slotProps={tooltipWhiteStyle}
        >
            <IconButton onClick={buttonAction} className={className}>
                {iconElement}
            </IconButton>
        </Tooltip>
    );
}

export default TooltipIconButton;