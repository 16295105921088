/* eslint-disable no-restricted-globals */
import { ApiClient, Appearance, EditSessionFiltersApi, EventFilters, ValidateSessionApi } from "smart_cameras_events_api";

import store from "src/appConfig/configureStore";
import { filtersFormFieldsNames } from "@/Components/Events/EventsFeed/EventsFilters.model";
import { eventTypes, matchOutcomes, notInWlOutcome, sessionEnded, updateFilters, validateSession } from "../EventsClient/EventsClient.model";
import { connectionFailedError, inconclusiveHeader, notDeterminedHeader, serverError, sseConnectionClosedWarning, sseConnectionStartSuccess, unableToReconnectSse } from "src/appConfig/Strings";
import { watchlistType } from "@/Components/POIs/Watchlists/CreateEditWatchlist/CreateWatchlist.model";
import BaseAPIClient, { resCodes } from "../BaseAPIClient";
import { showError, showSuccess, showWarning } from "@/Logic/Hooks/useToast";
import { eventsRoute } from "@/Logic/Infrastructure/networkConf";
import { specificOptionSelected } from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";

const { camerasFilter, matched, unauthorized, detections, facesAttrAgeFilter, facesAttrGenderFilter, facesAttrLivenessFilter, detectionsLowQualityFace } = filtersFormFieldsNames;

export const uncertaintyOptions = {
    not_determined: notDeterminedHeader,
    inconclusive: inconclusiveHeader
};

export const alertedMatchOutcomes = {
    [watchlistType.blacklist]: matchOutcomes.matched,
    [watchlistType.whitelist]: notInWlOutcome
};
export const severityAlertValue = 1;
export const toastOutcome = "toastOutcome";

export class EventsClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(eventsRoute, clientInstance);
        this.eventsApi = new ValidateSessionApi();
        this.filtersApi = new EditSessionFiltersApi();
        this.eventSource = null;
        this.listenerAddress = "";
        this.messageHandlers = [];
        this.eventsFilter = {};
        this.reconnectAttempts = 0;
        this.receivedError = false;
    }

    initialize(hostIp, token, roleDataFilters) {
        this.listenerAddress = `https://${hostIp}/${eventsRoute}/events/`;
        this.token = token;
        this.setHostIp(hostIp);
        this.setToken(token);
        this.setRoleDataFilters(roleDataFilters);
        this.startListener(true);
        for (const eventType in eventTypes) {
            this.addEventListener(eventType, this.#sentEventHandler(eventType));
        }
        this.addEventListener(validateSession, this.#sentEventHandler(validateSession));
        this.addEventListener(toastOutcome, this.#sentEventHandler(toastOutcome));
        this.addEventListener(updateFilters, this.#sentEventHandler(updateFilters));
        this.addEventListener(sessionEnded, this.#sentEventHandler(sessionEnded));
    }

    validationErrorCallback = (error, response, statusCode) => this.messageHandlers[validateSession](this.#parseResponse(error, response, statusCode));

    validationErrorCallbackMap = {
        [resCodes.badRequest]: (error, response) => this.validationErrorCallback(error, response, resCodes.badRequest),
        [resCodes.unauthorized]: (error, response) => this.validationErrorCallback(error, response, resCodes.unauthorized),
        [resCodes.PaymentRequired]: (error, response) => this.validationErrorCallback(error, response, resCodes.PaymentRequired),
        [resCodes.forbidden]: (error, response) => this.validationErrorCallback(error, response, resCodes.forbidden),
        [resCodes.noResponse]: (error, response) => this.validationErrorCallback(error, response, resCodes.noResponse),
        [resCodes.notFound]: (error, response) => this.validationErrorCallback(error, response, resCodes.notFound),
        [resCodes.unprocessable]: (error, response) => this.validationErrorCallback(error, response, resCodes.unprocessable),
        [resCodes.internalServerError]: (error, response) => this.validationErrorCallback(error, response, resCodes.internalServerError),
        [resCodes.badGateway]: (error, response) => this.validationErrorCallback(error, response, resCodes.badGateway),
        [resCodes.serviceUnavailable]: (error, response) => this.validationErrorCallback(error, response, resCodes.serviceUnavailable),
    };

    #sentEventHandler(eventType) {
        return (event) => {
            const encoder = new TextEncoder();
            const event_buffer = encoder.encode(event).buffer;
            self.postMessage([eventType, event_buffer]);
        };
    }

    #parseResponse(error, response, statusCode) {
        return JSON.stringify({
            statusCode: response?.status || statusCode,
            error,
            msg: [response?.body.metadata.msg]
        });
    }

    #parseResponseToast(toastType, message) {
        return JSON.stringify(
            { toastType: toastType, message: message }
        );
    }

    async updateSseFilters() {
        return await this.apiCall("update filters",
            (callback) => this.filtersApi.editSessionFilters({ eventFilters: this.eventsFilter }, callback),
            (error, _, response) => {
                this.messageHandlers[updateFilters](this.#parseResponse(error, response));
            },
            this.validationErrorCallbackMap
        );
    }

    async checkIfSessionCanStart() {
        this.apiCall("Check Get Event Session",
            (callback) => this.eventsApi.validateSession({ eventFilters: this.eventsFilter }, callback),
            async (error, _, response) => {
                if (error) {
                    this.messageHandlers[validateSession](this.#parseResponse(error, response));
                    return;
                }
                await this.updateSseFilters();
            },
            this.validationErrorCallbackMap
        );
    }

    startListener(isInitializing = false) {
        const params = new URLSearchParams({
            token: this.token,
            data: JSON.stringify(this.eventsFilter),
            gateway: 'true'
        });
        const url = `${this.listenerAddress}?${params}`;
        this.eventSource = new EventSource(url);
        this.eventSource.addEventListener("open", (res) => {
            this.resetListenerErrorFlags();
            if (res.eventPhase === res.AT_TARGET) {
                this.messageHandlers[toastOutcome](this.#parseResponseToast(showSuccess, sseConnectionStartSuccess));
            } else {
                this.messageHandlers[toastOutcome](this.#parseResponseToast(showError, `${connectionFailedError}, ${JSON.stringify(res)}`));
            }
        });
        this.eventSource.addEventListener("close", () => this.messageHandlers[toastOutcome](this.#parseResponseToast(showWarning, sseConnectionClosedWarning)));
        this.eventSource.addEventListener("error", () => this.handleSseError(isInitializing));
        for (const eventType in eventTypes) {
            this.eventSource.addEventListener(eventType, (msg) => {
                this.handleMessage(msg);
            });
        }
    }

    handleSseError(isInitializing) {
        const isFirstReconnectAttempt = this.reconnectAttempts === 0;
        const { isLoggedIn } = !store.getState().ApiActionsStore.login;
        if (!(isLoggedIn || isInitializing) || (this.receivedError && isFirstReconnectAttempt)) {
            //todo issue- for some reason the SSE receives 2 error signals when canceled by the BE, thats why the 2 flags
            // also we dont want to exit here on initialization before the redux isLoggedIn is set up 
            return;
        }
        this.receivedError = true;

        if (isFirstReconnectAttempt) {
            this.messageHandlers[toastOutcome](this.#parseResponseToast(showError, serverError));
            setTimeout(() => {
                this.reconnectAttempts++;
                this.resetSession(isInitializing);
            }, 5000);
        } else {
            this.messageHandlers[toastOutcome](this.#parseResponseToast(showError, unableToReconnectSse));
            this.messageHandlers[sessionEnded](JSON.stringify({}));
            this.stopListener();
        }
    }

    resetListenerErrorFlags() {
        this.reconnectAttempts = 0;
        this.receivedError = false;
    }

    stopListener() {
        this.resetListenerErrorFlags();
        this.eventSource?.close();
        this.eventSource = null;
        this.messageHandlers = [];
        this.abortSignalDispatcher = null;
    }

    resetSession(isInitializing) {
        this.eventSource.close();
        this.startListener(isInitializing);
    }

    handleMessage = (event) => {
        if (event.type === "ping")
            return;
        this.messageHandlers[event.type](event.data);
    };

    addEventListener(eventType, messageHandler) {
        this.messageHandlers[eventType] = messageHandler;
        return () => {
            delete this.messageHandlers[eventType];
        };
    }

    setRoleDataFilters(roleDataFilters) {
        const appearancesFilter = new Appearance();
        appearancesFilter.camera_ids = roleDataFilters.camera_ids;
        appearancesFilter.matches = roleDataFilters.matches;

        this.eventsFilter = new EventFilters();
        this.eventsFilter.appearance = appearancesFilter;
        this.eventsFilter.camera_ids = roleDataFilters.camera_ids;

        this.#setFaceAttrFilters(roleDataFilters);
    }

    #setFaceAttrFilters(faceAttrFiltersFilters) {
        this.eventsFilter.appearance.age_outcomes = faceAttrFiltersFilters.age_outcomes;
        this.eventsFilter.appearance.gender_outcomes = faceAttrFiltersFilters.gender_outcomes;
        this.eventsFilter.appearance.liveness_outcomes = faceAttrFiltersFilters.liveness_outcomes;
        this.eventsFilter.appearance.mask_outcomes = faceAttrFiltersFilters.mask_outcomes;
    }

    setSseFilters(submittedFormData, roleDataFiltersStore, cameraId) {
        const appearancesFilter = new Appearance();
        appearancesFilter.camera_ids = getCamerasFilterData(cameraId, submittedFormData[camerasFilter]);

        const { matchedWatchlists, unauthorizedWatchlists, detectionsWatchlists } = matchesFilterParser(submittedFormData);
        appearancesFilter.matches = [
            ...matchedWatchlists,
            ...unauthorizedWatchlists,
            ...detectionsWatchlists
        ];

        this.eventsFilter = new EventFilters();
        this.eventsFilter.appearance = appearancesFilter;
        this.#setFaceAttrFilters({
            age_outcomes: getFaceAttrFilters(submittedFormData[facesAttrAgeFilter]),
            gender_outcomes: getFaceAttrFilters(submittedFormData[facesAttrGenderFilter]),
            liveness_outcomes: getFaceAttrFilters(submittedFormData[facesAttrLivenessFilter]),
            mask_outcomes: roleDataFiltersStore.mask_outcomes
        });

        this.checkIfSessionCanStart();
    }
}

const processWatchlists = (eventFilters, filterKey) => {
    if (!eventFilters[filterKey]?.isToggledOn) {
        return [];
    }

    const selectedOptions = eventFilters[filterKey].selectedOptions;
    let watchlistsData;

    if (eventFilters[filterKey].radioValue === specificOptionSelected) {
        watchlistsData = Array.from(selectedOptions).map(watchlistId => ({
            match_outcome: matchOutcomes[filterKey],
            watchlist_id: watchlistId
        }));
    } else {
        watchlistsData = [{ match_outcome: matchOutcomes[filterKey] }];
    }

    return watchlistsData;
};

export const matchesFilterParser = (matchedEventFilters) => {
    const matchedWatchlists = processWatchlists(matchedEventFilters, matched);
    const unauthorizedWatchlists = processWatchlists(matchedEventFilters, unauthorized);
    let detectionsWatchlists = [];

    if (matchedEventFilters[detections]?.isToggledOn) {
        detectionsWatchlists = [
            { match_outcome: matchOutcomes[detections] },
            { match_outcome: matchOutcomes[detectionsLowQualityFace] }
        ];
    }

    return {
        matchedWatchlists,
        unauthorizedWatchlists,
        detectionsWatchlists
    };
};

export const getCamerasFilterData = (cameraId, camerasFormData) => {
    let camerasFilterData = [];

    if (cameraId) {
        camerasFilterData = [cameraId];
    } else if (camerasFormData?.radioValue === specificOptionSelected) {
        camerasFilterData = Array.from(camerasFormData.selectedOptions);
    }

    return camerasFilterData;
};

export const getFaceAttrFilters = (faceAttrFormData) => {
    return faceAttrFormData?.radioValue === specificOptionSelected ? Array.from(faceAttrFormData.selectedOptions) : [];
};