import { useCallback, useEffect, useRef, useState } from "react";


import { videoConstraints } from "@/Components/POIs/CreateEditPOI/CreateEditPOI.model";
import { formatImageToDataURL } from "@/Logic/Parsing/parseImages";

const selectedBBoxColor = "green";
const unselectedBBoxColor = "blue";

function DetectFaceCanvas({ image, setSelectedFace, detectedFacesResponse }) {
    const [detectedFaces, setDetectedFaces] = useState([]);
    const [imageSizing, setImageSizing] = useState({ width: 0, height: 0 });
    const canvasRef = useRef(null);

    const drawBBox = useCallback((context) => {
        detectedFaces.forEach((detection) => {
            if (detection.mouse.clicked || detectedFaces.length === 1) {
                context.strokeStyle = selectedBBoxColor;
            } else {
                context.strokeStyle = unselectedBBoxColor;
            }
            context.lineWidth = 3;
            context.strokeRect(detection.bbox.x1, detection.bbox.y1, detection.bbox.width, detection.bbox.height);
        });
    }, [detectedFaces]);

    const drawImage = useCallback((context) => {
        if (typeof context !== typeof Object()) {
            return;
        }

        const imageForCanvas = new Image();

        imageForCanvas.src = formatImageToDataURL(image);
        imageForCanvas.onload = () => {
            const canvas = canvasRef.current;
            if (!canvas) {
                return;
            }

            const maxWidth = videoConstraints.width;
            const maxHeight = videoConstraints.canvasMaxHeight;

            // Calculate aspect ratio based on the original image dimensions
            const aspectRatio = imageForCanvas.width / imageForCanvas.height;

            // Determine canvas dimensions based on the aspect ratio and max constraints
            let canvasWidth = Math.min(maxWidth, imageForCanvas.width);
            let canvasHeight = canvasWidth / aspectRatio;

            // Ensure the canvas height does not exceed the maximum height
            if (canvasHeight > maxHeight) {
                canvasHeight = maxHeight;
                canvasWidth = canvasHeight * aspectRatio;
            }

            // Set the canvas dimensions
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            // Clear the canvas and draw the image
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            context.drawImage(imageForCanvas, 0, 0, canvasWidth, canvasHeight);

            // Update image sizing state
            setImageSizing({ width: imageForCanvas.width, height: imageForCanvas.height });

            // Draw bounding boxes
            drawBBox(context);
        };
    }, [drawBBox, image]);

    const absoluteBBox = useCallback((bbox) => {
        const canvas = canvasRef.current;
        const absoluteBBox = bbox;
        const widthRatio = (imageSizing.width / canvas.width);
        const heightRatio = (imageSizing.height / canvas.height);

        absoluteBBox.x1 /= widthRatio;
        absoluteBBox.x2 /= widthRatio;
        absoluteBBox.y1 /= heightRatio;
        absoluteBBox.y2 /= heightRatio;
        absoluteBBox.width = absoluteBBox.x2 - absoluteBBox.x1;
        absoluteBBox.height = absoluteBBox.y2 - absoluteBBox.y1;

        return absoluteBBox;
    }, [imageSizing.height, imageSizing.width]);

    const checkMouseInBBox = useCallback((event, eventType) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const mouseX = (event.clientX - rect.left) * (canvas.width / rect.width);
        const mouseY = (event.clientY - rect.top) * (canvas.height / rect.height);

        const detectionWithMouseEvent = detectedFaces.reduce((detections, detection) => {
            const { bbox } = detection;

            const isInsideX = bbox.x1 <= mouseX && mouseX <= bbox.x2;
            const isInsideY = bbox.y1 <= mouseY && mouseY <= bbox.y2;

            detection.mouse[eventType] = isInsideX && isInsideY;
            detections.push(detection);

            return detections;
        }, []);

        if (eventType === "clicked") {
            const clickedDetections = detectionWithMouseEvent.filter((detection) => detection.mouse.clicked);
            if (clickedDetections.length > 1) {
                const topmostDetection = clickedDetections.reduce((topmost, detection) => {
                    return (detection.bbox.y1 < topmost.bbox.y1) ? detection : topmost;
                });
                clickedDetections.forEach((detection) => {
                    detection.mouse.clicked = detection === topmostDetection;
                });
            }
        }
        setDetectedFaces(detectionWithMouseEvent);
    }, [detectedFaces]);

    const onMouseClick = useCallback((event) => {
        if (detectedFacesResponse.length === 1) {
            return;
        }
        event.preventDefault();
        checkMouseInBBox(event, "clicked");
        const selectedDetection = detectedFaces.find((detection) => detection.mouse.clicked);
        setSelectedFace(selectedDetection);
    }, [detectedFaces, checkMouseInBBox, detectedFacesResponse.length, setSelectedFace]);

    const render = useCallback((context) => {
        drawImage(context);
        return window.requestAnimationFrame(render);
    }, [drawImage]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        let animatedFrameId;
        if (context) {
            animatedFrameId = render(context);
        }

        return () => window.cancelAnimationFrame(animatedFrameId);
    }, [render]);

    useEffect(() => {
        const parsedDetections = detectedFacesResponse.map((detection) => ({
            bbox: absoluteBBox(detection.bbox),
            mouse: {
                clicked: false
            },
            ...detection
        }));
        if (parsedDetections.length === 1) {
            setSelectedFace(parsedDetections[0]);
        }
        setDetectedFaces(parsedDetections);
    }, [absoluteBBox, detectedFacesResponse]);

    useEffect(() => {
        setSelectedFace(undefined);
        setDetectedFaces([]);
    }, [image]);

    return (
        <div className="flex max-h-[33rem] flex-col items-center justify-center">
            <canvas ref={canvasRef} onClick={onMouseClick} />
        </div>
    );
}

export default DetectFaceCanvas;