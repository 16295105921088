"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Frames Storage API
 * Frames Storage API gives access to the frames of recorded appearances from cameras analyzed by the application.  It allows to read and remove frames that associated with an appearance record.
 *
 * The version of the OpenAPI document: 0.34.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The BoundingBox model module.
 * @module model/BoundingBox
 * @version 0.34.0
 */
class BoundingBox {
  /**
   * Constructs a new <code>BoundingBox</code>.
   * @alias module:model/BoundingBox
   * @param x1 {Number} 
   * @param y1 {Number} 
   * @param x2 {Number} 
   * @param y2 {Number} 
   */
  constructor(x1, y1, x2, y2) {
    BoundingBox.initialize(this, x1, y1, x2, y2);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, x1, y1, x2, y2) {
    obj['x1'] = x1;
    obj['y1'] = y1;
    obj['x2'] = x2;
    obj['y2'] = y2;
  }

  /**
   * Constructs a <code>BoundingBox</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BoundingBox} obj Optional instance to populate.
   * @return {module:model/BoundingBox} The populated <code>BoundingBox</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BoundingBox();
      if (data.hasOwnProperty('x1')) {
        obj['x1'] = _ApiClient.default.convertToType(data['x1'], 'Number');
      }
      if (data.hasOwnProperty('y1')) {
        obj['y1'] = _ApiClient.default.convertToType(data['y1'], 'Number');
      }
      if (data.hasOwnProperty('x2')) {
        obj['x2'] = _ApiClient.default.convertToType(data['x2'], 'Number');
      }
      if (data.hasOwnProperty('y2')) {
        obj['y2'] = _ApiClient.default.convertToType(data['y2'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>BoundingBox</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>BoundingBox</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of BoundingBox.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
BoundingBox.RequiredProperties = ["x1", "y1", "x2", "y2"];

/**
 * @member {Number} x1
 */
BoundingBox.prototype['x1'] = undefined;

/**
 * @member {Number} y1
 */
BoundingBox.prototype['y1'] = undefined;

/**
 * @member {Number} x2
 */
BoundingBox.prototype['x2'] = undefined;

/**
 * @member {Number} y2
 */
BoundingBox.prototype['y2'] = undefined;
var _default = exports.default = BoundingBox;