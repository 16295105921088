"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Token model module.
 * @module model/Token
 * @version 0.35.0
 */
class Token {
  /**
   * Constructs a new <code>Token</code>.
   * @alias module:model/Token
   * @param tokenId {String} 
   * @param lastActionTimeUtc {Date} 
   * @param sessionTime {Number} 
   */
  constructor(tokenId, lastActionTimeUtc, sessionTime) {
    Token.initialize(this, tokenId, lastActionTimeUtc, sessionTime);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, tokenId, lastActionTimeUtc, sessionTime) {
    obj['token_id'] = tokenId;
    obj['last_action_time_utc'] = lastActionTimeUtc;
    obj['session_time'] = sessionTime;
  }

  /**
   * Constructs a <code>Token</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Token} obj Optional instance to populate.
   * @return {module:model/Token} The populated <code>Token</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Token();
      if (data.hasOwnProperty('token_id')) {
        obj['token_id'] = _ApiClient.default.convertToType(data['token_id'], 'String');
      }
      if (data.hasOwnProperty('last_action_time_utc')) {
        obj['last_action_time_utc'] = _ApiClient.default.convertToType(data['last_action_time_utc'], 'Date');
      }
      if (data.hasOwnProperty('session_time')) {
        obj['session_time'] = _ApiClient.default.convertToType(data['session_time'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Token</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Token</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Token.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['token_id'] && !(typeof data['token_id'] === 'string' || data['token_id'] instanceof String)) {
      throw new Error("Expected the field `token_id` to be a primitive type in the JSON string but got " + data['token_id']);
    }
    return true;
  }
}
Token.RequiredProperties = ["token_id", "last_action_time_utc", "session_time"];

/**
 * @member {String} token_id
 */
Token.prototype['token_id'] = undefined;

/**
 * @member {Date} last_action_time_utc
 */
Token.prototype['last_action_time_utc'] = undefined;

/**
 * @member {Number} session_time
 */
Token.prototype['session_time'] = undefined;
var _default = exports.default = Token;