import { useCallback, useMemo, useState } from "react";

import { isEmpty, capitalize } from "lodash";
import Collapse from "@mui/material/Collapse";
import { Paper } from "@mui/material";

import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import BadgeWrapper from "@/Components/Common/Wrappers/BadgeWrapper";
import POIFaceContextMenu from "./POIFaceContextMenu";
import { lowQuality, viewPoiFacesLabel } from "src/appConfig/Strings";
import BaseGrid from "@/Components/Common/MUIGrids/BaseGrid/BaseGrid";
import GridItem from "@/Components/Common/MUIGrids/BaseGrid/GridItem";
import POICard from "../../POIData/POI/POICard";
import { poiCardCenterBottomBadge } from "@/Components/Common/Styles/BadgeSx";

function PoiFacesList({ poiFaces, handleRemovePoiFace }) {
    const [showPoiFacesDrawer, setShowPoiFacesDrawer] = useState(true);

    const toggleShowFaces = useCallback(() => {
        if (isEmpty(poiFaces)) {
            return;
        }

        setShowPoiFacesDrawer((show) => !show);
    }, [poiFaces]);

    const facesList = useMemo(() => Object.values(poiFaces).map((faceData, index) => {
        const faceGender = faceData.face_attributes?.gender || faceData.face_attributes?.gender_outcome;
        const faceAgeGroup = faceData.face_attributes?.age_group || faceData.face_attributes?.age_group_outcome;
        const ageGenderLabel = `${faceGender ? (capitalize(faceGender) + ",") : ""} ${faceAgeGroup ? faceAgeGroup : ""}`;
        const poiCard = (
            <Paper elevation={5}>
                <POICard
                    poiImg={faceData.img}
                    poiFooterText={ageGenderLabel}
                    poiContextMenu={<POIFaceContextMenu poiFace={faceData} removeCallback={handleRemovePoiFace} />}
                    footerClassName="text-base"
                    defaultFaceCropClassName="!bg-transparent !fill-slate-blue"
                />
            </Paper>
        );

        return (
            <GridItem key={index} className="pb-2">
                {(faceData.isValidFace === false || faceData.face_attributes?.forced === 1) ? (
                    <BadgeWrapper badgeContent={lowQuality} color="error" sx={poiCardCenterBottomBadge}>
                        {poiCard}
                    </BadgeWrapper>
                ) : poiCard}
            </GridItem>
        );
    }), [handleRemovePoiFace, poiFaces]);

    return (
        <div className="flex w-full flex-col">
            <section className="ml-1 text-base">
                <button type="button" data-testid="show-faces-btn" onClick={toggleShowFaces}>
                    {viewPoiFacesLabel}
                    {showPoiFacesDrawer ? <ExpandLess /> : <ExpandMore />}
                </button>
            </section>
            <Collapse in={showPoiFacesDrawer}>
                {!isEmpty(poiFaces) && (
                    <BaseGrid gridSpacing={1.5} renderedItems={facesList} gridClassName="h-[14.5rem] p-1" />
                )}
            </Collapse>
        </div>
    );
}

export default PoiFacesList;