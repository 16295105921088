import store from "src/appConfig/configureStore";
import OverflowingText from "../../Tooltips/OverflowingText";
import { nodeId as nodeIdStr } from "src/appConfig/Strings";

function CameraInfoColumn({ displayName, notesData, nodeId }) {
    const { isMultiServerMode } = store.getState().ApiActionsStore;

    return (
        <span className="inline w-full overflow-hidden">
            <div className="flex flex-col items-center justify-center gap-1 text-center">
                <OverflowingText className="w-full font-bold text-white" text={displayName} />
                {notesData && (
                    <span className="notes-text">{notesData}</span>
                )}
                {isMultiServerMode && (
                    <span className="notes-text">{nodeIdStr}: {nodeId}</span>
                )}
            </div>
        </span>
    );
}

export function processCameraInfoData(cameraData) {
    if (!cameraData) {
        return {};
    }

    const { camera_description, camera_notes, node_id } = cameraData;
    const processedData = {
        displayName: camera_description,
        notesData: camera_notes?.free_notes || "",
        nodeId: node_id
    };

    return processedData;
}

export default CameraInfoColumn;