import { ActionColumnsDefinitions, ColumnsDefinitions } from "@/Components/Common/MUITables/Columns/ColumnsDefinitions";
import { actions, cameras, camerasList, progress, source, status } from "src/appConfig/Strings";

export const cameraNodeId = "cameraNodeId";

const CamerasListModel = (rows, isMultiServerMode, isLiveAnalysisMode) => {
    const camerasListColumns = [
        ColumnsDefinitions[status],
        ColumnsDefinitions[cameras],
        ColumnsDefinitions[source],
    ];
    if (isMultiServerMode) {
        camerasListColumns.push(ColumnsDefinitions[cameraNodeId]);
    }
    if (!isLiveAnalysisMode) {
        camerasListColumns.push(ColumnsDefinitions[progress]);
    }
    camerasListColumns.push(ActionColumnsDefinitions[camerasList]);

    return {
        columns: camerasListColumns,
        rows: rows.length > 0 ? rows.map(row => ({
            id: row.camera_id,
            [cameras]: row.description,
            [status]: row.camera_status.status,
            [actions]: row,
            camera_data: row,
            ...row,
        })) : [],
        rowHeight: 50
    };
};

export { CamerasListModel };