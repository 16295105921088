export const cameraSnapshotInterface = {
    selectUsbDevice: "selectUsbDevice",
    imgData: "imgData"
};

export const CameraSnapshotModel = {
    selectUsbDevice: {
        name: "selectUsbDevice",
        textField: "label",
        dataKey: "deviceId",
    },
};