import { useEffect, useState } from "react";

import store from "src/appConfig/configureStore";
import { apply, reviewAppearance } from "src/appConfig/Strings";
import { labelAppearanceDialog } from "@/Logic/Hooks/useDialog/useDialog.model";
import { setDialogData } from "src/appConfig/customEvents";
import { updateEvent } from "@/Logic/Redux/Stores/EventsStore";
import MainDialog from "../../MainDialog";
import LabelAppearanceForm from "./LabelAppearanceForm";
import { alertsTable, eventTable } from "@/Components/Common/MUITables/TablesLogic";

export function createLabelObject(labeledOutcome, freeNotes) {
    const labelUpdateData = {
        ...(labeledOutcome ? { labeled_outcome: labeledOutcome } : {}),
        ...(freeNotes ? { appearance_notes: { free_notes: freeNotes } } : {})
    };
    return labelUpdateData;
}

function updateEventCallback(appearanceId, labeledOutcome, freeNotes) {
    store.dispatch(updateEvent({ appearanceId: appearanceId, updateData: createLabelObject(labeledOutcome, freeNotes) }));
}

export const labelHandleMapping = {
    [eventTable]: updateEventCallback,
    [alertsTable]: updateEventCallback
};

function AppearanceLabelDialog() {
    const [dialogDetails, setAppearanceDetails] = useState({});
    useEffect(() => {
        const labelAppearanceDetailsFunc = (event) => {
            setAppearanceDetails(event.detail);
        };

        document.addEventListener(setDialogData, labelAppearanceDetailsFunc);
        return () => {
            document.removeEventListener(setDialogData, labelAppearanceDetailsFunc);
        };
    }, [setAppearanceDetails]);

    return (
        <MainDialog formID={labelAppearanceDialog} dialogID={labelAppearanceDialog} headerTitle={reviewAppearance} footerSubmitBtnText={apply} shouldNavigateOnCancel={false}>
            <div className="flex size-full flex-col items-center justify-center gap-4 text-center">
                <LabelAppearanceForm dialogDetails={dialogDetails} />
            </div>
        </MainDialog>
    );
}

export function dispatchLabelAppearanceDetailsEvent(appearance, tableType) {
    const setRetainAppearanceDetailsEvent = new CustomEvent(setDialogData, { detail: { tableType: tableType, appearance: appearance } });
    document.dispatchEvent(setRetainAppearanceDetailsEvent);
}

export default AppearanceLabelDialog;