import { useCallback, useContext, useEffect, useMemo, useState } from "react";


import store from "src/appConfig/configureStore";
import { appearanceRemoveMethod, remove, removeFromPlacement, removeFromSystem, successfullyRemovedEvent, removeAppearance as removeAppearanceText, retainedRemoveWarn, removeAlertWarn, removeEventWarn, wontRemoveAlertWarn, wontRemoveEventWarn, cantRemoveLiveAppearanceWarn, noteLabel } from "src/appConfig/Strings";
import { removeAppearanceDialog } from "@/Logic/Hooks/useDialog/useDialog.model";
import ApiContext from "@/Logic/Context/apiContext";
import { toastUtility } from "@/Logic/Hooks/useToast";
import { removeLocalAppearance, setDialogData } from "src/appConfig/customEvents";
import MainDialog from "../MainDialog";
import RadioInput from "../../FormComponents/FormInputs/RadioInput";
import { removeAlert, removeEvent } from "@/Logic/Redux/Stores/EventsStore";
import { alertsTable, eventTable, historyAppearanceTable, historyImgAppearanceTable, historyInsightsTable, insightAppearanceTable } from "../../MUITables/TablesLogic";
import AudioManagerContext from "@/Logic/Context/audioManagerContext";

const removeLocallyVal = "removeLocallyVal";
const removeFromSystemVal = "removeFromSystemVal";

const removeTypeMapping = {
    [eventTable]: (removedAppearanceId, shouldRemoveHistory) => store.dispatch(removeEvent({ removedAppearanceId, shouldRemoveHistory })),
    [alertsTable]: (removedAppearanceId, shouldRemoveHistory, audioManager) => {
        store.dispatch(removeAlert({ removedAppearanceId, shouldRemoveHistory }));
        const { isRepeatedSoundExist } = store.getState().EventsStore;
        if (!isRepeatedSoundExist) {
            audioManager.stop();
        }
    },
};
const noRemoveWarnTables = new Set([historyAppearanceTable, historyImgAppearanceTable, historyInsightsTable, insightAppearanceTable]);

function AppearanceRemoveDialog() {
    const { clientsManager } = useContext(ApiContext);
    const { audioManager } = useContext(AudioManagerContext);
    const [selectedOption, setSelectedOption] = useState(removeLocallyVal);
    const [appearanceDetails, setAppearanceDetails] = useState({});

    const radioOptions = useMemo(() => [{
        label: removeFromPlacement,
        option: removeLocallyVal
    }, {
        label: removeFromSystem,
        option: removeFromSystemVal,
        isOptionDisabled: !appearanceDetails?.removeFromSystemAllowed
    }], [appearanceDetails?.removeFromSystemAllowed]);

    const radioChangeHandler = useCallback((event) => {
        setSelectedOption(event.target.value);
    }, []);

    const removedAppearanceDetailsFunc = useCallback((event) => {
        setSelectedOption(removeLocallyVal);
        setAppearanceDetails(event.detail);
    }, []);

    useEffect(() => {
        document.addEventListener(setDialogData, removedAppearanceDetailsFunc);
        return () => {
            document.removeEventListener(setDialogData, removedAppearanceDetailsFunc);
        };
    }, [removedAppearanceDetailsFunc]);

    const removeLocalAppearanceHandler = useCallback(() => {
        const localRemoveFunc = removeTypeMapping[appearanceDetails.tableType];
        if (localRemoveFunc) {
            const shouldRemoveHistory = selectedOption === removeFromSystemVal;
            localRemoveFunc(appearanceDetails.removedAppearanceId, shouldRemoveHistory, audioManager);
        } else {
            document.dispatchEvent(new CustomEvent(removeLocalAppearance, { detail: { removedAppearanceId: appearanceDetails.removedAppearanceId, tableType: appearanceDetails.tableType } }));
        }
    }, [appearanceDetails.removedAppearanceId, appearanceDetails.tableType, audioManager, selectedOption]);

    async function removeAppearance() {
        let response = null;
        removeLocalAppearanceHandler();
        if (selectedOption === removeFromSystemVal) {
            response = await clientsManager.historyClient.removeAppearances({ appearanceId: appearanceDetails.removedAppearanceId, removeIncludingRetained: true });
            if (!(response && response.error)) {
                toastUtility.showSuccess(successfullyRemovedEvent);
            }
        }
    }

    const noteMsg = useMemo(() => {
        let noteMsg = "";
        if (noRemoveWarnTables.has(appearanceDetails.tableType)) {
            return noteMsg;
        }

        const isEventsTable = appearanceDetails.tableType === eventTable;
        if (selectedOption === removeFromSystemVal) {
            noteMsg = isEventsTable ? removeAlertWarn : removeEventWarn;
        } else {
            const warnMsg = isEventsTable ? wontRemoveAlertWarn : wontRemoveEventWarn;
            if (appearanceDetails?.removeFromSystemAllowed) {
                noteMsg = `${noteLabel}: ${warnMsg}`;
            } else {
                noteMsg = `${cantRemoveLiveAppearanceWarn} \n${warnMsg}`;
            }
        }

        return noteMsg;
    }, [appearanceDetails?.removeFromSystemAllowed, appearanceDetails.tableType, selectedOption]);

    return (
        <MainDialog dialogID={removeAppearanceDialog} headerTitle={removeAppearanceText} footerSubmitBtnText={remove} onSubmit={removeAppearance}>
            <div className="flex flex-col gap-2">
                <span className="items-center justify-center text-center">
                    {appearanceRemoveMethod}
                </span>
                <RadioInput options={radioOptions} value={selectedOption} onChange={radioChangeHandler} />
                {noteMsg && <span className="mt-4 whitespace-pre-line">{noteMsg}</span>}
                {appearanceDetails.isRetained && selectedOption === removeFromSystemVal && <span className="mt-2">{retainedRemoveWarn}</span>}
            </div>
        </MainDialog>
    );
}

export default AppearanceRemoveDialog;

export function dispatchSetRemovedAppearanceDetailsEvent(removedAppearanceId, tableType, isRetained, removeFromSystemAllowed = true) {
    const setRemovedAppearanceDetailsEvent = new CustomEvent(setDialogData, { detail: { removedAppearanceId, tableType, isRetained, removeFromSystemAllowed } });
    document.dispatchEvent(setRemovedAppearanceDetailsEvent);
}