"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _ContactInfo = _interopRequireDefault(require("./ContactInfo"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UserContactInfo model module.
 * @module model/UserContactInfo
 * @version 0.35.0
 */
class UserContactInfo {
  /**
   * Constructs a new <code>UserContactInfo</code>.
   * The user contact info
   * @alias module:model/UserContactInfo
   * @implements module:model/ContactInfo
   */
  constructor() {
    _ContactInfo.default.initialize(this);
    UserContactInfo.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>UserContactInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserContactInfo} obj Optional instance to populate.
   * @return {module:model/UserContactInfo} The populated <code>UserContactInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserContactInfo();
      _ContactInfo.default.constructFromObject(data, obj);
      if (data.hasOwnProperty('full_name')) {
        obj['full_name'] = _ApiClient.default.convertToType(data['full_name'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('phone')) {
        obj['phone'] = _ApiClient.default.convertToType(data['phone'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UserContactInfo</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserContactInfo</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['full_name'] && !(typeof data['full_name'] === 'string' || data['full_name'] instanceof String)) {
      throw new Error("Expected the field `full_name` to be a primitive type in the JSON string but got " + data['full_name']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['phone'] && !(typeof data['phone'] === 'string' || data['phone'] instanceof String)) {
      throw new Error("Expected the field `phone` to be a primitive type in the JSON string but got " + data['phone']);
    }
    return true;
  }
}

/**
 * The full name of the user
 * @member {String} full_name
 */
UserContactInfo.prototype['full_name'] = undefined;

/**
 * Email of the user
 * @member {String} email
 */
UserContactInfo.prototype['email'] = undefined;

/**
 * Phone number of the user
 * @member {String} phone
 */
UserContactInfo.prototype['phone'] = undefined;

// Implement ContactInfo interface:
/**
 * The full name of the user
 * @member {String} full_name
 */
_ContactInfo.default.prototype['full_name'] = undefined;
/**
 * Email of the user
 * @member {String} email
 */
_ContactInfo.default.prototype['email'] = undefined;
/**
 * Phone number of the user
 * @member {String} phone
 */
_ContactInfo.default.prototype['phone'] = undefined;
var _default = exports.default = UserContactInfo;