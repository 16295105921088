"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ValidationError model module.
 * @module model/ValidationError
 * @version 0.37.0
 */
class ValidationError {
  /**
   * Constructs a new <code>ValidationError</code>.
   * @alias module:model/ValidationError
   * @param loc {Object} 
   * @param msg {Object} 
   * @param type {Object} 
   */
  constructor(loc, msg, type) {
    ValidationError.initialize(this, loc, msg, type);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, loc, msg, type) {
    obj['loc'] = loc;
    obj['msg'] = msg;
    obj['type'] = type;
  }

  /**
   * Constructs a <code>ValidationError</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ValidationError} obj Optional instance to populate.
   * @return {module:model/ValidationError} The populated <code>ValidationError</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ValidationError();
      if (data.hasOwnProperty('loc')) {
        obj['loc'] = _ApiClient.default.convertToType(data['loc'], Object);
      }
      if (data.hasOwnProperty('msg')) {
        obj['msg'] = _ApiClient.default.convertToType(data['msg'], Object);
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = _ApiClient.default.convertToType(data['type'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ValidationError</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ValidationError</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ValidationError.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
ValidationError.RequiredProperties = ["loc", "msg", "type"];

/**
 * @member {Object} loc
 */
ValidationError.prototype['loc'] = undefined;

/**
 * @member {Object} msg
 */
ValidationError.prototype['msg'] = undefined;

/**
 * @member {Object} type
 */
ValidationError.prototype['type'] = undefined;
var _default = exports.default = ValidationError;