//general
export const clear = "Clear";
export const proceed = "Proceed";
export const close = "Close";
export const confirmation = "Are you sure you want to";
export const imgQualityErr = "face image quality insufficient"; //todo-sdk
export const off = "OFF";
export const on = "ON";
export const remove = "Remove";
export const save = "Save";
export const selectionRequest = "Please select an item";
export const weeks = "weeks";
export const days = "days";
export const hours = "hours";
export const minutes = "minutes";
export const seconds = "seconds";
export const accept = "Accept";
export const cancel = "Cancel";
export const create = "Create";
export const multiServerModeUnavailable = "Multi-server deployment is not enabled on your system";
export const loading = "Loading";
export const textAreaHelperText = (textLength, maxTextLength) => `${textLength} of ${maxTextLength} characters`;
export const noRecords = "No Records To Present";
export const disabled = "Disabled";
export const unlimited = "Unlimited";
export const enabled = "Enabled";
export const duplicate = "Duplicate";
export const selectAll = "Select all";
export const edit = "Edit";
export const successCreate = "was created successfully";
export const successEdit = "was edited successfully";
export const nameTxt = "Name";
export const generate = "Generate";
export const from = "From";
export const till = "Till";
export const total = "Total";
export const analyzed = "Analyzed";
export const aborted = "Aborted";
export const errors = "Errors";
export const started = "Started";
export const processInProgress = "process in progress";
export const processCompleted = "process completed";
export const summary = "Summary";
export const createdSuccessfully = "created successfully";
export const editedSuccessfully = "edited successfully";
export const collapseDrawerLockedTxt = "Please ensure all required fields are filled";
export const apply = "Apply";
export const lowQualityFace = "Low Quality Face";
export const start = "Start";
export const end = "End";
export const deleteStr = "Delete";
export const inactiveLabel = "Inactive";
export const activeLiveLabel = "Active: Live";
export const activeInvestigateLabel = "Active: Investigate";
export const activeInvestigateLiveLabel = "Active: Live & Investigate";
export const uniqueLabel = "Unique Persons";
export const endCamerasTitle = "End Cameras";
export const activeCamerasLabel = "Active Cameras";
export const colorTitle = "Color";
export const noteLabel = "Note";
export const nextLabel = "Next";
export const backLabel = "Back";
export const finishLabel = "Finish";
export const unableToExecute = "Unable To Execute Request";
export const noResults = "No results were found";
export const back = "Back";
export const successTxt = "Success";

//warnings
export const clearWarn = (clearType) => `${confirmation} clear all ${clearType} ?`;
export const alerts = "alerts";
export const eventsRemovalWarn = "This action will not remove the corresponding alerts in alerts feed.";
export const alertsRemovalWarn = "This action will not remove the corresponding events in events feed.";
export const allWlsPoisRemovalWarn = "All POIs and their associated faces that are part of this watchlist will be completely removed from the DB";
export const wlPoisRemovalWarn = "Remove All POIs and their associated faces from the selected watchlist only.\n";
export const wlPoisRemovalWarnNote = " In case some POIs are associated with other watchlists, they will remain part them. Otherwise, they will be completely removed from the DB.";
export const terminateConnectionAttempt = "Failed to establish connection to the server, logging out";
export const lostServerCOnnection = "Lost connection to server";
export const checkingServerConnection = "Checking connection to server";

// privacy notice
export const eula = "EULA";
export const noSystemStatusError = "Error while fetching the Privacy Notice, please check the Host IP and retry";
export const privacyConsent = "By logging in you consent to the Privacy notice";
export const privacyNoticeTitle = "Data Retention & Privacy Notice";

//login screen
export const newPassword = "New Password";
export const confirmNewPassword = `Confirm ${newPassword}`;
export const hostIp = "Host IP";
export const loginButton = "Login";
export const password = "Password";
export const passwordMatchWarning = `Passwords do not match. Please make sure the ${newPassword} and ${confirmNewPassword} fields match.`;
export const unreachableHostError = "Host IP is unreachable, please check that the Host IP is correct and the server is online";
export const username = "Username";

//logout dialog
export const logout = "Logout";
export const logoutPrompt = "Are you sure you want to logout?";
export const logoutTitle = "Logout User";

//Side bar navigation
export const camerasHeader = "Cameras";
export const camerasList = "Cameras List";
export const videosList = "Videos List";
export const configurationHeader = "Configuration";
export const usersHeader = "Users";
export const privacyHeader = "Privacy";
export const createCameraLabel = "Create Camera";
export const createWatchlistHeader = "Create Watchlist";
export const eventsHeader = "Events";
export const eventsList = "Events Feed";
export const alertsFeed = "Alerts Feed";
export const historyHeader = "History";
export const historyList = "History Feed";
export const licenseHeader = "License";
export const poisHeader = "POIs";
export const serversHeader = "Servers";
export const serversStatus = "Servers Status";
export const settingsHeader = "Settings";
export const systemInfoHeader = "System Info";
export const disabledDisplayInvestigate = "Display is not available in investigate mode";
export const AuditHeader = "Audit Report";

//cameras list
export const actions = "Actions";
export const active = "Active";
export const cameraInfo = "Camera Information";
export const videoInfo = "Video Information";
export const progress = "Progress";
export const source = "Source";
export const error = "Error";
export const idle = "Idle";
export const reconnecting = "Reconnecting";
export const removeCameraConfirmation = `${confirmation} remove the camera?`;
export const removeVideoConfirmation = `${confirmation} remove the video?`;
export const status = "Status";
export const changeAnalysisMode = "Change the analysis mode to";
export const confirm = "Confirm";
export const startAll = "Start All";
export const removeAllIdle = "Remove all idle";
export const stopAll = "Stop All";
export const viewProgress = "View Progress";
export const stoppedAllCameras = "Finished stopping cameras";
export const removedAllCameras = "Finished Removing cameras";
export const stoppedAllVideos = "Finished stopping videos";
export const removedAllVideos = "Finished removing videos";
export const analyzingVideos = "Analyzing videos";
export const startingCameras = "Starting cameras";
export const video = "Video";
export const createVideo = "Create Video";
export const editVideo = "Edit Video";

// create camera 
export const advancedSettingsHeader = "Advanced Settings";
export const ageDetector = "Enable Age Detector";
export const ageGenderDetection = "Age & Gender Detection";
export const analysisLoadControl = "Analysis Load Control";
export const analysisQualityHeader = "Analysis Quality";
export const average = "Average";
export const camera = "Camera";
export const name = "Camera Name";
export const cameraName = "Camera Name";
export const videoName = "Video Name";
export const cameraNotesHeader = "Camera Notes";
export const videoNotesHeader = "Video Notes";
export const cameraWatchlistsHeader = "Camera Watchlists";
export const videoWatchlistsHeader = "Video Watchlists";
export const cameraAnalysisNode = "Camera Analysis Node";
export const captureSource = "Capture Source";
export const createCameraLoadingMessage = "Creating your camera";
export const deviceNotAvailable = "Device Is Not Available";
export const file = "File";
export const framePlayback = "Enable Frame playback saving for matches";
export const genderDetector = "Enable Gender Detector";
export const rtspAdress = "RTSP Address";
export const livenessDetection = "Liveness Detection";
export const livenessDetector = "Enable Liveness Detector";
export const maxAppearancesInFrame = "Max Appearances In Frame";
export const minimumFaceWidth = "Minimum Face Width";
export const overallDetection = "Overall Detection";
export const personAttributes = "Person Attributes";
export const pixelsSuffix = "Pixels";
export const privacyProfile = "Privacy Profile";
export const quality = "Quality";
export const rtsp = "RTSP";
export const speed = "Speed";
export const thresholdLabel = "Threshold";
export const unauthorizedAlert = "Unauthorized Alert";
export const usb = "USB";
export const usbDevices = "USB Devices";
export const videoDisplayResolution = "Video display resolution";
export const videoFiles = "Video Files";
export const nestedFolderPath = "Nested folder path";
export const folderPathSelection = "Folder Path Selection";
export const analysisQualityWarn = "Prioritizing quality increases resource usage, may affect other streams";
export const tooManyFiles = "Too many files in the selected path, please choose a subfolder";

//cameras context menu
export const editCamera = "Edit Camera";
export const removeCamera = "Remove Camera";
export const startCamera = "Start Camera";
export const stopCamera = "Stop Camera";
export const stopVideo = "Stop Video";
export const startVideo = "Start Video";
export const removeVideo = "Remove Video";
export const removeAllIdleCamerasWarnLive = `${confirmation} remove all idle cameras?`;
export const removeAllIdleCamerasWarnInvestigate = `${confirmation} remove all idle videos?`;

//events list
export const age = "Age";
export const crop = "Detected Face Crop";
export const usedFaceImage = "Used Face Image";
export const faceAttributes = "Face Attributes";
export const gender = "Gender";
export const liveness = "Liveness";
export const mask = "Mask";
export const poiImg = "POI Image";
export const poiInfo = "POI Information";
export const similarityScore = "Similarity Score";
export const time = "Time";
export const watchlistInfo = "Watchlist Information";
export const appearanceRemoveMethod = "How would you like to remove this appearance?";
export const removeFromPlacement = "Remove the appearance from its current placement";
export const removeFromSystem = "Remove the appearance from the system including its history record";
export const cantRemoveEventFromSystemNote = "Note: Removing the event completely from the system is unavailable if the event is still being processed. \nPlease try again after confirming the person has left";
export const successfullyRemovedEvent = "Event was removed successfully";

//alerts list
export const poiAlertDetailLabel = "POI Alert Detail";
export const timeRecordedLabel = "Time Recorded";
export const matchSimilarityLabel = "Match Similarity";

//retention 
export const unretain = "Undo Retention";
export const retain = "Retain";
export const retainAppearance = "Retain Appearance";
export const unretainAppearance = "Undo Retention of Appearance";
export const retainUndoConfirm = "Are you sure you want to undo the retention of this appearance?";
export const retainConfirm = "Are you sure you want to retain this appearance?";
export const retainNote = "Note: the appearance will not be automatically removed according to the data retention settings";
export const retainUndoNote = "Note: by undoing the retention of the appearance, it may be automatically removed according to the data retention settings";
export const retainSuccess = "Successfully retained appearance";
export const unretainSuccess = "Successfully unreatined the appearance";
export const alsoRemoveRetained = "Remove retained appearances";
export const retainedRemoveWarn = "Are you sure you want to remove this appearance?\nIt is currently set to be retained";

//label appearance 
export const reviewAppearance = "Review Appearance";
export const successfullyLabeled = "Successfully labeled Appearance";
export const notReviewedText = "Not Reviewed";
export const correctMatchText = "Correct Match";
export const incorrectMatchText = "Incorrect Match";
export const missedMatchText = "Missed Match";
export const addNote = "Add a note";
export const appearanceNotes = "Appearance Notes";
export const appearanceLabel = "Appearance Label";

// analytics
export const analyticsHeader = "Analytics";
export const analyticsEventsLoading = "Loading Analytics...";
export const appearancesTitle = "Appearances";
export const detectionTitle = "Detections";
export const liveTitle = "Live";
export const maskedTitle = "Masked";
export const noAnalyticsToHandle = "There are no analytics to display";
export const notDeterminedLabel = "N/A";
export const notMaskedTitle = `Not ${maskedTitle}`;
export const openAnalyticsPanel = "Open Analytics Panel";
export const poiMatchesTitle = "POI Matches";
export const uniquePoisTitle = "Unique POIs";
export const spoofTitle = "Spoof";
export const totalTitle = "Total";
export const unauthorizedTitle = "Unauthorized";
export const analyticsErr = "Failed to load Analytics";
export const matchedAppearances = "Matched Appearances";
export const unknownAppearances = "Unknown Appearances";
export const uniqueUnknown = "Unique Unknown Persons";
export const unknownInsights = "Unknown Insights";
export const matchedInsights = "Matched Insights";

export const uniqueUnknownFromPersons = "Unique Unknown Persons / Unique Persons";
export const poisFromPersons = "Unique POIs / Unique Persons";
export const insightsPersonsFromInsights = "Unique Insights Persons / Insights";
export const matchedInsightsOfMatchedAppearances = "Matched Insights / Matched Appearances";
export const unknownInsightsOfUnknownAppearances = "Unknown Insights / Unknown Appearances";

export const minimum = "Minimum";
export const maximum = "Maximum";
export const insightDuration = "Insight Duration (minutes)";
export const numOfAppearances = "Number Of Appearances";

//filters
export const timeFilterHeader = "Time";
export const matchHeader = "Matched";
export const notMatchHeader = "Not Matched";
export const ageGroupHeader = "Age Range";
export const livenessFilterHeader = "Liveness Outcome";
export const genderFilterHeader = "Gender";
export const reviewedFilterHeader = "Reviewed";
export const unauthorizedHeader = "Unauthorized";
export const detectHeader = "Detections";
export const allWatchlistFilterOption = "All Watchlists";
export const specificWatchlistsFilterOption = "Specific Watchlists";
export const allCamerasFilterOption = "All Cameras";
export const allVideosOption = "All Videos";
export const specificCamerasFilterOption = "Specific Cameras";
export const specificVideosFilterOption = "Specific Videos";
export const allAgeGroups = "All Ranges";
export const allLivenessOutcomes = "All Outcomes";
export const specificOutcomes = "Specific Outcomes";
export const allGendersOutcomes = "All Genders";
export const allAppearancesFilterOption = "All Appearances";
export const applyingFilters = "Applying New Filters...";
export const filtersApplySuccess = "Filters applied successfully";
export const resetAnalytics = "Reset Analytics";
export const filtersConfirmationWarning = "Are you sure you want to reset analytics?";
export const openFilterPanel = "Open Filter Panel";
export const notDeterminedHeader = "Not Determined";
export const inconclusiveHeader = "Inconclusive";
export const setFilters = "Apply Filters";
export const watchlistsHeader = "Watchlists";
export const watchlistsFilter = "watchlistsFilter";
export const generativeInsightsFilter = "generativeInsightsFilter";
export const generateInsightFilterHeader = "Insights Names";
export const showAllGenerativeInsights = "All Insights";
export const allInsightTypes = "All Insight Types";
export const specificInsightType = "Specific Insight Type";
export const allInsightNames = "All Insight Names";
export const specificInsightNames = "Specific Insight Names";

// globals
export const message = "message";
export const VideoDeviceType = "videoinput";

//history list
export const search = "Search";
export const compare = "Compare";

//Create Watchlist
export const notIn = "NOT IN";
export const authorized = "Authorized";
export const authorizedListType = "Authorized List - Alert On Person NOT in Watchlist";
export const clearWatchlist = "Clear watchlist";
export const selectWlClearMethod = "Please select the required watchlist clearing method";
export const createWatchlistLoadingMessage = "Creating your watchlist";
export const createWatchlistSuccessfulMessage = "Watchlist was created successfully";
export const editWatchlist = "Edit watchlist";
export const editWatchlistSuccessfulMessage = "Watchlist was edited successfully";
export const interest = "Interest";
export const interestListType = "Interest List - Alert On Person in Watchlist";
export const removeWatchlist = "Remove watchlist";
export const watchlistColor = "Watchlist Color";
export const watchlisThreshold = "Watchlist Threshold";
export const watchlistName = "Watchlist Name";
export const watchlistNotes = "Watchlist Notes";
export const watchlistThresholdInfo = "Watchlist threshold acts as a +/- delta adjustment to threshold set by camera";
export const watchlistTypeLabel = "Type";

// Watchlist screen
export const editPoi = "Edit POI";
export const notes = "Notes";
export const removePoi = "Remove POI";
export const removePoiConfirmation = `${confirmation} remove the POI?`;
export const removePoiNote = `Note: the POI will be removed from all assigned watchlists`;
export const watchlistPoisNumber = "POIs";
export const alertedWatchList = "Match Severity";
export const soundOnAlert = "Sound On Alert";
export const repeatedAlertLabel = "Repeated Alert";
export const popUpOnAlertSeverity = "Alert";
export const normalSeverity = "Normal";
export const selectedWatchlists = "Selected Watchlist";
export const allWatchlists = "All Watchlists";
export const searchPoiName = "Search POI";

// Create/Edit POI
export const addImageFromCamera = "Upload From Camera";
export const addImagesHeader = "Images";
export const addPoi = "Add POIs";
export const forceUploadLowQualityImg = "Force upload low quality image";
export const captureImage = "Capture Snapshot";
export const clearCaptureImage = "Clear Snapshot";
export const clearWlConfirmation = `${confirmation} clear the watchlist?`;
export const confirmBBoxSelection = "Use Image";
export const confirmCaptureImage = "Confirm Snapshot";
export const consent = "Consent Given";
export const createPOIHeader = "Create POI";
export const createPoiLoadingMessage = "Creating your POIs";
export const createPoiSuccessfulMessage = "POIs was created successfully";
export const detectingFacesInImage = "Detecting faces in image...";
export const editPoiSuccessfulMessage = "POIs was updated successfully";
export const noAvailableCameras = "No Available Cameras";
export const noConsent = "Consent Refused";
export const not_reviewed = "Not Reviewed";
export const poiConsent = "POI Consent";
export const poiDisplayName = "Display Name";
export const poiDisplayImage = "POI Display Image";
export const poiIdPlaceholder = "Type or Generate ID";
export const removeWlConfirmation = `${confirmation} remove the watchlist?`;
export const selectCamera = "Select Camera";
export const setDisplay = "Set as Display";
export const poiFailedAddFaceImages = "The following images could not be added";
export const lowQuality = "Low Quality";
export const analyzeWarning = "An error occurred while analyzing the face";
export const poiNotes = "POI Notes";

// Display Camera Stream
export const deselectDisplay = "No Display Selected";
export const displayCamera = "Display Camera";
export const displayHeader = "Display";
export const goToCamera = "Go To Camera";
export const goToCameraConfirmationTxt = "By confirming this action new events will be filtered by the selected camera only. New events from other cameras will not be presented";
export const loadingStream = "Loading Stream...";
export const openDisplayPanel = "Open Display Panel";
export const selectDisplay = "Select Display";
export const streamDisconnectedAlert = "Stream is disconnected";

//settings system information
export const version = "VERSION";
export const clientVersion = "Client Version";
export const copied = "Copied";
export const licenseKey = "License key";
export const licenseKeyCopyMsg = `${licenseKey} copied!`;
export const serverVersion = "Server Version";
export const stabilityLabel = "Stability";
export const serverType = "Server Type";

//settings license
export const expiresOn = "expires on";
export const invalidLicense = "Current license is not valid";
export const is = "is";
export const licensePlan = "Your license plan";
export const perpetual = "Perpetual";
export const validLicense = "Current license is up to date";
export const licenseNotIncluded = "Your license does not include: ";
export const historyStorage = "History Storage";
export const historyRecords = "History Records";
///
export const analysisModelLicense = "Analysis Model";
export const poiFacesLicense = "Faces Capacity";
export const liveCamerasLicense = "Live Cameras";
export const investigateVideosLicense = "Investigate Videos";
export const analysisLoadControlLicense = "Analysis Load Control";
export const registeredUsersLicense = "Registered Users";
export const ageEstimationLicense = "Age Estimation";
export const genderEstimationLicense = "Gender Estimation";
export const compareFacesCountLicense = "Compare Faces Count";
export const detectFacesCountLicense = "Detect Faces Count";
export const analyzeFacesCountLicense = "Analyze Faces Count";
export const searchFacesCountLicense = "Search Faces Count";
export const livenessFacesCountLicense = "Liveness Detection";
export const videosSimultaneouslyLabel = "Videos Simultaneously";
export const seatsLabel = "Seats";
export const appearancesPerCameraLabel = "Appearances Per Camera";
export const apiRequestsLabel = "API Requests";

//settings configuration
export const disabledConfTooltip = "Stop all streams to enable this option";
export const disabledConfTooltipCamerasFilter = "Changing analysis mode is not supported while events have camera filters applied";
export const displayCalibrationProps = "Display Calibration Properties";
export const displayCalibrationConf = `Enable ${displayCalibrationProps}`;
export const faceSignatureConf = "Send Face Signature with Events ";
export const generalConf = "General configuration";
export const sigPayloadOutput = "Signature Payload Output";
export const zeroThreasholdInfo = "Searching with 0 threshold will return a large amount of low accuracy matches not guaranteed by the system";
export const zeroThresholdConf = "Enable Searching with 0 Threshold";
export const setInvestigateAnalysisMode = "Set Investigate Analysis Mode";

//Settings privacy 
export const privacyProfilesNameLabel = "Privacy profile name";
export const appearancePrivacyProfile = "Appearance Privacy Profile";
export const appearancesPrivacy = "Appearance Privacy Profiles";
export const poisPrivacy = "Watchlist Privacy Profiles";
export const createAppearancePrivacyProfile = `Create ${appearancesPrivacy}`;
export const createWatchlistPrivacyProfile = `Create ${poisPrivacy}`;
export const editAppearancePrivacyProfile = `Edit ${appearancesPrivacy}`;
export const editPoisPrivacyProfile = `Edit ${poisPrivacy}`;
export const profileName = "Profile Name";
export const ProfileConfiguration = "Profile Configuration";
export const streams = "Streams";
export const defaultAppearanceProfile = "Default Profile";
export const poiPrivacyConfiguration = "Configuration";
export const activePoiPrivacyProfile = "Active Profile";
export const matchInterest = "Faces Matched to an Interest List";
export const notAuthorized = "Faces outside an Authorized List";
export const authorizedList = "Faces Matched to an Authorized List";
export const detected = "Faces Detected Without a Match";
export const requireGuardianConsent = "Require Guardian Consent For Minors";
export const savePoiDisplayImage = "Store POI Display Image";
export const SavePoiFaceCrops = "Store Face Images for a POI";
export const savePoiFaceGenderOutcome = "Store Gender Estimation for POI Face Images";
export const SavePoiFaceAgeOutcome = "Store Age Estimation for POI Face Images";
export const removeAppearanceProfileConfirmation = `${confirmation} remove the Appearance Privacy Profile?`;
export const removePoiProfileConfirmation = `${confirmation} remove the Watchlist Privacy Profile?`;
export const editProfile = "Edit Profile";
export const viewProfile = "View Profile";
export const setDefault = "Set as Default";
export const setActive = "Set as Active";
export const privacyCreatedSuccessfully = "Privacy Profile created successfully";
export const privacyUpdatedSuccessfully = "Privacy Profile updated successfully";
//Appearances
export const setAppearancesPrivacySuccessMsg = "Privacy profile set as Default successfully";
export const privacyAppearancesTooltipWarning = "Enabling or disabling the display of specific data will be reflected in both the video display and the events found in the Feed.";
export const privacyAppearancesHistoryTooltipWarning = "Enabling or disabling the display of specific data will be reflected in both the playback display and the history records.";

//--Stream
export const streamAnalysis = "STREAM ANALYSIS";
export const detectedTooltipTxt = "Appearances that were detected but did not match to a POI in an Interest or Authorized type of watchlist.";
export const sendSignature = "Send signature";
//--// 
export const generateDetectionImg = "Generate detection events";
export const displayFaceImg = "Display face image";
export const displayAge = "Display age";
export const displayGender = "Display gender";
//--// 
export const matchInterestTooltipTxt = "Appearances that were detected and matched to a POI in an Interest type of watchlist.";
export const matchInfoFilterTooltipTxt = "Appearances that were detected and matched to a POI in an Interest or Authorized type of watchlist.";
export const generateMatchedEvents = "Generate matched events";
export const displayPoiId = "Display POI ID";
export const displayPoiImg = "Display POI image";
export const displayPoiNotes = "Display POI notes";
export const displayWatchlistNotes = "Display Watchlist notes";
//--//
export const authorizedListTooltipTxt = "Appearances that were detected by the camera and matched to a POI in an Authorized type of watchlist.";
//--//
export const notAuthorizedListTooltipTxt = "Appearances that were detected but did not match to a POI in an Authorized type of watchlist.";
export const generateNonAuthEvents = "Generate non-authorized events";
//--History
export const saveDetectionEvents = "Save detection events";
export const saveFaceImg = "Save face image";
export const saveAge = "Save age";
export const saveGender = "Save gender";
export const saveSignature = "Save signature";
//--//
export const saveMatchedEvents = "Save matched events";
export const savePoiId = "Save POI ID";
export const savePoiImg = "Save POI image";
export const savePoiNotes = "Save POI notes";
export const saveWatchlistNotes = "Save Watchlist notes";
//--//
export const saveNonAuthEvents = "Save non-authorized events";
//WatchlistPOIs
export const setWatchlistPrivacySuccessMsg = "Privacy profile set as Active successfully";
export const watchlistPois = "Watchlist POIs";
export const setAgeRange = "Set the age range that refers to a Minor";
export const watchlistPoiTooltipTxt = "These toggles represent which biometric data is stored with a POI while it is being created or edited";
export const watchlistPoiTooltipWarning = "Note that modifying these toggles will not edit existing biometric data associated with a POI";
export const guardianAgeGroupTxt = (group) => `${group} years old and younger`;
export const olderGuardianAgeGroupTxt = "70 years old and older";
export const guardianTooltipTxt = "POI images detected as a minor age range will display a notice that requires a guardian to provide consent for the POI";

//--//

//Settings users
export const rolesHeader = "Roles";
export const account = "Account";
export const createUsers = `Create User`;
export const createRoles = `Create Role`;
export const editUsers = `Edit User`;
export const editRoles = `Edit Role`;
export const userStatus = "User Status";
export const role = "Role";
export const user = "user";
export const fullName = "Full Name";
export const email = "Email";
export const phoneNumber = "Phone Number";
export const userState = "State";
export const roleName = "Role Name";
export const dataFiltersApplied = "Data Filters Applied";
export const removeUser = "Remove User";
export const editUser = "Edit User";
export const removeRole = "Remove Role";
export const editRole = "Edit Role";
export const removeUserConfirmation = `${confirmation} remove the ${user}?`;
export const removeRoleConfirmation = `${confirmation} remove the ${role}?`;
export const userCreatedSuccessfully = "User created successfully";
export const userUpdatedSuccessfully = "User updated successfully";
export const roleCreatedSuccessfully = "Role created successfully";
export const roleUpdatedSuccessfully = "Role updated successfully";
export const basicInfo = "Basic info";
export const contactInfo = "Contact info";
export const confirmPassword = "Confirm Password";
export const clickResetPassword = "Click to Reset Password";
export const enableAccount = "Enable Account";
//Roles
export const controllerHeader = "Controller";
//formFieldsNames
//POI DB
export const poiFacesLabel = "POI Faces";
export const activeWatchlistsPrivacyProfile = "Active Privacy Profile";
export const poiManagement = "POI Management";
export const listAllPoiRecords = "List All POI Records";
export const getPoiRecords = "Get POI Records";
export const createNewPoiRecord = "Create New POI Record";
export const updatePoiRecord = "Update POI Record";
export const addWatchlistToPoiRecord = "Add Watchlist To POI Record";
export const removeWatchlistFromPoiRec = "Remove Watchlist From POI Record";
export const getPoiFaces = "Get POI Faces";
export const addFacesToPoi = "Add Faces To POI";
export const removeFacesFromPoi = "Remove Faces From POI";
export const removePoiRecord = "Remove POI Record";
export const searchPoiRecordByDispName = "Search POI Record By Display Name";
//--Watchlist Management
export const watchlistManagement = "Watchlist Management";
export const listAllWatchlist = "List All Watchlists";
export const getWatchlistRecord = "Get Watchlist Record";
export const createWatchlistRecord = "Create New Watchlist Record";
export const updateWatchlistRecord = "Update Watchlist Record";
export const removeWatchlistRecord = "Remove Watchlist Record";
//--Face Analysis
export const faceAnalysis = "Face Analysis";
export const analyzeFace = "Analyze Face";
export const detectFacesInAnImg = "Detect Faces In An Image";
export const searchFaceInPoiDb = "Search Face In POI DB / Duplicate Check";
export const searchFacesInPoiDb = "Search Multiple Faces In POI DB";
export const compareFaces = "Compare Faces";
//--POI DB Control
export const poiDbControl = "POI DB Control";
export const clearPoiDb = "Clear POI DB";
export const clearPoiDbConfirm = `${confirmation} clear the POI DB ?\n\n By approving this action all Watchlists and POI records will be removed from the system and can not be retrieved.`;
//Cameras
export const liveCameraManagement = "Live Camera Management";
export const listAllLiveCameras = "List All Live Camera Records";
export const getLiveCamera = "Get Live Camera Record";
export const createLiveCameraRecord = "Create New Live Camera Record";
export const updateLiveCameraRecord = "Update Live Camera Record";
export const removeLiveCameraRecord = "Remove Live Camera Record";
export const removeAllLiveCameraRecord = "Remove All Live Camera Records";
export const startAnalysisLiveCamera = "Start Analysis Of Live Camera";
export const stopAnalysisLiveCamera = "Stop Analysis Of Live Camera";
export const getLiveCameraPrivacyProfile = "Get Live Camera Privacy Profile";
export const setLiveCameraPrivacyProfile = "Set Live Camera Privacy Profile";
export const searchLiveCameras = "Search Live Camera Records";
//--Investigate Video Management
export const investigateVideoManagement = "Investigate Video Management";
export const listAllInvestigateVideos = "List All Investigate Videos";
export const getInvestigateVideoRecord = "Get Investigate Video Record";
export const createNewInvestigateVideoRecord = "Create New Investigate Video Record";
export const updateInvestigateVideoRecord = "Update Investigate Video Record";
export const removeInvestigateVideoRecord = "Remove Investigate Video Record";
export const removeAllInvestigateVideoRecord = "Remove All Investigate Video Records";
export const startAnalysisInvestigateVideo = "Start Analysis Of Investigate Video";
export const stopAnalysisInvestigateVideo = "Stop Analysis Of Investigate Video";
export const getInvestigateVideoPrivacyProfile = "Get Investigate Video Privacy Profile";
export const setInvestigateVideoPrivacyProfile = "Set Investigate Video Privacy Profile";
export const searchInvestigateVideos = "Search Investigate Video Records";
//--CameraGroupsManagement
export const cameraGroupsManagementLabel = "Camera Groups Management";
export const listAllCameraGroupsTxt = "List All Camera Groups";
export const getCameraGroupTxt = "Get Camera Group";
export const createCameraGroupTxt = "Create Camera Group";
export const updateCameraGroupTxt = "Update Camera Group";
export const removeCameraGroupTxt = "Remove Camera Group";
export const addCameraToGroupTxt = "Add Camera To Group";
export const removeCameraFromGroupTxt = "Remove Camera From Group";
export const clearCameraGroupTxt = "Clear Camera Group";
//--Analysis Mode Control
export const analysisModeCtrl = "Analysis Mode Control";
export const getAnalysisMode = "Get Analysis Mode";
export const setAnalysisMode = "Set Analysis Mode";
//History
//--History Appearances 
export const historyAppearances = "History Appearances";
export const getAppearanceRecord = "Get Appearance Record";
export const getUniqAppearances = "Get Unique Appearances (Group Results)";
export const countAppearances = "Count Appearances";
export const labelAppearancesRecord = "Label Appearance Record";
export const retainAppearancesRecord = "Retain Appearance Record";
export const createAppearancesRecord = "Create Appearance Records From Image";
export const createAppearancesRecordFromFace = "Create Appearance Record From Face";
export const addEventToHistoryTxt = "Create Appearance Record From Event";
export const removeAppearancesRecord = "Remove Appearance Records";
export const searchHistoryProximity = "History Proximity Search";
//--History Search Control
export const historySearchControl = "History Search Control";
export const searchImgInHistoryGroupRes = "Search Image In History (Group Results)";
export const searchImgInHistoryUnGroupRes = "Search Image In History (Ungroup Results)";
export const searchImgInHistoryUnGroupResMultiFaces = "Search Image With Multiple Faces In History (Ungroup Results)";
export const searchPoiInHistoryGroupRes = "Search POI In History (Group Results)";
export const searchPoiInHistoryUnGroupRes = "Search POI In History (Ungroup Results)";
//--History Insights
export const getHistoryInsightRecord = "Get History Insight Record";
export const removeHistoryInsightRecord = "Remove History Insight Record";
export const clearHistoryInsightRecord = "Clear History Insights DB";
//--History DB Control
export const historyDbControl = "History DB Control";
export const clearHistoryDB = "Clear History DB";
//Users
export const userManagement = "User Management";
export const listAllUsersRecords = "List All User Records";
export const getUserRecord = "Get User Record";
export const createNewUserRecord = "Create New User Record";
export const updateUserRecord = "Update User Record";
export const removeUserRecord = "Remove User Record";
export const terminateUserSession = "Terminate User Session";
//--Role Management
export const roleManagement = "Role Management";
export const listAllRoleRecords = "List All Role Records";
export const listAllPermissionRecords = "List All Permission Records";
export const getRoleRecord = "Get Role Record";
export const createNewRoleRecord = "Create New Role Record";
export const updateRoleRecord = "Update Role Record";
export const addPermissionToRole = "Add Permission To Role";
export const removePermissionFromRole = "Remove Permission From Role";
export const removeRoleRecord = "Remove Role Record";
//Role Data Filters
export const resetChanges = "Reset Changes";
export const appearanceEventsSettings = "Appearance Events Settings";
export const permissions = "Permissions";
export const allAppearancesFilters = (type) => `${type} - All Appearances`;
export const noAppearancesFilters = (type) => `No ${type} Appearances`;
export const allFacesFilters = (type) => `All ${type} Outcome`;
export const atLeastOneOutcomeRequired = "At least one outcome filter is required";
export const alertOnFacesDidntMatch = "Alert on faces that didn't match to an Authorized List";
export const presentMatchedEvents = "Present matched events";
export const presentDetectionEvents = "Present detection events";
export const presentNonAuthorizedEvents = "Present non-authorized events";
export const facesMatchedToKnownPOI = "Show Faces Matched to a Known POI";
export const facesDetectedWithoutMatch = "Show Faces Detected Without a Match";
export const ageOptions = "Age Options";
export const genderOptions = "Gender Options";
export const livenessOptions = "Liveness Options";
export const matchesTogglesAlertMsg = "At least one toggle must remain active";
export const eventFilterNotAllowed = "The event filter is not allowed";

//--Audit Control
export const auditControl = "Audit Control";
export const getAuditRecord = "Get Audit Record";
export const createNewAuditRecord = "Create New Audit Record";
//Controller
//--License Information
export const licenseInfo = "License Information";
export const getLicenseData = "Get License Data";
//--Watchlist Privacy Profile
export const watchlistPrivacyProfile = "Watchlist Privacy Profile";
export const listAllWatchlistPrivacyProfileRecords = "List All Watchlist Privacy Profile Records";
export const getWatchlistPrivacyProfileRecord = "Get Watchlist Privacy Profile Record";
export const createNewWatchlistPrivacyProfileRecord = "Create New Watchlist Privacy Profile Record";
export const updateWatchlistPrivacyProfileRecord = "Update Watchlist Privacy Profile Record";
export const removeWatchlistPrivacyProfileRecord = "Remove Watchlist Privacy Profile Record";
export const getDefaultWatchlistPrivacyProfileRecord = "Get Default Watchlist Privacy Profile Record";
export const setDefaultWatchlistPrivacyProfileRecord = "Set Default Watchlist Privacy Profile Record";
//Appearance Privacy Profile
export const listAllAppearancePrivacyProfileRecord = "List All Appearance Privacy Profile Records";
export const getAppearancePrivacyProfileRecord = "Get Appearance Privacy Profile Record";
export const createNewAppearancePrivacyProfileRecord = "Create New Appearance Privacy Profile Record";
export const updateAppearancePrivacyProfileRecord = "Update Appearance Privacy Profile Record";
export const removeAppearancePrivacyProfileRecord = "Remove Appearance Privacy Profile Record";
export const getDefaultAppearancePrivacyProfileRecord = "Get Default Appearance Privacy Profile Record";
export const setDefaultAppearancePrivacyProfileRecord = "Set Default Appearance Privacy Profile Record";
//Insights Management
export const insightManagement = "Insights Management";
export const listAllInsightRecords = "List All Insight Records";
export const getInsightRecord = "Get Insight Record";
export const createNewInsightRecord = "Create New Insight Record";
export const updateInsightRecord = "Update Insight Record";
export const removeInsightRecord = "Remove Insight Record";
export const getInsightsAnalytics = "Get Insights Analytics";

//Retention Control
export const retentionControl = "Retention Control";
export const getHistoryRetentionSettings = "Get History Retention Settings";
export const setHistoryRetentionSettings = "Set History Retention Settings";
export const getAutoClearRetentionSettings = "Get Auto Clear Retention Settings";
export const setAutoClearRetentionSettings = "Set Auto Clear Retention Settings";
export const getFrameStorageRetentionSettings = "Get Frame Storage Retention Settings";
export const setFrameStorageRetentionSettings = "Set Frame Storage Retention Settings";
export const getAuditRetentionSettings = "Get Audit Retention Settings";
export const setAuditRetentionSettings = "Set Audit Retention Settings";
export const getInsightsRetentionSettings = "Get Insights Retention Settings";
export const setInsightsRetentionSettings = "Set Insights Retention Settings";
//Analysis Control
export const analysisControl = "Analysis Control";
export const getSearchFaceSettings = "Get Search Face Settings";
export const setSearchFaceSettings = "Set Search Face Settings";
export const getAnalysisSettings = "Get Analysis Settings";
export const setAnalysisSettings = "Set Analysis Settings";
export const getFeatureOutcomes = "Get Feature Outcomes";
//Multi-Server Control
export const multiServerControl = "Multi-Server Control";
export const listAllNodeRecords = "List All Node Records";
export const getNodeRecord = "Get Node Record";
//Playback
//--Frame Storage Control
export const frameStorageControl = "Frame Storage Control";
export const getAppearanceFrameData = "Get Appearance Frame Data";
export const removeAppearanceFrameData = "Remove Appearance Frame Data";
export const clearFamesDb = "Clear Frames DB";
//Events
export const eventsSessionControl = "Events Session Control";
export const openNewEventsSession = "Open New Events Session";
export const editEventsSessionFilters = "Edit Events Session Filters";
export const validateEventsSessionFilters = "Validate Events Session Filters";
export const logoutUserSuccessMsg = "Successfully logged out the user";
export const allUsers = "All users";
export const specificUsers = "Specific users";
export const createNewPoi = "Create New POI";
export const addFaceToPoi = "Add to Existing POI";

//audit
export const auditUser = "Audit User";
export const generateAuditReport = "Generate Audit Report";
export const auditRecords = "Audit Records";
export const dateAndTime = "Date & Time";
export const action = "Action";
export const targets = "Target(s)";
export const parameters = "Parameters";
export const startTime = "Start Time";
export const endTime = "End Time";
export const label = "Label";
export const id = "Id";
export const auditName = "Name";
export const failedAuditReport = "Failed to generate Audit report";
export const earlyDateWarn = "From date should be earlier then Till date";
export const invalidDate = "date is invalid";

//settings data retention 
export const dataRetention = "Data Retention";
export const recordedData = "Recorded Data";
export const storageStatistics = "Storage Statistics";
export const dataRetentionSettings = "Data Retention Settings";
export const retentionSettings = "Retention Settings";
export const title = "Title";
export const storedRecords = "Stored Records";
export const recordsCapacity = "Records Capacity";
export const usage = "Usage";
export const retainedHistoryRecords = `Retained ${historyRecords}`;
export const playbackFrames = "Playback Frames";
export const retainedPlaybackFrames = `Retained ${playbackFrames}`;
export const insightsRecords = "Insights Records";
export const retentionConfig = "Retention Configuration";
export const retentionValue = "Retention Value";
export const historyStorageLimitation = "History Storage Limitation";
export const historyStorageAutoClear = "History Storage Auto Clear";
export const auditStorageLimitation = "Audit Storage Limitation";
export const clearHistoryRecordsAutomatically = "Clear History Records Automatically";
export const clearAuditRecordsAutomatically = "Clear Audit Records Automatically";
export const setHistoryAutoClear = "Set automatic removal of all Appearances and History records";
export const setAuditLongevity = "Set Longevity for Audit Records";
export const removeHistoryRecordAfter = "Remove entire history record after";
export const removeStartOn = "Remove will start on";
export const removeAuditAfter = "Remove  Audit Records after";
export const repeatEvery = "And will repeat every";
export const ofExistence = "of existence";
export const clearRecordsTooltip = (clearFraction, type) => `When the capacity is reached the first ${clearFraction}% will be removed to free space for new ${type}`;
export const historyAppearanceRecords = "History Appearance Records";
export const clearStorageConfirmation = (type, clearType = appearancesTitle) => `${confirmation} clear the ${type.toLowerCase()} DB of ALL ${clearType.toLowerCase()}?`;
export const clearStorageNote = (type) => `Note: This will permanently erase all ${type.toLowerCase()} from the system.`;
export const removeRetainedText = (type = appearancesTitle) => `Also remove retained ${type.toLowerCase()}`;
export const unaffectedRetainedAppearances = "Retained appearances are unaffected by the system auto clear functionality.";
export const serverLocalTime = "Local Time of Server";
export const clientLocalTime = "Local Time of Client";
export const systemAutoClearTime = "The system Auto Clear will take effect based on the Server local time";
export const clearInsightsDatabase = `${confirmation} clear the insights database?`;
export const retainedHistoryRecordsTooltip = "The capacity of the retained appearances is a portion of all history records and not in addition to it";
export const retainedPlaybackTooltip = "The capacity of the retained frames is a portion of all playback frames and not in addition to it";
export const clearedSuccessfully = "cleared successfully";
export const framesStorage = "Frames storage";
export const insightsStorage = "Generative Insights storage";
export const confirmDataLongevityChanges = "Confirm data longevity changes";
export const changesWillTakeEffect = "The changes will take effect immediately once confirmed";
export const dataRetentionNote = "Note: this will potentially remove existing history records in the next cleaning cycle, according to the defined retention policy";

// Import POIs
export const abortPoiImportTitle = "Abort Import";
export const poisImportAbortConfirmation = `${confirmation} abort importing of POIs?`;
export const acceptAbortConfirmation = "Abort";
export const adjustDetectedThresholdTitle = "Set duplicate detection threshold";
export const allowMultipleFaces = "Allow multiple faces in an image";
export const clearPoiImportTitle = "Clear Import Log";
export const downloadXLSTemplateTitle = "Download XLS Template";
export const enableDuplicatedPois = "Exclude images detected as a duplicate POI";
export const errorTableErrorHeader = "Error";
export const errorTableGetImageHeader = "Image";
export const errorTableImageHeader = "Image Name";
export const EstimatedTimeTitle = "Estimated Time";
export const failedFilesImportNotProcessed = "Number of images failed (Not Processed)";
export const failedFilesImportTitle = "Number of images failed (Processed)";
export const filesNotFoundInImageListTitle = "File not found in uploaded images";
export const filesNotFoundInXlsListTitle = "File not found in XLS file";
export const importLogTitle = "Import Log";
export const importPOIHeader = "Import POIs";
export const importPOIsSubmitButtonHeader = "Upload POIs";
export const invalidXlsFileTitle = "POI in XLS has an invalid declaration";
export const loadingFailedImage = "Loading Failed Image";
export const loadingImagesTitle = "Loading images";
export const areImageNamesUniqueError = "Invalid XLS file format: Duplicate image names found.";
export const importPoisLimitationTxt = "Import is limited to 100,000 images per import";
export const importPoisMinWorkersThresholdNote = "Note: Importing POIs may take longer than usual due to unexpected errors. Restarting the process is recommended.";

// search screen
export const mainCasesHeader = "Cases";
export const caseInfo = "Case Information";
export const createNewCase = "Create New Case";
export const maxMatchesLabel = "Max Matches";
export const updateCaseLabel = "Update Case";
export const loadingSavedCase = "Loading Data";
export const deleteCase = "Delete Case";
export const confirmDeleteCaseBody = `${confirmation} delete this search case?`;
export const setCaseNameLabel = "Name your search case";
export const defaultCaseName = "Initial Case";
export const caseNameExistsError = "This case name exists";
export const validCaseNameError = "Please enter a valid name";
export const minAppearancesLabel = "Minimum Number of Appearances";
export const minAppearances = "Minimum Appearances";
export const resultsClearWarning = "By performing a new search query the current results will be cleared.";
export const proximityAppearances = "Proximity Appearances";
export const searchInputErrMsg = "Special characters and multiple white spaces are not permitted.";
export const removeAppearance = "Remove Appearance";
export const removeAppearances = "Remove Appearances";
export const successfullyRemovedAppearances = "Successfully removed appearances";
export const removeByQueryConfirm = `${confirmation} remove the appearance results from the History database?`;
export const removeAppearanceNote = "Note: Records that have been removed will be automatically deleted from the database.";
export const cannotBeUndone = "This action can not be undone.";
export const retainCheckboxNote = "By checking the box below, retained appearances will be removed from the database as well.";
export const removeUponApprovalNote = "All records will be removed from the table upon approval.";

export const cantRemoveLiveAppearanceWarn = "Note: Removing the event completely from the system is unavailable since the event is still being processed. \nPlease try again after confirming the person has left the field of view of the camera.";
export const wontRemoveAlertWarn = "This action will not remove the corresponding alert in alerts feed.";
export const wontRemoveEventWarn = "This action will not remove the corresponding event in events feed.";
export const removeEventWarn = "Note: This action will remove the corresponding event in events feed.";
export const removeAlertWarn = "Note: This action will remove the corresponding alert in alerts feed.";

// search screen elements 
export const input = "Input";
export const destination = "Destination";
export const filters = "Filters";
export const image = "Image";
export const imageWithMultipleFaces = "Image With Multiple Faces";
export const appearancesFilters = "Appearances Filters";
export const poi = "POI";
export const historyDB = "History DB";
export const poiDB = "POI DB";
export const allPOiRecords = "All POI Records";
export const historyInsights = "History Insights";
export const historyProximity = "History Proximity";
export const historyDBQuery = "History DB Query";
export const appearanceExpansionTimeframe = "Appearance Expansion Timeframe";
export const matchParameters = "Match Parameters";
export const poiSignature = "POI Signature";
export const useSig = `Use ${poiSignature}`;
export const matchedAppearancesOnly = "Matched Appearances Only";
export const poiSignatureHeader = `POI Search Method`;
export const watchlists = "Watchlists";
export const sortOptions = "Sort By";
export const searchingPois = "Searching POIs";
export const searchPOiName = "Search POI Name";
export const noAppearancesError = "Didn't find any appearances";
export const sortByRecentTitle = "Most Recent";
export const sortByRecent = "recent";
export const sortBySeenTitle = "Most Seen";
export const sortBySeen = "appearance_count";
export const imgsSimilarityScore = "Similarity Score";
export const retainedAppearances = "Retained appearances";
export const showRetained = "Show Only Retained Appearances";
export const invalidImage = "Invalid image";
export const poiSearchLimitWarn = "Results are limited to the top 25";
export const appearanceSimilarityScore = "Appearance Similarity Score";
export const analysisMode = "Analysis Mode";
export const invalidPoiNameSearch = "Minimum 2 characters required";
export const searchInHistory = "Search in History";
export const searchInPoiDb = "Search in POI DB";

// Filters Params
export const eventsFilters = "event_filters";
export const historyFilters = "history_filters";
export const camerasFilter = "camerasFilter";
export const timeFilter = "timeFilter";
export const matchFilter = "matchFilter";
export const notMatchFilter = "notMatchFilter";
export const unauthorizedFilter = "unauthorizedFilter";
export const detectionFilter = "detectionFilter";
export const ageGroupFilter = "ageGroupFilter";
export const livenessFilter = "livenessFilter";
export const genderFilter = "genderFilter";
export const reviewedFilter = "reviewedFilter";
export const analysisModeFilter = "analysisModeFilter";

// insights
export const insights = "Insights";
export const uniqueInsightsPersons = "Unique Insights Persons";
export const appearancesCount = "appearancesCount";
export const appearancesCountHeader = "Appearances Count";
export const retainFilter = "retainFilter";
export const loadingPersonsAppearances = "Loading person appearances";
export const matchToError = "Matched to";
export const minutesTimeParseTitle = (minutes) => `About ${minutes} minutes left`;
export const noSelectedWatchlistTitle = "No Watchlist Was Selected!";
export const openResponseHeader = "Last Import Log";
export const poisImportFinishedTittle = "POIs Import finished";
export const poisImportTittle = "POIs Import In Progress";
export const potentialDuplicationErrorFilter = "Potential Duplicate";
export const detectedFace = "Detected Face";
export const matchesHeader = "Number Of Matches";
export const topMatch = "Top Match";
export const topMatchInformation = `${topMatch} Information`;
export const saveFailedImagesTitle = "Present images that failed to import";
export const searchMatchResult = (displayName, faceScore) => `Matched to ${displayName} with ${faceScore} similarity score`;
export const imageContainsMultipleFaces = "Image Contains Multiple Faces";
export const selectImagesHeader = "Images";
export const selectWatchlistHeader = "Watchlist";
export const selectXlsHeader = "XLS File";
export const successfulPoiImportTitle = "Number of images Uploaded successfully";
export const totalFilesPoiImportTitle = "Number of Images Selected";
export const insightProperties = "Insight Properties";
export const unclusteredAppearances = "Unclustered Appearances";
export const removeInsightsQueryConfirm = `${confirmation} remove the insights results from the insights database?`;
export const removeInsightsQueryNote = "Note: Records that have been removed will be automatically deleted from the database";
export const noInsightsAnalytics = "There are no analytics to display. Insights analytics are available when searching by a specific insight type.";

// Alerts-types:
export const errorAlert = "showError";
export const successAlert = "showSuccess";
export const infoAlert = "showInfo";
export const warningAlert = "showWarning";

// Notification-Center
export const caughtUp = "All caught up!";
export const clearAllButton = "Clear All";
export const markAllAsReadButton = "Mark all as read";
export const noNewNotifications = "There are no new notifications";
export const notePrefix = "NOTE";
export const notificationCenterHeader = "Notifications Center";
export const showUnreadOnlyLabel = "Show unread only";
export const turnOff = "TURN OFF";
export const turnOn = "TURN ON";
export const alertedEvent = "New Alerted Event";
export const alertedInsight = "New Insight Event";
export const snoozeNotifications = "Snooze notifications";

//Alerts-Center
export const alertsCenterHeader = "Alerts Center";
export const snoozeAlerts = "Snooze alerts";
export const acknowledgeAlertLabel = "Acknowledge";
export const acknowledgeAllAlertsLabel = "Acknowledge All";
export const muteLabel = "Mute";
export const unMuteLabel = "Unmute";
export const snoozeLabel = "Snooze";
export const unSnoozeLabel = "Unsnooze";

// Notification-Alerts
export const cameraError = "CAMERA ERROR";
export const connectionFailedError = "Connection Failed, SSE client side error";
export const disconnectedSession = "The session has been disconnected";
export const noUsbCameraFound = "No USB camera found";
export const serverError = "Server Error, SSE server side error, attempting reconnect";
export const sseConnectionClosedWarning = "Events session has been terminated unexpectedly";
export const unableToReconnectSse = "Unable to reconnect the events session, logging out";
export const sseConnectionStartSuccess = "Events session has been successfully started";
export const startCameraFailed = "Failed starting camera";
export const startCameraSuccess = "Successfully started camera";
export const stopCameraSuccess = "Successfully stopped camera";

// Generative Insights
export const insightLabel = "Insight";
export const generativeInsightsSearch = "Search Insights";
export const generativeInsightsHeader = "Insights";
export const generativeInsightsEventsFeed = "Insights Feed";
export const insightsData = "Insight Data";
export const insightTime = "Insight Time";
export const loadingGenerativeInsights = "Loading Insights";
export const insightsPartialRecordsMsg = "Based on system preferences some records are not stored and cannot be presented";
export const noLiveCameras = "No Live Cameras";
export const allInvestigateVideos = "All Investigate Videos";
export const noInvestigateVideos = "No Investigate Videos";
export const generativeInsightsList = "Insights List";
export const createGenerativeInsight = "Create Insight";
export const editGenerativeInsight = "Edit Insight";
export const updateGenerativeInsightLabel = "Update Insight";
export const removeGenerativeInsight = "Remove Insight";
export const removeGenerativeInsights = "Remove Insights";
export const successfullyRemovedInsights = "Successfully removed insights";
export const createGenerativeInsightSuccessMsg = "Insight created successfully";
export const editGenerativeInsightSuccessMsg = "Insight edited successfully";
export const insightName = "Insight Name";
export const insightType = "Insight Type";
export const insightNotes = "Insight Notes";
export const insightActive = "Active";
export const insightAnalysisMode = "Analysis Mode";
export const minFaceWidth = "Min Face Width";
export const excludedWatchlistsHeader = "Excluded Watchlists";
export const createGenerativeInsightLabel = "Create Insight";
export const alertingAppearanceDurationTxt = "Alerting Appearance Duration";
export const alertingNumberOfAppearancesTxt = "Alerting Number Of Appearances";
export const alertingTimeFrameTxt = "Alerting Timeframe";
export const notAlertingTimeFrameTxt = "Not Alerting Timeframe";
export const alertingUniqueIndividualsTxt = "Alerting Unique Individuals";
export const generativeInsightConfig = "Insight Config";
export const removeGenerativeInsightConfirmation = `${confirmation} remove the insight?`;
export const createGenerativeInsightLoadingMessage = "Creating the Insight";
export const cameras = "Cameras";
export const videos = "Videos";
export const successfullyRemovedInsight = "Successfully removed insight";
export const insightCameras = "Insight Cameras";
export const generateAlertLabel = "Generate Alert";
export const insightSearchFilters = "Insights Search Filters";
export const minInsightIntervalTime = "Minimum Insight Duration (Seconds)";

export const minFaceWidthBaseInfo = (insightTitle) => `Define the minimum face width from which a ${insightTitle} alert will be triggered (optional, if not defined it will be based on the overall camera detection minimum width of each camera)`;
export const excludedWatchlistsBaseInfo = (insightTitle) => `Choose watchlists from whom known POIs won't trigger a ${insightTitle} alert (optional, if not chosen the system will be triggering ${insightTitle} alerts on all types of appearances)`;

export const loitering = "Loitering";
export const loiteringBaseInfo = "Alert when an individual has been seen in front of a specific camera for more than a pre-defined timeframe";
export const alertingAppearanceDurationLoiteringInfo = "Define how long an individual's appearance should last for it to trigger a loitering alert";

export const repeatedIndividual = "Repeated Individual";
export const repeatedBaseInfo = "Alert when an individual has been seen more than a pre-defined number of times in front of a specific camera or group of cameras";
export const alertingNumberOfAppearancesRepeatedInfo = "Define the number of appearances of the same individual for the system to trigger a repeated individual alert";
export const alertingTimeFrameRepeatedInfo = "Define the maximum duration between appearances for an appearance to be counted towards a repeated individual alert";
export const notAlertingTimeFrameRepeatedInfo = "Define the minimum duration between consecutive appearances for an appearance to be counted towards a repeated individual alert";

export const crowdedArea = "Crowded Area";
export const crowdedAreaBaseInfo = "Alert when a group of unique individuals has been seen in front of a specific camera for more than a pre-defined timeframe";
export const alertingUniqueIndividualsCrowdedInfo = "Define the number of unique individuals who will trigger a crowded area alert";
export const alertingAppearanceDurationCrowdedInfo = "Define how long the appearances of the unique individuals should last for it to trigger a crowded area alert";

export const poiProximityName = "POI Proximity";
export const proximityTimeframeHeader = "Appearance Overlap Timeframe";
export const proximityBaseInfo = "Alert when recognized POIs from different watchlists have been seen in the same camera for more than a pre-defined timeframe";
export const alertingAppearanceDurationProximityInfo = "Define how long an overlap between two POIs appearances should last for it to trigger a POI Proximity alert";
export const alertingWlLabel = "Alerting Watchlists";
export const alertingWlTooltipTxt = "Recognized POI from these watchlists will trigger a POI Proximity alert when seen with POI from the counter watchlists for the pre-defined timeframe";
export const counterWlTooltipTxt = "Recognized POI from these watchlists will trigger a POI Proximity alert when seen with POI from the alerting watchlists for the pre-defined timeframe";
export const counterWlLabel = "Counter Watchlists";

export const personJourneyName = "Person Journey";
export const personJourneyBaseInfo = "Alert when an individual has been seen at an entry camera but then has not been seen since or has been seen later than expected at a specific exit camera or group of cameras";
export const journeyTypeTooltipInfo = "Slow Journey - Alert when an individual has been seen at an entry camera but has not been seen after a pre-defined timeframe at a specific exit camera or group of cameras\n\nSpecific Journey - Alert when an individual has been seen at an entry camera and has also been seen after a pre - defined timeframe at a specific exit camera or group of cameras";
export const entryCameraLabel = "Entry Camera";
export const existCamerasLabel = "Exit Cameras";
export const slowJourneyLabel = "Slow Journey";
export const wrongJourneyLabel = "Specific Journey";
export const journeyType = "Journey Type";
export const alertingAppearanceDurationJourneyInfo = "Slow Journey - Define the allowed timeframe from the first appearance for it to trigger a Slow Journey alert \n\nSpecific Journey - Define the timeframe between appearances for it to trigger a Specific Journey alert";

// frames
export const showPlayback = "Show Playback";
export const openPlaybackPanel = "Open Playback Panel";
export const playback = "Playback";
export const frames = "Frames";
export const saveCurrentFrame = "Save current frame";
export const saveFullPlayback = "Save full playback";
export const deletePlayback = "Delete playback";
export const deletePlaybackConfirm = `${confirmation} delete this saved playback?`;
export const matchInfo = "Match Info";
export const frame = "frame";
export const deletePlaybackSuccess = "Successfully deleted playback";

// data-grid-reports
export const exportToExcel = "Export";
export const usedImage = "Used image";
export const searchCriteriaSheet = "Search Criteria";
export const resultsSheet = "Results Sheet";
export const noDetections = "No Detections";
export const poiName = "POI Name";
export const appearancesAnalyticsHeader = "Appearances Analytics";
export const appearancesAttrAnalyticsHeader = "Appearances Attributes Analytics";
export const indexHeader = "Index";

//Stepper
export const takePhotoLabel = "Take a Photo";
export const usePicLabel = "Use Picture";
export const retakeLabel = "Retake";
export const unableToLoadCamera = "Unable to Load Camera";
export const processDetection = "Process Detection";
export const rotateLabel = "Rotate";
export const forceUploadNote = "Face quality is too low and doesn't meet the minimum requirements. You can force add the face, but this is not recommended as it may increase the risk of incorrect identifications.\n\nAre you sure you want to proceed and force add this image?";
export const poiDuplicationNote = "Potential Duplicate - This POI may already exist in the DB\n Select the image below to merge the new image to an existing POI";
export const viewPoiFacesLabel = "View POI faces";
export const removeDisplayImg = "Remove Display Image";
export const removeFaceImg = "Remove Face";
export const removePoiConfirmationNote = "Are you sure you want to remove this POI";
export const removePoiFaceConfirmationNote = "Are you sure you want to remove this face";
export const uploadImageLabel = "Upload Image";
export const captureImageLabel = "Capture From USB";
export const snapshotStepLabel = "Capture";
export const fileStepLabel = "Upload";
export const detectStepLabel = "Select";
export const analyzeStepLabel = "Analyze";
export const duplicateStepLabel = "Duplicate";
export const requireGuardianConsentLabel = "Based on the detected age of the POI face, a guardian consent is required";
export const addFaceToPOI = "Continue to Edit POI";
export const searchDuplicationsNote = "Searching for duplicates";
export const analyzeFaceNote = "Analyzing face quality";
export const uploadImageNote = "Please upload an image";
export const selectFaceLabel = "Select Face";

//Servers-Nodes-List
//--servers status
export const notStable = "Not Stable";
export const stableLabel = "Stable";
export const statusTimeout = "Status timeout";
export const inactive = "Inactive";
export const pendingStatus = "Pending";
//--
export const lastUpdateTime = "Last Update Time";
export const nodeId = "Analysis Node";
export const serverStatus = "Server Status";
export const mainDeviceTypeLabel = "Multi-Server Main";
export const nodeDeviceTypeLabel = "Multi-Server Node";
export const distributedHQDeviceTypeLabel = "Distributed HQ";
export const distributedDeviceTypeLabel = "Distributed Site";
export const notResponsiveLabel = "is not responsive";
export const unStableLabel = "is Unstable";