"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GetFacesRequest model module.
 * @module model/GetFacesRequest
 * @version 0.37.1
 */
class GetFacesRequest {
  /**
   * Constructs a new <code>GetFacesRequest</code>.
   * @alias module:model/GetFacesRequest
   */
  constructor() {
    GetFacesRequest.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>GetFacesRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GetFacesRequest} obj Optional instance to populate.
   * @return {module:model/GetFacesRequest} The populated <code>GetFacesRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GetFacesRequest();
      if (data.hasOwnProperty('get_crops')) {
        obj['get_crops'] = _ApiClient.default.convertToType(data['get_crops'], Object);
      }
      if (data.hasOwnProperty('get_signature_payload')) {
        obj['get_signature_payload'] = _ApiClient.default.convertToType(data['get_signature_payload'], Object);
      }
      if (data.hasOwnProperty('face_ids')) {
        obj['face_ids'] = _ApiClient.default.convertToType(data['face_ids'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GetFacesRequest</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetFacesRequest</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * whether to return face crop and poi_display images
 * @member {Object} get_crops
 */
GetFacesRequest.prototype['get_crops'] = undefined;

/**
 * whether to return the signature payload of the faces
 * @member {Object} get_signature_payload
 */
GetFacesRequest.prototype['get_signature_payload'] = undefined;

/**
 * @member {Object} face_ids
 */
GetFacesRequest.prototype['face_ids'] = undefined;
var _default = exports.default = GetFacesRequest;