import { has, isEmpty } from "lodash";

import { colorValues } from "@/Styles/tailwind/Colors/appColors";
import { age, appearancesTitle, detectionTitle, liveTitle, notDeterminedLabel, poiMatchesTitle, spoofTitle, totalTitle, unauthorizedTitle, uniqueLabel, uniquePoisTitle } from "src/appConfig/Strings";
import { genderOutcome } from "@/Logic/ApiClients/ControllerClient";
import { eventTable } from "../MUITables/TablesLogic";

const genderPieChartColorSchema = {
    [genderOutcome.male]: colorValues.SteelBlue,
    [genderOutcome.female]: colorValues.Magenta,
    [notDeterminedLabel]: colorValues.Taupe
};

const ageGroupPieChartColorSchema = [
    colorValues.Yellow,
    colorValues.PalePink,
    colorValues.MidnightBlue,
    colorValues.BurntOrange,
    colorValues.RoyalPurple,
    colorValues.Teal,
    colorValues.LimeGreen,
    colorValues.TomatoRed,
    colorValues.OliveGreen,
    colorValues.Taupe
];

const matchOutcomeCountersWithTitles = (analyticsType, analytics) => {
    const isEventTable = analyticsType === eventTable;

    const matchOutcomeConfig = isEventTable ? {
        poiMatches: {
            title: poiMatchesTitle,
            value: analytics?.matches?.counter ?? 0
        },
        uniqueOrDetections: {
            title: detectionTitle,
            value: analytics?.detections?.counter ?? 0
        },
        unauthorized: {
            title: unauthorizedTitle,
            value: analytics?.notInWlOutcome?.counter ?? 0
        }
    } : {
        poiMatches: {
            title: uniquePoisTitle,
            value: analytics?.uniquePois?.counter ?? 0
        },
        uniqueOrDetections: {
            title: uniqueLabel,
            value: analytics?.uniquePersons?.counter ?? 0
        },
        unauthorized: {
            title: unauthorizedTitle,
            value: analytics?.notInWlOutcome?.counter ?? 0
        }
    };

    return [matchOutcomeConfig.poiMatches, matchOutcomeConfig.uniqueOrDetections, matchOutcomeConfig.unauthorized];
};

const faceFeatureOutcomeCountersWithTitles = (analytics) => {
    const liveCounter = {
        attributeName: "liveness",
        title: liveTitle,
        value: analytics?.liveness?.live?.counter ?? 0
    };

    const spoofCounter = {
        attributeName: "liveness",
        title: spoofTitle,
        value: analytics?.liveness?.spoof?.counter ?? 0
    };

    return [liveCounter, spoofCounter];
};

const getAgeGroupList = (ageGroup) => Object.keys(ageGroup || {});

export const analyticsModel = (analyticsType, analytics = {}, featureOutcome = {}) => {
    if (isEmpty(analytics) || isEmpty(featureOutcome)) {
        return null;
    }

    return ({
        appearances: {
            title: appearancesTitle,
            value: analytics?.totalAppearances?.counter ?? 0
        },
        matchOutcomeAnalytics: {
            content: matchOutcomeCountersWithTitles(analyticsType, analytics)
        },
        faceFeatureOutcomeAnalytics: {
            content: faceFeatureOutcomeCountersWithTitles(analytics).filter((feature) => featureOutcome[feature.attributeName]?.length)
        },
        ageGroupTable: {
            headers: featureOutcome.gender ?
                [age, ...featureOutcome.gender, notDeterminedLabel, totalTitle] : [notDeterminedLabel, totalTitle],
            content: Object.keys(analytics?.age_group || {}).map((ageGroup) => ({
                title: ageGroup,
                value: featureOutcome.gender ? [
                    analytics?.age_group?.[ageGroup]?.female ?? 0,
                    analytics?.age_group?.[ageGroup]?.male ?? 0,
                    analytics?.age_group?.[ageGroup]?.[notDeterminedLabel] ?? 0,
                    analytics?.age_group?.[ageGroup]?.counter ?? 0
                ] : [
                    analytics?.age_group?.[ageGroup]?.[notDeterminedLabel] ?? 0,
                    analytics?.age_group?.[ageGroup]?.counter ?? 0
                ],
            }))
        },
        genderPieChart: {
            labels: Object.keys(analytics?.gender || {}),
            dataSet: summarizeGenders(analytics?.age_group),
            colors: genderPieChartColorSchema
        },
        ageGroupPieChart: {
            labels: getAgeGroupList(analytics?.age_group),
            dataSet: getAgeGroupList(analytics?.age_group).reduce((accumulatedData, ageGroup) => ({
                [ageGroup]: analytics?.age_group?.[ageGroup]?.counter ?? 0,
                ...accumulatedData,
            }), {}),
            colors: getAgeGroupList(analytics?.age_group).reduce((accumulatedColors, ageGroup, index) => ({
                [ageGroup]: ageGroupPieChartColorSchema[index],
                ...accumulatedColors
            }), {})
        }
    });
};



function summarizeGenders(ageGroupAnalytics) {
    const initialSummary = {
        [genderOutcome.male]: 0,
        [genderOutcome.female]: 0,
        [notDeterminedLabel]: 0
    };
    if (isEmpty(ageGroupAnalytics)) {
        return initialSummary;
    }

    return Object.values(ageGroupAnalytics).reduce((summary, ageGroup) => {
        Object.keys(ageGroup).forEach(key => {
            if (has(summary, key)) {
                summary[key] += ageGroup[key];
            }
        });
        return summary;
    }, initialSummary);
}