import { useCallback, useContext, useMemo } from "react";

import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import ApiContext from "@/Logic/Context/apiContext";
import { editCamera, editVideo, goToCamera, remove, removeCamera, removeCameraConfirmation, removeVideo, removeVideoConfirmation, startCamera, startVideo, stopCamera, stopVideo } from "src/appConfig/Strings";
import { clearDisplayById } from "@/Logic/Redux/Stores/GlobalStore";
import { cameraDialogID } from "@/Logic/Hooks/useDialog/useDialog.model";
import { activeCapturingStatuses } from "@/Logic/ApiClients/CamerasClient";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { routesUrl } from "@/Components/Common/Layout/SideBar/Routes";
import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";

function CameraContextMenu({ camera, isLiveAnalysisMode, clearDisplayById }) {
    const { clientsManager } = useContext(ApiContext);
    const navigate = useNavigate();
    const location = useLocation();
    const editOption = useMemo(() => isLiveAnalysisMode ? editCamera : editVideo, [isLiveAnalysisMode]);
    const removeOption = useMemo(() => isLiveAnalysisMode ? removeCamera : removeVideo, [isLiveAnalysisMode]);
    const startOption = useMemo(() => isLiveAnalysisMode ? startCamera : startVideo, [isLiveAnalysisMode]);
    const stopOption = useMemo(() => isLiveAnalysisMode ? stopCamera : stopVideo, [isLiveAnalysisMode]);

    // const dispatch = useDispatch(); //todo - display camera not functional

    // const dispatchSetDisplay = useCallback((camera) => { //todo - display camera not functional
    //     dispatch(clearDisplays());
    //     dispatch(addDisplayStream({ cameraId: camera.camera_id, streamUrl: camera.display_rtsp_address, index: 0 }));
    // }, [dispatch]);

    // const displayCameraCallback = useCallback(async () => { //todo - display camera not functional
    //     if (!camera.display_rtsp_address?.length) {
    //         const updatedCamera = await clientsManager.camerasClient.GetCamera(camera.camera_id);
    //         dispatchSetDisplay(updatedCamera);
    //     } else {
    //         dispatch(clearDisplays());
    //         navigate(routesUrl.displayCameras);
    //     }
    // }, [camera.camera_id, camera.display_rtsp_address?.length, clientsManager.camerasClient, dispatch, dispatchSetDisplay, navigate]);

    const toggleEditDialog = useCallback(() => {
        navigate(`${location.pathname}?camera_id=${camera.camera_id}`);
        dispatchToggleDialogEvent(cameraDialogID);
    }, [navigate, location.pathname, camera.camera_id]);

    const setOptions = useCallback(() => {
        const options = {};
        if (activeCapturingStatuses.includes(camera.camera_status.status)) {
            options[stopOption] = async () => {
                await clientsManager.camerasClient.stopCamera(camera.camera_id);
                clearDisplayById(camera.camera_id);
            };
            options[editOption] = () => {
                toggleEditDialog();
            };
            // if (activeCapturingStatuses.includes(camera.camera_status.status)) //todo - display camera not functional
            //     options[displayCamera] = () => displayCameraCallback();
            if (isLiveAnalysisMode) {
                options[goToCamera] = () => navigate(`${routesUrl.eventList}?cameraId=${camera.camera_id}`);
            }
        } else {
            options[startOption] = async () => await clientsManager.camerasClient.startCamera(camera.camera_id);
            options[editOption] = () => {
                toggleEditDialog();
            };
            options[removeOption] = async () => {
                await clientsManager.camerasClient.removeCamera(camera.camera_id);
            };
        }

        return options;
    }, [camera.camera_id, camera.camera_status.status, clearDisplayById, clientsManager.camerasClient, editOption, isLiveAnalysisMode, navigate, removeOption, startOption, stopOption, toggleEditDialog]);

    const confirmationOptions = useMemo(() => {
        return {
            [removeOption]: { acceptButtonText: remove, confirmationText: isLiveAnalysisMode ? removeCameraConfirmation : removeVideoConfirmation },
        };
    }, [isLiveAnalysisMode, removeOption]);

    return (
        <ContextMenuButton options={setOptions()} confirmationOptions={confirmationOptions} dataTestId={`camera-context-menu-${camera.description}`} />
    );
}

const mapDispatchToProps = {
    clearDisplayById
};

const mapStateToProps = (state) => {
    const isLiveAnalysisMode = state.ApiActionsStore.isLiveAnalysisMode;

    return { isLiveAnalysisMode };
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraContextMenu);