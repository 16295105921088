"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ListAllSummaryModel model module.
 * @module model/ListAllSummaryModel
 * @version 0.32.2
 */
class ListAllSummaryModel {
  /**
   * Constructs a new <code>ListAllSummaryModel</code>.
   * @alias module:model/ListAllSummaryModel
   * @param totalCameras {Number} number of cameras in the system
   * @param activeCameras {Number} number of cameras currently capturing
   * @param irregularStateCameras {Number} cameras in some sort of erroneous / non-trivial state
   */
  constructor(totalCameras, activeCameras, irregularStateCameras) {
    ListAllSummaryModel.initialize(this, totalCameras, activeCameras, irregularStateCameras);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, totalCameras, activeCameras, irregularStateCameras) {
    obj['total_cameras'] = totalCameras;
    obj['active_cameras'] = activeCameras;
    obj['irregular_state_cameras'] = irregularStateCameras;
  }

  /**
   * Constructs a <code>ListAllSummaryModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ListAllSummaryModel} obj Optional instance to populate.
   * @return {module:model/ListAllSummaryModel} The populated <code>ListAllSummaryModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ListAllSummaryModel();
      if (data.hasOwnProperty('total_cameras')) {
        obj['total_cameras'] = _ApiClient.default.convertToType(data['total_cameras'], 'Number');
      }
      if (data.hasOwnProperty('active_cameras')) {
        obj['active_cameras'] = _ApiClient.default.convertToType(data['active_cameras'], 'Number');
      }
      if (data.hasOwnProperty('irregular_state_cameras')) {
        obj['irregular_state_cameras'] = _ApiClient.default.convertToType(data['irregular_state_cameras'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ListAllSummaryModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ListAllSummaryModel</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ListAllSummaryModel.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
ListAllSummaryModel.RequiredProperties = ["total_cameras", "active_cameras", "irregular_state_cameras"];

/**
 * number of cameras in the system
 * @member {Number} total_cameras
 */
ListAllSummaryModel.prototype['total_cameras'] = undefined;

/**
 * number of cameras currently capturing
 * @member {Number} active_cameras
 */
ListAllSummaryModel.prototype['active_cameras'] = undefined;

/**
 * cameras in some sort of erroneous / non-trivial state
 * @member {Number} irregular_state_cameras
 */
ListAllSummaryModel.prototype['irregular_state_cameras'] = undefined;
var _default = exports.default = ListAllSummaryModel;