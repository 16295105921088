import { useMemo } from "react";

import { connect } from "react-redux";

import MultiSelectInputField from "@/Components/Common/FormComponents/FormInputs/SelectInputField/MultiSelectInputField";
import { baseCamerasFieldNames, baseCamerasRadioModel } from "../BaseModels";
import { camerasHeader, videos } from "src/appConfig/Strings";
import FilterWrapper from "@/Components/Settings/Users/CreateEditRole/RoleForm/RoleDataFilters/Filters/FilterWrapper";
import RadioEnabledSelect from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";

function InsightCamerasSelector({ model, formData, onFieldChange, isLiveAnalysisMode, camerasFromStore }) {
    const cameraFieldName = useMemo(() => isLiveAnalysisMode ? baseCamerasFieldNames.cameras : baseCamerasFieldNames.videos,
        [isLiveAnalysisMode]);
    const camerasModel = { ...model[cameraFieldName], required: true };

    return (
        isLiveAnalysisMode ? <MultiSelectInputField
            label={camerasHeader}
            model={camerasModel}
            options={Object.values(camerasFromStore)}
            value={formData[cameraFieldName]}
            onChange={(value) => onFieldChange(cameraFieldName, value)}
            dataTestId="insight-cameras"
        /> : <FilterWrapper label={videos} isDrawerOpen={true} >
            <div className="ml-2 mt-3.5 w-full">
                <RadioEnabledSelect
                    isMulti={true}
                    selectValue={formData?.[cameraFieldName]?.selectedOptions}
                    selectLabel={videos}
                    selectOptions={Object.values(camerasFromStore)}
                    selectModel={camerasModel}
                    selectOnChange={(value) => onFieldChange(cameraFieldName, { ...formData?.[cameraFieldName], selectedOptions: value })}
                    radioValue={formData?.[cameraFieldName]?.radioValue}
                    radioOptions={baseCamerasRadioModel}
                    radioOnChange={(value) => onFieldChange(cameraFieldName, { ...formData?.[cameraFieldName], radioValue: value })}
                />
            </div>
        </FilterWrapper>
    );
}

const mapStateToProps = (state) => {
    const isLiveAnalysisMode = state.ApiActionsStore.isLiveAnalysisMode;
    const camerasFromStore = state.CamerasStore.cameras;

    return { isLiveAnalysisMode, camerasFromStore };
};

export default connect(mapStateToProps, null)(InsightCamerasSelector);