
import Button from '@mui/material/Button';

function FilledBtn({ children, className, isDisabled, ...props }) {
    return (
        <Button
            disabled={isDisabled}
            className={`filled-btn-txt inline-flex h-main-btn-height min-w-main-btn-width items-center justify-center gap-2.5 rounded bg-oceanic-azure p-2.5 hover:bg-pie-line-bright-blue ${className}`}
            {...props}
        >
            {children}
        </Button>
    );
}

export default FilledBtn;