import React from "react";

import ReactDOM from "react-dom";

import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import moment from "moment";
import 'moment/locale/en-gb';

import "./appConfig/polyfills";
import * as serviceWorker from "./appConfig/serviceWorker";
import ToastContainerWrapper from './app/Components/Common/NotificationCenter/ToastContainer/ToastContainerWrapper';

import "react-widgets/scss/styles.scss";
import "./TemplateAssets/templateBase.scss";

import store from "./appConfig/configureStore";
import ApiContext from "./app/Logic/Context/apiContext";
import AudioManagerContext from "./app/Logic/Context/audioManagerContext";
import ClientsManager from "./app/Logic/ApiClients/ClientsManager";
import "./assets/MuiLicense";
import "./app/Styles/tailwind/tailwind.css";

import AppMain from "./app/Components/AppMain";
import AudioManager from "./app/Logic/Infrastructure/audioManager";
import { alertSound } from "./appConfig/constants";

const rootElement = document.getElementById("root");
const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
});

const renderApp = (Component) => {
    moment.locale('en-gb');
    const clientsManager = new ClientsManager();
    const audioManager = new AudioManager(alertSound);

    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <ToastContainerWrapper />
                <AudioManagerContext.Provider value={{ audioManager }}>
                    <ApiContext.Provider value={{ clientsManager }}>
                        <StyledEngineProvider injectFirst>
                            <HashRouter>
                                <Component />
                            </HashRouter>
                        </StyledEngineProvider>
                    </ApiContext.Provider>
                </AudioManagerContext.Provider>
            </Provider>
        </ThemeProvider>
        , rootElement
    );
};

renderApp(AppMain);

if (module.hot) {
    module.hot.accept("./app/Components/AppMain", () => {
        const NextApp = require("./app/Components/AppMain").default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();