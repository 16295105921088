
import { connect } from "react-redux";

import { createPOIHeader, editPoi, save } from "src/appConfig/Strings";
import MainDialog from "@/Components/Common/Modals/MainDialog";
import { clearOpenPoi } from "@/Logic/Redux/Stores/POIStore";
import CreateEditPOI from "./CreateEditPOI";
import { poiDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";

function CreateEditPOIDialog({ searchParams, poiId, clearOpenPoi }) {
    return (
        <MainDialog
            dialogID={poiDialogId}
            formID={createPOIHeader}
            headerTitle={poiId ? editPoi : createPOIHeader}
            footerSubmitBtnText={poiId && save}
            onExit={() => clearOpenPoi()}
            dataTestId="create-edit-poi-dialog"
        >
            <CreateEditPOI searchParams={searchParams} />
        </MainDialog>
    );
}

const mapDispatchToProps = {
    clearOpenPoi
};

const mapStateToProps = (state, ownProps) => {
    const poiId = ownProps.searchParams.get("poiId");

    return { poiId };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditPOIDialog);