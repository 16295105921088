import { useCallback, useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";

import CropImage from "@/Components/Common/Images/CropImage";
import OverflowingText from "@/Components/Common/Tooltips/OverflowingText";

function POICard({ poiImg, poiContextMenu, poiFooterText, className, cropImageClassName, footerClassName, defaultFaceCropClassName }) {
    const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
    const boxRef = useRef(null);

    const handleMouseEnter = useCallback(() => {
        setIsContextMenuVisible(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setIsContextMenuVisible(false);
    }, []);

    const handleContextMenuClick = useCallback((event) => {
        event.stopPropagation();
        setIsContextMenuVisible(true);
    }, []);

    const handleClickOutside = useCallback((event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsContextMenuVisible(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <Box
            ref={boxRef}
            className={`group relative flex h-[11.5rem] w-[7.5rem] flex-col justify-center rounded-lg bg-transparent ${className}`}
            onMouseEnter={poiContextMenu && handleMouseEnter}
            onMouseLeave={poiContextMenu && handleMouseLeave}
        >
            <div className="flex justify-center">
                {poiContextMenu && (
                    <div
                        className={`absolute right-0 top-1 transition-opacity duration-100 ${isContextMenuVisible ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}
                        onClick={handleContextMenuClick}
                    >
                        {poiContextMenu}
                    </div>
                )}
                <CropImage srcImage={poiImg} cropImageClassName={`!bg-transparent ${cropImageClassName}`} defaultFaceCropClassName={defaultFaceCropClassName} />
            </div>
            <div className={`flex min-h-6 justify-center px-1 pb-1 text-black ${footerClassName}`}>
                <OverflowingText text={poiFooterText} />
            </div>
        </Box>
    );
}

export default POICard;