import { EventType, MatchOutcomes } from "smart_cameras_events_api";

export const notInWlOutcome = "not in watchlist"; // todo-sdk
export const matchOutcomes = new MatchOutcomes();
export const eventTypes = new EventType();
export const validateSession = "validateSession";
export const updateFilters = "updateFilters";
export const maskDetect = "mask_detect";
export const lastAppearanceTrigger = 2;
export const faceCropImg = "face_crop_img";
export const sessionEnded = "sessionEnded";

//label appearance Outcomes:
export const notReviewed = "not_reviewed";
export const correctMatch = "correct_match";
export const incorrectMatch = "incorrect_match";
export const missedMatch = "missed_match";