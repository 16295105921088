/* eslint-disable no-restricted-globals */
import { v4 as uuid } from "uuid";

import { IndexDBHandlerInterface, MainThreadMessages, ManagingWebWorkerInterface } from "../WorkersInterfaces";

export default class ManagingWebWorker {
    constructor() {
        this.workingChildren = [];
        this.progressCounter = 0;
        this.successfulImage = 0;
        this.failedImage = 0;
        this.responses = {};
        this.images = [];
    }

    async init(hostIp, token) {
        self.postMessage([MainThreadMessages.storageWorker, [IndexDBHandlerInterface.destroyFilesStore, []]]);
        this.hostIp = hostIp;
        this.token = token;
    }

    async run(numOfWorkers) {
        this.setNumberOfWebWorkers(numOfWorkers);
    }

    setNumberOfWebWorkers(groupsNumber) {
        const numberOfCPUCors = navigator.hardwareConcurrency;
        if (groupsNumber && groupsNumber < numberOfCPUCors) {
            this.webWorkersNumber = groupsNumber;
            return;
        }
        if (this.totalImages < numberOfCPUCors) {
            this.webWorkersNumber = this.totalImages;
        }
        else {
            this.webWorkersNumber = numberOfCPUCors - 1;
        }
        this.webWorkersMinThresholdToAlert = Math.floor(0.7 * this.webWorkersNumber);
    }

    setImages(images) {
        this.images = Array.from(images);
        this.totalImages = this.images.length;
    }

    onTerminated() {
        Object.values(this.workingChildren).forEach(worker => worker.terminate());
        this.finishedUpload();
        self.close();
    }

    splitImagesIntoGroups() {
        throw new Error("Subclasses must implement abstractMethod");
    }

    createNewWorkingChild(childWorker) {
        childWorker.onmessage = (message) => this[message.data[0]](...message.data[1]);
        const workerId = uuid();
        this.workingChildren[workerId] = childWorker;
        return workerId;
    }

    updateProgress(prosedNumberOfImages, response) {
        this.progressCounter += prosedNumberOfImages;
        const progressPercentage = ((this.progressCounter / this.totalImages) * 100).toFixed(2);
        this.handleResponse(response);
        self.postMessage([MainThreadMessages.progressUpdate, [progressPercentage]]);
    }

    handleResponse(response) {
        throw new Error("Subclasses must implement abstractMethod");
    }

    childWorkerFinishedWork(id, isInitialization = false) {
        this.workingChildren[id].terminate();
        delete this.workingChildren[id];
        if (!isInitialization && Object.keys(this.workingChildren).length === 0) {
            this.finishedUpload();
            self.close();
        }
    }

    finishedUpload() {
        throw new Error("Subclasses must implement abstractMethod");
    }

    setSuspendSignal(signalValue) {
        Object.values(this.workingChildren).forEach(worker => worker.postMessage([ManagingWebWorkerInterface.setSuspendSignal, [signalValue]]));
    }

    updateTime(requestTime) {
        self.postMessage([MainThreadMessages.updateTime, [this.progressCounter, requestTime / this.webWorkersNumber]]);
    }
}

export function SetSuspendSignal(signal, worker) {
    worker.postMessage([ManagingWebWorkerInterface.setSuspendSignal, [signal]]);
}

export function Terminate(worker) {
    worker.postMessage([ManagingWebWorkerInterface.onTerminated, []]);
}