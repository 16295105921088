const displayImageEvent = "setDisplayImageEvent";
const saveAlteredCaseEvent = "saveAlteredCase";
const drawerSelectionChange = "drawerSelectionChange";
const drawerExpansionChange = "drawerExpansionChange";
const changePageEvent = "changePage";
const getFirstPageEvent = "getFirstPage";
const searchEvent = "search";
const sessionEndedSignalEvent = "sessionEndedSignal";
const wlClickedEvent = "wlClickedEvent";
const stepperEvent = "stepperEvent";

// Dialog events
const toggleDialogEvent = "toggleDialogEvent";
const isLoadingSignalEvent = "isLoadingSignalEvent";
const isFormFieldsChanged = "isFormFieldsChanged";
const removeLocalAppearance = "removeLocalAppearance";
const setDialogData = "setDialogData"; // todo revisit, each dialog should have its own data event 
const updateLocalAppearance = "updateLocalAppearance";
const showConnectionCheckDialog = "showConnectionCheckDialog";

export {
    displayImageEvent,
    saveAlteredCaseEvent,
    drawerSelectionChange,
    drawerExpansionChange,
    changePageEvent,
    getFirstPageEvent,
    searchEvent,
    sessionEndedSignalEvent,
    toggleDialogEvent,
    isLoadingSignalEvent,
    isFormFieldsChanged,
    removeLocalAppearance,
    setDialogData,
    updateLocalAppearance,
    wlClickedEvent,
    showConnectionCheckDialog,
    stepperEvent,
};