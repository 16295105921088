import { useCallback } from "react";

import { NavLink, useLocation } from "react-router-dom";
import { List, ListItemButton, ListItemText } from "@mui/material";

function SideNavigationNestedList({ navItem, onNavItemClick }) {
    const currLocation = useLocation();

    const isNavItemActive = useCallback((isActive, navItemChildPath) => {
        const fullPath = currLocation.pathname + currLocation.search;
        return (isActive && fullPath === navItemChildPath) ? 'bg-pie-line-bright-blue border-l-4 border-white rounded font-bold' : '';
    }, [currLocation]);

    return (
        <List component="div" disablePadding>
            {navItem.content?.map((navItemChild, index) => (
                <NavLink key={index} to={navItemChild.path} reloadDocument end>
                    {({ isActive }) => (
                        <ListItemButton className={`text-white hover:bg-pie-line-bright-blue ${isNavItemActive(isActive, navItemChild.path)}`}
                            onClick={() => onNavItemClick(index, navItemChild.path, navItemChild.label)}
                        >
                            <ListItemText primaryTypographyProps={{ className: "side-navigation-txt" }} className="break-all pl-9" primary={navItemChild.label} />
                        </ListItemButton>
                    )}
                </NavLink>
            ))}
        </List>
    );
}

export default SideNavigationNestedList;