import { ApiClient, FramesAndBboxesStoredDataApi } from "smart_cameras_frames_storage_api";

import BaseAPIClient from "./BaseAPIClient";
import { framesStorageRoute } from "../Infrastructure/networkConf";
import store from "../../../appConfig/configureStore";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { setFrame } from "../Redux/Stores/FramesStore";

export const matchFrameIndexVal = 0;

export default class FramesStorageClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(framesStorageRoute, clientInstance);
        this.framesStoredDataApi = new FramesAndBboxesStoredDataApi();
    }

    initialize() {
    }

    async playbackRequest(appearanceId, poiName, frameSequence, color, successFunc) {
        return await this.apiCall("Get appearance playback frames",
            (callback) => this.framesStoredDataApi.getAppearanceFramesData(appearanceId, {
                label: poiName,
                frame_sequence: frameSequence,
                color
            }, callback),
            (error, data, response) => successFunc ? successFunc(error, response, data) : ({ error, response, data }));
    }

    async getPlayback(appearanceId, poiName, color) {
        const frames = {};
        const firstFrameRequest = await this.playbackRequest(appearanceId, poiName, 0, color);
        if (!firstFrameRequest?.error) {
            const framesData = firstFrameRequest?.data?.data;
            if (framesData) {
                frames[matchFrameIndexVal] = framesData?.frame;
                const filteredFrames = framesData.appearance_sequence.filter(sequence => sequence !== 0);
                const framesRequests = filteredFrames.map((frameSequence) =>
                    this.playbackRequest(appearanceId, poiName, frameSequence, color, (error, response, data) => {
                        if (!error) {
                            frames[frameSequence] = data?.data?.frame;
                        }
                    }));
                await Promise.all(framesRequests);
                store.dispatch(setFrame({ frames, appearanceSequence: framesData.appearance_sequence }));
            }
        }
        return parseResponseForUI(firstFrameRequest?.error, firstFrameRequest?.response, firstFrameRequest?.data);
    }

    async deletePlayback(appearanceId) {
        const options = { removeRetained: true };
        return await this.apiCall("Delete playback",
            (callback) => this.framesStoredDataApi.deleteFramesDataForAppearance(appearanceId, options, callback),
            (error, data, response) => parseResponseForUI(error, response, data)
        );
    }
}