import { useSearchParams } from "react-router-dom";

import StartAllCamerasProgress from "@/Components/Cameras/StartAllCamerasProgress";
import PoiImportProgressBar from "@/Components/POIs/ImportPoi/PoiImportProgressBar";
import AppearanceRetainDialog from "../Modals/dialogs/AppearanceRetainDialog";
import AppearanceLabelDialog from "../Modals/dialogs/labelAppearance/AppearanceLabelDialog";
import AppearanceRemoveDialog from "../Modals/dialogs/AppearanceRemoveDialog";
import PlaybackDialog from "@/Components/PlaybackDialog/PlaybackDialog";
import ConnectionCheckDialog from "./ConnectionCheckDialog";
import CreateEditPOIDialog from "@/Components/POIs/CreateEditPOI/CreateEditPOIDialog";
import ProgressStepper from "../ProgressStepper/ProgressStepper";

function GlobalPopups() {
    const [searchParams] = useSearchParams();

    return (
        <div className="flex flex-col">
            <PoiImportProgressBar />
            <StartAllCamerasProgress />
            <AppearanceRemoveDialog />
            <AppearanceRetainDialog />
            <AppearanceLabelDialog />
            <CreateEditPOIDialog searchParams={searchParams} />
            <PlaybackDialog />
            <ConnectionCheckDialog />
            <ProgressStepper />
        </div>
    );
}

export default GlobalPopups;