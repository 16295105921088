import moment from "moment";
import { toast } from 'react-toastify';

import { fullDataTimeFormat } from "../Parsing/timeParsing";

export const ERROR = 'error';
export const SUCCESS = 'success';
export const INFO = 'info';
export const WARNING = 'warning';

export const showError = "showError";
export const showSuccess = "showSuccess";
export const showInfo = "showInfo";
export const showWarning = "showWarning";

const toastDurations = {
    error: 3500,
    success: 3000,
    info: 3000,
    warning: 3500,
};

const showToastUtilHandler = (type, message, options = {}) => {
    const dateStr = moment().format(fullDataTimeFormat);

    console.log(`Client alert: ${type}- ${message}`);
    return toast[type](`${dateStr} - ${message}`, { autoClose: toastDurations[type] || 2500, data: { ...options }, ...options });
};

export const toastUtility = {
    [showError]: (message, ...options) => showToastUtilHandler(ERROR, message, ...options),
    [showSuccess]: (message, ...options) => showToastUtilHandler(SUCCESS, message, ...options),
    [showInfo]: (message, ...options) => showToastUtilHandler(INFO, message, ...options),
    [showWarning]: (message, ...options) => showToastUtilHandler(WARNING, message, ...options),
};

export const useToast = () => {
    const showToast = (type, message, ...options) => {
        showToastUtilHandler(type, message, ...options);
    };

    return {
        showErrorToast: (message, ...options) => showToast(ERROR, message, ...options),
        showSuccessToast: (message, ...options) => showToast(SUCCESS, message, ...options),
        showInfoToast: (message, ...options) => showToast(INFO, message, ...options),
        showWarningToast: (message, ...options) => showToast(WARNING, message, ...options),
    };
};