"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CropData model module.
 * @module model/CropData
 * @version 0.37.1
 */
class CropData {
  /**
   * Constructs a new <code>CropData</code>.
   * @alias module:model/CropData
   */
  constructor() {
    CropData.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>CropData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CropData} obj Optional instance to populate.
   * @return {module:model/CropData} The populated <code>CropData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CropData();
      if (data.hasOwnProperty('frame_id')) {
        obj['frame_id'] = _ApiClient.default.convertToType(data['frame_id'], Object);
      }
      if (data.hasOwnProperty('frame_timestamp')) {
        obj['frame_timestamp'] = _ApiClient.default.convertToType(data['frame_timestamp'], Object);
      }
      if (data.hasOwnProperty('detector_score')) {
        obj['detector_score'] = _ApiClient.default.convertToType(data['detector_score'], Object);
      }
      if (data.hasOwnProperty('signature_payload')) {
        obj['signature_payload'] = _ApiClient.default.convertToType(data['signature_payload'], Object);
      }
      if (data.hasOwnProperty('face_frame_pts')) {
        obj['face_frame_pts'] = _ApiClient.default.convertToType(data['face_frame_pts'], Object);
      }
      if (data.hasOwnProperty('bbox')) {
        obj['bbox'] = _ApiClient.default.convertToType(data['bbox'], Object);
      }
      if (data.hasOwnProperty('yaw')) {
        obj['yaw'] = _ApiClient.default.convertToType(data['yaw'], Object);
      }
      if (data.hasOwnProperty('pitch')) {
        obj['pitch'] = _ApiClient.default.convertToType(data['pitch'], Object);
      }
      if (data.hasOwnProperty('face_score')) {
        obj['face_score'] = _ApiClient.default.convertToType(data['face_score'], Object);
      }
      if (data.hasOwnProperty('masked_score')) {
        obj['masked_score'] = _ApiClient.default.convertToType(data['masked_score'], Object);
      }
      if (data.hasOwnProperty('face_crop_img')) {
        obj['face_crop_img'] = _ApiClient.default.convertToType(data['face_crop_img'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CropData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CropData</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * The frame_id in which this face appeared
 * @member {Object} frame_id
 */
CropData.prototype['frame_id'] = undefined;

/**
 * The timestamp of the frame in which this face appeared
 * @member {Object} frame_timestamp
 */
CropData.prototype['frame_timestamp'] = undefined;

/**
 * score given to this detected bbox by the detector
 * @member {Object} detector_score
 */
CropData.prototype['detector_score'] = undefined;

/**
 * If the system is configured to send sig with the event, it will be the value of this key, encrypted. Otherwise the key will be null
 * @member {Object} signature_payload
 */
CropData.prototype['signature_payload'] = undefined;

/**
 * The presentation timestamp of the face's frame_id
 * @member {Object} face_frame_pts
 */
CropData.prototype['face_frame_pts'] = undefined;

/**
 * the bbox of the best crop
 * @member {Object} bbox
 */
CropData.prototype['bbox'] = undefined;

/**
 * Observed yaw of the face in the image (in degrees)
 * @member {Object} yaw
 */
CropData.prototype['yaw'] = undefined;

/**
 * Observed pitch of the face in the image (in degrees)
 * @member {Object} pitch
 */
CropData.prototype['pitch'] = undefined;

/**
 * A score indicating the quality of the face image
 * @member {Object} face_score
 */
CropData.prototype['face_score'] = undefined;

/**
 * the masked score of the face
 * @member {Object} masked_score
 */
CropData.prototype['masked_score'] = undefined;

/**
 * b64 image of this event. The system sends the best face of this appearance that appeared in the time interval since the previous event for this appearance was sent.
 * @member {Object} face_crop_img
 */
CropData.prototype['face_crop_img'] = undefined;
var _default = exports.default = CropData;