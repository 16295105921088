import { useMemo } from "react";

import CircularProgress from '@mui/material/CircularProgress';

import { trimNumber } from "@/Logic/Parsing/numbersParsing";

function LabeledCircularProgress({ textClassName, isPercentage = true, ...props }) {
    const displayValue = useMemo(() => isPercentage ? `${Math.round(props.value)}%` : trimNumber(props.value, 2), [isPercentage, props.value]);

    return (
        <div className="relative inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <div className={`absolute inset-0 flex items-center justify-center  ${textClassName || "text-sm"}`}>
                <span >
                    {displayValue}
                </span>
            </div>
        </div>
    );
}

export default LabeledCircularProgress;