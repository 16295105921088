import { useEffect, useRef } from "react";

import JSMpeg from "@cycjimmy/jsmpeg-player";

function DisplayPlayerCanvas({ url }) {
    const canvas = useRef(null);

    useEffect(() => {
        if (url) {
            const player = new JSMpeg.Player(url, {
                canvas: canvas.current,
                audio: false,
                pauseWhenHidden: true,
                preserveDrawingBuffer: false,
                loop: false,
                disableWebAssembly: true
            });

            return () => {
                player.destroy();
            };
        }
    }, [canvas, url]);

    return <canvas data-testid="display-canvas" ref={canvas} />;
}

export default DisplayPlayerCanvas;