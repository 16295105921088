"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ListGenerativeInsightsList model module.
 * @module model/ListGenerativeInsightsList
 * @version 0.37.0
 */
class ListGenerativeInsightsList {
  /**
   * Constructs a new <code>ListGenerativeInsightsList</code>.
   * @alias module:model/ListGenerativeInsightsList
   * @param generativeInsights {Object} 
   */
  constructor(generativeInsights) {
    ListGenerativeInsightsList.initialize(this, generativeInsights);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, generativeInsights) {
    obj['generative_insights'] = generativeInsights;
  }

  /**
   * Constructs a <code>ListGenerativeInsightsList</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ListGenerativeInsightsList} obj Optional instance to populate.
   * @return {module:model/ListGenerativeInsightsList} The populated <code>ListGenerativeInsightsList</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ListGenerativeInsightsList();
      if (data.hasOwnProperty('generative_insights')) {
        obj['generative_insights'] = _ApiClient.default.convertToType(data['generative_insights'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ListGenerativeInsightsList</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ListGenerativeInsightsList</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ListGenerativeInsightsList.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
ListGenerativeInsightsList.RequiredProperties = ["generative_insights"];

/**
 * @member {Object} generative_insights
 */
ListGenerativeInsightsList.prototype['generative_insights'] = undefined;
var _default = exports.default = ListGenerativeInsightsList;