import { createSlice } from '@reduxjs/toolkit';

import { CreateEditUserFormFieldsNames } from "@/Components/Settings/Users/CreateEditUser/CreateEditUser.model";
import { SUPERADMIN, SUPPORT, systemRoleNames } from "@/Logic/ApiClients/UsersClient";

export const userSettingDefaultValue = {
    [CreateEditUserFormFieldsNames.active]: true,
};

const initialState = {
    users: [],
    openUser: {
        ...userSettingDefaultValue
    },
    roles: [],
    roleDataFilters: {},
    systemRolesDetail: {
        superadmin: "",
        support: ""
    },
    systemRoleIds: [],
};

const usersSlice = createSlice({
    name: 'usersStore',
    initialState,
    reducers: {
        setRoleDataFilters: (state, action) => {
            const { role_data_filters } = action.payload;
            state.roleDataFilters = role_data_filters;
        },
        setUsers: (state, action) => {
            const users = action.payload;
            state.users = users;

            return state;
        },
        setRoles: (state, action) => {
            const roles = action.payload;
            state.roles = roles;
            state.systemRoleIds = roles.reduce((accumulator, role) => {
                if (systemRoleNames.includes(role.role_name)) {
                    accumulator.push(role.role_id);
                    if (role.role_name === SUPERADMIN) {
                        state.systemRolesDetail.superadmin = role.role_id;
                    } else if (role.role_name === SUPPORT) {
                        state.systemRolesDetail.support = role.role_id;
                    }
                }
                return accumulator;
            }, []);

            return state;
        },
        setOpenUser: (state, action) => {
            const userData = action.payload;
            const userFormFields = {
                [CreateEditUserFormFieldsNames.userName]: userData.name,
                [CreateEditUserFormFieldsNames.password]: userData.password || "",
                confirm_password: "",
                [CreateEditUserFormFieldsNames.fullName]: userData.user_contact_info.full_name || "",
                [CreateEditUserFormFieldsNames.email]: userData.user_contact_info.email || "",
                [CreateEditUserFormFieldsNames.phone]: userData.user_contact_info.phone || "",
                [CreateEditUserFormFieldsNames.roleID]: userData.role_id,
                [CreateEditUserFormFieldsNames.active]: userData.active,
            };

            state.openUser = {
                ...state.openUser,
                ...userFormFields
            };

            return state;
        },
        clearOpenUser: (state) => {
            state.openUser = {
                ...userSettingDefaultValue
            };
        }
    }
});

export const {
    setRoleDataFilters,
    setUsers,
    setRoles,
    setOpenUser,
    clearOpenUser,
} = usersSlice.actions;

export default usersSlice.reducer;