import { createSlice } from "@reduxjs/toolkit";

import { isEqual } from "lodash";

import { eventsFilters } from "src/appConfig/Strings";
import { roleFiltersParser } from "@/Logic/Hooks/usePrepareCreateEditRole";
import { filtersFormFieldsNames } from "@/Components/Events/EventsFeed/EventsFilters.model";
import { allOptionSelected } from "@/Components/Common/FormComponents/FormInputs/RadioEnabledSelect";

const { camerasFilter, matched, unauthorized, detections, facesAttrAgeFilter, facesAttrGenderFilter, facesAttrLivenessFilter, eventFilters } = filtersFormFieldsNames;

export const eventsFiltersDefaultOptions = {
    radioValue: allOptionSelected,
    selectedOptions: []
};

export const eventsFiltersInitialState = {
    [camerasFilter]: eventsFiltersDefaultOptions,
    [matched]: {
        isToggledOn: true,
        ...eventsFiltersDefaultOptions
    },
    [unauthorized]: {
        isToggledOn: true,
        ...eventsFiltersDefaultOptions
    },
    [detections]: {
        isToggledOn: true,
        ...eventsFiltersDefaultOptions
    },
    [facesAttrAgeFilter]: eventsFiltersDefaultOptions,
    [facesAttrGenderFilter]: eventsFiltersDefaultOptions,
    [facesAttrLivenessFilter]: eventsFiltersDefaultOptions,
    mask_outcomes: []
};

const filterModelDefaultValue = {
    filterModel: {
        items: []
    }
};

const initialState = {
    [eventFilters]: eventsFiltersInitialState,
    areFiltersApplied: false,
    roleDataFiltersState: {},
    eventsTableMuiFilters: filterModelDefaultValue,
};

const filtersSlice = createSlice({
    name: "eventsFilters",
    initialState,
    reducers: {
        setEventsFilters: (state, action) => {
            const filtersData = action.payload;
            state[eventFilters] = { ...state[eventFilters], ...filtersData };
            state.areFiltersApplied = !isEqual(state[eventFilters], state.roleDataFiltersState);

            return state;
        },
        updateEventsFilter: (state, action) => {
            const { filterKey, filterData } = action.payload;
            state[eventFilters] = { ...state[eventFilters], [filterKey]: filterData };
            state.areFiltersApplied = !isEqual(state[eventFilters], state.roleDataFiltersState);

            return state;
        },
        setDefaultRoleDataFilters: (state, action) => {
            const { role_data_filters } = action.payload;
            if (role_data_filters[eventsFilters]) {
                const roleDataFilters = roleFiltersParser(role_data_filters[eventsFilters]);
                state.roleDataFiltersState = roleDataFilters;
                state[eventFilters] = roleDataFilters;
                state.areFiltersApplied = false;
            }

            return state;
        },
        setEventsTableMuiFilters: (state, action) => {
            const { filterModel } = action.payload;
            state.eventsTableMuiFilters = {
                filterModel,
            };

            return state;
        },
        updateEventsTableMuiFilters: (state, action) => {
            const { filterItem } = action.payload;
            state.eventsTableMuiFilters = {
                ...state.eventsTableMuiFilters,
                filterModel: {
                    ...state.eventsTableMuiFilters.filterModel,
                    items: [filterItem]
                },
            };

            return state;
        },
        clearEventsTableMuiFilters: (state, action) => {
            state.eventsTableMuiFilters = filterModelDefaultValue;

            return state;
        },
    }
});

export const { setEventsFilters, setDefaultRoleDataFilters, updateEventsFilter, setEventsTableMuiFilters, updateEventsTableMuiFilters, clearEventsTableMuiFilters } = filtersSlice.actions;

export default filtersSlice.reducer;