import { memo, useCallback, useContext } from "react";

import { connect } from "react-redux";
import { Badge } from "@mui/material";

import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { ReactComponent as NotificationsIcon } from "src/assets/Icons/TopBar/icon_notification.svg";

import MainDialog from "../Modals/MainDialog";
import AlertsTable from "@/Components/Common/AlertsCenter/AlertsTable/AlertsTable";
import ApiContext from "@/Logic/Context/apiContext";
import { alertCenterDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";
import { generalSettingsKeys } from "@/Logic/WebWorkers/IndexDBHandler/IndexDBHandler.model";
import { IndexDBHandlerInterface } from "@/Logic/WebWorkers/WorkersInterfaces";
import { alertsCenterHeader, muteLabel, snoozeLabel, unMuteLabel, unSnoozeLabel } from "src/appConfig/Strings";
import TooltipIconButton from "../Buttons/TooltipIconButton";
import AudioManagerContext from "@/Logic/Context/audioManagerContext";
import { clearAlertsAcknowledgement } from "@/Logic/Redux/Stores/EventsStore";

function AlertsCenter({ isAlertsDialogOpen, isAlertsSnoozed, isAlertsSoundMuted, alertedEvents, unacknowledgedAlertsCount, clearAlertsAcknowledgement }) {
    const { clientsManager } = useContext(ApiContext);
    const { audioManager } = useContext(AudioManagerContext);
    const alertCenterDialogSx = { '& .MuiDialog-paper': { maxWidth: '41rem' } };

    const toggleAlertSnoozeHandler = useCallback(() => {
        clientsManager.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.updateGeneralSetting, [generalSettingsKeys.snoozeAlerts, !isAlertsSnoozed]]);
    }, [clientsManager.workersClient.storageWorker, isAlertsSnoozed]);

    const toggleAlertMuteHandler = useCallback(() => {
        audioManager.stop();
        clearAlertsAcknowledgement({ disableAlertsRepeatedSound: true });
        isAlertsSoundMuted ? audioManager.unMuteSound() : audioManager.muteSound();
        clientsManager.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.updateGeneralSetting, [generalSettingsKeys.muteAlertsSound, !isAlertsSoundMuted]]);
    }, [audioManager, clearAlertsAcknowledgement, clientsManager.workersClient.storageWorker, isAlertsSoundMuted]);

    return (
        <MainDialog
            dialogSxClass={alertCenterDialogSx}
            dialogID={alertCenterDialogId}
            dialogContentClassName="p-0"
            triggerIcon={
                <Badge badgeContent={unacknowledgedAlertsCount} color="primary">
                    <NotificationsIcon className="mt-0.5 size-[21px] stroke-white" />
                </Badge>
            }
            headerTitle={alertsCenterHeader}
            headerRightSection={
                <div className="flex items-center gap-1">
                    <TooltipIconButton className="text-white" iconElement={isAlertsSoundMuted ? <VolumeOffIcon /> : < VolumeUpOutlinedIcon />} tooltipText={isAlertsSoundMuted ? unMuteLabel : muteLabel} buttonAction={toggleAlertMuteHandler} />
                    <TooltipIconButton className="text-white" iconElement={isAlertsSnoozed ? < NotificationsOffIcon /> : <NotificationsActiveIcon />} tooltipText={isAlertsSnoozed ? unSnoozeLabel : snoozeLabel} buttonAction={toggleAlertSnoozeHandler} />
                </div>
            }
            isFooterVisible={false}
        >
            {isAlertsDialogOpen && (
                <div className="h-inner-stepper w-full">
                    <AlertsTable alertedEvents={alertedEvents} />
                </div>
            )}
        </MainDialog>
    );
}

const mapActionsToProps = {
    clearAlertsAcknowledgement
};

const mapStateToProps = (state) => {
    const isAlertsDialogOpen = state.GlobalStore.dialogOpenStatus[alertCenterDialogId];
    const isAlertsSnoozed = state.ApiActionsStore?.generalSettings[generalSettingsKeys.snoozeAlerts];
    const isAlertsSoundMuted = state.ApiActionsStore?.generalSettings[generalSettingsKeys.muteAlertsSound];
    const { alertedEvents, unacknowledgedAlertsCount } = state.EventsStore;

    return { isAlertsDialogOpen, isAlertsSnoozed, isAlertsSoundMuted, unacknowledgedAlertsCount, alertedEvents };
};

export default connect(mapStateToProps, mapActionsToProps)(memo(AlertsCenter));