import { days, hours, minutes, seconds, weeks } from "src/appConfig/Strings";

export function valueToTimeMapping(value, inputMap) {
    return Object.keys(inputMap).reduce((acc, key) => {
        if (value >= inputMap[key].unitValue) {
            acc[key] = Math.floor(value / inputMap[key].unitValue);
            value %= inputMap[key].unitValue;
        } else if (!acc[key]) {
            acc[key] = 0;
        }
        return acc;
    }, {});
}

export function timeMappingToValue(timeUnits, inputMap) {
    return Object.keys(timeUnits).reduce((acc, key) => {
        if (inputMap[key]) {
            acc += timeUnits[key] * inputMap[key].unitValue;
        }
        return acc;
    }, 0);
}

export const daysInputMap = {
    [weeks]: {
        unitValue: 7,
    },
    [days]: {
        unitValue: 1,
    }
};

export const secondsInputMap = {
    [weeks]: {
        unitValue: 604800,
    },
    [days]: {
        unitValue: 86400,
    },
    [hours]: {
        unitValue: 3600,
    },
    [minutes]: {
        unitValue: 60,
    }
};

export const minSecTimeMapping = {
    [minutes]: {
        unitValue: 60,
    },
    [seconds]: {
        unitValue: 1,
    }
};

export const daysHoursMinSecTimeMapping = {
    [days]: {
        unitValue: 86400,
    },
    [hours]: {
        unitValue: 3600,
    },
    [minutes]: {
        unitValue: 60,
    },
    [seconds]: {
        unitValue: 1,
    }
};

export const DurationSelectorModel = {
    type: "number",
    required: true,
    min: 0,
};

export const timeDeltaModel = {
    [hours]: {
        unitValue: 3600,
    },
    [minutes]: {
        unitValue: 60,
    },
    [seconds]: {
        unitValue: 1,
    }
};