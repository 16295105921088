import { Modal } from "@mui/material";

import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";

function BaseModal({ modalId = "", isOpen, children, headerTitle, onClickHeaderCancel, className, ...footerProps }) {
    return (
        <Modal id={modalId} open={isOpen} className="flex items-center justify-center whitespace-pre-line text-center ">
            <div className={`flex h-fit w-main-modal-width flex-col rounded bg-white ${className}`}>
                <ModalHeader headerTitle={headerTitle} handleCancelClick={onClickHeaderCancel} />
                <main className="flex grow flex-col justify-between overflow-auto px-4 pt-4">
                    {children}
                </main>
                <ModalFooter className="shrink-0" {...footerProps} />
            </div>
        </Modal>
    );
}

export default BaseModal;