"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CameraNotes = _interopRequireDefault(require("./CameraNotes"));
var _CameraStatusData = _interopRequireDefault(require("./CameraStatusData"));
var _CaptureStreamConfig = _interopRequireDefault(require("./CaptureStreamConfig"));
var _Config = _interopRequireDefault(require("./Config"));
var _PrivacyProfileProperties = _interopRequireDefault(require("./PrivacyProfileProperties1"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Camera model module.
 * @module model/Camera
 * @version 0.32.2
 */
class Camera {
  /**
   * Constructs a new <code>Camera</code>.
   * @alias module:model/Camera
   * @param captureConfig {module:model/CaptureStreamConfig} 
   */
  constructor(captureConfig) {
    Camera.initialize(this, captureConfig);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, captureConfig) {
    obj['capture_config'] = captureConfig;
  }

  /**
   * Constructs a <code>Camera</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Camera} obj Optional instance to populate.
   * @return {module:model/Camera} The populated <code>Camera</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Camera();
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('camera_notes')) {
        obj['camera_notes'] = _CameraNotes.default.constructFromObject(data['camera_notes']);
      }
      if (data.hasOwnProperty('capture_config')) {
        obj['capture_config'] = _CaptureStreamConfig.default.constructFromObject(data['capture_config']);
      }
      if (data.hasOwnProperty('config')) {
        obj['config'] = _Config.default.constructFromObject(data['config']);
      }
      if (data.hasOwnProperty('persist')) {
        obj['persist'] = _ApiClient.default.convertToType(data['persist'], 'Boolean');
      }
      if (data.hasOwnProperty('node_id')) {
        obj['node_id'] = _ApiClient.default.convertToType(data['node_id'], 'String');
      }
      if (data.hasOwnProperty('privacy_profile_properties')) {
        obj['privacy_profile_properties'] = _PrivacyProfileProperties.default.constructFromObject(data['privacy_profile_properties']);
      }
      if (data.hasOwnProperty('camera_status')) {
        obj['camera_status'] = _CameraStatusData.default.constructFromObject(data['camera_status']);
      }
      if (data.hasOwnProperty('display_rtsp_address')) {
        obj['display_rtsp_address'] = _ApiClient.default.convertToType(data['display_rtsp_address'], 'String');
      }
      if (data.hasOwnProperty('camera_id')) {
        obj['camera_id'] = _ApiClient.default.convertToType(data['camera_id'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Camera</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Camera</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Camera.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // validate the optional field `camera_notes`
    if (data['camera_notes']) {
      // data not null
      _CameraNotes.default.validateJSON(data['camera_notes']);
    }
    // validate the optional field `capture_config`
    if (data['capture_config']) {
      // data not null
      _CaptureStreamConfig.default.validateJSON(data['capture_config']);
    }
    // validate the optional field `config`
    if (data['config']) {
      // data not null
      _Config.default.validateJSON(data['config']);
    }
    // ensure the json data is a string
    if (data['node_id'] && !(typeof data['node_id'] === 'string' || data['node_id'] instanceof String)) {
      throw new Error("Expected the field `node_id` to be a primitive type in the JSON string but got " + data['node_id']);
    }
    // validate the optional field `privacy_profile_properties`
    if (data['privacy_profile_properties']) {
      // data not null
      _PrivacyProfileProperties.default.validateJSON(data['privacy_profile_properties']);
    }
    // validate the optional field `camera_status`
    if (data['camera_status']) {
      // data not null
      _CameraStatusData.default.validateJSON(data['camera_status']);
    }
    // ensure the json data is a string
    if (data['display_rtsp_address'] && !(typeof data['display_rtsp_address'] === 'string' || data['display_rtsp_address'] instanceof String)) {
      throw new Error("Expected the field `display_rtsp_address` to be a primitive type in the JSON string but got " + data['display_rtsp_address']);
    }
    // ensure the json data is a string
    if (data['camera_id'] && !(typeof data['camera_id'] === 'string' || data['camera_id'] instanceof String)) {
      throw new Error("Expected the field `camera_id` to be a primitive type in the JSON string but got " + data['camera_id']);
    }
    return true;
  }
}
Camera.RequiredProperties = ["capture_config"];

/**
 * The name given to the camera
 * @member {String} description
 */
Camera.prototype['description'] = undefined;

/**
 * @member {module:model/CameraNotes} camera_notes
 */
Camera.prototype['camera_notes'] = undefined;

/**
 * @member {module:model/CaptureStreamConfig} capture_config
 */
Camera.prototype['capture_config'] = undefined;

/**
 * @member {module:model/Config} config
 */
Camera.prototype['config'] = undefined;

/**
 * @member {Boolean} persist
 * @default true
 */
Camera.prototype['persist'] = true;

/**
 * the node identifier of the machine the camera is associated with.
 * @member {String} node_id
 */
Camera.prototype['node_id'] = undefined;

/**
 * @member {module:model/PrivacyProfileProperties1} privacy_profile_properties
 */
Camera.prototype['privacy_profile_properties'] = undefined;

/**
 * @member {module:model/CameraStatusData} camera_status
 */
Camera.prototype['camera_status'] = undefined;

/**
 * @member {String} display_rtsp_address
 */
Camera.prototype['display_rtsp_address'] = undefined;

/**
 * a unique record id of the camera
 * @member {String} camera_id
 */
Camera.prototype['camera_id'] = undefined;
var _default = exports.default = Camera;