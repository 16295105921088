"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CaptureStreamConfig model module.
 * @module model/CaptureStreamConfig
 * @version 0.32.2
 */
class CaptureStreamConfig {
  /**
   * Constructs a new <code>CaptureStreamConfig</code>.
   * @alias module:model/CaptureStreamConfig
   * @param mode {String} mode of the camera. Possible options: ['RTSP', 'Video', 'Device', 'Edge']
   */
  constructor(mode) {
    CaptureStreamConfig.initialize(this, mode);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, mode) {
    obj['mode'] = mode;
  }

  /**
   * Constructs a <code>CaptureStreamConfig</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CaptureStreamConfig} obj Optional instance to populate.
   * @return {module:model/CaptureStreamConfig} The populated <code>CaptureStreamConfig</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CaptureStreamConfig();
      if (data.hasOwnProperty('capture_address')) {
        obj['capture_address'] = _ApiClient.default.convertToType(data['capture_address'], 'String');
      }
      if (data.hasOwnProperty('rebroadcast_frame_width')) {
        obj['rebroadcast_frame_width'] = _ApiClient.default.convertToType(data['rebroadcast_frame_width'], 'Number');
      }
      if (data.hasOwnProperty('camera_stream_password')) {
        obj['camera_stream_password'] = _ApiClient.default.convertToType(data['camera_stream_password'], 'String');
      }
      if (data.hasOwnProperty('camera_stream_username')) {
        obj['camera_stream_username'] = _ApiClient.default.convertToType(data['camera_stream_username'], 'String');
      }
      if (data.hasOwnProperty('capture_port')) {
        obj['capture_port'] = _ApiClient.default.convertToType(data['capture_port'], 'Number');
      }
      if (data.hasOwnProperty('in_fps')) {
        obj['in_fps'] = _ApiClient.default.convertToType(data['in_fps'], 'Number');
      }
      if (data.hasOwnProperty('min_detection_width')) {
        obj['min_detection_width'] = _ApiClient.default.convertToType(data['min_detection_width'], 'Number');
      }
      if (data.hasOwnProperty('mode')) {
        obj['mode'] = _ApiClient.default.convertToType(data['mode'], 'String');
      }
      if (data.hasOwnProperty('stream_max_faces')) {
        obj['stream_max_faces'] = _ApiClient.default.convertToType(data['stream_max_faces'], 'Number');
      }
      if (data.hasOwnProperty('video_settings')) {
        obj['video_settings'] = _ApiClient.default.convertToType(data['video_settings'], Object);
      }
      if (data.hasOwnProperty('analyze')) {
        obj['analyze'] = _ApiClient.default.convertToType(data['analyze'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CaptureStreamConfig</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CaptureStreamConfig</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CaptureStreamConfig.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['capture_address'] && !(typeof data['capture_address'] === 'string' || data['capture_address'] instanceof String)) {
      throw new Error("Expected the field `capture_address` to be a primitive type in the JSON string but got " + data['capture_address']);
    }
    // ensure the json data is a string
    if (data['camera_stream_password'] && !(typeof data['camera_stream_password'] === 'string' || data['camera_stream_password'] instanceof String)) {
      throw new Error("Expected the field `camera_stream_password` to be a primitive type in the JSON string but got " + data['camera_stream_password']);
    }
    // ensure the json data is a string
    if (data['camera_stream_username'] && !(typeof data['camera_stream_username'] === 'string' || data['camera_stream_username'] instanceof String)) {
      throw new Error("Expected the field `camera_stream_username` to be a primitive type in the JSON string but got " + data['camera_stream_username']);
    }
    // ensure the json data is a string
    if (data['mode'] && !(typeof data['mode'] === 'string' || data['mode'] instanceof String)) {
      throw new Error("Expected the field `mode` to be a primitive type in the JSON string but got " + data['mode']);
    }
    return true;
  }
}
CaptureStreamConfig.RequiredProperties = ["mode"];

/**
 * the accessible address of the camera
 * @member {String} capture_address
 */
CaptureStreamConfig.prototype['capture_address'] = undefined;

/**
 * frame width of the RTSP rebroadcasting
 * @member {Number} rebroadcast_frame_width
 */
CaptureStreamConfig.prototype['rebroadcast_frame_width'] = undefined;

/**
 * Optional pwd of credentials associated with camera
 * @member {String} camera_stream_password
 */
CaptureStreamConfig.prototype['camera_stream_password'] = undefined;

/**
 * Optional username of credentials associated with camera
 * @member {String} camera_stream_username
 */
CaptureStreamConfig.prototype['camera_stream_username'] = undefined;

/**
 * @member {Number} capture_port
 */
CaptureStreamConfig.prototype['capture_port'] = undefined;

/**
 * FPS sampling of the camera
 * @member {Number} in_fps
 */
CaptureStreamConfig.prototype['in_fps'] = undefined;

/**
 * min face width to accept from detector.
 * @member {Number} min_detection_width
 */
CaptureStreamConfig.prototype['min_detection_width'] = undefined;

/**
 * mode of the camera. Possible options: ['RTSP', 'Video', 'Device', 'Edge']
 * @member {String} mode
 */
CaptureStreamConfig.prototype['mode'] = undefined;

/**
 * maximum number of faces to detect per frame.
 * @member {Number} stream_max_faces
 */
CaptureStreamConfig.prototype['stream_max_faces'] = undefined;

/**
 * video_settings
 * @member {Object} video_settings
 */
CaptureStreamConfig.prototype['video_settings'] = undefined;

/**
 * is this camera currently required to analyze
 * @member {Boolean} analyze
 */
CaptureStreamConfig.prototype['analyze'] = undefined;
var _default = exports.default = CaptureStreamConfig;