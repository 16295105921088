import { useCallback, useEffect, useRef } from "react";

import { ReactComponent as FolderIcon } from "src/assets/Icons/UtilsIcons/icon_btn_folder.svg";

import { Alert, Button } from "@mui/material";

import { imageToBase64 } from "@/Logic/Parsing/parseImages";
import { uploadImageLabel, uploadImageNote, usePicLabel } from "src/appConfig/Strings";
import ImageWrapper from "@/Components/Common/Wrappers/ImageWrapper";
import { progressStepperInterface } from "../ProgressStepper.model";

export const imgUploadInterface = {
    imgData: "imgData"
};

function ImgUploadStep({ currData, setCurrData }) {
    const fileInputRef = useRef(null);

    const triggerFileClick = useCallback(() => {
        if (!fileInputRef.current) {
            return;
        }

        fileInputRef.current.click();
    }, []);

    const handleUploadImage = useCallback(async (event) => {
        if (!event.target.files?.length) {
            return;
        }

        const [image] = event.target.files;
        const imageInBase64 = await imageToBase64(URL.createObjectURL(image));

        const newData = {
            [imgUploadInterface.imgData]: imageInBase64,
            [progressStepperInterface.rightBtnText]: usePicLabel,
        };
        setCurrData({ newCompData: newData, newIsFinal: true });
        fileInputRef.current.value = null;
    }, [setCurrData]);

    const handleFolderIconClick = useCallback((event) => {
        event.preventDefault();
        triggerFileClick();
    }, [triggerFileClick]);

    useEffect(() => {
        triggerFileClick();
    }, [triggerFileClick]);

    return (
        <div className="flex size-full flex-col items-center justify-center">
            <section className="mt-0.5 flex w-full grow items-center justify-center">
                {currData.imgData ? (
                    <ImageWrapper className="max-h-[33rem]" src={currData.imgData} />
                ) : (
                    <div className="flex size-full items-center justify-center">
                        <Alert className="main-text-style w-full items-center justify-center" severity="info">
                            {uploadImageNote}
                        </Alert>
                    </div>
                )
                }
            </section>
            <section className="flex items-center justify-center">
                <Button
                    className="normal-case"
                    component="label"
                    variant="text"
                    onClick={handleFolderIconClick}
                    endIcon={<FolderIcon className="stroke-black" />}
                >
                    {uploadImageLabel}
                </Button>
                <input
                    className="hidden"
                    ref={fileInputRef}
                    id="upload-image"
                    name="upload"
                    type="file"
                    accept="image/*"
                    onChange={handleUploadImage}
                />
            </section>
        </div>
    );
}

export default ImgUploadStep;