"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Camera = _interopRequireDefault(require("./Camera"));
var _ListAllSummaryModel = _interopRequireDefault(require("./ListAllSummaryModel"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ListAllCamerasRespnseModel model module.
 * @module model/ListAllCamerasRespnseModel
 * @version 0.32.2
 */
class ListAllCamerasRespnseModel {
  /**
   * Constructs a new <code>ListAllCamerasRespnseModel</code>.
   * @alias module:model/ListAllCamerasRespnseModel
   * @param cameras {Array.<module:model/Camera>} 
   * @param summary {module:model/ListAllSummaryModel} 
   */
  constructor(cameras, summary) {
    ListAllCamerasRespnseModel.initialize(this, cameras, summary);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, cameras, summary) {
    obj['cameras'] = cameras;
    obj['summary'] = summary;
  }

  /**
   * Constructs a <code>ListAllCamerasRespnseModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ListAllCamerasRespnseModel} obj Optional instance to populate.
   * @return {module:model/ListAllCamerasRespnseModel} The populated <code>ListAllCamerasRespnseModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ListAllCamerasRespnseModel();
      if (data.hasOwnProperty('cameras')) {
        obj['cameras'] = _ApiClient.default.convertToType(data['cameras'], [_Camera.default]);
      }
      if (data.hasOwnProperty('summary')) {
        obj['summary'] = _ListAllSummaryModel.default.constructFromObject(data['summary']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ListAllCamerasRespnseModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ListAllCamerasRespnseModel</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ListAllCamerasRespnseModel.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    if (data['cameras']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['cameras'])) {
        throw new Error("Expected the field `cameras` to be an array in the JSON data but got " + data['cameras']);
      }
      // validate the optional field `cameras` (array)
      for (const item of data['cameras']) {
        _Camera.default.validateJsonObject(item);
      }
      ;
    }
    // validate the optional field `summary`
    if (data['summary']) {
      // data not null
      _ListAllSummaryModel.default.validateJSON(data['summary']);
    }
    return true;
  }
}
ListAllCamerasRespnseModel.RequiredProperties = ["cameras", "summary"];

/**
 * @member {Array.<module:model/Camera>} cameras
 */
ListAllCamerasRespnseModel.prototype['cameras'] = undefined;

/**
 * @member {module:model/ListAllSummaryModel} summary
 */
ListAllCamerasRespnseModel.prototype['summary'] = undefined;
var _default = exports.default = ListAllCamerasRespnseModel;