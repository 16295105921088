import { ApiClient, AppearancesApi, InsightsApi } from "smart_cameras_history_api";

import { minAppearances, sortOptions } from "src/appConfig/Strings";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { resultsPerPage } from "src/appConfig/constants";
import { historyRoute } from "../Infrastructure/networkConf";
import BaseAPIClient, { resCodes } from "./BaseAPIClient";
import { HistoryClient } from "./HistoryClient";
import { inactiveMinAppearance } from "../../Components/Cases/Inputs/Filters/MinAppearanceFilter";

export class InsightsClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(historyRoute, clientInstance);
        this.insightsApi = new InsightsApi();
        this.appearancesApi = new AppearancesApi();
    }

    async getInsights(caseData, pageId) {
        const parsedRequest = HistoryClient.parseHistoryQuery(caseData);
        if (caseData[minAppearances] !== inactiveMinAppearance)
            parsedRequest.min_number_of_appearances = caseData[minAppearances];
        const opts = {
            "afterId": pageId,
            "limit": resultsPerPage,
            "sortOption": caseData[sortOptions]
        };
        const insightsRequest = await this.apiCall("Search Insights",
            (callback) => this.insightsApi.getPersons(parsedRequest, opts, callback),
            async (error, data, response) => parseResponseForUI(error, response, await this.serializeApiData(data.data.matches, (record) => record.person_id)),
            {
                [resCodes.badRequest]: (error, _, response) => parseResponseForUI(error, response),
                [resCodes.forbidden]: (error, _, response) => parseResponseForUI(error, response)
            }
        );
        const insightsAnalytics = await this.countInsightsAppearances(parsedRequest, pageId);
        return {
            ...insightsRequest,
            data: {
                records: insightsRequest?.data,
                counters: insightsAnalytics?.data,
                caseData: caseData
            }
        };
    }

    async countInsightsAppearances(parsedRequest, pageId) {
        if (pageId !== "") {
            return;
        }

        const [params, queryParams] = HistoryClient.parseCountRequest(parsedRequest);
        return await this.apiCall("Count Insights Appearances",
            (callback) => this.appearancesApi.countAppearances(params, queryParams, callback),
            (_, data) => HistoryClient.handleSuccessfulCountAppearances(data)
        );
    }
}