import { AddCameraParams, ApiClient, CamerasApi, CaptureStreamConfigParams, Config, FeaturesMinFaceWidths, PrivacyProfileProperties, UpdateCaptureStreamConfig, CameraNotes, WatchlistIdParameter } from "smart_cameras_cameras_api";

import _, { isEmpty } from 'lodash';

import store from "src/appConfig/configureStore";
import BaseAPIClient, { resCodes } from "./BaseAPIClient";
import { removeCamera, setCameras, setOpenCamera, updateCamera } from "../Redux/Stores/CamerasStore";
import { startCameraFailed, startCameraSuccess, stopCameraSuccess } from "src/appConfig/Strings";
import { updatePropertyIfPresent } from "../Parsing/objectsParsing";
import { collectMultiResponses, parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { toastUtility } from "../Hooks/useToast";
import { RequiredFaceAttributesConfig, cameraFormFieldsNames, capturingSourceMode } from "@/Components/Cameras/CreateCamera/CreateEditCamera/CreateEditCamera.model";
import { camerasRoute } from "../Infrastructure/networkConf";
import { getFileNameFromPath } from "../Parsing/stringsParsing";

// todo-sdk
export const cameraStatuses = {
    idle: 0,
    active: 1,
    aborted: 3,
    EOF: 4,
    analyze_timeout: 5,
    input_error: 6,
    attempting_reconnect: 7,
    error_starting: 8,
    error_stopping: 9,
    non_responsive: 10,
    display: 11,
    serverError: 12
};
export const errorStatuses = [cameraStatuses.analyze_timeout, cameraStatuses.input_error, cameraStatuses.error_starting, cameraStatuses.error_stopping, cameraStatuses.non_responsive, cameraStatuses.serverError];
export const nonActiveCapturingStatuses = [cameraStatuses.idle, cameraStatuses.error_starting, cameraStatuses.aborted, cameraStatuses.EOF];
export const activeCapturingStatuses = [cameraStatuses.active, cameraStatuses.display, cameraStatuses.attempting_reconnect, cameraStatuses.serverError];
const { cameraName, analysisQuality, watchlists, captureAddress, cameraStreamUsername, cameraStreamPassword, nodeId, requiredFaceAttributes, cameraNotes, recordFrames, minDetectionWidth, authorizedMinFaceWidth, ageGenderMinWidth, livenessMinWidth, videoDisplayResolution, privacyProfileId, streamMaxFaces, threshold } = cameraFormFieldsNames;

export class CamerasClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(camerasRoute, clientInstance);
        this.camerasApi = new CamerasApi();
    }

    initialize() {
        this.listAllCameras();
    }

    async #handleListAllCameras(camerasData) {
        const cameras = await this.serializeApiData(camerasData.data.cameras, (camera) => camera.camera_id);
        store.dispatch(setCameras(cameras));
    }

    async listAllCameras() {
        await this.apiCall("List all cameras",
            (callback) => this.camerasApi.listAllCameras({ includeAllDbCameras: true }, callback),
            (_, data) => this.#handleListAllCameras(data)
        );
    }

    #handleUpdateStartCamera(camera) {
        store.dispatch(updateCamera({ camera }));
    }

    async startCamera(cameraId) {
        return await this.apiCall("Start Camera",
            (callback) => this.camerasApi.startCamera(cameraId, { "analyze": true }, callback),
            (error, data, response) => {
                this.#handleUpdateStartCamera(data.data);
                toastUtility.showSuccess(`${startCameraSuccess}: ${data.data.description}`);
                return parseResponseForUI(error, response, data.data);
            },
            {
                [resCodes.internalServerError]: (error, _, response) => {
                    const camera = response.body.data;
                    toastUtility.showError(`${camera.description}: ${response.body.metadata.msg}`);
                    return parseResponseForUI(error, response, response.body.data);
                },
                [resCodes.serviceUnavailable]: (error, _, response) => {
                    const camera = response.body.data;
                    this.#handleUpdateStartCamera(camera);
                    toastUtility.showError(`${startCameraFailed}: ${camera.description}`);
                    return parseResponseForUI(error, response, response.body.data);
                }
            }
        );
    }

    async stopCamera(cameraId, alertOnSuccess = true) {
        return await this.apiCall("Stop Camera",
            (callback) => this.camerasApi.stopCamera(cameraId, callback),
            (_, data) => {
                this.#handleUpdateStartCamera(data.data);
                alertOnSuccess && toastUtility.showSuccess(`${stopCameraSuccess}: ${data.data.description}`);
            }
        );
    }

    #parseCameraObject(cameraObject, isCreate = true) {
        const createCameraData = new AddCameraParams();
        parseCaptureConfig(cameraObject, createCameraData, isCreate);
        parseCameraConfig(cameraObject, createCameraData);
        updatePropertyIfPresent(cameraObject, nodeId, createCameraData);
        updatePropertyIfPresent(cameraObject, cameraName, createCameraData);
        isCreate && updatePropertyIfPresent(cameraObject, privacyProfileId, createCameraData, (privacyProfileId) => new PrivacyProfileProperties(privacyProfileId));
        updatePropertyIfPresent(cameraObject, cameraNotes, createCameraData, (cameraNotes) => {
            const notesObject = new CameraNotes();
            notesObject.free_notes = cameraNotes;
            return notesObject;
        });

        return createCameraData;
    }

    async createCamera(cameraObject) {
        const isFileCamera = cameraObject.mode == capturingSourceMode.file;
        let response;
        if (isFileCamera) {
            response = this.createFileCameras(cameraObject);
        } else {
            response = this.createSingleCamera(cameraObject);
        }

        return response;
    }

    async createFileCameras(cameraObject) {
        let res = {};
        const captureAddresses = new Set(cameraObject.capture_address);
        const isMultipleCreate = captureAddresses.size > 1;

        for (const captureAddress of captureAddresses) {
            const fileCameraObject = {
                ...cameraObject,
                [cameraFormFieldsNames.captureAddress]: captureAddress,
            };
            if (isMultipleCreate) {
                fileCameraObject[cameraFormFieldsNames.cameraName] = getFileNameFromPath(captureAddress);
            }

            res = await this.createSingleCamera(fileCameraObject);
            if (res.error) {
                return res;
            }
        }
        return res;
    }

    async createSingleCamera(cameraObject) {
        const cameraParams = this.#parseCameraObject(cameraObject);
        return await this.apiCall("Create Camera",
            (callback) => this.camerasApi.createCamera(cameraParams, callback),
            (error, __, response) => parseResponseForUI(error, response)
        );
    }

    async editCamera(cameraId, cameraObject, oldPrivacyProfile) {
        const cameraParams = this.#parseCameraObject(cameraObject, false);
        let editCamera, editCameraPrivacyProfile;

        if (!isEmpty(cameraParams)) {
            editCamera = await this.apiCall("Edit Camera",
                (callback) => this.camerasApi.updateCamera(cameraId, cameraParams, callback),
                (error, __, response, endPointName) => parseResponseForUI(error, response, undefined, true, endPointName)
            );
        }

        if (cameraObject.privacy_profile_properties && cameraObject.privacy_profile_properties !== oldPrivacyProfile) {
            editCameraPrivacyProfile = await this.apiCall("Edit Camera Privacy Profile",
                (callback) => this.camerasApi.setCameraPrivacy(cameraId, new PrivacyProfileProperties(cameraObject.privacy_profile_properties), callback),
                (error, _, response, endPointName) => parseResponseForUI(error, response, undefined, true, endPointName));
        }
        const finalResponse = collectMultiResponses([editCamera, editCameraPrivacyProfile]);

        return finalResponse;
    }

    async removeCamera(cameraId) {
        return await this.apiCall("Remove Camera",
            (callback) => this.camerasApi.removeCamera(cameraId, callback),
            (error, __, response) => this.#handleRemoveCamera(error, response, cameraId)
        );
    }

    #handleRemoveCamera(error, response, cameraId) {
        store.dispatch(removeCamera(cameraId));
        return parseResponseForUI(error, response);
    }

    #handleSuccessfulGetCamera(data) {
        const camera = data.data;
        store.dispatch(updateCamera({ camera }));
        return camera;
    }

    async GetCamera(cameraId) {
        return await this.apiCall("Get Camera",
            (callback) => this.camerasApi.getCamera(cameraId, callback),
            (_, data) => {
                this.#handleSuccessfulGetCamera(data);
                const payload = data.data;
                store.dispatch(setOpenCamera(payload));
            });
    }
}

function parseCaptureConfig(cameraObject, updatedCameraData, isCreate = true) {
    const captureConfig = isCreate ? new CaptureStreamConfigParams(cameraObject.mode) : new UpdateCaptureStreamConfig();
    updatePropertyIfPresent(cameraObject, minDetectionWidth, captureConfig);
    updatePropertyIfPresent(cameraObject, streamMaxFaces, captureConfig);
    updatePropertyIfPresent(cameraObject, videoDisplayResolution, captureConfig);
    updatePropertyIfPresent(cameraObject, captureAddress, captureConfig);
    updatePropertyIfPresent(cameraObject, cameraStreamPassword, captureConfig);
    updatePropertyIfPresent(cameraObject, cameraStreamUsername, captureConfig);
    if (!isEmpty(captureConfig)) {
        updatedCameraData.capture_config = captureConfig;
    } else {
        delete updatedCameraData.capture_config;
    }
}

function parseCameraConfig(cameraObject, updatedCameraData) {
    const config = new Config();
    updatePropertyIfPresent(cameraObject, threshold, config);
    updatePropertyIfPresent(cameraObject, authorizedMinFaceWidth, config);
    updatePropertyIfPresent(cameraObject, analysisQuality, config);
    updatePropertyIfPresent(cameraObject, recordFrames, config);
    updatePropertyIfPresent(cameraObject, watchlists, config, (WatchlistIDsSet) => parseWatchlistSetToArray(WatchlistIDsSet));
    updatePropertyIfPresent(cameraObject, requiredFaceAttributes, config, (faceAttributes) => parseFaceAttributesToList(faceAttributes));

    const featuresMinFaceWidth = new FeaturesMinFaceWidths();
    updatePropertyIfPresent(cameraObject, ageGenderMinWidth, featuresMinFaceWidth);
    updatePropertyIfPresent(cameraObject, livenessMinWidth, featuresMinFaceWidth);

    if (!_.isEmpty(featuresMinFaceWidth)) {
        config.features_min_face_widths = featuresMinFaceWidth;
    }

    if (!_.isEmpty(config)) {
        updatedCameraData.config = config;
    }
}

function parseFaceAttributesToObj(cameraFaceAttributesList) {
    return Object.keys(RequiredFaceAttributesConfig).reduce((accumulator, key) => {
        const attribute = RequiredFaceAttributesConfig[key];
        accumulator[attribute] = cameraFaceAttributesList.includes(attribute);
        return accumulator;
    }, {});
}

function parseFaceAttributesToList(parsedAttributes) {
    return Object.keys(parsedAttributes).reduce((accumulator, key) => {
        if (parsedAttributes[key]) {
            accumulator.push(key);
        }
        return accumulator;
    }, []);
}

function parseWatchlistSetToArray(watchlistIDsSet) {
    return Array.from(watchlistIDsSet).map(id => new WatchlistIdParameter(id));
}

function areCamerasInStatusList(cameras, statusList = activeCapturingStatuses) {
    return Object.values(cameras).some((camera) => statusList.includes(camera.camera_status.status));
}

function getCamerasNames(camerasIdsList = []) {
    const { cameras } = store.getState().CamerasStore;
    const camerasNames = camerasIdsList.map((cameraId) => {
        const cameraData = cameras[cameraId];
        return cameraData ? cameraData.description : cameraId;
    });

    return camerasNames;
}

function getIsCameraInInvestigateMode(analysisMode, investigateAnalysisMode) {
    return analysisMode === investigateAnalysisMode;
}

export { parseFaceAttributesToObj, parseFaceAttributesToList, parseWatchlistSetToArray, areCamerasInStatusList, getCamerasNames, getIsCameraInInvestigateMode };