"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AppearanceFrameData model module.
 * @module model/AppearanceFrameData
 * @version 0.37.1
 */
class AppearanceFrameData {
  /**
   * Constructs a new <code>AppearanceFrameData</code>.
   * @alias module:model/AppearanceFrameData
   */
  constructor() {
    AppearanceFrameData.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>AppearanceFrameData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AppearanceFrameData} obj Optional instance to populate.
   * @return {module:model/AppearanceFrameData} The populated <code>AppearanceFrameData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AppearanceFrameData();
      if (data.hasOwnProperty('utc_time_recorded')) {
        obj['utc_time_recorded'] = _ApiClient.default.convertToType(data['utc_time_recorded'], Object);
      }
      if (data.hasOwnProperty('utc_time_zone')) {
        obj['utc_time_zone'] = _ApiClient.default.convertToType(data['utc_time_zone'], Object);
      }
      if (data.hasOwnProperty('frame_id')) {
        obj['frame_id'] = _ApiClient.default.convertToType(data['frame_id'], Object);
      }
      if (data.hasOwnProperty('frame_width')) {
        obj['frame_width'] = _ApiClient.default.convertToType(data['frame_width'], Object);
      }
      if (data.hasOwnProperty('frame_height')) {
        obj['frame_height'] = _ApiClient.default.convertToType(data['frame_height'], Object);
      }
      if (data.hasOwnProperty('bounding_box')) {
        obj['bounding_box'] = _ApiClient.default.convertToType(data['bounding_box'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AppearanceFrameData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AppearanceFrameData</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * Time of the frame that generated the event. Important note: For Cameras that analyze in forensics mode, this time is not actually the utc time but the calculated time in the video
 * @member {Object} utc_time_recorded
 */
AppearanceFrameData.prototype['utc_time_recorded'] = undefined;

/**
 * @member {Object} utc_time_zone
 */
AppearanceFrameData.prototype['utc_time_zone'] = undefined;

/**
 * The frame_id of the frame in which the event was generated
 * @member {Object} frame_id
 */
AppearanceFrameData.prototype['frame_id'] = undefined;

/**
 * The width of the original frame
 * @member {Object} frame_width
 */
AppearanceFrameData.prototype['frame_width'] = undefined;

/**
 * The height of the original frame
 * @member {Object} frame_height
 */
AppearanceFrameData.prototype['frame_height'] = undefined;

/**
 * location of bounding box of the face generating this crop within its original frame so that (x1, y1) = top left corner, (x2, y2) = bottom right corner
 * @member {Object} bounding_box
 */
AppearanceFrameData.prototype['bounding_box'] = undefined;
var _default = exports.default = AppearanceFrameData;