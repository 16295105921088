
import { trimNumber } from "@/Logic/Parsing/numbersParsing";
import { similarityScore } from "src/appConfig/Strings";
import OverflowingText from "../../Tooltips/OverflowingText";
import { processCameraInfoData } from "./CameraInfoColumn";
import { formatTimeRecorded } from "./TimeColumn/TimeColumn.utils";

function PoiInfoColumn({ displayName, confidence, notes }) {
    if (!confidence) {
        return null;
    }

    return (
        <div className="flex w-full flex-col items-center gap-1 overflow-hidden">
            <OverflowingText className="w-full break-words text-center font-bold" text={displayName} />
            <span className="notes-text">
                {notes}
            </span>
            <span className="break-all text-center">
                <div className="font-bold">
                    {`${similarityScore}:`}
                </div>
                <div>
                    {trimNumber(confidence)}
                </div>
            </span>
        </div>
    );
}

export default PoiInfoColumn;

export function getPoiName(matchData) {
    return matchData.poi_display_name || matchData.display_name;
}

export function processMatchData(matchData, row) {
    if (!matchData) {
        return {};
    }

    let additionalData = {};
    if (row) {
        const { displayName, notesData } = processCameraInfoData(row.camera_data);

        additionalData = {
            timeRecorded: formatTimeRecorded(row, matchData.watchlists[0]),
            cameraName: displayName,
            cameraNotes: notesData
        };
    }

    const { poi_display_name, display_name, poi_notes, poi_confidence, poi_id } = matchData;

    const processedData = {
        displayName: poi_display_name || display_name,
        notes: poi_notes?.free_notes ? poi_notes?.free_notes + '\n' : "",
        confidence: trimNumber(poi_confidence, 2),
        poiId: poi_id,
        watchlists: matchData.watchlists,
        ...additionalData
    };

    return processedData;
}

export function poiMatchValueGetter(match) {
    const { displayName, confidence, notes, poiId } = processMatchData(match);
    if (!poiId) {
        return null;
    }

    return `${displayName || ""}\n${notes}${similarityScore}: ${confidence}`;
}