"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _EventFilters = _interopRequireDefault(require("./EventFilters"));
var _RoleDataFilters = _interopRequireDefault(require("./RoleDataFilters"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The RoleDataFilters1 model module.
 * @module model/RoleDataFilters1
 * @version 0.35.0
 */
class RoleDataFilters1 {
  /**
   * Constructs a new <code>RoleDataFilters1</code>.
   * The allowed data filters of the role associated with the user
   * @alias module:model/RoleDataFilters1
   * @implements module:model/RoleDataFilters
   */
  constructor() {
    _RoleDataFilters.default.initialize(this);
    RoleDataFilters1.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>RoleDataFilters1</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RoleDataFilters1} obj Optional instance to populate.
   * @return {module:model/RoleDataFilters1} The populated <code>RoleDataFilters1</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RoleDataFilters1();
      _RoleDataFilters.default.constructFromObject(data, obj);
      if (data.hasOwnProperty('event_filters')) {
        obj['event_filters'] = _EventFilters.default.constructFromObject(data['event_filters']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>RoleDataFilters1</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RoleDataFilters1</code>.
   */
  static validateJSON(data) {
    // validate the optional field `event_filters`
    if (data['event_filters']) {
      // data not null
      _EventFilters.default.validateJSON(data['event_filters']);
    }
    return true;
  }
}

/**
 * @member {module:model/EventFilters} event_filters
 */
RoleDataFilters1.prototype['event_filters'] = undefined;

// Implement RoleDataFilters interface:
/**
 * @member {module:model/EventFilters} event_filters
 */
_RoleDataFilters.default.prototype['event_filters'] = undefined;
var _default = exports.default = RoleDataFilters1;