export const poiPageSize = 120;
export const poiSearchMaxResults = 25;
export const resultsPerPage = 300;
export const resultsPerEventsPage = 400;
export const searchFaceMinConfidence = 60;

export const maxNotesLength = 256; //todo-sdk

//api calls
export const defaultNetworkTimeout = 90;

// display constants
export const maxDisplayGrid = 1;

//frames playback 
export const framesIntervalMs = 400;

// POI importer
export const poiImportRequestBatch = 2;
export const importErrorsPageSize = 100;

// History Search
export const imageMatchParametersThreshold = 30;
export const imageMatchParametersMaxMatches = 5;

// compare face results
export const minSimilarScore = 30;
export const mediumSimilarScore = 70;

//app resources
export const resourceFolder = "/appResources";
export const headerLogo = `${resourceFolder}/headerLogo.svg`;
export const loginLogo = `${resourceFolder}/loginLogo.png`;
export const eulaPdf = `${resourceFolder}/eula-v1.2.0.pdf`;
export const favicon = `${resourceFolder}/favicon.ico`;
export const alertSound = `${resourceFolder}/alert-sound.mp3`;
export const shareFolderPath = "share/";
export const auditReportPrefix = "Audit_Report";