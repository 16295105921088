import { useMemo } from "react";

import { licenseNotIncluded } from "src/appConfig/Strings";
import store from "src/appConfig/configureStore";
import StatusMsgCard from "../Loaders/StatusMsgCard";
import { INVALID } from "@/Logic/ApiClients/UsersClient";

export const checkLicenseValidity = (licenseToCheck) => {
    let isLicenseValid = true;
    if (licenseToCheck) {
        const licenseFeatures = store.getState().ApiActionsStore.license?.license?.features ?? {};
        const licenseFeatureValue = licenseFeatures[licenseToCheck]?.value;
        isLicenseValid = licenseFeatureValue && licenseFeatureValue !== INVALID;
    }

    return isLicenseValid;
};

export const lockCheckManager = (isValidLicense, featureName, statusMsgCheckFunc) => {
    let statusMsgContent = null;

    if (!isValidLicense) {
        statusMsgContent = licenseNotIncluded;
    } else if (statusMsgCheckFunc) {
        const customMessage = statusMsgCheckFunc();

        if (customMessage) {
            featureName = null;
            statusMsgContent = customMessage;
        }
    }

    return statusMsgContent ? (
        <StatusMsgCard content={statusMsgContent} >
            <span className="ml-1">
                {featureName}
            </span>
        </StatusMsgCard>
    ) : null;
};

function LockCheck({ licenseToCheck, featureName, statusMsgCheckFunc, children }) {
    const isValidLicense = useMemo(() => licenseToCheck ? checkLicenseValidity(licenseToCheck) : true, [licenseToCheck]);
    const lockStatusMsg = useMemo(() => lockCheckManager(isValidLicense, featureName, statusMsgCheckFunc), [featureName, isValidLicense, statusMsgCheckFunc]);

    return lockStatusMsg ? lockStatusMsg : children;
}

export default LockCheck;