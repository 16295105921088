"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The DeleteGenerativeInsight model module.
 * @module model/DeleteGenerativeInsight
 * @version 0.37.0
 */
class DeleteGenerativeInsight {
  /**
   * Constructs a new <code>DeleteGenerativeInsight</code>.
   * @alias module:model/DeleteGenerativeInsight
   * @param generativeInsightId {Object} 
   */
  constructor(generativeInsightId) {
    DeleteGenerativeInsight.initialize(this, generativeInsightId);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, generativeInsightId) {
    obj['generative_insight_id'] = generativeInsightId;
  }

  /**
   * Constructs a <code>DeleteGenerativeInsight</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DeleteGenerativeInsight} obj Optional instance to populate.
   * @return {module:model/DeleteGenerativeInsight} The populated <code>DeleteGenerativeInsight</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DeleteGenerativeInsight();
      if (data.hasOwnProperty('generative_insight_id')) {
        obj['generative_insight_id'] = _ApiClient.default.convertToType(data['generative_insight_id'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>DeleteGenerativeInsight</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DeleteGenerativeInsight</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of DeleteGenerativeInsight.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
DeleteGenerativeInsight.RequiredProperties = ["generative_insight_id"];

/**
 * @member {Object} generative_insight_id
 */
DeleteGenerativeInsight.prototype['generative_insight_id'] = undefined;
var _default = exports.default = DeleteGenerativeInsight;