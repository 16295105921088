"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The HTTPValidationError model module.
 * @module model/HTTPValidationError
 * @version 0.37.0
 */
class HTTPValidationError {
  /**
   * Constructs a new <code>HTTPValidationError</code>.
   * @alias module:model/HTTPValidationError
   */
  constructor() {
    HTTPValidationError.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>HTTPValidationError</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/HTTPValidationError} obj Optional instance to populate.
   * @return {module:model/HTTPValidationError} The populated <code>HTTPValidationError</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new HTTPValidationError();
      if (data.hasOwnProperty('detail')) {
        obj['detail'] = _ApiClient.default.convertToType(data['detail'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>HTTPValidationError</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>HTTPValidationError</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * @member {Object} detail
 */
HTTPValidationError.prototype['detail'] = undefined;
var _default = exports.default = HTTPValidationError;