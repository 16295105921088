"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MatchData model module.
 * @module model/MatchData
 * @version 0.37.1
 */
class MatchData {
  /**
   * Constructs a new <code>MatchData</code>.
   * @alias module:model/MatchData
   */
  constructor() {
    MatchData.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MatchData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MatchData} obj Optional instance to populate.
   * @return {module:model/MatchData} The populated <code>MatchData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MatchData();
      if (data.hasOwnProperty('poi_display_img')) {
        obj['poi_display_img'] = _ApiClient.default.convertToType(data['poi_display_img'], Object);
      }
      if (data.hasOwnProperty('poi_display_name')) {
        obj['poi_display_name'] = _ApiClient.default.convertToType(data['poi_display_name'], Object);
      }
      if (data.hasOwnProperty('watchlists')) {
        obj['watchlists'] = _ApiClient.default.convertToType(data['watchlists'], Object);
      }
      if (data.hasOwnProperty('poi_notes')) {
        obj['poi_notes'] = _ApiClient.default.convertToType(data['poi_notes'], Object);
      }
      if (data.hasOwnProperty('utc_time_matched')) {
        obj['utc_time_matched'] = _ApiClient.default.convertToType(data['utc_time_matched'], Object);
      }
      if (data.hasOwnProperty('poi_id')) {
        obj['poi_id'] = _ApiClient.default.convertToType(data['poi_id'], Object);
      }
      if (data.hasOwnProperty('poi_confidence')) {
        obj['poi_confidence'] = _ApiClient.default.convertToType(data['poi_confidence'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MatchData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MatchData</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * The b64 display image of the POI match (null if no match). Can also be null in the case this POI does not have a display image set, or privacy forbids showing the display image.
 * @member {Object} poi_display_img
 */
MatchData.prototype['poi_display_img'] = undefined;

/**
 * the display name associated with the matched POI in the POI DB at the time of analysis. None if no match.
 * @member {Object} poi_display_name
 */
MatchData.prototype['poi_display_name'] = undefined;

/**
 * the reported watchlists match outcome for this appearance
 * @member {Object} watchlists
 */
MatchData.prototype['watchlists'] = undefined;

/**
 * the notes associated with the matched POI, if matched.
 * @member {Object} poi_notes
 */
MatchData.prototype['poi_notes'] = undefined;

/**
 * Time of the frame in which this tracker was matched to a POI. null if there is no match. Important note: For Cameras that analyze in forensics mode, this time is not actually the utc time but the calculated time in the video
 * @member {Object} utc_time_matched
 */
MatchData.prototype['utc_time_matched'] = undefined;

/**
 * POI id this appearance matched to during the analysis. If this appearance didn't match any POI, this will be null
 * @member {Object} poi_id
 */
MatchData.prototype['poi_id'] = undefined;

/**
 * confidence of the POI match. Note - this is not necessarily the match confidence of the specific crop sent with this event.
 * @member {Object} poi_confidence
 */
MatchData.prototype['poi_confidence'] = undefined;
var _default = exports.default = MatchData;