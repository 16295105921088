"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AnalyticsResponse", {
  enumerable: true,
  get: function () {
    return _AnalyticsResponse.default;
  }
});
Object.defineProperty(exports, "ApiClient", {
  enumerable: true,
  get: function () {
    return _ApiClient.default;
  }
});
Object.defineProperty(exports, "AppearancesApi", {
  enumerable: true,
  get: function () {
    return _AppearancesApi.default;
  }
});
Object.defineProperty(exports, "AppearancesDBOptions", {
  enumerable: true,
  get: function () {
    return _AppearancesDBOptions.default;
  }
});
Object.defineProperty(exports, "AppearancesResponseData", {
  enumerable: true,
  get: function () {
    return _AppearancesResponseData.default;
  }
});
Object.defineProperty(exports, "AttributesCounters", {
  enumerable: true,
  get: function () {
    return _AttributesCounters.default;
  }
});
Object.defineProperty(exports, "BatchMsgMetadata", {
  enumerable: true,
  get: function () {
    return _BatchMsgMetadata.default;
  }
});
Object.defineProperty(exports, "BatchSuccessData", {
  enumerable: true,
  get: function () {
    return _BatchSuccessData.default;
  }
});
Object.defineProperty(exports, "BodyProximityHistoryInsight", {
  enumerable: true,
  get: function () {
    return _BodyProximityHistoryInsight.default;
  }
});
Object.defineProperty(exports, "BoundingBox", {
  enumerable: true,
  get: function () {
    return _BoundingBox.default;
  }
});
Object.defineProperty(exports, "FaceFeaturesData", {
  enumerable: true,
  get: function () {
    return _FaceFeaturesData.default;
  }
});
Object.defineProperty(exports, "GeneralResponse", {
  enumerable: true,
  get: function () {
    return _GeneralResponse.default;
  }
});
Object.defineProperty(exports, "GetAnalyticsRequestParams", {
  enumerable: true,
  get: function () {
    return _GetAnalyticsRequestParams.default;
  }
});
Object.defineProperty(exports, "GetAnalyticsResponse", {
  enumerable: true,
  get: function () {
    return _GetAnalyticsResponse.default;
  }
});
Object.defineProperty(exports, "GetAppearancesResponseData", {
  enumerable: true,
  get: function () {
    return _GetAppearancesResponseData.default;
  }
});
Object.defineProperty(exports, "GetPersonsResponseData", {
  enumerable: true,
  get: function () {
    return _GetPersonsResponseData.default;
  }
});
Object.defineProperty(exports, "GetProximityPersonsResponseData", {
  enumerable: true,
  get: function () {
    return _GetProximityPersonsResponseData.default;
  }
});
Object.defineProperty(exports, "GetSearchResponseModel", {
  enumerable: true,
  get: function () {
    return _GetSearchResponseModel.default;
  }
});
Object.defineProperty(exports, "HTTPValidationError", {
  enumerable: true,
  get: function () {
    return _HTTPValidationError.default;
  }
});
Object.defineProperty(exports, "HistoryAppearanceData", {
  enumerable: true,
  get: function () {
    return _HistoryAppearanceData.default;
  }
});
Object.defineProperty(exports, "HistoryDBQuery", {
  enumerable: true,
  get: function () {
    return _HistoryDBQuery.default;
  }
});
Object.defineProperty(exports, "HistoryDBRecordResponse", {
  enumerable: true,
  get: function () {
    return _HistoryDBRecordResponse.default;
  }
});
Object.defineProperty(exports, "ImagePayload", {
  enumerable: true,
  get: function () {
    return _ImagePayload.default;
  }
});
Object.defineProperty(exports, "InsightsApi", {
  enumerable: true,
  get: function () {
    return _InsightsApi.default;
  }
});
Object.defineProperty(exports, "LabelAppearanceParameters", {
  enumerable: true,
  get: function () {
    return _LabelAppearanceParameters.default;
  }
});
Object.defineProperty(exports, "LabelOutcome", {
  enumerable: true,
  get: function () {
    return _LabelOutcome.default;
  }
});
Object.defineProperty(exports, "MatchApi", {
  enumerable: true,
  get: function () {
    return _MatchApi.default;
  }
});
Object.defineProperty(exports, "MatchData", {
  enumerable: true,
  get: function () {
    return _MatchData.default;
  }
});
Object.defineProperty(exports, "MatchOutcomes", {
  enumerable: true,
  get: function () {
    return _MatchOutcomes.default;
  }
});
Object.defineProperty(exports, "MatchedAppearance", {
  enumerable: true,
  get: function () {
    return _MatchedAppearance.default;
  }
});
Object.defineProperty(exports, "MatchedPerson", {
  enumerable: true,
  get: function () {
    return _MatchedPerson.default;
  }
});
Object.defineProperty(exports, "MsgMetadata", {
  enumerable: true,
  get: function () {
    return _MsgMetadata.default;
  }
});
Object.defineProperty(exports, "Notes", {
  enumerable: true,
  get: function () {
    return _Notes.default;
  }
});
Object.defineProperty(exports, "PersonDBRecordResponse", {
  enumerable: true,
  get: function () {
    return _PersonDBRecordResponse.default;
  }
});
Object.defineProperty(exports, "PersonsResponseData", {
  enumerable: true,
  get: function () {
    return _PersonsResponseData.default;
  }
});
Object.defineProperty(exports, "ProximityBodyRequest", {
  enumerable: true,
  get: function () {
    return _ProximityBodyRequest.default;
  }
});
Object.defineProperty(exports, "ProximityPersonDBRecordResponse", {
  enumerable: true,
  get: function () {
    return _ProximityPersonDBRecordResponse.default;
  }
});
Object.defineProperty(exports, "ProximityPersonsResponseData", {
  enumerable: true,
  get: function () {
    return _ProximityPersonsResponseData.default;
  }
});
Object.defineProperty(exports, "ResponseCameraData", {
  enumerable: true,
  get: function () {
    return _ResponseCameraData.default;
  }
});
Object.defineProperty(exports, "ResponseCropData", {
  enumerable: true,
  get: function () {
    return _ResponseCropData.default;
  }
});
Object.defineProperty(exports, "SearchFacesInImage", {
  enumerable: true,
  get: function () {
    return _SearchFacesInImage.default;
  }
});
Object.defineProperty(exports, "SearchFacesInImageResponse", {
  enumerable: true,
  get: function () {
    return _SearchFacesInImageResponse.default;
  }
});
Object.defineProperty(exports, "SearchFacesInImageResponseModel", {
  enumerable: true,
  get: function () {
    return _SearchFacesInImageResponseModel.default;
  }
});
Object.defineProperty(exports, "SearchImgParams", {
  enumerable: true,
  get: function () {
    return _SearchImgParams.default;
  }
});
Object.defineProperty(exports, "SearchPoiParams", {
  enumerable: true,
  get: function () {
    return _SearchPoiParams.default;
  }
});
Object.defineProperty(exports, "SearchResponse", {
  enumerable: true,
  get: function () {
    return _SearchResponse.default;
  }
});
Object.defineProperty(exports, "SetAppearancesRetainParams", {
  enumerable: true,
  get: function () {
    return _SetAppearancesRetainParams.default;
  }
});
Object.defineProperty(exports, "UniquePersonBodyRequest", {
  enumerable: true,
  get: function () {
    return _UniquePersonBodyRequest.default;
  }
});
Object.defineProperty(exports, "ValidationError", {
  enumerable: true,
  get: function () {
    return _ValidationError.default;
  }
});
Object.defineProperty(exports, "WatchlistObject", {
  enumerable: true,
  get: function () {
    return _WatchlistObject.default;
  }
});
Object.defineProperty(exports, "WatchlistTypes", {
  enumerable: true,
  get: function () {
    return _WatchlistTypes.default;
  }
});
Object.defineProperty(exports, "WatchlistsQuery", {
  enumerable: true,
  get: function () {
    return _WatchlistsQuery.default;
  }
});
var _ApiClient = _interopRequireDefault(require("./ApiClient"));
var _AnalyticsResponse = _interopRequireDefault(require("./model/AnalyticsResponse"));
var _AppearancesDBOptions = _interopRequireDefault(require("./model/AppearancesDBOptions"));
var _AppearancesResponseData = _interopRequireDefault(require("./model/AppearancesResponseData"));
var _AttributesCounters = _interopRequireDefault(require("./model/AttributesCounters"));
var _BatchMsgMetadata = _interopRequireDefault(require("./model/BatchMsgMetadata"));
var _BatchSuccessData = _interopRequireDefault(require("./model/BatchSuccessData"));
var _BodyProximityHistoryInsight = _interopRequireDefault(require("./model/BodyProximityHistoryInsight"));
var _BoundingBox = _interopRequireDefault(require("./model/BoundingBox"));
var _FaceFeaturesData = _interopRequireDefault(require("./model/FaceFeaturesData"));
var _GeneralResponse = _interopRequireDefault(require("./model/GeneralResponse"));
var _GetAnalyticsRequestParams = _interopRequireDefault(require("./model/GetAnalyticsRequestParams"));
var _GetAnalyticsResponse = _interopRequireDefault(require("./model/GetAnalyticsResponse"));
var _GetAppearancesResponseData = _interopRequireDefault(require("./model/GetAppearancesResponseData"));
var _GetPersonsResponseData = _interopRequireDefault(require("./model/GetPersonsResponseData"));
var _GetProximityPersonsResponseData = _interopRequireDefault(require("./model/GetProximityPersonsResponseData"));
var _GetSearchResponseModel = _interopRequireDefault(require("./model/GetSearchResponseModel"));
var _HTTPValidationError = _interopRequireDefault(require("./model/HTTPValidationError"));
var _HistoryAppearanceData = _interopRequireDefault(require("./model/HistoryAppearanceData"));
var _HistoryDBQuery = _interopRequireDefault(require("./model/HistoryDBQuery"));
var _HistoryDBRecordResponse = _interopRequireDefault(require("./model/HistoryDBRecordResponse"));
var _ImagePayload = _interopRequireDefault(require("./model/ImagePayload"));
var _LabelAppearanceParameters = _interopRequireDefault(require("./model/LabelAppearanceParameters"));
var _LabelOutcome = _interopRequireDefault(require("./model/LabelOutcome"));
var _MatchData = _interopRequireDefault(require("./model/MatchData"));
var _MatchOutcomes = _interopRequireDefault(require("./model/MatchOutcomes"));
var _MatchedAppearance = _interopRequireDefault(require("./model/MatchedAppearance"));
var _MatchedPerson = _interopRequireDefault(require("./model/MatchedPerson"));
var _MsgMetadata = _interopRequireDefault(require("./model/MsgMetadata"));
var _Notes = _interopRequireDefault(require("./model/Notes"));
var _PersonDBRecordResponse = _interopRequireDefault(require("./model/PersonDBRecordResponse"));
var _PersonsResponseData = _interopRequireDefault(require("./model/PersonsResponseData"));
var _ProximityBodyRequest = _interopRequireDefault(require("./model/ProximityBodyRequest"));
var _ProximityPersonDBRecordResponse = _interopRequireDefault(require("./model/ProximityPersonDBRecordResponse"));
var _ProximityPersonsResponseData = _interopRequireDefault(require("./model/ProximityPersonsResponseData"));
var _ResponseCameraData = _interopRequireDefault(require("./model/ResponseCameraData"));
var _ResponseCropData = _interopRequireDefault(require("./model/ResponseCropData"));
var _SearchFacesInImage = _interopRequireDefault(require("./model/SearchFacesInImage"));
var _SearchFacesInImageResponse = _interopRequireDefault(require("./model/SearchFacesInImageResponse"));
var _SearchFacesInImageResponseModel = _interopRequireDefault(require("./model/SearchFacesInImageResponseModel"));
var _SearchImgParams = _interopRequireDefault(require("./model/SearchImgParams"));
var _SearchPoiParams = _interopRequireDefault(require("./model/SearchPoiParams"));
var _SearchResponse = _interopRequireDefault(require("./model/SearchResponse"));
var _SetAppearancesRetainParams = _interopRequireDefault(require("./model/SetAppearancesRetainParams"));
var _UniquePersonBodyRequest = _interopRequireDefault(require("./model/UniquePersonBodyRequest"));
var _ValidationError = _interopRequireDefault(require("./model/ValidationError"));
var _WatchlistObject = _interopRequireDefault(require("./model/WatchlistObject"));
var _WatchlistTypes = _interopRequireDefault(require("./model/WatchlistTypes"));
var _WatchlistsQuery = _interopRequireDefault(require("./model/WatchlistsQuery"));
var _AppearancesApi = _interopRequireDefault(require("./api/AppearancesApi"));
var _InsightsApi = _interopRequireDefault(require("./api/InsightsApi"));
var _MatchApi = _interopRequireDefault(require("./api/MatchApi"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }