import { correctMatch, incorrectMatch, missedMatch, notReviewed } from "@/Logic/ApiClients/EventsClient/EventsClient.model";
import { addNote, correctMatchText, incorrectMatchText, missedMatchText, notReviewedText } from "src/appConfig/Strings";

export const LabelAppearanceFieldNames = {
    freeNotes: "free_notes",
    labeledOutcome: "labeled_outcome",
};

export const LabelAppearancesInputModel = {
    [LabelAppearanceFieldNames.labeledOutcome]: {
        name: LabelAppearanceFieldNames.labeledOutcome,
        dataKey: "option",
        textField: "label",
        type: "text",
        required: true
    },
    [LabelAppearanceFieldNames.freeNotes]: {
        name: LabelAppearanceFieldNames.freeNotes,
        placeholder: addNote,
        type: "textarea",
    }
};

export const labelOptions = [
    {
        label: notReviewedText,
        option: notReviewed
    },
    {
        label: correctMatchText,
        option: correctMatch
    },
    {
        label: incorrectMatchText,
        option: incorrectMatch
    },
    {
        label: missedMatchText,
        option: missedMatch
    }
];