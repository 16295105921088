import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    eventsAnalytics: null,
    historyAnalytics: null
};

const framesSlice = createSlice({
    name: 'analyticsStore',
    initialState,
    reducers: {
        setEventAnalytics: (state, action) => {
            const eventsAnalytics = action.payload;
            state.eventsAnalytics = eventsAnalytics;
        },
        setHistoryAnalytics: (state, action) => {
            const historyAnalytics = action.payload;
            state.historyAnalytics = historyAnalytics;
        },
        clearHistoryAnalytics: (state, action) => {
            state.historyAnalytics = initialState.historyAnalytics;
        }
    },
});

export const { setEventAnalytics, setHistoryAnalytics, clearHistoryAnalytics } = framesSlice.actions;

export default framesSlice.reducer;