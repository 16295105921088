import { useCallback, useEffect, useState } from "react";
import { Input } from "reactstrap";

import { minAppearances, minAppearancesLabel } from "../../../../../appConfig/Strings";
import FilterContainer from "../../../Common/Filters/FilterContainer";
import { ToggleSwitchButton } from "../../../Common/FormComponents/FormComponentsOld";

export const inactiveMinAppearance = -1;

function MinAppearanceFilter({ onChange, initialData, defaultMinAppearances, ...props }) {
    const [minAppearancesData, setMinAppearancesData] = useState(initialData || inactiveMinAppearance);

    useEffect(() => {
        onChange && onChange(minAppearancesData);
    }, [onChange, minAppearancesData]);

    const handleToggleChange = useCallback((isActive) => {
        setMinAppearancesData(isActive ? defaultMinAppearances : inactiveMinAppearance);
    }, [defaultMinAppearances]);

    const handleMinValueChange = useCallback((newVal) => {
        setMinAppearancesData(newVal);
    }, []);

    return (
        <FilterContainer className="flex-row items-center" header={minAppearances} headerElement={<ToggleSwitchButton name="minAppearances" value={minAppearancesData !== inactiveMinAppearance} changeHandler={handleToggleChange} />}>
            {minAppearancesData !== inactiveMinAppearance && (
                <div className="flex">
                    <div className="ml-3">
                        {minAppearancesLabel}
                    </div>
                    <Input
                        className="w-20"
                        name="minAppearances"
                        value={minAppearancesData}
                        onChange={event => handleMinValueChange(event.target.value)}
                        min={defaultMinAppearances}
                        {...props} />
                </div>
            )}
        </FilterContainer>);
}

export default MinAppearanceFilter;
