import { useCallback, useContext, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { clearWatchlist, editWatchlist, remove, removeWatchlist, removeWlConfirmation } from "src/appConfig/Strings";
import ApiContext from "@/Logic/Context/apiContext";
import { routesUrl } from "@/Components/Common/Layout/SideBar/Routes";
import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { setDialogData } from "src/appConfig/customEvents";
import { clearWlDialog } from "@/Logic/Hooks/useDialog/useDialog.model";

function WatchlistContextMenu({ watchlist }) {
    const { clientsManager } = useContext(ApiContext);
    const navigate = useNavigate();

    const setOptions = useCallback(() => {
        const options = {
            [editWatchlist]: () => navigate(`${routesUrl.createEditWatchlist}?watchlistId=${watchlist.watchlist_id}`),
            [clearWatchlist]: () => {
                dispatchToggleDialogEvent(clearWlDialog);
                dispatchSetClearWlDialogDataEvent(watchlist.watchlist_id);
            },
            [removeWatchlist]: () => clientsManager.watchlistsClient.removeWatchlist(watchlist.watchlist_id)
        };

        return options;
    }, [navigate, clientsManager.watchlistsClient, watchlist.watchlist_id]);

    const confirmationOptions = useMemo(() => {
        return {
            [removeWatchlist]: { acceptButtonText: remove, confirmationText: removeWlConfirmation }
        };
    }, []);

    return (
        <ContextMenuButton options={setOptions()} confirmationOptions={confirmationOptions} />
    );
}

export default WatchlistContextMenu;

export function dispatchSetClearWlDialogDataEvent(watchlistId) {
    const setClearWlDialogDataEvent = new CustomEvent(setDialogData, { detail: { watchlistId } });
    document.dispatchEvent(setClearWlDialogDataEvent);
}