"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _User = _interopRequireDefault(require("./User"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UsersList model module.
 * @module model/UsersList
 * @version 0.35.0
 */
class UsersList {
  /**
   * Constructs a new <code>UsersList</code>.
   * @alias module:model/UsersList
   * @param users {Array.<module:model/User>} 
   */
  constructor(users) {
    UsersList.initialize(this, users);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, users) {
    obj['users'] = users;
  }

  /**
   * Constructs a <code>UsersList</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UsersList} obj Optional instance to populate.
   * @return {module:model/UsersList} The populated <code>UsersList</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UsersList();
      if (data.hasOwnProperty('users')) {
        obj['users'] = _ApiClient.default.convertToType(data['users'], [_User.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UsersList</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UsersList</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of UsersList.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    if (data['users']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['users'])) {
        throw new Error("Expected the field `users` to be an array in the JSON data but got " + data['users']);
      }
      // validate the optional field `users` (array)
      for (const item of data['users']) {
        _User.default.validateJsonObject(item);
      }
      ;
    }
    return true;
  }
}
UsersList.RequiredProperties = ["users"];

/**
 * @member {Array.<module:model/User>} users
 */
UsersList.prototype['users'] = undefined;
var _default = exports.default = UsersList;