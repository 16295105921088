const backgroundColors = {
    "main": "linear-gradient(to right, #819CB4 0%, #40688D 25%, #263F63 100%)",
    "panels": "linear-gradient(rgba(8, 14, 24, 0.50), rgba(8, 14, 24, 0.50))",
    "login-screen": "radial-gradient(50% 50% at 50% 50%, #42A6DD 0%, #080E18 100%)",
    "top-bar": "linear-gradient(rgba(15, 23, 42, 1), rgba(15, 23, 42, 1))"
};

const appColors = {
    "silver-gray": "#dddddd",
    "white": "#ffffff",
    "dusty-blue": "#819CB4",
    "soft-sky-blue": "#9DB7DF",
    "gentle-azure": "#72A4D8",
    "deep-space-blue": "#070E18",
    "deep-ocean-blue": "#072E69",
    "teal-blue": "#3D6286",
    "blue-gray": "#6787A7",
    "midnight-blue": "#080E18",
    "deep-navy": "rgba(8, 14, 24, 0.5)",
    "charcoal-blue": "#1a2d46",
    "deep-navy-blue": "#203853",
    "dark-bluish-gray": "#152535",
    "slate-blue": "#586d84",
    "dodger-blue": "#1976d2",
};

const pieChartColors = {
    "pie-sky-blue": "#5080E2",
    "pie-ocean": "#29A3FA",
    "pie-light-blue": "#92D8FF",
    "pie-ice-blue": "#D0F3F8",
    "pie-turquoise": "#0BCAE2",
    "pie-deep-sea": "#10808A",
    "pie-day-blue": "#97B7D5",
    "pie-steel-blue": "#4E6C89",
    "pie-royal-blue": "#204CE1",
    "pie-line-bright-blue": "#42A6DD",
};

const textColors = {
    "light-sky-blue": "#EDF6FB",
    "dark-charcoal": "#333333"
};

const btnColors = {
    "oceanic-azure": "#1A89C7"
};

const colorValues = {
    SteelBlue: "#4670B4",
    Magenta: "#D21E75",
    Yellow: "#FCD531",
    PalePink: "#F7C9B0",
    MidnightBlue: "#3B378B",
    BurntOrange: "#E67436",
    RoyalPurple: "#65348F",
    Teal: "#217378",
    LimeGreen: "#44B44F",
    TomatoRed: "#EE442E",
    crimsonRed: "#dc0000",
    Taupe: "#8C6F66",
    OliveGreen: "#887100",
    lightGrey: "#d3d3d3",
    lightOrange: "#FFD580",
    red: "#FF0000",
    green: "#008000",
    lightGreen: "#00dc00"
};

export { backgroundColors, appColors, pieChartColors, textColors, btnColors, colorValues };