import { memo, useCallback, useContext } from "react";

import { connect } from "react-redux";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { acknowledgeAllAlertsLabel } from "src/appConfig/Strings";
import TooltipIconButton from "../../Buttons/TooltipIconButton";
import AudioManagerContext from "@/Logic/Context/audioManagerContext";
import { clearAlertsAcknowledgement } from "@/Logic/Redux/Stores/EventsStore";

function AcknowledgeAll({ clearAlertsAcknowledgement }) {
    const { audioManager } = useContext(AudioManagerContext);

    const handleAcknowledgeAll = useCallback(() => {
        audioManager.stop();
        clearAlertsAcknowledgement({});
    }, [audioManager, clearAlertsAcknowledgement]);

    return (
        <TooltipIconButton className="text-dodger-blue" iconElement={<TaskAltIcon />} tooltipText={acknowledgeAllAlertsLabel} buttonAction={handleAcknowledgeAll} />
    );
}

const mapActionsToProps = {
    clearAlertsAcknowledgement
};

export default connect(null, mapActionsToProps)(memo(AcknowledgeAll));