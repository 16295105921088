import { createSlice } from '@reduxjs/toolkit';

import { AnalysisQualityEnum, cameraFormFieldsNames, capturingSourceMode } from "@/Components/Cameras/CreateCamera/CreateEditCamera/CreateEditCamera.model";
import { parseFaceAttributesToObj } from "@/Logic/ApiClients/CamerasClient";

const { cameraName, captureConfigMode, captureAddress, cameraStreamUsername, cameraStreamPassword, nodeId, analysisQuality, watchlists, threshold, cameraNotes, requiredFaceAttributes, recordFrames, minDetectionWidth, authorizedMinFaceWidth, ageGenderMinWidth, livenessMinWidth, videoDisplayResolution, privacyProfileId, streamMaxFaces } = cameraFormFieldsNames;

export const CreateCameraDefaultValues = {
    //todo-sdk
    [captureConfigMode]: capturingSourceMode.rtsp,
    [threshold]: 45,
    [minDetectionWidth]: 30,
    [authorizedMinFaceWidth]: 80,
    [ageGenderMinWidth]: 60,
    [livenessMinWidth]: 300,
    [analysisQuality]: AnalysisQualityEnum.fast,
    [videoDisplayResolution]: 1024,
};

const initialState = {
    cameras: {},
    lastCameraFilesPath: "",
    openCamera: {
        ...CreateCameraDefaultValues
    }
};

const camerasSlice = createSlice({
    name: 'cameras',
    initialState,
    reducers: {
        setCameras: (state, action) => {
            const cameras = action.payload;
            const parsedCameras = Object.keys(cameras).reduce((acc, key) => {
                const prevProgress = state.cameras[key]?.camera_status.elapsed_progress;
                if (prevProgress) {
                    cameras[key].camera_status.elapsed_progress = prevProgress;
                }
                acc[key] = cameras[key];
                return acc;
            }, {});

            state.cameras = parsedCameras;
            return state;
        },
        setOpenCamera: (state, action) => {
            const cameraData = action.payload;
            const cameraFormFields = {
                [cameraName]: cameraData.description,
                [captureConfigMode]: cameraData.capture_config.mode,
                [captureAddress]: cameraData.capture_config.capture_address,
                [cameraStreamUsername]: cameraData.capture_config.camera_stream_username,
                [cameraStreamPassword]: cameraData.capture_config.camera_stream_password,
                [nodeId]: cameraData.node_id,
                [analysisQuality]: cameraData.config.analysis_quality,
                [watchlists]: cameraData.config.watchlists.map(watchlist => watchlist.watchlist_id),
                [threshold]: cameraData.config.face_recognition_threshold,
                [cameraNotes]: cameraData.camera_notes.free_notes,
                [requiredFaceAttributes]: parseFaceAttributesToObj(cameraData.config.required_face_attributes),
                [recordFrames]: cameraData.config.record_frames,
                [minDetectionWidth]: cameraData.capture_config.min_detection_width,
                [authorizedMinFaceWidth]: cameraData.config.authorized_min_face_width,
                [ageGenderMinWidth]: cameraData.config.features_min_face_widths.age_gender,
                [livenessMinWidth]: cameraData.config.features_min_face_widths.liveness,
                [videoDisplayResolution]: cameraData.capture_config.rebroadcast_frame_width,
                [privacyProfileId]: cameraData.privacy_profile_properties.privacy_profile_id,
                [streamMaxFaces]: cameraData.capture_config.stream_max_faces,
            };

            state.openCamera = {
                ...state.openCamera,
                ...cameraFormFields,
            };

            return state;
        },
        clearOpenCamera: (state) => {
            state.openCamera = {
                ...CreateCameraDefaultValues
            };
        },
        updateCameraStatus: (state, action) => {
            const cameraStatus = action.payload;
            const camera = state.cameras[cameraStatus.camera_data.camera_id];

            if (camera) {
                camera.camera_status = cameraStatus.update_data;
            }

            return state;
        },
        removeCamera(state, action) {
            delete state.cameras[action.payload];
            return state;
        },
        updateCamera: (state, action) => {
            const { camera } = action.payload;
            state.cameras[camera.camera_id] = camera;

            return state;
        },
        setLastCameraFilesPath: (state, action) => {
            const path = action.payload;
            state.lastCameraFilesPath = path;

            return state;
        }
    }
});

export const { setCameras, setOpenCamera, clearOpenCamera, updateCameraStatus, removeCamera, updateCamera, setLastCameraFilesPath } = camerasSlice.actions;

export default camerasSlice.reducer;