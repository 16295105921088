"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AddCameraParams = _interopRequireDefault(require("../model/AddCameraParams"));
var _CameraPrivacyId = _interopRequireDefault(require("../model/CameraPrivacyId"));
var _CameraPrivacyResponse = _interopRequireDefault(require("../model/CameraPrivacyResponse"));
var _CameraResponse = _interopRequireDefault(require("../model/CameraResponse"));
var _CameraStartRequest = _interopRequireDefault(require("../model/CameraStartRequest"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _ListAllCamerasResponse = _interopRequireDefault(require("../model/ListAllCamerasResponse"));
var _RemoveCameraResponse = _interopRequireDefault(require("../model/RemoveCameraResponse"));
var _UpdateCameraParams = _interopRequireDefault(require("../model/UpdateCameraParams"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Cameras service.
* @module api/CamerasApi
* @version 0.32.2
*/
class CamerasApi {
  /**
  * Constructs a new CamerasApi. 
  * @alias module:api/CamerasApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the createCamera operation.
   * @callback module:api/CamerasApi~createCameraCallback
   * @param {String} error Error message, if any.
   * @param {module:model/CameraResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Create Camera
   * Create a new Camera and set its properties. Returns the data of the newly created Camera.
   * @param {module:model/AddCameraParams} addCameraParams 
   * @param {module:api/CamerasApi~createCameraCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/CameraResponse}
   */
  createCamera(addCameraParams, callback) {
    let postBody = addCameraParams;
    // verify the required parameter 'addCameraParams' is set
    if (addCameraParams === undefined || addCameraParams === null) {
      throw new Error("Missing the required parameter 'addCameraParams' when calling createCamera");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _CameraResponse.default;
    return this.apiClient.callApi('/cameras/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getCamera operation.
   * @callback module:api/CamerasApi~getCameraCallback
   * @param {String} error Error message, if any.
   * @param {module:model/CameraResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Camera
   * Get Camera properties
   * @param {String} cameraId 
   * @param {module:api/CamerasApi~getCameraCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/CameraResponse}
   */
  getCamera(cameraId, callback) {
    let postBody = null;
    // verify the required parameter 'cameraId' is set
    if (cameraId === undefined || cameraId === null) {
      throw new Error("Missing the required parameter 'cameraId' when calling getCamera");
    }
    let pathParams = {
      'camera_id': cameraId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _CameraResponse.default;
    return this.apiClient.callApi('/cameras/{camera_id}/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getCameraPrivacy operation.
   * @callback module:api/CamerasApi~getCameraPrivacyCallback
   * @param {String} error Error message, if any.
   * @param {module:model/CameraPrivacyResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Camera Privacy
   * View the Appearance Privacy Profile id assigned to the specific camera. The Appearance Profile of a camera controls the data this camera outputs in events and display, as well as the data it is allowed to save in History.
   * @param {String} cameraId 
   * @param {module:api/CamerasApi~getCameraPrivacyCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/CameraPrivacyResponse}
   */
  getCameraPrivacy(cameraId, callback) {
    let postBody = null;
    // verify the required parameter 'cameraId' is set
    if (cameraId === undefined || cameraId === null) {
      throw new Error("Missing the required parameter 'cameraId' when calling getCameraPrivacy");
    }
    let pathParams = {
      'camera_id': cameraId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _CameraPrivacyResponse.default;
    return this.apiClient.callApi('/cameras/{camera_id}/privacy/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the listAllCameras operation.
   * @callback module:api/CamerasApi~listAllCamerasCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ListAllCamerasResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List All Cameras
   * Get list of all Cameras from the Server, as well as a summary of their state.
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.verbose  (default to false)
   * @param {Boolean} opts.includeAllDbCameras  (default to false)
   * @param {Object} opts.nodeId 
   * @param {module:api/CamerasApi~listAllCamerasCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ListAllCamerasResponse}
   */
  listAllCameras(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'verbose': opts['verbose'],
      'include_all_db_cameras': opts['includeAllDbCameras'],
      'node_id': opts['nodeId']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ListAllCamerasResponse.default;
    return this.apiClient.callApi('/cameras/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeAllCameras operation.
   * @callback module:api/CamerasApi~removeAllCamerasCallback
   * @param {String} error Error message, if any.
   * @param {Object} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove All Cameras
   * Removes all the cameras from the system. If a camera is active, stops it and removes.
   * @param {Object} opts Optional parameters
   * @param {Object} opts.nodeId 
   * @param {module:api/CamerasApi~removeAllCamerasCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Object}
   */
  removeAllCameras(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'node_id': opts['nodeId']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Object;
    return this.apiClient.callApi('/cameras/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeCamera operation.
   * @callback module:api/CamerasApi~removeCameraCallback
   * @param {String} error Error message, if any.
   * @param {module:model/RemoveCameraResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Camera
   * Remove this camera from the system. This end point cannot remove a capturing camera.
   * @param {String} cameraId 
   * @param {module:api/CamerasApi~removeCameraCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/RemoveCameraResponse}
   */
  removeCamera(cameraId, callback) {
    let postBody = null;
    // verify the required parameter 'cameraId' is set
    if (cameraId === undefined || cameraId === null) {
      throw new Error("Missing the required parameter 'cameraId' when calling removeCamera");
    }
    let pathParams = {
      'camera_id': cameraId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _RemoveCameraResponse.default;
    return this.apiClient.callApi('/cameras/{camera_id}/', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setCameraPrivacy operation.
   * @callback module:api/CamerasApi~setCameraPrivacyCallback
   * @param {String} error Error message, if any.
   * @param {module:model/CameraPrivacyResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set Camera Privacy
   * Assign an Appearance Privacy Profile id to the specific camera. This will control the data this camera outputs in events and display, as well as the data it is allowed to save in History.
   * @param {String} cameraId 
   * @param {module:model/CameraPrivacyId} cameraPrivacyId 
   * @param {module:api/CamerasApi~setCameraPrivacyCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/CameraPrivacyResponse}
   */
  setCameraPrivacy(cameraId, cameraPrivacyId, callback) {
    let postBody = cameraPrivacyId;
    // verify the required parameter 'cameraId' is set
    if (cameraId === undefined || cameraId === null) {
      throw new Error("Missing the required parameter 'cameraId' when calling setCameraPrivacy");
    }
    // verify the required parameter 'cameraPrivacyId' is set
    if (cameraPrivacyId === undefined || cameraPrivacyId === null) {
      throw new Error("Missing the required parameter 'cameraPrivacyId' when calling setCameraPrivacy");
    }
    let pathParams = {
      'camera_id': cameraId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _CameraPrivacyResponse.default;
    return this.apiClient.callApi('/cameras/{camera_id}/privacy/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the startCamera operation.
   * @callback module:api/CamerasApi~startCameraCallback
   * @param {String} error Error message, if any.
   * @param {module:model/CameraResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Start Camera
   * Start a new capturing stream for a camera. The stream parameters are initialized with the config of the camera. A camera can be started either analyzing or not.
   * @param {String} cameraId 
   * @param {module:model/CameraStartRequest} cameraStartRequest 
   * @param {module:api/CamerasApi~startCameraCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/CameraResponse}
   */
  startCamera(cameraId, cameraStartRequest, callback) {
    let postBody = cameraStartRequest;
    // verify the required parameter 'cameraId' is set
    if (cameraId === undefined || cameraId === null) {
      throw new Error("Missing the required parameter 'cameraId' when calling startCamera");
    }
    // verify the required parameter 'cameraStartRequest' is set
    if (cameraStartRequest === undefined || cameraStartRequest === null) {
      throw new Error("Missing the required parameter 'cameraStartRequest' when calling startCamera");
    }
    let pathParams = {
      'camera_id': cameraId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _CameraResponse.default;
    return this.apiClient.callApi('/cameras/{camera_id}/start/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the stopCamera operation.
   * @callback module:api/CamerasApi~stopCameraCallback
   * @param {String} error Error message, if any.
   * @param {module:model/CameraResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Stop Camera
   * Stop a capturing camera.
   * @param {String} cameraId 
   * @param {module:api/CamerasApi~stopCameraCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/CameraResponse}
   */
  stopCamera(cameraId, callback) {
    let postBody = null;
    // verify the required parameter 'cameraId' is set
    if (cameraId === undefined || cameraId === null) {
      throw new Error("Missing the required parameter 'cameraId' when calling stopCamera");
    }
    let pathParams = {
      'camera_id': cameraId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _CameraResponse.default;
    return this.apiClient.callApi('/cameras/{camera_id}/stop/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updateCamera operation.
   * @callback module:api/CamerasApi~updateCameraCallback
   * @param {String} error Error message, if any.
   * @param {module:model/CameraResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update Camera
   * With this endpoint, the user can modify the properties of the Camera that was created in Create Camera, and the camera config. The camera config can be updated while running - configuration changes performed here will affect current analysis, if it is currently analyzing. Of the rest of the camera properties (mode, capture_address, etc), only description can be changed while the camera is running.
   * @param {String} cameraId 
   * @param {module:model/UpdateCameraParams} updateCameraParams 
   * @param {module:api/CamerasApi~updateCameraCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/CameraResponse}
   */
  updateCamera(cameraId, updateCameraParams, callback) {
    let postBody = updateCameraParams;
    // verify the required parameter 'cameraId' is set
    if (cameraId === undefined || cameraId === null) {
      throw new Error("Missing the required parameter 'cameraId' when calling updateCamera");
    }
    // verify the required parameter 'updateCameraParams' is set
    if (updateCameraParams === undefined || updateCameraParams === null) {
      throw new Error("Missing the required parameter 'updateCameraParams' when calling updateCamera");
    }
    let pathParams = {
      'camera_id': cameraId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _CameraResponse.default;
    return this.apiClient.callApi('/cameras/{camera_id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = CamerasApi;