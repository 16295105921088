import { MenuItem } from "@mui/material";

import BaseInput from "./BaseInput";
import { formatDataTestId } from "@/Logic/Parsing/stringsParsing";

function SelectInputField({ className, label, model, value, dataTestId, options, onOpen, defaultValue = "", onChange = null, ...props }) {
    const { dataKey, textField, ...restModel } = model;

    return (
        <BaseInput
            className={className}
            SelectProps={{
                className: "h-select-input-field",
                onOpen,
                MenuProps: {
                    PaperProps: {
                        className: "mb-2 border-2 border-oceanic-azure overflow-auto max-h-72 max-w-12",
                    },
                },
            }}
            inputProps={{ ...restModel }}
            dataTestId={dataTestId}
            label={label}
            value={options.length ? value : ""}
            defaultValue={defaultValue}
            onChange={onChange}
            model={restModel}
            variant="outlined"
            fullWidth
            select
            {...props}
        >
            {options.map((option, index) => {
                return (
                    <MenuItem key={index} value={option[dataKey]} data-testid={formatDataTestId(option[textField])} disabled={option.disabled}>
                        <div className="w-full truncate">
                            {option[textField]}
                        </div>
                    </MenuItem>
                );
            })}
        </BaseInput>
    );
}

export default SelectInputField;
