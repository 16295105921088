import { useCallback } from "react";

import TextField from "@mui/material/TextField";

import { textAreaHelperText } from "src/appConfig/Strings";

function TextAreaInputField({ className, label, model, value = "", dataTestId, inputProps, onChange = null }) {
    const maxTextLength = 256;

    const onChangeHandler = useCallback((event) => {
        onChange && onChange(event);
    }, [onChange]);

    return (
        <TextField
            multiline
            fullWidth
            minRows={2}
            maxRows={2}
            className={`overflow-y-visible ${className}`}
            inputProps={{ maxLength: 256, 'data-testid': dataTestId, ...inputProps }}
            label={label}
            value={value}
            onChange={onChangeHandler}
            helperText={textAreaHelperText(String(value).length, maxTextLength)}
            FormHelperTextProps={{ style: { textAlign: "right" } }}
            variant="outlined"
            {...model}
        />
    );
}

export default TextAreaInputField;