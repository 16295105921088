"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _FaceAttribute = _interopRequireDefault(require("./FaceAttribute"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The FaceAnalysisAttributes model module.
 * @module model/FaceAnalysisAttributes
 * @version 0.37.1
 */
class FaceAnalysisAttributes {
  /**
   * Constructs a new <code>FaceAnalysisAttributes</code>.
   * @alias module:model/FaceAnalysisAttributes
   */
  constructor() {
    FaceAnalysisAttributes.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>FaceAnalysisAttributes</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FaceAnalysisAttributes} obj Optional instance to populate.
   * @return {module:model/FaceAnalysisAttributes} The populated <code>FaceAnalysisAttributes</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FaceAnalysisAttributes();
      if (data.hasOwnProperty('masked')) {
        obj['masked'] = _FaceAttribute.default.constructFromObject(data['masked']);
      }
      if (data.hasOwnProperty('gender')) {
        obj['gender'] = _FaceAttribute.default.constructFromObject(data['gender']);
      }
      if (data.hasOwnProperty('age_group')) {
        obj['age_group'] = _FaceAttribute.default.constructFromObject(data['age_group']);
      }
      if (data.hasOwnProperty('lower_occlusion')) {
        obj['lower_occlusion'] = _FaceAttribute.default.constructFromObject(data['lower_occlusion']);
      }
      if (data.hasOwnProperty('liveness')) {
        obj['liveness'] = _FaceAttribute.default.constructFromObject(data['liveness']);
      }
      if (data.hasOwnProperty('face_score')) {
        obj['face_score'] = _ApiClient.default.convertToType(data['face_score'], Object);
      }
      if (data.hasOwnProperty('pitch')) {
        obj['pitch'] = _ApiClient.default.convertToType(data['pitch'], Object);
      }
      if (data.hasOwnProperty('yaw')) {
        obj['yaw'] = _ApiClient.default.convertToType(data['yaw'], Object);
      }
      if (data.hasOwnProperty('landmarks_score')) {
        obj['landmarks_score'] = _ApiClient.default.convertToType(data['landmarks_score'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>FaceAnalysisAttributes</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>FaceAnalysisAttributes</code>.
   */
  static validateJSON(data) {
    // validate the optional field `masked`
    if (data['masked']) {
      // data not null
      _FaceAttribute.default.validateJSON(data['masked']);
    }
    // validate the optional field `gender`
    if (data['gender']) {
      // data not null
      _FaceAttribute.default.validateJSON(data['gender']);
    }
    // validate the optional field `age_group`
    if (data['age_group']) {
      // data not null
      _FaceAttribute.default.validateJSON(data['age_group']);
    }
    // validate the optional field `lower_occlusion`
    if (data['lower_occlusion']) {
      // data not null
      _FaceAttribute.default.validateJSON(data['lower_occlusion']);
    }
    // validate the optional field `liveness`
    if (data['liveness']) {
      // data not null
      _FaceAttribute.default.validateJSON(data['liveness']);
    }
    return true;
  }
}

/**
 * @member {module:model/FaceAttribute} masked
 */
FaceAnalysisAttributes.prototype['masked'] = undefined;

/**
 * @member {module:model/FaceAttribute} gender
 */
FaceAnalysisAttributes.prototype['gender'] = undefined;

/**
 * @member {module:model/FaceAttribute} age_group
 */
FaceAnalysisAttributes.prototype['age_group'] = undefined;

/**
 * @member {module:model/FaceAttribute} lower_occlusion
 */
FaceAnalysisAttributes.prototype['lower_occlusion'] = undefined;

/**
 * @member {module:model/FaceAttribute} liveness
 */
FaceAnalysisAttributes.prototype['liveness'] = undefined;

/**
 * A score indicating the quality of the face image
 * @member {Object} face_score
 */
FaceAnalysisAttributes.prototype['face_score'] = undefined;

/**
 * Observed pitch of the face in the image (in degrees)
 * @member {Object} pitch
 */
FaceAnalysisAttributes.prototype['pitch'] = undefined;

/**
 * Observed yaw of the face in the image (in degrees)
 * @member {Object} yaw
 */
FaceAnalysisAttributes.prototype['yaw'] = undefined;

/**
 * @member {Object} landmarks_score
 */
FaceAnalysisAttributes.prototype['landmarks_score'] = undefined;
var _default = exports.default = FaceAnalysisAttributes;