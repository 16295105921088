"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Bbox = _interopRequireDefault(require("./Bbox"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Frames Storage API
 * Frames Storage API gives access to the frames of recorded appearances from cameras analyzed by the application.  It allows to read and remove frames that associated with an appearance record.
 *
 * The version of the OpenAPI document: 0.34.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The SavedFrame model module.
 * @module model/SavedFrame
 * @version 0.34.0
 */
class SavedFrame {
  /**
   * Constructs a new <code>SavedFrame</code>.
   * @alias module:model/SavedFrame
   */
  constructor() {
    SavedFrame.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>SavedFrame</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SavedFrame} obj Optional instance to populate.
   * @return {module:model/SavedFrame} The populated <code>SavedFrame</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SavedFrame();
      if (data.hasOwnProperty('image')) {
        obj['image'] = _ApiClient.default.convertToType(data['image'], 'String');
      }
      if (data.hasOwnProperty('bbox')) {
        obj['bbox'] = _Bbox.default.constructFromObject(data['bbox']);
      }
      if (data.hasOwnProperty('frame_id')) {
        obj['frame_id'] = _ApiClient.default.convertToType(data['frame_id'], 'Number');
      }
      if (data.hasOwnProperty('frame_record_id')) {
        obj['frame_record_id'] = _ApiClient.default.convertToType(data['frame_record_id'], 'String');
      }
      if (data.hasOwnProperty('sequence_id')) {
        obj['sequence_id'] = _ApiClient.default.convertToType(data['sequence_id'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>SavedFrame</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SavedFrame</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['image'] && !(typeof data['image'] === 'string' || data['image'] instanceof String)) {
      throw new Error("Expected the field `image` to be a primitive type in the JSON string but got " + data['image']);
    }
    // validate the optional field `bbox`
    if (data['bbox']) {
      // data not null
      _Bbox.default.validateJSON(data['bbox']);
    }
    // ensure the json data is a string
    if (data['frame_record_id'] && !(typeof data['frame_record_id'] === 'string' || data['frame_record_id'] instanceof String)) {
      throw new Error("Expected the field `frame_record_id` to be a primitive type in the JSON string but got " + data['frame_record_id']);
    }
    return true;
  }
}

/**
 * Base64 encoded frame image
 * @member {String} image
 */
SavedFrame.prototype['image'] = undefined;

/**
 * @member {module:model/Bbox} bbox
 */
SavedFrame.prototype['bbox'] = undefined;

/**
 * frame_id of this frame, corresponds to the broadcast frame id this frame had while streaming
 * @member {Number} frame_id
 */
SavedFrame.prototype['frame_id'] = undefined;

/**
 * DB record_id of this frame
 * @member {String} frame_record_id
 */
SavedFrame.prototype['frame_record_id'] = undefined;

/**
 * The sequence_id of the retrieved frame
 * @member {Number} sequence_id
 */
SavedFrame.prototype['sequence_id'] = undefined;
var _default = exports.default = SavedFrame;