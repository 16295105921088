import { useCallback, useContext, useState } from "react";

import { connect } from "react-redux";
import { Progress, Toast, ToastBody, ToastHeader } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { FiMinimize2 } from "react-icons/fi";
import { AiOutlineExpand } from "react-icons/ai";
import Button from '@mui/material/Button';

import { abortPoiImportTitle, acceptAbortConfirmation, importLogTitle, poisImportAbortConfirmation, poisImportFinishedTittle, poisImportTittle } from "../../../../appConfig/Strings";
import ApiContext from "../../../Logic/Context/apiContext";
import store from "../../../../appConfig/configureStore";
import Timer from "../../Common/UtilComponents/Timer";
import { routesUrl } from "../../Common/Layout/SideBar/Routes";
import { SetSuspendSignal, Terminate } from "../../../Logic/WebWorkers/BaseRunners/ManagingWebWorker";
import { abortRunner, closeFloatingModal, runnersNames } from "../../../Logic/Redux/Stores/BaseRunnersStore";
import FilledBtn from "@/Components/Common/Buttons/FilledBtn";
import BaseModal from "@/Components/Common/Modals/BaseModal/BaseModal";

function PoiImportProgressBar({ poiImporter }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [isMinimize, setIsMinimize] = useState(false);

    const closeProgressBar = useCallback((event) => {
        event.preventDefault();
        store.dispatch(closeFloatingModal({
            runnerName: runnersNames.poiImporter
        }));
    }, []);

    const openPoiImportPage = useCallback((event) => {
        event.preventDefault();
        navigate(routesUrl.importPois);
        store.dispatch(closeFloatingModal({
            runnerName: runnersNames.poiImporter
        }));
    }, [navigate]);

    const toggleMinimize = useCallback((event) => {
        event.preventDefault();
        setIsMinimize(state => !state);
    }, []);

    return pathname !== routesUrl.importPois && <>
        {poiImporter.showFloatingModal && <Toast className="poiImporterProgressBar">
            <ToastHeader className="block" tag="div"
                tagClassName="flex items-center justify-between">
                <span>{poiImporter.finished ? poisImportFinishedTittle : poisImportTittle}</span>
                {poiImporter.finished ?
                    <>
                        <Button variant="outlined" onClick={openPoiImportPage}>{importLogTitle}</Button>
                        <Button variant="outlined" color="error" onClick={closeProgressBar}><FaTimes /></Button>
                    </> :
                    <div className="flex flex-col gap-2">
                        <div >
                            <Button variant="outlined" onClick={toggleMinimize}>
                                {isMinimize ? <AiOutlineExpand /> : <FiMinimize2 />}
                            </Button>
                            <Button variant="outlined" color="error" onClick={closeProgressBar}>
                                <FaTimes />
                            </Button>
                        </div>
                        <AbortImportButtonWithModal />
                    </div>
                }
            </ToastHeader>
            {!isMinimize && <ToastBody className="flex flex-col items-center justify-center">
                {!poiImporter.finished && <>
                    <span>{poiImporter.progress} %</span>
                    <Timer remainingTime={poiImporter.remainingTime} />
                    <Progress className="w-100 mt-1" value={poiImporter.progress}
                        max={100}>{poiImporter.progress} %</Progress>
                </>}
            </ToastBody>}
        </Toast>}
    </>;
}

const mapStateToProps = (state) => ({
    poiImporter: state.BaseRunnersStore.poiImporter
});

export default connect(mapStateToProps, null)(PoiImportProgressBar);

export function AbortImportButtonWithModal() {
    const { clientsManager } = useContext(ApiContext);
    const [confirmationIsOpen, setOpenConfirmation] = useState(false);

    const openConfirmModal = useCallback((event) => {
        event.preventDefault();
        setOpenConfirmation(true);
        SetSuspendSignal(true, clientsManager.workersClient.poisImporterWorker);
    }, [clientsManager]);

    const abortPOIsImport = useCallback((userSelection) => {
        if (userSelection) {
            Terminate(clientsManager.workersClient.poisImporterWorker);
            store.dispatch(abortRunner({
                runnerName: runnersNames.poiImporter
            }));
        } else
            SetSuspendSignal(false, clientsManager.workersClient.poisImporterWorker);
        setOpenConfirmation(false);
    }, [clientsManager]);

    return (
        <div className="flex">
            <FilledBtn onClick={openConfirmModal} className="!w-32">
                {abortPoiImportTitle}
            </FilledBtn>
            <BaseModal
                isOpen={confirmationIsOpen}
                handleLeftBtnAction={() => abortPOIsImport(false)}
                handleRightBtnAction={() => abortPOIsImport(true)}
                rightBtnText={acceptAbortConfirmation}
                onClickHeaderCancel={() => abortPOIsImport(false)}
            >
                {poisImportAbortConfirmation}
            </BaseModal>
        </div>
    );
}