"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _BatchPOIIDResponse = _interopRequireDefault(require("../model/BatchPOIIDResponse"));
var _BatchResponse = _interopRequireDefault(require("../model/BatchResponse"));
var _CreatePOIsResponse = _interopRequireDefault(require("../model/CreatePOIsResponse"));
var _FacesListResponse = _interopRequireDefault(require("../model/FacesListResponse"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
var _GetPOIsResponse = _interopRequireDefault(require("../model/GetPOIsResponse"));
var _ListAllPOIsResponse = _interopRequireDefault(require("../model/ListAllPOIsResponse"));
var _SearchPOIsResponse = _interopRequireDefault(require("../model/SearchPOIsResponse"));
var _UpdatePOIResponse = _interopRequireDefault(require("../model/UpdatePOIResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras POI-DB API
 * ## POI-DB API - The POI DB (Persons of Interest Database) includes the following types of data resources, which are controlled by the **POI & Watchlist** API. #### Watchlist: - Defines a collection of POIs, with certain parameters (e.g. blacklist/whitelist). - Watchlists can exist without POIs - It is possible to remove all POIs from a Watchlist, and the Watchlist remains in the system as an empty watchlist. - A watchlist can be removed from the system only if it is empty. #### POI: - **Person of Interest** - a person entity that can be recognized by the system.  - This data record also includes metadata added by the operator such as a display name etc. - POIs contain **POI Faces** - A POI Face is a representation of a single face image of a POI. They are represented by unique signatures, which allow the system to recognize faces of the same person. Transforming a face image to a signature is an irreversible process, so that a face image cannot be deduced from a signature. - The POI Faces include other metadata regarding this specific face and may also store the face image crop itself. - A POI Face is part of its POI - it cannot be associated with another POI and cannot exist independently. - POI Faces are removed if their POI is removed. - On the other hand, POIs can exist independently of faces - Removing the last face from a POI is allowed, and the POI remains in the system. - POIs can be associated with more than 1 Watchlist. #### Privacy The POI DB can be configured to not allow saving sensitive data, such as-  - The POI Faces' crops - The POI display images - Some of the POI Faces' metadata - etc  ## Face API - The **Face API** is used to perform analysis processing operations on input provided by the API user, not necessarily in relation to the data currently existing in the POI DB. - Such operations are for example analyzing the properties of a face in an image given by the user, detecting faces in images provided by the user etc.
 *
 * The version of the OpenAPI document: 0.37.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* POI service.
* @module api/POIApi
* @version 0.37.1
*/
class POIApi {
  /**
  * Constructs a new POIApi. 
  * @alias module:api/POIApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addFacesToPOI operation.
   * @callback module:api/POIApi~addFacesToPOICallback
   * @param {String} error Error message, if any.
   * @param {module:model/FacesListResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add Faces To Poi
   * Add faces to the POI. POI DB saves face metadata & an irreversible signature representing the face (face image cannot be re-obtained from signature). Saving face crop image is optional.
   * @param {Object} poiId 
   * @param {Object} body 
   * @param {module:api/POIApi~addFacesToPOICallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/FacesListResponse}
   */
  addFacesToPOI(poiId, body, callback) {
    let postBody = body;
    // verify the required parameter 'poiId' is set
    if (poiId === undefined || poiId === null) {
      throw new Error("Missing the required parameter 'poiId' when calling addFacesToPOI");
    }
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling addFacesToPOI");
    }
    let pathParams = {
      'poi_id': poiId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _FacesListResponse.default;
    return this.apiClient.callApi('/poi_db/poi/{poi_id}/add_faces/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the addPOIToWatchlist operation.
   * @callback module:api/POIApi~addPOIToWatchlistCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BatchPOIIDResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add Pois To Watchlist
   * Add POIs to watchlist. If POI is already associated with watchlist there will not be an error (it will stay associated to watchlist)
   * @param {Object} body 
   * @param {module:api/POIApi~addPOIToWatchlistCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BatchPOIIDResponse}
   */
  addPOIToWatchlist(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling addPOIToWatchlist");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _BatchPOIIDResponse.default;
    return this.apiClient.callApi('/poi_db/poi/watchlist_add/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the addPOIs operation.
   * @callback module:api/POIApi~addPOIsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/CreatePOIsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Create Pois
   * Create new POI entries. Operation will fail if POI's required watchlist does not exist in DB
   * @param {Object} body 
   * @param {module:api/POIApi~addPOIsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/CreatePOIsResponse}
   */
  addPOIs(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling addPOIs");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _CreatePOIsResponse.default;
    return this.apiClient.callApi('/poi_db/poi/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getFacesOfPOI operation.
   * @callback module:api/POIApi~getFacesOfPOICallback
   * @param {String} error Error message, if any.
   * @param {module:model/FacesListResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Faces Of Poi
   * Get info for list of faces of required POI.
   * @param {Object} poiId 
   * @param {Object} body 
   * @param {module:api/POIApi~getFacesOfPOICallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/FacesListResponse}
   */
  getFacesOfPOI(poiId, body, callback) {
    let postBody = body;
    // verify the required parameter 'poiId' is set
    if (poiId === undefined || poiId === null) {
      throw new Error("Missing the required parameter 'poiId' when calling getFacesOfPOI");
    }
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling getFacesOfPOI");
    }
    let pathParams = {
      'poi_id': poiId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _FacesListResponse.default;
    return this.apiClient.callApi('/poi_db/poi/{poi_id}/get_faces/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the getPOIs operation.
   * @callback module:api/POIApi~getPOIsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GetPOIsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Pois
   * Get info on specific POIs
   * @param {Object} body 
   * @param {module:api/POIApi~getPOIsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GetPOIsResponse}
   */
  getPOIs(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling getPOIs");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GetPOIsResponse.default;
    return this.apiClient.callApi('/poi_db/poi/get/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the listAllPOIs operation.
   * @callback module:api/POIApi~listAllPOIsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ListAllPOIsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List All Pois
   * List all POIs
   * @param {Object} opts Optional parameters
   * @param {Object} opts.countPois should the request count the POIs
   * @param {Object} opts.afterId 
   * @param {Object} opts.limit 
   * @param {module:api/POIApi~listAllPOIsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ListAllPOIsResponse}
   */
  listAllPOIs(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {
      'count_pois': opts['countPois'],
      'after_id': opts['afterId'],
      'limit': opts['limit']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _ListAllPOIsResponse.default;
    return this.apiClient.callApi('/poi_db/poi/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeFacesFromPOI operation.
   * @callback module:api/POIApi~removeFacesFromPOICallback
   * @param {String} error Error message, if any.
   * @param {module:model/BatchResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Faces From Poi
   * Remove faces of required POI from the DB. If this operation removes the last faces of the POI, the POI still exists in the system but is not matchable (or searchable) anymore.
   * @param {Object} poiId 
   * @param {Object} body 
   * @param {module:api/POIApi~removeFacesFromPOICallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BatchResponse}
   */
  removeFacesFromPOI(poiId, body, callback) {
    let postBody = body;
    // verify the required parameter 'poiId' is set
    if (poiId === undefined || poiId === null) {
      throw new Error("Missing the required parameter 'poiId' when calling removeFacesFromPOI");
    }
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling removeFacesFromPOI");
    }
    let pathParams = {
      'poi_id': poiId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _BatchResponse.default;
    return this.apiClient.callApi('/poi_db/poi/{poi_id}/remove_faces/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removePOIFromWatchlist operation.
   * @callback module:api/POIApi~removePOIFromWatchlistCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BatchPOIIDResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Pois From Watchlist
   * Remove POIs from watchlist.
   * @param {Object} body 
   * @param {module:api/POIApi~removePOIFromWatchlistCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BatchPOIIDResponse}
   */
  removePOIFromWatchlist(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling removePOIFromWatchlist");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _BatchPOIIDResponse.default;
    return this.apiClient.callApi('/poi_db/poi/watchlist_remove/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removePOIs operation.
   * @callback module:api/POIApi~removePOIsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BatchPOIIDResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove Pois
   * Deletes POIs and all crops associated with those POIs from the database.
   * @param {Object} body 
   * @param {module:api/POIApi~removePOIsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BatchPOIIDResponse}
   */
  removePOIs(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling removePOIs");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _BatchPOIIDResponse.default;
    return this.apiClient.callApi('/poi_db/poi/remove/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the searchPOIs operation.
   * @callback module:api/POIApi~searchPOIsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/SearchPOIsResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Search Pois By Name
   * Search POIs whose display name matches (contains) the requested display name. The search is not case-sensitive
   * @param {Object} body 
   * @param {module:api/POIApi~searchPOIsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/SearchPOIsResponse}
   */
  searchPOIs(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling searchPOIs");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _SearchPOIsResponse.default;
    return this.apiClient.callApi('/poi_db/poi/name_search/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the updatePOI operation.
   * @callback module:api/POIApi~updatePOICallback
   * @param {String} error Error message, if any.
   * @param {module:model/UpdatePOIResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update Poi
   * Update the POI properties (not including watchlist/crop associations)
   * @param {Object} poiId 
   * @param {Object} body 
   * @param {module:api/POIApi~updatePOICallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/UpdatePOIResponse}
   */
  updatePOI(poiId, body, callback) {
    let postBody = body;
    // verify the required parameter 'poiId' is set
    if (poiId === undefined || poiId === null) {
      throw new Error("Missing the required parameter 'poiId' when calling updatePOI");
    }
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updatePOI");
    }
    let pathParams = {
      'poi_id': poiId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _UpdatePOIResponse.default;
    return this.apiClient.callApi('/poi_db/poi/{poi_id}/', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = POIApi;