import { useCallback, useMemo } from "react";

import { omit } from "lodash";

import { DurationSelectorModel } from "./DurationSelector.model";
import BaseInput from "../BaseInput";

export function DurationSelector({ value, onChange, timeUnitsMap, hideUnitsArray = [], className, inputSx, model }) {
    const filteredTimeInputMap = useMemo(() => omit(timeUnitsMap, hideUnitsArray),
        [hideUnitsArray, timeUnitsMap]);

    const handleChangeInput = useCallback((event, timeUnit) => {
        event.preventDefault();
        const currentValue = event.target.valueAsNumber;
        const timeUnitValue = currentValue > 0 ? currentValue : (filteredTimeInputMap[timeUnit]?.min || 0);
        const changedValue = { ...value, [timeUnit]: timeUnitValue };
        onChange && onChange(changedValue);
    }, [filteredTimeInputMap, onChange, value]);

    return (
        <div className={`${className} flex w-full flex-row gap-3`}>
            {Object.keys(filteredTimeInputMap).map((timeUnit) => {
                return (
                    <div key={timeUnit} className="flex max-w-24 flex-col items-center gap-2">
                        <BaseInput
                            label={timeUnit}
                            model={{ ...DurationSelectorModel, ...model }}
                            value={value[timeUnit]?.toString()}
                            innerClassName="text-center max-h-4"
                            onChange={(event) => handleChangeInput(event, timeUnit)}
                            sx={inputSx}
                        />
                    </div>
                );
            })}
        </div>
    );
}