import { slowJourneyLabel, wrongJourneyLabel } from "src/appConfig/Strings";
import { baseCamerasModel } from "../../BaseModels";

export const personJourneyFormFieldsNames = {
    completedJourney: "completed_journey",
    alertingJourneyDuration: "alerting_journey_duration",
    journeyStartCamera: "start_camera",
    journeyEndCamera: "end_cameras",
};

export const completedJourneyOptions = [
    { name: slowJourneyLabel, value: false },
    { name: wrongJourneyLabel, value: true },
];

export const PersonJourneyInsightFormModel = {
    [personJourneyFormFieldsNames.completedJourney]: {
        name: personJourneyFormFieldsNames.completedJourney,
        textField: "name",
        dataKey: "value",
        required: true,
    },
    [personJourneyFormFieldsNames.journeyStartCamera]: {
        name: personJourneyFormFieldsNames.journeyStartCamera,
        textField: "description",
        dataKey: "camera_id",
        required: true,
    },
    [personJourneyFormFieldsNames.journeyEndCamera]: {
        name: personJourneyFormFieldsNames.journeyEndCamera,
        ...baseCamerasModel
    },
    [personJourneyFormFieldsNames.alertingJourneyDuration]: {
        name: personJourneyFormFieldsNames.alertingJourneyDuration,
        type: "number",
        min: 0,
        required: true,
    },
};