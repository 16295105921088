import { useCallback, useContext, useEffect, useMemo } from "react";

import { isEmpty, upperFirst } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Paper } from "@mui/material";
import FocusTrap from "@mui/material/Unstable_TrapFocus";
import Loader from "react-loaders";

import { analyzeInterface } from "./AnalyzeStep";
import BaseGrid from "../../MUIGrids/BaseGrid/BaseGrid";
import GridItem from "../../MUIGrids/BaseGrid/GridItem";
import { addFaceToPOI, addFaceToPoi, createNewPoi, poiDuplicationNote, searchDuplicationsNote } from "src/appConfig/Strings";
import useDuplicatePOI from "@/Logic/Hooks/useDuplicatePOI";
import { progressStepperInterface } from "../ProgressStepper.model";
import { getPoisRequest } from "@/Logic/Hooks/usePrepareCreateEditPoi";
import ApiContext from "@/Logic/Context/apiContext";
import POICard from "@/Components/POIs/POIData/POI/POICard";
import ImageWrapper from "../../Wrappers/ImageWrapper";

export const checkDuplicateInterface = {
    imgData: "imgData",
    facesList: "facesList",
    selectedPoiId: "selectedPoiId",
};

function CheckDuplicateStep({ prevStepData, currData, setCurrData }) {
    const { clientsManager } = useContext(ApiContext);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const editedPoiId = searchParams.get("poiId");
    const preparedImg = prevStepData[analyzeInterface.analyzedImg];
    const { searchResult, isLoading, error, responseMessage } = useDuplicatePOI(editedPoiId, preparedImg);
    const noDuplicateFound = useMemo(() => Array.isArray(searchResult) && isEmpty(searchResult), [searchResult]);

    const handleAddFaceToDuplicate = useCallback(async (poiId) => {
        const reqSucceed = await getPoisRequest(clientsManager, poiId);
        if (!reqSucceed) {
            return;
        }
        navigate(`${location.pathname}?poiId=${poiId}`);
    }, [clientsManager, location.pathname, navigate]);

    const handleGridItemClick = useCallback((poiId) => {
        const isSamePoi = currData[checkDuplicateInterface.selectedPoiId] === poiId;

        setCurrData({
            newCompData: {
                [checkDuplicateInterface.selectedPoiId]: isSamePoi ? null : poiId,
                [progressStepperInterface.rightBtnText]: isSamePoi ? (editedPoiId ? addFaceToPOI : createNewPoi) : addFaceToPoi,
            },
            handleNext: isSamePoi ? undefined : () => handleAddFaceToDuplicate(poiId),
        });
    }, [currData, handleAddFaceToDuplicate, setCurrData, editedPoiId]);

    const poisCardsData = useMemo(() => {
        if (isLoading || !Array.isArray(searchResult)) {
            return;
        }

        return searchResult.map((matchedPOI) => (
            <GridItem
                id={matchedPOI.poi_id}
                key={matchedPOI.poi_id}
                onClick={() => handleGridItemClick(matchedPOI.poi_id)}
                className="pb-2"
            >
                <Paper elevation={5}>
                    <POICard
                        poiImg={matchedPOI.display_img}
                        poiFooterText={matchedPOI.display_name}
                        className={`cursor-pointer ${currData[checkDuplicateInterface.selectedPoiId] === matchedPOI.poi_id && "rounded-none border-2 border-blue-500"}`}
                        footerClassName="text-base"
                        defaultFaceCropClassName="!bg-transparent !fill-slate-blue"
                    />
                </Paper>
            </GridItem>
        ));
    }, [currData, handleGridItemClick, isLoading, searchResult]);

    useEffect(() => {
        if (isLoading || !searchResult || error) {
            return;
        }

        setCurrData({
            newCompData: {
                [checkDuplicateInterface.facesList]: searchResult,
            },
            autoTriggerNextStep: noDuplicateFound,
            newIsFinal: !noDuplicateFound,
        });
    }, [error, isLoading, noDuplicateFound, searchResult, setCurrData]);

    useEffect(() => {
        if (!isEmpty(currData)) {
            return;
        }

        setCurrData({
            newCompData: {
                [checkDuplicateInterface.imgData]: preparedImg,
                [progressStepperInterface.rightBtnText]: editedPoiId ? addFaceToPOI : createNewPoi,
            },
        });
    }, []);

    const checkDuplicationRes = useMemo(() => {
        let duplicationsResults;
        if (isLoading || noDuplicateFound) {
            duplicationsResults = (
                <div className="flex w-full flex-col items-center justify-start gap-3">
                    <Paper elevation={5}>
                        <ImageWrapper className="h-52 !w-48 rounded" src={prevStepData[analyzeInterface.analyzedImg]} />
                    </Paper>
                    <Alert className="flex h-fit w-full items-center justify-center" severity="info">
                        <div className="w-full text-center text-base">
                            {searchDuplicationsNote}
                        </div>
                    </Alert>
                    <div className="flex h-full min-h-28 flex-1 items-center justify-center">
                        <Loader type="ball-spin-fade-loader" />
                    </div>
                </div>
            );

        } else if (error) {
            duplicationsResults = (
                <div className="flex size-full items-center justify-center">
                    <FocusTrap disableEnforceFocus open >
                        <Alert className="main-text-style mb-2 flex w-full items-center justify-center" severity="error" tabIndex="0" data-testid="error-alert">
                            {upperFirst(responseMessage)}
                        </Alert>
                    </FocusTrap>
                </div>
            );
        } else if (Array.isArray(searchResult) && !isEmpty(searchResult)) {
            duplicationsResults = (
                <div className="flex w-full flex-col items-center gap-3">
                    <section className="flex flex-col items-center justify-start">
                        <Paper elevation={5}>
                            <ImageWrapper className="h-52 !w-48 rounded" src={prevStepData[analyzeInterface.analyzedImg]} />
                        </Paper>
                    </section>
                    <section className="w-full">
                        <Alert className="flex w-full items-center justify-center" severity="warning">
                            <div className="w-full text-center text-base">
                                {poiDuplicationNote}
                            </div>
                        </Alert>
                    </section>
                    <section className="flex size-full items-center justify-center">
                        <BaseGrid gridSpacing={1.5} renderedItems={poisCardsData} gridClassName="items-start justify-center h-full max-h-64 pt-1" />
                    </section>
                </div>
            );
        }

        return duplicationsResults;
    }, [error, isLoading, noDuplicateFound, poisCardsData, prevStepData, responseMessage, searchResult]);

    return (
        <div className="flex size-full max-h-tooltip-box flex-col items-center">
            {checkDuplicationRes}
        </div>
    );
}

export default CheckDuplicateStep;