import {
    detected,
    displayAge,
    displayFaceImg,
    displayGender,
    displayPoiId,
    displayPoiImg,
    displayPoiNotes,
    displayWatchlistNotes,
    generateDetectionImg,
    generateMatchedEvents,
    generateNonAuthEvents,
    privacyProfilesNameLabel,
    saveAge,
    saveDetectionEvents,
    saveFaceImg,
    saveGender,
    saveMatchedEvents,
    saveNonAuthEvents,
    savePoiId,
    savePoiImg,
    savePoiNotes,
    saveSignature,
    saveWatchlistNotes,
    authorizedList,
    matchInterest,
    notAuthorized,
    sendSignature,
    detectedTooltipTxt,
    matchInterestTooltipTxt,
    authorizedListTooltipTxt,
    notAuthorizedListTooltipTxt
} from "src/appConfig/Strings";

const appearancesPrivacyFormFieldsNames = {
    privacyName: "name",
    history: "history",
    settings: "settings",
    matchInterest: "match_interest",
    authorized: "authorized",
    notAuthorized: "not_authorized",
    detected: "detected",
    stream: "stream",
    saveEvent: "save_event",
    saveSignature: "save_signature",
    saveFaceImage: "save_face_image",
    saveAge: "save_age",
    saveGender: "save_gender",
    saveWatchlistNotes: "save_watchlist_notes",
    saveMatchDisplayName: "save_match_display_name",
    saveMatchDisplayImage: "save_match_display_image",
    saveMatchPoiNotes: "save_match_poi_notes",
    sendEvent: "send_event",
    sendSignature: "send_signature",
    showFaceImage: "show_face_image",
    showAge: "show_age",
    showGender: "show_gender",
    showWatchlistNotes: "show_watchlist_notes",
    showMatchDisplayName: "show_match_display_name",
    showMatchDisplayImage: "show_match_display_image",
    showMatchPoiNotes: "show_match_poi_notes",
    profileId: "profile_id",
    systemProfile: "system_profile"
};

const appearancesPrivacyFormNameMapping = {
    [appearancesPrivacyFormFieldsNames.showFaceImage]: displayFaceImg,
    [appearancesPrivacyFormFieldsNames.showAge]: displayAge,
    [appearancesPrivacyFormFieldsNames.showGender]: displayGender,
    [appearancesPrivacyFormFieldsNames.showWatchlistNotes]: displayWatchlistNotes,
    [appearancesPrivacyFormFieldsNames.showMatchDisplayName]: displayPoiId,
    [appearancesPrivacyFormFieldsNames.showMatchDisplayImage]: displayPoiImg,
    [appearancesPrivacyFormFieldsNames.showMatchPoiNotes]: displayPoiNotes,
    [appearancesPrivacyFormFieldsNames.saveFaceImage]: saveFaceImg,
    [appearancesPrivacyFormFieldsNames.saveAge]: saveAge,
    [appearancesPrivacyFormFieldsNames.saveGender]: saveGender,
    [appearancesPrivacyFormFieldsNames.saveWatchlistNotes]: saveWatchlistNotes,
    [appearancesPrivacyFormFieldsNames.saveMatchDisplayName]: savePoiId,
    [appearancesPrivacyFormFieldsNames.saveMatchDisplayImage]: savePoiImg,
    [appearancesPrivacyFormFieldsNames.saveMatchPoiNotes]: savePoiNotes,
    [appearancesPrivacyFormFieldsNames.saveSignature]: saveSignature,
    [appearancesPrivacyFormFieldsNames.sendSignature]: sendSignature,

    [appearancesPrivacyFormFieldsNames.stream]: {
        [appearancesPrivacyFormFieldsNames.detected]: generateDetectionImg,
        [appearancesPrivacyFormFieldsNames.matchInterest]: generateMatchedEvents,
        [appearancesPrivacyFormFieldsNames.authorized]: generateMatchedEvents,
        [appearancesPrivacyFormFieldsNames.notAuthorized]: generateNonAuthEvents,
    },
    [appearancesPrivacyFormFieldsNames.history]: {
        [appearancesPrivacyFormFieldsNames.detected]: saveDetectionEvents,
        [appearancesPrivacyFormFieldsNames.matchInterest]: saveMatchedEvents,
        [appearancesPrivacyFormFieldsNames.authorized]: saveMatchedEvents,
        [appearancesPrivacyFormFieldsNames.notAuthorized]: saveNonAuthEvents,
    }
};

const appearancePrivacyNameMapping = {
    [appearancesPrivacyFormFieldsNames.detected]: {
        name: detected,
        tooltipTxt: detectedTooltipTxt
    },
    [appearancesPrivacyFormFieldsNames.matchInterest]: {
        name: matchInterest,
        tooltipTxt: matchInterestTooltipTxt
    },
    [appearancesPrivacyFormFieldsNames.authorized]: {
        name: authorizedList,
        tooltipTxt: authorizedListTooltipTxt
    },
    [appearancesPrivacyFormFieldsNames.notAuthorized]: {
        name: notAuthorized,
        tooltipTxt: notAuthorizedListTooltipTxt
    }
};

const AppearancesPrivacyFormModel = {
    [appearancesPrivacyFormFieldsNames.privacyName]: {
        name: appearancesPrivacyFormFieldsNames.privacyName,
        type: "text",
        placeholder: privacyProfilesNameLabel,
        required: true,
    }
};

export { AppearancesPrivacyFormModel, appearancesPrivacyFormFieldsNames, appearancesPrivacyFormNameMapping, appearancePrivacyNameMapping };