import { useContext, useEffect, useMemo } from "react";

import { isEmpty } from "lodash";

import store from "src/appConfig/configureStore";
import ApiContext from "../Context/apiContext";
import { PoiFormFieldsNames } from "@/Components/POIs/CreateEditPOI/CreateEditPOI.model";
import { VideoDeviceType } from "src/appConfig/Strings";
import { clearOpenPoi } from "../Redux/Stores/POIStore";

export async function getPoisRequest(clientsManager, poiId) {
    store.dispatch(clearOpenPoi());
    return await clientsManager.poisClient.getSinglePoi(poiId);
}

function usePrepareCreateEditPoi(poiId, openPoi, currWatchlistInfo, shouldFetchPOIData) {
    const { watchlists } = PoiFormFieldsNames;
    const { clientsManager } = useContext(ApiContext);

    const updatedOpenPoi = useMemo(() => {
        const shouldApplySelectWl = !poiId && !isEmpty(currWatchlistInfo.watchlistId);

        return {
            ...openPoi,
            [watchlists]: new Set(shouldApplySelectWl ? [currWatchlistInfo.watchlistId] : openPoi[watchlists]),
        };
    }, [currWatchlistInfo.watchlistId, openPoi, poiId, watchlists]);

    useEffect(() => {
        async function prepareRequest() {
            await clientsManager.watchlistsClient.listAllWatchlists(true);
        }

        prepareRequest();
    }, []);

    useEffect(() => {
        async function getPoi() {
            await getPoisRequest(clientsManager, poiId);
        }

        if (poiId) {
            getPoi();
        }
    }, [shouldFetchPOIData]);

    return { updatedOpenPoi };
}

export async function getAllUSBCameras() {
    const devices = await navigator.mediaDevices.enumerateDevices();

    return devices.filter((device) => device.kind === VideoDeviceType);
}

export default usePrepareCreateEditPoi;