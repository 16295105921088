import { useState } from "react";

import { Collapse, Tooltip } from "@mui/material";
import ExpandIconBtn from "../Buttons/ExpandIconBtn";

function CollapseDrawer({ label, children, isDisabled = false, isDrawerOpen = false, isDrawerLocked = false, drawerLockedTooltip, isLabelClickable = true, className }) {
    const [isOpen, setIsOpen] = useState(isDrawerOpen);

    const handleCollapse = () => {
        if (isDrawerLocked && isOpen) {
            return;
        }
        setIsOpen((prev) => !prev);
    };

    return (
        <div className={`mb-1 ${className}`}>
            {!isDisabled && (
                <div className="flex justify-center">
                    <div className="flex w-full items-center justify-between">
                        <div onClick={isLabelClickable ? handleCollapse : null} className={`grow ${isLabelClickable ? 'cursor-pointer' : ''}`}>
                            <span>{label}</span>
                        </div>
                        {isDrawerLocked && isOpen ? (
                            <Tooltip title={drawerLockedTooltip}>
                                <div>
                                    <ExpandIconBtn onClick={handleCollapse} isOpen={isOpen} />
                                </div>
                            </Tooltip>
                        ) : (
                            <ExpandIconBtn onClick={handleCollapse} isOpen={isOpen} />
                        )}
                    </div>
                </div>
            )}
            <Collapse in={isOpen} >
                {children}
            </Collapse>
        </div>
    );
}

export default CollapseDrawer;