"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PersonJourneyConfig model module.
 * @module model/PersonJourneyConfig
 * @version 0.37.0
 */
class PersonJourneyConfig {
  /**
   * Constructs a new <code>PersonJourneyConfig</code>.
   * @alias module:model/PersonJourneyConfig
   * @param completedJourney {Object} 
   * @param alertingJourneyDuration {Object} 
   * @param startCamera {Object} 
   */
  constructor(completedJourney, alertingJourneyDuration, startCamera) {
    PersonJourneyConfig.initialize(this, completedJourney, alertingJourneyDuration, startCamera);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, completedJourney, alertingJourneyDuration, startCamera) {
    obj['completed_journey'] = completedJourney;
    obj['alerting_journey_duration'] = alertingJourneyDuration;
    obj['start_camera'] = startCamera;
  }

  /**
   * Constructs a <code>PersonJourneyConfig</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PersonJourneyConfig} obj Optional instance to populate.
   * @return {module:model/PersonJourneyConfig} The populated <code>PersonJourneyConfig</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PersonJourneyConfig();
      if (data.hasOwnProperty('excluded_watchlists')) {
        obj['excluded_watchlists'] = _ApiClient.default.convertToType(data['excluded_watchlists'], Object);
      }
      if (data.hasOwnProperty('min_detection_width')) {
        obj['min_detection_width'] = _ApiClient.default.convertToType(data['min_detection_width'], Object);
      }
      if (data.hasOwnProperty('completed_journey')) {
        obj['completed_journey'] = _ApiClient.default.convertToType(data['completed_journey'], Object);
      }
      if (data.hasOwnProperty('alerting_journey_duration')) {
        obj['alerting_journey_duration'] = _ApiClient.default.convertToType(data['alerting_journey_duration'], Object);
      }
      if (data.hasOwnProperty('start_camera')) {
        obj['start_camera'] = _ApiClient.default.convertToType(data['start_camera'], Object);
      }
      if (data.hasOwnProperty('end_cameras')) {
        obj['end_cameras'] = _ApiClient.default.convertToType(data['end_cameras'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PersonJourneyConfig</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PersonJourneyConfig</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of PersonJourneyConfig.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
PersonJourneyConfig.RequiredProperties = ["completed_journey", "alerting_journey_duration", "start_camera"];

/**
 * List of Watchlists to exclude from the insight
 * @member {Object} excluded_watchlists
 */
PersonJourneyConfig.prototype['excluded_watchlists'] = undefined;

/**
 * @member {Object} min_detection_width
 */
PersonJourneyConfig.prototype['min_detection_width'] = undefined;

/**
 * @member {Object} completed_journey
 */
PersonJourneyConfig.prototype['completed_journey'] = undefined;

/**
 * @member {Object} alerting_journey_duration
 */
PersonJourneyConfig.prototype['alerting_journey_duration'] = undefined;

/**
 * @member {Object} start_camera
 */
PersonJourneyConfig.prototype['start_camera'] = undefined;

/**
 * @member {Object} end_cameras
 */
PersonJourneyConfig.prototype['end_cameras'] = undefined;
var _default = exports.default = PersonJourneyConfig;