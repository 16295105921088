import { useCallback, useContext, useMemo } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import ContextMenuButton from "@/Components/Common/Buttons/ContextMenuButton";
import ApiContext from "@/Logic/Context/apiContext";
import { removeRole, remove, removeRoleConfirmation, editRole, duplicate } from "src/appConfig/Strings";
import { dispatchToggleDialogEvent } from "@/Logic/Hooks/useDialog/useDialog";
import { checkIsSystemRole } from "@/Logic/ApiClients/RolesClient";
import { rolesSettingsDialogId } from "@/Logic/Hooks/useDialog/useDialog.model";

function RolesContextMenu({ row }) {
    const { clientsManager } = useContext(ApiContext);
    const navigate = useNavigate();
    const location = useLocation();

    const isSystemRole = useMemo(() => checkIsSystemRole(row.role_id), [row.role_id]);

    const setOptions = useCallback(() => {
        const options = {
            [editRole]: () => {
                navigate(`${location.pathname}?role_id=${row.role_id}`);
                dispatchToggleDialogEvent(rolesSettingsDialogId);
            },
            [duplicate]: () => {
                navigate(`${location.pathname}?duplicate=true&role_id=${row.role_id}`);
                dispatchToggleDialogEvent(rolesSettingsDialogId);
            }
        };

        if (!isSystemRole) {
            options[removeRole] = async () => {
                await clientsManager.rolesClient.deleteRole(row.role_id);
            };
        }
        return options;
    }, [clientsManager.rolesClient, isSystemRole, location.pathname, navigate, row.role_id]);

    const confirmationOptions = useMemo(() => {
        return { [removeRole]: { acceptButtonText: remove, confirmationText: removeRoleConfirmation } };
    }, []);

    const disabledOptions = useMemo(() => ({
        [editRole]: () => isSystemRole,
        [duplicate]: () => isSystemRole,
    }), [isSystemRole]);

    return (
        <ContextMenuButton options={setOptions()} confirmationOptions={confirmationOptions} disabledOptions={disabledOptions} />
    );
}

export default RolesContextMenu;