import { useCallback } from "react";

import { ReactComponent as SnapshotIcon } from "src/assets/Icons/Cameras/icon_snapshot.svg";
import { ReactComponent as FolderIcon } from "src/assets/Icons/UtilsIcons/icon_btn_folder.svg";

import { dispatchStepperEvent } from "../ProgressStepper/ProgressStepper";
import TooltipIconButton from "./TooltipIconButton";
import { captureImageLabel, uploadImageLabel } from "src/appConfig/Strings";

function ImageSelector({ imgSelectorId, cameraSelectedSteps, fileSelectedStep, iconClassName = "stroke-black", tooltipPlacement }) {
    const handleIconClick = useCallback((event, selectedSteps) => {
        event.preventDefault();
        event.stopPropagation();
        dispatchStepperEvent({ id: imgSelectorId, shouldOpenStepper: true, selectedSteps });
    }, [imgSelectorId]);

    return (
        <div className="flex flex-col gap-2">
            <div className="my-1 flex flex-row justify-center gap-2">
                <TooltipIconButton tooltipText={uploadImageLabel} buttonAction={(event) => handleIconClick(event, fileSelectedStep)} iconElement={<FolderIcon className={iconClassName} />} tooltipPlacement={tooltipPlacement} />
                <TooltipIconButton tooltipText={captureImageLabel} buttonAction={(event) => handleIconClick(event, cameraSelectedSteps)} iconElement={<SnapshotIcon className={iconClassName} />} tooltipPlacement={tooltipPlacement} />
            </div>
        </div>
    );
}

export default ImageSelector;