import { Suspense, useContext, useEffect } from "react";

import Loader from "react-loaders";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";

import ApiContext from "../Logic/Context/apiContext";
import useEventListener from "../Logic/Hooks/useEventListener/useEventListener";
import { IndexDBHandlerInterface } from "../Logic/WebWorkers/WorkersInterfaces";
import { routes, routesUrl } from "./Common/Layout/SideBar/Routes";
import Login from "./Login/Login";
import Header from "./Common/Layout/Header/Header";
import useCaseInjector from "../Logic/Hooks/useCaseInjector";
import { toastEventHandler } from "../Logic/WebWorkers/webWorkerEventHandlers";
import useSessionEndListener from "../Logic/Hooks/useSessionEndListener";
import CamerasList from "./Cameras/CamerasList/CamerasList";
import { loading } from "../../appConfig/Strings";
import SideNavigation from "./Common/Layout/SideBar/SideNavigation/SideNavigation";
import GlobalPopups from "./Common/UtilComponents/GlobalPopups";

function AppMain() {
    const loginStatus = useSelector((state) => state.ApiActionsStore.login);
    const { clientsManager } = useContext(ApiContext);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEventListener(clientsManager.eventsClientWorker);
    useEventListener(clientsManager.eventsClientWorker, toastEventHandler);
    useCaseInjector();
    useSessionEndListener();

    window.onbeforeunload = () => "";
    window.onunload = () => {
        clientsManager.workersClient.storageWorker.postMessage([IndexDBHandlerInterface.destroyFilesStore, []]);
    };

    useEffect(() => {
        if (loginStatus.user && !loginStatus.isLoggedIn) {
            const sessionEnded = true;
            clientsManager.logoutUserAndCleanup(sessionEnded);
        }
    }, [clientsManager, loginStatus.isLoggedIn, loginStatus.user]);

    if (!loginStatus.isLoggedIn) {
        return (
            <Login initializeClients={(hotIp, token, roleDataFilters) => {
                clientsManager.initializeClients(hotIp, token, roleDataFilters);
                navigate(routesUrl.cameraList);
            }}
            />
        );
    }

    const LoaderFallback = () => (
        <div className="loader-container">
            <div className="loader-container-inner">
                <div className="text-center">
                    <Loader type="ball-grid-cy" />
                </div>
                <h6 className="mt-3">{loading}</h6>
            </div>
        </div>
    );

    return (
        <div className="flex size-full flex-col">
            <div className="sticky top-0 z-10">
                <Header logoutUser={() => clientsManager.logoutUserAndCleanup()} />
            </div>
            <div className="flex grow">
                <div className="shrink-0">
                    <SideNavigation />
                </div>
                <div className="grow overflow-auto px-main-layout-padding-side py-main-layout-padding-top-bottom" >
                    <Suspense fallback={<LoaderFallback />}>
                        <Routes>
                            {Object.entries(routes).map(([path, Component]) => (
                                <Route key={path} path={path} element={<Component searchParams={searchParams} />} />
                            ))}
                            <Route path="*" element={<CamerasList />} />
                        </Routes>
                    </Suspense>
                </div>
                <GlobalPopups />
            </div>
        </div>
    );
}

export default AppMain;