import { useCallback, useEffect, useMemo } from "react";

import { v4 as uuid } from "uuid";
import { isNil } from "lodash";

import { stepperEvent } from "src/appConfig/customEvents";
import FacesList from "./PoiFacesList";
import { PoiFormFieldsNames } from "../CreateEditPOI.model";
import { stepperComponentsIds } from "@/Components/Common/ProgressStepper/ProgressStepper.model";
import { findStepDataIndex } from "@/Components/Common/ProgressStepper/ProgressStepper";

export const attachIdToFace = (stepperImgData) => {
    const face_id = uuid();
    const faceWithId = { [face_id]: { ...stepperImgData, face_id } };

    return { faceWithId, faceID: face_id };
};

function POIFacesManager({ poiId, formData, onFieldChange, setIsRequireGuardianConsent }) {
    const poiFaces = useMemo(() => formData.faces || {}, [formData.faces]);

    const handleRemovePoiFace = useCallback((faceId) => {
        const { [faceId]: _, ...remainingImages } = poiFaces;
        onFieldChange([PoiFormFieldsNames.faces], remainingImages);
    }, [onFieldChange, poiFaces]);

    const handleFaceValidation = useCallback((stepperImgData) => {
        if (stepperImgData.face_attributes?.face_requires_guardian_consent) {
            setIsRequireGuardianConsent(true);
            onFieldChange([PoiFormFieldsNames.consent], undefined);
        }

        if (!stepperImgData.isValidFace) {
            onFieldChange([PoiFormFieldsNames.allowLowQuality], true);
        }
    }, [onFieldChange, setIsRequireGuardianConsent]);

    const handleUpdateFaces = useCallback((stepperImgData) => {
        const { faceWithId } = attachIdToFace(stepperImgData);
        const updatedFaces = { ...formData.faces, ...faceWithId };
        onFieldChange([PoiFormFieldsNames.faces], updatedFaces);
    }, [formData.faces, onFieldChange]);

    useEffect(() => {
        const stepperEventListener = (event) => {
            const { selectedSteps, stepsData } = event.detail;
            if (!stepsData || !selectedSteps) {
                return;
            }

            const analyzeStepIndex = findStepDataIndex(selectedSteps, stepperComponentsIds.analyzeId);
            if (isNil(analyzeStepIndex) || isNil(stepsData[analyzeStepIndex]?.compData)) {
                return;
            }

            const { analyzedImg, isValidFace, faceAttributes } = stepsData[analyzeStepIndex].compData;

            const stepperImgData = {
                img: analyzedImg,
                isValidFace: isValidFace,
                face_attributes: faceAttributes
            };

            handleFaceValidation(stepperImgData);
            handleUpdateFaces(stepperImgData);
        };

        document.addEventListener(stepperEvent, stepperEventListener);
        return () => document.removeEventListener(stepperEvent, stepperEventListener);
    }, [handleFaceValidation, handleUpdateFaces]);

    useEffect(() => {
        const inCreateMode = !poiId;
        const poiFacesKeys = Object.keys(poiFaces);
        const hasOneImage = poiFacesKeys.length === 1;

        if (!inCreateMode || !hasOneImage) {
            return;
        }

        const [firstImageId] = poiFacesKeys;
        const firstImage = poiFaces[firstImageId]?.img;
        if (firstImage) {
            onFieldChange(PoiFormFieldsNames.displayImage, firstImage);
        }
    }, [onFieldChange, poiFaces, poiId]);

    return (
        <div className="mt-2">
            <FacesList handleRemovePoiFace={handleRemovePoiFace} poiFaces={poiFaces} />
        </div>
    );
}

export default POIFacesManager;