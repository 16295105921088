import { cameraStatuses } from "@/Logic/ApiClients/CamerasClient";
import LabeledCircularProgress from "../../Loaders/LabeledCircularProgress";

function CameraProgressColumn({ camera_status: cameraStatus }) {
    if (cameraStatus.status !== cameraStatuses.active || cameraStatus.elapsed_progress >= 1 || cameraStatus.elapsed_progress < 0) {
        return <></>;
    }

    const progress = Math.ceil(cameraStatus.elapsed_progress * 100);
    return (
        <div className="mt-1">
            <LabeledCircularProgress value={progress} size={40} thickness={5} />
        </div>
    );
}

export default CameraProgressColumn;