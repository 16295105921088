"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Controller API
 * ## Control & Management - Status Endpoint allows the user of the API to see the very basic data of the deployment - and notably, the deployment_id - even if the user has no credentials or valid license - License Endpoint allows an authenticated user to view current license usage  ## System Settings **Privacy settings** - 'Privacy' is a global system mode that allows a user of the system to restrict the biometric data that is saved and/or outputted by SC. - The global system mode, as well as the specific privacy settings that control which biometric data the system is permitted to save and output, are configured using the privacy settings API of Controller Service.  **SearchFace settings** - Settings that apply to the SearchFace endpoints (in POI DB & History DB)  **Analysis settings** - System-wide settings related to the analysis of streams  ## Data Retention Perform actions & retrieve information regarding the data saved by the system - how many records exist, what is the max capcity, enable automatic clearing etc. 
 *
 * The version of the OpenAPI document: 0.36.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The BodySetDefaultAppearancePrivacyProfile model module.
 * @module model/BodySetDefaultAppearancePrivacyProfile
 * @version 0.36.2
 */
class BodySetDefaultAppearancePrivacyProfile {
  /**
   * Constructs a new <code>BodySetDefaultAppearancePrivacyProfile</code>.
   * @alias module:model/BodySetDefaultAppearancePrivacyProfile
   * @param profileId {Object} profile_id to set as default
   */
  constructor(profileId) {
    BodySetDefaultAppearancePrivacyProfile.initialize(this, profileId);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, profileId) {
    obj['profile_id'] = profileId;
  }

  /**
   * Constructs a <code>BodySetDefaultAppearancePrivacyProfile</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/BodySetDefaultAppearancePrivacyProfile} obj Optional instance to populate.
   * @return {module:model/BodySetDefaultAppearancePrivacyProfile} The populated <code>BodySetDefaultAppearancePrivacyProfile</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new BodySetDefaultAppearancePrivacyProfile();
      if (data.hasOwnProperty('profile_id')) {
        obj['profile_id'] = _ApiClient.default.convertToType(data['profile_id'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>BodySetDefaultAppearancePrivacyProfile</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>BodySetDefaultAppearancePrivacyProfile</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of BodySetDefaultAppearancePrivacyProfile.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
BodySetDefaultAppearancePrivacyProfile.RequiredProperties = ["profile_id"];

/**
 * profile_id to set as default
 * @member {Object} profile_id
 */
BodySetDefaultAppearancePrivacyProfile.prototype['profile_id'] = undefined;
var _default = exports.default = BodySetDefaultAppearancePrivacyProfile;