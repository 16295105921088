import { useCallback } from "react";

import CheckIcon from "@mui/icons-material/Check";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import { Alert, IconButton } from "@mui/material";

function AlertElement({ notification, markAsRead }) {
    const handleOnClick = useCallback(() => {
        markAsRead(notification.id, true);
    }, [markAsRead, notification]);

    return (
        <Alert onClick={notification?.data?.onClick} style={{ cursor: notification?.data?.onClick ? "pointer" : "" }}
            severity={notification.type}
            action={notification.read ? (<CheckIcon />) :
                (<IconButton color="primary" aria-label="mark as read" component="span" onClick={handleOnClick}>
                    <MarkChatReadIcon />
                </IconButton>)}>
            {notification.content}
        </Alert>
    );
}


export default AlertElement;