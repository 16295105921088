import { isEmpty } from "lodash";

function ParseArrayBuffer(buffer) {
    const uint8Array = new Uint8Array(buffer);
    const decoder = new TextDecoder();
    const json = decoder.decode(uint8Array);
    return JSON.parse(json);
}

function parsingMultiSelectInput(objects, labelGetter, valueGetter) {
    return Object.keys(objects).map((key) => ({
        label: labelGetter(objects[key]),
        value: valueGetter(objects[key])
    }));
}

function updatePropertyIfPresent(objectToCheck, propertyName, objectToUpdate, parseFunc = undefined) {
    if (Object.prototype.hasOwnProperty.call(objectToCheck, propertyName)) {
        const valToUpdate = objectToCheck[propertyName];
        objectToUpdate[propertyName] = parseFunc ? parseFunc(valToUpdate) : valToUpdate;
    }
}

function filterKeysFromObject(obj, keysToFilter) {
    if (isEmpty(keysToFilter)) {
        return obj;
    }
    return Object.fromEntries(
        Object.keys(obj)
            .filter(key => !keysToFilter.includes(key))
            .map(key => [key, obj[key]])
    );
}

export { ParseArrayBuffer, parsingMultiSelectInput, updatePropertyIfPresent, filterKeysFromObject };