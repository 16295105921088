import { useEffect } from 'react';

const useDrawerEvent = (eventType, callback) => {
    useEffect(() => {
        const eventHandler = (event) => {
            event.preventDefault();
            callback(event.detail.drawerIndex);
        };

        document.addEventListener(eventType, eventHandler);
        return () => document.removeEventListener(eventType, eventHandler);
    }, [eventType, callback]);
};

export default useDrawerEvent;