"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _BasicRoleData = _interopRequireDefault(require("./BasicRoleData"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The RolesList model module.
 * @module model/RolesList
 * @version 0.35.0
 */
class RolesList {
  /**
   * Constructs a new <code>RolesList</code>.
   * @alias module:model/RolesList
   * @param roles {Array.<module:model/BasicRoleData>} 
   */
  constructor(roles) {
    RolesList.initialize(this, roles);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, roles) {
    obj['roles'] = roles;
  }

  /**
   * Constructs a <code>RolesList</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RolesList} obj Optional instance to populate.
   * @return {module:model/RolesList} The populated <code>RolesList</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RolesList();
      if (data.hasOwnProperty('roles')) {
        obj['roles'] = _ApiClient.default.convertToType(data['roles'], [_BasicRoleData.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>RolesList</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RolesList</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of RolesList.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    if (data['roles']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['roles'])) {
        throw new Error("Expected the field `roles` to be an array in the JSON data but got " + data['roles']);
      }
      // validate the optional field `roles` (array)
      for (const item of data['roles']) {
        _BasicRoleData.default.validateJsonObject(item);
      }
      ;
    }
    return true;
  }
}
RolesList.RequiredProperties = ["roles"];

/**
 * @member {Array.<module:model/BasicRoleData>} roles
 */
RolesList.prototype['roles'] = undefined;
var _default = exports.default = RolesList;