import { ApiClient, CompareRequest, DetectRequest, FaceApi } from "smart_cameras_poi_db_api";

import BaseAPIClient, { resCodes } from "./BaseAPIClient";
import { poiDbRoute } from "../Infrastructure/networkConf";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { trimNumber } from "../Parsing/numbersParsing";
import { image, imageWithMultipleFaces, invalidImage, matchParameters, poi, watchlists } from "../../../appConfig/Strings";
import { RequiredFaceAttributesConfig } from "@/Components/Cameras/CreateCamera/CreateEditCamera/CreateEditCamera.model";

const invalidImageResponse = parseResponseForUI(true, {
    body: {
        metadata: {
            msg: invalidImage
        }
    }
});

export default class FaceClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(poiDbRoute, clientInstance);
        this.faceApi = new FaceApi();
    }

    async detectFaces(images, shouldFilterSuccessList) {
        const parsedImages = images.map(image => ({ img: image }));
        const detectRequest = new DetectRequest(parsedImages);
        return this.apiCall("Detect Faces",
            (callback) => this.faceApi.detectFaces(detectRequest, callback),
            (error, data, response) => this.#handleDetect(error, data, response, shouldFilterSuccessList),
        );
    }

    #handleDetect(error, detectedData, response, shouldFilterSuccessList) {
        return parseResponseForUI(error, response, detectedData.data, shouldFilterSuccessList);
    }

    #handleAnalyze(error, AnalyzedData, response) {
        return parseResponseForUI(error, response, AnalyzedData.data);
    }

    async searchInPoiDB(caseData) {
        const searchRequest = {
            minConfidence: caseData[matchParameters].threshold,
            maxMatches: caseData[matchParameters].maxMatches,
            watchlists: caseData[watchlists].includes(null) ? [] : caseData[watchlists]
        };
        return this.#sendFaceApiRequest(caseData, searchRequest);
    }

    async #sendFaceApiRequest(caseData, searchRequest) {
        if (caseData[poi]) {
            return this.searchFace(caseData[poi].selectedPoi.display_img, searchRequest, parseResponseForUI);
        } else if (caseData[image]) {
            return this.searchFace(caseData[image], searchRequest, parseResponseForUI);
        } else if (caseData[imageWithMultipleFaces]) {
            return this.searchFaces(caseData[imageWithMultipleFaces], searchRequest);
        }
    }

    async analyzeFace(image, requiredFaceAttributes = RequiredFaceAttributesConfig) {
        const analyzeRequest = {
            image_payload: this.#setOptionsOnImagePayload(image, { detect: false, failOnMultipleFaces: true }),
            required_face_attributes: Object.values(requiredFaceAttributes)
        };
        return this.apiCall("Analyze Faces",
            (callback) => this.faceApi.analyzeFace(analyzeRequest, callback),
            (error, data, response) => this.#handleAnalyze(error, data, response));
    }

    async searchFace(image, options, successCallback) {
        if (!image) {
            return invalidImageResponse;
        }

        const searchRequest = {
            min_confidence: options.minConfidence,
            max_matches: options.maxMatches,
            image_payload: this.#setOptionsOnImagePayload(image, options),
            watchlists: options.watchlists || []
        };
        return await this.apiCall("Search Face",
            (callback) => this.faceApi.searchFace(searchRequest, callback),
            (error, data, response) => successCallback ? successCallback(error, response, {
                image,
                data
            }) : this.#handleSearch(data),
            {
                [resCodes.badRequest]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.unprocessable]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.forbidden]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.internalServerError]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.notFound]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.noResponse]: (error, __, response) => this.baseUIResponse(error, response)
            });
    }

    async searchFaces(image, options) {
        const searchRequest = {
            min_confidence: options.minConfidence,
            max_matches: options.maxMatches,
            img: image,
            watchlists: options.watchlists.includes(null) ? [] : options.watchlists
        };
        return await this.apiCall("Search Face",
            (callback) => this.faceApi.searchFaces(searchRequest, callback),
            (error, data, response) => parseResponseForUI(error, response, { image, data }),
            {
                [resCodes.badRequest]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.unprocessable]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.forbidden]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.unauthorized]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.internalServerError]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.notFound]: (error, __, response) => this.baseUIResponse(error, response),
                [resCodes.noResponse]: (error, __, response) => this.baseUIResponse(error, response)
            });
    }

    #setOptionsOnImagePayload(image, options, obj) {
        const parsedImage = {
            img: image,
            detect: options.detect,
            fail_on_multiple_faces: options.failOnMultipleFaces
        };
        return parsedImage;
    }

    #handleSearch(searchData) {
        return searchData.data.matches;
    }

    #getImagesFromCase(caseData) {
        let values = Object.values(caseData);
        if (values.length === 1)
            values = values[0];
        const imgData = values.reduce((acc, element) =>
            [...acc, element.selectedPoi ? element.selectedPoi.display_img : element],
            []);
        return imgData;
    }

    async compareFaces(caseData) {
        const imgData = this.#getImagesFromCase(caseData);
        if (!imgData[0] || !imgData[1]) {
            return invalidImageResponse;
        }

        const compareRequest = new CompareRequest();
        compareRequest.face_ref = { image_payload: { img: imgData[0] } };
        compareRequest.face_test = { image_payload: { img: imgData[1] } };
        return await this.apiCall("Compare Faces",
            (callback) => this.faceApi.compareFaces(compareRequest, callback),
            (error, data, response) => parseResponseForUI(error, response, trimNumber(data.data.match_confidence)),
            {
                [resCodes.badRequest]: (error, _, response) => parseResponseForUI(error, response),
                [resCodes.forbidden]: (error, _, response) => parseResponseForUI(error, response),
                [resCodes.unprocessable]: (error, _, response) => parseResponseForUI(error, response)
            }
        );
    }
}
