"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AuditTarget = _interopRequireDefault(require("./AuditTarget"));
var _ReturnMsg = _interopRequireDefault(require("./ReturnMsg"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Users API
 * ## Users Managements SmartCameras Users Management consists of:  - **Users**, each having a unique username. The Users authenticate with a password in the \"login\" end-point and are provided an `access_token`.  - **Tokens**, that are received as a response to a successful login and should be used for any subsequent API call. Tokens are used to identify the user. If a token is not used for a specific amount of time, it is expired.  - **Roles**, that define which end-points a user is authorized to.  Each user is associated with a single role. A single role can be associated with many users.  The following API provides a mechanism to add, inspect, modify and remove Users and Roles.  ## Audit SmartCameras Audit mechanism documents each API call, and also allows user to add independent audit records. This API provides endpoints to add & query SmartCameras Audit records.
 *
 * The version of the OpenAPI document: 0.35.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AuditRecord model module.
 * @module model/AuditRecord
 * @version 0.35.0
 */
class AuditRecord {
  /**
   * Constructs a new <code>AuditRecord</code>.
   * @alias module:model/AuditRecord
   * @param apiName {String} the name of the general API service that logged this audit record
   * @param actionName {String} an identifier of the action recorded
   */
  constructor(apiName, actionName) {
    AuditRecord.initialize(this, apiName, actionName);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, apiName, actionName) {
    obj['api_name'] = apiName;
    obj['action_name'] = actionName;
  }

  /**
   * Constructs a <code>AuditRecord</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AuditRecord} obj Optional instance to populate.
   * @return {module:model/AuditRecord} The populated <code>AuditRecord</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AuditRecord();
      if (data.hasOwnProperty('audit_id')) {
        obj['audit_id'] = _ApiClient.default.convertToType(data['audit_id'], 'String');
      }
      if (data.hasOwnProperty('api_name')) {
        obj['api_name'] = _ApiClient.default.convertToType(data['api_name'], 'String');
      }
      if (data.hasOwnProperty('action_name')) {
        obj['action_name'] = _ApiClient.default.convertToType(data['action_name'], 'String');
      }
      if (data.hasOwnProperty('action_time')) {
        obj['action_time'] = _ApiClient.default.convertToType(data['action_time'], 'Number');
      }
      if (data.hasOwnProperty('action_msg')) {
        obj['action_msg'] = _ApiClient.default.convertToType(data['action_msg'], Object);
      }
      if (data.hasOwnProperty('targets')) {
        obj['targets'] = _ApiClient.default.convertToType(data['targets'], [_AuditTarget.default]);
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('user_name')) {
        obj['user_name'] = _ApiClient.default.convertToType(data['user_name'], 'String');
      }
      if (data.hasOwnProperty('user_role')) {
        obj['user_role'] = _ApiClient.default.convertToType(data['user_role'], 'String');
      }
      if (data.hasOwnProperty('return_code')) {
        obj['return_code'] = _ApiClient.default.convertToType(data['return_code'], 'Number');
      }
      if (data.hasOwnProperty('return_msg')) {
        obj['return_msg'] = data['return_msg'];
      }
      if (data.hasOwnProperty('node_id')) {
        obj['node_id'] = _ApiClient.default.convertToType(data['node_id'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AuditRecord</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AuditRecord</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of AuditRecord.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['audit_id'] && !(typeof data['audit_id'] === 'string' || data['audit_id'] instanceof String)) {
      throw new Error("Expected the field `audit_id` to be a primitive type in the JSON string but got " + data['audit_id']);
    }
    // ensure the json data is a string
    if (data['api_name'] && !(typeof data['api_name'] === 'string' || data['api_name'] instanceof String)) {
      throw new Error("Expected the field `api_name` to be a primitive type in the JSON string but got " + data['api_name']);
    }
    // ensure the json data is a string
    if (data['action_name'] && !(typeof data['action_name'] === 'string' || data['action_name'] instanceof String)) {
      throw new Error("Expected the field `action_name` to be a primitive type in the JSON string but got " + data['action_name']);
    }
    if (data['targets']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['targets'])) {
        throw new Error("Expected the field `targets` to be an array in the JSON data but got " + data['targets']);
      }
      // validate the optional field `targets` (array)
      for (const item of data['targets']) {
        _AuditTarget.default.validateJsonObject(item);
      }
      ;
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // ensure the json data is a string
    if (data['user_name'] && !(typeof data['user_name'] === 'string' || data['user_name'] instanceof String)) {
      throw new Error("Expected the field `user_name` to be a primitive type in the JSON string but got " + data['user_name']);
    }
    // ensure the json data is a string
    if (data['user_role'] && !(typeof data['user_role'] === 'string' || data['user_role'] instanceof String)) {
      throw new Error("Expected the field `user_role` to be a primitive type in the JSON string but got " + data['user_role']);
    }
    // validate the optional field `return_msg`
    if (data['return_msg']) {
      // data not null
      _ReturnMsg.default.validateJSON(data['return_msg']);
    }
    // ensure the json data is a string
    if (data['node_id'] && !(typeof data['node_id'] === 'string' || data['node_id'] instanceof String)) {
      throw new Error("Expected the field `node_id` to be a primitive type in the JSON string but got " + data['node_id']);
    }
    return true;
  }
}
AuditRecord.RequiredProperties = ["api_name", "action_name"];

/**
 * audit record id
 * @member {String} audit_id
 */
AuditRecord.prototype['audit_id'] = undefined;

/**
 * the name of the general API service that logged this audit record
 * @member {String} api_name
 */
AuditRecord.prototype['api_name'] = undefined;

/**
 * an identifier of the action recorded
 * @member {String} action_name
 */
AuditRecord.prototype['action_name'] = undefined;

/**
 * UTC timestamp of the action time
 * @member {Number} action_time
 */
AuditRecord.prototype['action_time'] = undefined;

/**
 * an open field to add further data regarding the action performed (usually in the form of dict / string)
 * @member {Object} action_msg
 */
AuditRecord.prototype['action_msg'] = undefined;

/**
 * @member {Array.<module:model/AuditTarget>} targets
 */
AuditRecord.prototype['targets'] = undefined;

/**
 * the unique id of the user who performed the action
 * @member {String} user_id
 */
AuditRecord.prototype['user_id'] = undefined;

/**
 * the name of the user who performed the action
 * @member {String} user_name
 */
AuditRecord.prototype['user_name'] = undefined;

/**
 * the role of the user who performed the action
 * @member {String} user_role
 */
AuditRecord.prototype['user_role'] = undefined;

/**
 * return code of the action performed
 * @member {Number} return_code
 */
AuditRecord.prototype['return_code'] = undefined;

/**
 * @member {module:model/ReturnMsg} return_msg
 */
AuditRecord.prototype['return_msg'] = undefined;

/**
 * node id of the host that performed the action
 * @member {String} node_id
 */
AuditRecord.prototype['node_id'] = undefined;
var _default = exports.default = AuditRecord;