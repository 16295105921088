import { useCallback, useMemo } from "react";

import { Autocomplete, CircularProgress, Paper, Popper, TextField } from "@mui/material";

function BaseSelectInputField({ value, onChange, onOpen, options = [], model, label, className, disabled, dataTestId = "", sx = {}, renderTags, renderListBox, isMultiple = false, popperProps, ListboxProps, limitTags = 3, isLoading, ...props }) {
    const { getId, getLabel, required } = model;

    const optionsMap = useMemo(() => new Map(options?.map(option => [getId(option), option])), [options, getId]);
    const allOptions = useMemo(() => options?.map(getId), [getId, options]);

    const getOptionLabel = useCallback((option) => {
        const foundOption = optionsMap.get(option);
        return foundOption ? getLabel(foundOption) : option;
    }, [optionsMap, getLabel]);

    const renderOptions = useCallback((props, option) => {
        const label = getOptionLabel(option);
        return (
            <li data-testid={`${dataTestId}-${label}`} {...props} key={option} className="main-text-style m-1 truncate hover:bg-midnight-blue/5">
                {label}
            </li>
        );
    }, [dataTestId, getOptionLabel]);

    const handleChange = useCallback((_, newValue) => {
        onChange && onChange(newValue);
    }, [onChange]);

    const handleSelectAllClick = useCallback(() => {
        onChange && onChange(allOptions);
    }, [allOptions, onChange]);

    const customFilter = useCallback((options = [], { inputValue }) => {
        const normalizedInput = inputValue.toLowerCase();
        return options?.filter(option => {
            const label = getOptionLabel(option).toLowerCase();
            return label.includes(normalizedInput);
        });
    }, [getOptionLabel]);

    return (
        <div className='flex w-full items-center justify-center'>
            <Autocomplete
                className={`w-full ${className}`}
                multiple={isMultiple}
                disableCloseOnSelect={isMultiple}
                filterSelectedOptions
                filterOptions={customFilter}
                value={value}
                onChange={handleChange}
                loading={isLoading}
                renderInput={(params) =>
                    <TextField
                        required={required && (!value || value.length === 0)}
                        label={label}
                        {...params}
                        InputLabelProps={{
                            className: className
                        }}
                        sx={sx}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <div>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </div>
                            ),
                        }}
                    />}
                classes={{
                    popupIndicator: className,
                    clearIndicator: className,
                    hasClearIcon: className,
                    inputRoot: {
                        outlined: className
                    }
                }}
                PaperComponent={(props) => <Paper className="mx-1 mb-2 border-2 border-oceanic-azure" {...props} />}
                PopperComponent={(props) => <Popper {...props} {...popperProps} />}
                renderTags={(value, getTagProps) => isMultiple && renderTags(value, getTagProps, getOptionLabel)}
                renderOption={renderOptions}
                options={allOptions}
                limitTags={limitTags}
                disablePortal
                disabled={disabled}
                data-testid={dataTestId}
                onOpen={onOpen}
                ListboxComponent={renderListBox && renderListBox(handleSelectAllClick)}
                ListboxProps={ListboxProps}
                getOptionLabel={getOptionLabel}
                {...props}
            />
        </div>
    );
}

export default BaseSelectInputField;