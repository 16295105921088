"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AnalysisMode = _interopRequireDefault(require("../model/AnalysisMode"));
var _AnalysisModeResponse = _interopRequireDefault(require("../model/AnalysisModeResponse"));
var _GeneralResponse = _interopRequireDefault(require("../model/GeneralResponse"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* AnalysisMode service.
* @module api/AnalysisModeApi
* @version 0.32.2
*/
class AnalysisModeApi {
  /**
  * Constructs a new AnalysisModeApi. 
  * @alias module:api/AnalysisModeApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the getAnalysisMode operation.
   * @callback module:api/AnalysisModeApi~getAnalysisModeCallback
   * @param {String} error Error message, if any.
   * @param {module:model/AnalysisModeResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Get Analysis Mode
   * With this endpoint, user can query the current analysis mode of the system - live analysis (\"live\"), which prefers real-time frame analysis, even at the cost of dropping frames or faces, or offline analysis (\"forensics\") which prefers complete analysis of every frame and face.
   * @param {module:api/AnalysisModeApi~getAnalysisModeCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/AnalysisModeResponse}
   */
  getAnalysisMode(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _AnalysisModeResponse.default;
    return this.apiClient.callApi('/cameras/analysis/', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setAnalysisMode operation.
   * @callback module:api/AnalysisModeApi~setAnalysisModeCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GeneralResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Set Analysis Mode
   * With this endpoint, user can query the current analysis mode of the system - *live analysis (\"live\")*, which prefers real-time frame analysis, even at the cost of dropping frames or faces, or *offline analysis (\"forensics\")* which prefers complete analysis of every frame and face. *Analysis mode cannot be changed while any camera is capturing.*
   * @param {module:model/AnalysisMode} analysisMode 
   * @param {module:api/AnalysisModeApi~setAnalysisModeCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GeneralResponse}
   */
  setAnalysisMode(analysisMode, callback) {
    let postBody = analysisMode;
    // verify the required parameter 'analysisMode' is set
    if (analysisMode === undefined || analysisMode === null) {
      throw new Error("Missing the required parameter 'analysisMode' when calling setAnalysisMode");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['OAuth2PasswordBearer'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = _GeneralResponse.default;
    return this.apiClient.callApi('/cameras/analysis/', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = AnalysisModeApi;