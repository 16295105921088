import { ApiClient, AppearancesApi, ImagePayload, InsightsApi, MatchApi, WatchlistsQuery } from "smart_cameras_history_api";

import moment from "moment";
import { isEmpty } from "lodash";

import BaseAPIClient, { resCodes } from "./BaseAPIClient";
import { historyRoute } from "../Infrastructure/networkConf";
import { resultsPerPage } from "src/appConfig/constants";
import { appearancesFilters, historyDBQuery, historyProximity, image, imageWithMultipleFaces, matchParameters, minAppearances, poi, poiSignature, appearanceExpansionTimeframe, unclusteredAppearances, noResults } from "src/appConfig/Strings";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import store from "src/appConfig/configureStore";
import { setHistoryAnalytics } from "../Redux/Stores/AnalyticsStore";
import { matchOutcomes, notInWlOutcome } from "./EventsClient/EventsClient.model";
import { baseDate, localToUtc } from "../Parsing/timeParsing";
import { timeDeltaModel, timeMappingToValue } from "@/Components/Common/FormComponents/FormInputs/DurationSelector/DurationSelector.model";
import { getWatchlistRank } from "../Hooks/useEventListener/useEventListener.utils";

export const queryDBOptions = {
    live: "live_db",
    history: "history_db",
    liveAndHistory: "live_and_history_db"
}; //todo-sdk

export class HistoryClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(historyRoute, clientInstance);
        this.appearancesApi = new AppearancesApi();
        this.insightsApi = new InsightsApi();
        this.matchApi = new MatchApi();
    }

    static parseCountRequest(parsedRequest, queryDB) {
        const params = {
            ...parsedRequest,
            count_attributes_analytics: true
        };
        const queryParams = {
            "queryDb": queryDB
        };
        return [params, queryParams];
    }

    static handleSuccessfulCountAppearances(data) {
        store.dispatch(setHistoryAnalytics(data?.data));
        return data;
    }

    static parseMatchOutcomeFilter(filter, outcomes) {
        if (filter.includes(null)) {
            return outcomes.map(outcome => new WatchlistsQuery(outcome));
        }

        const res = [];
        for (const watchlistId of filter) {
            for (const matchOutcome of outcomes) {
                const wl = new WatchlistsQuery(matchOutcome);
                wl.watchlist_id = watchlistId;
                res.push(wl);
            }
        }
        return res;
    }

    static parseHistoryQuery(caseData) {
        const historyQuery = caseData[historyDBQuery];

        const timeFilters = parseCaseTime(historyQuery.timeFilter);

        const matchedWatchlists = HistoryClient.parseMatchOutcomeFilter(historyQuery.matchFilter, [matchOutcomes.matched]);
        const notMatchedWatchlists = HistoryClient.parseMatchOutcomeFilter(historyQuery.notMatchFilter, [matchOutcomes.matched]);
        const unauthorizedWatchlists = HistoryClient.parseMatchOutcomeFilter(historyQuery.unauthorizedFilter, [matchOutcomes[notInWlOutcome]]);
        const detectionsWatchlists = HistoryClient.parseMatchOutcomeFilter(historyQuery.detectionFilter, [matchOutcomes.low_quality_face, matchOutcomes.not_determined]);

        const parsedRequest = {
            cameras: !historyQuery.camerasFilter.includes(null) ? historyQuery.camerasFilter : [],
            watchlists: [...matchedWatchlists, ...unauthorizedWatchlists, ...detectionsWatchlists],
            not_watchlists: [...notMatchedWatchlists],
            age_group_outcomes: historyQuery.ageGroupFilter.includes(null) ? [] : historyQuery.ageGroupFilter,
            gender_outcomes: historyQuery.genderFilter.includes(null) ? [] : historyQuery.genderFilter,
            liveness_outcomes: historyQuery.livenessFilter.includes(null) ? [] : historyQuery.livenessFilter,
            label_outcomes: historyQuery.reviewedFilter.includes(null) ? [] : historyQuery.reviewedFilter,
            analysis_mode: historyQuery.analysisModeFilter?.value,
            ...timeFilters,
            ...(historyQuery.retainFilter.includes(null) ? { retain: true } : {}),
        };

        return parsedRequest;
    }

    async searchAppearances(caseData, pageId, analytics, queryDB = queryDBOptions.history, addMatchConf = true) {
        const query = caseData[historyDBQuery] ? HistoryClient.parseHistoryQuery(caseData) : {};
        let shouldSortRecordWatchlists = true;

        let res = null;
        if (caseData[poiSignature]?.useSig) {
            const getAppearancesResponse = await this.#getAppearanceIds(caseData);
            if (!getAppearancesResponse.error) {
                this.#getAppearancesSourceParsing({ appearanceIds: getAppearancesResponse.data.records }, query);
                res = await this.#getAppearancesAndCount(query, pageId, queryDB, analytics);
                this.#handleMatchConfidence(getAppearancesResponse.data.records, res);
            }
        } else if (caseData[appearancesFilters] || caseData[poi] || caseData.personId || caseData.appearanceIds) {
            const matches = caseData.appearanceIds;
            const getAppearancesQuery = this.#getAppearancesSourceParsing(caseData, query);
            res = await this.#getAppearancesAndCount(getAppearancesQuery, pageId, queryDB, analytics);
            addMatchConf && this.#handleMatchConfidence(matches, res);
        } else {
            //todo this is done because the response of img/multi is not history records but persons so they don't have matches 
            // needs to be separated from searchAppearances
            shouldSortRecordWatchlists = !(caseData[image] || caseData[imageWithMultipleFaces]);
            res = await this.#getAppearanceIds(caseData);
        }

        if (res?.data) {
            res.data.query = query;
            res.data.caseData = caseData;
            shouldSortRecordWatchlists && sortRecWatchlistsByRank(res?.data.records);
        }
        return res;
    }

    #handleMatchConfidence(matches, getAppearancesResponse) {
        if (getAppearancesResponse.error) {
            return;
        }

        matches?.forEach((match) => {
            if (getAppearancesResponse.data.records[match.appearance_id]) {
                getAppearancesResponse.data.records[match.appearance_id] = {
                    ...getAppearancesResponse.data.records[match.appearance_id],
                    match_confidence: match.match_confidence
                };
            }
        });
    }

    async #getAppearancesAndCount(getAppearancesQuery, pageId, queryDB, analytics) {
        const opts = {
            "countAppearance": !analytics,
            "afterId": pageId,
            "limit": resultsPerPage,
            "queryDb": queryDB
        };
        const historyRecords = this.apiCall("Search Appearances",
            (callback) => this.appearancesApi.getAppearances(getAppearancesQuery, opts, callback),
            async (error, data, response) => parseResponseForUI(error, response, {
                counters: data?.data?.matched_entries,
                records: await this.serializeApiData(data.data.matches, (record) => record.appearance_data.appearance_id)
            }), {
            [resCodes.badRequest]: (error, _, response) => parseResponseForUI(error, response),
            [resCodes.forbidden]: (error, _, response) => parseResponseForUI(error, response)
        });
        const countAppearance = analytics && this.countHistoryAppearances(getAppearancesQuery, pageId, queryDB);
        const results = await Promise.all([historyRecords, countAppearance]);

        return {
            ...results[0],
            data: {
                records: results[0]?.data?.records,
                counters: results[1]?.data || results[0]?.data?.counters
            }
        };
    }

    #getAppearancesSourceParsing(caseData, query) {
        if (caseData[historyProximity]) {
            query.appearance_ids = caseData.appearanceIds;
        }
        else if (caseData[poi]) {
            query.poi_ids = [caseData[poi].selectedPoi.poi_id];
        }
        else if (caseData.personId) {
            query.person_ids = [caseData.personId];
        }
        else if (caseData.appearanceIds) {
            query.appearance_ids = caseData.appearanceIds?.map(appearance => appearance?.appearance_id || appearance);
        }
        return query;
    }

    async #getAppearanceIds(caseData) {
        const [endpoint, endpointString, serializeData] = this.#getAppearanceIdsSourceParsing(caseData);
        const appearanceIdsResponse = await this.executeAppearancesEndpoint(endpoint, endpointString, serializeData);
        if (!appearanceIdsResponse?.error && appearanceIdsResponse?.data?.length !== 0) {
            return {
                ...appearanceIdsResponse,
                data: {
                    caseData: caseData,
                    records: appearanceIdsResponse?.data
                }
            };
        } else {
            return {
                error: true,
                msg: appearanceIdsResponse.error ? appearanceIdsResponse.msg : [noResults],
                data: {
                    caseData: {},
                    records: {}
                }
            };
        }
    }

    #getAppearanceIdsSourceParsing(caseData) {
        let searchQuery = {
            history_query: HistoryClient.parseHistoryQuery(caseData)
        };
        let endpoint = null;
        let endpointString = "";
        let serializeData = (data) => data.matches;
        if (caseData[image]) {
            searchQuery = { ...searchQuery, ...this.#parseGetAppearanceIds(caseData, caseData[image]) };
            endpoint = (callback) => this.matchApi.searchAppImgInHistory(searchQuery, callback);
            endpointString = "Search Img In Appearance DB";
        } else if (caseData[poiSignature] && caseData[poiSignature].useSig) {
            searchQuery = {
                ...searchQuery, ...this.#parseGetAppearanceIds({
                    ...caseData,
                    [matchParameters]: caseData[poiSignature].matchParametersData
                }, caseData[image])
            };
            searchQuery.poi_id = caseData[poi].selectedPoi.poi_id;
            endpoint = (callback) => this.matchApi.searchPOIInAppearances(searchQuery, callback);
            endpointString = "Search POI in appearances DB";
        } else if (caseData[imageWithMultipleFaces]) {
            searchQuery = { ...searchQuery, ...this.#parseGetAppearanceIds(caseData, caseData[imageWithMultipleFaces], true) };
            endpoint = (callback) => this.matchApi.searchAppearancesInImage(searchQuery, callback);
            endpointString = "Search Appearances From Image";
            serializeData = (data) => data;
        }
        return [endpoint, endpointString, serializeData];
    }

    #parseGetAppearanceIds(caseData, image, withoutImagePayload) {
        const searchData = caseData[matchParameters];
        const query = {};
        query.min_confidence = searchData.threshold;
        query.max_matches = searchData.maxMatches;
        if (withoutImagePayload) {
            query.img = image;
        } else {
            query.image_payload = new ImagePayload();
            query.image_payload.img = image;
        }
        return query;
    }

    async executeAppearancesEndpoint(endpoint, callText, serializeData) {
        return await this.apiCall(callText,
            (callback) => endpoint(callback),
            (_, __, response) => parseResponseForUI(response.error, response, serializeData(response.body?.data)),
            {
                [resCodes.badRequest]: (error, _, response) => parseResponseForUI(error, response),
                [resCodes.forbidden]: (error, _, response) => parseResponseForUI(error, response),
                [resCodes.unprocessable]: (error, _, response) => parseResponseForUI(error, response)
            });
    }

    async countHistoryAppearances(parsedRequest, pageId, queryDB) {
        if (pageId !== "") {
            return;
        }

        const [params, queryParams] = HistoryClient.parseCountRequest(parsedRequest, queryDB);
        return await this.apiCall("Count History Appearances",
            (callback) => this.appearancesApi.countAppearances(params, queryParams, callback),
            (_, data) => HistoryClient.handleSuccessfulCountAppearances(data)
        );
    }

    async removeAppearances({ appearanceId, query = {}, firstAppearanceStartTime, removeIncludingRetained }) {
        const historyDBQuery = {
            ...query,
            ...((query.till || firstAppearanceStartTime) ? { till: query.till || firstAppearanceStartTime } : {}),
            ...(appearanceId ? { appearance_ids: [appearanceId] } : {}),
            ...(!removeIncludingRetained && { retain: false })
        };

        return await this.apiCall("Remove history appearances",
            (callback) => this.appearancesApi.removeAppearances(historyDBQuery, callback),
            (error, data, response) => parseResponseForUI(error, response, data)
        );
    }

    async setAppearanceRetention(appearanceId, shouldRetain) {
        const retentionQuery = { appearance_ids: [appearanceId], should_retain: shouldRetain };

        return await this.apiCall("Set Appearance Retention",
            (callback) => this.appearancesApi.setAppearancesRetention(retentionQuery, callback),
            (error, data, response) => parseResponseForUI(error, response, data)
        );
    }

    async labelAppearance(appearanceId, labelOutcome, notes) {
        const labelParams = {
            labeled_outcome: labelOutcome,
            ...(notes !== undefined ? { appearance_notes: { free_notes: notes } } : {})
        };

        return await this.apiCall("Label Appearance",
            (callback) => this.appearancesApi.labelAppearance(appearanceId, labelParams, callback),
            (error, data, response) => parseResponseForUI(error, response, data)
        );
    }

    async getHistoryProximityInsight(caseData) {
        const minAppearancesVal = caseData[minAppearances];
        const historyDbQuery = HistoryClient.parseHistoryQuery(caseData);
        const searchedPoi = caseData[poi]?.selectedPoi.poi_id;
        const { searchImgError, searchImgResponse, personId } = caseData[image] ? await this.searchImgInPersonsDb(caseData) : {};
        const timeDeltaVal = caseData[appearanceExpansionTimeframe];
        if (searchImgError) {
            return parseResponseForUI(searchImgError, searchImgResponse);
        }

        const requestData = {
            proximity_request: {
                time_delta: timeDeltaVal ? timeMappingToValue(timeDeltaVal, timeDeltaModel) : 0,
                ...minAppearancesVal > 0 && { min_number_of_appearances: minAppearancesVal },
                ...searchedPoi && { poi_id: searchedPoi },
                ...personId && { person_id: personId }
            }, results_query: historyDbQuery
        };

        const proximityRequest = await this.apiCall("Get History Proximity",
            (callback) => this.insightsApi.proximityHistoryInsight(requestData, callback),
            async (error, data, response) => {
                this.#handleProximityUnclusteredAppearances(data.data);
                const serializedData = await this.serializeApiData(data.data.matches, (record) => record.person_id);

                return parseResponseForUI(error, response, serializedData);
            }
        );

        return {
            ...proximityRequest,
            data: {
                records: proximityRequest?.data,
                caseData: caseData
            }
        };
    }

    #handleProximityUnclusteredAppearances(proximityRequestData) {
        const unclusteredPersons = proximityRequestData?.not_clustered_persons;
        if (isEmpty(unclusteredPersons)) {
            return;
        }

        const newUnclusteredPerson = {
            ...unclusteredPersons,
            camera_data: { camera_description: unclusteredAppearances },
            appearance_data: {},
            crop_data: {},
            face_features_data: {},
            appearance_count: "",
            utc_time_recorded: ""
        };
        proximityRequestData.matches = [
            ...(proximityRequestData.matches ?? []),
            newUnclusteredPerson
        ];
    }

    async searchImgInPersonsDb(caseData) {
        const requestData = {
            min_confidence: 30,
            image_payload: { img: caseData[image] },
            max_matches: 1,
            history_query: caseData[historyDBQuery]
        };

        const [searchImgError, searchImgResponse, personId] = await this.apiCall("Search Image In Persons DB",
            callback => this.matchApi.searchImgInHistory(requestData, callback),
            async (error, data, response) => {
                const personId = data.data.matches?.[0]?.person_id;
                if (!personId) {
                    response.body.metadata.msg = noResults;
                }

                return [error || !personId, response, personId];
            }
        );

        return { searchImgError, searchImgResponse, personId };
    }
}

function sortRecWatchlistsByRank(historyRecords) {
    Object.values(historyRecords).forEach(record => {
        record.match_data.watchlists.sort((a, b) => getWatchlistRank(a) - getWatchlistRank(b));
    });
}

export function parseCaseTime({ start, end }) {
    const parseTimeToSec = (time) => new Date(`${baseDate}${time}Z`).getTime() / 1000;

    const isDateTimeValid = (timeString) => moment(timeString, moment.ISO_8601, true).isValid();

    const isStartDateTime = isDateTimeValid(start);
    const isEndDateTime = isDateTimeValid(end);

    const timeFilters = {
        ...(start ? { from: isStartDateTime ? localToUtc(start) : parseTimeToSec(start) } : {}),
        ...(end ? { till: isEndDateTime ? localToUtc(end) : parseTimeToSec(end) } : {})
    };

    return timeFilters;
}