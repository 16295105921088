"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CrowdedAreaConfig model module.
 * @module model/CrowdedAreaConfig
 * @version 0.37.0
 */
class CrowdedAreaConfig {
  /**
   * Constructs a new <code>CrowdedAreaConfig</code>.
   * @alias module:model/CrowdedAreaConfig
   * @param alertingUniqueIndividuals {Object} 
   * @param alertingCrowdedDuration {Object} 
   */
  constructor(alertingUniqueIndividuals, alertingCrowdedDuration) {
    CrowdedAreaConfig.initialize(this, alertingUniqueIndividuals, alertingCrowdedDuration);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, alertingUniqueIndividuals, alertingCrowdedDuration) {
    obj['alerting_unique_individuals'] = alertingUniqueIndividuals;
    obj['alerting_crowded_duration'] = alertingCrowdedDuration;
  }

  /**
   * Constructs a <code>CrowdedAreaConfig</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CrowdedAreaConfig} obj Optional instance to populate.
   * @return {module:model/CrowdedAreaConfig} The populated <code>CrowdedAreaConfig</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CrowdedAreaConfig();
      if (data.hasOwnProperty('camera_ids')) {
        obj['camera_ids'] = _ApiClient.default.convertToType(data['camera_ids'], Object);
      }
      if (data.hasOwnProperty('videos_ids')) {
        obj['videos_ids'] = _ApiClient.default.convertToType(data['videos_ids'], Object);
      }
      if (data.hasOwnProperty('excluded_watchlists')) {
        obj['excluded_watchlists'] = _ApiClient.default.convertToType(data['excluded_watchlists'], Object);
      }
      if (data.hasOwnProperty('min_detection_width')) {
        obj['min_detection_width'] = _ApiClient.default.convertToType(data['min_detection_width'], Object);
      }
      if (data.hasOwnProperty('alerting_unique_individuals')) {
        obj['alerting_unique_individuals'] = _ApiClient.default.convertToType(data['alerting_unique_individuals'], Object);
      }
      if (data.hasOwnProperty('alerting_crowded_duration')) {
        obj['alerting_crowded_duration'] = _ApiClient.default.convertToType(data['alerting_crowded_duration'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CrowdedAreaConfig</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CrowdedAreaConfig</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CrowdedAreaConfig.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
CrowdedAreaConfig.RequiredProperties = ["alerting_unique_individuals", "alerting_crowded_duration"];

/**
 * Live cameras that should trigger the insight
 * @member {Object} camera_ids
 */
CrowdedAreaConfig.prototype['camera_ids'] = undefined;

/**
 * Forensics videos that should trigger the insight
 * @member {Object} videos_ids
 */
CrowdedAreaConfig.prototype['videos_ids'] = undefined;

/**
 * List of Watchlists to exclude from the insight
 * @member {Object} excluded_watchlists
 */
CrowdedAreaConfig.prototype['excluded_watchlists'] = undefined;

/**
 * @member {Object} min_detection_width
 */
CrowdedAreaConfig.prototype['min_detection_width'] = undefined;

/**
 * @member {Object} alerting_unique_individuals
 */
CrowdedAreaConfig.prototype['alerting_unique_individuals'] = undefined;

/**
 * @member {Object} alerting_crowded_duration
 */
CrowdedAreaConfig.prototype['alerting_crowded_duration'] = undefined;
var _default = exports.default = CrowdedAreaConfig;