import { ApiClient, WatchlistApi, WatchlistParameters } from "smart_cameras_poi_db_api";

import BaseAPIClient, { resCodes } from "./BaseAPIClient";
import { poiDbRoute } from "../Infrastructure/networkConf";

import store from "src/appConfig/configureStore";
import { hexToBGR, RGBToHex } from "../Parsing/stringsParsing";
import { toastUtility } from "../Hooks/useToast";
import { parseResponseForUI } from "../Hooks/useUIResponseHandler";
import { addWatchlist, removeWatchlist, setWatchlists } from "../Redux/Stores/WatchlistsStore";
import { poiPageSize } from "src/appConfig/constants";
import { setPoisPage } from "../Redux/Stores/POIStore";

export const watchlistSeverityValues = {
    normal_severity: 0,
    popUpOnAlert_severity: 1
};

export function getColorFromMatchData(matchData) {
    return RGBToHex(hexToBGR(matchData.watchlists[0].display_color));
}

export const interestWlType = "blacklist";

export class WatchlistsClient extends BaseAPIClient {
    constructor() {
        const clientInstance = ApiClient.instance;
        super(poiDbRoute, clientInstance);
        this.watchlistsApi = new WatchlistApi();
    }

    initialize() {
        this.listAllWatchlists();
    }

    async #handleListAllWatchlists(watchlistsData) {
        const watchlists = await this.serializeApiData(watchlistsData.data.watchlists, (watchlist) => watchlist.watchlist_id);
        store.dispatch(setWatchlists(watchlists));
    }

    async listAllWatchlists(countPois = false) {
        const options = { "countPois": countPois };
        await this.apiCall("List all watchlists",
            (callback) => this.watchlistsApi.listAllWatchlists(options, callback),
            (_, data) => this.#handleListAllWatchlists(data)
        );
    }

    #parseWatchlistForm(form) {
        const watchlistPrams = new WatchlistParameters();

        watchlistPrams.display_name = form.display_name.value;
        watchlistPrams.display_color = RGBToHex(hexToBGR(form.display_color.value));
        watchlistPrams.watchlist_type = form.watchlist_type.value;
        watchlistPrams.threshold_delta = form.threshold_delta.value;

        watchlistPrams.watchlist_notes = {
            free_notes: form.watchlist_notes.value
        };
        watchlistPrams.severity = form.severity.value;
        watchlistPrams.sound_on_alert = form.sound_on_alert.checked;
        watchlistPrams.repeated_alert = form.repeated_alert.checked;

        return watchlistPrams;
    }

    #handleAddWatchlist(error, watchlist, response) {
        store.dispatch(addWatchlist(watchlist.data));
        return parseResponseForUI(error, response);
    }

    async createWatchlist(watchlistForm) {
        const parsedWatchlistForm = this.#parseWatchlistForm(watchlistForm);
        return await this.apiCall("Create Watchlist",
            (callback) => this.watchlistsApi.addWatchlist(parsedWatchlistForm, callback),
            (error, data, response) => this.#handleAddWatchlist(error, data, response),
            {
                [resCodes.badRequest]: (error, __, response) => parseResponseForUI(error, response),
                [resCodes.notFound]: (error, __, response) => parseResponseForUI(error, response)
            });
    }

    async editWatchlist(watchlistId, watchlistForm) {
        const parsedWatchlistForm = this.#parseWatchlistForm(watchlistForm);
        return await this.apiCall("Edit Watchlist",
            (callback) => this.watchlistsApi.updateWatchlist(watchlistId, parsedWatchlistForm, callback),
            (error, data, response) => this.#handleAddWatchlist(error, data, response),
            {
                [resCodes.badRequest]: (error, __, response) => parseResponseForUI(error, response),
                [resCodes.notFound]: (error, __, response) => parseResponseForUI(error, response)
            });
    }

    async getWatchlistWithPois(watchlistId, afterId = "", countPois = false) {
        const options = {
            afterId,
            limit: poiPageSize,
            countPois
        };

        return await this.apiCall("Get pois page for watchlist",
            (callback) => this.watchlistsApi.getWatchlist(watchlistId, options, callback),
            (_, data) => {
                const payload = {
                    watchlistId,
                    ids: data.data.pois.map((poi) => poi.poi_id),
                    totalPois: data.data.total_pois
                };

                return payload;
            });
    }

    async clearWatchlist(watchlistId, shouldDeleteAllPois = true) {
        const deletePoisPayload = {
            bodyClearWatchlistPoiDbWatchlistWatchlistIdClearPost: { "delete_pois": shouldDeleteAllPois }
        };

        return await this.apiCall("Clear Watchlist",
            (callback) => this.watchlistsApi.clearWatchlist(watchlistId, deletePoisPayload, callback),
            (error, __, response) => {
                this.listAllWatchlists(true);
                const clearPoisPageData = {
                    poisData: {},
                    totalPois: 0
                };
                store.dispatch(setPoisPage(clearPoisPageData));
                return parseResponseForUI(error, response);
            });
    }

    async removeWatchlist(watchlistId) {
        return await this.apiCall("Remove Watchlist", (callback) => this.watchlistsApi.removeWatchlist(watchlistId, callback),
            () => store.dispatch(removeWatchlist(watchlistId)),
            {
                [resCodes.unprocessable]: (error, _, response) => toastUtility.showError(response.body.metadata.msg)
            });
    }
}

export const getWatchlistsNames = (WatchlistsIdsList = []) => {
    const watchlistStore = store.getState().WatchlistsStore;
    const watchlistsNames = WatchlistsIdsList.map((watchlistId) => {
        const watchListData = watchlistStore[watchlistId];
        return watchListData ? watchListData.display_name : watchlistId;
    });

    return watchlistsNames;
};