"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Cameras API
 *  A Camera defines a video input source, such as a webcam, a RTSP address or file location.  Camera properties include:  - properties - address, source type, frame sampling etc.    - Note: properties cannot be changed when the camera is capturing. - configuration -  configuration settings for AI analysis (e.g. face recognition).    - Note: configuration can be changed online while the camera is running & analyzing.###For cameras that are currently capturing, the video source is rebroadcast by the system with RTSP protocol, either annotated (with bounding boxes and labels overlayed on the video) if the camera is currently analyzed, or as captured. A single stream can support multiple ‘listeners’ that connect to its outputs: RTSP rebroadcasting of video source and HTTP events.
 *
 * The version of the OpenAPI document: 0.32.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The FrameTimeoutSettings model module.
 * @module model/FrameTimeoutSettings
 * @version 0.32.2
 */
class FrameTimeoutSettings {
  /**
   * Constructs a new <code>FrameTimeoutSettings</code>.
   * @alias module:model/FrameTimeoutSettings
   */
  constructor() {
    FrameTimeoutSettings.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>FrameTimeoutSettings</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FrameTimeoutSettings} obj Optional instance to populate.
   * @return {module:model/FrameTimeoutSettings} The populated <code>FrameTimeoutSettings</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FrameTimeoutSettings();
      if (data.hasOwnProperty('live_frame_timeout')) {
        obj['live_frame_timeout'] = _ApiClient.default.convertToType(data['live_frame_timeout'], 'Number');
      }
      if (data.hasOwnProperty('forensics_process_timeout')) {
        obj['forensics_process_timeout'] = _ApiClient.default.convertToType(data['forensics_process_timeout'], 'Number');
      }
      if (data.hasOwnProperty('relative_index_part')) {
        obj['relative_index_part'] = _ApiClient.default.convertToType(data['relative_index_part'], 'Number');
      }
      if (data.hasOwnProperty('relative_track_part')) {
        obj['relative_track_part'] = _ApiClient.default.convertToType(data['relative_track_part'], 'Number');
      }
      if (data.hasOwnProperty('relative_recognition_part')) {
        obj['relative_recognition_part'] = _ApiClient.default.convertToType(data['relative_recognition_part'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>FrameTimeoutSettings</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>FrameTimeoutSettings</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * Number of seconds of a frame of this camera source is allowed to be delayed by the analysis flow, if this camera is a live source
 * @member {Number} live_frame_timeout
 */
FrameTimeoutSettings.prototype['live_frame_timeout'] = undefined;

/**
 * Number of seconds of a frame of this camera source is allowed to be delayed by the analysis flow, if this camera is a forensics source
 * @member {Number} forensics_process_timeout
 */
FrameTimeoutSettings.prototype['forensics_process_timeout'] = undefined;

/**
 * The relative part (0-1] of `live_frame_timeout` that the indexing process is allowed to use
 * @member {Number} relative_index_part
 */
FrameTimeoutSettings.prototype['relative_index_part'] = undefined;

/**
 * The relative part (0-1] of `live_frame_timeout` that the tracking process is allowed to use
 * @member {Number} relative_track_part
 */
FrameTimeoutSettings.prototype['relative_track_part'] = undefined;

/**
 * The relative part (0-1] of `live_frame_timeout` that the recognition process is allowed to use
 * @member {Number} relative_recognition_part
 */
FrameTimeoutSettings.prototype['relative_recognition_part'] = undefined;
var _default = exports.default = FrameTimeoutSettings;