"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * SmartCameras Generative Insights API
 *  ## Generative Insights Managemant Create, Update & retrieve information regarding the genrative insights stored in the DB.. 
 *
 * The version of the OpenAPI document: 0.37.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GenerativeInsightsQuery model module.
 * @module model/GenerativeInsightsQuery
 * @version 0.37.0
 */
class GenerativeInsightsQuery {
  /**
   * Constructs a new <code>GenerativeInsightsQuery</code>.
   * @alias module:model/GenerativeInsightsQuery
   */
  constructor() {
    GenerativeInsightsQuery.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>GenerativeInsightsQuery</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GenerativeInsightsQuery} obj Optional instance to populate.
   * @return {module:model/GenerativeInsightsQuery} The populated <code>GenerativeInsightsQuery</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GenerativeInsightsQuery();
      if (data.hasOwnProperty('record_ids')) {
        obj['record_ids'] = _ApiClient.default.convertToType(data['record_ids'], Object);
      }
      if (data.hasOwnProperty('camera_ids')) {
        obj['camera_ids'] = _ApiClient.default.convertToType(data['camera_ids'], Object);
      }
      if (data.hasOwnProperty('start_time')) {
        obj['start_time'] = _ApiClient.default.convertToType(data['start_time'], Object);
      }
      if (data.hasOwnProperty('end_time')) {
        obj['end_time'] = _ApiClient.default.convertToType(data['end_time'], Object);
      }
      if (data.hasOwnProperty('age_group_outcomes')) {
        obj['age_group_outcomes'] = _ApiClient.default.convertToType(data['age_group_outcomes'], Object);
      }
      if (data.hasOwnProperty('gender_outcomes')) {
        obj['gender_outcomes'] = _ApiClient.default.convertToType(data['gender_outcomes'], Object);
      }
      if (data.hasOwnProperty('min_insight_time_interval')) {
        obj['min_insight_time_interval'] = _ApiClient.default.convertToType(data['min_insight_time_interval'], Object);
      }
      if (data.hasOwnProperty('min_number_of_appearances')) {
        obj['min_number_of_appearances'] = _ApiClient.default.convertToType(data['min_number_of_appearances'], Object);
      }
      if (data.hasOwnProperty('analysis_mode')) {
        obj['analysis_mode'] = _ApiClient.default.convertToType(data['analysis_mode'], Object);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GenerativeInsightsQuery</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GenerativeInsightsQuery</code>.
   */
  static validateJSON(data) {
    return true;
  }
}

/**
 * List of record ids
 * @member {Object} record_ids
 */
GenerativeInsightsQuery.prototype['record_ids'] = undefined;

/**
 * The camera_ids of the insights to return
 * @member {Object} camera_ids
 */
GenerativeInsightsQuery.prototype['camera_ids'] = undefined;

/**
 * The start time to check insight from,
 * @member {Object} start_time
 */
GenerativeInsightsQuery.prototype['start_time'] = undefined;

/**
 * The end time to check insight from,
 * @member {Object} end_time
 */
GenerativeInsightsQuery.prototype['end_time'] = undefined;

/**
 * The age of the insight alert
 * @member {Object} age_group_outcomes
 */
GenerativeInsightsQuery.prototype['age_group_outcomes'] = undefined;

/**
 * The gender of the insight alert
 * @member {Object} gender_outcomes
 */
GenerativeInsightsQuery.prototype['gender_outcomes'] = undefined;

/**
 * The number of seconds the insight took.
 * @member {Object} min_insight_time_interval
 */
GenerativeInsightsQuery.prototype['min_insight_time_interval'] = undefined;

/**
 * The minimum number of appearances related to the insight
 * @member {Object} min_number_of_appearances
 */
GenerativeInsightsQuery.prototype['min_number_of_appearances'] = undefined;

/**
 * The analysis mode of interest. Options: ['forensics', 'live']
 * @member {Object} analysis_mode
 */
GenerativeInsightsQuery.prototype['analysis_mode'] = undefined;
var _default = exports.default = GenerativeInsightsQuery;