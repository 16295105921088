import { Button } from "@mui/material";
import IosShareIcon from '@mui/icons-material/IosShare';

import { exportToExcel } from "src/appConfig/Strings";

function ExportReportButton({ onClick }) {
    return (
        <Button
            startIcon={<IosShareIcon className="mb-1 ml-1 w-5" />}
            className="flex items-center text-sm normal-case"
            onClick={onClick}
        >
            {exportToExcel}
        </Button>
    );
}

export default ExportReportButton;