import { useCallback, useEffect, useState } from "react";

import { TextField } from "@mui/material";

function BaseInput({ className, label, model, value, dataTestId, innerClassName, children, onChange = null, disabled = false, defaultValue = "", sx, ...props }) {
    const [state, setState] = useState(value ?? defaultValue);
    // todo! : Refactor and remove this stand-alone controlled mechanism, it should be part of the useForm hook and should be controlled by this hook only

    const onChangeHandler = useCallback((event) => {
        const newValue = event.target.value;
        setState(newValue);
        onChange && onChange(event);
    }, [onChange]);

    useEffect(() => {
        setState(value ?? defaultValue);
    }, [value, defaultValue]);

    return (
        <TextField
            className={className}
            variant="outlined"
            label={label}
            value={state}
            inputProps={{ className: innerClassName, ...model }}
            onChange={onChangeHandler}
            disabled={disabled}
            {...model}
            {...props}
            data-testid={dataTestId}
            sx={sx}
        >
            {children}
        </TextField>
    );
}

export default BaseInput;