import { acknowledgeAlertLabel, crop, eventsHeader, poiAlertDetailLabel, poiImg } from "src/appConfig/Strings";
import { generateGridUiColumns } from "../../MUITables/TablesLogic";

export const alertsColumnsHeaders = (isLiveAnalysisMode) => ([
    { name: acknowledgeAlertLabel },
    { name: crop },
    { name: poiImg },
    { name: poiAlertDetailLabel, props: { isLiveAnalysisMode } }
]);

export const AlertsModel = (rows, tableType, isLiveAnalysisMode) => {
    const columns = generateGridUiColumns(alertsColumnsHeaders(isLiveAnalysisMode), eventsHeader, tableType);

    return {
        columns,
        rows: rows.length > 0 ? rows.map(row => ({
            id: row.appearance_data.appearance_id,
            ...row
        })) : [],
        rowHeight: 170,
    };
};